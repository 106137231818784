import { Component, OnInit } from "@angular/core";
import DataService from "./../data/service";
import UserService from "./../user/service";
import MapService from "./../map/service";
import MapViewService from "./../map-view/service";
/**
 * Mit dieser Komponente kann der Nutzer die angezeigten Kartenelemente zusammenstellen.
 *
 * Die Kartenelemente sind in Kategorien unterteilt. Der Nutzer kann in jeder der Kategorien Kartenelemente an- und abwählen.
 */
@Component({
    selector: "map-panel-layer",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class MapPanelLayer implements OnInit {
    constructor(public dataService: DataService, public mapService: MapService, public userService: UserService, public mapViewService: MapViewService) {
    }
    public layerGroups: any = [];
    ngOnInit() {
    }
    ngAfterViewInit() {
        let groups = {};
        this.mapService.wmsLayerStock.forEach(layer => {
            groups[layer.group] = groups[layer.group] || {
                name: layer.group,
                layers: []
            };
            groups[layer.group].layers.push(layer);
        });
        for (let group in groups) {
            this.layerGroups.push(groups[group]);
        }
    }
    public wmsLayerIsInMassstab(name: string): boolean {
        return this.mapService.wmsLayerInMassstabList.filter(layer => layer.name === name).length > 0;
    }
    public onLayerChange(e, layer) {
        if (this.wmsLayerIsInMassstab(layer.name)) {
            this.mapService.checkAdditionalLayers();
        }
    }
    public romanize(num) {
        if (isNaN(num))
            return NaN;
        var digits = String(+num).split(""), key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
            "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
            "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"], roman = "", i = 3;
        while (i--)
            roman = (key[+digits.pop() + (i * 10)] || "") + roman;
        return Array(+digits.join("") + 1).join("M") + roman;
    }
    public get isExtendedView(): boolean {
        return this.userService.isLoggedIn === true;
    }
    public get accordionHeight(): number {
        const h = this.mapViewService.height
            - this.mapViewService.mapPadding.panel.bottom
            - (8 * 24)
            - 110;
        return Math.max(230, h);
    }
}
