import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoMethodegeraetetyp from "../../models/fischinfo/methodegeraetetyp/model";
import FischinfoElektrobefischungsmethode from "../../models/fischinfo/elektrobefischungsmethode/model";
import FischinfoMethode from "../../models/fischinfo/methode/model";
/**
 * Mit dieser Komponente kann der Nutzer die Informationen zum Befischungsgerät E-Gerät ansehen und ggf. bearbeiten.
 *
 * Der Nutzer kann mit dieser Komponente unter Anderem die Befischungsart, eingesetzte E-Gerätetypen,
 * die E-Befischungsmethode und elektrotechnische Daten der Befischungsmethode angeben.
 * In Abhängigkeit der ausgewählten E-Befischungsmethode kommen zum dargestellten Formular unterschiedliche Felder hinzu.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-methode-egeraet",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungMethodeEgeraet implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get elektrobefischungsmethode_id(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .elektrobefischungsmethode_id;
    }
    public lastChosenElektrobefischungsmethode_id;
    public deletedByElektrobefischungsmethode_id: any = {
        "1": {},
        "2": {},
        "3": {},
    };
    public set elektrobefischungsmethode_id(value: number) {
        let methode = this.formFischinfoUntersuchungService.modelUtil
            .setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode");
        if (methode.elektrobefischungsmethode_id === parseInt(String(value), 10))
            return;
        const undefinedValueReplacer = (k: string, v: any) => v === undefined ? null : v;
        const modelsToBeDeleted = {
            "1": (clear: boolean) => {
                [
                    "befischtelaenge",
                    "befischtebreite"
                ].forEach(key => {
                    !clear && this.lastChosenElektrobefischungsmethode_id === 1
                        && (this.deletedByElektrobefischungsmethode_id[String(this.lastChosenElektrobefischungsmethode_id)][key] = JSON.parse(JSON.stringify(methode[key], undefinedValueReplacer)));
                    clear && (methode[key] = null);
                });
            },
            "2": (clear: boolean) => {
                [
                    "anodenart_id",
                    "anodendurchmesser",
                    "vorgehensweise_id",
                    "anzahlprobepunkte",
                    "befischteteilflaeche",
                    "untersuchungsbereich",
                ].forEach(key => {
                    !clear && this.lastChosenElektrobefischungsmethode_id === 2
                        && (this.deletedByElektrobefischungsmethode_id[String(this.lastChosenElektrobefischungsmethode_id)][key] = JSON.parse(JSON.stringify(methode[key], undefinedValueReplacer)));
                    clear && (methode[key] = null);
                });
            },
            "3": (clear: boolean) => {
                [].forEach(key => {
                    !clear && this.lastChosenElektrobefischungsmethode_id === 3
                        && (this.deletedByElektrobefischungsmethode_id[String(this.lastChosenElektrobefischungsmethode_id)][key] = JSON.parse(JSON.stringify(methode[key], undefinedValueReplacer)));
                    clear && (methode[key] = null);
                });
            }
        };
        Object.keys(modelsToBeDeleted)
            .filter(x => String(x) !== String(value))
            .map(x => { modelsToBeDeleted[String(x)](false); return x; })
            .forEach(x => modelsToBeDeleted[String(x)](true));
        value
            && this.deletedByElektrobefischungsmethode_id[String(value)]
            && Object.keys(this.deletedByElektrobefischungsmethode_id[String(value)])
                .forEach(x => {
                methode[x] =
                    this.deletedByElektrobefischungsmethode_id[String(value)][x]
                        || methode[x]
                        || null;
            });
        this.lastChosenElektrobefischungsmethode_id = parseInt(String(value), 10) || null;
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .elektrobefischungsmethode_id = parseInt(String(value), 10) || null;
    }
    public get anzahlanodenkaescher(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anzahlanodenkaescher;
    }
    public set anzahlanodenkaescher(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anzahlanodenkaescher = parseInt(String(value), 10) || null;
    }
    public get streifenanode(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .streifenanode;
    }
    public set streifenanode(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .streifenanode = parseInt(String(value), 10) || null;
    }
    public get anzahlnichtelektrischerkaescher(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anzahlnichtelektrischerkaescher;
    }
    public set anzahlnichtelektrischerkaescher(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anzahlnichtelektrischerkaescher = parseInt(String(value), 10) || null;
    }
    public get spannung(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .spannung;
        return value
            && String(value).replace(".", ",");
    }
    public set spannung(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .spannung = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get stromstaerkeampere(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .stromstaerkeampere;
        return value
            && String(value).replace(".", ",");
    }
    public set stromstaerkeampere(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .stromstaerkeampere = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get stromstaerkeprozent(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .stromstaerkeprozent;
        return value
            && String(value).replace(".", ",");
    }
    public set stromstaerkeprozent(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .stromstaerkeprozent = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get methodegeraetetypList(): FischinfoMethodegeraetetyp[] {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .fischinfoMethodegeraetetypListByForeignMethode_id || [];
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    public elektrobefischungsmethode_idRefJsonItems: string;
    public get firstIsSet(): boolean {
        return this.methodegeraetetypList.length > 0
            && (this.methodegeraetetypList.length > 1
                || parseInt(String(this.methodegeraetetypList[0].anzahl), 10) > 0);
    }
    ngOnInit() {
        !this.readonly && this.methodegeraetetypList.length < 1
            && (this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethodegeraetetyp>(this.untersuchung, "FischinfoMethodegeraetetyp")
                .anzahl = null);
        const a = this.dataService
            .getReferenceTable("elektrobefischungsmethode", FischinfoElektrobefischungsmethode, "id");
        return Promise.all<{
            [key: string]: FischinfoElektrobefischungsmethode;
        }>([a])
            .then(refs => {
            [0].map(x => {
                let refList = [], entityKey;
                Object.keys(refs[x]).forEach(key => {
                    entityKey = key;
                    refList.push('[' + refs[x][key].id + ',"' + refs[x][key].bezeichnung + '"]');
                });
                refs[x] && refs[x][entityKey] instanceof FischinfoElektrobefischungsmethode
                    && (this.elektrobefischungsmethode_idRefJsonItems = "[" + refList.join(",") + "]");
            });
        });
    }
    public addModel(e: Event) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethodegeraetetyp>(this.untersuchung, "FischinfoMethodegeraetetyp")
            .anzahl = null;
    }
    public onChangedMethodegeraetetyp(e: Event, u: FischinfoMethodegeraetetyp, index: number) {
        !this.readonly && this.methodegeraetetypList.length < 1
            && (this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethodegeraetetyp>(this.untersuchung, "FischinfoMethodegeraetetyp")
                .anzahl = null);
        this.changed.emit(true);
    }
    public onChangedMethodegeraetstreckenbefischung(e: Event) {
        this.changed.emit(true);
    }
    public onChangedMethodegeraetpointabundance(e: Event) {
        this.changed.emit(true);
    }
    public onChangedMethodegeraetanderemethode(e: Event) {
        this.changed.emit(true);
    }
}
