import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoBefischung from "./interface";
import FischinfoGewaesserzustand from "./../../fischinfo/gewaesserzustand/model";
import FischinfoHaeufigkeitbefischungergebnis from "./../../fischinfo/haeufigkeitbefischungergebnis/model";
import FischinfoFibsresult from "./../../fischinfo/fibsresult/model";
import FischinfoFfhbewertung from "./../../fischinfo/ffhbewertung/model";
import FischinfoAnzahlbefischungergebnis from "./../../fischinfo/anzahlbefischungergebnis/model";
import FischinfoDetailbefischungergebnis from "./../../fischinfo/detailbefischungergebnis/model";
import FischinfoLaengenklassenbefischungergebnis from "./../../fischinfo/laengenklassenbefischungergebnis/model";
import FischinfoNachweisbefischungergebnis from "./../../fischinfo/nachweisbefischungergebnis/model";
import FischinfoMethode from "./../../fischinfo/methode/model";
import FischinfoQualitaetdermeldung from "./../../fischinfo/qualitaetdermeldung/model";
import FischinfoUntersuchung from "./../../fischinfo/untersuchung/model";
@fn.DataModel
export default class FischinfoBefischung extends DataModel implements IFischinfoBefischung {
    constructor(obj?: object | IFischinfoBefischung) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoBefischung): FischinfoBefischung {
        return new FischinfoBefischung(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoBefischung): FischinfoBefischung {
        if (obj.hasOwnProperty("g")) {
            Object.assign(this, {
                bemerkung: obj["a"],
                beobachtung: obj["b"],
                erfasst: obj["c"],
                ffhmonitoring: obj["d"],
                fischereigenossenschaft: obj["e"],
                id: obj["g"],
                keinefische: obj["h"],
                psnrgues: obj["i"],
                referenznr: obj["j"],
                referenznrgues: obj["k"],
                trockengefallen: obj["l"],
                wrrlmonitoring: obj["m"],
                fischinfoQualitaetdermeldungByQualitaetdermeldung_id: obj["n"]
                    ? FischinfoQualitaetdermeldung.deserialize(obj["n"])
                    : null
            });
        } else {
            Object.assign(this, obj);
            obj && obj["fischinfoGewaesserzustandByGewaesserzustand_id"] && (this.fischinfoGewaesserzustandByGewaesserzustand_id = FischinfoGewaesserzustand.deserialize(obj["fischinfoGewaesserzustandByGewaesserzustand_id"]));
            obj && obj["fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id"] && obj["fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id"].length && (this.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id = obj["fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id"].map(FischinfoHaeufigkeitbefischungergebnis.deserialize));
            obj && obj["fischinfoFibsresultListByForeignBefischung_id"] && obj["fischinfoFibsresultListByForeignBefischung_id"].length && (this.fischinfoFibsresultListByForeignBefischung_id = obj["fischinfoFibsresultListByForeignBefischung_id"].map(FischinfoFibsresult.deserialize));
            obj && obj["fischinfoFfhbewertungListByForeignBefischung_id"] && obj["fischinfoFfhbewertungListByForeignBefischung_id"].length && (this.fischinfoFfhbewertungListByForeignBefischung_id = obj["fischinfoFfhbewertungListByForeignBefischung_id"].map(FischinfoFfhbewertung.deserialize));
            obj && obj["fischinfoAnzahlbefischungergebnisListByForeignBefischung_id"] && obj["fischinfoAnzahlbefischungergebnisListByForeignBefischung_id"].length && (this.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id = obj["fischinfoAnzahlbefischungergebnisListByForeignBefischung_id"].map(FischinfoAnzahlbefischungergebnis.deserialize));
            obj && obj["fischinfoDetailbefischungergebnisListByForeignBefischung_id"] && obj["fischinfoDetailbefischungergebnisListByForeignBefischung_id"].length && (this.fischinfoDetailbefischungergebnisListByForeignBefischung_id = obj["fischinfoDetailbefischungergebnisListByForeignBefischung_id"].map(FischinfoDetailbefischungergebnis.deserialize));
            obj && obj["fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id"] && obj["fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id"].length && (this.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id = obj["fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id"].map(FischinfoLaengenklassenbefischungergebnis.deserialize));
            obj && obj["fischinfoNachweisbefischungergebnisListByForeignBefischung_id"] && obj["fischinfoNachweisbefischungergebnisListByForeignBefischung_id"].length && (this.fischinfoNachweisbefischungergebnisListByForeignBefischung_id = obj["fischinfoNachweisbefischungergebnisListByForeignBefischung_id"].map(FischinfoNachweisbefischungergebnis.deserialize));
            obj && obj["fischinfoMethodeByMethode_id"] && (this.fischinfoMethodeByMethode_id = FischinfoMethode.deserialize(obj["fischinfoMethodeByMethode_id"]));
            obj && obj["fischinfoQualitaetdermeldungByQualitaetdermeldung_id"] && (this.fischinfoQualitaetdermeldungByQualitaetdermeldung_id = FischinfoQualitaetdermeldung.deserialize(obj["fischinfoQualitaetdermeldungByQualitaetdermeldung_id"]));
            obj && obj["fischinfoUntersuchungByUntersuchung_id"] && (this.fischinfoUntersuchungByUntersuchung_id = FischinfoUntersuchung.deserialize(obj["fischinfoUntersuchungByUntersuchung_id"]));
        }
        return this;
    }

    /**
     * @type {string} character varying 2000
     */
    @fn.DataMember({ name: "bemerkung", type: String, databaseType: "character varying(2000)", nullable: true, memberType: "" })
    @fn.Label("Bemerkungen")
    public bemerkung?: string;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "beobachtung", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public beobachtung?: boolean;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "erfasst", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    @fn.Label("Erfassung abgeschlossen")
    public erfasst?: boolean;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "ffhmonitoring", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    @fn.Label("FFH-Monitoring")
    public ffhmonitoring?: boolean;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "fischereigenossenschaft", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    @fn.Label("Fischereigenossenschaft")
    public fischereigenossenschaft?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gewaesserzustand_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public gewaesserzustand_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "keinefische", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    @fn.Label("Keine Fische")
    public keinefische?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "methode_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public methode_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "psnrgues", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public psnrgues?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "qualitaetdermeldung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    @fn.Label("Qualität der Meldung")
    public qualitaetdermeldung_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "referenznr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public referenznr?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "referenznrgues", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public referenznrgues?: string;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "trockengefallen", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    @fn.Label("Trocken gefallen")
    public trockengefallen?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "untersuchung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public untersuchung_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "wrrlmonitoring", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    @fn.Label("WRRL-Monitoring")
    public wrrlmonitoring?: boolean;

    /**
     * @type {FischinfoGewaesserzustand}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandByGewaesserzustand_id", type: FischinfoGewaesserzustand, nullable: true, memberType: "JOIN", joinProperty: "gewaesserzustand_id" })
    public fischinfoGewaesserzustandByGewaesserzustand_id?: FischinfoGewaesserzustand;

    /**
     * @type {Array<FischinfoHaeufigkeitbefischungergebnis>}
     */
    @fn.DataMember({ name: "fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id", type: [FischinfoHaeufigkeitbefischungergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id?: Array<FischinfoHaeufigkeitbefischungergebnis>;

    /**
     * @type {Array<FischinfoFibsresult>}
     */
    @fn.DataMember({ name: "fischinfoFibsresultListByForeignBefischung_id", type: [FischinfoFibsresult], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoFibsresultListByForeignBefischung_id?: Array<FischinfoFibsresult>;

    /**
     * @type {Array<FischinfoFfhbewertung>}
     */
    @fn.DataMember({ name: "fischinfoFfhbewertungListByForeignBefischung_id", type: [FischinfoFfhbewertung], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoFfhbewertungListByForeignBefischung_id?: Array<FischinfoFfhbewertung>;

    /**
     * @type {Array<FischinfoAnzahlbefischungergebnis>}
     */
    @fn.DataMember({ name: "fischinfoAnzahlbefischungergebnisListByForeignBefischung_id", type: [FischinfoAnzahlbefischungergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoAnzahlbefischungergebnisListByForeignBefischung_id?: Array<FischinfoAnzahlbefischungergebnis>;

    /**
     * @type {Array<FischinfoDetailbefischungergebnis>}
     */
    @fn.DataMember({ name: "fischinfoDetailbefischungergebnisListByForeignBefischung_id", type: [FischinfoDetailbefischungergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoDetailbefischungergebnisListByForeignBefischung_id?: Array<FischinfoDetailbefischungergebnis>;

    /**
     * @type {Array<FischinfoLaengenklassenbefischungergebnis>}
     */
    @fn.DataMember({ name: "fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id", type: [FischinfoLaengenklassenbefischungergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id?: Array<FischinfoLaengenklassenbefischungergebnis>;

    /**
     * @type {Array<FischinfoNachweisbefischungergebnis>}
     */
    @fn.DataMember({ name: "fischinfoNachweisbefischungergebnisListByForeignBefischung_id", type: [FischinfoNachweisbefischungergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoNachweisbefischungergebnisListByForeignBefischung_id?: Array<FischinfoNachweisbefischungergebnis>;

    /**
     * @type {FischinfoMethode}
     */
    @fn.DataMember({ name: "fischinfoMethodeByMethode_id", type: FischinfoMethode, nullable: true, memberType: "JOIN", joinProperty: "methode_id" })
    public fischinfoMethodeByMethode_id?: FischinfoMethode;

    /**
     * @type {FischinfoQualitaetdermeldung}
     */
    @fn.DataMember({ name: "fischinfoQualitaetdermeldungByQualitaetdermeldung_id", type: FischinfoQualitaetdermeldung, nullable: true, memberType: "JOIN", joinProperty: "qualitaetdermeldung_id" })
    public fischinfoQualitaetdermeldungByQualitaetdermeldung_id?: FischinfoQualitaetdermeldung;

    /**
     * @type {FischinfoUntersuchung}
     */
    @fn.DataMember({ name: "fischinfoUntersuchungByUntersuchung_id", type: FischinfoUntersuchung, nullable: true, memberType: "JOIN", joinProperty: "untersuchung_id" })
    public fischinfoUntersuchungByUntersuchung_id?: FischinfoUntersuchung;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
