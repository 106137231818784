import { Component, Input } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
/**
 * Mit dieser Komponente kann der Nutzer die Inhalte einer Untersuchung ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt nur die Strukturinformationen des befischten Gewässers dar.
 * Dazu gehören unter Anderem fischrelevante Strukturelemente, Uferböschungsbestand, Untergrundstruktur und Ausbauzustand.
 */
@Component({
    selector: "form-fischinfo-untersuchung-gewaesser-struktur",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungGewaesserStruktur {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get beschattung(): string {
        let beschattung = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .beschattung;
        return beschattung
            && String(beschattung).replace(".", ",");
    }
    public set beschattung(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .beschattung = parseFloat(String(value).replace(",", ".")) || null;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService) {
    }
}
