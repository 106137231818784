import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { saveAs } from "file-saver";
import UserService from "./../user/service";
import ModalService from "./../modal/service";
import DataService from "./../data/service";
import MapService from "../map/service";
import IntragisBenutzerprobenahmestelle from "../../models/intragis/benutzerprobenahmestelle/model";
import { Subscription, firstValueFrom } from "rxjs";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
@Component({
    selector: "uw-modal-window-tab-auftragverwaltung",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowTabAuftragverwaltung implements OnInit, OnDestroy {
    @Input()
    public modal: any;
    @Input()
    public set topmargin(value: string) {
        this.topMarginPx = String(Number(value) - (this.userService.roles.includes("ROLE_ADMIN")
            ? 0
            : Number(this.buttonPanelHeight)));
    }
    ;
    public get topmargin(): string {
        return this.topMarginPx;
    }
    ;
    public buttonPanelHeight: string = "24";
    public topMarginPx: string;
    public auftragList: IntragisBenutzerprobenahmestelle[] = null;
    public filteredAuftragList: IntragisBenutzerprobenahmestelle[] = null;
    public filtered: boolean = false;
    public probestellennrIdx: Record<number, boolean> = Object.create(null);
    public subscriptions = new Subscription();
    public auftragImportDisabled: boolean = false;
    constructor(public readonly http: HttpClient, public readonly modalService: ModalService, public readonly dataService: DataService, public readonly userService: UserService, public readonly mapService: MapService) {
        this.topmargin = "24";
    }
    public ngOnInit() {
        this.updateAuftragList();
        this.subscriptions.add(this.dataService.completeLoadSuccess.subscribe(() => {
            this.probestellennrIdx = (this.dataService.dataStore.probenahmestelle as FischinfoProbenahmestelle[])
                .reduce((idx, ps) => (idx[ps.probestellennr] = true, idx), Object.create(null) as Record<number, boolean>);
            this.updateFilteredList();
        }));
    }
    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.subscriptions = null;
    }
    public fetchAuftragList() {
        return this.dataService.getAll(this.dataService.transcribeURL(this.dataService.webServiceApiListUrl, { folder: "auftragverwaltung" }, 5000), IntragisBenutzerprobenahmestelle);
    }
    public async updateAuftragList() {
        this.modal.data.loaded = 0;
        const items = await this.fetchAuftragList();
        this.auftragList = items.sort(this.auftragComparer);
        this.modal.data.loaded = 1;
        this.updateFilteredList();
    }
    public clearAuftragList() {
        this.auftragList = Array<IntragisBenutzerprobenahmestelle>(0);
        this.updateFilteredList();
    }
    public readonly auftragComparer = (l: IntragisBenutzerprobenahmestelle, r: IntragisBenutzerprobenahmestelle) => l.benutzer_name.localeCompare(r.benutzer_name)
        || l.probestellennr.split("-", 1)[0].localeCompare(r.probestellennr.split("-", 1)[0])
        || (Number.parseInt(l.probestellennr.split("-", 2)[1]) - Number.parseInt(r.probestellennr.split("-", 2)[1]))
        || (Number.parseInt(l.probestellennr.split("-", 3)[2]) - Number.parseInt(r.probestellennr.split("-", 3)[2]));
    public updateFilteredList() {
        this.filteredAuftragList = this.filtered
            ? this.auftragList.filter(bp => this.probestellennrIdx[bp.probestellennr] === true)
            : this.auftragList;
    }
    public insertOrReplaceAuftrag(auftrag: IntragisBenutzerprobenahmestelle) {
        const idx = this.auftragList.findIndex(bp => bp.id === auftrag.id);
        if (idx >= 0) {
            this.auftragList[idx] = auftrag;
        }
        else {
            this.auftragList.push(auftrag);
        }
        this.auftragList = this.auftragList.sort(this.auftragComparer);
        this.updateFilteredList();
    }
    public removeAuftrag(auftrag: IntragisBenutzerprobenahmestelle) {
        const idx = this.auftragList.findIndex(bp => bp.id === auftrag.id);
        if (idx >= 0) {
            this.auftragList.splice(idx, 1);
            this.updateFilteredList();
        }
    }
    public showImportTab(modalId: string) {
        let data = this.modalService.getModalOption(modalId, "data") || {};
        data.activeTab = "auftragimport";
        data.auftragToBeLoaded = null;
        data.auftragimport = true;
        data.auftragList = this;
        this.modalService.setModalOption(modalId, "data", data);
    }
    public async showAuftrag(modalId: string, item: IntragisBenutzerprobenahmestelle) {
        let data = this.modalService.getModalOption(modalId, "data") || {};
        data.loaded = 0;
        data.activeTab = "auftrag";
        data.auftragToBeLoaded = item;
        data.auftragimport = null;
        data.status = "Wird geladen…";
        data.auftragList = this;
        this.modalService.setModalOption(modalId, "data", data);
    }
    public getDateInDatetime(ts: number): string {
        const d = ts && new Date(ts);
        return !d || d.getUTCFullYear() === 1970 ? "" : ((d.getUTCDate() < 10
            ? "0" + d.getUTCDate()
            : d.getUTCDate()) + "." + ((d.getUTCMonth() + 1) < 10
            ? "0" + (d.getUTCMonth() + 1)
            : (d.getUTCMonth() + 1)) + "." + d.getUTCFullYear());
    }
    public async exportCsvFile() {
        const data = this.modal.data || {};
        const loadingAuskunft = data.loadingAuskunft || (data.loadingAuskunft = {});
        const exportView = "export_auftrag_csv";
        if (!loadingAuskunft[exportView]) {
            loadingAuskunft[exportView] = true;
            data.loaded = false;
            try {
                const requtils = await this.prepareRequest();
                const csv = await firstValueFrom(this.http.post(this.dataService.transcribeURL(this.dataService.webServiceApiListUrl.replace("?", "/{version}?"), {
                    folder: "auftragverwaltung",
                    version: exportView,
                }), this.filteredAuftragList.map(a => a.id), {
                    headers: requtils.headers(),
                    responseType: "blob",
                }));
                saveAs(csv, `${exportView.replace(/_csv$/, "")}${this.filtered ? "_gefiltert" : ""}.csv`);
            }
            finally {
                delete loadingAuskunft[exportView];
                data.loaded = Object.keys(loadingAuskunft).length === 0;
            }
        }
    }
    public prepareRequest() {
        const baseHeaders = {};
        return this.dataService.getLoginTokenPromise()
            .then(token => {
            const tokenHeaders = {
                "X-AUTH-TOKEN": token,
            };
            return {
                headers: (otherHeaders?: {
                    [name: string]: any;
                }) => new HttpHeaders(Object.assign({}, baseHeaders, tokenHeaders, otherHeaders)),
            };
        });
    }
    public onFilteredChange(newFiltered: boolean) {
        const oldFiltered = this.filtered;
        this.filtered = newFiltered;
        if (oldFiltered !== newFiltered) {
            this.updateFilteredList();
        }
    }
    public onClickShowAuftrag(e: Event, auftrag: IntragisBenutzerprobenahmestelle) {
        this.showAuftrag(this.modal.id, auftrag);
    }
    public onClickCreateAuftrag(e: Event) {
        this.showAuftrag(this.modal.id, new IntragisBenutzerprobenahmestelle({ id: 0 }));
    }
    public onClickExportList(e: Event) {
        this.exportCsvFile();
    }
    public onClickImportList(e: Event) {
        this.showImportTab(this.modal.id);
    }
}
