import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoGewaesserzustanduferpflanze from "./interface";
import FischinfoGewaesserzustand from "./../../fischinfo/gewaesserzustand/model";
import FischinfoUferpflanze from "./../../fischinfo/uferpflanze/model";
@fn.DataModel
export default class FischinfoGewaesserzustanduferpflanze extends DataModel implements IFischinfoGewaesserzustanduferpflanze {
    constructor(obj?: object | IFischinfoGewaesserzustanduferpflanze) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoGewaesserzustanduferpflanze): FischinfoGewaesserzustanduferpflanze {
        return new FischinfoGewaesserzustanduferpflanze(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoGewaesserzustanduferpflanze): FischinfoGewaesserzustanduferpflanze {
        Object.assign(this, obj);
        obj && obj["fischinfoGewaesserzustandByGewaesserzustand_id"] && (this.fischinfoGewaesserzustandByGewaesserzustand_id = FischinfoGewaesserzustand.deserialize(obj["fischinfoGewaesserzustandByGewaesserzustand_id"]));
        obj && obj["fischinfoUferpflanzeByUferpflanze_id"] && (this.fischinfoUferpflanzeByUferpflanze_id = FischinfoUferpflanze.deserialize(obj["fischinfoUferpflanzeByUferpflanze_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gewaesserzustand_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public gewaesserzustand_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "uferpflanze_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public uferpflanze_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoGewaesserzustand}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandByGewaesserzustand_id", type: FischinfoGewaesserzustand, nullable: true, memberType: "JOIN", joinProperty: "gewaesserzustand_id" })
    public fischinfoGewaesserzustandByGewaesserzustand_id?: FischinfoGewaesserzustand;

    /**
     * @type {FischinfoUferpflanze}
     */
    @fn.DataMember({ name: "fischinfoUferpflanzeByUferpflanze_id", type: FischinfoUferpflanze, nullable: true, memberType: "JOIN", joinProperty: "uferpflanze_id" })
    public fischinfoUferpflanzeByUferpflanze_id?: FischinfoUferpflanze;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
