import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoUntersuchunganlass from "./interface";
import FischinfoAnlass from "./../../fischinfo/anlass/model";
import FischinfoUntersuchung from "./../../fischinfo/untersuchung/model";
@fn.DataModel
export default class FischinfoUntersuchunganlass extends DataModel implements IFischinfoUntersuchunganlass {
    constructor(obj?: object | IFischinfoUntersuchunganlass) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoUntersuchunganlass): FischinfoUntersuchunganlass {
        return new FischinfoUntersuchunganlass(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoUntersuchunganlass): FischinfoUntersuchunganlass {
        Object.assign(this, obj);
        obj && obj["fischinfoAnlassByAnlass_id"] && (this.fischinfoAnlassByAnlass_id = FischinfoAnlass.deserialize(obj["fischinfoAnlassByAnlass_id"]));
        obj && obj["fischinfoUntersuchungByUntersuchung_id"] && (this.fischinfoUntersuchungByUntersuchung_id = FischinfoUntersuchung.deserialize(obj["fischinfoUntersuchungByUntersuchung_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anlass_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public anlass_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "untersuchung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public untersuchung_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoAnlass}
     */
    @fn.DataMember({ name: "fischinfoAnlassByAnlass_id", type: FischinfoAnlass, nullable: true, memberType: "JOIN", joinProperty: "anlass_id" })
    public fischinfoAnlassByAnlass_id?: FischinfoAnlass;

    /**
     * @type {FischinfoUntersuchung}
     */
    @fn.DataMember({ name: "fischinfoUntersuchungByUntersuchung_id", type: FischinfoUntersuchung, nullable: true, memberType: "JOIN", joinProperty: "untersuchung_id" })
    public fischinfoUntersuchungByUntersuchung_id?: FischinfoUntersuchung;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
