import DecoratorHelper from "./../helper";

export default function Table(data: {
    columnIndex?: number,
    columnPercentualWidth?: number,
    dependsOnFilter?: string,
    label?: string,
    cssClass?: string,
    align?: string,
    sortBy?: string,
    sortType?: string,
} = {}): any {
    return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
        DecoratorHelper.definePropertyMetadata(target, propertyKey, {
            table: data
        });
    }
}
