import { Component, Input, Output, EventEmitter, } from "@angular/core";
import { trigger, state, style, animate, transition } from '@angular/animations';
import UWAccordion from "../accordion/directive";
/**
 * Diese Komponente markiert einen Bereich als Körper einer Kategorie (siehe Komponente `UWAccordion`).
 *
 * Der Körper einer Kategorie kann seine Sichtbarkeit ändern. Das heißt, seine Inhalte können eingeblendet
 * und ausgeblendet werden. Wenn der Nutzer den dazugehörigen Titel der Kategorie klickt, schaltet die
 * Elternkomponente die Sichtbarkeit des Körpers um.
 */
@Component({
    selector: "uw-accordion-pane",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"],
    animations: [
        trigger('growInOut', [
            state("inactive", style({ height: 0 })),
            state("active", style({ height: "*" })),
            transition('inactive => active', [
                style({ height: 0 }),
                animate(250, style({ height: '*' }))
            ]),
            transition('inactive => instantActive', [
                style({ height: 0 }),
                animate(0, style({ height: '*' }))
            ]),
            transition('active => inactive', [
                style({ height: '*' }),
                animate(250, style({ height: 0 }))
            ]),
            transition('instantActive => inactive', [
                style({ height: '*' }),
                animate(250, style({ height: 0 }))
            ])
        ]),
        trigger('fadeIn', [
            state('void', style({ opacity: 0 })),
            state('*', style({ opacity: 1 })),
            transition('void => *', animate('0.15s 400ms ease-in')),
        ]),
    ]
})
export default class UWAccordionPane {
    @Input()
    public id: number = 0;
    @Input()
    public headerTitle: string = "";
    @Output()
    change: EventEmitter<boolean> = new EventEmitter();
    public activeValue: boolean = false;
    public set active(active: boolean) {
        if (active !== this.activeValue) {
            this.activeValue = active;
            this.change.emit(this.activeValue);
        }
    }
    public get active(): boolean {
        return this.activeValue;
    }
    @Input()
    public footerLayout: string = "relative";
    public headerHeightValue: number = 24;
    @Input()
    public get headerHeight(): number {
        return this.headerHeightValue;
    }
    public set headerHeight(height: number) {
        this.headerHeightValue = parseFloat(height.toString());
    }
    public footerHeightValue: number = 0;
    @Input()
    public get footerHeight(): number {
        return this.footerHeightValue;
    }
    public set footerHeight(height: number) {
        this.footerHeightValue = parseFloat(height.toString());
    }
    public paddingBottomValue: number = 10;
    @Input()
    public get paddingBottom(): number {
        return this.paddingBottomValue;
    }
    public set paddingBottom(height: number) {
        this.paddingBottomValue = parseFloat(height.toString());
    }
    public paddingTopValue: number = 0;
    @Input()
    public get paddingTop(): number {
        return this.paddingTopValue;
    }
    public set paddingTop(height: number) {
        this.paddingTopValue = parseFloat(height.toString());
    }
    public bodyHeight: number = 0;
    public targetBodyHeight: number = 0;
    @Input()
    public set height(height: number) {
        const h: number = height - this.headerHeight;
        this.targetBodyHeight = h > 0 ? h : 0;
        this.active = false;
        if (h > 0) {
            this.bodyHeight = this.targetBodyHeight;
            this.animationState = this.animationEnabled ? "active" : "instantActive";
        }
        else {
            if (this.animationEnabled) {
                this.animationState = "inactive";
            }
            else {
                this.bodyHeight = this.targetBodyHeight;
            }
        }
    }
    @Input()
    public animationEnabled: boolean = true; // opening/closing animation
    public animationState: string = "inactive";
    public animationStarted(e: Event) {
    }
    public animationDone(e: Event) {
        this.active = parseInt(this.bodyHeight.toString(), 10) > 0 ? true : false;
    }
    constructor(public accordion: UWAccordion) {
        this.accordion.classifyPane(this);
    }
}
