import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoGewaesser from "./interface";
import FischinfoBesatzort from "./../../fischinfo/besatzort/model";
import FischinfoProbenahmestelle from "./../../fischinfo/probenahmestelle/model";
@fn.DataModel
export default class FischinfoGewaesser extends DataModel implements IFischinfoGewaesser {
    constructor(obj?: object | IFischinfoGewaesser) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoGewaesser): FischinfoGewaesser {
        return new FischinfoGewaesser(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoGewaesser): FischinfoGewaesser {
        if (obj.hasOwnProperty("c")) {
            Object.assign(this, {
                gewaesserkennzahl: obj["a"],
                gewaessername: obj["b"],
                id: obj["c"],
                seenkennzahl: obj["d"],
                stillgewaesser: obj["e"]
            });
        } else {
            Object.assign(this, obj);
            obj && obj["fischinfoBesatzortListByForeignGewaesser_id"] && obj["fischinfoBesatzortListByForeignGewaesser_id"].length && (this.fischinfoBesatzortListByForeignGewaesser_id = obj["fischinfoBesatzortListByForeignGewaesser_id"].map(FischinfoBesatzort.deserialize));
            obj && obj["fischinfoProbenahmestelleListByForeignGewaesser_id"] && obj["fischinfoProbenahmestelleListByForeignGewaesser_id"].length && (this.fischinfoProbenahmestelleListByForeignGewaesser_id = obj["fischinfoProbenahmestelleListByForeignGewaesser_id"].map(FischinfoProbenahmestelle.deserialize));
        }
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gewaesserkennzahl", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gewaesserkennzahl?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gewaessername", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gewaessername?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "seenkennzahl", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public seenkennzahl?: string;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "stillgewaesser", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public stillgewaesser?: boolean;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoBesatzort>}
     */
    @fn.DataMember({ name: "fischinfoBesatzortListByForeignGewaesser_id", type: [FischinfoBesatzort], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzortListByForeignGewaesser_id?: Array<FischinfoBesatzort>;

    /**
     * @type {Array<FischinfoProbenahmestelle>}
     */
    @fn.DataMember({ name: "fischinfoProbenahmestelleListByForeignGewaesser_id", type: [FischinfoProbenahmestelle], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoProbenahmestelleListByForeignGewaesser_id?: Array<FischinfoProbenahmestelle>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
