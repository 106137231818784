import { Component, Input, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import DataService from "../data/service";
import FischinfoWasserstand from "../../models/fischinfo/wasserstand/model";
import FischinfoStroemung from "../../models/fischinfo/stroemung/model";
import FischinfoLinienfuehrung from "../../models/fischinfo/linienfuehrung/model";
/**
 * Mit dieser Komponente kann der Nutzer die Inhalte einer Untersuchung ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt nur die allgemeinen Informationen des befischten Gewässers dar.
 * Dazu gehören unter Anderem physikalische und chemische Werte des Gewässers.
 */
@Component({
    selector: "form-fischinfo-untersuchung-gewaesser-allgemein",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungGewaesserAllgemein implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get gewaesserbreite(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .gewaesserbreite;
        return value
            && String(value).replace(".", ",");
    }
    public set gewaesserbreite(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .gewaesserbreite = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get gewaesserflaeche(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .gewaesserflaeche;
        return value
            && String(value).replace(".", ",");
    }
    public set gewaesserflaeche(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .gewaesserflaeche = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get gewaessertiefe(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .gewaessertiefe;
        return value
            && String(value).replace(".", ",");
    }
    public set gewaessertiefe(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .gewaessertiefe = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get wassertemperatur(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .wassertemperatur;
        return value
            && String(value).replace(".", ",");
    }
    public set wassertemperatur(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .wassertemperatur = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get leitfaehigkeit(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .leitfaehigkeit;
        return value
            && String(value).replace(".", ",");
    }
    public set leitfaehigkeit(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .leitfaehigkeit = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get ph(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .ph;
        return value
            && String(value).replace(".", ",");
    }
    public set ph(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .ph = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get truebung(): boolean {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .truebung;
    }
    public set truebung(value: boolean) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .truebung = value;
    }
    public get wasserstand_id(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .wasserstand_id;
    }
    public set wasserstand_id(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .wasserstand_id = parseInt(String(value), 10) || null;
    }
    public get stroemung_id(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .stroemung_id;
    }
    public set stroemung_id(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .stroemung_id = parseInt(String(value), 10) || null;
    }
    public get linienfuehrung_id(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .linienfuehrung_id;
    }
    public set linienfuehrung_id(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .linienfuehrung_id = parseInt(String(value), 10) || null;
    }
    public get angebunden(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .angebunden;
    }
    public set angebunden(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .angebunden = value
            ? 1
            : 0;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    public wasserstand_idRefJsonItems: string;
    public stroemung_idRefJsonItems: string;
    public linienfuehrung_idRefJsonItems: string;
    ngOnInit() {
        const a = this.dataService
            .getReferenceTable("wasserstand", FischinfoWasserstand, "id");
        const b = this.dataService
            .getReferenceTable("stroemung", FischinfoStroemung, "id");
        const c = this.dataService
            .getReferenceTable("linienfuehrung", FischinfoLinienfuehrung, "id");
        return Promise.all<{
            [key: string]: FischinfoWasserstand | FischinfoStroemung | FischinfoLinienfuehrung;
        }>([a, b, c])
            .then(refs => {
            [0, 1, 2].map(x => {
                let refList = [], entityKey;
                Object.keys(refs[x]).forEach(key => {
                    entityKey = key;
                    refList.push('[' + refs[x][key].id + ',"' + refs[x][key].bezeichnung + '"]');
                });
                refs[x] && refs[x][entityKey] instanceof FischinfoWasserstand
                    && (this.wasserstand_idRefJsonItems = "[" + refList.join(",") + "]");
                refs[x] && refs[x][entityKey] instanceof FischinfoStroemung
                    && (this.stroemung_idRefJsonItems = "[" + refList.join(",") + "]");
                refs[x] && refs[x][entityKey] instanceof FischinfoLinienfuehrung
                    && (this.linienfuehrung_idRefJsonItems = "[" + refList.join(",") + "]");
            });
        });
    }
}
