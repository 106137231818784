import { Component, Input, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoMethode from "../../models/fischinfo/methode/model";
/**
 * Mit dieser Komponente kann der Nutzer die Informationen zum Befischungsgerät Reuse ansehen und ggf. bearbeiten.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-methode-reuse",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungMethodeReuse {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public set anzahlreusen(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anzahlreusen = parseInt(String(value), 10) || null;
    }
    public get anzahlreusen(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anzahlreusen;
    }
    public set reusenarten(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .reusenarten = value;
    }
    public get reusenarten(): string {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .reusenarten;
    }
    public set expositionsdauer(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .expositionsdauer = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get expositionsdauer(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .expositionsdauer;
        return value
            && String(value).replace(".", ",");
    }
    public set maschenweiten(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .maschenweiten = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get maschenweiten(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .maschenweiten;
        return value
            && String(value).replace(".", ",");
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService) {
    }
}
