import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { take } from "rxjs/operators";
import { detect as detectEncoding } from "jschardet";
import DataService from "../data/service";
import ModalService from "../modal/service";
import UserService from "../user/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import FischinfoMethode from "../../models/fischinfo/methode/model";
import FischinfoHaeufigkeitbefischungergebnis from "../../models/fischinfo/haeufigkeitbefischungergebnis/model";
import FischinfoLaengenklassenbefischungergebnis from "../../models/fischinfo/laengenklassenbefischungergebnis/model";
import FischinfoKrebsuntersuchung from "../../models/fischinfo/krebsuntersuchung/model";
import FischinfoHaeufigkeitkrebsergebnis from "../../models/fischinfo/haeufigkeitkrebsergebnis/model";
import FischinfoLaengenklassenkrebsergebnis from "../../models/fischinfo/laengenklassenkrebsergebnis/model";
import FischinfoMuscheluntersuchung from "../../models/fischinfo/muscheluntersuchung/model";
import FischinfoHaeufigkeitsmuschelergebnis from "../../models/fischinfo/haeufigkeitsmuschelergebnis/model";
import FischinfoLaengenklassenmuschelergebnis from "../../models/fischinfo/laengenklassenmuschelergebnis/model";
import FischinfoFischart from "../../models/fischinfo/fischart/model";
import FischinfoMuschelart from "../../models/fischinfo/muschelart/model";
import FischinfoKrebsart from "../../models/fischinfo/krebsart/model";
@Component({
    selector: "uw-modal-window-tab-befischungimport",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowTabBefischungimport {
    @Input()
    public modal: any;
    @Input()
    public set topmargin(value: string) {
        this.topMarginPx = String(Number(value) - (this.userService.roles.includes("ROLE_ADMIN")
            ? 0
            : Number(this.buttonPanelHeight)));
    }
    ;
    public get topmargin(): string {
        return this.topMarginPx;
    }
    ;
    public buttonPanelHeight: string = "24";
    public topMarginPx: string;
    constructor(public readonly modalService: ModalService, public readonly dataService: DataService, public readonly userService: UserService, public readonly http: HttpClient) {
        this.topmargin = "0";
        this.artMaps = this.getArtMaps();
    }
    public running: boolean = false;
    public error: any = null;
    public progress: {
        step: number;
        total: number;
    } = null;
    public failedImports: {
        shortdesc?: string;
        error: any;
        item: FischinfoUntersuchung;
    }[] = [];
    public async getArtMaps() {
        const reindexByBezeichnung = <T extends {
            bezeichnung: string;
        }>(rec: Record<string, T>) => {
            const mapped = {} as Record<string, T>;
            for (const o of Object.values(rec)) {
                mapped[o.bezeichnung] = o;
            }
            return mapped;
        };
        const maps = await Promise.all([
            this.dataService.getReferenceTable("fischart", FischinfoFischart, "id").then(reindexByBezeichnung),
            this.dataService.getReferenceTable("muschelart", FischinfoMuschelart, "id").then(reindexByBezeichnung),
            this.dataService.getReferenceTable("krebsart", FischinfoKrebsart, "id").then(reindexByBezeichnung),
        ]) as [
            Record<string, FischinfoFischart>,
            Record<string, FischinfoMuschelart>,
            Record<string, FischinfoKrebsart>
        ];
        return maps;
    }
    public artMaps: Promise<[
        Record<string, FischinfoFischart>,
        Record<string, FischinfoMuschelart>,
        Record<string, FischinfoKrebsart>
    ]> = null;
    public async importCsv(file: File) {
        this.failedImports = [];
        try {
            this.error = null;
            this.running = true;
            this.modal.data.loaded = 0;
            const indexSeed = new Map<Function, Map<any, any>>((await this.artMaps)
                .map(index => Array.from(Object.entries(index)))
                .map(entries => [
                entries[0][1].constructor,
                new Map<string, any>(entries),
            ] as [
                Function,
                Map<string, any>
            ]));
            const data = Array.from(this.readCsvAsObject(await this.loadContent(file)))
                .reduce<Map<Function, Map<any, any>>>((indexes, row, index) => {
                try {
                    return this.accumulateLineObjects(indexes, row);
                }
                catch (e) {
                    throw this.addLineToInvalidFormatError(e, index + 1);
                }
            }, indexSeed)
                .get(FischinfoUntersuchung);
            if (!data || data.size === 0) {
                throw this.createInvalidFormatError("Keine Daten zum Import vorhanden");
            }
            await this.requestImport(Array.from(data.values()));
            this.modal.data.loaded = 1;
        }
        catch (e) {
            console.error(e);
            this.error = e;
        }
        finally {
            this.running = false;
            this.modal.data.loaded = 1;
        }
        this.dataService.loadDataStore();
    }
    public ifReaderIsDone(ev: ProgressEvent, ondone: (result: string | ArrayBuffer) => void, onerror: (error: any) => void) {
        const fr = ev.target as FileReader;
        if (fr.readyState === FileReader.DONE) {
            const { result } = fr;
            try {
                ondone(result);
            }
            catch (e) {
                onerror(e);
            }
        }
    }
    public setupReader(fr: FileReader, ondone: (result: string | ArrayBuffer) => void, onerror: (error: any) => void) {
        fr.onload = (ev: ProgressEvent) => this.ifReaderIsDone(ev, ondone, onerror);
        fr.onerror = onerror;
        return fr;
    }
    public async loadContent(file: File) {
        const fr = new FileReader();
        // for the encoding assertion a Binary string is needed
        // ... so we read once as BinaryString and twice as Text
        await new Promise<void>((assertResolve, assertReject) => {
            this.setupReader(fr, result => (this.assertUtf8BinaryString(result as string), assertResolve()), assertReject)
                .readAsBinaryString(file);
        });
        return await new Promise<string>((stringResolve, stringReject) => {
            this.setupReader(fr, stringResolve as (_: string | ArrayBuffer) => void, stringReject).readAsText(file);
        });
    }
    public *lines(text: string) {
        for (let i = -1; (i = (text = text.slice(i + 1)).indexOf("\n", 1)) != -1;) {
            yield text.slice(0, i);
        }
        if (text.length)
            yield text;
    }
    public *readCsvAsObject(text: string) {
        const lines = this.lines(text);
        const cols = this.getCsvColumnsDescriptors();
        let i = 0;
        for (let line: string | void = undefined; !({ value: line! } = lines.next()).done; ++i) {
            line = line as string;
            line = line.trim();
            if (i === 0) {
                const expectHeader = cols.map(c => c.head).join(";");
                if (line !== expectHeader) {
                    throw this.createInvalidFormatError("CSV-Datei kann nicht verarbeitet werden. Bitte prüfen Sie Trennzeichen (';') und Spaltennamen.");
                }
            }
            else {
                try {
                    yield this.parseCsvLine(line, cols);
                }
                catch (e) {
                    throw this.createInvalidFormatError((e as Error).message, (i + 1));
                }
            }
        }
    }
    public parseCsvLine(line: string, parsers: {
        head: string;
        parse(cell: string): any;
    }[]) {
        const cells = line.split(";");
        if (cells.length !== parsers.length) {
            throw new RangeError("Unerwartete Spaltenanzahl (" + cells.length + ") der CSV-Datei.");
        }
        const map = cells.reduce((o, c, i) => (o[parsers[i].head] = parsers[i].parse(c), o), {});
        return map;
    }
    public getErgebnisType(rowObj: any) {
        const isset = (n: number) => n != null && n > 0;
        let type = 0;
        if (isset(rowObj.haeufigkeit)) {
            type = type | 1;
        }
        if (isset(rowObj.anzahl_0_5) || isset(rowObj.anzahl_5_10) || isset(rowObj.anzahl_0_10)
            || isset(rowObj.anzahl_10_15) || isset(rowObj.anzahl_15_20) || isset(rowObj.anzahl_10_20)
            || isset(rowObj.anzahl_20_25) || isset(rowObj.anzahl_25_30) || isset(rowObj.anzahl_20_30)
            || isset(rowObj.anzahl_30_40) || isset(rowObj.anzahl_40_50) || isset(rowObj.anzahl_50_60)
            || isset(rowObj.anzahl_60_70) || isset(rowObj.anzahl_70)) {
            type = type | 2;
        }
        if (type === 1)
            return "haeufigkeit";
        if (type === 2)
            return "laengenklasse";
        if (type > 0)
            throw this.createInvalidFormatError("Uneindeutiger Ergebnistyp");
        if (type === 0)
            throw this.createInvalidFormatError("Fehlende Ergebnisdaten");
    }
    public assertUtf8BinaryString(str: string) {
        const enc = detectEncoding(str).encoding;
        if (enc.toUpperCase() !== "UTF-8") {
            throw this.createInvalidFormatError(`Unerwartete Text-Kodierung der CSV-Datei (UTF-8 erwartet): ${enc}`);
        }
        return str;
    }
    public assertValidMethode(methode: FischinfoMethode) {
        if (methode.elektrobefischungsmethode_id !== 1
            && (methode.befischtelaenge != null || methode.befischtebreite != null)) {
            throw this.createInvalidFormatError("Ohne Streckenbefischung (ebefischungsmethode = 1) sind 'befischtelaenge' und 'befischtebreite' nicht zugelassen.");
        }
        return methode;
    }
    public assertKeyIsntIncluded<T, TKey>(list: T[], selectKey: (item: T) => TKey, searchKey: TKey) {
        const index = list.map(selectKey).indexOf(searchKey);
        if (index !== -1) {
            const existent = list[index];
            throw this.createInvalidFormatError(`${existent.constructor.name} mit Schlüssel "${searchKey}" doppelt vorhanden.`);
        }
        return list;
    }
    public assertOutdatedAnzahlFieldsAreUnset<T extends Partial<Record<"anzahl_0_10" | "anzahl_10_20" | "anzahl_20_30", number>>>(obj: T) {
        if ((obj.anzahl_0_10 || 0) > 0
            || (obj.anzahl_10_20 || 0) > 0
            || (obj.anzahl_20_30 || 0) > 0) {
            throw this.createInvalidFormatError("Felder 'anzahl_0_10', 'anzahl_10_20' und 'anzahl_20_30' sind nicht zugelassen.");
        }
        return obj;
    }
    public assertAnzahlFieldsOver30AreUnset<T extends Partial<Record<"anzahl_30_40" | "anzahl_40_50" | "anzahl_50_60" | "anzahl_60_70" | "anzahl_70", number>>>(obj: T) {
        if ((obj.anzahl_30_40 || 0) > 0
            || (obj.anzahl_40_50 || 0) > 0
            || (obj.anzahl_50_60 || 0) > 0
            || (obj.anzahl_60_70 || 0) > 0
            || (obj.anzahl_70 || 0) > 0) {
            throw this.createInvalidFormatError("Felder 'anzahl_30_40', 'anzahl_40_50', 'anzahl_50_60', 'anzahl_60_70' und 'anzahl_70' sind nicht zugelassen");
        }
        return obj;
    }
    public getArtFromIndexes<T>(ctor: new (...as: any[]) => T, bezeichnung: string, indexes: Map<Function, Map<any, any>>) {
        const index = indexes.get(ctor) as Map<string, T>;
        const obj = index.get(bezeichnung);
        if (obj == null) {
            throw this.createInvalidFormatError("Art mit Bezeichnung '" + bezeichnung + "' nicht erkannt.");
        }
        return obj;
    }
    public addFischartToUntersuchung(u: FischinfoUntersuchung, rowObj: any, indexes: Map<Function, Map<any, any>>) {
        const bef = (u.fischinfoBefischungListByForeignUntersuchung_id || [])[0]
            || (u.fischinfoBefischungListByForeignUntersuchung_id = [
                new FischinfoBefischung({
                    bemerkung: rowObj.bemerkung,
                    beobachtung: false,
                    erfasst: true,
                    fischinfoGewaesserzustandByGewaesserzustand_id: new FischinfoGewaesserzustand({
                        gewaesserbreite: rowObj.gewaesserbreite,
                        gewaesserflaeche: rowObj.gewaesserflaeche,
                        gewaessertiefe: rowObj.gewaessertiefe,
                    }),
                    fischinfoMethodeByMethode_id: this.assertValidMethode(new FischinfoMethode({
                        befischungsgeraet_id: 1,
                        elektrobefischungsmethode_id: rowObj.ebefischungsmethode,
                        befischtebreite: rowObj.befischtebreite,
                        befischtelaenge: rowObj.befischtelaenge,
                        beschreibung: rowObj.methode_beschreibung,
                    })),
                }),
            ])[0];
        const erg = this.getErgebnisType(rowObj);
        const fischart = this.getArtFromIndexes(FischinfoFischart, rowObj.fischart, indexes);
        if (erg === "haeufigkeit") {
            this.assertKeyIsntIncluded(bef.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id
                || (bef.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id = []), hfe => hfe.art_id, fischart.id).push(new FischinfoHaeufigkeitbefischungergebnis({
                art_id: fischart.id,
                haeufigkeit_id: rowObj.haeufigkeit,
            }));
        }
        else {
            this.assertKeyIsntIncluded(bef.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id
                || (bef.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id = []), lfe => lfe.art_id, fischart.id).push(new FischinfoLaengenklassenbefischungergebnis({
                art_id: fischart.id,
                anzahl_0_5: rowObj.anzahl_0_5,
                anzahl_5_10: rowObj.anzahl_5_10,
                anzahl_0_10: rowObj.anzahl_0_10,
                anzahl_10_15: rowObj.anzahl_10_15,
                anzahl_15_20: rowObj.anzahl_15_20,
                anzahl_10_20: rowObj.anzahl_10_20,
                anzahl_20_25: rowObj.anzahl_20_25,
                anzahl_25_30: rowObj.anzahl_25_30,
                anzahl_20_30: rowObj.anzahl_20_30,
                anzahl_30_40: rowObj.anzahl_30_40,
                anzahl_40_50: rowObj.anzahl_40_50,
                anzahl_50_60: rowObj.anzahl_50_60,
                anzahl_60_70: rowObj.anzahl_60_70,
                anzahl_70: rowObj.anzahl_70,
                anzahljungfische: rowObj.n_jungfisch,
            }));
        }
        return u;
    }
    public addKrebsartToUntersuchung(u: FischinfoUntersuchung, rowObj: any, indexes: Map<Function, Map<any, any>>) {
        const kun = (u.fischinfoKrebsuntersuchungListByForeignUntersuchung_id || [])[0]
            || (u.fischinfoKrebsuntersuchungListByForeignUntersuchung_id = [
                new FischinfoKrebsuntersuchung({})
            ])[0];
        const erg = this.getErgebnisType(rowObj);
        const krebsart = this.getArtFromIndexes(FischinfoKrebsart, rowObj.krebsart, indexes);
        this.assertOutdatedAnzahlFieldsAreUnset(rowObj);
        this.assertAnzahlFieldsOver30AreUnset(rowObj);
        if (erg === "haeufigkeit") {
            this.assertKeyIsntIncluded(kun.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id
                || (kun.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id = []), hke => hke.art_id, krebsart.id).push(new FischinfoHaeufigkeitkrebsergebnis({
                art_id: krebsart.id,
                krebshaeufigkeit_id: rowObj.haeufigkeit,
            }));
        }
        else {
            this.assertKeyIsntIncluded(kun.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id
                || (kun.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id = []), lke => lke.krebsart_id, krebsart.id).push(new FischinfoLaengenklassenkrebsergebnis({
                krebsart_id: krebsart.id,
                anzahl_0_5: rowObj.anzahl_0_5,
                anzahl_5_10: rowObj.anzahl_5_10,
                anzahl_10_15: rowObj.anzahl_10_15,
                anzahl_15_20: rowObj.anzahl_15_20,
                anzahl_20_25: rowObj.anzahl_20_25,
                anzahl_25_30: rowObj.anzahl_25_30,
            }));
        }
        return u;
    }
    public addMuschelartToUntersuchung(u: FischinfoUntersuchung, rowObj: any, indexes: Map<Function, Map<any, any>>) {
        const mun = (u.fischinfoMuscheluntersuchungListByForeignUntersuchung_id || [])[0]
            || (u.fischinfoMuscheluntersuchungListByForeignUntersuchung_id = [
                new FischinfoMuscheluntersuchung({})
            ])[0];
        const erg = this.getErgebnisType(rowObj);
        const muschelart = this.getArtFromIndexes(FischinfoMuschelart, rowObj.muschelart, indexes);
        this.assertOutdatedAnzahlFieldsAreUnset(rowObj);
        this.assertAnzahlFieldsOver30AreUnset(rowObj);
        if (erg === "haeufigkeit") {
            this.assertKeyIsntIncluded(mun.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id
                || (mun.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id = []), hme => hme.art_id, muschelart.id).push(new FischinfoHaeufigkeitsmuschelergebnis({
                art_id: muschelart.id,
                muschelhaeufigkeit_id: rowObj.haeufigkeit,
            }));
        }
        else {
            this.assertKeyIsntIncluded(mun.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id
                || (mun.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id = []), lme => lme.muschelart_id, muschelart.id).push(new FischinfoLaengenklassenmuschelergebnis({
                muschelart_id: muschelart.id,
                anzahl_0_5: rowObj.anzahl_0_5,
                anzahl_5_10: rowObj.anzahl_5_10,
                anzahl_10_15: rowObj.anzahl_10_15,
                anzahl_15_20: rowObj.anzahl_15_20,
                anzahl_20_25: rowObj.anzahl_20_25,
                anzahl_25_30: rowObj.anzahl_25_30,
            }));
        }
        return u;
    }
    public accumulateLineObjects(indexes: Map<Function, Map<any, any>>, rowObj: any) {
        const uSet: Map<string, FischinfoUntersuchung> = indexes.get(FischinfoUntersuchung)
            || indexes.set(FischinfoUntersuchung, new Map<string, FischinfoUntersuchung>()).get(FischinfoUntersuchung);
        const uStrKey = [rowObj.probestellennr, rowObj.datum].join(":");
        const u = uSet.get(uStrKey)
            || uSet.set(uStrKey, new FischinfoUntersuchung({
                untersuchungstermin: rowObj.datum,
                bearbeiter: rowObj.bearbeiter,
                schluesselwort: rowObj.schluesselwort,
                freigegeben: true,
                fischinfoProbenahmestelleByProbenahmestelle_id: new FischinfoProbenahmestelle({
                    probestellennr: rowObj.probestellennr,
                }),
            })).get(uStrKey);
        const artfield = Array.of<"fischart" | "krebsart" | "muschelart">("fischart", "krebsart", "muschelart").reduce((f, k) => {
            if (rowObj[k] != null) {
                if (f != null)
                    throw this.createInvalidFormatError("Mehrere Arten angegeben.");
                return k;
            }
            return f;
        }, null);
        if (artfield == null) {
            throw this.createInvalidFormatError("Keine Art angegeben.");
        }
        const adder: (this: ModalWindowTabBefischungimport, u: FischinfoUntersuchung, row: any, indexes: Map<Function, Map<any, any>>) => FischinfoUntersuchung = this[`add${artfield[0].toUpperCase() + artfield.slice(1)}ToUntersuchung`];
        if (typeof adder === "function") {
            adder.call(this, u, rowObj, indexes);
        }
        return indexes;
    }
    public createInvalidFormatError(desc: string, line?: number | undefined) {
        const err = Object.assign(new Error("Format-Fehler: " + desc), { name: "INVALID_FORMAT" as "INVALID_FORMAT" });
        if (line != undefined) {
            this.addLineToInvalidFormatError(err, line);
        }
        return err;
    }
    public addLineToInvalidFormatError(err: any, line: number) {
        if (err instanceof Error && err.name === "INVALID_FORMAT") {
            err.message = `CSV-Zeile ${line.toFixed(0)}, ` + err.message;
        }
        return err;
    }
    public async requestImport(data: FischinfoUntersuchung[]) {
        // await this.userService.whenUserSetup;
        const token = await this.dataService.getLoginTokenPromise();
        let idx = -1;
        const failedImports = [];
        this.failedImports = [];
        this.progress = { step: 0, total: data.length };
        for (const item of data) {
            try {
                await this.http
                    .post<void>(this.dataService.transcribeURL(this.dataService.webServiceApiListUrl, { folder: "untersuchung" }, 1), item, { headers: { "X-Auth-Token": token } })
                    .pipe(take(1)).toPromise();
            }
            catch (e) {
                item.untersuchungstermin += new Date(item.untersuchungstermin).getTimezoneOffset() * 60000;
                console.error(e);
                const shortdesc = e instanceof HttpErrorResponse
                    ? (e.error || "").split("\n", 1)[0].split(": ", 2).slice(-1)[0]
                    : undefined;
                failedImports.push({ shortdesc, error: e, item: item });
            }
            this.progress = { step: ++idx, total: data.length };
        }
        this.failedImports = failedImports;
    }
    public getCsvColumnsDescriptors() {
        const isnotInteger = (n: number) => Number.isNaN(n) || !Number.isInteger(n);
        const nullIfEmpty = <T extends {
            length: number;
        }>(val: T) => val != null && val.length === 0 ? null : val;
        const allowEmptyAsNull = <T extends {
            length: number;
        }, U>(parse: (src: T) => U) => (val: T) => val != null && val.length === 0 ? null : parse(val);
        const parseDate = (src: string) => {
            const tup = src.split(/\.| |:/g).map(n => Number.parseInt(n));
            if ((tup.length !== 5 && tup.length !== 3) || tup.some(isnotInteger)) {
                throw new RangeError(`Unbekanntes Zeitformat '${src}'.`);
            }
            [tup[0], tup[2]] = [tup[2], tup[0]];
            tup[1] -= 1;
            return Date.UTC.apply(Date, tup as [number, number, number] | [number, number, number, number, number]);
        };
        const parseString = (src: string) => nullIfEmpty(JSON.parse(`"${src.replace(/"/g, (_, i) => i === 0 || i === src.length - 1 ? "" : "\\\"")}"`));
        const parseNatInclZero = (src: string) => {
            const num = Number.parseInt(src);
            if (isnotInteger(num) || num < 0) {
                throw new RangeError(`Fehlerhafte natürliche Zahl '${src}'.`);
            }
            return num;
        };
        const parseNatInclZeroOrNull = allowEmptyAsNull(parseNatInclZero);
        const parseFloat = (src: string) => {
            const num = Number.parseFloat(src.replace(".", "").replace(",", "."));
            if (Number.isNaN(num) || !Number.isFinite(num)) {
                throw new RangeError(`Fehlerhafte Fließkomma-Zahl '${src}'.`);
            }
            return num;
        };
        const parseFloatOrNull = allowEmptyAsNull(parseFloat);
        const parseEnum = (name: string, values: number[], src: string) => {
            const num = Number.parseInt(src);
            if (values.indexOf(num) === -1) {
                throw new RangeError(`Unerwarteter Wert '${num}' für ${name} (${values}).`);
            }
            return num;
        };
        return Array.of<{
            head: string;
            parse: (src: string) => any;
        }>({ head: "probestellennr", parse: parseString }, { head: "datum", parse: parseDate }, { head: "schluesselwort", parse: parseString }, { head: "bearbeiter", parse: parseString }, { head: "bemerkung", parse: parseString }, { head: "fischart", parse: parseString }, { head: "krebsart", parse: parseString }, { head: "muschelart", parse: parseString }, { head: "haeufigkeit", parse: allowEmptyAsNull(parseEnum.bind(undefined, "haeufigkeit", [1, 2, 3, 4, 5])) }, { head: "n_jungfisch", parse: parseNatInclZeroOrNull }, { head: "anzahl_0_5", parse: parseNatInclZeroOrNull }, { head: "anzahl_5_10", parse: parseNatInclZeroOrNull }, { head: "anzahl_0_10", parse: parseNatInclZeroOrNull }, { head: "anzahl_10_15", parse: parseNatInclZeroOrNull }, { head: "anzahl_15_20", parse: parseNatInclZeroOrNull }, { head: "anzahl_10_20", parse: parseNatInclZeroOrNull }, { head: "anzahl_20_25", parse: parseNatInclZeroOrNull }, { head: "anzahl_25_30", parse: parseNatInclZeroOrNull }, { head: "anzahl_20_30", parse: parseNatInclZeroOrNull }, { head: "anzahl_30_40", parse: parseNatInclZeroOrNull }, { head: "anzahl_40_50", parse: parseNatInclZeroOrNull }, { head: "anzahl_50_60", parse: parseNatInclZeroOrNull }, { head: "anzahl_60_70", parse: parseNatInclZeroOrNull }, { head: "anzahl_70", parse: parseNatInclZeroOrNull }, { head: "methode_beschreibung", parse: parseString }, { head: "ebefischungsmethode", parse: parseEnum.bind(undefined, "ebefischungsmethode", [1, 3]) }, { head: "befischtelaenge", parse: parseFloatOrNull }, { head: "befischtebreite", parse: parseFloatOrNull }, { head: "gewaesserbreite", parse: parseFloatOrNull }, { head: "gewaesserflaeche", parse: parseFloatOrNull }, { head: "gewaessertiefe", parse: parseFloatOrNull });
    }
}
