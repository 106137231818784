<div class="uw-virtual-table-header">
  <table>
    <thead>
      <tr>
        <th 
            *ngFor="let column of columns" 
            (click)="onClickHeaderColumn($event)"
            [attr.width]="column.columnPercentualWidth + '%'"
            [attr.title]="column.label || column.name" 
            [attr.data-sort-by]="column.sortBy || column.name" 
            [attr.data-sort-type]="column.sortType || 'string'" 
            [style.text-align]="column.align"
            [attr.class]="column.cssClass"
        >{{column.label || column.name}}
            <i 
                *ngIf="selectedSortColumn === (column.sortBy || column.name) && selectedSortOrder === 'desc'"
                class="fa fa-sort-up"
                title="Nach Spalte sortieren"
            ></i>
            <i 
                *ngIf="selectedSortColumn === (column.sortBy || column.name) && selectedSortOrder === 'asc'"
                class="fa fa-sort-down"
                title="Nach Spalte Sortieren"
            ></i>
        </th>
      </tr>
    </thead>
  </table>
</div>
<div
    #table
    [style.height.px]="-virtualRowHeight+height"
    class="uw-virtual-table-body"
    (scroll)="onScrollContainer($event)"
>
    <div
        [style.height.px]="virtualHeight"
    >
    <table>
        <thead>
            <tr>
                <th 
                    *ngFor="let column of columns" 
                    [style.height.px]="virtualPaddingTop"
                    [attr.width]="column.columnPercentualWidth + '%'"
                    [attr.class]="column.cssClass"
                ><span> </span></th>
            </tr>
        </thead>
        <tbody>
           <tr 
            *ngFor="let item of virtualPageItems" 
            [class.odd]="item.__id % 2"
            (click)="onClickItem($event, item)"
            [class.selected]="item.id === selectedId[dataService.folder]"
          >
            <td 
                *ngFor="let column of columns"
                [style.text-align]="column.align"
                [title]="item[column.name]"
                [attr.class]="column.cssClass"
            >{{item[column.name]}}</td>
          </tr>
        </tbody>
    </table>
  </div>
</div>
