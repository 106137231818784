import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoSubstratprozent from "./interface";
import FischinfoGewaesserzustand from "./../../fischinfo/gewaesserzustand/model";
import FischinfoSubstrat from "./../../fischinfo/substrat/model";
@fn.DataModel
export default class FischinfoSubstratprozent extends DataModel implements IFischinfoSubstratprozent {
    constructor(obj?: object | IFischinfoSubstratprozent) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoSubstratprozent): FischinfoSubstratprozent {
        return new FischinfoSubstratprozent(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoSubstratprozent): FischinfoSubstratprozent {
        Object.assign(this, obj);
        obj && obj["fischinfoGewaesserzustandByGewaesserzustand_id"] && (this.fischinfoGewaesserzustandByGewaesserzustand_id = FischinfoGewaesserzustand.deserialize(obj["fischinfoGewaesserzustandByGewaesserzustand_id"]));
        obj && obj["fischinfoSubstratBySubstrat_id"] && (this.fischinfoSubstratBySubstrat_id = FischinfoSubstrat.deserialize(obj["fischinfoSubstratBySubstrat_id"]));
        return this;
    }

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "anteil", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public anteil: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gewaesserzustand_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public gewaesserzustand_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "substrat_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public substrat_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoGewaesserzustand}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandByGewaesserzustand_id", type: FischinfoGewaesserzustand, nullable: true, memberType: "JOIN", joinProperty: "gewaesserzustand_id" })
    public fischinfoGewaesserzustandByGewaesserzustand_id?: FischinfoGewaesserzustand;

    /**
     * @type {FischinfoSubstrat}
     */
    @fn.DataMember({ name: "fischinfoSubstratBySubstrat_id", type: FischinfoSubstrat, nullable: true, memberType: "JOIN", joinProperty: "substrat_id" })
    public fischinfoSubstratBySubstrat_id?: FischinfoSubstrat;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
