import { Component, Input, OnInit, Optional } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoFibsresult from "../../models/fischinfo/fibsresult/model";
import FischinfoArtenabundanzleitartscore from "../../models/fischinfo/artenabundanzleitartscore/model";
import FischinfoAltersstrukturleitartscore from "../../models/fischinfo/altersstrukturleitartscore/model";
import DataService from "../data/service";
import ModalService from "../modal/service";
import { UWTab } from "ng-uw-tabs";
/**
 * Mit dieser Komponente kann der Nutzer die Ergebnisse der FIBS-Bewertung einer Untersuchung ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt nur die Ergebnisse der FIBS-Bewertung für Heavily Modified Water Bodies (HMWB) dar.
 * Dazu gehören unter Anderem die Bewertungen der Qualitätmerkmale und Parameter, die Bewertungen des Arten- und Gildeninventars
 * sowie Bewertungen der Leitarten.
 * Der Nutzer kann zudem eine plausibilisierte Zustandsklasse zusammen mit Datum und Begründung ansehen und bearbeiten.
 */
@Component({
    selector: "form-fischinfo-untersuchung-fibs-hmwb",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungFibsHmwb implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get fibsresult(): FischinfoFibsresult {
        return this.formFischinfoUntersuchungService.modelUtil
            .getOrDefault<FischinfoFibsresult>(this.untersuchung, "FischinfoFibsresultHMWB");
    }
    public artenabundanzList: Array<FischinfoArtenabundanzleitartscore>;
    public nullplusanteileList: Array<FischinfoAltersstrukturleitartscore>;
    public readonly novalue: string = "k.A.";
    public readonly zustandsklasseNames: {
        [zk: number]: string;
    } = Object.freeze({
        "1": "sehr gut",
        "2": "gut",
        "3": "mäßig",
        "4": "unbefriedigend",
        "5": "schlecht",
        "-999": "N/A",
    });
    public readonly zustandsklasseListJSON: string = JSON.stringify([
        { id: 1, term: "1 – sehr gut" },
        { id: 2, term: "2 – gut" },
        { id: 3, term: "3 – mäßig" },
        { id: 4, term: "4 – unbefriedigend" },
        { id: 5, term: "5 – schlecht" },
    ]);
    public constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService, public modalService: ModalService,
        @Optional()
        public uwtab: UWTab) {
    }
    public ngOnInit() {
        this.resetDataReferences();
        this.fibsVersion = this.formFischinfoUntersuchungService
            .fibsVersion().then(ver => ["(", ver, ")"].join(""));
    }
    public resetDataReferences() {
        type HasArtname = {
            artname?: string;
        };
        const cmpByArtname = (a: HasArtname, b: HasArtname) => a.artname.localeCompare(b.artname);
        this.artenabundanzList = (this.fibsresult.fischinfoArtenabundanzleitartscoreListByForeignFibsresult_id || []).sort(cmpByArtname);
        this.nullplusanteileList = (this.fibsresult.fischinfoAltersstrukturleitartscoreListByForeignFibsresult_id || []).sort(cmpByArtname);
    }
    public get inActiveTab() {
        return !!this.uwtab && this.uwtab.active;
    }
    public get fibsCalculatable() {
        return !this.readonly && this.untersuchung.fibsgeeignet;
    }
    public fibsVersion: Promise<string>;
    public get potenzial(): string {
        const zk = this.fibsresult.potenzial;
        return zk && ((zk === -999 ? this.zustandsklasseNames[zk] : zk)
            + (this.zustandsklasseNames[zk] ? " – " + this.zustandsklasseNames[zk] : ""));
    }
    public _datum_zustandsklasse_expert: Date;
    public get datum_zustandsklasse_expert() {
        if (typeof this._datum_zustandsklasse_expert === "undefined") {
            this._datum_zustandsklasse_expert = this.fibsresult.datum_zustandsklasse_expert
                && new Date(this.fibsresult.datum_zustandsklasse_expert);
        }
        return this._datum_zustandsklasse_expert;
    }
    public set datum_zustandsklasse_expert(value: Date) {
        this._datum_zustandsklasse_expert = value;
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoFibsresult>(this.untersuchung, "FischinfoFibsresultHMWB")
            .datum_zustandsklasse_expert = value ? value.getTime() : null;
    }
    public get begruendung_zustandsklasse_expert() {
        return this.fibsresult.begruendung_zustandsklasse_expert;
    }
    public set begruendung_zustandsklasse_expert(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoFibsresult>(this.untersuchung, "FischinfoFibsresultHMWB")
            .begruendung_zustandsklasse_expert = value;
    }
    public get zustandsklasse_expert() {
        return this.fibsresult.zustandsklasse_expert;
    }
    public set zustandsklasse_expert(value: number) {
        if ((value === null || typeof value === "number")
            && this.zustandsklasse_expert !== value) {
            this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoFibsresult>(this.untersuchung, "FischinfoFibsresultHMWB")
                .zustandsklasse_expert = value;
            if (value === null) {
                this.datum_zustandsklasse_expert = null;
                this.begruendung_zustandsklasse_expert = null;
            }
            else {
                this.datum_zustandsklasse_expert = new Date();
            }
        }
    }
    public label(field: string): string {
        return this.formFischinfoUntersuchungService.label("FischinfoFibsresult", field);
    }
    public novalueOr<T = any, U = T>(val: T, map?: ((val: T) => U) | ((val: T) => T)) {
        map = map || (x => x);
        return Number(val) === -999 ? this.zustandsklasseNames["-999"] : val != null ? map(val) : this.novalue;
    }
    public novalueOrTruncatedNumber(val: number) {
        const truncnum = (n: number) => {
            let s = n.toString();
            const d = s.indexOf(".");
            if (d >= 0) {
                if (s.length - d - 1 > 2) {
                    s = n.toFixed(2);
                }
                s = s.slice(0, d) + "," + s.slice(d + 1);
            }
            return s;
        };
        return this.novalueOr(val, truncnum);
    }
    public async calculateFibsResult() {
        let data = this.modalService.getModalOption(this.modalId, "data") || {};
        if (data.loaded) {
            this.modalService.setModalOption(this.modalId, "data", Object.assign(data, { loaded: false }));
            try {
                // Die befischung_id kann durch ein Speichern geaendert sein, untersuchung.id bleibt bestehen
                const befischung_id = await this.dataService.get<FischinfoBefischung>("untersuchung", FischinfoBefischung, String(this.untersuchung.id), null, this.dataService.webServiceApiUrl + "{folder}/{id}/befischung?limit=1&format=application/json")
                    .then(befischung => { return (befischung && befischung.id) || 0; }) || 0;
                const fibsresults = !befischung_id || befischung_id === 0
                    ? null
                    : (await this.dataService.getAll(this.dataService.transcribeURL(this.dataService.webServiceApiListUrl.replace("?", "/{id}?"), Object.assign({
                        folder: "fibscalculator",
                        id: befischung_id,
                        limit: 1000,
                    }, this.dataService.filter)), FischinfoFibsresult));
                fibsresults
                    && fibsresults.map((fibsresult: FischinfoFibsresult) => {
                        let fibsresultInForm = fibsresult.nwb
                            ? this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoFibsresult>(this.untersuchung, "FischinfoFibsresultNWB")
                            : this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoFibsresult>(this.untersuchung, "FischinfoFibsresultHMWB");
                        return Object.assign(fibsresult, new FischinfoFibsresult({
                            begruendung_zustandsklasse_expert: fibsresultInForm.begruendung_zustandsklasse_expert,
                            datum_zustandsklasse_expert: fibsresultInForm.datum_zustandsklasse_expert,
                            zustandsklasse_expert: fibsresultInForm.zustandsklasse_expert,
                            befischung_id: befischung_id
                        }));
                    });
                this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
                    .fischinfoFibsresultListByForeignBefischung_id = fibsresults;
                this.resetDataReferences();
            }
            finally {
                this.modalService.setModalOption(this.modalId, "data", Object.assign(data, { loaded: true }));
            }
        }
    }
    public async downloadFibsResult() {
        return await this.dataService.saveFibsResult(this.untersuchung.id, false);
    }
}
