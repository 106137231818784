import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import FormFischinfoProbenahmestelleService from "./service";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import DataService from "../data/service";
import ModalService from "../modal/service";
import DataModel from "../../models/model";
import UserService from "../user/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FibsHoep from "../../models/fibs/hoep/model";
import FischinfoStillgewaessertyp from "../../models/fischinfo/stillgewaessertyp/model";
/**
 * Über dieses Formular kann der Nutzer eine Probenahmestelle barbeiten.
 *
 * Das Bearbeiten beschränkt sich auf die direkten Felder der Probenahmestelle.
 * `ModalWindowTabProbenahmestelle` steuert das Anlegen und Löschen von Untersuchungen.
 */
@Component({
    selector: "form-fischinfo-probenahmestelle",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoProbenahmestelle implements OnInit {
    @Input()
    public modalId: string;
    @Output()
    stillgewaesserChanged: EventEmitter<any> = new EventEmitter();
    @Output()
    anzahlUntersuchungenChanged: EventEmitter<any> = new EventEmitter();
    public _probenahmestelle: FischinfoProbenahmestelle;
    public get probenahmestelle(): FischinfoProbenahmestelle {
        return this._probenahmestelle;
    }
    public _untersuchungListFiltered: FischinfoUntersuchung[] = [];
    public _untersuchungListUnfiltered: FischinfoUntersuchung[] = [];
    public untersuchungListLoading: boolean;
    public get untersuchungList(): FischinfoUntersuchung[] {
        return this.readonly
            ? this._untersuchungListFiltered
            : this._untersuchungListUnfiltered;
    }
    @Input()
    public set probenahmestelle(item: FischinfoProbenahmestelle) {
        const finishFunc = () => {
            setTimeout(() => {
                this.anzahlUntersuchungenChanged.emit(this._untersuchungListUnfiltered ? this._untersuchungListUnfiltered.length : 0);
                this.untersuchungListLoading = false;
            }, 0);
        }, filter = Object.assign(JSON.parse(JSON.stringify(this.dataService.filter)) || {}, {
            gewaessertyp: "",
            gewkz: "",
            ofwk: "",
            fischgewtyp: "",
            fibshoep: "",
            shape: "",
            bbox: "",
            geomrefname: "",
            geomrefnameid: "",
            geomrefnameschema: "",
            geomrefnametable: "",
            geomrefvalueid: "",
        });
        this._probenahmestelle = item;
        this._untersuchungListFiltered = this.formFischinfoProbenahmestelleService.calcUntersuchungenList(this.probenahmestelle && this.probenahmestelle.fischinfoUntersuchungListByForeignProbenahmestelle_id, this.isExtendedView);
        if (this.isExtendedView) {
            this.dataService.get<FibsHoep>("probenahmestelle", FibsHoep, String(this.probenahmestelle.id), null, this.dataService.webServiceApiUrl + "{folder}/{id}/hoep?format=application/json")
                .then(response => {
                this.probenahmestelle.fischhoep = response
                    ? response.bezeichnung
                    : null;
            });
            this.untersuchungListLoading = true;
            this.dataService.get<FischinfoProbenahmestelle>("probenahmestelle", FischinfoProbenahmestelle, String(this.probenahmestelle.id), null, null, true, filter)
                .then((probenahmestelle) => {
                this._untersuchungListUnfiltered = this.formFischinfoProbenahmestelleService.calcUntersuchungenList(probenahmestelle && probenahmestelle.fischinfoUntersuchungListByForeignProbenahmestelle_id, this.isExtendedView);
                finishFunc();
            })
                .catch(finishFunc);
        }
    }
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get hasExtendedAdminRightToEdit(): boolean {
        const roleFilter = (x: string): boolean => x == "ROLE_ADMIN";
        return this.userService.roles
            && this.userService.roles.filter(roleFilter).length
            ? true
            : false;
    }
    constructor(public formFischinfoProbenahmestelleService: FormFischinfoProbenahmestelleService, public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService, public userService: UserService, public modalService: ModalService) {
    }
    public gewaessertyp_idRefJsonItems: string;
    public ngOnInit() {
        this.dataService
            .getReferenceTable("stillgewaessertyp", FischinfoStillgewaessertyp, "id")
            .then(refs => {
            let refList = [];
            Object.keys(refs).forEach(key => {
                refList.push('[' + refs[key].id + ',"' + refs[key].bezeichnung + '"]');
            });
            this.gewaessertyp_idRefJsonItems = "[" + refList.join(",") + "]";
        });
    }
    public get isExtendedView(): boolean {
        return this.userService.isLoggedIn === true;
    }
    public prevoiusStillgewaessertyp_id;
    public onStillgewaesserChanged(e: Event) {
        if (!this.probenahmestelle.stillgewaesser) {
            this.prevoiusStillgewaessertyp_id = this.probenahmestelle.stillgewaessertyp_id;
            this.probenahmestelle.stillgewaessertyp_id = null;
        }
        else {
            this.probenahmestelle.stillgewaessertyp_id = this.prevoiusStillgewaessertyp_id;
        }
        this.stillgewaesserChanged.emit(true);
    }
    public onClickItem(event, item): boolean {
        let data = this.modalService.getModalOption(this.modalId, "data") || {};
        data.loaded = 0;
        data.untersuchungToBeLoaded = item;
        data.untersuchung = null;
        data.activeTab = "untersuchung";
        data.mode = null;
        this.modalService.setModalOption(this.modalId, "data", data);
        this.dataService.get("untersuchung", item.constructor as typeof DataModel, (item as any).id)
            .then(item => {
            let data = this.modalService.getModalOption(this.modalId, "data") || {};
            data.loaded = 1;
            data.untersuchung = item;
            this.modalService.setModalOption(this.modalId, "data", data);
            this.modalService.setModalOption(this.modalId, "status", "");
        })
            .catch(error => console.error(error));
        return true;
    }
}
