<div class="row">
    <div class="col-xs-15 col-sm-6"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','expositionsdauer')}}"
        [(value)]="expositionsdauer"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-6"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','anzahlreusen')}}"
        [(value)]="anzahlreusen"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-15"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','reusenarten')}}"
        [(value)]="reusenarten"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-6"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','maschenweiten')}}"
        [(value)]="maschenweiten"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>