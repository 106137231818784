<div class="uw-map-draw-control">
    <div
        title="Zeichenwerkzeuge ein-/ausblenden"
        [class.active]="mapEditorService.toolbarIsOpen"
        (click)="toggleToolbar()"
    >
        <i
            *ngIf="!vanitizing"
            class="fa fa-edit"
        ></i>
        <i
            *ngIf="vanitizing"
            class="fa fa-spinner fa-spin"
        ></i>
    </div>
    <ng-container
        *ngIf="mapEditorService.toolbarIsOpen"
    >
        <div
            *ngIf="!vanitizing"
            class="sub"
            (click)="zoom()"
            [class.active]="mapEditorService.isZoomable"
            title="Gebiet heranzoomen"
        >
            <i class="fa fa-search"></i>
        </div>
        <div
            *ngIf="measureTool==='true'"
            (click)="toggleEdit('measurePolyline')"
            class="sub"
            [class.active]="true"
            [class.hover]="editMode==='measurePolyline'"
            title="Eine Entfernung messen"
        >
            <i class="fa fa-arrows-h"></i>
        </div>
        <div
            *ngIf="!vanitizing"
            (click)="toggleEdit('drawPoint')"
            class="sub"
            [class.active]="mapEditorService.isEditable && point==='true'"
            [class.hover]="editMode==='drawPoint' && point==='true'"
            title="Einen Punkt zum Gebiet hinzufügen"
        >
            <i class="fa fa-main-attributed icon-punkt"></i>
            <i class="fa fa-attr fa-plus"></i>
        </div>
        <div
            *ngIf="!vanitizing"
            (click)="toggleEdit('drawPolyline')"
            class="sub"
            [class.active]="mapEditorService.isEditable && polyline==='true'"
            [class.hover]="editMode==='drawPolyline' && polyline==='true'"
            title="Eine Linie zum Gebiet hinzufügen"
        >
            <i class="fa fa-main-attributed icon-linie"></i>
            <i class="fa fa-attr fa-plus"></i>
        </div>
        <div
            *ngIf="!vanitizing"
            (click)="toggleEdit('drawPolygon')"
            class="sub"
            [class.active]="mapEditorService.isEditable && polygon==='true'"
            [class.hover]="editMode==='drawPolygon' && polygon==='true'"
            title="Eine Fläche zum Gebiet hinzufügen"
        >
            <i class="fa fa-main-attributed icon-polygon"></i>
            <i class="fa fa-attr fa fa-plus"></i>
        </div>
        <div 
            class="sub"
            *ngIf="shapesUpload==='true'"
            [class.hide]="vanitizing"
        >
            <uw-shapefile-input
                iconified="true"
                [disabled]="!mapEditorService.isEditable"
                (change)="onChangeShapeInput($event)"
            ></uw-shapefile-input>
        </div>
        <div
            *ngIf="!vanitizing"
            (click)="toggleSelect()"
            class="sub"
            [class.active]="mapEditorService.isEditable"
            [class.hover]="editMode==='select'"
            title="Eine Geometrie des Gebietes auswählen und nachbearbeiten"
        >
            <i class="fa fa-main-attributed fa-mouse-pointer"></i>
            <i class="fa fa-attr icon-punkt"></i>
        </div>
        <div
            *ngIf="!vanitizing"
            (click)="toggleEdit('cutPolygon')"
            class="sub"
            [class.active]="mapEditorService.isEditable && polygon==='true'"
            [class.hover]="editMode==='cutPolygon' && polygon==='true'"
            title="Eine einzuzeichnende Fläche abziehen"
        >
            <i class="fa fa-main-attributed fa-scissors"></i>
            <i class="fa fa-attr fa fa-minus"></i>
        </div>
        <div
            *ngIf="!vanitizing"
            (click)="toggleRemove()"
            class="sub"
            [class.active]="mapEditorService.isEditable"
            [class.hover]="editMode==='remove'"
            title="Eine Geometrie des Gebietes durch Auswahl entfernen"
        >
            <i class="fa fa-main-attributed fa-eraser"></i>
            <i class="fa fa-attr fa-minus"></i>
        </div>
        <div
            *ngIf="!vanitizing"
            (click)="walkFeatureLayerGroupHistory(-1)"
            class="sub"
            [class.active]="historyBackwardsFlag"
            title="Bearbeitungsschritt zurück"
        >
            <i class="fa fa-main fa-mail-reply"></i>
        </div>
        <div
            *ngIf="!vanitizing"
            (click)="walkFeatureLayerGroupHistory(1)"
            class="sub"
            [class.active]="historyForwardFlag"
            title="Bearbeitungsschritt vor"
        >
            <i class="fa fa-main fa-mail-forward"></i>
        </div>
        <div
            *ngIf="measureTool==='true'"
            (click)="onClickCancel()"
            class="sub"
            [class.active]="editMode==='measurePolyline'"
            title="Bearbeitung abbrechen"
        >
            <i class="fa fa-main fa-remove"></i>
        </div>
        <div
            *ngIf="!vanitizing"
            (click)="save()"
            class="sub"
            [class.active]="mapEditorService.isEditable"
            title="Änderungen am Gebiet speichern"
        >
            <i class="fa fa-main fa-save"></i>
        </div>
    </ng-container>
    <div
        (click)="toggleToolbar()"
        class="last active" 
        title="Zeichenwerkzeuge ein-/ausblenden"
    >
        <i
            [class.fa-chevron-left]="mapEditorService.toolbarIsOpen"
            [class.fa-chevron-right]="!mapEditorService.toolbarIsOpen"
            class="fa"
        ></i>
    </div>
</div>
