<div class="row">
    <div class="col-xs-15 col-sm-3 disabled"><uw-textinput
        label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle','probestellennrAsString')}}"
        [value]="probenahmestelle.probestellennrAsString"
        [disabled]="true"
    ></uw-textinput></div>
    <div
        class="col-xs-15 col-sm-3 checkbox"
        [class.disabled]="!readonly && !hasExtendedAdminRightToEdit"
    >
        <uw-checkbox
            [label]="formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle','freigegeben')"
            [(value)]="probenahmestelle.freigegeben"
            [disabled]="readonly || !hasExtendedAdminRightToEdit"
            labelAt="e"
        ></uw-checkbox>
    </div>
    <div *ngIf="isExtendedView" class="col-xs-15 col-sm-9 disabled"><uw-textinput
        label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle','gemeindeNameGkz')}}"
        value="{{probenahmestelle?.gemeindeNameGkz}}"
        [disabled]="true"
    ></uw-textinput></div>
</div>
<div *ngIf="!isExtendedView" class="row">
    <div class="col-xs-15 col-sm-9 disabled">
        <uw-textinput
            label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle','gewaessernameAsString')}}"
            [value]="probenahmestelle.gewaessernameAsString"
            [disabled]="true"
        ></uw-textinput>
    </div>
    <div class="col-xs-15 col-sm-3 disabled">
        <uw-textinput
            label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle','gewaesserkennzahlAsNumber')}}"
            [value]="probenahmestelle.gewaesserkennzahlAsNumber"
            [disabled]="true"
        ></uw-textinput>
    </div>
</div>
<div *ngIf="isExtendedView" class="row">
    <div class="col-xs-15 col-sm-6 disabled">
        <uw-textinput
            label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle','gewaesserNameGkz')}}"
            [value]="probenahmestelle.gewaesserNameGkz"
            [disabled]="true"
        ></uw-textinput>
    </div>
    <div class="col-xs-15 col-sm-3 disabled">
        <uw-textinput
            label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle','stationierung')}}"
            [value]="probenahmestelle.stationierung"
            [disabled]="true"
        ></uw-textinput>
    </div>
    <div class="col-xs-15 col-sm-6 disabled">
        <uw-textinput
            label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle','vorfluterNameGkz')}}"
            [value]="probenahmestelle.vorfluterNameGkz"
            [disabled]="true"
        ></uw-textinput>
    </div>
</div>
<div *ngIf="isExtendedView" class="row">
    <div class="col-xs-15">
        <uw-textinput
            label="Gewässeralias"
            [(value)]="probenahmestelle.gewaesseralias"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <!--
        <div class="col-xs-15 col-sm-6" >
            <label>Eingabe abgeschlossen</label>
            <br/>
            <uw-checkbox
                [(value)]="probenahmestelle.abgeschlossen"
                [disabled]="readonly"
            ></uw-checkbox>
        </div>
    -->
</div>
<div class="row">
    <div class="col-xs-15 col-sm-15 disabled"><uw-textinput
        label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle','utm32koordinatenAsString')}}"
        [value]="probenahmestelle.utm32koordinatenAsString"
        [disabled]="true"
    ></uw-textinput></div>
</div>
<ng-container *ngIf="isExtendedView">
    <div class="row">
        <div class="col-xs-15">
            <uw-textarea
                label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle', 'lagebeschreibung')}}"
                [(value)]="probenahmestelle.lagebeschreibung"
                [disabled]="readonly"
            ></uw-textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-15 col-sm-6">
            <label>Fließ- oder Stillgewässer</label>
            <uw-radiobutton-group
                [disabled]="readonly"
                [(value)]="probenahmestelle.stillgewaesser"
                (change)="onStillgewaesserChanged($event)"
            >
                <uw-radiobutton
                    [value]="false"
                    label="Fließgewässer"
                    labelAt="e"
                ></uw-radiobutton>
                <uw-radiobutton
                    [value]="true"
                    label="Stillgewässer"
                    labelAt="e"
                ></uw-radiobutton>
            </uw-radiobutton-group>
        </div>
        <div
            [class]="probenahmestelle.stillgewaesser ? 'col-xs-15 col-xs-9' : 'col-xs-15 col-xs-9 disabled'"
        >
            <label>Stillgewässertyp</label>
            <uw-select
                [disabled]="readonly || !probenahmestelle.stillgewaesser"
                *ngIf="gewaessertyp_idRefJsonItems"
                [jsonItems]="gewaessertyp_idRefJsonItems"
                keyValueMapping="0:1"
                size="10"
                [key]="probenahmestelle.stillgewaessertyp_id"
                (keyChange)="probenahmestelle.stillgewaessertyp_id = $event === '' ? null : $event"
                nullable="true"
            ></uw-select>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-15 disabled">
            <uw-textinput
                label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle', 'fischgewaessertypName')}}"
                [value]="probenahmestelle.fischgewaessertypName"
                [disabled]="true"
            ></uw-textinput>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-15 col-sm-4 disabled">
            <uw-textinput
                label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle', 'fischhoep')}}"
                [value]="probenahmestelle.fischhoep"
                [disabled]="true"
            ></uw-textinput>
        </div>
        <div class="col-xs-15 col-sm-3 disabled">
            <uw-textinput
                label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle', 'hmwbnutzungKuerzel')}}"
                [value]="probenahmestelle.hmwbnutzungKuerzel"
                [title]="probenahmestelle.hmwbnutzungKuerzel"
                [disabled]="true"
            ></uw-textinput>
        </div>
        <div class="col-xs-15 col-sm-4">
            <uw-textinput
                label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle', 'guesnr')}}"
                [(value)]="probenahmestelle.guesnr"
                [disabled]="readonly"
            ></uw-textinput>
        </div>
        <div class="col-xs-15 col-sm-4 disabled">
            <uw-textinput
                label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle', 'ofwkAusweisung')}}"
                [value]="probenahmestelle.ofwkAusweisung"
                [disabled]="true"
            ></uw-textinput>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-15 col-sm-9 disabled">
            <uw-textinput
                label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle', 'n2000KennBezeich')}}"
                [value]="probenahmestelle.n2000KennBezeich"
                [disabled]="true"
            ></uw-textinput>
        </div>
        <div class="col-xs-15 col-sm-6 disabled">
            <uw-textinput
                label="{{formFischinfoProbenahmestelleService.label('FischinfoProbenahmestelle', 'ofwkBezeichnung')}}"
                [value]="probenahmestelle.ofwkBezeichnung"
                [disabled]="true"
            ></uw-textinput>
        </div>
    </div>
</ng-container>
<div class="row" style="margin-top:14px;">
    <div class="col-xs-15">
        <label>Untersuchungen</label>
        <table class="table table-striped untersuchungList">
            <thead *ngIf="!isExtendedView">
                <tr>
                    <th [style.width.px]="120" title="Datum">Datum</th>
                    <th title="Erfassungs-Nr.">Erfassungs-Nr.</th>
                </tr>
            </thead>
            <thead *ngIf="isExtendedView">
                <tr>
                    <th [style.width.px]="100" title="Datum">Datum</th>
                    <th [style.width.%]="15" title="Erfassungs-Nr.">Erfassungs-Nr.</th>
                    <th title="Schlüsselwort">Schlüsselwort</th>
                    <th [style.width.%]="15" title="Untersuchung">Untersuchung</th>
                    <th [style.width.%]="10" title="Unsichere Daten">Unsicher</th>
                </tr>
            </thead>
            <tbody *ngIf="!untersuchungListLoading && (!untersuchungList || !untersuchungList.length)">
                <tr><td attr.colspan="{{isExtendedView ? 5 : 2}}">Keine Untersuchungen</td></tr>
            </tbody>
            <tbody *ngIf="untersuchungListLoading && (!untersuchungList || !untersuchungList.length)">
                <tr><td attr.colspan="{{isExtendedView ? 5 : 2}}"></td></tr>
            </tbody>
            <tbody *ngIf="untersuchungList && untersuchungList.length">
                <ng-container *ngIf="!isExtendedView">
                    <tr
                        *ngFor="let u of untersuchungList"
                        class="hover"
                        (click)="onClickItem($event, u)"
                    >
                        <td style="padding: 0 2px 0 0;"><uw-inline-button
                            iconClass="fa"
                            title="{{u.untersuchungsterminAsLocaleDateString}}"
                            value="{{u.untersuchungsterminAsLocaleDateString}}"
                            stopPropagation="false"
                        ></uw-inline-button></td>
                        <td [title]="u.erfassungsNr">{{u.erfassungsNr}}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="isExtendedView">
                    <tr
                        *ngFor="let u of untersuchungList"
                        class="hover"
                        (click)="onClickItem($event, u)"
                    >
                        <td style="padding: 0 2px 0 0;"><uw-inline-button
                            iconClass="fa"
                            title="{{u.untersuchungsterminAsLocaleDateString}}"
                            value="{{u.untersuchungsterminAsLocaleDateString}}"
                            (click)="onClickItem($event, u)"
                        ></uw-inline-button></td>
                        <td [title]="u.erfassungsNr">{{u.erfassungsNr}}</td>
                        <td [title]="u.schluesselwort">{{u.schluesselwort}}</td>
                        <td [title]="u.untersuchung">{{u.untersuchung}}</td>
                        <td [title]="u.unsicherAsString">{{u.unsicherAsString}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
