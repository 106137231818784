<div class="row">
    <div class="col-xs-15">
        <label>Allgemeine Angaben Untersuchung</label>
    </div>
</div>
<div class="row">
    <div class="col-xs-15 col-sm-3"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoUntersuchung','untersuchungstermin')}}"
        [value]="untersuchung.untersuchungsterminAsLocaleDateString"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-4"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoUntersuchung','untersuchung')}}"
        [value]="untersuchung.untersuchung"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-4"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoUntersuchung','methode')}}"
        [value]="untersuchung.methode"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-4"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoUntersuchung','befischteLaenge')}}"
        [value]="untersuchung.befischteLaenge"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>
<div class="row">
    <div class="col-xs-15">
        <div class="table-responsive">
            <label>Untersuchungsergebnisse</label>
            <table class="table table-striped table-condensed table-sm untersuchungList">
                <thead>
                    <tr>
                        <th>Artname</th>
                        <!-- <th [style.text-align]="'center'">Häufigkeit</th> //-->
                        <th 
                            [style.text-align]="'center'"
                            [style.width.px]="100"
                        >Summe</th>
                        <ng-container *ngFor="let einheit of skalaKeys">
                            <th
                                *ngIf="skala[einheit].set"
                                [style.text-align]="'center'"
                                [style.width.px]="38"
                            >{{skala[einheit].label}}</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody
                    *ngIf="!untersuchungsergebnisList || !untersuchungsergebnisList.length;"
                >
                        <tr><td colspan="2">Keine Untersuchungsergebnisse</td></tr>
                    </tbody>
                <tbody
                    *ngIf="untersuchungsergebnisList && untersuchungsergebnisList.length"
                >
                    <tr
                        *ngFor="let u of untersuchungsergebnisList"
                    >
                        <td>{{u.art}}</td>
                        <!-- <td [style.text-align]="'center'">{{u.haeufigkeit}}</td> //-->
                        <td [style.text-align]="'center'">{{u.summe}}</td>
                        <ng-container *ngFor="let einheit of skalaKeys">
                            <td
                                *ngIf="skala[einheit].set"
                                [style.text-align]="'center'"
                            >{{u[einheit]}}</td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
