<div
    class="row"
    style="padding-top:19px"
    *ngIf="substratprozentList?.length > 0"
>
    <div class="col-xs-15">
        <table class="table table-striped substratprozentList inherit-inputs">
            <thead>
                <tr>
                    <th [style.width.%]="60" title="Untergrund / Substrat">Untergrund / Substrat</th>
                    <th></th>
                    <th [style.width.px]="140" title="Anteil (in %)">Anteil (in %)</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let u of substratprozentList"
                >
                    <td [title]="u.bezeichnung">{{u.bezeichnung}}</td>
                    <td></td>
                    <td style="padding:0;"><uw-textinput
                        [(value)]="u.anteil"
                        [disabled]="readonly"
                        (valueChange)="onChangeSubstratprozent($event, u)"
                    ></uw-textinput></td>
                </tr>
                <tr>
                <td></td>
                <td><label>Summe</label></td>
                <td>{{gesamtProzent}} %</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
<div
    class="row"
    *ngIf="!substratprozentList?.length"
    style="padding-top:10px;"
>
    <div class="col-xs-15"><uw-textinput
        label="Untergrund / Substrat"
        value=""
        disabled="true"
    ></uw-textinput></div>
</div>