<div
    class="row"
>
    <div class="col-xs-15">
        <label>
            FIBS-Bewertungen
        </label>
    </div>
</div>
<div 
    *ngIf="dataService.isLoading"
    class="row"
>
    <div class="col-sm-15">
        <p>
            Bitte warten Sie bis die Ergebnistabellen vollständig geladen sind…
        </p>
    </div>
</div>
<div
    *ngIf="!dataService.isLoading && !fibsresultsGenerating"
    class="row"
>
    <div class="col-sm-15">
        <label>Berechnung der FIBS-Bewertung für insgesamt {{untersuchungCount}} gefilterte Untersuchungen</label>
    </div>
    <ng-container *ngIf="!calcConfirmation">
        <div class="col-sm-15">
            <uw-inline-button
                iconClass="fa fa-calculator float-right"
                value="FIBS-Bewertungen {{fibsVersion | async}} berechnen"
                title="FIBS-Bewertungen {{fibsVersion | async}} berechnen oder aktualisieren"
                (click)="confirmCalculation()"
                class="inline-btn-nobrk"
            ></uw-inline-button>
        </div>
        <div class="col-sm-15">
            <label>Durch betätigen der Schaltfläche wird für alle gefilterten Befischungen in der Tabelle die FIBS-Bewertung neu berechnet. Bitte führen Sie die Funktion sparsam aus, da die Berechnung, abhängig der Anzahl der Befischungen, sehr zeitintensiv ist.</label>
        </div>
    </ng-container>

    <ng-container *ngIf="calcConfirmation">
        <div class="col-sm-15">
            <p>FIBS in {{untersuchungCount}} Untersuchungen überschreiben?</p>
        </div>
        <div class="col-sm-7dot5">
            <uw-inline-button
                iconClass="fa fa-check float-right"
                value="Ja, überschreiben"
                title="Ja, überschreiben"
                (click)="calcConfirmation.proceed()"
                class="inline-btn-nobrk"
            ></uw-inline-button>
        </div>
        <div class="col-sm-7dot5">
            <uw-inline-button
                iconClass="fa fa-times float-right"
                value="Nein, Abbrechen"
                title="Nein, Abbrechen"
                (click)="calcConfirmation.abort()"
                class="inline-btn-nobrk"
            ></uw-inline-button>
        </div>
    </ng-container>
</div>
<div
    *ngIf="!dataService.isLoading && fibsresultsGenerating"
    class="row"
>
    <div class="col-sm-15">
        <label>FIBS-Bewertungen werden berechnet</label>
    </div>
    <div class="col-sm-15">
        FIBS-Bewertungen sind durch einen Nutzer beauftragt und werden berechnet. Bitte warten Sie bis die Bewertungen vollständig berechnet sind…
        <div class="progress">
            <div class="progress-bar" style="width: 0.0000%; min-width:80px;"
                [style.width]="fibsresultsProgress.toFixed(6) + '%'"
            >{{fibsresultsProgressInt}} %</div>
        </div>
    </div>
</div>