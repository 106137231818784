import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoFischart from "../../models/fischinfo/fischart/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoDetailbefischungergebnis from "../../models/fischinfo/detailbefischungergebnis/model";
import FischinfoDetailmuschelergebnis from "../../models/fischinfo/detailmuschelergebnis/model";
import FischinfoDetailkrebsergebnis from "../../models/fischinfo/detailkrebsergebnis/model";
import FischinfoMuschelart from "../../models/fischinfo/muschelart/model";
import FischinfoKrebsart from "../../models/fischinfo/krebsart/model";
import FischinfoLaengenklassenbefischungergebnis from "../../models/fischinfo/laengenklassenbefischungergebnis/model";
import FischinfoLaengenklassenmuschelergebnis from "../../models/fischinfo/laengenklassenmuschelergebnis/model";
import FischinfoLaengenklassenkrebsergebnis from "../../models/fischinfo/laengenklassenkrebsergebnis/model";
import FischinfoNachweisbefischungergebnis from "../../models/fischinfo/nachweisbefischungergebnis/model";
import FischinfoNachweismuschelergebnis from "../../models/fischinfo/nachweismuschelergebnis/model";
import FischinfoNachweiskrebsergebnis from "../../models/fischinfo/nachweiskrebsergebnis/model";
import FischinfoAnzahlbefischungergebnis from "../../models/fischinfo/anzahlbefischungergebnis/model";
import FischinfoAnzahlmuschelergebnis from "../../models/fischinfo/anzahlmuschelergebnis/model";
import FischinfoAnzahlkrebsergebnis from "../../models/fischinfo/anzahlkrebsergebnis/model";
import FischinfoHaeufigkeitbefischungergebnis from "../../models/fischinfo/haeufigkeitbefischungergebnis/model";
import FischinfoHaeufigkeitsmuschelergebnis from "../../models/fischinfo/haeufigkeitsmuschelergebnis/model";
import FischinfoHaeufigkeitkrebsergebnis from "../../models/fischinfo/haeufigkeitkrebsergebnis/model";
import FischinfoMuscheluntersuchung from "../../models/fischinfo/muscheluntersuchung/model";
import FischinfoKrebsuntersuchung from "../../models/fischinfo/krebsuntersuchung/model";
import DataService from "../data/service";
import ModelUtil from "./model-util";

export default class ViewUtil {
    constructor(
        protected dataService: DataService,
        protected modelUtil: ModelUtil
    ) {
    }

    public skala = {
        "anzahl_0_5": { label: "<5", set: false },
        "anzahl_5_10": { label: "<10", set: false },
        "anzahl_0_10": { label: "0-10", set: false },
        "anzahl_10_15": { label: "<15", set: false },
        "anzahl_15_20": { label: "<20", set: false },
        "anzahl_10_20": { label: "10-20", set: false },
        "anzahl_20_25": { label: "<25", set: false },
        "anzahl_25_30": { label: "<30", set: false },
        "anzahl_20_30": { label: "20-30", set: false },
        "anzahl_30_40": { label: "<40", set: false },
        "anzahl_40_50": { label: "<50", set: false },
        "anzahl_50_60": { label: "<60", set: false },
        "anzahl_60_70": { label: "<70", set: false },
        "anzahl_70": { label: ">70", set: false },
    };

    public get skalaKeys(): string[] {
        return Object.keys(this.skala);
    }

    public getNormalizedBefischungsergebnisseList(untersuchung: FischinfoUntersuchung): Promise<any[]> {

        const a = this.dataService
            .getReferenceTable("fischart", FischinfoFischart, "id")
            ;
        const b = this.dataService
            .getReferenceTable("muschelart", FischinfoMuschelart, "id")
            ;
        const c = this.dataService
            .getReferenceTable("krebsart", FischinfoKrebsart, "id")
            ;

        return Promise.all([a, b, c])
            .then(
                ([fischartRef, muschelartRef, krebsartRef]) => {
                    let list: any[] = [];

                    const fischinfoBefischungList = this.modelUtil.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                    const fischinfoMuscheluntersuchungList = this.modelUtil.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung");
                    const fischinfoKrebsuntersuchungList = this.modelUtil.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung");

                    // #region Detail
                    const detailSkala = {
                        0: "anzahl_0_5",
                        1: "anzahl_5_10",
                        2: "anzahl_10_15",
                        3: "anzahl_15_20",
                        4: "anzahl_20_25",
                        5: "anzahl_25_30",
                        6: "anzahl_30_40",
                        7: "anzahl_30_40",
                        8: "anzahl_40_50",
                        9: "anzahl_40_50",
                        10: "anzahl_50_60",
                        11: "anzahl_50_60",
                        12: "anzahl_60_70",
                        13: "anzahl_60_70",
                        14: "anzahl_70"
                    };
                    const redundanteArtenListe =
                        Array.of<FischinfoDetailbefischungergebnis | FischinfoDetailmuschelergebnis | FischinfoDetailkrebsergebnis>(
                            ...(fischinfoBefischungList.fischinfoDetailbefischungergebnisListByForeignBefischung_id || []),
                            ...(fischinfoMuscheluntersuchungList.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id || []),
                            ...(fischinfoKrebsuntersuchungList.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        )
                            .filter(x => !(x instanceof FischinfoDetailbefischungergebnis && x.jungfisch))
                            .map(x => {
                                const artId = x instanceof FischinfoDetailbefischungergebnis
                                    ? x.art_id.toString()
                                    : x instanceof FischinfoDetailmuschelergebnis
                                        ? x.muschelart_id.toString()
                                        : x.krebsart_id.toString()
                                    ;
                                const einheit = detailSkala[(Math.min(14, Math.floor(
                                    (
                                        x instanceof FischinfoDetailkrebsergebnis
                                            ? x.groessecm
                                            : x.laengecm
                                    )
                                    / 5))) || 0];
                                x["art"] = x instanceof FischinfoDetailbefischungergebnis
                                    ? fischartRef[artId]
                                        ? fischartRef[artId].bezeichnung
                                        : "N.N. (" + artId + ")"
                                    : x instanceof FischinfoDetailmuschelergebnis
                                        ? muschelartRef[artId]
                                            ? muschelartRef[artId].bezeichnung
                                            : "N.N. (" + artId + ")"
                                        : krebsartRef[artId]
                                            ? krebsartRef[artId].bezeichnung
                                            : "N.N. (" + artId + ")"
                                    ;
                                x["haeufigkeit"] = "";
                                x["untersuchungstyp"] = "Individuen";
                                x["summe"] = x.anzahl || 0;
                                x["gruppe"] = x instanceof FischinfoDetailbefischungergebnis
                                    ? "Fische"
                                    : x instanceof FischinfoDetailmuschelergebnis
                                        ? "Muscheln"
                                        : "Krebse"
                                    ;
                                x[einheit] = x.anzahl;
                                this.skala[einheit].set = this.skala[einheit].set || x[einheit];
                                return x;
                            }) || [];

                    redundanteArtenListe.length && redundanteArtenListe.forEach(redundanteArt => {
                        const redundanteArtId = redundanteArt instanceof FischinfoDetailbefischungergebnis
                            ? redundanteArt.art_id.toString()
                            : redundanteArt instanceof FischinfoDetailmuschelergebnis
                                ? redundanteArt.muschelart_id.toString()
                                : redundanteArt.krebsart_id.toString()
                            ;
                        const redundanteArtType = redundanteArt instanceof FischinfoDetailbefischungergebnis
                            ? "Fische"
                            : redundanteArt instanceof FischinfoDetailmuschelergebnis
                                ? "Muscheln"
                                : "Krebse"
                            ;
                        let art: any = null;
                        if (list.some(x => {
                            return (art = (
                                (redundanteArtType === "Fische" && x.gruppe === redundanteArtType && x.art_id.toString() === redundanteArtId.toString())
                                || (redundanteArtType === "Muscheln" && x.gruppe === redundanteArtType && x.muschelart_id.toString() === redundanteArtId.toString())
                                || (redundanteArtType === "Krebse" && x.gruppe === redundanteArtType && x.krebsart_id.toString() === redundanteArtId.toString())
                            ) ? x : art) !== null;
                        })) {
                            art.summe += redundanteArt["summe"];
                            this.skalaKeys.forEach(einheit => {
                                if (redundanteArt[einheit]) {
                                    art[einheit] = art[einheit]
                                        ? art[einheit] + redundanteArt[einheit]
                                        : redundanteArt[einheit]
                                        ;
                                }
                            });
                        } else {
                            list.push(redundanteArt);
                        }
                    });

                    list.push(
                        ...
                        Array.of<FischinfoLaengenklassenbefischungergebnis | FischinfoLaengenklassenmuschelergebnis | FischinfoLaengenklassenkrebsergebnis>(
                            ...(fischinfoBefischungList.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id || []),
                            ...(fischinfoMuscheluntersuchungList.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id || []),
                            ...(fischinfoKrebsuntersuchungList.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        )
                            .map(x => {
                                const artId = x instanceof FischinfoLaengenklassenbefischungergebnis
                                    ? x.art_id.toString()
                                    : x instanceof FischinfoLaengenklassenmuschelergebnis
                                        ? x.muschelart_id.toString()
                                        : x.krebsart_id.toString()
                                    ;
                                x["art"] = x instanceof FischinfoLaengenklassenbefischungergebnis
                                    ? fischartRef[artId]
                                        ? fischartRef[artId].bezeichnung
                                        : "N.N. (" + artId + ")"
                                    : x instanceof FischinfoLaengenklassenmuschelergebnis
                                        ? muschelartRef[artId]
                                            ? muschelartRef[artId].bezeichnung
                                            : "N.N. (" + artId + ")"
                                        : krebsartRef[artId]
                                            ? krebsartRef[artId].bezeichnung
                                            : "N.N. (" + artId + ")"
                                    ;
                                x["haeufigkeit"] = "";
                                x["untersuchungstyp"] = "Längenklassen";
                                x["summe"] = 0;
                                x["gruppe"] = x instanceof FischinfoLaengenklassenbefischungergebnis
                                    ? "Fische"
                                    : x instanceof FischinfoLaengenklassenmuschelergebnis
                                        ? "Muscheln"
                                        : "Krebse"
                                    ;
                                this.skalaKeys.forEach(einheit => {
                                    x["summe"] += x[einheit] || 0;
                                    this.skala[einheit].set = this.skala[einheit].set || x[einheit] || 0;
                                });
                                return x;
                            })

                    );
                    // #endregion

                    // #region Nachweis
                    list.push(
                        ...
                        Array.of<FischinfoNachweisbefischungergebnis | FischinfoNachweismuschelergebnis | FischinfoNachweiskrebsergebnis>(
                            ...(fischinfoBefischungList.fischinfoNachweisbefischungergebnisListByForeignBefischung_id || []),
                            ...(fischinfoMuscheluntersuchungList.fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id || []),
                            ...(fischinfoKrebsuntersuchungList.fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        )
                            .map(x => {
                                const artId = x instanceof FischinfoNachweisbefischungergebnis
                                    ? x.art_id.toString()
                                    : x instanceof FischinfoNachweismuschelergebnis
                                        ? x.muschelart_id.toString()
                                        : x.krebsart_id.toString()
                                    ;
                                x["art"] = x instanceof FischinfoNachweisbefischungergebnis
                                    ? fischartRef[artId]
                                        ? fischartRef[artId].bezeichnung
                                        : "N.N. (" + artId + ")"
                                    : x instanceof FischinfoNachweismuschelergebnis
                                        ? muschelartRef[artId]
                                            ? muschelartRef[artId].bezeichnung
                                            : "N.N. (" + artId + ")"
                                        : krebsartRef[artId]
                                            ? krebsartRef[artId].bezeichnung
                                            : "N.N. (" + artId + ")"
                                    ;
                                x["haeufigkeit"] = "";
                                x["untersuchungstyp"] = "Nachweis";
                                x["summe"] = 0;
                                x["gruppe"] = x instanceof FischinfoNachweisbefischungergebnis
                                    ? "Fische"
                                    : x instanceof FischinfoNachweismuschelergebnis
                                        ? "Muscheln"
                                        : "Krebse"
                                    ;

                                return x;
                            })

                    );
                    // #endregion

                    // #region Anzahl
                    list.push(
                        ...
                        Array.of<FischinfoAnzahlbefischungergebnis | FischinfoAnzahlmuschelergebnis | FischinfoAnzahlkrebsergebnis>(
                            ...(fischinfoBefischungList.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id || []),
                            ...(fischinfoMuscheluntersuchungList.fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id || []),
                            ...(fischinfoKrebsuntersuchungList.fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        )
                            .map(x => {
                                const artId = x instanceof FischinfoAnzahlbefischungergebnis
                                    ? x.art_id.toString()
                                    : x instanceof FischinfoAnzahlmuschelergebnis
                                        ? x.muschelart_id.toString()
                                        : x.krebsart_id.toString()
                                    ;
                                x["art"] = x instanceof FischinfoAnzahlbefischungergebnis
                                    ? fischartRef[artId]
                                        ? fischartRef[artId].bezeichnung
                                        : "N.N. (" + artId + ")"
                                    : x instanceof FischinfoAnzahlmuschelergebnis
                                        ? muschelartRef[artId]
                                            ? muschelartRef[artId].bezeichnung
                                            : "N.N. (" + artId + ")"
                                        : krebsartRef[artId]
                                            ? krebsartRef[artId].bezeichnung
                                            : "N.N. (" + artId + ")"
                                    ;
                                x["haeufigkeit"] = "";
                                x["untersuchungstyp"] = "Anzahl";
                                x["summe"] = x.anzahl || 0;
                                x["gruppe"] = x instanceof FischinfoAnzahlbefischungergebnis
                                    ? "Fische"
                                    : x instanceof FischinfoAnzahlmuschelergebnis
                                        ? "Muscheln"
                                        : "Krebse"
                                    ;
                                return x;
                            })

                    );
                    // #endregion

                    // #region Haeufigkeit
                    list.push(
                        ...
                        Array.of<FischinfoHaeufigkeitbefischungergebnis | FischinfoHaeufigkeitsmuschelergebnis | FischinfoHaeufigkeitkrebsergebnis>(
                            ...(fischinfoBefischungList.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id || []),
                            ...(fischinfoMuscheluntersuchungList.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id || []),
                            ...(fischinfoKrebsuntersuchungList.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        )
                            .map(x => {
                                const artId = x instanceof FischinfoHaeufigkeitbefischungergebnis
                                    ? x.art_id.toString()
                                    : x instanceof FischinfoHaeufigkeitsmuschelergebnis
                                        ? x.art_id.toString()
                                        : x.art_id.toString()
                                    ;
                                x instanceof FischinfoHaeufigkeitsmuschelergebnis // fix fuer unterschiedliche id spalte
                                    && (x["muschelart_id"] = x.art_id)
                                    ;
                                x instanceof FischinfoHaeufigkeitkrebsergebnis // fix fuer unterschiedliche id spalte
                                    && (x["krebsart_id"] = x.art_id)
                                    ;
                                x["art"] = x instanceof FischinfoHaeufigkeitbefischungergebnis
                                    ? fischartRef[artId]
                                        ? fischartRef[artId].bezeichnung
                                        : "N.N. (" + artId + ")"
                                    : x instanceof FischinfoHaeufigkeitsmuschelergebnis
                                        ? muschelartRef[artId]
                                            ? muschelartRef[artId].bezeichnung
                                            : "N.N. (" + artId + ")"
                                        : krebsartRef[artId]
                                            ? krebsartRef[artId].bezeichnung
                                            : "N.N. (" + artId + ")"
                                    ;

                                x["haeufigkeit"] =
                                    x instanceof FischinfoHaeufigkeitbefischungergebnis
                                        ? x.fischinfoFischhaeufigkeitByHaeufigkeit_id
                                            ? x.fischinfoFischhaeufigkeitByHaeufigkeit_id.bezeichnung
                                            : ""
                                        : x instanceof FischinfoHaeufigkeitsmuschelergebnis
                                            ? x.fischinfoMuschelhaeufigkeitByMuschelhaeufigkeit_id
                                                ? x.fischinfoMuschelhaeufigkeitByMuschelhaeufigkeit_id.bezeichnung
                                                : ""
                                            : x.fischinfoKrebshaeufigkeitByKrebshaeufigkeit_id
                                                ? x.fischinfoKrebshaeufigkeitByKrebshaeufigkeit_id.bezeichnung
                                                : ""
                                    ;

                                x["untersuchungstyp"] = "Häufigkeit";
                                x["summe"] = 0;
                                x["gruppe"] = x instanceof FischinfoHaeufigkeitbefischungergebnis
                                    ? "Fische"
                                    : x instanceof FischinfoHaeufigkeitsmuschelergebnis
                                        ? "Muscheln"
                                        : "Krebse"
                                    ;
                                return x;
                            })

                    );
                    // #endregion
                    return list
                        .map(x => {
                            this.skalaKeys.forEach(einheit => {
                                x[einheit] = x[einheit] || 0;
                            });
                            return x;
                        })
                        .sort((a, b) => {
                            a = a.art.trim().toLowerCase()
                                .replace("ä", "ae")
                                .replace("ö", "oe")
                                .replace("ü", "ue")
                                .replace("ß", "ss")
                                ;
                            b = b.art.trim().toLowerCase()
                                .replace("ä", "ae")
                                .replace("ö", "oe")
                                .replace("ü", "ue")
                                .replace("ß", "ss")
                                ;
                            return (a > b)
                                ? 1
                                : (
                                    (b > a)
                                        ? -1
                                        : 0
                                )
                                ;
                        });
                })
            ;
    }
}
