<ng-container
    [ngSwitch]="userService.isLoggedIn || isPublicView"
>   <div
        *ngSwitchCase="true"
        uw-overlay
    >
        <uw-map-view
            height="740"
            [editable]="userService.isLoggedIn === true"
        ></uw-map-view>
        <uw-legende></uw-legende>
        <uw-modal-window
            #modal
            *ngIf="modalService.modalViewInitLeft > 0"
            [height]="modalService.modalViewInitHeight"
            [width]="modalService.modalViewInitWidth"
            [left]="modalService.modalViewInitLeft"
            [top]="modalService.modalViewInitTop"
            zIndex="2000"
            (move)="onModalMove($event, modal)"
            (resize)="onModalResize($event, modal)"
        ></uw-modal-window>
        <uw-overlay-layer></uw-overlay-layer>
    </div>
    <custom-login
        *ngSwitchDefault
        url="{{dataService.webServiceApiUrl}}login?format=application/json"
        requestMethod="get"
        nameParam="loginname"
        passParam="password"
        (loginChange)="loginChanged($event)"
    ></custom-login>
</ng-container>
