import * as fn from "./../../decorators/index";
import DataModel from "./../model";
import ITermItem from "./interface";
@fn.DataModel
export default class TermItem extends DataModel implements ITermItem {
    constructor(obj?: object | ITermItem) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | ITermItem): TermItem {
        return new TermItem(obj);
    }
    public deserialize<T extends object>(obj: T | ITermItem): TermItem {
        Object.assign(this, obj);
        return this;
    }

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "term", type: String, databaseType: "character varying", nullable: false, memberType: "" })
    public term: string;
};
