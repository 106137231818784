<div
    class="row"
    style="padding-bottom:0px;"
    *ngIf="!readonly"
>
    <div class="col-xs-15" style="padding-top:10px;padding-bottom:10px;">
        <label>Wählen Sie die entsprechende Erfassungsart.</label>
        <br/><label>Es können keine Erfassungsarten kombiniert werden, eine einzelne Erfassungsart muss bestimmt sein.</label>
    </div>
    <div class="col-xs-15">
        <uw-radiobutton-group
            (change)="onErfassungsartChange($event)"
            [value]="erfassungsart"
            [disabled]="readonly"
        >
            <uw-radiobutton 
                value="keine"
                label="Keine"
                labelAt="e"
                infoTag="Es ist keine Erfassung der Art vorgenommen"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="individuen"
                label="Individuen"
                labelAt="e"
                infoTag="Detaillierte Eingabe einzelner Individuen nach Grösse und Gewicht"
            ></uw-radiobutton>
            <uw-radiobutton
                value="laengenklassen"
                [label]="artGruppe === 'Muscheln' ? 'Grössenklassen' : 'Längenklassen'"
                labelAt="e"
                [infoTag]="artGruppe === 'Muscheln' ? 'Eingabe der Anzahl nach Grössenklassen' : 'Eingabe der Anzahl nach Längenklassen'"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="anzahl"
                label="Anzahl"
                labelAt="e"
                infoTag="Eingabe der Anzahl der Individuen"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="haeufigkeit"
                label="Häufigkeit"
                labelAt="e"
                infoTag="Eingabe der geschätzten Häufigkeit (Klassen 1-5)"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="nachweis"
                label="Nachweis"
                labelAt="e"
                infoTag="Die ausgewählte Art wurde lediglich nachgewiesen. Nähere Angaben liegen nicht vor."
            ></uw-radiobutton>
        </uw-radiobutton-group>
    </div>
</div>

<ng-container
    [ngSwitch]="erfassungsart"
>
    <form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-detail
        *ngSwitchCase="'individuen'"
        [untersuchung]="untersuchung"
        [modalId]="modalId"
        [readonly]="readonly"
        [artId]="artId"
        [artGruppe]="artGruppe"
        [detailErgebnis]="detailErgebnis"
        (changed)="onChanged($event)"
    ></form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-detail>
    <form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-laengenklassen
        *ngSwitchCase="'laengenklassen'"
        [modalId]="modalId"
        [readonly]="readonly"
        [artId]="artId"
        [artGruppe]="artGruppe"
        [laengenklassenErgebnis]="laengenklassenErgebnis"
        (changed)="onChanged($event)"
    ></form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-laengenklassen>
    <form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-anzahl
        *ngSwitchCase="'anzahl'"
        [modalId]="modalId"
        [readonly]="readonly"
        [artId]="artId"
        [artGruppe]="artGruppe"
        [anzahlErgebnis]="anzahlErgebnis"
        (changed)="onChanged($event)"
    ></form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-anzahl>
    <form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-haeufigkeit
        *ngSwitchCase="'haeufigkeit'"
        [modalId]="modalId"
        [readonly]="readonly"
        [artId]="artId"
        [artGruppe]="artGruppe"
        [haeufigkeitErgebnis]="haeufigkeitErgebnis"
        (changed)="onChanged($event)"
    ></form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-haeufigkeit>
    <form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-nachweis
        *ngSwitchCase="'nachweis'"
        [modalId]="modalId"
        [readonly]="readonly"
        [artId]="artId"
        [artGruppe]="artGruppe"
        [nachweisErgebnis]="nachweisErgebnis"
        (changed)="onChanged($event)"
    ></form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-nachweis>
    <div
        *ngSwitchDefault
        class="row"
    >
        <div class="col-xs-15">
            <div class="row" style="padding-top:10px;">
                <div class="col-xs-15">
                    <label>Es ist keine Erfassung der Art vorgenommen, die ausgewählte Art wurde nicht nachgewiesen.</label>
                </div>
            </div>
        </div>
    </div>
</ng-container>
