<div class="uw-modal"
 [style.height.px]="height"
 [style.width.px]="width"
 [style.top.px]="top"
 [style.left.px]="left"
 [style.z-index]="zIndex"
 (uwtranslateneeded)="resizeOrTranslateNeededOnElement($event)"
 (mousedown)="mousedownOnModal($event)"
 (click)="mousedownOnModal($event)"
>
    <div [style.z-index]="zIndex + 3" class="uw-modal-controls">
        <div (click)="toggleHeight()" class="uw-minimize"><i class="fa fa-minus" title="Fenster minimieren"></i></div>
        <div (click)="closeModal()" class="uw-close"><i class="fa fa-close" title="Fenster schliessen"></i></div>
    </div>
    <div [style.z-index]="zIndex + 2"
        uw-draggable
        [style.min-height.px]="headerHeight"
        [style.height.px]="headerHeight"
        [style.max-height.px]="headerHeight"
        class="uw-modal-header draghandle"
    >
        <ng-content select="header"></ng-content>
    </div>
    <div *ngIf="height > 48"
        [style.z-index]="zIndex + 1"
        class="uw-modal-content"
        [style.height.px]="height - 2 * headerHeight - 2"
        [style.max-height.px]="height - 2 * headerHeight - 2"
    >
        <ng-content select="article"></ng-content>
    </div>
    <div [style.z-index]="zIndex + 1" class="uw-modal-footer">
         <ng-content select="footer"></ng-content>
    </div>
    <div [style.z-index]="zIndex + 4" [style.height.px]="height" uw-resizeable class="resizehandle resizeable-e"></div>
    <div [style.z-index]="zIndex + 4" [style.height.px]="height" uw-resizeable class="resizehandle resizeable-w"></div>
    <div [style.z-index]="zIndex + 4" [style.width.px]="width" uw-resizeable class="resizehandle resizeable-s"></div>
    <div [style.z-index]="zIndex + 5" uw-resizeable class="resizehandle resizeable-sw"></div>
    <div [style.z-index]="zIndex + 5" uw-resizeable class="resizehandle resizeable-se"></div>
</div>
