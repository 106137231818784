<div class="row">
    <div class="col-xs-15 col-sm-7dot5"><uw-select
        [disabled]="readonly"
        *ngIf="anodenart_idRefJsonItems"
        [jsonItems]="anodenart_idRefJsonItems"
        keyValueMapping="0:1"
        size="10"
        [label]="formFischinfoUntersuchungService.label('FischinfoMethode','anodenart_id')"
        [(key)]="anodenart_id"
        nullable="true"
    ></uw-select></div>
    <div class="col-xs-15 col-sm-5"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','anodendurchmesser')}}"
        [(value)]="anodendurchmesser"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-7dot5"><uw-select
        [disabled]="readonly"
        *ngIf="vorgehensweise_idRefJsonItems"
        [jsonItems]="vorgehensweise_idRefJsonItems"
        keyValueMapping="0:1"
        size="10"
        [label]="formFischinfoUntersuchungService.label('FischinfoMethode','vorgehensweise_id')"
        [(key)]="vorgehensweise_id"
        nullable="true"
    ></uw-select></div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-5"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','anzahlprobepunkte')}}"
        [(value)]="anzahlprobepunkte"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-5"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','befischteteilflaeche')}}"
        [(value)]="befischteteilflaeche"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-5"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','untersuchungsbereich')}}"
        [(value)]="untersuchungsbereich"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>
