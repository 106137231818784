import { Component, Input, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoStrukturelement from "../../models/fischinfo/strukturelement/model";
import FischinfoStrukturelementehaeufigkeit from "../../models/fischinfo/strukturelementehaeufigkeit/model";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoHaeufigkeit from "../../models/fischinfo/haeufigkeit/model";
/**
 * Mit dieser Komponente kann der Nutzer die Häufigkeit der fischrelevanten Strukturelemente des befischten Gewässers ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt die Häufigkeitsklassen der Strukturelemente dar. Der Nutzer kann für jedes Strukturelement einzeln die Häufigkeitsklasse ansehen und bearbeiten.
 */
@Component({
    selector: "form-fischinfo-untersuchung-gewaesser-struktur-strukturelementehaeufigkeit",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungGewaesserStrukturStrukturelementehaeufigkeit implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public haeufigkeit_idRefJsonItems: string;
    public _strukturelementehaeufigkeitList: any[];
    public get strukturelementehaeufigkeitList() {
        return this._strukturelementehaeufigkeitList;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    public onChangeStrukturelementehaeufigkeit(key: number, strukturelementehaeufigkeit: FischinfoStrukturelementehaeufigkeit) {
        !this.readonly && this.formFischinfoUntersuchungService.modelUtil
            .remove<FischinfoStrukturelementehaeufigkeit>(this.untersuchung, "FischinfoStrukturelementehaeufigkeit", strukturelementehaeufigkeit);
        !this.readonly && parseInt(String(key)) > 0
            && this.formFischinfoUntersuchungService.modelUtil
                .setOrAdd<FischinfoStrukturelementehaeufigkeit>(this.untersuchung, "FischinfoStrukturelementehaeufigkeit", new FischinfoStrukturelementehaeufigkeit({
                id: 1,
                strukturelement_id: strukturelementehaeufigkeit.strukturelement_id,
                haeufigkeit_id: parseInt(String(key)) || null,
                gewaesserzustand_id: this.formFischinfoUntersuchungService.modelUtil
                    .getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                    .id
            }));
    }
    ngOnInit() {
        this.refreshStrukturelementehaeufigkeitList();
        this.dataService
            .getReferenceTable("haeufigkeit", FischinfoHaeufigkeit, "id")
            .then(haeufigkeiten => {
            let refList = [];
            Object.keys(haeufigkeiten).forEach(key => {
                refList.push('[' + haeufigkeiten[key].id + ',"' + haeufigkeiten[key].bezeichnung + '"]');
            });
            this.haeufigkeit_idRefJsonItems = "[" + refList.join(",") + "]";
        });
    }
    public refreshStrukturelementehaeufigkeitList() {
        this.getNormalizedStrukturelementehaeufigkeitList()
            .then((list) => {
            this._strukturelementehaeufigkeitList = list;
        });
    }
    public getNormalizedStrukturelementehaeufigkeitList(): Promise<any[]> {
        let a = this.dataService
            .getReferenceTable("strukturelement", FischinfoStrukturelement, "id");
        return Promise.all([a])
            .then(([strukturelement]) => {
            const values = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                .fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id || [];
            let list = [];
            Object.keys(strukturelement).forEach(key => {
                (strukturelement[key].fliessgewaesser && this.probenahmestelle.gewaessertyp === 'FG'
                    ||
                        strukturelement[key].stillgewaesser && this.probenahmestelle.gewaessertyp === 'SG') && list.push({
                    id: (values.find(x => x.strukturelement_id === strukturelement[key].id) || { id: 0 }).id,
                    bezeichnung: strukturelement[key].bezeichnung,
                    strukturelement_id: strukturelement[key].id,
                    haeufigkeit_id: (values.find(x => x.strukturelement_id === strukturelement[key].id) || { haeufigkeit_id: 0 }).haeufigkeit_id
                });
            });
            return list
                .filter(x => !this.readonly || x.haeufigkeit_id > 0)
                .sort((a, b) => {
                a = a.strukturelement_id;
                b = b.strukturelement_id;
                return (a > b)
                    ? 1
                    : ((b > a)
                        ? -1
                        : 0);
            });
        });
    }
}
