
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Subject } from "rxjs";

import DataService from "../data/service";

import Pulse from "./pulse";

type PromiseControl<T> = {
    resolve(v: T): void,
    reject(r: any): void,
}

export type TaskStateResult = {
    state: string,
    location: string,
    error: string,
    progress: number,
};

export type TaskIdResult = {
    task: number,
};

export type Resultset<T> = {
    resultset: T[],
};

export default class AuskunftShpExport {
    constructor(
        private http: HttpClient,
        private data: DataService,
        private prepareRequest: () => Promise<{
            headers: (otherHeaders?: { [name: string]: any }) => HttpHeaders,
        }>
    ) {
    }

    protected cancelRequested = new Subject<void>();
    private fileBlob: Blob;
    public get file() { return this.fileBlob };
    public progress: number = 0.0;

    protected addCacheBuster(location: string) {
        return location
            + (location.includes("?") ? "&" : "?")
            + "_t=" + String(Date.now()) + String(Math.random()).slice(2)
    }

    public cancel() {
        this.cancelRequested.next()
    }

    protected checkProgress(location: string) {
        return new Promise<string>((resolve, reject) => {
            const pulse = new Pulse(750);
            const cancelSub = this.cancelRequested.subscribe(() => void pulse.stop());
            const fetchState = () => this.sendProgressCheck(location)
                .then(resp => resp.body.resultset[0]);
            const processState = (progress: TaskStateResult) => {
                switch (progress.state) {
                    case "running":
                        pulse.next().then(fetchState).then(processState);
                        this.progress = progress.progress;
                        break;
                    case "completed":
                        cancelSub.unsubscribe();
                        pulse.stop();
                        this.progress = 1.0;
                        resolve(this.data.webServiceApi
                            + progress.location.replace(/^\/api\/v\d+\//, ""));
                        break;
                    case "failed":
                        cancelSub.unsubscribe()
                        pulse.stop();
                        resolve(this.data.webServiceApi
                            + progress.location.replace(/^\/api\/v\d+\//, ""));
                        break;
                }
            };

            pulse
                .start()
                .next()
                .then(fetchState)
                .then(processState)
                ;

        });
    }

    protected downloadZip(location: string) {
        return this.sendDownload(location)
            .then(resp => resp.body)
            ;
    }

    public export(auskunftVersion: string, befischungIds: number[]): Promise<Blob> {
        const dataType = this.data.folder;
        if (!dataType) {
            return Promise.reject(
                new RangeError("unknown ESRI-shape export datatype: " + this.data.folder)
            );
        }
        const p = this.prepareShapefile(auskunftVersion, befischungIds)
            .then(location => this.checkProgress(location))
            .then(location => this.downloadZip(location))
            ;
        return p;
    }

    protected prepareShapefile(auskunftVersion: string, befischungIds: number[]) {
        const protocol = /^\w+:/;
        const trimProtocol = (url: string) =>
            protocol.test(url) ? url.replace(protocol, "") : url;
        return this.sendPrepare(auskunftVersion, befischungIds)
            .then(resp =>
                trimProtocol(resp.headers.get("Location"))
            )
            ;
    }

    protected sendDownload(location: string) {
        return this.prepareRequest()
            .then(requtil =>
                this.http.get(
                    this.addCacheBuster(location),
                    {
                        observe: "response",
                        headers: requtil.headers({
                            "Accept": "application/zip",
                        }),
                        responseType: "blob",
                    },
                ).toPromise()
            )
            ;
    }

    protected sendProgressCheck(location: string) {
        return this.prepareRequest()
            .then(requtil =>
                this.http.get<Resultset<TaskStateResult>>(
                    this.addCacheBuster(location),
                    {
                        observe: "response",
                        headers: requtil.headers(),
                    },
                ).toPromise()
            )
            ;
    }

    protected sendPrepare(auskunftVersion: string, befischungIds: number[]) {
        return this.prepareRequest()
            .then(requtil =>
                this.http.post<Resultset<TaskIdResult>>(
                    this.addCacheBuster(this.data.webServiceApiUrl
                        + "auskunftshp/"
                        + auskunftVersion),
                    JSON.stringify(befischungIds),
                    {
                        observe: "response",
                        headers: requtil.headers({
                            "Content-Type": "application/json",
                        }),
                    }
                ).toPromise()
            )
            ;
    }
}
