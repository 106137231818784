import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoAnodenart from "../../models/fischinfo/anodenart/model";
import FischinfoVorgehensweise from "../../models/fischinfo/vorgehensweise/model";
import FischinfoMethode from "../../models/fischinfo/methode/model";
/**
 * Mit dieser Komponente kann der Nutzer die Informationen zu einer "Point Abundance"-Methode eines E-Gerät ansehen und ggf. bearbeiten.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-methode-egeraet-pointabundance",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungMethodeEgeraetPointabundance implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public set anodenart_id(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anodenart_id = parseInt(String(value), 10) || null;
    }
    public get anodenart_id(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anodenart_id;
    }
    public set anodendurchmesser(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anodendurchmesser = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get anodendurchmesser(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anodendurchmesser;
        return value
            && String(value).replace(".", ",");
    }
    public set vorgehensweise_id(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .vorgehensweise_id = parseInt(String(value), 10) || null;
    }
    public get vorgehensweise_id(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .vorgehensweise_id;
    }
    public set anzahlprobepunkte(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anzahlprobepunkte = parseInt(String(value), 10) || null;
    }
    public get anzahlprobepunkte(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .anzahlprobepunkte;
    }
    public set befischteteilflaeche(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .befischteteilflaeche = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get befischteteilflaeche(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .befischteteilflaeche;
        return value
            && String(value).replace(".", ",");
    }
    public set untersuchungsbereich(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .untersuchungsbereich = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get untersuchungsbereich(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .untersuchungsbereich;
        return value
            && String(value).replace(".", ",");
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    public anodenart_idRefJsonItems: string;
    public vorgehensweise_idRefJsonItems: string;
    ngOnInit() {
        const a = this.dataService
            .getReferenceTable("anodenart", FischinfoAnodenart, "id");
        const b = this.dataService
            .getReferenceTable("vorgehensweise", FischinfoVorgehensweise, "id");
        return Promise.all<{
            [key: string]: FischinfoAnodenart | FischinfoVorgehensweise;
        }>([a, b])
            .then(refs => {
            [0, 1].map(x => {
                let refList = [], entityKey;
                Object.keys(refs[x]).forEach(key => {
                    entityKey = key;
                    refList.push('[' + refs[x][key].id + ',"' + refs[x][key].bezeichnung + '"]');
                });
                refs[x] && refs[x][entityKey] instanceof FischinfoAnodenart
                    && (this.anodenart_idRefJsonItems = "[" + refList.join(",") + "]");
                refs[x] && refs[x][entityKey] instanceof FischinfoVorgehensweise
                    && (this.vorgehensweise_idRefJsonItems = "[" + refList.join(",") + "]");
            });
        });
    }
}
