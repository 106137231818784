<div class="row">
    <div class="col-xs-15">
        <label>Allgemeine Angaben zum Gewässer</label>
    </div>
</div>

<div class="row">
    <div
        *ngIf="probenahmestelle?.gewaessertyp === 'FG'"
        class="col-xs-15 col-sm-5"
        ><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','gewaesserbreite')"
        [(value)]="gewaesserbreite"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div
        *ngIf="probenahmestelle?.gewaessertyp === 'SG'"
        class="col-xs-15 col-sm-5"
        ><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','gewaesserflaeche')"
        [(value)]="gewaesserflaeche"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-10">
        <div class="row">
            <div class="col-xs-15" style="margin-bottom: -6px;">
                <label>{{formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','gewaessertiefe')}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-15 col-sm-7dot5">
                <uw-textinput
                    [(value)]="gewaessertiefe"
                    [disabled]="readonly"
                ></uw-textinput> 
            </div>
        </div>
   </div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-5" ><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','wassertemperatur')"
        [(value)]="wassertemperatur"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-5" ><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','leitfaehigkeit')"
        [(value)]="leitfaehigkeit"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-5" ><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','ph')"
        [(value)]="ph"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-5" >
        <label>{{formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','truebung')}}</label>
        <br/>
        <uw-checkbox
            [(value)]="truebung"
            [disabled]="readonly"
        ></uw-checkbox>
    </div>
    <div
        *ngIf="probenahmestelle?.gewaessertyp === 'FG'"
        class="col-xs-15 col-sm-5"
    ><uw-select
        [label]="formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','wasserstand_id')"
        *ngIf="wasserstand_idRefJsonItems"
        [jsonItems]="wasserstand_idRefJsonItems"
        keyValueMapping="0:1"
        [disabled]="readonly"
        size="10"
        [(key)]="wasserstand_id"
    ></uw-select></div>
</div>

<div
    *ngIf="probenahmestelle?.gewaessertyp === 'FG'"
    class="row"
>
    <div class="col-xs-15 col-sm-5" ><uw-select
        [label]="formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','stroemung_id')"
        *ngIf="stroemung_idRefJsonItems"
        [jsonItems]="stroemung_idRefJsonItems"
        keyValueMapping="0:1"
        [disabled]="readonly"
        size="10"
        [(key)]="stroemung_id"
    ></uw-select></div>
    <div class="col-xs-15 col-sm-5" ><uw-select
        [label]="formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','linienfuehrung_id')"
        *ngIf="linienfuehrung_idRefJsonItems"
        [jsonItems]="linienfuehrung_idRefJsonItems"
        keyValueMapping="0:1"
        [disabled]="readonly"
        size="10"
        [(key)]="linienfuehrung_id"
    ></uw-select></div>
</div>

<div
    class="row"
    *ngIf="probenahmestelle?.gewaessertyp === 'SG'"
>
    <div class="col-xs-15 col-sm-5" >
        <label>{{formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','angebunden')}}</label>
        <br/>
        <uw-checkbox
            [(value)]="angebunden"
            [disabled]="readonly"
        ></uw-checkbox>
    </div>
</div>
