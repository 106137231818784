import { Component, Input, ElementRef, Output, EventEmitter } from "@angular/core";
import { UWVirtualTable } from "ng-uw-virtual-table";
import ModalService from "./../modal/service";
import DataService from "./../data/service";
import MapService from "./../map/service";
import MapEditorService from "./../map-editor/service";
import UserService from "./../user/service";
import DataModel from "../../models/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
/**
 * Die Komponente zeigt alle ausgewählten Probenahmestellen/Untersuchungen als tabellarische Liste an.
 *
 * Der Nutzer kann auf die Einträge in der Tabelle klicken, um detailierte Informationen über die Probestelle/Untersuchung zu erhalten.
 */
@Component({
    selector: "custom-table",
    templateUrl: "./default.component.html",
    styleUrls: [
        "./default.component.less",
        "../../assets/styles/main.less"
    ]
})
export default class Table extends UWVirtualTable {
    @Input()
    public columns: any = [];
    @Input()
    virtualPageSize = 100;
    public selectedId: any = new Map<string, number>();
    constructor(public elementRef: ElementRef, public dataService: DataService, public mapService: MapService, public mapEditorService: MapEditorService, public modalService: ModalService, public userService: UserService) {
        super(dataService);
    }
    public ngAfterViewInit(): void {
        this.modalService
            .modalCloseObservable
            .subscribe(event => {
                this.onModalClose(event);
            });
    }
    public init() {
        this.dataService
            .folderChanged
            .subscribe(isSuccess => {
                if (isSuccess) {
                    this.selectedSortColumn = null;
                    this.selectedSortOrder = "desc";
                    this.table.nativeElement.scrollTop = 0;
                    this.previousScrollingIndex = 0;
                    this.columns = this.dataService.columns;
                    this.dataService.sort("id", this.selectedSortOrder, this.selectedSortType);
                    this.virtualHeight = this.dataService.length * this.virtualRowHeight;
                }
            });
    }
    public onClickHeaderColumn(event): boolean {
        this.selectedSortColumn = event.target.attributes["data-sort-by"].value;
        this.selectedSortType = event.target.attributes["data-sort-type"].value || "number";
        this.selectedSortOrder = this.selectedSortOrder === "desc" ? "asc" : "desc";
        this.table.nativeElement.scrollTop = 0;
        this.previousScrollingIndex = 0;
        this.dataService.sort(this.selectedSortColumn, this.selectedSortOrder, this.selectedSortType);
        return true;
    }
    public onModalClose(e: any): void { }
    public onClickItem(event, item): boolean {
        this.modalService.closeModals();
        const folder = this.dataService.folder;
        const modalId: string = this.modalService.createModal({
            title: "Anfrage wird ausgeführt…",
            status: "Wird geladen…",
            data: {
                probenahmestelleToBeLoaded: folder === "probenahmestelle"
                    ? (item as FischinfoProbenahmestelle)
                    : (item as FischinfoUntersuchung).fischinfoProbenahmestelleByProbenahmestelle_id,
                untersuchungToBeLoaded: folder === "untersuchung"
                    ? (item as FischinfoUntersuchung)
                    : null,
                wmsFeatureRequest: [folder === "probenahmestelle"
                    ? (item as FischinfoProbenahmestelle)
                    : (item as FischinfoUntersuchung)
                ],
                zoomOnFeature: true,
                type: folder,
                typeLabel: folder[0].toLocaleUpperCase() + folder.slice(1),
                activeTab: folder,
                loaded: 0
            }
        });
        return true;
    }
    public scrollTop: number = 0;
    public ngOnInit(): void {
        this.dataService.partialLoadSuccess.subscribe((items: Array<any>) => {
            this.virtualHeight = this.dataService.length * this.virtualRowHeight;
            this.dataService.sort(this.selectedSortColumn, this.selectedSortOrder, this.selectedSortType);
        });
        this.dataService.completeLoadSuccess.subscribe(() => {
            this.virtualHeight = this.dataService.length * this.virtualRowHeight;
            this.dataService.sort(this.selectedSortColumn, this.selectedSortOrder, this.selectedSortType);
        });
        this.dataService.sortSuccess.subscribe(() => {
            this.dataService.slice(this.previousScrollingIndex, this.previousScrollingIndex + this.virtualPageSize);
            this.refresh(this.scrollTop); // ScrollUpdateFix
        });
        this.dataService.sliceSuccess.subscribe((items: Array<any>) => {
            this.virtualPageItems = items;
        });
        /** better Scroll */
        let transformEventToRequestAnimationFrame = (type, name, obj?) => {
            obj = obj || window;
            let running = false, func = () => {
                if (running) {
                    return;
                }
                running = true;
                try {
                    requestAnimationFrame(function () {
                        try {
                            obj.dispatchEvent(new CustomEvent(name));
                        }
                        catch (e) { }
                        running = false;
                    });
                }
                catch (e) { }
            };
            try {
                obj.addEventListener(type, func);
            }
            catch (e) { }
        };
        transformEventToRequestAnimationFrame("scroll", "optimizedScroll", this.table.nativeElement);
        try {
            this.table.nativeElement.addEventListener("optimizedScroll", (e: UIEvent) => {
                this.refresh(-1 * (e.target["children"][0].getBoundingClientRect().top - 32));
            });
        }
        catch (e) { }
    }
    public onScrollContainer(event): void {
        this.scrollTop = event.target.scrollTop; // ScrollUpdateFix
        try {
            clearTimeout(this.scrollEventGapTimestamp);
        }
        catch (e) { }
        this.scrollEventGapTimestamp = setTimeout(() => {
            this.refresh(this.scrollTop);
        }, 200);
    }
}
