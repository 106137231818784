import { IUWPersistentStorage, IKeyValuePair, IConfigOptions } from "./interface";
import { Observable, Observer } from "rxjs";
import { filter, map } from "rxjs/operators";
import localForage from "localforage";
import LazyPromise from "lazy-promise";

const DefaultStorage = localForage;
const Separator = "/";
const DefaultNamespace = Separator + "UwPersistentStorage";
const DefaultPrefix = DefaultNamespace + Separator;

export interface PersistentStorage {
    length(): Promise<number>
    key(index: number): Promise<string>
    getItem<TValue>(key: string): Promise<TValue | null>
    setItem<TValue>(key: string, value: TValue): Promise<typeof value>
    removeItem(key: string): Promise<void>
    iterate<TValue = any>(fn: (value: TValue, key: string) => void): Promise<void>
    config(values: IConfigOptions): void
}

export interface HasByteSize {
    size: number,
}

export interface ByteSizedValue<T> extends HasByteSize {
    value: T,
}

export interface ComplexValueMetadata<TDataType extends string> extends HasByteSize {
    complexType: TDataType
}

export interface SlicedBlobMetadata extends ComplexValueMetadata<"file" | "blob"> {
    mimeType: string
    name: string
    sliceCount: number
} 

export interface FormDataMetadata extends ComplexValueMetadata<"formdata"> {
    fieldCount: number
    fieldNameMap: { [index: number]: string }
}

export default class UWPersistentStorage implements IUWPersistentStorage {
    private prefix: string;
    private storage: PersistentStorage;
    protected rawEntriesObservable: Observable<IKeyValuePair<any>>
    private entriesObservable: Observable<IKeyValuePair<Promise<any>>>;
    private readonly blobSuffixSep = "$blobslice#";
    private readonly blobSuffixRegex = /\$blobslice#\d+$/;
    private readonly formdataSuffixSep = "$formdatafield#";
    private readonly formdataSuffixRegex = /\$formdatafield#\d+$/
    private readonly blobSliceSize = 256 * 1024; //256 KiB

    constructor(options?: { storage?: PersistentStorage, namespace?: string }) {
        this.storage = DefaultStorage;

        if (options) {
            if (options.storage) {
                this.storage = options.storage;
            }
            if (options.namespace) {
                this.setNamespace(options.namespace);
            }
        }

        this.rawEntriesObservable = (
            new Observable<IKeyValuePair<Blob | ByteSizedValue<any>>>(this.createKeyValueEmitter())
            .pipe(
                filter(this.isQualifiedKVPair, this),
                map(this.localizeKVPair, this)
            )
        );
        this.entriesObservable = this.rawEntriesObservable
            .pipe(
                filter(this.isNotComplexTypeFragment, this),
                map(this.resolveValue, this),
            )
            ;
    }

    public entries() {
        return this.entriesObservable;
    }

    public config(options: IConfigOptions) {
        this.storage.config(options);
    }

    protected getBlobSliceSuffix(sliceIndex: number, sliceCount: number) {
        const indexStr = sliceIndex.toFixed(0);
        const indexLen = indexStr.length;
        const countLen = sliceCount.toFixed(0).length;
        return this.blobSuffixSep
            + new Array(Math.max(countLen - indexLen, 0)).fill(0).join("")
            + indexStr
            ;
    }

    protected hasBlobSliceSuffix(key: string) {
        return this.blobSuffixRegex.test(key);
    }

    protected getFormDataFieldSuffix(fieldIndex: number /*, fieldCount: number*/) {
        const indexStr = fieldIndex.toFixed(0);
        // const indexLen = indexStr.length;
        // const countLen = fieldCount.toFixed(0).length;
        return this.formdataSuffixSep
            // + new Array(Math.max(countLen - indexLen, 0)).fill(0).join("")
            + indexStr
            ;
    }

    protected hasFormDataFieldSuffix(key: string) {
        return this.formdataSuffixRegex.test(key);
    }

    protected createKeyValueEmitter() {
        return (observer: Observer<IKeyValuePair<Blob | ByteSizedValue<any>>>) => {
            this.storage
                .iterate((value, key) => void observer.next({ key, value }))
                .then(_ => observer.complete(), e => observer.error(e))
                ;
        };
    }

    public setNamespace(namespace: string) {
        if (this.prefix != null) return false;

        let prefix = "";
        if (namespace[0] !== Separator) {
            prefix += Separator;
        }
        prefix += namespace;
        if (namespace[namespace.length - 1] !== Separator) {
            prefix += Separator;
        }

        this.prefix = prefix;
        return true;
    }

    public isComplexValueMetadata(x: any): x is ComplexValueMetadata<string> {
        const isObj = x !== null && typeof x === "object";
        const type = isObj ? (x as ComplexValueMetadata<string>).complexType : null;
        return typeof type === "string" && type.length > 0; 
    }

    public isSlicedBlobMetadata(x: any): x is SlicedBlobMetadata {
        const datatype = this.isComplexValueMetadata(x) ? (x as ComplexValueMetadata<"file" | "blob">).complexType : null 
        return  datatype === "blob" || datatype === "file";
    }

    public isFormDataMetadata(x: any): x is FormDataMetadata {
        const datatype = this.isComplexValueMetadata(x) ? (x as ComplexValueMetadata<"formdata">).complexType : null 
        return  datatype === "formdata";
    }

    public isComplexValue(key: string): Promise<boolean> {
        return this.getItemRaw(key).then(x => this.isComplexValueMetadata(x));
    }

    public isSlicedBlob(key: string): Promise<boolean> {
        return this.getItemRaw(key).then(x => this.isSlicedBlobMetadata(x));
    }

    public isFormData(key: string): Promise<boolean> {
        return this.getItemRaw(key).then(x => this.isFormDataMetadata(x));
    }

    protected readonly unwrapValue: <T>(v: T extends Blob ? T : ByteSizedValue<T>) => T =
        v => v instanceof Blob || v === null || this.isComplexValueMetadata(v) ? v : (v as ByteSizedValue<any>).value
        ;
    
    protected readonly resolveComplexTypeMetadata: {
        <T>(key: string, x: ComplexValueMetadata<string>): Promise<T>,
        <T>(key: string, x: T): T,
    } = <T>(key: string, x: any): T | Promise<T> =>
        this.isSlicedBlobMetadata(x) ? this.loadBlob(key, x) as Promise<any>
            : this.isFormDataMetadata(x) ? this.loadFormData(key, x) as Promise<any>
                : x
        ;

    protected getItemRaw<T>(key: string) {
        return this.storage.getItem<T>(this.qualifyKey(key));
    }

    public getItemValue<T>(key: string) {
        type ItemType = T extends Blob ? T : ByteSizedValue<T>;
        return this.getItemRaw<ItemType>(key)
            .then(this.unwrapValue)
            ;
    }

    public getItemSize(key: string) {
        return this.getItemRaw<HasByteSize>(key)
            .then(value => {
                const n = value && value.size;
                return n == null ? Number.NaN : n;
            })
            ;
    }

    public getItem<T = any>(key: string) {
        return this.getItemValue<T>(key)
            .then(x => this.resolveComplexTypeMetadata<T>(key, x))
            ;
    }

    protected calculateStringByteSize(s: string) {
        return s.length * 2;
    }

    protected calculateByteSize<T>(value: T) {
        return value instanceof Blob ? value.size
            : typeof value === "object" ? this.calculateStringByteSize(JSON.stringify(value))
                : typeof value === "string" ? this.calculateStringByteSize(value)
                    : typeof value === "number" ? 4 // 64-bit floating point
                        : typeof value === "boolean" ? 1 //TODO check assumption that booleans are just one byte
                            : Number.NaN
            ;
    }

    protected setItemRaw<T = any>(key: string, value: T) {
        return this.storage.setItem(this.qualifyKey(key), value);
    }

    public setItemValue<T = any>(key: string, value: T) {
        return value instanceof Blob
            ? this.setItemRaw(key, value)
            : this.setItemRaw(key, { value, size: this.calculateByteSize(value) })
                .then(x => x.value)
            ;
    }

    public setItem<T = any>(key: string, value: T) {
        if (value === null) {
            return this.removeItem(key).then(_ => value);
        }
        else {
            return this.removeItem(key).then(_ =>
                value instanceof Blob ? this.storeBlob(key, value) as Promise<typeof value>
                : value instanceof FormData ? this.storeFormData(key, value) as Promise<typeof value>
                : this.setItemValue(key, value)
            );
        }
    }

    protected removeItemRaw(key: string) {
        return this.storage.removeItem(this.qualifyKey(key));
    }
    
    public removeItem(key: string) {
        return this.getItemValue(key)
            .then(x =>
                this.isSlicedBlobMetadata(x) ? this.removeBlobSlices(key, x)
                : this.isFormDataMetadata(x) ? this.removeFormDataFields(key, x)
                : void x
            )
            .then(_ => this.removeItemRaw(key))
            ;
    }

    protected removeBlobSlices(key: string, { sliceCount }: SlicedBlobMetadata) {
        const slices = new Array(sliceCount);
        for (let i = 0; i < sliceCount; ++i) {
            slices[i] = this.removeItemRaw(key + this.getBlobSliceSuffix(i, sliceCount));
        }
        return Promise.all(slices).then<void>(_ => void 0);
    }

    protected storeBlob<T extends Blob>(key: string, blob: T) {
        const sliceSize = this.blobSliceSize;
        const sliceCount = Math.ceil(blob.size / sliceSize);
        const slices = new Array(sliceCount);
        for (
            let i = 0, start = sliceSize * i;
            i < sliceCount;
            ++i, start = i * sliceSize
        ) {
            const sliceKey = key + this.getBlobSliceSuffix(i, sliceCount);
            const sliceValue = blob.slice(start, start + sliceSize, "application/octet-stream");
            slices[i] = this.setItemValue(sliceKey, sliceValue);
        }
        return Promise.all(slices)
            // @TODO maybe include some kind of rollback mechanism
            .then(_ => this.setItemRaw(key, {
                complexType: blob instanceof File ? "file" : "blob",
                mimeType: blob.type,
                name: blob instanceof File ? blob.name : null,
                size: blob.size,
                sliceCount,
            } as SlicedBlobMetadata))
            .then(_ => blob)
            ;
    }

    protected loadBlob(key: string, { sliceCount, mimeType, complexType, name }: SlicedBlobMetadata) {
        const slices = new Array(sliceCount);
        for (let i = 0; i < sliceCount; ++i) {
            slices[i] = this.getItemValue<Blob>(key + this.getBlobSliceSuffix(i, sliceCount));
        }
        return Promise.all(slices)
            .then(slices => complexType === "file"
                ? new File(slices, name, { type: mimeType })
                : new Blob(slices, { type: mimeType })
            );
    }

    protected removeFormDataFields(key: string, { fieldCount }: FormDataMetadata) {
        const slices = new Array(fieldCount);
        for (let i = 0; i < fieldCount; ++i) {
            slices[i] = this.removeItem(key + this.getFormDataFieldSuffix(i/*, fieldCount*/));
        }
        return Promise.all(slices).then<void>(_ => void 0);
    }

    protected storeFormData(key: string, formdata: FormData) {
        type FormDataEntry = [string, FormDataEntryValue];
        type IterableFormData = FormData & { entries(): Iterator<FormDataEntry> };

        let fieldCount = 0;
        const fieldNameMap = {} as FormDataMetadata["fieldNameMap"];
        const fields = Array<Promise<number>>();
        
        for (
            let iter = (formdata as IterableFormData).entries(), value: FormDataEntry = null;
            !({ value } = iter.next()).done;
        ) {
            const i = fieldCount;
            const [fieldName, fieldValue] = value;
            const fieldKey = key + this.getFormDataFieldSuffix(i);
            fieldNameMap[i] = fieldName;
            fields[i] = this.setItem(fieldKey, fieldValue).then(_ => this.getItemSize(fieldKey));
            ++fieldCount;
        }

        return Promise.all(fields)
            // @TODO maybe include some kind of rollback mechanism
            .then(sizes => this.setItemRaw(key, {
                complexType: "formdata",
                fieldCount,
                fieldNameMap,
                size: sizes.reduce((a, b) => a + b, 0),
            } as FormDataMetadata))
            .then(_ => formdata)
            ;
    }

    protected loadFormData(key: string, { fieldCount, fieldNameMap }: FormDataMetadata) {
        const fields = new Array<Promise<FormDataEntryValue>>(fieldCount);
        for (let i = 0; i < fieldCount; ++i) {
            fields[i] = this.getItem(key + this.getFormDataFieldSuffix(i/*, fieldCount*/));
        }
        return Promise.all(fields)
            .then(fields => fields.reduce<FormData>(
                (formdata, value, index) => (
                    formdata.append(
                        fieldNameMap[index] || ("unknown_field_" + index),
                        value,
                    ),
                    formdata
                ),
                new FormData(),
            ));
    }

    protected getPrefix() {
        return this.prefix == null ? DefaultPrefix : this.prefix;
    }

    protected qualifyKey(key: string, forceQualify?: boolean) {
        // Do not qualify an already qualified key only when we are not forceQualify-ing
        const prefix = this.getPrefix();
        if (!forceQualify && key.startsWith(prefix)) {
            return key;
        }
        return prefix + key;
    }

    protected isQualifiedKVPair(kv: IKeyValuePair<any>) {
        return kv.key.startsWith(this.getPrefix());
    }

    protected localizeKVPair(kv: IKeyValuePair<any>) {
        const prefix = this.getPrefix();
        if (kv.key.startsWith(prefix)) {
            kv.key = kv.key.slice(prefix.length);
        }
        return kv;
    }

    protected isNotComplexTypeFragment(kv: IKeyValuePair<any>) {
        return !this.hasBlobSliceSuffix(kv.key) && !this.hasFormDataFieldSuffix(kv.key);
    }

    protected resolveValue({ key, value }: IKeyValuePair<any>) {
        return {
                key,
                size: value && value.size,
                value: this.isComplexValueMetadata(value)
                    ? new LazyPromise(resolve => resolve(this.resolveComplexTypeMetadata(key, value)))
                    : Promise.resolve(this.unwrapValue(value))
                    ,
            } as IKeyValuePair<Promise<any>>;
    }
}
