import { Component, Input, OnInit, OnDestroy, ViewChild, } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { saveAs } from "file-saver";
import ModalService from "./../modal/service";
import DataService from "./../data/service";
import MapService from "../map/service";
import PrintService from "./../print/service";
@Component({
    selector: "uw-modal-window-tab-kartendruck",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowTabKartendruck implements OnDestroy, OnInit {
    @Input()
    public modal: any;
    @Input()
    public topmargin: string = "24";
    constructor(public modalService: ModalService, public dataService: DataService, public mapService: MapService, public printService: PrintService, public http: HttpClient) {
    }
    public ngOnInit() {
        this.modal.data.loaded = true;
    }
    public ngOnDestroy() {
    }
    public async exportImgFile() {
        if (!this.modal.data.printing) {
            this.modal.data.printing = true;
            try {
                const canvas = await this.mapService.getMapAsCanvas();
                await this.printService.printMapPDF(canvas);
            }
            catch (e) {
            }
            this.modal.data.printing = false;
        }
    }
}
