import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoKrebsuntersuchung from "./interface";
import FischinfoHaeufigkeitkrebsergebnis from "./../../fischinfo/haeufigkeitkrebsergebnis/model";
import FischinfoLaengenklassenkrebsergebnis from "./../../fischinfo/laengenklassenkrebsergebnis/model";
import FischinfoDetailkrebsergebnis from "./../../fischinfo/detailkrebsergebnis/model";
import FischinfoAnzahlkrebsergebnis from "./../../fischinfo/anzahlkrebsergebnis/model";
import FischinfoNachweiskrebsergebnis from "./../../fischinfo/nachweiskrebsergebnis/model";
import FischinfoUntersuchung from "./../../fischinfo/untersuchung/model";
@fn.DataModel
export default class FischinfoKrebsuntersuchung extends DataModel implements IFischinfoKrebsuntersuchung {
    constructor(obj?: object | IFischinfoKrebsuntersuchung) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoKrebsuntersuchung): FischinfoKrebsuntersuchung {
        return new FischinfoKrebsuntersuchung(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoKrebsuntersuchung): FischinfoKrebsuntersuchung {
        Object.assign(this, obj);
        obj && obj["fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id"] && obj["fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id"].length && (this.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id = obj["fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id"].map(FischinfoHaeufigkeitkrebsergebnis.deserialize));
        obj && obj["fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id"] && obj["fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id"].length && (this.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id = obj["fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id"].map(FischinfoLaengenklassenkrebsergebnis.deserialize));
        obj && obj["fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id"] && obj["fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id"].length && (this.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id = obj["fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id"].map(FischinfoDetailkrebsergebnis.deserialize));
        obj && obj["fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id"] && obj["fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id"].length && (this.fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id = obj["fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id"].map(FischinfoAnzahlkrebsergebnis.deserialize));
        obj && obj["fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id"] && obj["fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id"].length && (this.fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id = obj["fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id"].map(FischinfoNachweiskrebsergebnis.deserialize));
        obj && obj["fischinfoUntersuchungByUntersuchung_id"] && (this.fischinfoUntersuchungByUntersuchung_id = FischinfoUntersuchung.deserialize(obj["fischinfoUntersuchungByUntersuchung_id"]));
        return this;
    }

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "referenznr", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public referenznr?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "untersuchung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public untersuchung_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoHaeufigkeitkrebsergebnis>}
     */
    @fn.DataMember({ name: "fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id", type: [FischinfoHaeufigkeitkrebsergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id?: Array<FischinfoHaeufigkeitkrebsergebnis>;

    /**
     * @type {Array<FischinfoLaengenklassenkrebsergebnis>}
     */
    @fn.DataMember({ name: "fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id", type: [FischinfoLaengenklassenkrebsergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id?: Array<FischinfoLaengenklassenkrebsergebnis>;

    /**
     * @type {Array<FischinfoDetailkrebsergebnis>}
     */
    @fn.DataMember({ name: "fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id", type: [FischinfoDetailkrebsergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id?: Array<FischinfoDetailkrebsergebnis>;

    /**
     * @type {Array<FischinfoAnzahlkrebsergebnis>}
     */
    @fn.DataMember({ name: "fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id", type: [FischinfoAnzahlkrebsergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id?: Array<FischinfoAnzahlkrebsergebnis>;

    /**
     * @type {Array<FischinfoNachweiskrebsergebnis>}
     */
    @fn.DataMember({ name: "fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id", type: [FischinfoNachweiskrebsergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id?: Array<FischinfoNachweiskrebsergebnis>;

    /**
     * @type {FischinfoUntersuchung}
     */
    @fn.DataMember({ name: "fischinfoUntersuchungByUntersuchung_id", type: FischinfoUntersuchung, nullable: true, memberType: "JOIN", joinProperty: "untersuchung_id" })
    public fischinfoUntersuchungByUntersuchung_id?: FischinfoUntersuchung;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
