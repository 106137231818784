
<!-- #region Buttonleiste zum Auftrag //-->
<uw-modal-window-message
    *ngIf="hasRightToEdit || !isExtendedView" 
    top="0"
    [height]="buttonPanelHeight"
    zIndex="1005"
    paddingLeft="0"
    paddingRight="0"
    [class]="isExtendedView ? 'sub-tabs' : 'sub-tabs sub-tabs-last'"
>
    <article>
        <ng-container
            [ngSwitch]="mode"
        >
            <ng-container 
                *ngSwitchCase="'Delete'"
            >
                <uw-inline-button
                    iconClass="fa fa-trash-o"
                    (click)="onClickDelete($event)"
                    title="Ja, löschen"
                    value="Ja, löschen"
                ></uw-inline-button>
                <uw-inline-button
                    iconClass="fa fa-remove"
                    (click)="mode = 'Edit'" 
                    title="Nein, abbrechen"
                    value="Nein, abbrechen"
                ></uw-inline-button>
                <div class="question-button">
                    <span>Auftrag unwideruflich löschen?</span>
                </div>
            </ng-container>

            <ng-container
                *ngSwitchCase="'Edit'"
            >
                <uw-inline-button
                    iconClass="fa fa-remove"
                    (click)="onClickCancel($event)" 
                    title="Bearbeitung abbrechen"
                    value="Abbrechen"
                ></uw-inline-button>
                <uw-inline-button 
                    iconClass="fa fa-save"
                    (click)="onClickSubmit($event)" 
                    title="Änderungen speichern"
                    value="Speichern"
                ></uw-inline-button >
                <uw-inline-button
                    iconClass="fa fa-trash-o"
                    (click)="mode = 'Delete'" 
                    title="Auftrag löschen"
                    value="Löschen"
                ></uw-inline-button>
            </ng-container>

            <ng-container
                *ngSwitchCase="'Create'"
            >
                <uw-inline-button
                    iconClass="fa fa-remove"
                    (click)="onClickCancel($event)" 
                    title="Neuen Auftrag verwerfen"
                    value="Abbrechen"
                ></uw-inline-button>
                <uw-inline-button 
                    iconClass="fa fa-save"
                    (click)="onClickSubmit($event)" 
                    title="Neuen Auftrag anlegen"
                    value="Speichern"
                ></uw-inline-button>
            </ng-container>

            <ng-container
                *ngSwitchDefault
            >
                <uw-inline-button
                    (click)="onClickEdit($event)" 
                    title="Sachdaten des Auftrags bearbeiten"
                    iconClass="fa fa-pencil"
                    value="Bearbeiten"
                ></uw-inline-button>
            </ng-container>

        </ng-container>
    </article>
</uw-modal-window-message>
<!-- #endregion //-->

<!-- #region Warnmeldung(en) //-->
<uw-modal-window-message
    [(visibility)]="warningIsShown"
    closeable="true"
    [top]="isExtendedView ? buttonPanelHeightPx * (hasRightToEdit ? 3 : 2) : buttonPanelHeightPx"
    warning="true"
>
    <article [ngSwitch]="warningText.constructor.name">
        <p *ngIf="warningSummary"
            [textContent]="warningSummary"
        ></p>
        <ng-container *ngSwitchCase="'Array'">
            <p *ngFor="let para of warningText"
                [textContent]="para"
            ></p>
        </ng-container>
        <p *ngSwitchCase="'String'"
            [textContent]="warningText"
        ></p>
    </article>
</uw-modal-window-message>
<!-- #endregion //-->

<!-- #region Formular //-->
<section
    class="uw-tab-body"
    [class.edit]="mode === 'Edit' || mode === 'Create'"
>
    <div
        class="container-fluid"
        [class.edit-mode]="mode === 'Edit' || mode === 'Create'"
        [style.marginTop.px]="topmargin"
    >
        <div class="row">
            <div class="col-xs-15"><label>Auftrag</label></div>
        </div>
        <div class="row">
            <div class="col-xs-15 col-sm-7">
                <uw-type-ahead
                    label="Benutzer"
                    [webServiceApiUrl]="benutzerUrlTemplate"
                    keyValueMapping="id:term"
                    itemsAccess=".resultset"
                    min="0"
                    limit="32"
                    size="8"
                    [disabled]="readonly"
                    [(key)]="benutzer_id"
                    (valueChange)="new_benutzer_name = $event"
                    [placeholder]="benutzer_name || ''"
                ></uw-type-ahead>
            </div>
            <div class="col-xs-15 col-sm-3">
                <uw-type-ahead
                    label="Probestellen-Nr."
                    [webServiceApiUrl]="probestellenUrlTemplate"
                    keyValueMapping="term:name"
                    itemsAccess=".resultset"
                    min="0"
                    limit="32"
                    size="8"
                    [disabled]="readonly"
                    [(key)]="probestellennr"
                    [placeholder]="probestellennr || ''"
                ></uw-type-ahead>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-15 col-sm-4">
                <uw-textinput
                    [label]="'Gültig von'"
                    [(value)]="gueltigvon"
                    [disabled]="readonly"
                ></uw-textinput>
            </div>
            <div class="col-xs-15 col-sm-4">
                <uw-textinput
                    [label]="'Gültig bis'"
                    [(value)]="gueltigbis"
                    [disabled]="readonly"
                ></uw-textinput>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-15">
                <uw-textinput
                    [label]="'Bezeichnung'"
                    [(value)]="bezeichnung"
                    [disabled]="readonly"
                ></uw-textinput>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-15">
                <uw-textinput
                    [label]="'Anlass'"
                    [(value)]="anlass"
                    [disabled]="readonly"
                ></uw-textinput>
            </div>
        </div>
    </div>
</section>
<!-- #endregion //-->
