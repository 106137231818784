import { Component, Input, Optional, OnInit } from "@angular/core";
import { UWTab } from "ng-uw-tabs";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoQualitaetdermeldung from "../../models/fischinfo/qualitaetdermeldung/model";
import DataService from "../data/service";
import FischinfoAnlass from "../../models/fischinfo/anlass/model";
import FischinfoUntersuchunganlass from "../../models/fischinfo/untersuchunganlass/model";
import UserService from "../user/service";
/**
 * Mit dieser Komponente kann der Nutzer die Inhalte einer Untersuchung ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt nur die allgemeinen Informationen einer Untersuchung dar.
 * Dazu gehören unter Anderem Datum, Uhrzeit, Anlass und Art der Befischung, Qualität der Meldung,
 * Schlüsselwörter, Bemerkungen sowie ob die Erfassung abgeschlossen ist.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-allgemein",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungAllgemein implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    public _readonly: boolean = true;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        const prevValue = this._readonly;
        this._readonly = value;
        if (value != prevValue)
            this.refreshAnlassList();
    }
    public get untersuchungsterminDate(): string {
        const d = this.untersuchung.untersuchungstermin && new Date(this.untersuchung.untersuchungstermin);
        return !d || d.getUTCFullYear() === 1970 ? "" : ((d.getUTCDate() < 10
            ? "0" + d.getUTCDate()
            : d.getUTCDate()) + "." + ((d.getUTCMonth() + 1) < 10
                ? "0" + (d.getUTCMonth() + 1)
                : (d.getUTCMonth() + 1)) + "." + d.getUTCFullYear());
    }
    public set untersuchungsterminDate(value: string) {
        const d = (this.untersuchung.untersuchungstermin && new Date(this.untersuchung.untersuchungstermin)) || new Date(0);
        if (value
            && value.split(".").length === 3
            && parseInt(value.split(".")[2], 10) < 100) {
            value = value.split(".")[0]
                + "." + value.split(".")[1]
                + "." + ((parseInt(value.split(".")[2], 10) < 70
                    ? 2000
                    : 1900)
                    + parseInt(value.split(".")[2], 10)).toString();
        }
        const untersuchungstermin = (value
            && value.split(".").length === 3
            && parseInt(value.split(".")[0], 10) <= 31
            && parseInt(value.split(".")[0], 10) >= 1
            && parseInt(value.split(".")[1], 10) <= 12
            && parseInt(value.split(".")[1], 10) >= 1
            && parseInt(value.split(".")[2], 10) >= 1900)
            ? Date.UTC(parseInt(value.split(".")[2], 10), parseInt(value.split(".")[1], 10) - 1, parseInt(value.split(".")[0], 10), d.getUTCHours(), d.getUTCMinutes())
            : this.untersuchungsterminTime
                ? Date.UTC(1970, 0, 1, d.getUTCHours(), d.getUTCMinutes())
                : null;
        this.untersuchung.untersuchungstermin = untersuchungstermin;
    }
    public get untersuchungsterminTime(): string {
        const d = this.untersuchung.untersuchungstermin && new Date(this.untersuchung.untersuchungstermin);
        return !d || (d.getUTCHours() === 0 && d.getUTCMinutes() === 0) ? "" : ((d.getUTCHours() < 10
            ? "0" + d.getUTCHours()
            : d.getUTCHours()) + ":" + (d.getUTCMinutes() < 10
                ? "0" + d.getUTCMinutes()
                : d.getUTCMinutes()));
    }
    public set untersuchungsterminTime(value: string) {
        const d = (this.untersuchung.untersuchungstermin && new Date(this.untersuchung.untersuchungstermin)) || new Date(0);
        value = value.replace(" ", "");
        value = value.replace("min", "");
        value = value.replace("Min", "");
        value = value.replace("M", "");
        value = value.replace("h", ":");
        value = value.replace("H", ":");
        if (value
            && value.split(":").length === 2
            && !value.split(":")[1]) {
            value = value.split(":")[0];
        }
        if (value
            && value.split(":").length === 1
            && parseInt(value.split(":")[0]) < 24) {
            value = value.split(":")[0] + ":00";
        }
        if (value
            && value.split(":").length === 3
            && parseInt(value.split(":")[0]) < 24
            && parseInt(value.split(":")[0]) >= 0
            && parseInt(value.split(":")[1]) < 60
            && parseInt(value.split(":")[1]) >= 0) {
            value = value.split(":")[0] + ":" + value.split(":")[1];
        }
        const untersuchungstermin = (value
            && value.split(":").length === 2
            && parseInt(value.split(":")[0]) < 24
            && parseInt(value.split(":")[0]) >= 0
            && parseInt(value.split(":")[1]) < 60
            && parseInt(value.split(":")[1]) >= 0)
            ? Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), parseInt(value.split(":")[0], 10), parseInt(value.split(":")[1], 10), 0)
            : this.untersuchungsterminDate
                ? Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0)
                : null;
        this.untersuchung.untersuchungstermin = untersuchungstermin;
    }
    public get erfasst(): boolean {
        return this.formFischinfoUntersuchungService
            .modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .erfasst;
    }
    public set erfasst(value: boolean) {
        this.formFischinfoUntersuchungService
            .modelUtil.setOrAdd<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .erfasst = value;
    }
    public get qualitaet(): number {
        return this.formFischinfoUntersuchungService
            .modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .qualitaetdermeldung_id;
    }
    public set qualitaet(value: number) {
        this.formFischinfoUntersuchungService
            .modelUtil.setOrAdd<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .qualitaetdermeldung_id = parseInt(String(value), 10) || null;
    }
    public get ffhmonitoring(): boolean {
        return this.formFischinfoUntersuchungService
            .modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .ffhmonitoring;
    }
    public set ffhmonitoring(value: boolean) {
        this.formFischinfoUntersuchungService
            .modelUtil.setOrAdd<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .ffhmonitoring = value;
    }
    public get wrrlmonitoring(): boolean {
        return this.formFischinfoUntersuchungService
            .modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .wrrlmonitoring;
    }
    public set wrrlmonitoring(value: boolean) {
        this.formFischinfoUntersuchungService
            .modelUtil.setOrAdd<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .wrrlmonitoring = value;
    }
    public get bemerkung(): string {
        return this.formFischinfoUntersuchungService
            .modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .bemerkung;
    }
    public set bemerkung(value: string) {
        this.formFischinfoUntersuchungService
            .modelUtil.setOrAdd<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .bemerkung = value;
    }
    public get hasExtendedAdminRightToEdit(): boolean {
        return this.userService.roles
            && this.userService.roles.filter((x: string): boolean => x == "ROLE_ADMIN").length
            ? true
            : false;
    }
    constructor(public readonly formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public readonly dataService: DataService, public readonly userService: UserService,
        @Optional()
        public readonly uwtab: UWTab) {
    }
    public qualitaetdermeldung_idRefJsonItems: string;
    ngOnInit() {
        this.refreshAnlassList();
        this.dataService
            .getReferenceTable("qualitaetdermeldung", FischinfoQualitaetdermeldung, "id")
            .then(refs => {
                let refList = [];
                Object.keys(refs).forEach(key => {
                    refList.push('[' + refs[key].id + ',"' + refs[key].bezeichnung + '"]');
                });
                this.qualitaetdermeldung_idRefJsonItems = "[" + refList.join(",") + "]";
            });
    }
    public get showsBearbeiterField() {
        const managers = UserService.ManagerRoles;
        const { roles } = this.userService;
        return roles != null && roles.some(managers.includes, managers);
    }
    public get inActiveTab() {
        return !!this.uwtab && this.uwtab.active;
    }
    public _anlassList: any[];
    public get anlassList() {
        return this._anlassList;
    }
    public refreshAnlassList() {
        this.getNormalizedAnlassList()
            .then((list) => {
                this.
                    _anlassList = list;
            });
    }
    public onClickAnlass(e: any, u: FischinfoUntersuchunganlass) {
        this.formFischinfoUntersuchungService.modelUtil
            .getOrDefault<FischinfoUntersuchunganlass>(this.untersuchung, "FischinfoUntersuchunganlass", u)
            .id > 0
            ? this.formFischinfoUntersuchungService.modelUtil
                .remove<FischinfoUntersuchunganlass>(this.untersuchung, "FischinfoUntersuchunganlass", u)
            : this.formFischinfoUntersuchungService.modelUtil
                .setOrAdd<FischinfoUntersuchunganlass>(this.untersuchung, "FischinfoUntersuchunganlass", new FischinfoUntersuchunganlass({
                    id: 1,
                    anlass_id: u.anlass_id,
                    untersuchung_id: this.untersuchung.id
                }));
        this.refreshAnlassList();
    }
    public getNormalizedAnlassList(): Promise<any[]> {
        let a = this.dataService
            .getReferenceTable("anlass", FischinfoAnlass, "id");
        return Promise.all([a])
            .then(([anlass]) => {
                const values = (this.untersuchung
                    && this.untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id)
                    || [];
                let list = [];
                Object.keys(anlass)
                    .forEach(key => {
                        list.push({
                            id: (values.find(x => x.anlass_id === anlass[key].id) || { id: 0 }).id,
                            bezeichnung: anlass[key].bezeichnung,
                            anlass_id: anlass[key].id
                        });
                    });
                return list
                    .filter(x => !this.readonly || x.id > 0)
                    .sort((a, b) => {
                        a = a.bezeichnung.trim().toLowerCase()
                            .replace("ä", "ae")
                            .replace("ö", "oe")
                            .replace("ü", "ue")
                            .replace("ß", "ss");
                        b = b.bezeichnung.trim().toLowerCase()
                            .replace("ä", "ae")
                            .replace("ö", "oe")
                            .replace("ü", "ue")
                            .replace("ß", "ss");
                        return (a > b)
                            ? 1
                            : ((b > a)
                                ? -1
                                : 0);
                    });
            });
    }
}
