import { Injectable } from "@angular/core";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import UserService from "../user/service";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoAnlass from "../../models/fischinfo/anlass/model";
import FischinfoMethode from "../../models/fischinfo/methode/model";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import FischinfoDokument from "../../models/fischinfo/dokument/model";
import ModelUtil from "./model-util";
import ViewUtil from "./view-util";
import FischinfoFibsresult from "../../models/fischinfo/fibsresult/model";
import Validation from "./validation";

@Injectable({
    providedIn: 'root'
})
export default class FormFischinfoUntersuchungService {
    public modelProps = [];

    public _editMode: boolean;
    public get editMode(): boolean {
        return this._editMode;
    }
    public set editMode(value: boolean) {
        this._editMode = value;
    }

    // public befischungChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public isBeobachtung(untersuchung: FischinfoUntersuchung): boolean {
        return this.modelUtil
            .getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung")
            .beobachtung
            ? true
            : false
            ;
    }

    public modelUtil: ModelUtil;
    public viewUtil: ViewUtil;
    constructor(
        protected dataService: DataService,
        protected userService: UserService,
    ) {
        this.modelUtil = new ModelUtil();
        this.viewUtil = new ViewUtil(this.dataService, this.modelUtil);
        this.modelProps["FischinfoUntersuchung"] = FischinfoUntersuchung.prototype.listModelProperties();
        this.modelProps["FischinfoBefischung"] = FischinfoBefischung.prototype.listModelProperties();
        this.modelProps["FischinfoAnlass"] = FischinfoAnlass.prototype.listModelProperties();
        this.modelProps["FischinfoMethode"] = FischinfoMethode.prototype.listModelProperties();
        this.modelProps["FischinfoGewaesserzustand"] = FischinfoGewaesserzustand.prototype.listModelProperties();
        this.modelProps["FischinfoDokument"] = FischinfoDokument.prototype.listModelProperties();
        this.modelProps["FischinfoFibsresult"] = FischinfoFibsresult.prototype.listModelProperties();
    }

    public label(model: string, key: string) {
        return (this.modelProps[model][key] && this.modelProps[model][key].label) || "N.N.";
    }

    private _fibsversion: Promise<string>;
    public fibsVersion(): Promise<string> {
        return this._fibsversion || (this._fibsversion = this.dataService.get(
            "fibscalculator",
            { deserialize: (o: { version: string }) => o.version },
            "version",
        ));
    }

    public revise(untersuchung: FischinfoUntersuchung): FischinfoUntersuchung {
        let validation = new Validation();
        return validation.revise(untersuchung);
    }

    public validate(untersuchung: FischinfoUntersuchung) {
        let validation = new Validation();
        return validation.validate(untersuchung);
    }

    public calcCSVUntersuchungsergebnisseList(untersuchung: FischinfoUntersuchung, probenahmestelle: FischinfoProbenahmestelle): Promise<any[]> {
        interface ColumnInfo { label: string, value?: any, key?: string, get?(item: any): any };
        return this.viewUtil.getNormalizedBefischungsergebnisseList(untersuchung)
            .then(list => {
                const cols = Array.of<ColumnInfo>(
                    { label: "Probestellennr", value: probenahmestelle.probestellennr },
                    { label: "FG/SG", value: probenahmestelle.gewaessertyp },
                    { label: "Beo/Bef", value: this.modelUtil.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").beobachtung ? "beo" : "bef" },
                    { label: "Gewässername", value: probenahmestelle.gewaessernameAsString },
                    { label: "Gewässerkennzahl", value: probenahmestelle.gewaesserkennzahlAsNumber },
                    { label: "Lagebeschreibung", value: probenahmestelle.lagebeschreibung },
                    { label: "ETRS89E", value: probenahmestelle.etrs89e },
                    { label: "ETRS89N", value: probenahmestelle.etrs89n },
                    { label: "Befischung-ID", value: this.modelUtil.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").id },
                    { label: "Erfassungsnr", value: untersuchung.lfdnr },
                    { label: "Datum", value: untersuchung.untersuchungsterminAsLocaleDateString },
                    { label: "Befischungsgerät", value: untersuchung.methode },
                    { label: "Befischte Länge", value: untersuchung.befischteLaenge },
                    { label: "Artname", key: "art" },
                    { label: "Art-ID", key: "art_id" },
                    {
                        isBeobachtung: this.modelUtil.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").beobachtung,
                        label: "Art beobachtet",
                        get(item) { return item.untersuchungstyp === "Nachweis" || this.isBeobachtung ? "ja" : "nein"; },
                    } as ColumnInfo & ThisType<ColumnInfo & { isBeobachtung: boolean }>,
                    { label: "Häufigkeit", key: "haeufigkeit" },
                    { label: "Summe", key: "summe" },
                    ...this.viewUtil.skalaKeys.map((col) => ({ label: col, key: col })),
                )

                const tabDef = [
                    cols.map(col => col.label),
                    ...list
                        .filter(x => x.gruppe === "Fische")
                        .map(item => cols.map(col =>
                            col.hasOwnProperty("key") ? item[col.key] : col.hasOwnProperty("get") ? col.get(item) : col.value
                        )),
                ];

                return tabDef;
            });
    }
}
