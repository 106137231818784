import { NgModule } from "@angular/core";
import UWOverlay from "./directive";
import UWOverlayLayer from "./layer/component";

@NgModule({
    declarations: [
        UWOverlay,
        UWOverlayLayer,
    ],
    exports: [
        UWOverlay,
        UWOverlayLayer,
    ],
})
export default class UWOverlayModule {
}
