<div [style.height.px]="height" class="uw-map" [class.uw-map-hover]="mouseoverUTFGridFeature">
</div>
<div class="uw-map-tool-control">
    <div [class.active]="toolControlBarViewToggle" (click)="clickOnToolControl($event)" title="Werkzeugleiste ein-/ausblenden"><i class="fa fa-compass"></i></div>
    <div class="sub" *ngIf="toolControlBarViewToggle" (click)="clickOnResetMapExtentToolControl($event)" title="Zur Startausdehnung der Karte wechseln"><i class="fa fa-globe"></i></div>
    <div class="sub" *ngIf="toolControlBarViewToggle" (click)="onClickSearchLocationButton($event)" title="Standortsuche nach Koordinaten"><i class="fa fa-search"></i></div>
    <div class="sub" *ngIf="toolControlBarViewToggle" (click)="onClickReloadButton($event)" title="Daten auffrischen"><i class="fa fa-refresh"></i></div>
    <div class="sub" *ngIf="toolControlBarViewToggle" (click)="onClickExportCSVButton($event)" title="Filterergebnis in Datei speichern"><i class="fa fa-download"></i></div>
    <div class="sub active uw-map-create-control" *ngIf="toolControlBarViewToggle && hasRightToCreatePS" (click)="onClickAddProbenahmestelle($event)" title="Eine neue Probenahmestelle in die Karte einzeichnen"><i class="fa fa-plus-circle"></i></div>
    <div class="sub" *ngIf="toolControlBarViewToggle" (click)="onClickInfoButton($event)" title="Impressum"><i class="fa fa-info-circle"></i></div>
    <div class="sub" *ngIf="toolControlBarViewToggle" (click)="onClickHelpButton($event)" title="Hilfe"><i class="fa fa-question-circle"></i></div>
    <div (click)="clickOnToolControl($event)" class="last active" title="Werkzeugleiste ein-/ausblenden"><i [class]="toolControlBarViewToggle?'fa fa-chevron-left':'fa fa-chevron-right'"></i></div>
</div>
<div class="uw-map-zoom-control">
    <div (click)="clickOnZoomIn($event)" title="In die Kartenansicht hineinzoomen"><i class="fa fa-plus"></i></div>
    <div (click)="clickOnZoomOut($event)" title="Aus der Kartenansicht herauszoomen"><i class="fa fa-minus"></i></div>
</div>
<div class="uw-map-layer-control">
    <div (click)="switchBaseLayer('karte')" [class.active]="baseLayer === 'karte'" title="Karte"><span>Karte</span></div>
    <div (click)="switchBaseLayer('luftbild')" [class.active]="baseLayer === 'luftbild'" title="Luftbild"><span>Luftbild</span></div>
</div>