import { Component, Input, Output, EventEmitter, SimpleChange } from "@angular/core";
import ModalService from "./../modal/service";
import UserService from "../user/service";
import MapService from "../map/service";
import { Point } from "geojson";
import { take } from "rxjs/operators";
import { Subscription, Observable } from "rxjs";
import GridLayerService from "../map-gridlayer/service";
@Component({
    selector: "uw-modal-window",
    templateUrl: "./default.component.html",
    styleUrls: [
        "./tabs.directive.less",
        "./default.component.less",
        "./default.partial.modaltable.less",
        "./default.partial.arrow-header.less",
        "./default.partial.color.less"
    ]
})
export default class ModalWindow {
    @Input()
    public height: string;
    @Input()
    public width: string;
    @Input()
    public left: string;
    @Input()
    public top: string;
    @Input()
    public zIndex: string;
    @Output()
    public resize: EventEmitter<SimpleChange> = new EventEmitter<SimpleChange>();
    @Output()
    public move: EventEmitter<SimpleChange> = new EventEmitter<SimpleChange>();
    public erfassungsNrUntersuchung(modal: any): string {
        return modal
            && modal.data
            && modal.data.untersuchung
            ? modal.data.untersuchung.erfassungsNr == ""
                ? modal.data.untersuchung.fischinfoBefischungListByForeignUntersuchung_id
                    && modal.data.untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
                    && modal.data.untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].beobachtung
                    ? "Neue Beobachtung"
                    : "Neue Befischung"
                : modal.data.untersuchung.erfassungsNr
            : "";
    }
    public get hasExtendedRightAuskuenfte(): boolean {
        return this.userService.roles
            && (this.userService.roles.filter((x: string): boolean => x == "ERFASSER_INTERN" || x == "ROLE_ADMIN").length
                || this.userService.roles.filter((x: string): boolean => x == "ERFASSER_EXTERN").length < 1)
            ? true
            : false;
    }
    constructor(public modalService: ModalService, public userService: UserService, public mapService: MapService, public gridService: GridLayerService) { }
    public onModalTabChanged(e: any, modal: any): void {
        let data = this.modalService.getModalOption(modal.id, "data") || {};
        data.activeTab = e.currentValue;
        this.modalService.setModalOption(modal.id, "data", data);
    }
    public onMove(e: any, modal: any): void {
        this.modalService.setPreviousActiveModalPosition(e.currentValue.top, e.currentValue.left);
        this.move.emit(e);
    }
    public onResize(e: any, modal: any): void {
        this.modalService.setPreviousActiveModalDimensions(e.currentValue.width, e.currentValue.height);
        this.resize.emit(e);
    }
    public searchLocationError: Record<string, any> = null;
    public searchLocationMarkerId: string = null;
    public removeSearchLocationMarker() {
        if (this.searchLocationMarkerId) {
            this.mapService.removeMarker(this.searchLocationMarkerId);
            this.searchLocationMarkerId = null;
        }
    }
    public onSearchLocation(lngStr: string, latStr: string) {
        lngStr = String(lngStr).replace(/\./g, "").replace(",", ".");
        latStr = String(latStr).replace(/\./g, "").replace(",", ".");
        const lng = Number.parseFloat(lngStr);
        const lat = Number.parseFloat(latStr);
        let errors: Record<string, any> = {};
        if (Number.isNaN(lng) || Number.isNaN(lat)) {
            errors.NOT_A_NUMBER = true;
        }
        this.searchLocationError = Object.keys(errors).length ? errors : null;
        if (!this.searchLocationError) {
            if (!this.searchLocationMarkerId) {
                this.modalService.modalCloseObservable.pipe(take(1)).toPromise()
                    .then(() => {
                        this.removeSearchLocationMarker();
                    });
            }
            else {
                this.removeSearchLocationMarker();
            }
            this.searchLocationMarkerId = this.mapService.addCircleMarker(lat, lng, "EPSG:25832", {
                renderer: (this.gridService as any).renderer,
                interactive: false,
                radius: this.gridService.iconScale[18] + 2,
                stroke: true,
                weight: 2,
                color: "#FFDD00",
                opacity: 1,
                fill: false,
            });
            this.mapService.zoomToLatLng(lat, lng, "EPSG:25832");
        }
    }
    public get isExtendedView(): boolean {
        return this.userService.isLoggedIn === true;
    }
}
