import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoFischart from "./interface";
import FischinfoGewaesserstruktur from "./../../fischinfo/gewaesserstruktur/model";
import FischinfoBesatzereignis from "./../../fischinfo/besatzereignis/model";
import FischinfoFfhbewertung from "./../../fischinfo/ffhbewertung/model";
import FischinfoEntwicklungsstadium from "./../../fischinfo/entwicklungsstadium/model";
import FischinfoReferenz_fischgewicht from "./../../fischinfo/referenz_fischgewicht/model";
@fn.DataModel
export default class FischinfoFischart extends DataModel implements IFischinfoFischart {
    constructor(obj?: object | IFischinfoFischart) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoFischart): FischinfoFischart {
        return new FischinfoFischart(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoFischart): FischinfoFischart {
        Object.assign(this, obj);
        obj && obj["fischinfoGewaesserstrukturListByForeignSpezies_id"] && obj["fischinfoGewaesserstrukturListByForeignSpezies_id"].length && (this.fischinfoGewaesserstrukturListByForeignSpezies_id = obj["fischinfoGewaesserstrukturListByForeignSpezies_id"].map(FischinfoGewaesserstruktur.deserialize));
        obj && obj["fischinfoBesatzereignisListByForeignSpezies_id"] && obj["fischinfoBesatzereignisListByForeignSpezies_id"].length && (this.fischinfoBesatzereignisListByForeignSpezies_id = obj["fischinfoBesatzereignisListByForeignSpezies_id"].map(FischinfoBesatzereignis.deserialize));
        obj && obj["fischinfoFfhbewertungListByForeignArt_id"] && obj["fischinfoFfhbewertungListByForeignArt_id"].length && (this.fischinfoFfhbewertungListByForeignArt_id = obj["fischinfoFfhbewertungListByForeignArt_id"].map(FischinfoFfhbewertung.deserialize));
        obj && obj["fischinfoEntwicklungsstadiumListByForeignSpezies_id"] && obj["fischinfoEntwicklungsstadiumListByForeignSpezies_id"].length && (this.fischinfoEntwicklungsstadiumListByForeignSpezies_id = obj["fischinfoEntwicklungsstadiumListByForeignSpezies_id"].map(FischinfoEntwicklungsstadium.deserialize));
        obj && obj["fischinfoReferenz_fischgewichtListByForeignFischart_id"] && obj["fischinfoReferenz_fischgewichtListByForeignFischart_id"].length && (this.fischinfoReferenz_fischgewichtListByForeignFischart_id = obj["fischinfoReferenz_fischgewichtListByForeignFischart_id"].map(FischinfoReferenz_fischgewicht.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "artenkennziffer", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public artenkennziffer?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "dvnr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public dvnr?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "dvnr_fibs", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public dvnr_fibs?: string;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "epo", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public epo?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "erh", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public erh?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "ffh", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public ffh?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "habitat_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public habitat_id?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "hpo", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public hpo?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "hrh", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public hrh?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @required
     * @type {boolean}
     */
    @fn.DataMember({ name: "iswan", type: Boolean, databaseType: "boolean", nullable: false, memberType: "" })
    public iswan: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "lafkat_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public lafkat_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "migrationsdistanz_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public migrationsdistanz_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "migrationstyp_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public migrationstyp_id?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "mpo", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public mpo?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "mrh", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public mrh?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "nr_auswertung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public nr_auswertung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "nr_osiris", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public nr_osiris?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "reproduktion_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public reproduktion_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "schluessel_nr", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public schluessel_nr?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "trophie_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public trophie_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "wissenschaftlichebezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public wissenschaftlichebezeichnung?: string;

    /**
     * @type {Array<FischinfoGewaesserstruktur>}
     */
    @fn.DataMember({ name: "fischinfoGewaesserstrukturListByForeignSpezies_id", type: [FischinfoGewaesserstruktur], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoGewaesserstrukturListByForeignSpezies_id?: Array<FischinfoGewaesserstruktur>;

    /**
     * @type {Array<FischinfoBesatzereignis>}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisListByForeignSpezies_id", type: [FischinfoBesatzereignis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzereignisListByForeignSpezies_id?: Array<FischinfoBesatzereignis>;

    /**
     * @type {Array<FischinfoFfhbewertung>}
     */
    @fn.DataMember({ name: "fischinfoFfhbewertungListByForeignArt_id", type: [FischinfoFfhbewertung], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoFfhbewertungListByForeignArt_id?: Array<FischinfoFfhbewertung>;

    /**
     * @type {Array<FischinfoEntwicklungsstadium>}
     */
    @fn.DataMember({ name: "fischinfoEntwicklungsstadiumListByForeignSpezies_id", type: [FischinfoEntwicklungsstadium], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoEntwicklungsstadiumListByForeignSpezies_id?: Array<FischinfoEntwicklungsstadium>;

    /**
     * @type {Array<FischinfoReferenz_fischgewicht>}
     */
    @fn.DataMember({ name: "fischinfoReferenz_fischgewichtListByForeignFischart_id", type: [FischinfoReferenz_fischgewicht], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoReferenz_fischgewichtListByForeignFischart_id?: Array<FischinfoReferenz_fischgewicht>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
