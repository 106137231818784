import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoNetz from "../../models/fischinfo/netz/model";
import FischinfoMethode from "../../models/fischinfo/methode/model";
/**
 * Mit dieser Komponente kann der Nutzer die Informationen zum Befischungsgerät Reuse ansehen und ggf. bearbeiten.
 *
 * Der Nutzer kann mit dieser Komponente mehrere Netze mit unterschiedlichen Maßen angeben.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-methode-netz",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungMethodeNetz implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get fischinfoNetzList(): FischinfoNetz[] {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .fischinfoNetzListByForeignMethode_id || [];
    }
    public set expositionsdauer(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .expositionsdauer = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get expositionsdauer(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .expositionsdauer;
        return value
            && String(value).replace(".", ",");
    }
    public get firstIsSet(): boolean {
        return this.fischinfoNetzList.length > 0
            && (this.fischinfoNetzList.length > 1
                || parseInt(String(this.fischinfoNetzList[0].hoehe), 10) > 0
                || parseInt(String(this.fischinfoNetzList[0].laenge), 10) > 0);
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService) {
    }
    public ngOnInit() {
        !this.readonly && this.fischinfoNetzList.length < 1
            && (this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoNetz>(this.untersuchung, "FischinfoNetz")
                .laenge = null);
    }
    public onChangedMethodenetzangabe(e: Event, u: FischinfoNetz, index: number) {
        !this.readonly && this.fischinfoNetzList.length < 1
            && (this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoNetz>(this.untersuchung, "FischinfoNetz")
                .laenge = null);
        this.changed.emit(true);
    }
    public addModel(e: Event) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoNetz>(this.untersuchung, "FischinfoNetz")
            .laenge = null;
    }
}
