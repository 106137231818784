import DecoratorHelper from "./../helper";

export default function Label(label: string, namedLabel: { [name: string]: string } = {}): any {
    return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
        DecoratorHelper.definePropertyMetadata(target, propertyKey, {
            label: label,
            namedLabel: namedLabel
        });
    }
}
