import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import { UWAutoLoginService as UWAutologinService } from "ng-uw-login";
import UWMapView from "./../map-view/component";
import Modal from "./../modal/component";
import { SHA1 } from "crypto-js";
import { LoginService as UWLoginService } from "ng-uw-login-service";
import UserService from "./../user/service";
import DataService from "./../data/service";
import AppService from "./service";
import ModalService from "./../modal/service";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoFischart from "../../models/fischinfo/fischart/model";
import FischinfoMuschelart from "../../models/fischinfo/muschelart/model";
import FischinfoKrebsart from "../../models/fischinfo/krebsart/model";
import FischinfoStrukturelement from "../../models/fischinfo/strukturelement/model";
import FischinfoSubstrat from "../../models/fischinfo/substrat/model";
import FischinfoAusbauzustand from "../../models/fischinfo/ausbauzustand/model";
import FischinfoUferpflanze from "../../models/fischinfo/uferpflanze/model";
import FischinfoPflanzenart from "../../models/fischinfo/pflanzenart/model";
import FischinfoUmland from "../../models/fischinfo/umland/model";
import FischinfoEinzelbeeintraechtigung from "../../models/fischinfo/einzelbeeintraechtigung/model";
import DataModel from "../../models/model";
import FischinfoAnlass from "../../models/fischinfo/anlass/model";
import FischinfoHaeufigkeit from "../../models/fischinfo/haeufigkeit/model";
import FischinfoBeeintraechtigunggesamt from "../../models/fischinfo/beeintraechtigunggesamt/model";
import FischinfoWasserstand from "../../models/fischinfo/wasserstand/model";
import FischinfoStroemung from "../../models/fischinfo/stroemung/model";
import FischinfoLinienfuehrung from "../../models/fischinfo/linienfuehrung/model";
import FischinfoQualitaetdermeldung from "../../models/fischinfo/qualitaetdermeldung/model";
import FischinfoBefischungsgeraet from "../../models/fischinfo/befischungsgeraet/model";
import FischinfoBefischungsart from "../../models/fischinfo/befischungsart/model";
import FischinfoElektrobefischungsmethode from "../../models/fischinfo/elektrobefischungsmethode/model";
import FischinfoGeraetetyp from "../../models/fischinfo/geraetetyp/model";
import FischinfoStromart from "../../models/fischinfo/stromart/model";
import FischinfoAnodenart from "../../models/fischinfo/anodenart/model";
import FischinfoVorgehensweise from "../../models/fischinfo/vorgehensweise/model";
import IntragisBenutzer from "../../models/intragis/benutzer/model";
import { webServiceBaseUrl, autoLogin } from "../../config/app.config";
@Component({
    selector: "app",
    templateUrl: "./default.component.html",
    styleUrls: [
        "./default.component.less",
        "./../../assets/styles/main.less"
    ]
})
export default class App implements OnInit {
    @ViewChild("modal", { static: false })
    public modal: Modal;
    public nativeElement: any;
    public isPublicView: boolean;
    constructor(public elementRef: ElementRef, public appService: AppService, public loginService: UWLoginService, public userService: UserService, public autologinService: UWAutologinService, public dataService: DataService, public modalService: ModalService) {
        this.nativeElement = elementRef.nativeElement;
    }
    ngOnInit() {
        this.appService.nativeElement = this.nativeElement;
        this.dataService.webServiceApi =
            webServiceBaseUrl.startsWith('//')
                ? window.location.protocol + webServiceBaseUrl
                : webServiceBaseUrl.startsWith('/')
                    ? window.location.protocol + '//' + window.location.host + webServiceBaseUrl
                    : webServiceBaseUrl;
        this.isPublicView = (<Element>this.appService.nativeElementValue).hasAttribute("public")
            && (<Element>this.appService.nativeElementValue).getAttribute("public").length > 0
            ? true
            : false;
        this.isPublicView
            && (this.fetchProbenahmestellen(), this.openProbestelleFromSearch());
        !this.isPublicView
            && this.prefetchReferences();
        if (autoLogin) {
            Promise.resolve(autoLogin).then(this.loginChanged.bind(this));
        }
    }
    public async getProbestelleFromGebietsbezug(probestnr: string): Promise<FischinfoProbenahmestelle> {
        const gebietsbezugUrl = this.dataService.transcribeURL(this.dataService.webServiceApiListUrl
            .replace("{folder}", "{folder1}/{folder2}")
            .replace("?", "?term={term}&"), { folder1: "gebietsbezug", folder2: "probestellennr", term: probestnr }, 200);
        const gebietsbezugList = await this.dataService.getAll(gebietsbezugUrl, { deserialize: x => x });
        const gebietsbezug = gebietsbezugList && gebietsbezugList.find(gb => gb.term === probestnr);
        return gebietsbezug && new FischinfoProbenahmestelle({
            id: parseInt(gebietsbezug.value),
            probestellennr: gebietsbezug.term,
        });
    }
    public async openProbestelleFromSearch(search?: string) {
        search = search || location.search;
        const psnrMatch = search.match(/[?&]ps-nr=([^?&#]+)/);
        if (psnrMatch != null) {
            const probestnr = psnrMatch[1];
            const folder = "probenahmestelle";
            if (this.dataService.folder === folder) {
                let item = { probestellennr: probestnr } as Partial<FischinfoProbenahmestelle>;
                const data = {
                    probenahmestelleToBeLoaded: item,
                    untersuchungToBeLoaded: null,
                    wmsFeatureRequest: null,
                    zoomOnFeature: true,
                    type: "probenahmestelle",
                    typeLabel: "Probenahmestelle",
                    activeTab: "probenahmestelle",
                    loaded: 0,
                    error: null,
                };
                const modalId: string = this.modalService.createModal({
                    title: "Anfrage wird ausgeführt…",
                    status: "Wird geladen…",
                    data,
                });
                item = await this.getProbestelleFromGebietsbezug(probestnr).catch(_ => void 0);
                if (item != null) {
                    data.probenahmestelleToBeLoaded = item;
                    data.wmsFeatureRequest = [item];
                }
                else {
                    data.loaded = 1;
                    data.error = "Die Probenahmestelle konnte nicht gefunden werden.";
                }
                this.modalService.setModalOption(modalId, "data", data);
            }
        }
    }
    public fetchProbenahmestellen() {
        const a = this.dataService
            .getReferenceTable("fischart", FischinfoFischart, "id");
        const b = this.dataService
            .getReferenceTable("muschelart", FischinfoMuschelart, "id");
        const c = this.dataService
            .getReferenceTable("krebsart", FischinfoKrebsart, "id");
        return Promise.all<{
            [key: string]: DataModel;
        }>([a, b, c])
            .then(x => {
                this.dataService.loadDataStore();
                // this.debugUntersuchung("7416", "12857");
                this.isPublicView && this.prefetchReferences();
            });
    }
    public prefetchReferences() {
        this.dataService
            .getReferenceTable("qualitaetdermeldung", FischinfoQualitaetdermeldung, "id");
        this.dataService
            .getReferenceTable("strukturelement", FischinfoStrukturelement, "id");
        this.dataService
            .getReferenceTable("substrat", FischinfoSubstrat, "id");
        this.dataService
            .getReferenceTable("ausbauzustand", FischinfoAusbauzustand, "id");
        this.dataService
            .getReferenceTable("uferpflanze", FischinfoUferpflanze, "id");
        this.dataService
            .getReferenceTable("pflanzenart", FischinfoPflanzenart, "id");
        this.dataService
            .getReferenceTable("umland", FischinfoUmland, "id");
        this.dataService
            .getReferenceTable("haeufigkeit", FischinfoHaeufigkeit, "id");
        this.dataService
            .getReferenceTable("einzelbeeintraechtigung", FischinfoEinzelbeeintraechtigung, "id");
        this.dataService
            .getReferenceTable("anlass", FischinfoAnlass, "id");
        this.dataService
            .getReferenceTable("beeintraechtigunggesamt", FischinfoBeeintraechtigunggesamt, "id");
        this.dataService
            .getReferenceTable("wasserstand", FischinfoWasserstand, "id");
        this.dataService
            .getReferenceTable("stroemung", FischinfoStroemung, "id");
        this.dataService
            .getReferenceTable("linienfuehrung", FischinfoLinienfuehrung, "id");
        this.dataService
            .getReferenceTable("befischungsgeraet", FischinfoBefischungsgeraet, "id");
        this.dataService
            .getReferenceTable("befischungsart", FischinfoBefischungsart, "id");
        this.dataService
            .getReferenceTable("elektrobefischungsmethode", FischinfoElektrobefischungsmethode, "id");
        this.dataService
            .getReferenceTable("geraetetyp", FischinfoGeraetetyp, "id");
        this.dataService
            .getReferenceTable("stromart", FischinfoStromart, "id");
        this.dataService
            .getReferenceTable("anodenart", FischinfoAnodenart, "id");
        this.dataService
            .getReferenceTable("vorgehensweise", FischinfoVorgehensweise, "id");
    }
    public ngAfterViewInit(): void {
        if (this.appService.isIFrame) {
            this.toggleFullscreen();
        }
    }
    public toggleFullscreen(): void {
        if (!this.appService.isFullscreenMode) {
            document.body.classList.add("fullscreen");
        }
        else {
            document.body.classList.remove("fullscreen");
        }
        this.appService.isFullscreenMode = !this.appService.isFullscreenMode;
        if (this.modal) {
            this.modal.left = this.appService.isFullscreenMode
                ? this.appService.width - this.modalService.previousActiveModalDimensions.width
                - 6
                : this.appService.width - this.modalService.previousActiveModalDimensions.width;
            this.modal.left = this.modal.left < 0
                ? 0
                : this.modal.left;
            this.modalService.setPreviousActiveModalPosition(this.modal.top, this.modal.left);
        }
        setTimeout(() => {
            this.modalService.checkIfModalsAreInView(this.appService.isFullscreenMode);
        }, 100);
    }
    public loginChanged(e: any) {
        this.loginService.login = e.target.username;
        this.loginService.password = e.target.passwordSHA1;
        this.dataService.webServiceApiTokenBasedLoginUrl =
            this.dataService.webServiceApiTokenBasedLoginUrl.split("&_session=")[0] +
            "&_session=" +
            e.detail.id;
        this.userService.id = e.detail.id;
        this.userService.token = e.detail.token;
        this.userService.name = "";
        this.userService.beschreibungRoles = "";
        this.userService.roles = [];
        this.dataService.requestJsonURI(this.dataService.webServiceApiItemUrl, "login", {
            folder: "benutzer",
            id: this.userService.id
        }, response => {
            const user: IntragisBenutzer = response
                && response.resultset
                && response.resultset.resultset
                && response.resultset.resultset[0]
                ? response.resultset.resultset[0]
                : null;
            if (user
                && user.aktiviert
                && !user.gesperrt
                && (!user.gueltigbis
                    ||
                    Date.parse(String(user.gueltigbis)) > Date.now())) {
                this.userService.user = user;
                this.userService.isLoggedIn = e.target.isLoggedIn;
                this.fetchProbenahmestellen();
            }
            else {
                console.log("not allowed", Date.parse(String(user.gueltigbis)), user.gueltigbis);
            }
        }, error => console.error(error));
    }
    public onModalMove() {
    }
    public onModalResize() {
    }
    public debugUntersuchung(probenahmestelleId: string, untersuchungId: string) {
        /*
        this.loginChanged({
           target: {
               username: "JosSch",
               passwordSHA1: SHA1("JosSch"),
               isLoggedIn: true
           },
           detail: {
               id: 1
           }
        });
        this.userService.isEditor = true;
        */
        this.modalService.closeModals();
        const modalId: string = this.modalService.createModal({
            title: "Gebiete",
            status: "",
            data: {
                activeTab: "untersuchung",
                loaded: 0
            }
        });
        this.dataService.get("untersuchung", FischinfoUntersuchung, untersuchungId)
            .then(item => {
                let data = this.modalService.getModalOption(modalId, "data") || {};
                data.loaded = 1;
                data.untersuchung = item;
                this.modalService.setModalOption(modalId, "data", data);
            });
        this.dataService.get("probenahmestelle", FischinfoProbenahmestelle, probenahmestelleId, null, null, false)
            .then(item => {
                let data = this.modalService.getModalOption(modalId, "data") || {};
                data.probenahmestelle = item;
                this.modalService.setModalOption(modalId, "data", data);
            });
    }
}
