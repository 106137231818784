import { Component, Input, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoFibsresult from "../../models/fischinfo/fibsresult/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import DataService from "../data/service";
/**
 * Mit dieser Komponente kann der Nutzer die Ergebnisse der FIBS-Bewertung einer Untersuchung ansehen.
 *
 * Diese Komponente stellt die Ergebnisse der FIBS-Bewertung für Natural Water Bodies (NBW) und Heavily Modified Water Body (HMWB)
 * gegenüber. Dazu gehören unter Anderem die Bewertungen der Qualitätmerkmale und Parameter.
 */
@Component({
    selector: "form-fischinfo-untersuchung-fibs-vergleich",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungFibsVergleich implements OnInit {
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public nwbfibsresult: FischinfoFibsresult;
    public hmwbfibsresult: FischinfoFibsresult;
    public individuensumme: number;
    public readonly novalue: string = "k.A.";
    public readonly zustandsklasseNames: {
        [zk: number]: string;
    } = Object.freeze({
        "1": "sehr gut",
        "2": "gut",
        "3": "mäßig",
        "4": "unbefriedigend",
        "5": "schlecht",
        "-999": "N/A",
    });
    public readonly zustandsklasseListJSON: string = JSON.stringify([
        { id: 1, term: "1 – sehr gut" },
        { id: 2, term: "2 – gut" },
        { id: 3, term: "3 – mäßig" },
        { id: 4, term: "4 – unbefriedigend" },
        { id: 5, term: "5 – schlecht" },
    ]);
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    ngOnInit() {
        const fibsresultList = this.formFischinfoUntersuchungService.modelUtil
            .getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .fischinfoFibsresultListByForeignBefischung_id;
        this.nwbfibsresult = fibsresultList && fibsresultList.find(fr => fr.nwb)
            || ({} as FischinfoFibsresult);
        this.hmwbfibsresult = fibsresultList && fibsresultList.find(fr => !fr.nwb)
            || ({} as FischinfoFibsresult);
        this.getIndividuensumme().then(sum => this.individuensumme = sum);
    }
    public get zustand(): string {
        const zk = this.nwbfibsresult.zustandsklasse;
        return zk && ((zk === -999 ? this.zustandsklasseNames[zk] : zk)
            + (this.zustandsklasseNames[zk] ? " – " + this.zustandsklasseNames[zk] : ""));
    }
    public get potenzial(): string {
        const zk = this.hmwbfibsresult.potenzial;
        return zk && ((zk === -999 ? this.zustandsklasseNames[zk] : zk)
            + (this.zustandsklasseNames[zk] ? " – " + this.zustandsklasseNames[zk] : ""));
    }
    public label(field: string): string {
        return this.formFischinfoUntersuchungService.label("FischinfoFibsresult", field);
    }
    public readonly cssEqualClassesByBool = Object.freeze({
        true: "wb-values-eq",
        false: "wb-values-ne",
        null: "",
    });
    public cssEqualClasses(field: keyof FischinfoFibsresult) {
        return this.cssEqualClassesByBool[String(this.nwbfibsresult && this.hmwbfibsresult
            ? this.nwbfibsresult[field] === this.hmwbfibsresult[field]
            : null)];
    }
    public novalueOr<T = any, U = T>(val: T, map?: ((val: T) => U) | ((val: T) => T)) {
        map = map || (x => x);
        return Number(val) === -999 ? this.zustandsklasseNames["-999"] : val != null ? map(val) : this.novalue;
    }
    public novalueOrTruncatedNumber(val: number) {
        const truncnum = (n: number) => {
            let s = n.toString();
            const d = s.indexOf(".");
            if (d >= 0) {
                if (s.length - d - 1 > 2) {
                    s = n.toFixed(2);
                }
                s = s.slice(0, d) + "," + s.slice(d + 1);
            }
            return s;
        };
        return this.novalueOr(val, truncnum);
    }
    public getIndividuensumme(): Promise<number> {
        let b = this
            .formFischinfoUntersuchungService
            .viewUtil
            .getNormalizedBefischungsergebnisseList(this.untersuchung);
        return b
            .then((untersuchungsergebnisseList) => untersuchungsergebnisseList
            .filter(x => x.gruppe === "Fische" && x.summe > 0)
            .reduce((a, b) => a + b.summe, 0));
    }
}
