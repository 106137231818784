import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoNatura2000 from "./interface";
import FischinfoProbenahmestelle from "./../../fischinfo/probenahmestelle/model";
@fn.DataModel
export default class FischinfoNatura2000 extends DataModel implements IFischinfoNatura2000 {
    constructor(obj?: object | IFischinfoNatura2000) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoNatura2000): FischinfoNatura2000 {
        return new FischinfoNatura2000(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoNatura2000): FischinfoNatura2000 {
        Object.assign(this, obj);
        obj && obj["fischinfoProbenahmestelleListByForeignNatura2000_id"] && obj["fischinfoProbenahmestelleListByForeignNatura2000_id"].length && (this.fischinfoProbenahmestelleListByForeignNatura2000_id = obj["fischinfoProbenahmestelleListByForeignNatura2000_id"].map(FischinfoProbenahmestelle.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "kennung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public kennung?: string;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoProbenahmestelle>}
     */
    @fn.DataMember({ name: "fischinfoProbenahmestelleListByForeignNatura2000_id", type: [FischinfoProbenahmestelle], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoProbenahmestelleListByForeignNatura2000_id?: Array<FischinfoProbenahmestelle>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
