<uw-tabs>

    <uw-modal-window-message
        *ngIf="userService.isEditor"
        [height]="buttonPanelHeight"
        [paddingLeft]="0"
        [paddingRight]="0"
    >
        <article>
            <uw-inline-button
                iconClass="fa fa-plus-circle"
                (click)="onClickCreateDataItem($event)" 
                title="Neue ... erfassen"
                value="Neue ... erfassen"
            ></uw-inline-button>
        </article>
    </uw-modal-window-message>

    <section class="uw-tab-body">
        <div
            class="container-fluid"
            [style.marginTop.px]="topmargin"
        >
            <div class="row">
                <div class="col-md-15">
                    <table class="uw-modal-table">
                        <thead>
                            <tr>
                                <th
                                    width="140px"
                                    title="PS-Nr."
                                >PS-Nr.</th>
                                <th
                                    width="20%"
                                    title="GKZ"
                                >GKZ</th>
                                <th
                                    width="40%"
                                    title="Gewässername"
                                >Gewässername</th>
                                <th
                                    width="20%"
                                    title="Gewässertyp"
                                >Gewässertyp</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="let probestelle of probestellen"
                            >
                                <tr
                                    (click)="onClickModalWMSFeature($event, probestelle)"
                                >
                                    <td
                                        [title]="probestelle.probestellennrAsString"
                                        style="padding: 0 2px 0 0;"><uw-inline-button
                                        iconClass="fa"
                                        title="{{probestelle.probestellennrAsString}}"
                                        value="{{probestelle.probestellennrAsString}}"
                                        stopPropagation="false"
                                    ></uw-inline-button></td>
                                    <td
                                        [title]="probestelle.gewaesserkennzahlAsNumber"
                                    >{{probestelle.gewaesserkennzahlAsNumber}}</td>
                                    <td
                                        [title]="probestelle.gewaessernameAsString"
                                    >{{probestelle.gewaessernameAsString}}</td>
                                    <td
                                        [title]="probestelle.gewaessertyp"
                                    >{{probestelle.gewaessertyp}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                        <tfoot>
                            <tr *ngIf="modal.data.loaded && probestellen.length < 1">
                                <td colspan="5">Es sind keine Probestellen im angewählten Kartenbereich vorhanden.</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </section>

</uw-tabs>
