 <uw-type-ahead
    class="uw-map-search"
    placeholder="Gewässer oder Gebiet suchen"
    webServiceApiUrl="{{dataService.webServiceApiUrl}}gebietsbezug?term={term}&limit={limit}&format=application/json"
    webServiceApiTokenBasedLoginUrl="{{dataService.webServiceApiUrl}}login?loginname={login}&password={password}&format=application/json"
    keyValueMapping="id:name"
    itemsAccess=".resultset"
    min="0"
    limit="48"
    size="16"
    submit="fa fa-map-marker"
    (change)="onMapSearchInputChanged($event)"
></uw-type-ahead>
