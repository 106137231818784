import { Component, Input, Output, EventEmitter } from "@angular/core";
@Component({
    selector: "uw-modal-window-message",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowMessage {
    @Input()
    public top: string = "0";
    @Input()
    public zIndex: string = "1000";
    @Input()
    public height: string = "auto";
    @Input()
    public paddingLeft: string = "10";
    @Input()
    public paddingRight: string = "10";
    @Input()
    public warning: string = "false";
    @Input()
    public closeable: string = "false";
    public visibilityValue: string = "true";
    @Output()
    public visibilityChange: EventEmitter<string> = new EventEmitter();
    @Input()
    public get visibility(): string {
        return this.visibilityValue;
    }
    public set visibility(value: string) {
        this.visibilityValue = value;
        this.visibilityChange.emit(this.visibilityValue);
    }
    constructor() { }
    public close(e: MouseEvent) {
        this.visibility = "false";
    }
}
