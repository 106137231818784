import { Component, Input, AfterViewInit, OnDestroy } from "@angular/core";
import UserService from "../user/service";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import { UWCsvService as CSVService } from "ng-uw-csv-service";
import DataService from "./../data/service";
import { LoginService } from "ng-uw-login-service";
/**
 * Mit dieser Komponente kann der Nutzer die Inhalte einer Untersuchung ansehen und ggf. bearbeiten.
 *
 * Aufgrund des Umfangs der Informationen in einer Untersuchung ist die Ansicht
 * in Teilformulare durch entsprechenden Komponenten aufgebrochen.
 * Diese Komponente koordiniert die Anzeige  verschiedene Formularteile
 *
 * Die Namen aller Komponenten der Formularteile beginnen mit
 * `FormFischinfoUntersuchung`/`form-fischinfo-untersuchung`.
 *
 * Diese Komponente wird als Inhalt eines schwebenden Fensters verwendet.
 */
@Component({
    selector: "uw-modal-window-tab-untersuchung",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowTabUntersuchung implements AfterViewInit {
    @Input()
    public modal: any;
    public _mode: string;
    @Input()
    public set mode(value: string) { const oldValue = this._mode; this._mode = value; if (value === "Edit" && oldValue !== "Edit") { /* this.onFormChangedToEditMode() */ } }
    public get mode(): string { return this._mode; }
    public _topmargin: string;
    @Input()
    public set topmargin(value: string) { this._topmargin = String(Number(value)); /* - (this.userService.isEditor ? 0 : Number(this.buttonPanelHeight))); */ }
    ;
    public get topmargin(): string { return this._topmargin; }
    ;
    s;
    public buttonPanelHeight: string = "24";
    public get buttonPanelHeightPx(): number {
        return Number(this.buttonPanelHeight);
    }
    public confirmSaveIsShown: boolean = false;
    public warningIsShown: string = "false";
    public warningSummary: string;
    public warningText: string | Array<string> = "";
    public warningTarget: any;
    public warningStack: Array<any> = [];
    public get probenahmestelle(): FischinfoProbenahmestelle {
        return this.modal && this.modal.data && this.modal.data.probenahmestelle;
    }
    public untersuchungInCache: FischinfoUntersuchung;
    public get untersuchung(): FischinfoUntersuchung {
        return this.modal && this.modal.data && this.modal.data.untersuchung;
    }
    public set untersuchung(item: FischinfoUntersuchung) {
        this.modal && this.modal.data && (this.modal.data.untersuchung = item);
    }
    public get isBeobachtung(): boolean {
        return this.formFischinfoUntersuchungService.isBeobachtung(this.untersuchung);
    }
    public get hasErfassungsNr(): boolean {
        const erfNr = this.untersuchung.erfassungsNr;
        return erfNr != null && erfNr.length > 0;
    }
    public selectedTab: string = "befischung";
    public selectedSubTab: string = "befischung.default";
    public selectedSubTabs: string[] = [];
    public tabChange(e) {
        if (e.currentValue.split(".").length > 1) {
            this.selectedSubTab = e.currentValue;
        }
        else {
            this.selectedSubTab = this.selectedSubTabs[e.currentValue]
                ||
                (e.currentValue === "gewaesser" && this.isBeobachtung
                    ? e.currentValue + ".sonstiges"
                    : e.currentValue + ".default");
        }
        this.selectedSubTabs[this.selectedSubTab.split(".")[0]] = this.selectedSubTab;
    }
    public get readonly(): boolean {
        return !this.formFischinfoUntersuchungService.editMode;
    }
    public isExtendedViewContentShown: boolean = true;
    public onClickEdit(e: any) {
        if (!this.modal.data.saving) {
            this.untersuchungInCache = new FischinfoUntersuchung(JSON.parse(JSON.stringify(this.untersuchung)));
            this.isExtendedViewContentShown = false;
            this.mode = e.mode === "Create"
                ? "Create"
                : "Edit";
            setTimeout(() => {
                this.formFischinfoUntersuchungService.editMode = true;
                this.isExtendedViewContentShown = true;
            }, 0);
        }
    }
    public onClickCancel(e) {
        if (!this.modal.data.saving) {
            this.warningText = "";
            this.warningIsShown = "false";
            if (this.mode === "Create") {
                this.formFischinfoUntersuchungService.editMode = false;
                this.modal.data.activeTab = "probenahmestelle";
                this.modal.data.untersuchung = null;
            }
            else {
                this.isExtendedViewContentShown = false;
                this.mode = "";
                setTimeout(() => {
                    this.untersuchung = new FischinfoUntersuchung(JSON.parse(JSON.stringify(this.untersuchungInCache)));
                    this.formFischinfoUntersuchungService.editMode = false;
                    this.isExtendedViewContentShown = true;
                }, 0);
            }
        }
    }
    public onClickValidate(e) {
        const validationFail = this.formFischinfoUntersuchungService.validate(this.untersuchung);
        const validationMessage = (validationFail && validationFail.fails || [])
            .map(x => x.validationText + " " + x.warningText);
        this.warningStack = this.warningStack.filter(x => x.target !== "validator").concat(validationMessage.map(message => ({ target: "validator", message })));
        this.warningSummary = validationFail.message;
        this.warningText = validationMessage;
        this.warningIsShown = validationMessage.length ? "true" : "false";
        this.confirmSaveIsShown = false;
    }
    public onClickSubmit(e, mode, ignoreWarning?: boolean) {
        this.warningStack.forEach((element, index) => {
            if (element.target === "submit") {
                this.warningStack.splice(index, 1);
            }
        });
        this.validate();
        if (ignoreWarning === true || !this.warningStack.length) {
            if (!this.modal.data.saving) {
                this.modal.data.saving = true;
                const validUntersuchungInCache = new FischinfoUntersuchung(JSON.parse(JSON.stringify(this.untersuchungInCache)));
                this.untersuchungInCache = new FischinfoUntersuchung(JSON.parse(JSON.stringify(this.untersuchung)));
                const postData = new FischinfoUntersuchung(JSON.parse(JSON.stringify(this.formFischinfoUntersuchungService.revise(this.untersuchung)))), finishFunc = () => {
                    setTimeout(() => {
                        this.mode = "Edit";
                        if (mode === "Move") {
                            this.selectedTab = "befischung";
                            this.selectedSubTab = "befischung.default";
                        }
                        ;
                        this.modal.data.saving = false;
                    }, 0);
                };
                (this.mode === "Create"
                    ? this.dataService.create("untersuchung", postData)
                    : this.dataService.save("untersuchung", postData))
                    .then(response => {
                        if (this.mode === "Create") {
                            this.untersuchung = new FischinfoUntersuchung(response.body.resultset[0]);
                            this.onClickEdit({});
                        }
                    })
                    .then(() => {
                        this.modal.data.probenahmestelle = null;
                        return this.dataService.get("probenahmestelle", FischinfoProbenahmestelle, String(this.untersuchung.probenahmestelle_id), null, null, false);
                    })
                    .then(probenahmestelle => this.modal.data.probenahmestelle = probenahmestelle)
                    .then(probenahmestelle => {
                        const untersuchung = (probenahmestelle.fischinfoUntersuchungListByForeignProbenahmestelle_id || [])
                            .find(u => u.id === postData.id);
                        if (untersuchung) {
                            this.dataService.updateUntersuchungChildIdsFrom(untersuchung);
                        }
                        return probenahmestelle;
                    })
                    .then(finishFunc)
                    .catch((e) => {
                        this.untersuchungInCache = new FischinfoUntersuchung(JSON.parse(JSON.stringify(validUntersuchungInCache)));
                        this.warningTarget = null;
                        this.warningText = this.mode === "Create"
                            ? "Die neue Untersuchung konnte nicht erfolgreich angelegt werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen ein erneutes Speichern."
                            : "Die bearbeitete Untersuchung konnte nicht erfolgreich gespeichert werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen ein erneutes Speichern.";
                        this.warningIsShown = "true";
                        finishFunc();
                    });
            }
        }
        else {
            this.warningTarget = this.warningStack[0].target;
            this.warningText = this.warningStack[0].message;
            this.warningIsShown = "true";
        }
    }
    public validate() {
        this.warningStack.forEach((element, index) => {
            if (element.target === "validator") {
                this.warningStack.splice(index, 1);
            }
        });
        let validationResult = this.formFischinfoUntersuchungService.validate(this.untersuchung);
        if (validationResult && validationResult.fails.length) {
            const warningText = validationResult.fails[0].warningText;
            this.warningStack.push({ message: warningText, target: "validator" });
            this.warningSummary = validationResult.message;
            this.warningText = warningText;
            this.confirmSaveIsShown = !validationResult.isFatal;
            this.warningIsShown = "true";
        }
    }
    public changePSTo: number;
    onPSChanged(e) {
        this.changePSTo = null;
        e.suggestions.forEach(element => {
            if (element.suggestion
                && parseInt(element.key, 10) === parseInt(e.key, 10)) {
                this.changePSTo = parseInt(element.suggestion.value, 10);
            }
        });
    }
    onClickPSChange(e) {
        if (this.changePSTo) {
            this.untersuchung.probenahmestelle_id = this.changePSTo;
            this.onClickSubmit({}, "Move");
        }
    }
    public onClickMove(e) {
        this.selectedTab = "befischung";
        this.selectedSubTab = "befischung.verschieben";
    }
    public onClickDelete(e) {
        if (!this.modal.data.saving) {
            const finishFunc = () => {
                this.modal.data.loaded = 1;
                this.isExtendedViewContentShown = true;
            }, id = String(this.untersuchung.id), probenahmestelleId = String(this.untersuchung.probenahmestelle_id);
            this.isExtendedViewContentShown = false;
            this.modal.data.loaded = 0;
            this.formFischinfoUntersuchungService.editMode = false;
            this.modal.data.activeTab = "probenahmestelle";
            this.modal.data.untersuchungToBeLoaded = null;
            this.modal.data.untersuchung = null;
            return this.dataService.delete("untersuchung", id)
                .then(() => {
                    this.modal.data.probenahmestelle = null;
                    return this.dataService.get("probenahmestelle", FischinfoProbenahmestelle, probenahmestelleId, null, null, false);
                })
                .then(probenahmestelle => this.modal.data.probenahmestelle = probenahmestelle)
                .then(finishFunc)
                .catch(finishFunc);
        }
    }
    public get hasRightToEdit(): boolean {
        const erfasst = (this.untersuchung
            && this.untersuchung.fischinfoBefischungListByForeignUntersuchung_id
            && this.untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
            && this.untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].erfasst) && !(this.untersuchungInCache
                && this.untersuchungInCache.fischinfoBefischungListByForeignUntersuchung_id
                && this.untersuchungInCache.fischinfoBefischungListByForeignUntersuchung_id[0]
                && !this.untersuchungInCache.fischinfoBefischungListByForeignUntersuchung_id[0].erfasst);
        const freigegeben = (this.untersuchung
            && this.untersuchung.freigegeben)
            && !(this.untersuchungInCache
                && !this.untersuchungInCache.freigegeben);
        const eigene = this.untersuchung
            && this.untersuchung.untersuchungbenutzer_id === parseInt(this.userService.id, 10);
        return this.userService.roles
            && this.userService.roles.length
            && (this.userService.roles.filter((x: string): boolean => x == "ROLE_ADMIN").length
                || (this.userService.roles.filter((x: string): boolean => x == "ERFASSER_EXTERN" || x == "ERFASSER_INTERN").length
                    && !erfasst
                    && !freigegeben
                    && eigene))
            ? true
            : false;
    }
    public get hasRightToMovePS(): boolean {
        const roleFilter = (x: string): boolean => /* x == "ERFASSER_INTERN" || */ x == "ROLE_ADMIN";
        return this.userService.roles
            && this.userService.roles.filter(roleFilter).length
            ? true
            : false;
    }
    constructor(public userService: UserService, public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public csv: CSVService, public dataService: DataService, public loginService: LoginService) {
        this.topmargin = this.isExtendedView
            ? String(Number(this.buttonPanelHeight) * (this.hasRightToEdit ? 3 : 2))
            : this.buttonPanelHeight;
    }
    public ngAfterViewInit() {
        this.formFischinfoUntersuchungService.editMode = false;
        this.isExtendedViewContentShown = false;
        setTimeout(() => {
            this.isExtendedViewContentShown = true;
        }, 0);
        if (this.modal.data.errorMessage) {
            this.warningIsShown = "true";
            this.warningText = this.modal.data.errorMessage;
            this.modal.data.errorMessage = null;
        }
        if (this.modal.data.mode === "Create") {
            this.modal.data.mode = null;
            this.onClickEdit({ mode: "Create" });
        }
    }
    public ngOnDestroy() {
    }
    public get isExtendedView(): boolean {
        return this.userService.isLoggedIn === true;
    }
    public get showsHMWB(): boolean {
        let t: FischinfoProbenahmestelle;
        const ausweisung = (t = this.probenahmestelle) && t.ofwkAusweisung;
        return ausweisung != 'NWB';
    }
    public get showsWBVergleich(): boolean {
        return this.showsHMWB;
    }
    public get showsFIBS(): boolean {
        return this.userService.roles
            && this.userService.roles.length
            && this.untersuchung.fibsgeeignet;
    }
    public get showsFFH(): boolean {
        return this.userService.roles
            && this.userService.roles.length
            && this.untersuchung.fischinfoBefischungListByForeignUntersuchung_id
            && this.untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
            && this.untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].ffhmonitoring;
    }
    public saveUntersuchungsergebnisseAsCsvFile(e) {
        this.formFischinfoUntersuchungService
            .calcCSVUntersuchungsergebnisseList(this.untersuchung, this.probenahmestelle)
            .then(list => {
                this.csv
                    .saveAs(list, this.probenahmestelle.probestellennr
                        + "_"
                        + this.untersuchung.untersuchungsterminAsLocaleDateString
                            .replace(/\./g, "-")
                        + "_"
                        + this.untersuchung.lfdnr
                        + ".csv");
            });
    }
}
