import { Component, Input, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoMethode from "../../models/fischinfo/methode/model";
/**
 * Mit dieser Komponente kann der Nutzer die Informationen zu einer Streckenbefischung eines E-Gerät ansehen und ggf. bearbeiten.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-methode-egeraet-streckenbefischung",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungMethodeEgeraetStreckenbefischung {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public set befischtelaenge(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .befischtelaenge = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get befischtelaenge(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .befischtelaenge;
        return value
            && String(value).replace(".", ",");
    }
    public set befischtebreite(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .befischtebreite = parseFloat(String(value).replace(",", ".")) || null;
    }
    public get befischtebreite(): string {
        let value = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .befischtebreite;
        return value
            && String(value).replace(".", ",");
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService) {
    }
}
