<td><uw-select
    [disabled]="readonly"
    *ngIf="geraetetyp_idRefJsonItems"
    [jsonItems]="geraetetyp_idRefJsonItems"
    keyValueMapping="0:1"
    [(key)]="geraetetyp_id"
    nullable="true"
    size="10"
></uw-select></td>
<td><uw-select
    [disabled]="readonly"
    *ngIf="stromart_idRefJsonItems"
    [jsonItems]="stromart_idRefJsonItems"
    keyValueMapping="0:1"
    [(key)]="stromart_id"
    nullable="true"
    size="10"
></uw-select></td>
<td><uw-textinput
    [(value)]="anzahl"
    [disabled]="readonly"
></uw-textinput></td>
<td><uw-select
    [disabled]="readonly"
    jsonItems='[[true,"Ja"], [false,"Nein"]]'
    keyValueMapping="0:1"
    [(key)]="abgelesen"
></uw-select></td>
<td 
    *ngIf="!readonly"
    style="width:24px;padding:0;"
><uw-inline-uncheck-button
    checked="true"
    hideIcon="true"
    width="24"
    (click)="onClickRemoveMethodegeraetetyp($event)"
></uw-inline-uncheck-button></td>
