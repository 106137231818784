import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoPflanzenart from "./interface";
import FischinfoGewaesserzustandpflanzenart from "./../../fischinfo/gewaesserzustandpflanzenart/model";
@fn.DataModel
export default class FischinfoPflanzenart extends DataModel implements IFischinfoPflanzenart {
    constructor(obj?: object | IFischinfoPflanzenart) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoPflanzenart): FischinfoPflanzenart {
        return new FischinfoPflanzenart(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoPflanzenart): FischinfoPflanzenart {
        Object.assign(this, obj);
        obj && obj["fischinfoGewaesserzustandpflanzenartListByForeignPflanzenart_id"] && obj["fischinfoGewaesserzustandpflanzenartListByForeignPflanzenart_id"].length && (this.fischinfoGewaesserzustandpflanzenartListByForeignPflanzenart_id = obj["fischinfoGewaesserzustandpflanzenartListByForeignPflanzenart_id"].map(FischinfoGewaesserzustandpflanzenart.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoGewaesserzustandpflanzenart>}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandpflanzenartListByForeignPflanzenart_id", type: [FischinfoGewaesserzustandpflanzenart], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoGewaesserzustandpflanzenartListByForeignPflanzenart_id?: Array<FischinfoGewaesserzustandpflanzenart>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
