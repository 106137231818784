import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoMarkierungmethode from "./interface";
import FischinfoBesatzereignis from "./../../fischinfo/besatzereignis/model";
@fn.DataModel
export default class FischinfoMarkierungmethode extends DataModel implements IFischinfoMarkierungmethode {
    constructor(obj?: object | IFischinfoMarkierungmethode) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoMarkierungmethode): FischinfoMarkierungmethode {
        return new FischinfoMarkierungmethode(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoMarkierungmethode): FischinfoMarkierungmethode {
        Object.assign(this, obj);
        obj && obj["fischinfoBesatzereignisListByForeignMarkierungmethode_id"] && obj["fischinfoBesatzereignisListByForeignMarkierungmethode_id"].length && (this.fischinfoBesatzereignisListByForeignMarkierungmethode_id = obj["fischinfoBesatzereignisListByForeignMarkierungmethode_id"].map(FischinfoBesatzereignis.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "kurzbezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public kurzbezeichnung?: string;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoBesatzereignis>}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisListByForeignMarkierungmethode_id", type: [FischinfoBesatzereignis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzereignisListByForeignMarkierungmethode_id?: Array<FischinfoBesatzereignis>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
