import { Injectable } from "@angular/core";
import IntragisBenutzer from "../../models/intragis/benutzer/model";
import IntragisBenutzerrolle from "../../models/intragis/benutzerrolle/model";

/**
 * Dieser Dienst liefert Daten über den angemeldeten Nutzer und dessen Rechte.
 */
@Injectable({
    providedIn: 'root'
})
export default class UserService {
    public static ReadonlyId: number = 3;
    public static AdminOrganisation: number = 1;
    public static ManagerRoles = Object.freeze(["ERFASSER_EXTERN", "ERFASSER_INTERN", "ROLE_ADMIN"]);

    public name: string = "Unbekannter Nutzer";
    public beschreibungRoles: string = "";
    public roles: Array<string> = [];
    public id: string = "999999999";
    public isLoggedIn: boolean = false;
    public isEditor: boolean = false;
    public isAdmin: boolean = false;

    public tokenValue: string;
    public set token(value: string) {
        this.tokenValue = value && value.length > 0 ? value : null;
        // Todo: setUserByToken, meanwhile:
        this.isLoggedIn = this.tokenValue ? true : false;
    };

    public get token(): string {
        return this.tokenValue;
    }

    public editModeAppAttr: boolean;

    public hasRightToEditForm(): boolean {
        return this.editModeAppAttr && this.isLoggedIn ? true : false;
    }

    public set user(user: IntragisBenutzer) {
        if (user !== null) {
            if (
                user.intragisBenutzerrolleListByForeignBenutzer_id
                && user.intragisBenutzerrolleListByForeignBenutzer_id.length
            ) {
                const roleFilter = (x: IntragisBenutzerrolle): boolean =>
                    x.intragisRolleByRolle_id
                    && (
                        x.intragisRolleByRolle_id.rolle == "ERFASSER_EXTERN"
                        || x.intragisRolleByRolle_id.rolle == "ERFASSER_INTERN"
                        || x.intragisRolleByRolle_id.rolle == "ROLE_ADMIN"
                    )
                    ;
                this.roles = user.intragisBenutzerrolleListByForeignBenutzer_id
                    .filter(roleFilter)
                    .map(x => x.intragisRolleByRolle_id.rolle)
                    ;
                this.beschreibungRoles = user.intragisBenutzerrolleListByForeignBenutzer_id
                    .filter(roleFilter)
                    .map(x => x.intragisRolleByRolle_id.beschreibung)
                    .join(", ")
                    || "Externer Nutzer"
                    ;
            }
            this.id = user.id.toString();
            this.name = (
                user.intragisPersonByPerson_id
                    && user.intragisPersonByPerson_id.titel
                    ? user.intragisPersonByPerson_id.titel + " "
                    : ""
            )
                + (
                    user.intragisPersonByPerson_id
                        && user.intragisPersonByPerson_id.nachname
                        ? user.intragisPersonByPerson_id.nachname + ", "
                        : ""
                )
                + (
                    user.intragisPersonByPerson_id
                        && user.intragisPersonByPerson_id.vorname
                        ? user.intragisPersonByPerson_id.vorname
                        : ""
                )
                ;

        }
    }
}
