import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoGewaesserzustandpflanzenart from "./interface";
import FischinfoGewaesserzustand from "./../../fischinfo/gewaesserzustand/model";
import FischinfoPflanzenart from "./../../fischinfo/pflanzenart/model";
@fn.DataModel
export default class FischinfoGewaesserzustandpflanzenart extends DataModel implements IFischinfoGewaesserzustandpflanzenart {
    constructor(obj?: object | IFischinfoGewaesserzustandpflanzenart) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoGewaesserzustandpflanzenart): FischinfoGewaesserzustandpflanzenart {
        return new FischinfoGewaesserzustandpflanzenart(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoGewaesserzustandpflanzenart): FischinfoGewaesserzustandpflanzenart {
        Object.assign(this, obj);
        obj && obj["fischinfoGewaesserzustandByGewaesserzustand_id"] && (this.fischinfoGewaesserzustandByGewaesserzustand_id = FischinfoGewaesserzustand.deserialize(obj["fischinfoGewaesserzustandByGewaesserzustand_id"]));
        obj && obj["fischinfoPflanzenartByPflanzenart_id"] && (this.fischinfoPflanzenartByPflanzenart_id = FischinfoPflanzenart.deserialize(obj["fischinfoPflanzenartByPflanzenart_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gewaesserzustand_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public gewaesserzustand_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "pflanzenart_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public pflanzenart_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoGewaesserzustand}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandByGewaesserzustand_id", type: FischinfoGewaesserzustand, nullable: true, memberType: "JOIN", joinProperty: "gewaesserzustand_id" })
    public fischinfoGewaesserzustandByGewaesserzustand_id?: FischinfoGewaesserzustand;

    /**
     * @type {FischinfoPflanzenart}
     */
    @fn.DataMember({ name: "fischinfoPflanzenartByPflanzenart_id", type: FischinfoPflanzenart, nullable: true, memberType: "JOIN", joinProperty: "pflanzenart_id" })
    public fischinfoPflanzenartByPflanzenart_id?: FischinfoPflanzenart;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
