import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoArtenabundanzleitartscore from "./interface";
import FischinfoFibsresult from "./../../fischinfo/fibsresult/model";
@fn.DataModel
export default class FischinfoArtenabundanzleitartscore extends DataModel implements IFischinfoArtenabundanzleitartscore {
    constructor(obj?: object | IFischinfoArtenabundanzleitartscore) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoArtenabundanzleitartscore): FischinfoArtenabundanzleitartscore {
        return new FischinfoArtenabundanzleitartscore(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoArtenabundanzleitartscore): FischinfoArtenabundanzleitartscore {
        Object.assign(this, obj);
        obj && obj["fischinfoFibsresultByFibsresult_id"] && (this.fischinfoFibsresultByFibsresult_id = FischinfoFibsresult.deserialize(obj["fischinfoFibsresultByFibsresult_id"]));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "artname", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public artname?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "fibsresult_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public fibsresult_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "score", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public score?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoFibsresult}
     */
    @fn.DataMember({ name: "fischinfoFibsresultByFibsresult_id", type: FischinfoFibsresult, nullable: true, memberType: "JOIN", joinProperty: "fibsresult_id" })
    public fischinfoFibsresultByFibsresult_id?: FischinfoFibsresult;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
