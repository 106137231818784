import { Injectable } from "@angular/core";

import SuggestionService from "./service.interface";
import SuggestionServiceOptions from "./options.interface";
import KeyValue from "./key-value.interface";

@Injectable()
export default class NoneSuggestionService implements SuggestionService {
    constructor() {
    }

    public config(options: SuggestionServiceOptions): SuggestionService {
        return <SuggestionService>this;
    }

    public search(pattern: { [name: string]: string }): Promise<any[]> {
        return Promise.resolve([]);
    }
}
