import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import DataService from "../data/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoMuscheluntersuchung from "../../models/fischinfo/muscheluntersuchung/model";
import FischinfoKrebsuntersuchung from "../../models/fischinfo/krebsuntersuchung/model";
import FischinfoDetailbefischungergebnis from "../../models/fischinfo/detailbefischungergebnis/model";
import FischinfoDetailmuschelergebnis from "../../models/fischinfo/detailmuschelergebnis/model";
import FischinfoDetailkrebsergebnis from "../../models/fischinfo/detailkrebsergebnis/model";
import FischinfoLaengenklassenbefischungergebnis from "../../models/fischinfo/laengenklassenbefischungergebnis/model";
import FischinfoLaengenklassenmuschelergebnis from "../../models/fischinfo/laengenklassenmuschelergebnis/model";
import FischinfoLaengenklassenkrebsergebnis from "../../models/fischinfo/laengenklassenkrebsergebnis/model";
import FischinfoAnzahlbefischungergebnis from "../../models/fischinfo/anzahlbefischungergebnis/model";
import FischinfoAnzahlmuschelergebnis from "../../models/fischinfo/anzahlmuschelergebnis/model";
import FischinfoAnzahlkrebsergebnis from "../../models/fischinfo/anzahlkrebsergebnis/model";
import FischinfoHaeufigkeitbefischungergebnis from "../../models/fischinfo/haeufigkeitbefischungergebnis/model";
import FischinfoHaeufigkeitsmuschelergebnis from "../../models/fischinfo/haeufigkeitsmuschelergebnis/model";
import FischinfoHaeufigkeitkrebsergebnis from "../../models/fischinfo/haeufigkeitkrebsergebnis/model";
import FischinfoNachweisbefischungergebnis from "../../models/fischinfo/nachweisbefischungergebnis/model";
import FischinfoNachweismuschelergebnis from "../../models/fischinfo/nachweismuschelergebnis/model";
import FischinfoNachweiskrebsergebnis from "../../models/fischinfo/nachweiskrebsergebnis/model";
import { Subscription } from "rxjs";
/**
 * Mit dieser Komponente kann der Nutzer die Befischungsergebnis einer Art (Fisch, Muschel, Krebs) ansehen und ggf. bearbeiten.
 *
 * Die Komponente stellt Befischungsergebnisse in fünf verschiedenen Arten zur Verfügung:
 * Individuenanzahl mit Länge und Gewicht, Individuenanzahl nach Längenklassen, Individuenanzahl mit
 * Gesamtgewicht, Vorkommenshäufigkeit und Vorkommensnachweis.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungErgebnisseArtErfassung implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @Input()
    public artId: string;
    @Input()
    public artGruppe: string;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public _erfassungsart: string;
    public get erfassungsart(): string {
        return this._erfassungsart;
    }
    public set erfassungsart(value: string) {
        this._erfassungsart = value;
    }
    ;
    public lastDeleted: {
        Fische: {
            FischinfoLaengenklassenbefischungergebnis: FischinfoLaengenklassenbefischungergebnis;
            FischinfoDetailbefischungergebnis: FischinfoDetailbefischungergebnis[];
            FischinfoNachweisbefischungergebnis: FischinfoNachweisbefischungergebnis;
            FischinfoHaeufigkeitbefischungergebnis: FischinfoHaeufigkeitbefischungergebnis;
            FischinfoAnzahlbefischungergebnis: FischinfoAnzahlbefischungergebnis;
        };
        Krebse: {
            FischinfoLaengenklassenkrebsergebnis: FischinfoLaengenklassenkrebsergebnis;
            FischinfoDetailkrebsergebnis: FischinfoDetailkrebsergebnis[];
            FischinfoNachweiskrebsergebnis: FischinfoNachweiskrebsergebnis;
            FischinfoHaeufigkeitkrebsergebnis: FischinfoHaeufigkeitkrebsergebnis;
            FischinfoAnzahlkrebsergebnis: FischinfoAnzahlkrebsergebnis;
        };
        Muscheln: {
            FischinfoLaengenklassenmuschelergebnis: FischinfoLaengenklassenmuschelergebnis;
            FischinfoDetailmuschelergebnis: FischinfoDetailmuschelergebnis[];
            FischinfoNachweismuschelergebnis: FischinfoNachweismuschelergebnis;
            FischinfoHaeufigkeitsmuschelergebnis: FischinfoHaeufigkeitsmuschelergebnis;
            FischinfoAnzahlmuschelergebnis: FischinfoAnzahlmuschelergebnis;
        };
    } = {
        Fische: {
            FischinfoLaengenklassenbefischungergebnis: null,
            FischinfoDetailbefischungergebnis: null,
            FischinfoNachweisbefischungergebnis: null,
            FischinfoHaeufigkeitbefischungergebnis: null,
            FischinfoAnzahlbefischungergebnis: null
        },
        Krebse: {
            FischinfoLaengenklassenkrebsergebnis: null,
            FischinfoDetailkrebsergebnis: null,
            FischinfoNachweiskrebsergebnis: null,
            FischinfoHaeufigkeitkrebsergebnis: null,
            FischinfoAnzahlkrebsergebnis: null
        },
        Muscheln: {
            FischinfoLaengenklassenmuschelergebnis: null,
            FischinfoDetailmuschelergebnis: null,
            FischinfoNachweismuschelergebnis: null,
            FischinfoHaeufigkeitsmuschelergebnis: null,
            FischinfoAnzahlmuschelergebnis: null
        }
    };
    public onErfassungsartChange(e: any) {
        let item = this.artGruppe === "Fische"
            ? this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoLaengenklassenbefischungergebnis>(this.untersuchung, "FischinfoLaengenklassenbefischungergebnis", new FischinfoLaengenklassenbefischungergebnis({ id: 0, art_id: parseInt(this.artId, 10) }))
                || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoDetailbefischungergebnis[]>(this.untersuchung, "FischinfoDetailbefischungergebnis[]", [new FischinfoDetailbefischungergebnis({ id: 0, art_id: parseInt(this.artId, 10) })])
                || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoNachweisbefischungergebnis>(this.untersuchung, "FischinfoNachweisbefischungergebnis", new FischinfoNachweisbefischungergebnis({ id: 0, art_id: parseInt(this.artId, 10) }))
                || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoHaeufigkeitbefischungergebnis>(this.untersuchung, "FischinfoHaeufigkeitbefischungergebnis", new FischinfoHaeufigkeitbefischungergebnis({ id: 0, art_id: parseInt(this.artId, 10) }))
                || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoAnzahlbefischungergebnis>(this.untersuchung, "FischinfoAnzahlbefischungergebnis", new FischinfoAnzahlbefischungergebnis({ id: 0, art_id: parseInt(this.artId, 10) }))
            : this.artGruppe === "Muscheln"
                ? this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoLaengenklassenmuschelergebnis>(this.untersuchung, "FischinfoLaengenklassenmuschelergebnis", new FischinfoLaengenklassenmuschelergebnis({ id: 0, muschelart_id: parseInt(this.artId, 10) }))
                    || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoDetailmuschelergebnis[]>(this.untersuchung, "FischinfoDetailmuschelergebnis[]", [new FischinfoDetailmuschelergebnis({ id: 0, muschelart_id: parseInt(this.artId, 10) })])
                    || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoNachweismuschelergebnis>(this.untersuchung, "FischinfoNachweismuschelergebnis", new FischinfoNachweismuschelergebnis({ id: 0, muschelart_id: parseInt(this.artId, 10) }))
                    || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoHaeufigkeitsmuschelergebnis>(this.untersuchung, "FischinfoHaeufigkeitsmuschelergebnis", new FischinfoHaeufigkeitsmuschelergebnis({ id: 0, art_id: parseInt(this.artId, 10) }))
                    || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoAnzahlmuschelergebnis>(this.untersuchung, "FischinfoAnzahlmuschelergebnis", new FischinfoAnzahlmuschelergebnis({ id: 0, muschelart_id: parseInt(this.artId, 10) }))
                : this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoLaengenklassenkrebsergebnis>(this.untersuchung, "FischinfoLaengenklassenkrebsergebnis", new FischinfoLaengenklassenkrebsergebnis({ id: 0, krebsart_id: parseInt(this.artId, 10) }))
                    || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoDetailkrebsergebnis[]>(this.untersuchung, "FischinfoDetailkrebsergebnis[]", [new FischinfoDetailkrebsergebnis({ id: 0, krebsart_id: parseInt(this.artId, 10) })])
                    || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoNachweiskrebsergebnis>(this.untersuchung, "FischinfoNachweiskrebsergebnis", new FischinfoNachweiskrebsergebnis({ id: 0, krebsart_id: parseInt(this.artId, 10) }))
                    || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoHaeufigkeitkrebsergebnis>(this.untersuchung, "FischinfoHaeufigkeitkrebsergebnis", new FischinfoHaeufigkeitkrebsergebnis({ id: 0, art_id: parseInt(this.artId, 10) }))
                    || this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoAnzahlkrebsergebnis>(this.untersuchung, "FischinfoAnzahlkrebsergebnis", new FischinfoAnzahlkrebsergebnis({ id: 0, krebsart_id: parseInt(this.artId, 10) }));
        this.lastDeleted[this.artGruppe][item instanceof FischinfoLaengenklassenbefischungergebnis ? "FischinfoLaengenklassenbefischungergebnis"
            : item instanceof Array && item && item[0] && item[0] instanceof FischinfoDetailbefischungergebnis ? "FischinfoDetailbefischungergebnis"
                : item instanceof FischinfoNachweisbefischungergebnis ? "FischinfoNachweisbefischungergebnis"
                    : item instanceof FischinfoHaeufigkeitbefischungergebnis ? "FischinfoHaeufigkeitbefischungergebnis"
                        : item instanceof FischinfoAnzahlbefischungergebnis ? "FischinfoAnzahlbefischungergebnis"
                            : item instanceof FischinfoLaengenklassenmuschelergebnis ? "FischinfoLaengenklassenmuschelergebnis"
                                : item instanceof Array && item && item[0] && item[0] instanceof FischinfoDetailmuschelergebnis ? "FischinfoDetailmuschelergebnis"
                                    : item instanceof FischinfoNachweismuschelergebnis ? "FischinfoNachweismuschelergebnis"
                                        : item instanceof FischinfoHaeufigkeitsmuschelergebnis ? "FischinfoHaeufigkeitsmuschelergebnis"
                                            : item instanceof FischinfoAnzahlmuschelergebnis ? "FischinfoAnzahlmuschelergebnis"
                                                : item instanceof FischinfoLaengenklassenkrebsergebnis ? "FischinfoLaengenklassenkrebsergebnis"
                                                    : item instanceof Array && item && item[0] && item[0] instanceof FischinfoDetailkrebsergebnis ? "FischinfoDetailkrebsergebnis"
                                                        : item instanceof FischinfoNachweiskrebsergebnis ? "FischinfoNachweiskrebsergebnis"
                                                            : item instanceof FischinfoHaeufigkeitkrebsergebnis ? "FischinfoHaeufigkeitkrebsergebnis"
                                                                : item instanceof FischinfoAnzahlkrebsergebnis ? "FischinfoAnzahlkrebsergebnis"
                                                                    : "_"] = item;
        if (e.value === "laengenklassen"
            && !this.laengenklassenErgebnis) {
            this.artGruppe === "Fische"
                ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoLaengenklassenbefischungergebnis>(this.untersuchung, "FischinfoLaengenklassenbefischungergebnis", this.lastDeleted.Fische.FischinfoLaengenklassenbefischungergebnis || new FischinfoLaengenklassenbefischungergebnis({
                    id: 0,
                    art_id: parseInt(this.artId, 10),
                    befischung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung").id
                }))
                : this.artGruppe === "Muscheln"
                    ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoLaengenklassenmuschelergebnis>(this.untersuchung, "FischinfoLaengenklassenmuschelergebnis", this.lastDeleted.Muscheln.FischinfoLaengenklassenmuschelergebnis || new FischinfoLaengenklassenmuschelergebnis({
                        id: 0,
                        muschelart_id: parseInt(this.artId, 10),
                        muscheluntersuchung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMuscheluntersuchung>(this.untersuchung, "FischinfoMuscheluntersuchung").id
                    }))
                    : this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoLaengenklassenkrebsergebnis>(this.untersuchung, "FischinfoLaengenklassenkrebsergebnis", this.lastDeleted.Krebse.FischinfoLaengenklassenkrebsergebnis || new FischinfoLaengenklassenkrebsergebnis({
                        id: 0,
                        krebsart_id: parseInt(this.artId, 10),
                        krebsuntersuchung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoKrebsuntersuchung>(this.untersuchung, "FischinfoKrebsuntersuchung").id
                    }));
        }
        else if (e.value === "individuen"
            && !this.detailErgebnis) {
            this.artGruppe === "Fische"
                ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDetailbefischungergebnis[]>(this.untersuchung, "FischinfoDetailbefischungergebnis[]", this.lastDeleted.Fische.FischinfoDetailbefischungergebnis
                    && this.lastDeleted.Fische.FischinfoDetailbefischungergebnis[0]
                    && this.lastDeleted.Fische.FischinfoDetailbefischungergebnis[0].art_id
                    ? this.lastDeleted.Fische.FischinfoDetailbefischungergebnis
                    : [
                        new FischinfoDetailbefischungergebnis({
                            id: 0,
                            art_id: parseInt(this.artId, 10),
                            befischung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung").id
                        })
                    ])
                : this.artGruppe === "Muscheln"
                    ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDetailmuschelergebnis[]>(this.untersuchung, "FischinfoDetailmuschelergebnis[]", this.lastDeleted.Muscheln.FischinfoDetailmuschelergebnis
                        && this.lastDeleted.Muscheln.FischinfoDetailmuschelergebnis[0]
                        && this.lastDeleted.Muscheln.FischinfoDetailmuschelergebnis[0].muschelart_id
                        ? this.lastDeleted.Muscheln.FischinfoDetailmuschelergebnis
                        : [
                            new FischinfoDetailmuschelergebnis({
                                id: 0,
                                muschelart_id: parseInt(this.artId, 10),
                                muscheluntersuchung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMuscheluntersuchung>(this.untersuchung, "FischinfoMuscheluntersuchung").id
                            })
                        ])
                    : this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDetailkrebsergebnis[]>(this.untersuchung, "FischinfoDetailkrebsergebnis[]", this.lastDeleted.Krebse.FischinfoDetailkrebsergebnis
                        && this.lastDeleted.Krebse.FischinfoDetailkrebsergebnis[0]
                        && this.lastDeleted.Krebse.FischinfoDetailkrebsergebnis[0].krebsart_id
                        ? this.lastDeleted.Krebse.FischinfoDetailkrebsergebnis
                        : [
                            new FischinfoDetailkrebsergebnis({
                                id: 0,
                                krebsart_id: parseInt(this.artId, 10),
                                krebsuntersuchung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoKrebsuntersuchung>(this.untersuchung, "FischinfoKrebsuntersuchung").id
                            })
                        ]);
        }
        else if (e.value === "anzahl"
            && !this.anzahlErgebnis) {
            this.artGruppe === "Fische"
                ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoAnzahlbefischungergebnis>(this.untersuchung, "FischinfoAnzahlbefischungergebnis", this.lastDeleted.Fische.FischinfoAnzahlbefischungergebnis || new FischinfoAnzahlbefischungergebnis({
                    id: 0,
                    art_id: parseInt(this.artId, 10),
                    befischung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung").id
                }))
                : this.artGruppe === "Muscheln"
                    ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoAnzahlmuschelergebnis>(this.untersuchung, "FischinfoAnzahlmuschelergebnis", this.lastDeleted.Muscheln.FischinfoAnzahlmuschelergebnis || new FischinfoAnzahlmuschelergebnis({
                        id: 0,
                        muschelart_id: parseInt(this.artId, 10),
                        muscheluntersuchung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMuscheluntersuchung>(this.untersuchung, "FischinfoMuscheluntersuchung").id
                    }))
                    : this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoAnzahlkrebsergebnis>(this.untersuchung, "FischinfoAnzahlkrebsergebnis", this.lastDeleted.Krebse.FischinfoAnzahlkrebsergebnis || new FischinfoAnzahlkrebsergebnis({
                        id: 0,
                        krebsart_id: parseInt(this.artId, 10),
                        krebsuntersuchung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoKrebsuntersuchung>(this.untersuchung, "FischinfoKrebsuntersuchung").id
                    }));
        }
        else if (e.value === "haeufigkeit"
            && !this.haeufigkeitErgebnis) {
            this.artGruppe === "Fische"
                ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoHaeufigkeitbefischungergebnis>(this.untersuchung, "FischinfoHaeufigkeitbefischungergebnis", this.lastDeleted.Fische.FischinfoHaeufigkeitbefischungergebnis || new FischinfoHaeufigkeitbefischungergebnis({
                    id: 0,
                    art_id: parseInt(this.artId, 10),
                    befischung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung").id
                }))
                : this.artGruppe === "Muscheln"
                    ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoHaeufigkeitsmuschelergebnis>(this.untersuchung, "FischinfoHaeufigkeitsmuschelergebnis", this.lastDeleted.Muscheln.FischinfoHaeufigkeitsmuschelergebnis || new FischinfoHaeufigkeitsmuschelergebnis({
                        id: 0,
                        art_id: parseInt(this.artId, 10),
                        muscheluntersuchung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMuscheluntersuchung>(this.untersuchung, "FischinfoMuscheluntersuchung").id
                    }))
                    : this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoHaeufigkeitkrebsergebnis>(this.untersuchung, "FischinfoHaeufigkeitkrebsergebnis", this.lastDeleted.Krebse.FischinfoHaeufigkeitkrebsergebnis || new FischinfoHaeufigkeitkrebsergebnis({
                        id: 0,
                        art_id: parseInt(this.artId, 10),
                        krebsuntersuchung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoKrebsuntersuchung>(this.untersuchung, "FischinfoKrebsuntersuchung").id
                    }));
        }
        else if (e.value === "nachweis"
            && !this.nachweisErgebnis) {
            this.artGruppe === "Fische"
                ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoNachweisbefischungergebnis>(this.untersuchung, "FischinfoNachweisbefischungergebnis", this.lastDeleted.Fische.FischinfoNachweisbefischungergebnis || new FischinfoNachweisbefischungergebnis({
                    id: 0,
                    art_id: parseInt(this.artId, 10),
                    befischung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung").id
                }))
                : this.artGruppe === "Muscheln"
                    ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoNachweismuschelergebnis>(this.untersuchung, "FischinfoNachweismuschelergebnis", this.lastDeleted.Muscheln.FischinfoNachweismuschelergebnis || new FischinfoNachweismuschelergebnis({
                        id: 0,
                        muschelart_id: parseInt(this.artId, 10),
                        muscheluntersuchung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMuscheluntersuchung>(this.untersuchung, "FischinfoMuscheluntersuchung").id
                    }))
                    : this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoNachweiskrebsergebnis>(this.untersuchung, "FischinfoNachweiskrebsergebnis", this.lastDeleted.Krebse.FischinfoNachweiskrebsergebnis || new FischinfoNachweiskrebsergebnis({
                        id: 0,
                        krebsart_id: parseInt(this.artId, 10),
                        krebsuntersuchung_id: this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoKrebsuntersuchung>(this.untersuchung, "FischinfoKrebsuntersuchung").id
                    }));
            this.changed.emit(true);
        }
        else {
            this.changed.emit(true);
        }
        this.refreshErgebnisse();
    }
    public laengenklassenErgebnis: FischinfoLaengenklassenbefischungergebnis | FischinfoLaengenklassenmuschelergebnis | FischinfoLaengenklassenkrebsergebnis;
    public detailErgebnis: (FischinfoDetailbefischungergebnis | FischinfoDetailmuschelergebnis | FischinfoDetailkrebsergebnis)[];
    public anzahlErgebnis: FischinfoAnzahlbefischungergebnis | FischinfoAnzahlmuschelergebnis | FischinfoAnzahlkrebsergebnis;
    public haeufigkeitErgebnis: FischinfoHaeufigkeitbefischungergebnis | FischinfoHaeufigkeitsmuschelergebnis | FischinfoHaeufigkeitkrebsergebnis;
    public nachweisErgebnis: FischinfoNachweisbefischungergebnis | FischinfoNachweismuschelergebnis | FischinfoNachweiskrebsergebnis;
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    public befischungChangedSubscription: Subscription;
    public ngOnInit() {
        this.refreshErgebnisse();
    }
    public onChanged(e) {
        this.changed.emit(true);
        this.refreshErgebnisse();
    }
    public refreshErgebnisse() {
        let befischung: FischinfoBefischung | FischinfoMuscheluntersuchung | FischinfoKrebsuntersuchung = this.artGruppe === "Fische"
            ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            : this.artGruppe === "Muscheln"
                ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMuscheluntersuchung>(this.untersuchung, "FischinfoMuscheluntersuchung")
                : this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoKrebsuntersuchung>(this.untersuchung, "FischinfoKrebsuntersuchung");
        this.detailErgebnis =
            Array.of<FischinfoDetailbefischungergebnis | FischinfoDetailmuschelergebnis | FischinfoDetailkrebsergebnis>(...befischung instanceof FischinfoBefischung
                ? Array.of<FischinfoDetailbefischungergebnis>(...(befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id || []))
                : befischung instanceof FischinfoMuscheluntersuchung
                    ? Array.of<FischinfoDetailmuschelergebnis>(...(befischung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id || []))
                    : Array.of<FischinfoDetailkrebsergebnis>(...(befischung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id || []))).filter(x => x instanceof FischinfoDetailbefischungergebnis
                ? x.art_id === parseInt(this.artId, 10)
                : x instanceof FischinfoDetailmuschelergebnis
                    ? x.muschelart_id === parseInt(this.artId, 10)
                    : x.krebsart_id === parseInt(this.artId, 10));
        this.detailErgebnis = this.detailErgebnis.length
            ? this.detailErgebnis
            : null;
        this.laengenklassenErgebnis = this.detailErgebnis ? null :
            Array.of<FischinfoLaengenklassenbefischungergebnis | FischinfoLaengenklassenmuschelergebnis | FischinfoLaengenklassenkrebsergebnis>(...befischung instanceof FischinfoBefischung
                ? Array.of<FischinfoLaengenklassenbefischungergebnis>(...(befischung.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id || []))
                : befischung instanceof FischinfoMuscheluntersuchung
                    ? Array.of<FischinfoLaengenklassenmuschelergebnis>(...(befischung.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id || []))
                    : Array.of<FischinfoLaengenklassenkrebsergebnis>(...(befischung.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id || [])))
                .filter(x => x instanceof FischinfoLaengenklassenbefischungergebnis
                ? x.art_id === parseInt(this.artId, 10)
                : x instanceof FischinfoLaengenklassenmuschelergebnis
                    ? x.muschelart_id === parseInt(this.artId, 10)
                    : x.krebsart_id === parseInt(this.artId, 10))[0] || null;
        this.anzahlErgebnis = this.detailErgebnis || this.laengenklassenErgebnis ? null :
            Array.of<FischinfoAnzahlbefischungergebnis | FischinfoAnzahlmuschelergebnis | FischinfoAnzahlkrebsergebnis>(...befischung instanceof FischinfoBefischung
                ? Array.of<FischinfoAnzahlbefischungergebnis>(...(befischung.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id || []))
                : befischung instanceof FischinfoMuscheluntersuchung
                    ? Array.of<FischinfoAnzahlmuschelergebnis>(...(befischung.fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id || []))
                    : Array.of<FischinfoAnzahlkrebsergebnis>(...(befischung.fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id || [])))
                .filter(x => x instanceof FischinfoAnzahlbefischungergebnis
                ? x.art_id === parseInt(this.artId, 10)
                : x instanceof FischinfoAnzahlmuschelergebnis
                    ? x.muschelart_id === parseInt(this.artId, 10)
                    : x.krebsart_id === parseInt(this.artId, 10))[0] || null;
        this.haeufigkeitErgebnis = this.detailErgebnis || this.laengenklassenErgebnis || this.anzahlErgebnis ? null :
            Array.of<FischinfoHaeufigkeitbefischungergebnis | FischinfoHaeufigkeitsmuschelergebnis | FischinfoHaeufigkeitkrebsergebnis>(...befischung instanceof FischinfoBefischung
                ? Array.of<FischinfoHaeufigkeitbefischungergebnis>(...(befischung.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id || []))
                : befischung instanceof FischinfoMuscheluntersuchung
                    ? Array.of<FischinfoHaeufigkeitsmuschelergebnis>(...(befischung.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id || []))
                    : Array.of<FischinfoHaeufigkeitkrebsergebnis>(...(befischung.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id || [])))
                .filter(x => x.art_id === parseInt(this.artId, 10))[0] || null;
        this.nachweisErgebnis = this.detailErgebnis || this.laengenklassenErgebnis || this.anzahlErgebnis || this.haeufigkeitErgebnis ? null :
            Array.of<FischinfoNachweisbefischungergebnis | FischinfoNachweismuschelergebnis | FischinfoNachweiskrebsergebnis>(...befischung instanceof FischinfoBefischung
                ? Array.of<FischinfoNachweisbefischungergebnis>(...(befischung.fischinfoNachweisbefischungergebnisListByForeignBefischung_id || []))
                : befischung instanceof FischinfoMuscheluntersuchung
                    ? Array.of<FischinfoNachweismuschelergebnis>(...(befischung.fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id || []))
                    : Array.of<FischinfoNachweiskrebsergebnis>(...(befischung.fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id || [])))
                .filter(x => x instanceof FischinfoNachweisbefischungergebnis
                ? x.art_id === parseInt(this.artId, 10)
                : x instanceof FischinfoNachweismuschelergebnis
                    ? x.muschelart_id === parseInt(this.artId, 10)
                    : x.krebsart_id === parseInt(this.artId, 10))[0] || null;
        this.erfassungsart = this.detailErgebnis
            ? "individuen"
            : this.laengenklassenErgebnis
                ? "laengenklassen"
                : this.anzahlErgebnis
                    ? "anzahl"
                    : this.haeufigkeitErgebnis
                        ? "haeufigkeit"
                        : this.nachweisErgebnis
                            ? "nachweis"
                            : "keine";
    }
}
