import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoBesatzereignisgewaesserstruktur from "./interface";
import FischinfoBesatzereignis from "./../../fischinfo/besatzereignis/model";
import FischinfoGewaesserstruktur from "./../../fischinfo/gewaesserstruktur/model";
@fn.DataModel
export default class FischinfoBesatzereignisgewaesserstruktur extends DataModel implements IFischinfoBesatzereignisgewaesserstruktur {
    constructor(obj?: object | IFischinfoBesatzereignisgewaesserstruktur) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoBesatzereignisgewaesserstruktur): FischinfoBesatzereignisgewaesserstruktur {
        return new FischinfoBesatzereignisgewaesserstruktur(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoBesatzereignisgewaesserstruktur): FischinfoBesatzereignisgewaesserstruktur {
        Object.assign(this, obj);
        obj && obj["fischinfoBesatzereignisByBesatzereignis_id"] && (this.fischinfoBesatzereignisByBesatzereignis_id = FischinfoBesatzereignis.deserialize(obj["fischinfoBesatzereignisByBesatzereignis_id"]));
        obj && obj["fischinfoGewaesserstrukturByGewaesserstruktur_id"] && (this.fischinfoGewaesserstrukturByGewaesserstruktur_id = FischinfoGewaesserstruktur.deserialize(obj["fischinfoGewaesserstrukturByGewaesserstruktur_id"]));
        return this;
    }

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "besatzereignis_id", type: Number, databaseType: "integer", nullable: false, memberType: "UNIQUE" })
    public besatzereignis_id: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "gewaesserstruktur_id", type: Number, databaseType: "integer", nullable: false, memberType: "UNIQUE" })
    public gewaesserstruktur_id: number;

    /**
     * @required
     * @type {number} bigint
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "bigint", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {FischinfoBesatzereignis}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisByBesatzereignis_id", type: FischinfoBesatzereignis, nullable: true, memberType: "JOIN", joinProperty: "besatzereignis_id" })
    public fischinfoBesatzereignisByBesatzereignis_id?: FischinfoBesatzereignis;

    /**
     * @type {FischinfoGewaesserstruktur}
     */
    @fn.DataMember({ name: "fischinfoGewaesserstrukturByGewaesserstruktur_id", type: FischinfoGewaesserstruktur, nullable: true, memberType: "JOIN", joinProperty: "gewaesserstruktur_id" })
    public fischinfoGewaesserstrukturByGewaesserstruktur_id?: FischinfoGewaesserstruktur;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
