import {
    Injectable
} from "@angular/core";

import UWTemplateFileService from "./template";
import { UWFileService } from "./interface";

@Injectable()
export default class UWFileServiceFactory {
    constructor() {}

    createFileService<TViewModel>(
        mimeType: string,
        mapModelToBlobSource: UWTemplateFileService<TViewModel>["transformModelToBlobSource"]
    ): UWFileService<TViewModel> {
        return new UWTemplateFileService(mimeType, mapModelToBlobSource);
    }
}