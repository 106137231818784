import { Component, Input, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoPflanzenart from "../../models/fischinfo/pflanzenart/model";
import FischinfoGewaesserzustandpflanzenart from "../../models/fischinfo/gewaesserzustandpflanzenart/model";
/**
 * Mit dieser Komponente kann der Nutzer im7am befischten Gewässers vorkommende wichtige Pflanzen ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt eine Menge von Pflanzenarten dar. Der Nutzer kann diese an- und abwählen.
 */
@Component({
    selector: "form-fischinfo-untersuchung-gewaesser-struktur-zustandpflanzenart",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungGewaesserStrukturZustandpflanzenart implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public _zustandpflanzenartList: any[];
    public get zustandpflanzenartList() {
        return this._zustandpflanzenartList;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    ngOnInit() {
        this.refreshZustandpflanzenartList();
    }
    public onClickZustandpflanzenart(e: any, zustandpflanzenart: FischinfoGewaesserzustandpflanzenart) {
        this.formFischinfoUntersuchungService.modelUtil
            .getOrDefault<FischinfoGewaesserzustandpflanzenart>(this.untersuchung, "FischinfoGewaesserzustandpflanzenart", zustandpflanzenart)
            .id > 0
            ? this.formFischinfoUntersuchungService.modelUtil
                .remove<FischinfoGewaesserzustandpflanzenart>(this.untersuchung, "FischinfoGewaesserzustandpflanzenart", zustandpflanzenart)
            : this.formFischinfoUntersuchungService.modelUtil
                .setOrAdd<FischinfoGewaesserzustandpflanzenart>(this.untersuchung, "FischinfoGewaesserzustandpflanzenart", new FischinfoGewaesserzustandpflanzenart({
                id: 1,
                pflanzenart_id: zustandpflanzenart.pflanzenart_id,
                gewaesserzustand_id: this.formFischinfoUntersuchungService.modelUtil
                    .getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                    .id
            }));
        this.refreshZustandpflanzenartList();
    }
    public refreshZustandpflanzenartList() {
        this.getNormalizedZustandpflanzenartList()
            .then((list) => {
            this._zustandpflanzenartList = list;
        });
    }
    public getNormalizedZustandpflanzenartList(): Promise<any[]> {
        let a = this.dataService
            .getReferenceTable("pflanzenart", FischinfoPflanzenart, "id");
        return Promise.all([a])
            .then(([pflanzenart]) => {
            const values = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                .fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id || [];
            let list = [];
            Object.keys(pflanzenart).forEach(key => {
                list.push({
                    id: (values.find(x => x.pflanzenart_id === pflanzenart[key].id) || { id: 0 }).id,
                    bezeichnung: pflanzenart[key].bezeichnung,
                    pflanzenart_id: pflanzenart[key].id
                });
            });
            return list
                .filter(x => !this.readonly || x.id > 0)
                .sort((a, b) => {
                a = a.pflanzenart_id;
                b = b.pflanzenart_id;
                return (a > b)
                    ? 1
                    : ((b > a)
                        ? -1
                        : 0);
            });
        });
    }
}
