import {
    Directive,
    Input,
    AfterContentInit,
    Output,
    EventEmitter
} from "@angular/core";

import UWAccordionPane from "../accordion-pane/component";
import UWAccordionTab from "../accordion-pane/directive";

/**
 * Mit dieser Komponenten kann der Nutzer kategorisierte Informationen selektiv anzeigen oder ausblenden.
 * 
 * Für jede Kategorie gibt es einen Titel und einen Körper. Der Titel der Kategorie ist immer sichtbar.
 * Der Körper der Kategorie ist einblendbar. Wenn der Nutzer auf den Titel einer Kategorie klickt,
 * dann schaltet die Komponente die Sichtbarkeit des Körpers der Kategorie um.
 */
@Directive({
    selector: "[uw-accordion]"
})
export default class UWAccordion implements AfterContentInit {

    protected activePaneValue: number = -1;
    @Input() public active: number = 0;
    @Input() public get activePane(): number {
        return this.activePaneValue;
    }
    @Output() activePaneChange = new EventEmitter();
    public set activePane(pane: number) {
        this.activatePane(pane);
    }

    public _height: number = 0;
    @Input() public set accordionHeight(value: number) {
        if (this._height !== value) {
            this._height = value;
            if (this.activePaneValue > -1) {
                this.panes.forEach((pane: UWAccordionPane) => {
                    pane.animationEnabled = true;
                    pane.height = this.activePaneValue === pane.id ? this.accordionHeight : 0;
                });
                this.activePaneChange.emit(this.activePaneValue);
            }
        } else {
            this._height = value;
        }
    }
    public get accordionHeight(): number {
        return this._height;
    }

    @Input() public toggle: string = "true";

    protected panes: Array<UWAccordionPane> = [];
    protected tabs: Array<UWAccordionTab> = [];

    constructor() { }

    public classifyPane(pane: UWAccordionPane) {
        pane.id = this.panes.length;
        this.panes.push(pane);
    }

    public classifyTab(tab: UWAccordionTab) {
        tab.id = this.tabs.length;
        this.tabs.push(tab);
    }

    public activatePane(chosenPane: number, toggle?: boolean, noAnimation?: boolean) {
        toggle = toggle === false ? false : true; // default: true
        if (this.activePaneValue !== chosenPane || (toggle && this.toggle !== "false")) {
            this.panes.forEach((pane: UWAccordionPane) => {
                pane.animationEnabled = !noAnimation;
                pane.height = chosenPane === pane.id && (!toggle || this.toggle === "false" || this.activePaneValue !== pane.id) ? this.accordionHeight : 0;
            });
            this.activePaneValue = !toggle || this.toggle === "false" || this.activePaneValue !== chosenPane ? chosenPane : null;
            this.activePaneChange.emit(this.activePaneValue);
        }
    }

    public ngAfterContentInit() {
        this.activatePane(parseInt(this.active.toString(), 10), false, true);
    }

}
