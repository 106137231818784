<uw-select *ngIf="!levels"
    placeholder=""
    [disabled]="true"
    [size]="size"
></uw-select>
<ng-container *ngIf="levels">
<ng-container *ngFor="let level of levels; let i = index"
    [ngSwitch]="level"
>
    <uw-select *ngSwitchCase="null"
        [label]="labels[i] || ''"
        [disabled]="true"
        [size]="size"
        [placeholder]="childPlaceholder"
    ></uw-select>
    <uw-select *ngSwitchDefault
        [label]="labels[i] || ''"
        [infoTag]="infoTag"
        [infoTagClass]="infoTagClass"
        [disabled]="disabled"
        [limit]="limit"
        [nullable]="nullable"
        [size]="size"
        [placeholder]="i === 0 ? rootPlaceholder : childPlaceholder"
        [key]="level.firstKey"
        [itemsAccess]="itemsAccess"
        [keyValueMapping]="keyValueMapping"
        [webServiceApiUrl]="level.webServiceApiUrl"
        (change)="changeOnLevel(i, $event)"
    ></uw-select>
</ng-container>
</ng-container>