import
{ Directive
} from "@angular/core";

import UWOverlayLayer from "./layer/component";
import UWOverlayControls from "./interface"

/**
 * Diese Direktive stellt die Steuerung für schwebende Elemente dar.
 * 
 * Mit Hilfe dieser Direktive können Komponenten oder andere Direktiven Elemente der Oberfläche oberhalb anderer Oberflächenteile anzeigen. Anwendung dafür sind z.B. schwebende Fenster oder aufklappende Komponententeile.
 */
@Directive(
    { selector: "uw-overlay, [uw-overlay]"
    , providers:
        [ { provide: UWOverlay, useClass: UWOverlay }
        , { provide: "UWOverlay", useExisting: UWOverlay }
        ]
    }
)
export default class UWOverlay {
    protected layer: UWOverlayLayer;

    constructor () {
    }

    public create (content: Element, anchor: Element): UWOverlayControls {
        return this.layer.createOverlay(content, anchor);
    }

    public registerLayer (layer: UWOverlayLayer) {
        if (this.layer) throw new Error("cannot register multiple overlay layers");
        this.layer = layer;
    }

    public updateLayerBounds () {
        if (this.layer) this.layer.updateBoundingRect();
    }
}
