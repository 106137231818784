import {
    Injectable,
} from "@angular/core";

import { UWPersistentStorageService } from "ng-uw-persistent-storage-service";

/**
 * Dieser Dienst verwaltet die Anmeldedaten für eine automatische Anmeldung beim Aufruf der Anwendung.
 */
@Injectable({
    providedIn: 'root'
})
export default class UWAutoLoginService {
    constructor(
        protected storage: UWPersistentStorageService,
    ) { }

    public async storeLoginData(username: string, password: string) {
        await this.storage.setItem("lastlogin_username", username);
        await this.storage.setItem("lastlogin_password", password);
    }

    public async clearLoginData() {
        await this.storage.removeItem("lastlogin_username");
        await this.storage.removeItem("lastlogin_password");
    }

    public async getLoginData(): Promise<{ username: string, password: string }> {
        const username = await this.storage.getItem("lastlogin_username");
        const password = await this.storage.getItem("lastlogin_password");
        return (username == null || password == null) ? null : { username, password };
    }

}
