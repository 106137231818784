import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import StaticSuggestionService from "./static.service";
import FixedSuggestionService from "./fixed.service";
import DynamicSuggestionService from "./dynamic.service";
import NoneSuggestionService from "./none.service";
import SuggestionService from "./service.interface";
import SuggestionServiceOptions from "./options.interface";
import KeyValue from "./key-value.interface";
import { LoginService } from "ng-uw-login-service";

@Injectable()
export default class SuggestionFactoryService {
    constructor(
        protected http: HttpClient
        , protected login: LoginService
    ) {
    }

    public createSuggestionService(options: SuggestionServiceOptions): SuggestionService {
        if (options.jsonItems) {
            return new FixedSuggestionService().config(options);
        }
        else if (options.staticJsonFileUrl) {
            return new StaticSuggestionService(this.http).config(options);
        }
        else if (options.webServiceApiUrl) {
            return new DynamicSuggestionService(this.http, this.login).config(options);
        }
        return new NoneSuggestionService().config(options);
    }
}
