<div class="row">
    <div class="col-xs-15">
        <label>Allgemeine Angaben zur Befischung</label>
    </div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-4 disabled"><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoUntersuchung','erfassungsNr')"
        [value]="untersuchung.erfassungsNr"
        [disabled]="true"
    ></uw-textinput></div>
    <div class="hidden-xs col-sm-2"></div>
    <div class="col-xs-15 col-sm-4"><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoUntersuchung','untersuchungsterminAsLocaleDateString')"
        [(value)]="untersuchungsterminDate"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-3"><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoUntersuchung','untersuchungsterminAsLocaleTimeString')"
        [(value)]="untersuchungsterminTime"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>

<div class="row checkbox">
    <div class="col-xs-15 col-sm-6">
        <uw-checkbox
            [label]="formFischinfoUntersuchungService.label('FischinfoBefischung','erfasst')"
            [(value)]="erfasst"
            [disabled]="readonly"
            [infoTag]="!readonly && !hasExtendedAdminRightToEdit ? 'Bitte beachten Sie: Sobald Sie die Erfassung abschließen und speichern können Sie diese Untersuchung nicht mehr nachbearbeiten.' : null "
            [title]="!readonly && !hasExtendedAdminRightToEdit ? 'Bitte beachten Sie: Sobald Sie die Erfassung abschließen und speichern können Sie diese Untersuchung nicht mehr nachbearbeiten.' : '' "
            infoTagClass="fa-exclamation-circle"
            labelAt="e"
        ></uw-checkbox>
    </div>
    <div 
        class="col-xs-15 col-sm-9"
        [class.disabled]="!readonly && !hasExtendedAdminRightToEdit"
    >
        <uw-checkbox
            [label]="formFischinfoUntersuchungService.label('FischinfoUntersuchung','freigegeben')"
            [(value)]="untersuchung.freigegeben"
            [disabled]="readonly || !hasExtendedAdminRightToEdit"
            labelAt="e"
        ></uw-checkbox>
    </div>
</div>

<div class="row">
    <div
        class="col-xs-15"
        [class.disabled]="!readonly && !hasExtendedAdminRightToEdit"
    >
        <uw-select
            [disabled]="readonly || !hasExtendedAdminRightToEdit"
            *ngIf="qualitaetdermeldung_idRefJsonItems"
            [jsonItems]="qualitaetdermeldung_idRefJsonItems"
            keyValueMapping="0:1"
            size="10"
            [label]="formFischinfoUntersuchungService.label('FischinfoBefischung','qualitaetdermeldung_id')"
            [(key)]="qualitaet"
            nullable="true"
        ></uw-select>
    </div>
</div>

<div class="row checkbox">
    <div
        class="col-xs-15 col-sm-6"
        [class.disabled]="!readonly && !hasExtendedAdminRightToEdit"
    >
        <uw-checkbox
            [label]="formFischinfoUntersuchungService.label('FischinfoUntersuchung','unsicher')"
            [(value)]="untersuchung.unsicher"
            [disabled]="readonly || !hasExtendedAdminRightToEdit"
            labelAt="e"
        ></uw-checkbox>
    </div>
    <div
        class="col-xs-15 col-sm-9"
        [class.disabled]="!readonly && !hasExtendedAdminRightToEdit"
    >
        <uw-checkbox
            [label]="formFischinfoUntersuchungService.label('FischinfoUntersuchung','fibsgeeignet')"
            [(value)]="untersuchung.fibsgeeignet"
            [disabled]="readonly || !hasExtendedAdminRightToEdit"
            labelAt="e"
        ></uw-checkbox>
    </div>
</div>

<div
    *ngIf="showsBearbeiterField"
    class="row"
>
    <div class="col-xs-15"><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoUntersuchung','bearbeiter')"
        [(value)]="untersuchung.bearbeiter"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>

<div class="row checkbox">
    <div
        class="col-xs-15 col-sm-6"
        [class.disabled]="!readonly && !hasExtendedAdminRightToEdit"
    >
        <uw-checkbox
            [label]="formFischinfoUntersuchungService.label('FischinfoBefischung','ffhmonitoring')"
            [(value)]="ffhmonitoring"
            [disabled]="readonly || !hasExtendedAdminRightToEdit"
            labelAt="e"
        ></uw-checkbox>
    </div>
    <div class="col-xs-15 col-sm-9">
        <uw-checkbox
            [label]="formFischinfoUntersuchungService.label('FischinfoBefischung','wrrlmonitoring')"
            [(value)]="wrrlmonitoring"
            [disabled]="readonly"
            labelAt="e"
        ></uw-checkbox>
    </div>
</div>

<div
    class="row"
    *ngIf="anlassList?.length > 0"
    style="padding-top:10px;"
>
    <div class="col-xs-15">
        <table class="table table-striped anlassList">
            <thead>
                <tr>
                    <th title="{{formFischinfoUntersuchungService.label('FischinfoAnlass','bezeichnung')}}">{{formFischinfoUntersuchungService.label('FischinfoAnlass','bezeichnung')}}</th>
                    <th [style.width.%]="30" title=""> </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let u of anlassList"
                    class="hover"
                >
                    <td [title]="u.bezeichnung">{{u.bezeichnung}}</td>
                    <td style="padding:0;"><uw-inline-uncheck-button
                        [checked]="u.id > 0"
                        [disabled]="readonly"
                        (click)="onClickAnlass($event, u)"
                    ></uw-inline-uncheck-button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div
    class="row"
    *ngIf="!anlassList?.length"
    style="padding-top:10px;"
>
    <div class="col-xs-15"><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoAnlass','bezeichnung')"
        value=""
        disabled="true"
    ></uw-textinput></div>
</div>


<div class="row">
    <div class="col-xs-15"><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoUntersuchung','schluesselwort')"
        [(value)]="untersuchung.schluesselwort"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>

<div class="row">
    <div class="col-xs-15"><uw-textarea
        [label]="formFischinfoUntersuchungService.label('FischinfoBefischung','bemerkung')"
        [(value)]="bemerkung"
        [disabled]="readonly"
        [updateHeight]="inActiveTab"
    ></uw-textarea></div>
</div>
