import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export default class MapEditorService {
    public isEditingValue: boolean = false;
    public get isEditing(): boolean {
        return this.isEditingValue;
    }
    public set isEditing(value: boolean) {
        if (value !== this.isEditingValue) {
            this.isEditingValue = value;
            this.edit.emit(value);
        }
    }
    public isEditable: boolean;
    public isZoomable: boolean;
    public toolbarIsOpen: boolean;
    public layerName: any;
    public bbox: any = {};
    public load: EventEmitter<any> = new EventEmitter();
    public add: EventEmitter<any> = new EventEmitter();
    public save: EventEmitter<any> = new EventEmitter();
    public edit: EventEmitter<any> = new EventEmitter();
    public vanitized: EventEmitter<any> = new EventEmitter();
    public vanitizeFailed: EventEmitter<any> = new EventEmitter();
    public toggleVector(
        name: string,
        item?: any,
        the_geom?: JSON,
        projection?: string,
        style?: any,
        editable?: boolean,
        zoom?: boolean,
        bbox?: JSON
    ) {
        this.load.emit({
            name: name,
            item: item,
            the_geom: the_geom,
            projection: projection,
            style: style,
            editable: editable,
            zoom: zoom,
            bbox: bbox
        });
    }
    public addGeoJSON(
        name: string,
        geometry?: JSON,
        srs?: string,
        style?: any,
        touchModeFlag?: boolean
    ) {
        this.add.emit({
            name: name,
            geometry: geometry,
            srs: srs,
            style: style,
            touchModeFlag: touchModeFlag
        });
    }

}
