import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoLaengenklassenbefischungergebnis from "../../models/fischinfo/laengenklassenbefischungergebnis/model";
import FischinfoLaengenklassenmuschelergebnis from "../../models/fischinfo/laengenklassenmuschelergebnis/model";
import FischinfoLaengenklassenkrebsergebnis from "../../models/fischinfo/laengenklassenkrebsergebnis/model";
import FischinfoNachweisbefischungergebnis from "../../models/fischinfo/nachweisbefischungergebnis/model";
import FischinfoNachweismuschelergebnis from "../../models/fischinfo/nachweismuschelergebnis/model";
import FischinfoNachweiskrebsergebnis from "../../models/fischinfo/nachweiskrebsergebnis/model";
import FischinfoAnzahlbefischungergebnis from "../../models/fischinfo/anzahlbefischungergebnis/model";
import FischinfoAnzahlmuschelergebnis from "../../models/fischinfo/anzahlmuschelergebnis/model";
import FischinfoAnzahlkrebsergebnis from "../../models/fischinfo/anzahlkrebsergebnis/model";
import FischinfoHaeufigkeitbefischungergebnis from "../../models/fischinfo/haeufigkeitbefischungergebnis/model";
import FischinfoHaeufigkeitsmuschelergebnis from "../../models/fischinfo/haeufigkeitsmuschelergebnis/model";
import FischinfoHaeufigkeitkrebsergebnis from "../../models/fischinfo/haeufigkeitkrebsergebnis/model";
import FischinfoMuscheluntersuchung from "../../models/fischinfo/muscheluntersuchung/model";
import FischinfoKrebsuntersuchung from "../../models/fischinfo/krebsuntersuchung/model";
import FischinfoDetailmuschelergebnis from "../../models/fischinfo/detailmuschelergebnis/model";
import FischinfoDetailkrebsergebnis from "../../models/fischinfo/detailkrebsergebnis/model";
import FischinfoDetailbefischungergebnis from "../../models/fischinfo/detailbefischungergebnis/model";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import FischinfoMethode from "../../models/fischinfo/methode/model";
import FischinfoFoto from "../../models/fischinfo/foto/model";
import FischinfoDokument from "../../models/fischinfo/dokument/model";
import FischinfoUntersuchunganlass from "../../models/fischinfo/untersuchunganlass/model";
import FischinfoGewaesserzustandpflanzenart from "../../models/fischinfo/gewaesserzustandpflanzenart/model";
import FischinfoGewaesserzustandumland from "../../models/fischinfo/gewaesserzustandumland/model";
import FischinfoGewaesserzustandeinzelbeeintraechtigung from "../../models/fischinfo/gewaesserzustandeinzelbeeintraechtigung/model";
import FischinfoGewaesserzustanduferpflanze from "../../models/fischinfo/gewaesserzustanduferpflanze/model";
import FischinfoStrukturelementehaeufigkeit from "../../models/fischinfo/strukturelementehaeufigkeit/model";
import FischinfoSubstratprozent from "../../models/fischinfo/substratprozent/model";
import FischinfoGewaesserzustandausbauzustand from "../../models/fischinfo/gewaesserzustandausbauzustand/model";
import FischinfoFfhbewertung from "../../models/fischinfo/ffhbewertung/model";
import FischinfoNetz from "../../models/fischinfo/netz/model";
import FischinfoMethodegeraetetyp from "../../models/fischinfo/methodegeraetetyp/model";
import FischinfoFibsresult from "../../models/fischinfo/fibsresult/model";

export default class ModelUtil {
    constructor(
    ) {
    }

    public getOrDefault<T>(
        untersuchung: FischinfoUntersuchung,
        model: string,
        data?: T
    ): T {
        return ({
            FischinfoBefischung: () => {
                return (
                    untersuchung.fischinfoBefischungListByForeignUntersuchung_id
                    && <T>(untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0] as any)
                ) || data || {} as T
            },
            FischinfoMuscheluntersuchung: () => {
                return (
                    untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id
                    && <T>(untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0] as any)
                ) || data || {} as T
            },
            FischinfoFibsresultNWB: () => {
                let befischung = this
                    .getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung")
                    ;
                return (
                    befischung
                    && befischung.fischinfoFibsresultListByForeignBefischung_id
                    && <T>(befischung.fischinfoFibsresultListByForeignBefischung_id.find(x =>
                        x.nwb
                    ) as any)
                ) || data ||
                    new FischinfoFibsresult({
                        nwb: true,
                        befischung_id: befischung.id,
                        id: 1
                    }) as any as T
            },
            FischinfoFibsresultHMWB: () => {
                let befischung = this
                    .getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung")
                    ;
                return (
                    befischung
                    && befischung.fischinfoFibsresultListByForeignBefischung_id
                    && <T>(befischung.fischinfoFibsresultListByForeignBefischung_id.find(x =>
                        !x.nwb
                    ) as any)
                ) || data || new FischinfoFibsresult({
                    nwb: false,
                    befischung_id: befischung.id,
                    id: 1
                }) as any as T
            },
            FischinfoKrebsuntersuchung: () => {
                return (
                    untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id
                    && <T>(untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0] as any)
                ) || data || {} as T
            },
            FischinfoGewaesserzustand: () => {
                return (
                    untersuchung.fischinfoBefischungListByForeignUntersuchung_id
                    && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
                    && <T>(untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoGewaesserzustandByGewaesserzustand_id as any)
                ) || data || {} as T
            },
            FischinfoGewaesserzustandpflanzenart: () => {
                let gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                return (
                    gewaesserzustand
                    && gewaesserzustand.fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id
                    && <T>(gewaesserzustand.fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id.find(x =>
                        x.pflanzenart_id === (data as any || new FischinfoGewaesserzustandpflanzenart({ id: 1 })).pflanzenart_id
                    ) as any)
                ) || data || {} as T
            },
            FischinfoGewaesserzustandumland: () => {
                let gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                return (
                    gewaesserzustand
                    && gewaesserzustand.fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id
                    && <T>(gewaesserzustand.fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id.find(x =>
                        x.umland_id === (data as any || new FischinfoGewaesserzustandumland({ id: 1 })).umland_id
                    ) as any)
                ) || data || {} as T
            },
            FischinfoGewaesserzustandausbauzustand: () => {
                let gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                return (
                    gewaesserzustand
                    && gewaesserzustand.fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id
                    && <T>(gewaesserzustand.fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id.find(x =>
                        x.ausbauzustand_id === (data as any || new FischinfoGewaesserzustandausbauzustand({ id: 1 })).ausbauzustand_id
                    ) as any)
                ) || data || {} as T
            },
            FischinfoGewaesserzustandeinzelbeeintraechtigung: () => {
                let gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                return (
                    gewaesserzustand
                    && gewaesserzustand.fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id
                    && <T>(gewaesserzustand.fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id.find(x =>
                        x.einzelbeeintraechtigung_id === (data as any || new FischinfoGewaesserzustandeinzelbeeintraechtigung({ id: 1 })).einzelbeeintraechtigung_id
                    ) as any)
                ) || data || {} as T
            },
            FischinfoGewaesserzustanduferpflanze: () => {
                let gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                return (
                    gewaesserzustand
                    && gewaesserzustand.fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id
                    && <T>(gewaesserzustand.fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id.find(x =>
                        x.uferpflanze_id === (data as any || new FischinfoGewaesserzustanduferpflanze({ id: 1 })).uferpflanze_id
                    ) as any)
                ) || data || {} as T
            },
            FischinfoStrukturelementehaeufigkeit: () => {
                let gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                return (
                    gewaesserzustand
                    && gewaesserzustand.fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id
                    && <T>(gewaesserzustand.fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id.find(x =>
                        x.strukturelement_id === (data as any || new FischinfoStrukturelementehaeufigkeit({ id: 1 })).strukturelement_id
                    ) as any)
                ) || data || {} as T
            },
            FischinfoSubstratprozent: () => {
                let gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                return (
                    gewaesserzustand
                    && gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id
                    && <T>(gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id.find(x =>
                        x.substrat_id === (data as any || new FischinfoSubstratprozent({ id: 1 })).substrat_id
                    ) as any)
                ) || data || {} as T
            },
            FischinfoMethode: () => {
                return (
                    untersuchung.fischinfoBefischungListByForeignUntersuchung_id
                    && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
                    && <T>(untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoMethodeByMethode_id as any)
                ) || data || {} as T
            },
            FischinfoFoto: () => {
                return (
                    <T>(untersuchung.fischinfoFotoByFoto_id as any)
                ) || data || {} as T
            },
            FischinfoDokument: () => {
                return (
                    untersuchung.fischinfoFotoByFoto_id
                    && <T>(untersuchung.fischinfoFotoByFoto_id.fischinfoDokumentByDokument_id as any)
                ) || data || {} as T
            },
            FischinfoUntersuchunganlass: () => {
                return (
                    untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id
                    && untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id.length
                    && <T>(untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id.find(x =>
                        x.anlass_id === (data as any || new FischinfoUntersuchunganlass({ id: 1 })).anlass_id
                    ) as any)
                ) || data || {} as T
            },
        })[model]();
    }

    public remove<T extends (
        FischinfoUntersuchunganlass |
        FischinfoFoto | FischinfoDokument |
        FischinfoGewaesserzustand |
        FischinfoGewaesserzustandpflanzenart | FischinfoGewaesserzustandumland | FischinfoGewaesserzustandeinzelbeeintraechtigung | FischinfoGewaesserzustanduferpflanze | FischinfoGewaesserzustandausbauzustand |
        FischinfoStrukturelementehaeufigkeit | FischinfoSubstratprozent |
        FischinfoMethode |
        FischinfoNetz | FischinfoMethodegeraetetyp |
        FischinfoBefischung | FischinfoMuscheluntersuchung | FischinfoKrebsuntersuchung |
        FischinfoLaengenklassenbefischungergebnis | FischinfoLaengenklassenmuschelergebnis | FischinfoLaengenklassenkrebsergebnis |
        FischinfoAnzahlbefischungergebnis | FischinfoAnzahlmuschelergebnis | FischinfoAnzahlkrebsergebnis |
        FischinfoHaeufigkeitbefischungergebnis | FischinfoHaeufigkeitsmuschelergebnis | FischinfoHaeufigkeitkrebsergebnis |
        FischinfoNachweisbefischungergebnis | FischinfoNachweismuschelergebnis | FischinfoNachweiskrebsergebnis |
        FischinfoDetailbefischungergebnis | FischinfoDetailmuschelergebnis | FischinfoDetailkrebsergebnis |
        FischinfoDetailbefischungergebnis[] | FischinfoDetailmuschelergebnis[] | FischinfoDetailkrebsergebnis[] |
        FischinfoFfhbewertung
    )>(
        untersuchung: FischinfoUntersuchung,
        model: string,
        data?:
            FischinfoUntersuchunganlass |
            FischinfoFoto | FischinfoDokument |
            FischinfoGewaesserzustand |
            FischinfoGewaesserzustandpflanzenart | FischinfoGewaesserzustandumland | FischinfoGewaesserzustandeinzelbeeintraechtigung | FischinfoGewaesserzustanduferpflanze | FischinfoGewaesserzustandausbauzustand |
            FischinfoStrukturelementehaeufigkeit | FischinfoSubstratprozent |
            FischinfoMethode |
            FischinfoNetz | FischinfoMethodegeraetetyp |
            FischinfoBefischung | FischinfoMuscheluntersuchung | FischinfoKrebsuntersuchung |
            FischinfoLaengenklassenbefischungergebnis | FischinfoLaengenklassenmuschelergebnis | FischinfoLaengenklassenkrebsergebnis |
            FischinfoAnzahlbefischungergebnis | FischinfoAnzahlmuschelergebnis | FischinfoAnzahlkrebsergebnis |
            FischinfoHaeufigkeitbefischungergebnis | FischinfoHaeufigkeitsmuschelergebnis | FischinfoHaeufigkeitkrebsergebnis |
            FischinfoNachweisbefischungergebnis | FischinfoNachweismuschelergebnis | FischinfoNachweiskrebsergebnis |
            FischinfoDetailbefischungergebnis | FischinfoDetailmuschelergebnis | FischinfoDetailkrebsergebnis |
            FischinfoDetailbefischungergebnis[] | FischinfoDetailmuschelergebnis[] | FischinfoDetailkrebsergebnis[] |
            FischinfoFfhbewertung
    ): T {
        return ({
            FischinfoBefischung: () => {
                let item = JSON.parse(JSON.stringify(untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0] || null));
                untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0] = null;
                return <FischinfoBefischung>item;
            },
            FischinfoMuscheluntersuchung: () => {
                let item = JSON.parse(JSON.stringify(untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0] || null));
                untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0] = null;
                return <FischinfoMuscheluntersuchung>item;
            },
            FischinfoKrebsuntersuchung: () => {
                let item = JSON.parse(JSON.stringify(untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0] || null));
                untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0] = null;
                return <FischinfoKrebsuntersuchung>item;
            },
            FischinfoUntersuchunganlass: () => {
                let item = null;
                untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id =
                    (untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id || [])
                        .filter((o) => {
                            const found = o.anlass_id === (data as FischinfoUntersuchunganlass).anlass_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoHaeufigkeitbefischungergebnis>item;
            },
            FischinfoNetz: () => {
                let item = null, methode = this
                    .getOrDefault<FischinfoMethode>(untersuchung, "FischinfoMethode")
                    ;
                methode.fischinfoNetzListByForeignMethode_id =
                    (methode.fischinfoNetzListByForeignMethode_id || [])
                        .filter((o) => {
                            const found = o.id === (data as FischinfoNetz).id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoNetz>item;
            },
            FischinfoMethodegeraetetyp: () => {
                let item = null, methode = this
                    .getOrDefault<FischinfoMethode>(untersuchung, "FischinfoMethode")
                    ;
                methode.fischinfoMethodegeraetetypListByForeignMethode_id =
                    (methode.fischinfoMethodegeraetetypListByForeignMethode_id || [])
                        .filter((o) => {
                            const found = o.id === (data as FischinfoMethodegeraetetyp).id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoMethodegeraetetyp>item;
            },
            FischinfoGewaesserzustandpflanzenart: () => {
                let item = null, gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                gewaesserzustand.fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id =
                    (gewaesserzustand.fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id || [])
                        .filter((o) => {
                            const found = o.pflanzenart_id === (data as FischinfoGewaesserzustandpflanzenart).pflanzenart_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoGewaesserzustandpflanzenart>item;
            },
            FischinfoGewaesserzustandumland: () => {
                let item = null, gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                gewaesserzustand.fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id =
                    (gewaesserzustand.fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id || [])
                        .filter((o) => {
                            const found = o.umland_id === (data as FischinfoGewaesserzustandumland).umland_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoGewaesserzustandumland>item;
            },
            FischinfoGewaesserzustandausbauzustand: () => {
                let item = null, gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                gewaesserzustand.fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id =
                    (gewaesserzustand.fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id || [])
                        .filter((o) => {
                            const found = o.ausbauzustand_id === (data as FischinfoGewaesserzustandausbauzustand).ausbauzustand_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoGewaesserzustandausbauzustand>item;
            },
            FischinfoGewaesserzustandeinzelbeeintraechtigung: () => {
                let item = null, gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                gewaesserzustand.fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id =
                    (gewaesserzustand.fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id || [])
                        .filter((o) => {
                            const found = o.einzelbeeintraechtigung_id === (data as FischinfoGewaesserzustandeinzelbeeintraechtigung).einzelbeeintraechtigung_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoGewaesserzustandeinzelbeeintraechtigung>item;
            },
            FischinfoGewaesserzustanduferpflanze: () => {
                let item = null, gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                gewaesserzustand.fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id =
                    (gewaesserzustand.fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id || [])
                        .filter((o) => {
                            const found = o.uferpflanze_id === (data as FischinfoGewaesserzustanduferpflanze).uferpflanze_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoGewaesserzustanduferpflanze>item;
            },
            FischinfoStrukturelementehaeufigkeit: () => {
                let item = null, gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                gewaesserzustand.fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id =
                    (gewaesserzustand.fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id || [])
                        .filter((o) => {
                            const found = o.strukturelement_id === (data as FischinfoStrukturelementehaeufigkeit).strukturelement_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoStrukturelementehaeufigkeit>item;
            },
            FischinfoSubstratprozent: () => {
                let item = null, gewaesserzustand = this
                    .getOrDefault<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand")
                    ;
                gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id =
                    (gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id || [])
                        .filter((o) => {
                            const found = o.substrat_id === (data as FischinfoSubstratprozent).substrat_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoSubstratprozent>item;
            },
            FischinfoFfhbewertung: () => {
                let item = null;
                this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoFfhbewertungListByForeignBefischung_id =
                    (this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoFfhbewertungListByForeignBefischung_id || [])
                        .filter((o) => {
                            const found = o.art_id === (data as FischinfoFfhbewertung).art_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoFfhbewertung>item;
            },
            FischinfoLaengenklassenbefischungergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id =
                    (this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id || [])
                        .filter((o) => {
                            const found = o.art_id === (data as FischinfoLaengenklassenbefischungergebnis).art_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoLaengenklassenbefischungergebnis>item;
            },
            FischinfoAnzahlbefischungergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoAnzahlbefischungergebnisListByForeignBefischung_id =
                    (this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoAnzahlbefischungergebnisListByForeignBefischung_id || [])
                        .filter((o) => {
                            const found = o.art_id === (data as FischinfoAnzahlbefischungergebnis).art_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoAnzahlbefischungergebnis>item;
            },
            FischinfoHaeufigkeitbefischungergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id =
                    (this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id || [])
                        .filter((o) => {
                            const found = o.art_id === (data as FischinfoHaeufigkeitbefischungergebnis).art_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoHaeufigkeitbefischungergebnis>item;
            },
            FischinfoNachweisbefischungergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoNachweisbefischungergebnisListByForeignBefischung_id =
                    (this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoNachweisbefischungergebnisListByForeignBefischung_id || [])
                        .filter((o) => {
                            const found = o.art_id === (data as FischinfoNachweisbefischungergebnis).art_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoNachweisbefischungergebnis>item;
            },
            FischinfoDetailbefischungergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoDetailbefischungergebnisListByForeignBefischung_id =
                    (this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoDetailbefischungergebnisListByForeignBefischung_id || [])
                        .filter((o) => {
                            const found = o.art_id === (data as FischinfoDetailbefischungergebnis).art_id
                                && o.id === (data as FischinfoDetailbefischungergebnis).id
                                ;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoDetailbefischungergebnis>item;
            },
            "FischinfoDetailbefischungergebnis[]": () => {
                let item = null;
                this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoDetailbefischungergebnisListByForeignBefischung_id =
                    (this.getOrDefault<FischinfoBefischung>(untersuchung, "FischinfoBefischung").fischinfoDetailbefischungergebnisListByForeignBefischung_id || [])
                        .filter((o) => {
                            const found = o.art_id === ((data && data[0] || {}) as FischinfoDetailbefischungergebnis).art_id;
                            if (found) {
                                item = item || [];
                                item.push(o);
                            }
                            return !found;
                        })
                    ;
                return <FischinfoDetailbefischungergebnis[]>item;
            },
            FischinfoLaengenklassenmuschelergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id =
                    (this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id || [])
                        .filter((o) => {
                            const found = o.muschelart_id === (data as FischinfoLaengenklassenmuschelergebnis).muschelart_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoLaengenklassenmuschelergebnis>item;
            },
            FischinfoAnzahlmuschelergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id =
                    (this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id || [])
                        .filter((o) => {
                            const found = o.muschelart_id === (data as FischinfoAnzahlmuschelergebnis).muschelart_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoAnzahlmuschelergebnis>item;
            },
            FischinfoHaeufigkeitsmuschelergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id =
                    (this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id || [])
                        .filter((o) => {
                            const found = o.art_id === (data as FischinfoHaeufigkeitsmuschelergebnis).art_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoHaeufigkeitsmuschelergebnis>item;
            },
            FischinfoNachweismuschelergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id =
                    (this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id || [])
                        .filter((o) => {
                            const found = o.muschelart_id === (data as FischinfoNachweismuschelergebnis).muschelart_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoNachweismuschelergebnis>item;
            },
            FischinfoDetailmuschelergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id =
                    (this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id || [])
                        .filter((o) => {
                            const found = o.muschelart_id === (data as FischinfoDetailmuschelergebnis).muschelart_id
                                && o.id === (data as FischinfoDetailmuschelergebnis).id
                                ;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoDetailmuschelergebnis>item;
            },
            "FischinfoDetailmuschelergebnis[]": () => {
                let item = null;
                this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id =
                    (this.getOrDefault<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung").fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id || [])
                        .filter((o) => {
                            const found = o.muschelart_id === ((data && data[0] || {}) as FischinfoDetailmuschelergebnis).muschelart_id;
                            if (found) {
                                item = item || [];
                                item.push(o);
                            }
                            return !found
                        })
                    ;
                return <FischinfoDetailmuschelergebnis[]>item;
            },
            FischinfoLaengenklassenkrebsergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id =
                    (this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        .filter((o) => {
                            const found = o.krebsart_id === (data as FischinfoLaengenklassenkrebsergebnis).krebsart_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoLaengenklassenkrebsergebnis>item;
            },
            FischinfoAnzahlkrebsergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id =
                    (this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        .filter((o) => {
                            const found = o.krebsart_id === (data as FischinfoAnzahlkrebsergebnis).krebsart_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoAnzahlkrebsergebnis>item;
            },
            FischinfoHaeufigkeitkrebsergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id =
                    (this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        .filter((o) => {
                            const found = o.art_id === (data as FischinfoHaeufigkeitkrebsergebnis).art_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoHaeufigkeitkrebsergebnis>item;
            },
            FischinfoNachweiskrebsergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id =
                    (this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        .filter((o) => {
                            const found = o.krebsart_id === (data as FischinfoNachweiskrebsergebnis).krebsart_id;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoNachweiskrebsergebnis>item;
            },
            FischinfoDetailkrebsergebnis: () => {
                let item = null;
                this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id =
                    (this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        .filter((o) => {
                            const found = o.krebsart_id === (data as FischinfoDetailkrebsergebnis).krebsart_id
                                && o.id === (data as FischinfoDetailkrebsergebnis).id
                                ;
                            item = found ? o : item;
                            return !found
                        })
                    ;
                return <FischinfoDetailkrebsergebnis>item;
            },
            "FischinfoDetailkrebsergebnis[]": () => {
                let item = null;
                this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id =
                    (this.getOrDefault<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung").fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id || [])
                        .filter((o) => {
                            const found = o.krebsart_id === ((data && data[0] || {}) as FischinfoDetailkrebsergebnis).krebsart_id;
                            if (found) {
                                item = item || [];
                                item.push(o);
                            }
                            return !found
                        })
                    ;
                return <FischinfoDetailkrebsergebnis[]>item;
            }
        })[model]();
    }

    public setOrAdd<T extends (
        FischinfoUntersuchunganlass |
        FischinfoFoto | FischinfoDokument |
        FischinfoGewaesserzustand |
        FischinfoGewaesserzustandpflanzenart | FischinfoGewaesserzustandumland | FischinfoGewaesserzustandeinzelbeeintraechtigung | FischinfoGewaesserzustanduferpflanze | FischinfoGewaesserzustandausbauzustand |
        FischinfoStrukturelementehaeufigkeit | FischinfoSubstratprozent |
        FischinfoMethode |
        FischinfoNetz | FischinfoMethodegeraetetyp |
        FischinfoBefischung | FischinfoMuscheluntersuchung | FischinfoKrebsuntersuchung |
        FischinfoLaengenklassenbefischungergebnis | FischinfoLaengenklassenmuschelergebnis | FischinfoLaengenklassenkrebsergebnis |
        FischinfoAnzahlbefischungergebnis | FischinfoAnzahlmuschelergebnis | FischinfoAnzahlkrebsergebnis |
        FischinfoHaeufigkeitbefischungergebnis | FischinfoHaeufigkeitsmuschelergebnis | FischinfoHaeufigkeitkrebsergebnis |
        FischinfoNachweisbefischungergebnis | FischinfoNachweismuschelergebnis | FischinfoNachweiskrebsergebnis |
        FischinfoDetailbefischungergebnis | FischinfoDetailmuschelergebnis | FischinfoDetailkrebsergebnis |
        FischinfoDetailbefischungergebnis[] | FischinfoDetailmuschelergebnis[] | FischinfoDetailkrebsergebnis[] |
        FischinfoFfhbewertung |
        FischinfoFibsresult
    )>(
        untersuchung: FischinfoUntersuchung,
        model: string,
        data?: T
    ): T {
        return ({
            FischinfoUntersuchunganlass: () => {
                untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id =
                    untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id || [];
                return untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id.find(x =>
                    x.anlass_id === (data as FischinfoUntersuchunganlass).anlass_id
                ) || (
                        untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id.push(
                            (data as FischinfoUntersuchunganlass)
                        ) &&
                        untersuchung.fischinfoUntersuchunganlassListByForeignUntersuchung_id
                            .slice(-1)[0]
                    );
            },
            FischinfoDokument: () => {
                let foto = this.setOrAdd<FischinfoFoto>(untersuchung, "FischinfoFoto");
                (!foto.fischinfoDokumentByDokument_id || data) && (
                    foto.fischinfoDokumentByDokument_id = <FischinfoDokument>data || new FischinfoDokument({
                        id: 0
                    })
                ) && (
                        foto.dokument_id = ((data || {}) as FischinfoDokument).id || -1
                    );
                return <T>(foto.fischinfoDokumentByDokument_id as any);
            },
            FischinfoFoto: () => {
                (!untersuchung.fischinfoFotoByFoto_id || data) && (
                    untersuchung.fischinfoFotoByFoto_id = <FischinfoFoto>data || new FischinfoFoto({
                        id: 0
                    })
                ) && (
                        untersuchung
                            .foto_id = ((data || {}) as FischinfoFoto).id || -1
                    );
                return <T>(untersuchung.fischinfoFotoByFoto_id as any);
            },
            FischinfoGewaesserzustand: () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                (!befischung.fischinfoGewaesserzustandByGewaesserzustand_id || data) && (
                    befischung.fischinfoGewaesserzustandByGewaesserzustand_id = <FischinfoGewaesserzustand>data || new FischinfoGewaesserzustand({
                        id: 0,
                    })
                ) && (
                        befischung.gewaesserzustand_id = ((data || {}) as FischinfoGewaesserzustand).id || -1
                    );
                return <T>(befischung.fischinfoGewaesserzustandByGewaesserzustand_id as any);
            },
            FischinfoGewaesserzustandpflanzenart: () => {
                let gewaesserzustand = this.setOrAdd<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand");
                !gewaesserzustand.fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id && (
                    gewaesserzustand.fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id = []
                );
                return (
                    data && gewaesserzustand.fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id
                        .map(element => {
                            return (element.pflanzenart_id === (data as FischinfoGewaesserzustandpflanzenart).pflanzenart_id && data as FischinfoGewaesserzustandpflanzenart) || element
                        }).find(
                            element => element.pflanzenart_id === (data as FischinfoGewaesserzustandpflanzenart).pflanzenart_id
                        )
                ) || (
                        gewaesserzustand.fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id
                            .push(
                                <FischinfoGewaesserzustandpflanzenart>data || new FischinfoGewaesserzustandpflanzenart({
                                    id: 0,
                                    pflanzenart_id: (data as FischinfoGewaesserzustandpflanzenart).pflanzenart_id,
                                    gewaesserzustand_id: gewaesserzustand.id
                                })
                            ) && gewaesserzustand.fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoGewaesserzustandumland: () => {
                let gewaesserzustand = this.setOrAdd<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand");
                !gewaesserzustand.fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id && (
                    gewaesserzustand.fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id = []
                );
                return (
                    data && gewaesserzustand.fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id
                        .map(element => {
                            return (element.umland_id === (data as FischinfoGewaesserzustandumland).umland_id && data as FischinfoGewaesserzustandumland) || element
                        }).find(
                            element => element.umland_id === (data as FischinfoGewaesserzustandumland).umland_id
                        )
                ) || (
                        gewaesserzustand.fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id
                            .push(
                                <FischinfoGewaesserzustandumland>data || new FischinfoGewaesserzustandumland({
                                    id: 0,
                                    umland_id: (data as FischinfoGewaesserzustandumland).umland_id,
                                    gewaesserzustand_id: gewaesserzustand.id
                                })
                            ) && gewaesserzustand.fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoGewaesserzustandausbauzustand: () => {
                let gewaesserzustand = this.setOrAdd<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand");
                !gewaesserzustand.fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id && (
                    gewaesserzustand.fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id = []
                );
                return (
                    data && gewaesserzustand.fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id
                        .map(element => {
                            return (element.ausbauzustand_id === (data as FischinfoGewaesserzustandausbauzustand).ausbauzustand_id && data as FischinfoGewaesserzustandausbauzustand) || element
                        }).find(
                            element => element.ausbauzustand_id === (data as FischinfoGewaesserzustandausbauzustand).ausbauzustand_id
                        )
                ) || (
                        gewaesserzustand.fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id
                            .push(
                                <FischinfoGewaesserzustandausbauzustand>data || new FischinfoGewaesserzustandausbauzustand({
                                    id: 0,
                                    ausbauzustand_id: (data as FischinfoGewaesserzustandausbauzustand).ausbauzustand_id,
                                    gewaesserzustand_id: gewaesserzustand.id
                                })
                            ) && gewaesserzustand.fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoGewaesserzustandeinzelbeeintraechtigung: () => {
                let gewaesserzustand = this.setOrAdd<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand");
                !gewaesserzustand.fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id && (
                    gewaesserzustand.fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id = []
                );
                return (
                    data && gewaesserzustand.fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id
                        .map(element => {
                            return (element.einzelbeeintraechtigung_id === (data as FischinfoGewaesserzustandeinzelbeeintraechtigung).einzelbeeintraechtigung_id && data as FischinfoGewaesserzustandeinzelbeeintraechtigung) || element
                        }).find(
                            element => element.einzelbeeintraechtigung_id === (data as FischinfoGewaesserzustandeinzelbeeintraechtigung).einzelbeeintraechtigung_id
                        )
                ) || (
                        gewaesserzustand.fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id
                            .push(
                                <FischinfoGewaesserzustandeinzelbeeintraechtigung>data || new FischinfoGewaesserzustandeinzelbeeintraechtigung({
                                    id: 0,
                                    einzelbeeintraechtigung_id: (data as FischinfoGewaesserzustandeinzelbeeintraechtigung).einzelbeeintraechtigung_id,
                                    gewaesserzustand_id: gewaesserzustand.id
                                })
                            ) && gewaesserzustand.fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoGewaesserzustanduferpflanze: () => {
                let gewaesserzustand = this.setOrAdd<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand");
                !gewaesserzustand.fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id && (
                    gewaesserzustand.fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id = []
                );
                return (
                    data && gewaesserzustand.fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id
                        .map(element => {
                            return (element.uferpflanze_id === (data as FischinfoGewaesserzustanduferpflanze).uferpflanze_id && data as FischinfoGewaesserzustanduferpflanze) || element
                        }).find(
                            element => element.uferpflanze_id === (data as FischinfoGewaesserzustanduferpflanze).uferpflanze_id
                        )
                ) || (
                        gewaesserzustand.fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id
                            .push(
                                <FischinfoGewaesserzustanduferpflanze>data || new FischinfoGewaesserzustanduferpflanze({
                                    id: 0,
                                    uferpflanze_id: (data as FischinfoGewaesserzustanduferpflanze).uferpflanze_id,
                                    gewaesserzustand_id: gewaesserzustand.id
                                })
                            ) && gewaesserzustand.fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoStrukturelementehaeufigkeit: () => {
                let gewaesserzustand = this.setOrAdd<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand");
                !gewaesserzustand.fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id && (
                    gewaesserzustand.fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id = []
                );
                return (
                    data && gewaesserzustand.fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id
                        .map(element => {
                            return (element.strukturelement_id === (data as FischinfoStrukturelementehaeufigkeit).strukturelement_id && data as FischinfoStrukturelementehaeufigkeit) || element
                        }).find(
                            element => element.strukturelement_id === (data as FischinfoStrukturelementehaeufigkeit).strukturelement_id
                        )
                ) || (
                        gewaesserzustand.fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id
                            .push(
                                <FischinfoStrukturelementehaeufigkeit>data || new FischinfoStrukturelementehaeufigkeit({
                                    id: 0,
                                    strukturelement_id: (data as FischinfoStrukturelementehaeufigkeit).strukturelement_id,
                                    haeufigkeit_id: (data as FischinfoStrukturelementehaeufigkeit).haeufigkeit_id,
                                    gewaesserzustand_id: gewaesserzustand.id
                                })
                            ) && gewaesserzustand.fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoSubstratprozent: () => {
                let gewaesserzustand = this.setOrAdd<FischinfoGewaesserzustand>(untersuchung, "FischinfoGewaesserzustand");
                !gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id && (
                    gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id = []
                );
                return (
                    data && gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id
                        .map(element => {
                            return (element.substrat_id === (data as FischinfoSubstratprozent).substrat_id && data as FischinfoSubstratprozent) || element
                        }).find(
                            element => element.substrat_id === (data as FischinfoSubstratprozent).substrat_id
                        )
                ) || (
                        gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id
                            .push(
                                <FischinfoSubstratprozent>data || new FischinfoSubstratprozent({
                                    id: 0,
                                    substrat_id: (data as FischinfoSubstratprozent).substrat_id,
                                    anteil: (data as FischinfoSubstratprozent).anteil,
                                    gewaesserzustand_id: gewaesserzustand.id
                                })
                            ) && gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoMethode: () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                (!befischung.fischinfoMethodeByMethode_id || data) && (
                    befischung.fischinfoMethodeByMethode_id = <FischinfoMethode>data || new FischinfoMethode({
                        id: 0
                    })
                ) && (
                        befischung.methode_id = ((data || {}) as FischinfoMethode).id || -1
                    );
                return <T>(befischung.fischinfoMethodeByMethode_id as any);
            },
            FischinfoNetz: () => {
                let methode = this.setOrAdd<FischinfoMethode>(untersuchung, "FischinfoMethode");
                !methode.fischinfoNetzListByForeignMethode_id && (
                    methode.fischinfoNetzListByForeignMethode_id = []
                );
                return (
                    data && methode.fischinfoNetzListByForeignMethode_id
                        .map(element => {
                            return (element.id === (data as FischinfoNetz).id && data as FischinfoNetz) || element
                        })
                        .find(
                            element => element.id === (data as FischinfoNetz).id
                        )
                ) || (
                        methode.fischinfoNetzListByForeignMethode_id
                            .push(
                                <FischinfoNetz>data || new FischinfoNetz({
                                    id: Math.max.apply(Math,
                                        (
                                            methode.fischinfoNetzListByForeignMethode_id.length
                                                ? methode.fischinfoNetzListByForeignMethode_id
                                                : [{ id: 0 } as FischinfoNetz]
                                        )
                                            .map(function (o) { return o.id; })
                                    ) + 1,
                                    methode_id: methode.id
                                })
                            ) && methode.fischinfoNetzListByForeignMethode_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoMethodegeraetetyp: () => {
                let methode = this.setOrAdd<FischinfoMethode>(untersuchung, "FischinfoMethode");
                !methode.fischinfoMethodegeraetetypListByForeignMethode_id && (
                    methode.fischinfoMethodegeraetetypListByForeignMethode_id = []
                );
                return (
                    data && methode.fischinfoMethodegeraetetypListByForeignMethode_id
                        .map(element => {
                            return (element.id === (data as FischinfoMethodegeraetetyp).id && data as FischinfoMethodegeraetetyp) || element
                        })
                        .find(
                            element => element.id === (data as FischinfoMethodegeraetetyp).id
                        )
                ) || (
                        methode.fischinfoMethodegeraetetypListByForeignMethode_id
                            .push(
                                <FischinfoMethodegeraetetyp>data || new FischinfoMethodegeraetetyp({
                                    id: Math.max.apply(Math,
                                        (
                                            methode.fischinfoMethodegeraetetypListByForeignMethode_id.length
                                                ? methode.fischinfoMethodegeraetetypListByForeignMethode_id
                                                : [{ id: 0 } as FischinfoMethodegeraetetyp]
                                        )
                                            .map(function (o) { return o.id; })
                                    ) + 1,
                                    methode_id: methode.id
                                })
                            ) && methode.fischinfoMethodegeraetetypListByForeignMethode_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoBefischung: () => {
                !untersuchung.fischinfoBefischungListByForeignUntersuchung_id && (
                    untersuchung.fischinfoBefischungListByForeignUntersuchung_id = []
                );
                (!untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0] || data) && (
                    untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0] = <FischinfoBefischung>data || new FischinfoBefischung({
                        untersuchung_id: untersuchung.id,
                        id: 0
                    })
                );
                return <T>(untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0] as any);
            },
            FischinfoMuscheluntersuchung: () => {
                !untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id && (
                    untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id = []
                );
                (!untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0] || data) && (
                    untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0] = <FischinfoMuscheluntersuchung>data || new FischinfoMuscheluntersuchung({
                        untersuchung_id: untersuchung.id,
                        id: 0
                    })
                );
                return <T>(untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0] as any);
            },
            FischinfoKrebsuntersuchung: () => {
                !untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id && (
                    untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id = []
                );
                (!untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0] || data) && (
                    untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0] = <FischinfoKrebsuntersuchung>data || new FischinfoKrebsuntersuchung({
                        untersuchung_id: untersuchung.id,
                        id: 0
                    })
                );
                return <T>(untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0] as any);
            },
            FischinfoFfhbewertung: () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                !befischung.fischinfoFfhbewertungListByForeignBefischung_id && (
                    befischung.fischinfoFfhbewertungListByForeignBefischung_id = []
                );
                return (
                    data && befischung.fischinfoFfhbewertungListByForeignBefischung_id
                        .map(element => {
                            return (element.art_id === (data as FischinfoFfhbewertung).art_id && data as FischinfoFfhbewertung) || element
                        })
                        .find(
                            element => element.art_id === (data as FischinfoFfhbewertung).art_id
                        )
                ) || (
                        befischung.fischinfoFfhbewertungListByForeignBefischung_id
                            .push(
                                <FischinfoFfhbewertung>data || new FischinfoFfhbewertung({
                                    id: 0,
                                    befischung_id: befischung.id
                                })
                            ) && befischung.fischinfoFfhbewertungListByForeignBefischung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoLaengenklassenbefischungergebnis: () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                !befischung.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id && (
                    befischung.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id = []
                );
                return (
                    data && befischung.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id
                        .map(element => {
                            return (element.art_id === (data as FischinfoLaengenklassenbefischungergebnis).art_id && data as FischinfoLaengenklassenbefischungergebnis) || element
                        })
                        .find(
                            element => element.art_id === (data as FischinfoLaengenklassenbefischungergebnis).art_id
                        )
                ) || (
                        befischung.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id
                            .push(
                                <FischinfoLaengenklassenbefischungergebnis>data || new FischinfoLaengenklassenbefischungergebnis({
                                    id: 0,
                                    befischung_id: befischung.id
                                })
                            ) && befischung.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoLaengenklassenmuschelergebnis: () => {
                let muscheluntersuchung = this.setOrAdd<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung");
                !muscheluntersuchung.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id && (
                    muscheluntersuchung.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id = []
                );
                return (
                    data && muscheluntersuchung.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id
                        .map(element => {
                            return (element.muschelart_id === (data as FischinfoLaengenklassenmuschelergebnis).muschelart_id && data as FischinfoLaengenklassenmuschelergebnis) || element
                        }).find(
                            element => element.muschelart_id === (data as FischinfoLaengenklassenmuschelergebnis).muschelart_id
                        )
                ) || (
                        muscheluntersuchung.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id
                            .push(
                                <FischinfoLaengenklassenmuschelergebnis>data || new FischinfoLaengenklassenmuschelergebnis({
                                    id: 0,
                                    muscheluntersuchung_id: muscheluntersuchung.id
                                })
                            ) && muscheluntersuchung.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoLaengenklassenkrebsergebnis: () => {
                let krebsuntersuchung = this.setOrAdd<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung");
                !krebsuntersuchung.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id && (
                    krebsuntersuchung.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id = []
                );
                return (
                    data && krebsuntersuchung.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id
                        .map(element => {
                            return (element.krebsart_id === (data as FischinfoLaengenklassenkrebsergebnis).krebsart_id && data as FischinfoLaengenklassenkrebsergebnis) || element
                        }).find(
                            element => element.krebsart_id === (data as FischinfoLaengenklassenkrebsergebnis).krebsart_id
                        )
                ) || (
                        krebsuntersuchung.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id
                            .push(
                                <FischinfoLaengenklassenkrebsergebnis>data || new FischinfoLaengenklassenkrebsergebnis({
                                    id: 0,
                                    krebsuntersuchung_id: krebsuntersuchung.id
                                })
                            ) && krebsuntersuchung.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoAnzahlbefischungergebnis: () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                !befischung.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id && (
                    befischung.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id = []
                );
                return (
                    data && befischung.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id
                        .map(element => {
                            return (element.art_id === (data as FischinfoAnzahlbefischungergebnis).art_id && data as FischinfoAnzahlbefischungergebnis) || element
                        })
                        .find(
                            element => element.art_id === (data as FischinfoAnzahlbefischungergebnis).art_id
                        )
                ) || (
                        befischung.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id
                            .push(
                                <FischinfoAnzahlbefischungergebnis>data || new FischinfoAnzahlbefischungergebnis({
                                    id: 0,
                                    befischung_id: befischung.id
                                })
                            ) && befischung.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoAnzahlmuschelergebnis: () => {
                let muscheluntersuchung = this.setOrAdd<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung");
                !muscheluntersuchung.fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id && (
                    muscheluntersuchung.fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id = []
                );
                return (
                    data && muscheluntersuchung.fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id
                        .map(element => {
                            return (element.muschelart_id === (data as FischinfoAnzahlmuschelergebnis).muschelart_id && data as FischinfoAnzahlmuschelergebnis) || element
                        }).find(
                            element => element.muschelart_id === (data as FischinfoAnzahlmuschelergebnis).muschelart_id
                        )
                ) || (
                        muscheluntersuchung.fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id
                            .push(
                                <FischinfoAnzahlmuschelergebnis>data || new FischinfoAnzahlmuschelergebnis({
                                    id: 0,
                                    muscheluntersuchung_id: muscheluntersuchung.id
                                })
                            ) && muscheluntersuchung.fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoAnzahlkrebsergebnis: () => {
                let krebsuntersuchung = this.setOrAdd<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung");
                !krebsuntersuchung.fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id && (
                    krebsuntersuchung.fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id = []
                );
                return (
                    data && krebsuntersuchung.fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id
                        .map(element => {
                            return (element.krebsart_id === (data as FischinfoAnzahlkrebsergebnis).krebsart_id && data as FischinfoAnzahlkrebsergebnis) || element
                        }).find(
                            element => element.krebsart_id === (data as FischinfoAnzahlkrebsergebnis).krebsart_id
                        )
                ) || (
                        krebsuntersuchung.fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id
                            .push(
                                <FischinfoAnzahlkrebsergebnis>data || new FischinfoAnzahlkrebsergebnis({
                                    id: 0,
                                    krebsuntersuchung_id: krebsuntersuchung.id
                                })
                            ) && krebsuntersuchung.fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoHaeufigkeitbefischungergebnis: () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                !befischung.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id && (
                    befischung.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id = []
                );
                return (
                    data && befischung.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id
                        .map(element => {
                            return (element.art_id === (data as FischinfoHaeufigkeitbefischungergebnis).art_id && data as FischinfoHaeufigkeitbefischungergebnis) || element
                        })
                        .find(
                            element => element.art_id === (data as FischinfoHaeufigkeitbefischungergebnis).art_id
                        )
                ) || (
                        befischung.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id
                            .push(
                                <FischinfoHaeufigkeitbefischungergebnis>data || new FischinfoHaeufigkeitbefischungergebnis({
                                    id: 0,
                                    befischung_id: befischung.id
                                })
                            ) && befischung.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoHaeufigkeitsmuschelergebnis: () => {
                let muscheluntersuchung = this.setOrAdd<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung");
                !muscheluntersuchung.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id && (
                    muscheluntersuchung.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id = []
                );
                return (
                    data && muscheluntersuchung.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id
                        .map(element => {
                            return (element.art_id === (data as FischinfoHaeufigkeitsmuschelergebnis).art_id && data as FischinfoHaeufigkeitsmuschelergebnis) || element
                        }).find(
                            element => element.art_id === (data as FischinfoHaeufigkeitsmuschelergebnis).art_id
                        )
                ) || (
                        muscheluntersuchung.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id
                            .push(
                                <FischinfoHaeufigkeitsmuschelergebnis>data || new FischinfoHaeufigkeitsmuschelergebnis({
                                    id: 0,
                                    muscheluntersuchung_id: muscheluntersuchung.id
                                })
                            ) && muscheluntersuchung.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoHaeufigkeitkrebsergebnis: () => {
                let krebsuntersuchung = this.setOrAdd<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung");
                !krebsuntersuchung.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id && (
                    krebsuntersuchung.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id = []
                );
                return (
                    data && krebsuntersuchung.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id
                        .map(element => {
                            return (element.art_id === (data as FischinfoHaeufigkeitkrebsergebnis).art_id && data as FischinfoHaeufigkeitkrebsergebnis) || element
                        }).find(
                            element => element.art_id === (data as FischinfoHaeufigkeitkrebsergebnis).art_id
                        )
                ) || (
                        krebsuntersuchung.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id
                            .push(
                                <FischinfoHaeufigkeitkrebsergebnis>data || new FischinfoHaeufigkeitkrebsergebnis({
                                    id: 0,
                                    krebsuntersuchung_id: krebsuntersuchung.id
                                })
                            ) && krebsuntersuchung.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoNachweisbefischungergebnis: () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                !befischung.fischinfoNachweisbefischungergebnisListByForeignBefischung_id && (
                    befischung.fischinfoNachweisbefischungergebnisListByForeignBefischung_id = []
                );
                return (
                    data && befischung.fischinfoNachweisbefischungergebnisListByForeignBefischung_id
                        .map(element => {
                            return (element.art_id === (data as FischinfoNachweisbefischungergebnis).art_id && data as FischinfoNachweisbefischungergebnis) || element
                        })
                        .find(
                            element => element.art_id === (data as FischinfoNachweisbefischungergebnis).art_id
                        )
                ) || (
                        befischung.fischinfoNachweisbefischungergebnisListByForeignBefischung_id
                            .push(
                                <FischinfoNachweisbefischungergebnis>data || new FischinfoNachweisbefischungergebnis({
                                    id: 0,
                                    befischung_id: befischung.id
                                })
                            ) && befischung.fischinfoNachweisbefischungergebnisListByForeignBefischung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoNachweismuschelergebnis: () => {
                let muscheluntersuchung = this.setOrAdd<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung");
                !muscheluntersuchung.fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id && (
                    muscheluntersuchung.fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id = []
                );
                return (
                    data && muscheluntersuchung.fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id
                        .map(element => {
                            return (element.muschelart_id === (data as FischinfoNachweismuschelergebnis).muschelart_id && data as FischinfoNachweismuschelergebnis) || element
                        }).find(
                            element => element.muschelart_id === (data as FischinfoNachweismuschelergebnis).muschelart_id
                        )
                ) || (
                        muscheluntersuchung.fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id
                            .push(
                                <FischinfoNachweismuschelergebnis>data || new FischinfoNachweismuschelergebnis({
                                    id: 0,
                                    muscheluntersuchung_id: muscheluntersuchung.id
                                })
                            ) && muscheluntersuchung.fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoNachweiskrebsergebnis: () => {
                let krebsuntersuchung = this.setOrAdd<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung");
                !krebsuntersuchung.fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id && (
                    krebsuntersuchung.fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id = []
                );
                return (
                    data && krebsuntersuchung.fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id
                        .map(element => {
                            return (element.krebsart_id === (data as FischinfoNachweiskrebsergebnis).krebsart_id && data as FischinfoNachweiskrebsergebnis) || element
                        }).find(
                            element => element.krebsart_id === (data as FischinfoNachweiskrebsergebnis).krebsart_id
                        )
                ) || (
                        krebsuntersuchung.fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id
                            .push(
                                <FischinfoNachweiskrebsergebnis>data || new FischinfoNachweiskrebsergebnis({
                                    id: 0,
                                    krebsuntersuchung_id: krebsuntersuchung.id
                                })
                            ) && krebsuntersuchung.fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoDetailbefischungergebnis: () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                !befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id && (
                    befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id = []
                );
                return (
                    data && befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id
                        .map(element => {
                            return (
                                element.id === (data as FischinfoDetailbefischungergebnis).id
                                && element.art_id === (data as FischinfoDetailbefischungergebnis).art_id
                                && data as FischinfoDetailbefischungergebnis
                            ) || element
                        })
                        .find(
                            element =>
                                element.id === (data as FischinfoDetailbefischungergebnis).id
                                && element.art_id === (data as FischinfoDetailbefischungergebnis).art_id
                        )
                ) || (
                        befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id
                            .push(
                                <FischinfoDetailbefischungergebnis>data || new FischinfoDetailbefischungergebnis({
                                    id: 0,
                                    befischung_id: befischung.id
                                })
                            ) && befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoDetailmuschelergebnis: () => {
                let muscheluntersuchung = this.setOrAdd<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung");
                !muscheluntersuchung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id && (
                    muscheluntersuchung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id = []
                );
                return (
                    data && muscheluntersuchung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id
                        .map(element => {
                            return (
                                element.id === (data as FischinfoDetailmuschelergebnis).id
                                && element.muschelart_id === (data as FischinfoDetailmuschelergebnis).muschelart_id
                                && data as FischinfoDetailmuschelergebnis
                            ) || element
                        }).find(
                            element =>
                                element.id === (data as FischinfoDetailmuschelergebnis).id
                                && element.muschelart_id === (data as FischinfoDetailmuschelergebnis).muschelart_id
                        )
                ) || (
                        muscheluntersuchung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id
                            .push(
                                <FischinfoDetailmuschelergebnis>data || new FischinfoDetailmuschelergebnis({
                                    id: 0,
                                    muscheluntersuchung_id: muscheluntersuchung.id
                                })
                            ) && muscheluntersuchung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            FischinfoDetailkrebsergebnis: () => {
                let krebsuntersuchung = this.setOrAdd<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung");
                !krebsuntersuchung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id && (
                    krebsuntersuchung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id = []
                );
                return (
                    data && krebsuntersuchung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id
                        .map(element => {
                            return (
                                element.id === (data as FischinfoDetailkrebsergebnis).id && data as FischinfoDetailkrebsergebnis
                                && element.krebsart_id === (data as FischinfoDetailkrebsergebnis).krebsart_id
                                && data as FischinfoDetailkrebsergebnis
                            ) || element
                        }).find(
                            element =>
                                element.id === (data as FischinfoDetailkrebsergebnis).id
                                && element.krebsart_id === (data as FischinfoDetailkrebsergebnis).krebsart_id
                        )
                ) || (
                        krebsuntersuchung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id
                            .push(
                                <FischinfoDetailkrebsergebnis>data || new FischinfoDetailkrebsergebnis({
                                    id: 0,
                                    krebsuntersuchung_id: krebsuntersuchung.id
                                })
                            ) && krebsuntersuchung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id
                                .slice(-1)[0]
                    )
                    ;
            },
            "FischinfoDetailbefischungergebnis[]": () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                !befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id && (
                    befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id = []
                );
                data && data[0] && this.remove<FischinfoDetailbefischungergebnis[]>(untersuchung, "FischinfoDetailbefischungergebnis[]", data);
                return data && data[0]
                    ? befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id
                        .push(
                            ...(<FischinfoDetailbefischungergebnis[]>data || [])
                        )
                    && befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id
                        .filter(element => element.art_id === (data as FischinfoDetailbefischungergebnis[])[0].art_id)
                    : befischung.fischinfoDetailbefischungergebnisListByForeignBefischung_id
            },
            "FischinfoDetailmuschelergebnis[]": () => {
                let muscheluntersuchung = this.setOrAdd<FischinfoMuscheluntersuchung>(untersuchung, "FischinfoMuscheluntersuchung");
                !muscheluntersuchung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id && (
                    muscheluntersuchung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id = []
                );
                data && data[0] && this.remove<FischinfoDetailmuschelergebnis[]>(untersuchung, "FischinfoDetailmuschelergebnis[]", data);
                return data && data[0]
                    ? muscheluntersuchung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id
                        .push(
                            ...(<FischinfoDetailmuschelergebnis[]>data || [])
                        )
                    && muscheluntersuchung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id
                        .filter(element => element.muschelart_id === (data as FischinfoDetailmuschelergebnis[])[0].muschelart_id)
                    : muscheluntersuchung.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id
            },
            "FischinfoDetailkrebsergebnis[]": () => {
                let krebsuntersuchung = this.setOrAdd<FischinfoKrebsuntersuchung>(untersuchung, "FischinfoKrebsuntersuchung");
                !krebsuntersuchung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id && (
                    krebsuntersuchung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id = []
                );
                data && data[0] && this.remove<FischinfoDetailkrebsergebnis[]>(untersuchung, "FischinfoDetailkrebsergebnis[]", data);
                return data && data[0]
                    ? krebsuntersuchung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id
                        .push(
                            ...(<FischinfoDetailkrebsergebnis[]>data || [])
                        )
                    && krebsuntersuchung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id
                        .filter(element => element.krebsart_id === (data as FischinfoDetailkrebsergebnis[])[0].krebsart_id)
                    : krebsuntersuchung.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id
            },
            FischinfoFibsresultNWB: () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                !befischung.fischinfoFibsresultListByForeignBefischung_id && (
                    befischung.fischinfoFibsresultListByForeignBefischung_id = []
                );
                return (
                    data && befischung.fischinfoFibsresultListByForeignBefischung_id
                        .map(element => {
                            return (element.nwb && data as FischinfoFibsresult) || element
                        })
                        .find(
                            element => element.nwb
                        )
                ) || (
                        befischung.fischinfoFibsresultListByForeignBefischung_id
                            .find(fr => fr.nwb)
                        ||
                        (
                            befischung.fischinfoFibsresultListByForeignBefischung_id.push(
                                new FischinfoFibsresult({
                                    id: 1,
                                    befischung_id: befischung.id,
                                    nwb: true
                                })
                            )
                            && befischung.fischinfoFibsresultListByForeignBefischung_id.find(fr => fr.nwb)
                        )
                    );
            },
            FischinfoFibsresultHMWB: () => {
                let befischung = this.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung");
                !befischung.fischinfoFibsresultListByForeignBefischung_id && (
                    befischung.fischinfoFibsresultListByForeignBefischung_id = []
                );
                return (
                    data && befischung.fischinfoFibsresultListByForeignBefischung_id
                        .map(element => {
                            return (!element.nwb && data as FischinfoFibsresult) || element
                        })
                        .find(
                            element => !element.nwb
                        )
                ) || (
                        befischung.fischinfoFibsresultListByForeignBefischung_id
                            .find(fr => !fr.nwb)
                        ||
                        (
                            befischung.fischinfoFibsresultListByForeignBefischung_id.push(
                                new FischinfoFibsresult({
                                    id: 1,
                                    befischung_id: befischung.id,
                                    nwb: false
                                })
                            )
                            && befischung.fischinfoFibsresultListByForeignBefischung_id.find(fr => !fr.nwb)
                        )
                    )
                    ;
            },
        })[model]();
    }
}