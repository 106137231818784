<div class="row">
    <div class="col-xs-15">
        <label *ngIf="readonly">Befischungsergebnisse / Muscheluntersuchung</label>
        <label *ngIf="!readonly">Wählen Sie die Muschelart für welche Sie Befischungsergebnisse eintragen können</label>
    </div>
</div>

<div
    class="row"
    *ngIf="untersuchungsergebnisseList?.length > 0"
>
    <div class="col-xs-15">
        <table class="table table-accordion">
            <thead>
                <tr>
                    <th (click)="onClickSortColumn('id', 'number')" [style.width.%]="12" title="Nach Spalte sortieren">ID
                        <i 
                            *ngIf="sortField.id === 'desc'"
                            class="fa fa-sort-up"
                            title="Nach Spalte sortieren"
                        ></i>
                        <i 
                            *ngIf="sortField.id === 'asc'"
                            class="fa fa-sort-down"
                            title="Nach Spalte Sortieren"
                        ></i>
                    </th>
                    <th (click)="onClickSortColumn('art', 'text')" title="Nach Spalte sortieren">Muschelart
                        <i 
                            *ngIf="sortField.art === 'desc'"
                            class="fa fa-sort-up"
                            title="Nach Spalte sortieren"
                        ></i>
                        <i 
                            *ngIf="sortField.art === 'asc'"
                            class="fa fa-sort-down"
                            title="Nach Spalte Sortieren"
                        ></i>
                    </th>
                    <th (click)="onClickSortColumn('untersuchungstyp', 'text')" [style.width.%]="20" title="Nach Spalte sortieren">Erfassungsart
                        <i 
                            *ngIf="sortField.untersuchungstyp === 'desc'"
                            class="fa fa-sort-up"
                            title="Nach Spalte sortieren"
                        ></i>
                        <i 
                            *ngIf="sortField.untersuchungstyp === 'asc'"
                            class="fa fa-sort-down"
                            title="Nach Spalte Sortieren"
                        ></i>
                    </th>
                    <th (click)="onClickSortColumn('summe', 'number')" [style.width.%]="12" title="Nach Spalte sortieren">Anzahl
                        <i 
                            *ngIf="sortField.summe === 'desc'"
                            class="fa fa-sort-up"
                            title="Nach Spalte sortieren"
                        ></i>
                        <i 
                            *ngIf="sortField.summe === 'asc'"
                            class="fa fa-sort-down"
                            title="Nach Spalte Sortieren"
                        ></i>
                    </th>
                </tr>
            </thead>
            <tbody
                *ngIf="!isSorting"
                uw-accordion
                active="-1"
                toggle="true"
                accordionHeight="260"
            >
                <ng-container
                    *ngFor="let u of untersuchungsergebnisseList; let i = index"
                >
                    <tr
                        uw-accordion-pane
                    >
                        <td style="padding: 0 2px 0 0;" class="td-pane"><uw-inline-button
                            iconClass="fa"
                            title="{{u.id}}"
                            value="{{u.id}}"
                            stopPropagation="false"
                        ></uw-inline-button></td>
                        <td [title]="u.art">{{u.art}}</td>
                        <td [title]="labelList[i]?.untersuchungstyp">{{labelList[i]?.untersuchungstyp}}</td>
                        <td [title]="labelList[i]?.summe">{{labelList[i]?.summe}}</td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <uw-accordion-pane>
                                <article>
                                    <form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung
                                        *ngIf="i===accordion?.activePane"
                                        [untersuchung]="untersuchung"
                                        [modalId]="modalId"
                                        [readonly]="readonly"
                                        [artId]="u.id"
                                        [artGruppe]="u.gruppe"
                                        (changed)="onChanged($event)" 
                                    ></form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung>
                                </article>
                            </uw-accordion-pane>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
<div
    class="row"
    *ngIf="!untersuchungsergebnisseList?.length"
>
    <div class="col-xs-15"><uw-textinput
        value="Kein Eintrag"
        disabled="true"
    ></uw-textinput></div>
</div>