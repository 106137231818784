<uw-tabs>
    <section class="uw-tab-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-15">
                    <label>
                        Filterergebnis bzw. Tabelle in Datei speichern
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-15">
                    <p *ngIf="dataService.isLoading">
                        Bitte warten Sie bis die Ergebnistabelle vollständig geladen ist…
                    </p>
                    <p *ngIf="!dataService.isLoading">
                        Bitte wählen Sie das gewünschte Dateiformat in welches die
                        <br/>
                        <span>{{dataService.length}} </span>
                        <span>{{dataService.folder === 'dataNodePlaceholder' ? '...' : 'Probenahmestellen'}}</span> aus der Tabelle
                        <br/>
                        gespeichert werden sollen.
                    </p>
                </div>
            </div>
            <div
                *ngIf="!dataService.isLoading"
                class="row"
            >
                <div class="col-sm-15">
                    <uw-button
                        label="CSV-Tabelle für Excel"
                        (click)="exportCsvFile()"
                    >Als CSV-Tabelle speichern</uw-button>
                </div>
            </div>
            <!--
            <div class="row">
                <div class="col-sm-15">
                    <label
                        *ngIf="!dataService.isLoading"
                    >ESRI Shapefile</label>
                    <div
                        *ngIf="isProcessingEsri"
                        class="uw-esriconv-progress"
                    >
                        <div
                            class="uw-esriconv-progress-bar"
                            [style.width.%]="esriProgressPerc"
                        ></div>
                        <span
                            class="uw-esriconv-progress-label"
                        >Bitte warten…
                        </span>
                    </div>
                    <uw-button
                        *ngIf="!isProcessingEsri && !dataService.isLoading"
                        [disabled]="isProcessingEsri"
                        (click)="exportEsriShapefile()"
                    >Als ESRI Shapefile speichern
                    </uw-button>
                    <div style="display: none">
                        <uw-fileupload #fileupload
                            disabled="true"
                            [url]="uploadUrl"
                            (progress)="onUploadProgress($event)"
                            (success)="onUploadSuccess($event)"
                            (error)="onUploadError($event)"
                        ></uw-fileupload>
                    </div>
                </div>
            </div>
            //-->
        </div>
    </section>
</uw-tabs>
