<div
    class="row"
    style="padding-top:9px"
    *ngIf="zustandeinzelbeeintraechtigungList?.length > 0"
>
    <div class="col-xs-15">
        <table class="table table-striped zustandeinzelbeeintraechtigungList inherit-inputs">
            <thead>
                <tr>
                    <th title="Einzelbeeinträchtigungen">Einzelbeeinträchtigungen</th>
                    <th [style.width.%]="40" title=""> </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let u of zustandeinzelbeeintraechtigungList"
                    class="hover"
                >
                    <td [title]="u.bezeichnung">{{u.bezeichnung}}</td>
                    <td style="padding:0;"><uw-inline-uncheck-button
                        [checked]="u.id > 0"
                        [disabled]="readonly"
                        (click)="onClickZustandeinzelbeeintraechtigung($event, u)"
                    ></uw-inline-uncheck-button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div
    class="row"
    *ngIf="!zustandeinzelbeeintraechtigungList?.length"
>
    <div class="col-xs-15"><uw-textinput
        label="Einzelbeeinträchtigungen"
        value=""
        disabled="true"
    ></uw-textinput></div>
</div>
