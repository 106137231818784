import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoLinienfuehrung from "./interface";
import FischinfoGewaesserzustand from "./../../fischinfo/gewaesserzustand/model";
@fn.DataModel
export default class FischinfoLinienfuehrung extends DataModel implements IFischinfoLinienfuehrung {
    constructor(obj?: object | IFischinfoLinienfuehrung) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoLinienfuehrung): FischinfoLinienfuehrung {
        return new FischinfoLinienfuehrung(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoLinienfuehrung): FischinfoLinienfuehrung {
        Object.assign(this, obj);
        obj && obj["fischinfoGewaesserzustandListByForeignLinienfuehrung_id"] && obj["fischinfoGewaesserzustandListByForeignLinienfuehrung_id"].length && (this.fischinfoGewaesserzustandListByForeignLinienfuehrung_id = obj["fischinfoGewaesserzustandListByForeignLinienfuehrung_id"].map(FischinfoGewaesserzustand.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoGewaesserzustand>}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandListByForeignLinienfuehrung_id", type: [FischinfoGewaesserzustand], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoGewaesserzustandListByForeignLinienfuehrung_id?: Array<FischinfoGewaesserzustand>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
