import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoGewaesserstruktur from "./interface";
import FischinfoBesatzereignisgewaesserstruktur from "./../../fischinfo/besatzereignisgewaesserstruktur/model";
import FischinfoFischart from "./../../fischinfo/fischart/model";
@fn.DataModel
export default class FischinfoGewaesserstruktur extends DataModel implements IFischinfoGewaesserstruktur {
    constructor(obj?: object | IFischinfoGewaesserstruktur) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoGewaesserstruktur): FischinfoGewaesserstruktur {
        return new FischinfoGewaesserstruktur(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoGewaesserstruktur): FischinfoGewaesserstruktur {
        Object.assign(this, obj);
        obj && obj["fischinfoBesatzereignisgewaesserstrukturByForeignGewaesserstruktur_id"] && (this.fischinfoBesatzereignisgewaesserstrukturByForeignGewaesserstruktur_id = FischinfoBesatzereignisgewaesserstruktur.deserialize(obj["fischinfoBesatzereignisgewaesserstrukturByForeignGewaesserstruktur_id"]));
        obj && obj["fischinfoFischartBySpezies_id"] && (this.fischinfoFischartBySpezies_id = FischinfoFischart.deserialize(obj["fischinfoFischartBySpezies_id"]));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "erlaeuterung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public erlaeuterung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "kurzbezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public kurzbezeichnung?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "spezies_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public spezies_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoBesatzereignisgewaesserstruktur}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisgewaesserstrukturByForeignGewaesserstruktur_id", type: FischinfoBesatzereignisgewaesserstruktur, nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzereignisgewaesserstrukturByForeignGewaesserstruktur_id?: FischinfoBesatzereignisgewaesserstruktur;

    /**
     * @type {FischinfoFischart}
     */
    @fn.DataMember({ name: "fischinfoFischartBySpezies_id", type: FischinfoFischart, nullable: true, memberType: "JOIN", joinProperty: "spezies_id" })
    public fischinfoFischartBySpezies_id?: FischinfoFischart;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
