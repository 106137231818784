import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoBefischungsart from "./interface";
import FischinfoMethode from "./../../fischinfo/methode/model";
@fn.DataModel
export default class FischinfoBefischungsart extends DataModel implements IFischinfoBefischungsart {
    constructor(obj?: object | IFischinfoBefischungsart) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoBefischungsart): FischinfoBefischungsart {
        return new FischinfoBefischungsart(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoBefischungsart): FischinfoBefischungsart {
        Object.assign(this, obj);
        obj && obj["fischinfoMethodeListByForeignBefischungsart_id"] && obj["fischinfoMethodeListByForeignBefischungsart_id"].length && (this.fischinfoMethodeListByForeignBefischungsart_id = obj["fischinfoMethodeListByForeignBefischungsart_id"].map(FischinfoMethode.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoMethode>}
     */
    @fn.DataMember({ name: "fischinfoMethodeListByForeignBefischungsart_id", type: [FischinfoMethode], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoMethodeListByForeignBefischungsart_id?: Array<FischinfoMethode>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
