import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoMethodegeraetetyp from "./interface";
import FischinfoGeraetetyp from "./../../fischinfo/geraetetyp/model";
import FischinfoMethode from "./../../fischinfo/methode/model";
import FischinfoStromart from "./../../fischinfo/stromart/model";
@fn.DataModel
export default class FischinfoMethodegeraetetyp extends DataModel implements IFischinfoMethodegeraetetyp {
    constructor(obj?: object | IFischinfoMethodegeraetetyp) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoMethodegeraetetyp): FischinfoMethodegeraetetyp {
        return new FischinfoMethodegeraetetyp(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoMethodegeraetetyp): FischinfoMethodegeraetetyp {
        Object.assign(this, obj);
        obj && obj["fischinfoGeraetetypByGeraetetyp_id"] && (this.fischinfoGeraetetypByGeraetetyp_id = FischinfoGeraetetyp.deserialize(obj["fischinfoGeraetetypByGeraetetyp_id"]));
        obj && obj["fischinfoMethodeByMethode_id"] && (this.fischinfoMethodeByMethode_id = FischinfoMethode.deserialize(obj["fischinfoMethodeByMethode_id"]));
        obj && obj["fischinfoStromartByStromart_id"] && (this.fischinfoStromartByStromart_id = FischinfoStromart.deserialize(obj["fischinfoStromartByStromart_id"]));
        return this;
    }

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "abgelesen", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public abgelesen?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "geraetetyp_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public geraetetyp_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "methode_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public methode_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "stromart_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public stromart_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoGeraetetyp}
     */
    @fn.DataMember({ name: "fischinfoGeraetetypByGeraetetyp_id", type: FischinfoGeraetetyp, nullable: true, memberType: "JOIN", joinProperty: "geraetetyp_id" })
    public fischinfoGeraetetypByGeraetetyp_id?: FischinfoGeraetetyp;

    /**
     * @type {FischinfoMethode}
     */
    @fn.DataMember({ name: "fischinfoMethodeByMethode_id", type: FischinfoMethode, nullable: true, memberType: "JOIN", joinProperty: "methode_id" })
    public fischinfoMethodeByMethode_id?: FischinfoMethode;

    /**
     * @type {FischinfoStromart}
     */
    @fn.DataMember({ name: "fischinfoStromartByStromart_id", type: FischinfoStromart, nullable: true, memberType: "JOIN", joinProperty: "stromart_id" })
    public fischinfoStromartByStromart_id?: FischinfoStromart;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
