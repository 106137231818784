import proj4 from "proj4";

export class GaussKrugerProjection implements proj4.InterfaceProjection {
    datum: string;
    b: number;
    rf: number;
    sphere: number;
    es: number;
    e: number;
    ep2: number;
    _tmerc: proj4.InterfaceProjection & { init(this: proj4.InterfaceProjection) };
    init() {
        this._tmerc = (<any>proj4.Proj).projections.get("tmerc");
        this._tmerc.init.call(this);
    }

    forward(p) {
        return this._tmerc.forward.call(this, p);
    }

    inverse(p) {
        return this._tmerc.inverse.call(this, p);
    }

    readonly names = ["Gauss_Kruger"];
}


export default new GaussKrugerProjection();
