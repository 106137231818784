import { Component, Input, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
/**
 * Diese Komponente stellt keine Eingabefelder für den Nutzer zur Verfügung,
 * da andere E-Befischungsmethoden keine weiteren Informationen zugewiesen werden können.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-methode-egeraet-anderemethode",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungMethodeEgeraetAnderemethode {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService) {
        {
        }
    }
}
