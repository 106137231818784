
<div
    *ngIf="isExtendedView"
    class="row"
    style="padding-top:6px;"
>
    <div class="col-xs-15">
        <table 
            class="table table-accordion"
            uw-accordion
            active="-1"
            toggle="true"
            [accordionHeight]="accordionHeight"
        >
            <thead>
                <tr>
                    <th title="Filter">Filterbereich</th>
                </tr>
            </thead>
            <tbody>

                <tr uw-accordion-pane>
                    <td style="padding: 0 2px 0 0;"><uw-inline-button
                        iconClass="fa"
                        title="I. Räumliche Filter"
                        value="I. Räumliche Filter"
                        stopPropagation="false"
                    ></uw-inline-button> <span *ngIf="filterBadge.raum" class="badge">{{filterBadge.raum}}</span></td>
                </tr>
                <tr>
                    <td>
                        <uw-accordion-pane>
                            <article>
                                <ng-container *ngIf="arrayFilters.gewkz && objectKeysHelperFunc(arrayFilters.gewkz).length">
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <label>Gewässername / Gewässerkennzahl</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <table class="table table-striped arrayList">
                                                <tbody>
                                                    <tr *ngFor="let u of objectKeysHelperFunc(arrayFilters.gewkz)">
                                                        <td [title]="arrayFilters.gewkz[u].name">{{arrayFilters.gewkz[u].name}}</td>
                                                        <td style="width:24px;padding:0;"><uw-inline-uncheck-button
                                                            checked="true"
                                                            hideIcon="true"
                                                            width="24"
                                                            (click)="deleteArrayFilter('gewkz', u); updateFilterGeometryLayer('gewkz')"
                                                        ></uw-inline-uncheck-button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="row">
                                    <div class="col-xs-15">
                                        <uw-type-ahead
                                            label="Gewässername / Gewässerkennzahl{{arrayFilters.gewkz && objectKeysHelperFunc(arrayFilters.gewkz).length ? ' hinzufügen' : ''}}"
                                            webServiceApiUrl="{{dataService.webServiceApiUrl}}gebietsbezug/gewaesser?term={term}&limit={limit}&format=application/json"
                                            webServiceApiTokenBasedLoginUrl="{{dataService.webServiceApiUrl}}login?loginname={login}&password={password}&format=application/json"
                                            keyValueMapping="id:name"
                                            itemsAccess=".resultset"
                                            placeholder="{{arrayFilters.gewkz && objectKeysHelperFunc(arrayFilters.gewkz).length ? 'Gewässername / Gewässerkennzahl hinzufügen' : 'Kein Filter'}}"
                                            min="0"
                                            nullable="true"
                                            limit="32"
                                            size="8"
                                            (change)="onFilterChanged($event, 'gewkz')"
                                        ></uw-type-ahead>
                                    </div>
                                </div>
                                <ng-container *ngIf="arrayFilters.psnr && objectKeysHelperFunc(arrayFilters.psnr).length">
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <label>Probestellen-Nr.</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <table class="table table-striped arrayList">
                                                <tbody>
                                                    <tr *ngFor="let u of objectKeysHelperFunc(arrayFilters.psnr)">
                                                        <td [title]="arrayFilters.psnr[u].name">{{arrayFilters.psnr[u].name}}</td>
                                                        <td style="width:24px;padding:0;"><uw-inline-uncheck-button
                                                            checked="true"
                                                            hideIcon="true"
                                                            width="24"
                                                            (click)="unsetArrayFilter('psnr', 'psnr', u, { separator: '$$' }); updateFilterGeometryLayer('gebietsbezug', 'psnr')"
                                                        ></uw-inline-uncheck-button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="row">
                                    <div class="col-xs-15">
                                        <uw-type-ahead
                                            label="Probestellen-Nr.{{arrayFilters.psnr && objectKeysHelperFunc(arrayFilters.psnr).length ? ' hinzufügen' : ''}}"
                                            webServiceApiUrl="{{dataService.webServiceApiUrl}}gebietsbezug/probestellennr?term={term}&limit={limit}&format=application/json"
                                            webServiceApiTokenBasedLoginUrl="{{dataService.webServiceApiUrl}}login?loginname={login}&password={password}&format=application/json"
                                            keyValueMapping="id:name"
                                            itemsAccess=".resultset"
                                            placeholder="{{arrayFilters.gewkz && objectKeysHelperFunc(arrayFilters.gewkz).length ? 'Probestellen-Nr. hinzufügen' : 'Kein Filter'}}"
                                            min="0"
                                            nullable="true"
                                            limit="32"
                                            size="8"
                                            (change)="onFilterChanged($event, 'psnr')"
                                        ></uw-type-ahead>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-15">
                                        <uw-type-ahead
                                            label="GÜS-Nr."
                                            webServiceApiUrl="{{dataService.webServiceApiUrl}}guesnr?term={term}&limit={limit}&format=application/json"
                                            webServiceApiTokenBasedLoginUrl="{{dataService.webServiceApiUrl}}login?loginname={login}&password={password}&format=application/json"
                                            keyValueMapping="term:term"
                                            itemsAccess=".resultset"
                                            placeholder="Kein Filter"
                                            min="0"
                                            nullable="true"
                                            limit="32"
                                            size="8"
                                            (change)="onFilterChanged($event, 'gu')"
                                        ></uw-type-ahead>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-15">
                                        <uw-type-ahead
                                            label="Bezirk / Kreis / Gemeinde"
                                            webServiceApiUrl="{{dataService.webServiceApiUrl}}gebietsbezug/kreisgemeinde?term={term}&limit={limit}&format=application/json"
                                            webServiceApiTokenBasedLoginUrl="{{dataService.webServiceApiUrl}}login?loginname={login}&password={password}&format=application/json"
                                            keyValueMapping="id:name"
                                            itemsAccess=".resultset"
                                            placeholder="Kein Filter"
                                            min="0"
                                            nullable="true"
                                            limit="32"
                                            size="8"
                                            (change)="onFilterChanged($event, 'gebietsbezug3')"
                                        ></uw-type-ahead>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-15"> 
                                        <uw-type-ahead
                                            label="Natura 2000-Gebiet"
                                            webServiceApiUrl="{{dataService.webServiceApiUrl}}gebietsbezug/natura2000?term={term}&limit={limit}&format=application/json"
                                            webServiceApiTokenBasedLoginUrl="{{dataService.webServiceApiUrl}}login?loginname={login}&password={password}&format=application/json"
                                            keyValueMapping="id:name"
                                            itemsAccess=".resultset"
                                            placeholder="Kein Filter"
                                            min="0"
                                            nullable="true"
                                            limit="32"
                                            size="8"
                                            (change)="onFilterChanged($event, 'gebietsbezug4')"
                                        ></uw-type-ahead>
                                    </div>
                                </div>
                            </article>
                        </uw-accordion-pane>
                    </td>
                </tr>

                <tr uw-accordion-pane>
                    <td style="padding: 0 2px 0 0;"><uw-inline-button
                        iconClass="fa"
                        title="II. Verschiedenes"
                        value="II. Verschiedenes"
                        stopPropagation="false"
                    ></uw-inline-button> <span *ngIf="filterBadge.verschiedenes" class="badge">{{filterBadge.verschiedenes}}</span></td>
                </tr>
                <tr>
                    <td>
                        <uw-accordion-pane>
                            <article>
                                <div class="row">
                                    <div class="col-xs-15 col-sm-6">
                                        <uw-select
                                            label="Still- / Fließgewässer"
                                            jsonItems='[["stillgewaesser","Stillgewässer"], ["fliessgewaesser","Fließgewässer"]]'
                                            keyValueMapping="0:1"
                                            nullable="true"
                                            placeholder="Kein Filter"
                                            (change)="onFilterChanged($event, 'gewaessertyp')"
                                        ></uw-select>
                                    </div>
                                    <div class="col-xs-15 col-sm-5">
                                        <uw-select
                                            label="Untersuchung"
                                            jsonItems='[["beobachtung","Beobachtung"], ["befischung","Befischung"]]'
                                            keyValueMapping="0:1"
                                            nullable="true"
                                            placeholder="Kein Filter"
                                            (change)="onFilterChanged($event, 'bb')"
                                        ></uw-select>
                                    </div>
                                    <div class="col-xs-15 col-sm-4">
                                        <uw-select
                                            label="Keine Fische"
                                            jsonItems='[["ja","Ja"], ["nein","Nein"]]'
                                            keyValueMapping="0:1"
                                            nullable="true"
                                            placeholder="Kein Filter"
                                            (change)="onFilterChanged($event, 'kf')"
                                        ></uw-select>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-xs-15 col-sm-6">
                                        <label>Untersuchungstermin von</label>
                                    </div>
                                    <div class="col-xs-15 col-sm-5">
                                        <label>bis</label>
                                    </div>
                                    <div class="col-xs-15 col-sm-4">
                                        <label>Trocken gefallen</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-15 col-sm-5">
                                        <uw-textinput
                                            value=""
                                            placeholder="Kein Filter"
                                            nullable="true"
                                            (validate)="onFilterChanged($event, 'untersuchterminvon')"
                                        ></uw-textinput>
                                    </div>
                                    <div class="col-xs-15 col-sm-1"></div>
                                    <div class="col-xs-15 col-sm-5">
                                        <uw-textinput
                                            value=""
                                            placeholder="Kein Filter"
                                            nullable="true"
                                            (validate)="onFilterChanged($event, 'untersuchterminbis')"
                                        ></uw-textinput>
                                    </div>
                                    <div class="col-xs-15 col-sm-4">
                                        <uw-select
                                            jsonItems='[["ja","Ja"], ["nein","Nein"]]'
                                            keyValueMapping="0:1"
                                            nullable="true"
                                            placeholder="Kein Filter"
                                            (change)="onFilterChanged($event, 'tg')"
                                        ></uw-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-15 col-sm-11">
                                        <uw-type-ahead
                                            label="Oberflächenwasserkörper"
                                            webServiceApiUrl="{{dataService.webServiceApiUrl}}oberflaechenwasserkoerper/bezeichnung?term={term}&limit={limit}&format=application/json"
                                            webServiceApiTokenBasedLoginUrl="{{dataService.webServiceApiUrl}}login?loginname={login}&password={password}&format=application/json"
                                            keyValueMapping="id:term"
                                            itemsAccess=".resultset"
                                            placeholder="Kein Filter"
                                            min="0"
                                            limit="32"
                                            size="8"
                                            nullable="true"
                                            (change)="onFilterChanged($event, 'ofwk')"
                                        ></uw-type-ahead>
                                    </div>
                                    <div class="col-xs-15 col-sm-4">
                                        <uw-select
                                            label="Ausweisung"
                                            *ngIf="ausweisungJsonItems"
                                            [jsonItems]="ausweisungJsonItems"
                                            keyValueMapping="0:1"
                                            nullable="true"
                                            placeholder="Kein Filter"
                                            size="6"
                                            (change)="onFilterChanged($event, 'ausw')"
                                        ></uw-select>
                                    </div>
                                </div>
                                <ng-container
                                    *ngIf="arrayFilters.schluesselwort && objectKeysHelperFunc(arrayFilters.schluesselwort).length"
                                >
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <label>Schlüsselworte</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <table class="table table-striped arrayList">
                                                <tbody>
                                                    <tr *ngFor="let u of objectKeysHelperFunc(arrayFilters.schluesselwort)">
                                                        <td [title]="arrayFilters.schluesselwort[u].name">{{arrayFilters.schluesselwort[u].name}}</td>
                                                        <td style="width:24px;padding:0;"><uw-inline-uncheck-button
                                                            checked="true"
                                                            hideIcon="true"
                                                            width="24"
                                                            (click)="deleteArrayFilter('schluesselwort', u)"
                                                        ></uw-inline-uncheck-button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="row">
                                    <div class="col-xs-15">
                                        <uw-type-ahead
                                            [label]="arrayFilters.schluesselwort && objectKeysHelperFunc(arrayFilters.schluesselwort).length ? 'Schlüsselwort hinzufügen' : 'Schlüsselwort'"
                                            webServiceApiUrl="{{dataService.webServiceApiUrl}}untersuchung/schluesselwort?term={term}&limit={limit}&format=application/json"
                                            webServiceApiTokenBasedLoginUrl="{{dataService.webServiceApiUrl}}login?loginname={login}&password={password}&format=application/json"
                                            keyValueMapping="id:term"
                                            itemsAccess=".resultset"
                                            [placeholder]="arrayFilters.schluesselwort && objectKeysHelperFunc(arrayFilters.schluesselwort).length ? 'Schlüsselwort hinzufügen' : 'Kein Filter'"
                                            min="0"
                                            limit="32"
                                            size="8"
                                            nullable="true"
                                            (change)="onFilterChanged($event, 'schluesselwort')"
                                        ></uw-type-ahead>
                                    </div>
                                </div>
                                <div class="row" *ngIf="hasAdminRights">
                                    <div class="col-xs-15 col-sm-5">
                                        <uw-select
                                            label="Anonyme Untersuchungen"
                                            jsonItems='[["ja","Ja"], ["nein","Nein"]]'
                                            keyValueMapping="0:1"
                                            nullable="true"
                                            placeholder="Kein Filter"
                                            (change)="onFilterChanged($event, 'an')"
                                        ></uw-select>
                                    </div>
                                </div>
                            </article>
                        </uw-accordion-pane>
                    </td>
                </tr>

                <tr uw-accordion-pane>
                    <td style="padding: 0 2px 0 0;"><uw-inline-button
                        iconClass="fa"
                        title="III. Fischgewässertyp"
                        value="III. Fischgewässertyp"
                        stopPropagation="false"
                    ></uw-inline-button> <span *ngIf="filterBadge.fischgewaesser" class="badge">{{filterBadge.fischgewaesser}}</span></td>
                </tr>
                <tr>
                    <td>
                        <uw-accordion-pane>
                            <article>
                                <ng-container
                                    *ngIf="arrayFilters.fischgewtyp && objectKeysHelperFunc(arrayFilters.fischgewtyp).length"
                                >
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <label>Filterauswahl</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <table class="table table-striped arrayList">
                                                <tbody>
                                                    <tr *ngFor="let u of objectKeysHelperFunc(arrayFilters.fischgewtyp)">
                                                        <td [title]="arrayFilters.fischgewtyp[u].name">{{arrayFilters.fischgewtyp[u].name}}</td>
                                                        <td style="width:24px;padding:0;"><uw-inline-uncheck-button
                                                            checked="true"
                                                            hideIcon="true"
                                                            width="24"
                                                            (click)="deleteArrayFilter('fischgewtyp', u)"
                                                        ></uw-inline-uncheck-button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="row">
                                    <div class="col-xs-15">
                                        <uw-select
                                            label="Fischgewässertyp"
                                            *ngIf="fischgewtypRefJsonItems"
                                            [jsonItems]="fischgewtypRefJsonItems"
                                            keyValueMapping="0:1"
                                            nullable="true"
                                            placeholder="Filter hinzufügen"
                                            size="6"
                                            [key]="-1"
                                            (change)="onFilterChanged($event, 'fischgewtyp'); $event.key = $event.key ?? -1"
                                        ></uw-select>
                                    </div>
                                </div>
                            </article>
                        </uw-accordion-pane>
                    </td>
                </tr>

                <tr uw-accordion-pane>
                    <td style="padding: 0 2px 0 0;"><uw-inline-button
                        iconClass="fa"
                        title="IV. Hoep-Typ"
                        value="IV. Hoep-Typ"
                        stopPropagation="false"
                    ></uw-inline-button> <span *ngIf="filterBadge.hoep" class="badge">{{filterBadge.hoep}}</span></td>
                </tr>
                <tr>
                    <td>
                        <uw-accordion-pane>
                            <article>
                                <ng-container
                                    *ngIf="arrayFilters.fibshoep && objectKeysHelperFunc(arrayFilters.fibshoep).length"
                                >
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <label>Filterauswahl</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <table class="table table-striped arrayList">
                                                <tbody>
                                                    <tr *ngFor="let u of objectKeysHelperFunc(arrayFilters.fibshoep)">
                                                        <td [title]="arrayFilters.fibshoep[u].name">{{arrayFilters.fibshoep[u].name}}</td>
                                                        <td style="width:24px;padding:0;"><uw-inline-uncheck-button
                                                            checked="true"
                                                            hideIcon="true"
                                                            width="24"
                                                            (click)="deleteArrayFilter('fibshoep', u)"
                                                        ></uw-inline-uncheck-button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="row">
                                    <div class="col-xs-15">
                                        <uw-select
                                            label="Hoep-Typ"
                                            *ngIf="fibshoepRefJsonItems"
                                            [jsonItems]="fibshoepRefJsonItems"
                                            keyValueMapping="0:1"
                                            nullable="true"
                                            placeholder="Filter hinzufügen"
                                            size="6"
                                            [key]="-1"
                                            (change)="onFilterChanged($event, 'fibshoep'); $event.key = $event.key ?? -1"
                                        ></uw-select>
                                    </div>
                                </div>
                            </article>
                        </uw-accordion-pane>
                    </td>
                </tr>

                <tr uw-accordion-pane>
                    <td style="padding: 0 2px 0 0;"><uw-inline-button
                        iconClass="fa"
                        title="V. Art"
                        value="V. Art"
                        stopPropagation="false"
                    ></uw-inline-button> <span *ngIf="filterBadge.art" class="badge">{{filterBadge.art}}</span></td>
                </tr>
                <tr>
                    <td>
                        <uw-accordion-pane>
                            <article>
                                <div class="row">
                                    <div class="col-xs-15">
                                        <article>
                                            <ng-container
                                                *ngIf="
                                                    (
                                                        arrayFilters.fischart
                                                        && objectKeysHelperFunc(arrayFilters.fischart).length
                                                    )
                                                    ||
                                                    (
                                                        arrayFilters.muschelart
                                                        && objectKeysHelperFunc(arrayFilters.muschelart).length
                                                    )
                                                    || 
                                                    (
                                                        arrayFilters.krebsart
                                                        && objectKeysHelperFunc(arrayFilters.krebsart).length
                                                    )
                                                "
                                            >
                                                <div class="row">
                                                    <div class="col-xs-15">
                                                        <label>Filterauswahl</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-15">
                                                        <table class="table table-striped arrayList">
                                                            <tbody>
                                                                <ng-container *ngIf="arrayFilters.fischart && objectKeysHelperFunc(arrayFilters.fischart).length">
                                                                    <tr *ngFor="let u of objectKeysHelperFunc(arrayFilters.fischart)">
                                                                        <td [title]="arrayFilters.fischart[u].name">{{arrayFilters.fischart[u].name}}</td>
                                                                        <td style="width:24px;padding:0;"><uw-inline-uncheck-button
                                                                            checked="true"
                                                                            hideIcon="true"
                                                                            width="24"
                                                                            (click)="deleteArrayFilter('fischart', u)"
                                                                        ></uw-inline-uncheck-button></td>
                                                                    </tr>
                                                                </ng-container>
                                                                <ng-container *ngIf="arrayFilters.krebsart && objectKeysHelperFunc(arrayFilters.krebsart).length">
                                                                    <tr *ngFor="let u of objectKeysHelperFunc(arrayFilters.krebsart)">
                                                                        <td [title]="arrayFilters.krebsart[u].name">{{arrayFilters.krebsart[u].name}}</td>
                                                                        <td style="width:24px;padding:0;"><uw-inline-uncheck-button
                                                                            checked="true"
                                                                            hideIcon="true"
                                                                            width="24"
                                                                            (click)="deleteArrayFilter('krebsart', u)"
                                                                        ></uw-inline-uncheck-button></td>
                                                                    </tr>
                                                                </ng-container>
                                                                <ng-container *ngIf="arrayFilters.muschelart && objectKeysHelperFunc(arrayFilters.muschelart).length">
                                                                    <tr *ngFor="let u of objectKeysHelperFunc(arrayFilters.muschelart)">
                                                                        <td [title]="arrayFilters.muschelart[u].name">{{arrayFilters.muschelart[u].name}}</td>
                                                                        <td style="width:24px;padding:0;"><uw-inline-uncheck-button
                                                                            checked="true"
                                                                            hideIcon="true"
                                                                            width="24"
                                                                            (click)="deleteArrayFilter('muschelart', u)"
                                                                        ></uw-inline-uncheck-button></td>
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="row">
                                                <div class="col-xs-15">
                                                    <uw-select
                                                        label="Art"
                                                        *ngIf="artRefJsonItems"
                                                        [jsonItems]="artRefJsonItems"
                                                        keyValueMapping="0:1"
                                                        nullable="true"
                                                        placeholder="Filter hinzufügen"
                                                        size="6"
                                                        (change)="onFilterChanged($event, 'art')"
                                                    ></uw-select>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </article>
                        </uw-accordion-pane>
                    </td>
                </tr>

                <tr uw-accordion-pane>
                    <td style="padding: 0 2px 0 0;"><uw-inline-button
                        iconClass="fa"
                        title="VI. Anlass, Art der Befischung"
                        value="VI. Anlass, Art der Befischung"
                        stopPropagation="false"
                    ></uw-inline-button> <span *ngIf="filterBadge.anlass" class="badge">{{filterBadge.anlass}}</span></td>
                </tr>
                <tr>
                    <td>
                        <uw-accordion-pane>
                            <article>
                                <div class="row">
                                    <div class="col-xs-15">
                                        <article>
                                            <ng-container
                                                *ngIf="arrayFilters.anlass && objectKeysHelperFunc(arrayFilters.anlass).length"
                                            >
                                                <div class="row">
                                                    <div class="col-xs-15">
                                                        <label>Filterauswahl</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-15">
                                                        <table class="table table-striped arrayList">
                                                            <tbody>
                                                                <tr *ngFor="let u of objectKeysHelperFunc(arrayFilters.anlass)">
                                                                    <td [title]="arrayFilters.anlass[u].name">{{arrayFilters.anlass[u].name}}</td>
                                                                    <td style="width:24px;padding:0;"><uw-inline-uncheck-button
                                                                        checked="true"
                                                                        hideIcon="true"
                                                                        width="24"
                                                                        (click)="deleteArrayFilter('anlass', u)"
                                                                    ></uw-inline-uncheck-button></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="row">
                                                <div class="col-xs-15">
                                                    <uw-select
                                                        label="Anlass, Art der Befischung"
                                                        *ngIf="anlassRefJsonItems"
                                                        [jsonItems]="anlassRefJsonItems"
                                                        keyValueMapping="0:1"
                                                        nullable="true"
                                                        placeholder="Filter hinzufügen"
                                                        size="6"
                                                        (change)="onFilterChanged($event, 'anlass')"
                                                    ></uw-select>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </article>
                        </uw-accordion-pane>
                    </td>
                </tr>

                <tr uw-accordion-pane>
                    <td style="padding: 0 2px 0 0;"><uw-inline-button
                        iconClass="fa"
                        title="VII. Qualität der Meldung"
                        value="VII. Qualität der Meldung"
                        stopPropagation="false"
                    ></uw-inline-button> <span *ngIf="filterBadge.qualitaet" class="badge">{{filterBadge.qualitaet}}</span></td>
                </tr>
                <tr>
                    <td>
                        <uw-accordion-pane>
                            <article>
                                <div class="row">
                                    <div class="col-xs-15">
                                        <article>
                                            <ng-container
                                                *ngIf="arrayFilters.qualitaet && objectKeysHelperFunc(arrayFilters.qualitaet).length"
                                            >
                                                <div class="row">
                                                    <div class="col-xs-15">
                                                        <label>Filterauswahl</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-15">
                                                        <table class="table table-striped arrayList">
                                                            <tbody>
                                                                <tr *ngFor="let u of objectKeysHelperFunc(arrayFilters.qualitaet)">
                                                                    <td [title]="arrayFilters.qualitaet[u].name">{{arrayFilters.qualitaet[u].name}}</td>
                                                                    <td style="width:24px;padding:0;"><uw-inline-uncheck-button
                                                                        checked="true"
                                                                        hideIcon="true"
                                                                        width="24"
                                                                        (click)="deleteArrayFilter('qualitaet', u)"
                                                                    ></uw-inline-uncheck-button></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="row">
                                                <div class="col-xs-15">
                                                    <uw-select
                                                        label="Qualität der Meldung"
                                                        *ngIf="qualitaetRefJsonItems"
                                                        [jsonItems]="qualitaetRefJsonItems"
                                                        keyValueMapping="0:1"
                                                        nullable="true"
                                                        placeholder="Filter hinzufügen"
                                                        size="6"
                                                        (change)="onFilterChanged($event, 'qualitaet')"
                                                    ></uw-select>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </article>
                        </uw-accordion-pane>
                    </td>
                </tr>

                <ng-container
                    *ngIf="hasExtendedRights"
                >
                    <tr uw-accordion-pane>
                        <td style="padding: 0 2px 0 0;"><uw-inline-button
                            iconClass="fa"
                            title="VIII. Verwaltung"
                            value="VIII. Verwaltung"
                            stopPropagation="false"
                        ></uw-inline-button> <span *ngIf="filterBadge.verwaltung" class="badge">{{filterBadge.verwaltung}}</span></td>
                    </tr>
                    <tr>
                        <td>
                            <uw-accordion-pane>
                                <article>
                                    <div class="row">
                                        <div class="col-xs-15">
                                            <article>
                                                <div class="row">
                                                    <div class="col-xs-15 col-sm-5">
                                                        <uw-select
                                                            label="Unsichere Daten"
                                                            jsonItems='[["ja","Unsicher"], ["nein","Sicher"]]'
                                                            keyValueMapping="0:1"
                                                            nullable="true"
                                                            placeholder="Kein Filter"
                                                            (change)="onFilterChanged($event, 'un')"
                                                        ></uw-select>
                                                    </div>
                                                    <div class="col-xs-15 col-sm-5">
                                                        <uw-select
                                                            label="Untersuchung freigegeben"
                                                            jsonItems='[["ja","Freigegeben"], ["nein","Nicht freigegeben"]]'
                                                            keyValueMapping="0:1"
                                                            nullable="true"
                                                            placeholder="Kein Filter"
                                                            (change)="onFilterChanged($event, 'uf')"
                                                        ></uw-select>
                                                    </div>
                                                    <div class="col-xs-15 col-sm-5">
                                                        <uw-select
                                                            label="Probenahmestelle freigegeben"
                                                            jsonItems='[["ja","Freigegeben"], ["nein","Nicht freigegeben"]]'
                                                            keyValueMapping="0:1"
                                                            nullable="true"
                                                            placeholder="Kein Filter"
                                                            (change)="onFilterChanged($event, 'pf')"
                                                        ></uw-select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-15 col-sm-5">
                                                        <uw-select
                                                            label="FIBS geeignet"
                                                            jsonItems='[["ja","Geeignet"], ["nein","Nicht geeignet"]]'
                                                            keyValueMapping="0:1"
                                                            nullable="true"
                                                            placeholder="Kein Filter"
                                                            (change)="onFilterChanged($event, 'fg')"
                                                        ></uw-select>
                                                    </div>
                                                    <div class="col-xs-15 col-sm-5">
                                                        <uw-select
                                                            label="WRRL-Monitoring"
                                                            jsonItems='[["ja","Monitoring"], ["nein","Kein Monitoring"]]'
                                                            keyValueMapping="0:1"
                                                            nullable="true"
                                                            placeholder="Kein Filter"
                                                            (change)="onFilterChanged($event, 'wg')"
                                                        ></uw-select>
                                                    </div>
                                                    <div class="col-xs-15 col-sm-5">
                                                        <uw-select
                                                            label="FFH-Monitoring"
                                                            jsonItems='[["ja","Monitoring"], ["nein","Kein Monitoring"]]'
                                                            keyValueMapping="0:1"
                                                            nullable="true"
                                                            placeholder="Kein Filter"
                                                            (change)="onFilterChanged($event, 'fm')"
                                                        ></uw-select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-15 col-sm-5">
                                                        <uw-select
                                                            label="FIBS plausibilisiert"
                                                            jsonItems='[["ja","Plausibilisiert"], ["nein","Nicht plausibilisiert"]]'
                                                            keyValueMapping="0:1"
                                                            nullable="true"
                                                            placeholder="Kein Filter"
                                                            (change)="onFilterChanged($event, 'fp')"
                                                        ></uw-select>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </article>
                            </uw-accordion-pane>
                        </td>
                    </tr>
                </ng-container>

            </tbody>
        </table>
    </div>
</div>
