import { Injectable } from "@angular/core";
import UWPersistentStorage from "./implementation";

/**
 * Dieser Dienst speichert Zeichenketten-Schlüssel-Wert-Paare.
 * 
 * Die Schlüssel-Wert-Paare werdeb über einen Seitenaufruf hinaus gespeichert und stehen beim erneuten Aufruf der Anwendung zur Verfügung.
 */
@Injectable({
    providedIn: 'root'
})
export default class UwPersistentStorageService extends UWPersistentStorage {
    constructor() {
        super();
    }
}
