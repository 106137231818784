import { Component, Input, OnInit, OnDestroy, ViewChild, } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { saveAs } from "file-saver";
import { UWFileUpload } from "ng-uw-fileupload";
import ModalService from "./../modal/service";
import DataService from "./../data/service";
import EsriExport from "./esri";
import MapService from "../map/service";
export type Warning = {
    message: string | Array<string>;
    target: string;
    code?: string;
};
export type WarningInfo = {
    message: string | Array<string>;
    target?: string;
    code?: string;
};
@Component({
    selector: "uw-modal-window-tab-export",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowTabExport implements OnDestroy, OnInit {
    @Input()
    public modal: any;
    @Input()
    public topmargin: string = "24";
    public readonly baseHeaders = {
        "Accept": "application/json",
    };
    public esriExport: EsriExport;
    public isProcessingEsri: boolean = false;
    public get esriProgressPerc() {
        let esriProgress = 0;
        if (this.esriExport) {
            esriProgress = this.esriExport.progress;
        }
        let uploadProgress = this.uploadProgress;
        const progress = (uploadProgress * .1 + esriProgress * .9) * 100;
        return Math.min(progress, 100);
    }
    public dataNodePlaceholder: string = "";
    public uploadUrl: string = "";
    public uploadProgress: number = 0.0;
    public warningIsShown: boolean = false;
    public warningStack: Array<Warning> = [];
    public warningTarget: string;
    public warningText: string | Array<string> = null;
    @ViewChild("fileupload", { static: false })
    public fileupload: UWFileUpload;
    public get warningIsShownAsString(): string {
        return String(this.warningIsShown);
    }
    public set warningIsShownAsString(value: string) {
        this.warningIsShown = value === 'true';
    }
    constructor(public modalService: ModalService, public dataService: DataService, public mapService: MapService, public http: HttpClient) {
        this.uploadUrl = dataService.webServiceApiUrl + (dataService.webServiceApiUrl.slice(-1) === "/"
            ? "upload" : "/upload");
    }
    public ngOnInit() {
        this.modal.data.loaded = true;
    }
    public ngOnDestroy() {
        this.esriExport && this.esriExport.cancel();
    }
    public doExportEsriShapefile(uploadpath: string) {
        const esri = this.esriExport = new EsriExport(this.http, this.dataService, () => this.prepareRequest());
        this.isProcessingEsri = true;
        esri.export(uploadpath)
            .then(file => {
                this.resetEsriExport();
                saveAs(file, "esrishape.zip");
            }, _ => {
                this.resetEsriExport();
            });
    }
    public exportEsriShapefile() {
        const csv = this.dataService.buildCsvData();
        this.fileupload.uploadString(csv, "source.csv", "text/csv");
    }
    public exportCsvFile() {
        this.dataService.saveAsFile("csv");
    }
    public fetchUploadFilePath(fileid: string) {
        return this.prepareRequest()
            .then(requtil => this.http.get<{
                resultset: [
                    {
                        path: string;
                    }
                ];
            }>(this.dataService.webServiceApiUrl
                + "upload/" + fileid + "/path"
                + "?_t=" + String(Date.now()) + String(Math.random()).slice(2), {
                    headers: requtil.headers(),
                }).toPromise())
            .then(resp => resp.resultset[0].path);
    }
    public prepareRequest() {
        const baseHeaders = this.baseHeaders;
        return this.dataService.getLoginTokenPromise()
            .then(token => {
                const tokenHeaders = {
                    "X-AUTH-TOKEN": token,
                };
                return {
                    headers: (otherHeaders?: {
                        [name: string]: any;
                    }) => new HttpHeaders(Object.assign({}, baseHeaders, tokenHeaders, otherHeaders)),
                };
            });
    }
    public resetEsriExport() {
        this.uploadProgress = 0.0;
        this.esriExport = null;
        this.isProcessingEsri = false;
    }
    public warn(w: WarningInfo) {
        if (!w.target)
            w.target = "submit";
        this.warningStack.push(<Warning>w);
        this.warningText = w.message;
        this.warningIsShown = true;
    }
    public warnFromResponse(response: Response) {
        const warning = {
            "400": {
                code: "InvalidData",
                message: "Bitte überprüfen Sie alle eingegebene Daten auf Korrektheit!",
            },
            "403": {
                code: "ActionForbidden",
                message: "Sie haben nicht die nötigen Rechte!",
            },
            "500": {
                code: "ServiceUnavailable",
                message: "Die Anfrage kann aktuell nicht bearbeitet werden. Bitte probieren Sie es später noch einmal.",
            }
        }[response.status]
            || {
                code: "UnknownError",
                message: "Unbekannter Fehler im Datenservice. Bitte wenden Sie sich an Ihren IT-Dienstleister.",
            };
        if (warning)
            this.warningRemove((w) => w.code === warning.code);
        this.warn(warning);
    }
    public closeWarning() {
        this.warningIsShown = false;
        this.warningText = null;
        this.warningTarget = null;
    }
    public warningRemove(filter: (item: Warning, index: number) => boolean) {
        const stack = this.warningStack;
        for (let i = 0; i < stack.length;) {
            if (filter(stack[i], i)) {
                stack.splice(i, 1);
            }
            else
                ++i;
        }
    }
    public warningTopShow() {
        this.warningTarget = this.warningStack[0].target;
        this.warningText = this.warningStack[0].message;
        this.warningIsShown = true;
    }
    public onUploadProgress(progress: number) {
        this.uploadProgress = progress;
    }
    public onUploadSuccess(result: any) {
        const fileid = result.data.resultset[0].id;
        this.fetchUploadFilePath(fileid)
            .then(uploadpath => this.doExportEsriShapefile(uploadpath));
    }
    public onUploadError(result: any) {
        this.resetEsriExport();
    }
}
