import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoQualitaetdermeldung from "./interface";
import FischinfoBefischung from "./../../fischinfo/befischung/model";
@fn.DataModel
export default class FischinfoQualitaetdermeldung extends DataModel implements IFischinfoQualitaetdermeldung {
    constructor(obj?: object | IFischinfoQualitaetdermeldung) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoQualitaetdermeldung): FischinfoQualitaetdermeldung {
        return new FischinfoQualitaetdermeldung(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoQualitaetdermeldung): FischinfoQualitaetdermeldung {
        if (obj.hasOwnProperty("b")) {
            Object.assign(this, {
                bezeichnung: obj["a"],
                id: obj["b"],
                kurzbezeichnung: obj["c"]
            });
        } else {
            Object.assign(this, obj);
            obj && obj["fischinfoBefischungListByForeignQualitaetdermeldung_id"] && obj["fischinfoBefischungListByForeignQualitaetdermeldung_id"].length && (this.fischinfoBefischungListByForeignQualitaetdermeldung_id = obj["fischinfoBefischungListByForeignQualitaetdermeldung_id"].map(FischinfoBefischung.deserialize));
        } return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "kurzbezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public kurzbezeichnung?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoBefischung>}
     */
    @fn.DataMember({ name: "fischinfoBefischungListByForeignQualitaetdermeldung_id", type: [FischinfoBefischung], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBefischungListByForeignQualitaetdermeldung_id?: Array<FischinfoBefischung>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
