import { Component, ContentChildren, QueryList, AfterContentInit, Input, Output, EventEmitter } from "@angular/core";
import UWRadiobutton from "../component";
/**
 * Diese Kompoente stellt eine Auwahl aus einer Menge von Werten dar.
 *
 * Die Auswahlgruppe umfasst eine oder mehr Auswahloptionen repräsentiert durch <uw-radiobutton>-Eingabekomponenten. Aus dieser Menge kann immer nur eine Option ausgewählt sein. Wenn der Nutzer eine Auswahloption auswählt, wird der Wert der ausgewählten Option zum neuen Wert der Auswahlgruppe.
 */
@Component({
    selector: "uw-radiobutton-group",
    templateUrl: "./default.component.html",
    styleUrls: [
        "./default.component.less"
    ]
})
export default class UWRadiobuttongroup implements AfterContentInit {
    @Input()
    public inputValue: string;
    @Input()
    public infoTag: string;
    @Input()
    public label: string;
    @Output()
    public change: EventEmitter<UWRadiobuttongroup> = new EventEmitter();
    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter();
    @Input()
    public set value(value: string) {
        if (this.inputValue !== value) {
            this.inputValue = value;
            this.updateButtonsFromValue();
            this.valueChange.emit(this.inputValue);
        }
    }
    public get value() { return this.inputValue; }
    @Input()
    public set disabled(value: boolean) {
        if (this.btns) {
            this.btns.forEach(btn => btn.disabled = value);
        }
        else if (this._buttonInitializer) {
            this._buttonInitializer.disabled = value;
        }
    }
    public get disabled() {
        return Boolean(this.btns) && this.btns.first.disabled;
    }
    public _buttonInitializer: RadioButtonInitializer;
    public _boundUpdateButtonValue: (btn: UWRadiobutton) => void;
    @ContentChildren(UWRadiobutton)
    public btns: QueryList<UWRadiobutton>;
    constructor() {
        this._boundUpdateButtonValue = (radiobtn) => {
            radiobtn.checked = radiobtn.value === this.value;
        };
        this._buttonInitializer = new RadioButtonInitializer(() => delete this._buttonInitializer);
        this._buttonInitializer.changeObserver = (radiobtn) => {
            if (radiobtn.checked === true) {
                // setter aufruf
                this.value = radiobtn.value;
                this.btns.forEach(otherbtn => {
                    if (otherbtn !== radiobtn) {
                        otherbtn.checked = false;
                    }
                });
                this.change.emit(this);
            }
            else {
                radiobtn.checked = true;
            }
        };
        this._buttonInitializer.updateButtonValue = this._boundUpdateButtonValue;
    }
    public updateButtonsFromValue() {
        if (!this.btns)
            return;
        void this.btns.forEach(this._boundUpdateButtonValue);
    }
    public ngAfterContentInit() {
        this._buttonInitializer.initializeAllButtons(this.btns);
    }
}
interface ForEachable<T> {
    forEach(iteration: (item: T, index?: number, collection?: any) => void);
}
class RadioButtonInitializer {
    public disabled: boolean;
    public changeObserver: (radiobtn: UWRadiobutton) => void;
    public updateButtonValue: (radiobtn: UWRadiobutton) => void;
    private _destroy: () => void;
    public constructor(destroy: () => void) {
        this._destroy = destroy;
    }
    public initializeAllButtons(radiobtns: ForEachable<UWRadiobutton>) {
        radiobtns.forEach(btn => this.initializeButton(btn));
        this._destroy();
    }
    protected initializeButton(radiobtn: UWRadiobutton) {
        radiobtn.disabled = this.disabled;
        radiobtn.change.subscribe(this.changeObserver);
        this.updateButtonValue(radiobtn);
    }
}
