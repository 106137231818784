import { Component } from "@angular/core";
import DataService from "./../data/service";
import MapService from "./../map/service";
import { KeyValue as UWTypeAheadKeyValue, SuggestionService as UWTypeAheadSuggestionService } from "ng-uw-type-ahead";
import UserService from "../user/service";
/**
 * Diese Komponente stellt dem Nutzer ein Suchfeld für geographische Elemente zur Verfügung.
 *
 * Wenn der Nutzer einen Suchterm eingibt, zeigt diese Komponente eine Auswahlliste von
 * geographischen Elemente, die den Suchterm im Namen enthalten. Wenn der Nutzer einen
 * Eintrag aus der Auswahlliste auswählt, dann weist diese Komponente die Karte an, das
 * geographische Element anzuzeigen.
 */
@Component({
    selector: "uw-map-search",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class MapSearch {
    public mapSearchGetGeomService: UWTypeAheadSuggestionService;
    constructor(public dataService: DataService, public mapService: MapService, public userService: UserService) { }
    public get isExtendedView(): boolean {
        return this.userService.isLoggedIn === true;
    }
    public onMapSearchInputChanged(event: any): void {
        if (event.selectedValueIsSuggested && event.key) {
            if (!this.mapSearchGetGeomService) {
                this.mapSearchGetGeomService = event.suggestionFactoryService.createSuggestionService({
                    limit: event.limit,
                    jsonItems: event.jsonItems && JSON.parse(event.jsonItems),
                    staticJsonFileUrl: event.staticJsonFileUrl,
                    webServiceApiUrl: this.dataService.webServiceApiUrl +
                        "gebietsbezug/{id}?shape=true&bbox=true&geomrefname={geomrefname}&geomrefnameid={geomrefnameid}&geomrefnameschema={geomrefnameschema}&geomrefnametable={geomrefnametable}&geomrefvalueid={geomrefvalueid}&format=application/json",
                    webServiceApiTokenBasedLoginUrl: event.webServiceApiTokenBasedLoginUrl,
                    itemsAccess: event.itemsAccess && event.itemsAccess_parse(event.itemsAccess),
                    matcherFactory: event.patternMatchingOnSuggestions
                        ? (pattern: string) => (suggestion: UWTypeAheadKeyValue) => suggestion[event.keyValueMap.value]
                            .toLowerCase()
                            .indexOf(pattern.toLowerCase()) >= 0
                        : null
                });
            }
            event.suggestions.forEach(element => {
                if (element.key === event.key) {
                    this.mapSearchGetGeomService
                        .search(element.suggestion)
                        .catch((e: Error) => {
                            this.mapService.zoomToBounds(null, null, null);
                            this.mapService.drawToggleGeoJSON(event.id, null, null);
                            return [];
                        })
                        .then((result: any) => {
                            if (result && result[0] && result[0].bbox) {
                                let bbox = JSON.parse(result[0].bbox);
                                let a = this.mapService.project("EPSG:25832", this.mapService.projection, [bbox.coordinates[0][2][0], bbox.coordinates[0][0][1]]);
                                let b = this.mapService.project("EPSG:25832", this.mapService.projection, [bbox.coordinates[0][0][0], bbox.coordinates[0][1][1]]);
                                this.mapService.zoomToBounds([a[1], a[0]], [b[1], b[0]], this.mapService.projection);
                                this.mapService.drawToggleGeoJSON(event.id, JSON.parse(result[0].shape), "EPSG:25832", this.mapService.getMapFeatureStyle("Kartensuche"));
                                if (!this.isExtendedView) {
                                    if (element.suggestion.geomrefnametable === "gsk3c_gewaesser"
                                        || element.suggestion.geomrefnametable === "gsk3c_gew_flaeche") {
                                        this.dataService.setFilter("gewkz", element.suggestion.name
                                            .split(" (")[1]
                                            .split(")")[0]);
                                        this.dataService.setFilter("geomrefname", null);
                                        this.dataService.setFilter("geomrefnameid", null);
                                        this.dataService.setFilter("geomrefnameschema", null);
                                        this.dataService.setFilter("geomrefnametable", null);
                                        this.dataService.setFilter("geomrefvalueid", null);
                                    }
                                    else {
                                        this.dataService.setFilter("gewkz", null);
                                        this.dataService.setFilter("geomrefname", element.suggestion.geomrefname);
                                        this.dataService.setFilter("geomrefnameid", element.suggestion.geomrefnameid);
                                        this.dataService.setFilter("geomrefnameschema", element.suggestion.geomrefnameschema);
                                        this.dataService.setFilter("geomrefnametable", element.suggestion.geomrefnametable);
                                        this.dataService.setFilter("geomrefvalueid", element.suggestion.geomrefvalueid);
                                    }
                                    this.dataService.loadDataStore();
                                }
                            }
                        });
                }
            });
        }
        else {
            this.mapService.drawToggleGeoJSON(event.id, null, null);
            if (!this.isExtendedView
                &&
                (this.dataService.filter["geomrefname"]
                    ||
                    this.dataService.filter["gewkz"])) {
                this.dataService.setFilter("gewkz", null);
                this.dataService.setFilter("geomrefname", null);
                this.dataService.setFilter("geomrefnameid", null);
                this.dataService.setFilter("geomrefnameschema", null);
                this.dataService.setFilter("geomrefnametable", null);
                this.dataService.setFilter("geomrefvalueid", null);
                this.dataService.loadDataStore();
            }
            // this.mapService.zoomToBounds(null, null, null);
        }
    }
}
