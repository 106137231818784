import { Component, Input, Output, OnInit, EventEmitter, ViewChildren, QueryList, ElementRef, } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import UWSelect from "../component";
export interface Node {
    id: any;
    node: any;
    parentnode: any;
}
export interface Level {
    webServiceApiUrl: string;
    firstKey?: any;
}
/**
 * Diese Eingabekomponente stellt eine Auswahliste ähnlich HTML-<select> dar.
 *
 * Die Komponente unterscheidet sich von HTML-<select> in dem, dass die Auswahloptionen als beliebig tiefer Baum strukturiert sind. Der Nutzer kann jeden Knoten der Baumstruktur als Options auswählen. Jede Ebene der Baumstruktur wird als eigenes <uw-select> angezeigt.
 */
@Component({
    selector: "uw-tree-select",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class UWTreeSelect implements OnInit {
    @Input()
    public disabled: string = "";
    @Input()
    public itemsAccess: string;
    @Input()
    public keyValueMapping: string;
    @Input()
    public set label(value: string) { this.labels[0] = value; }
    ;
    @Input()
    public infoTag: string = "";
    @Input()
    public infoTagClass: string = "fa-info-circle";
    @Input()
    public minDepth: number = 0;
    @Input()
    public limit: number;
    @Input()
    public rootPlaceholder: string = "";
    @Input()
    public childPlaceholder: string = "";
    @Input()
    public size: number = null;
    @Input()
    public nullable: boolean = false;
    @Input()
    public webServiceApiUrl: string;
    @Input()
    public webServiceApiTokenBasedLoginUrl: string;
    @Input()
    public rootNode: string = "0";
    @Input()
    public set key(value: any) {
        if (value == null) {
            if (this.levels) {
                this.resetAllLevels();
            }
        }
        else {
            value = value.toString(); // allow numbers in key
            const found = this.findSelectWithKey(value);
            if (found) {
                this.findNodeWithId(value).then(node => {
                    found.select.key = value;
                    this.updateLevel(found.index, node);
                });
            }
            else {
                this.findPathForId(value).then(path => void (path && this.updateAllLevels(path["reverse"]())));
            }
        }
    }
    @Output()
    public keyChange: EventEmitter<any> = new EventEmitter();
    @Output()
    public change: EventEmitter<UWTreeSelect> = new EventEmitter();
    public lastChangedSelect: UWSelect;
    public get changedSelect(): UWSelect { return this.lastChangedSelect; }
    public lastChangedLevel: number;
    public get changedLevel(): number { return this.lastChangedLevel; }
    public lastWasNulled: boolean;
    public get wasNulled(): boolean { return this.lastWasNulled; }
    public get key() {
        return this.lastChangedSelect && this.lastChangedSelect.key;
    }
    public get value() {
        return this.lastChangedSelect && this.lastChangedSelect.value;
    }
    public labels: Array<string> = [];
    public levels: Array<Level> = null;
    public allNodes: Promise<Array<Node>>;
    public allNodesCtrl: {
        fulfill: (v: Array<Node>) => void;
        reject: (e: any) => void;
    };
    @ViewChildren(UWSelect)
    public selects: QueryList<UWSelect>;
    constructor(public http: HttpClient, public hostElement: ElementRef) {
        this.allNodes = new Promise<Array<Node>>((fulfill, reject) => (this.allNodesCtrl = {
            fulfill: v => (delete this.allNodesCtrl, fulfill(v)),
            reject: r => (delete this.allNodesCtrl, reject(r))
        })).then(_ => (this.resetAllLevels(), _));
    }
    public ngOnInit() {
        this.initAllNodes();
        this.initAllLabels();
    }
    public initAllLabels() {
        const hostElement: Element = this.hostElement.nativeElement;
        if (hostElement.hasAttributes()) {
            const attrs = hostElement.attributes;
            for (let i = 0; i < attrs.length; ++i) {
                const attr = attrs[i];
                if (attr.name.startsWith("label")) {
                    const idx = Number(attr.name.slice(5));
                    if (!Number.isNaN(idx) && Number.isFinite(idx)) {
                        this.labels[idx] = attr.value;
                    }
                }
            }
        }
    }
    public initAllNodes() {
        type ListPartResult = {
            resultset: Array<Node>;
            next: string | null;
        };
        let lastNodes = null;
        const { fulfill, reject } = this.allNodesCtrl;
        const processChunk = (data: ListPartResult) => {
            if (lastNodes) {
                lastNodes.push.apply(lastNodes, data.resultset);
            }
            else {
                lastNodes = Array.from(data.resultset);
            }
            if (data.next) {
                fetchChunk(data.next);
            }
            else {
                fulfill(<Array<Node>>lastNodes);
            }
        };
        const fetchChunk = url => this.http.get<ListPartResult>(url).subscribe(processChunk, reject);
        fetchChunk(this.webServiceApiUrl + "?format=application/json&limit=1000");
    }
    public changeOnLevel(index: number, select: UWSelect) {
        const selected = select.suggestions[select.hoverIndex];
        if (select.key === null && this.nullable) {
            this.lastChangedSelect = select;
            this.lastChangedLevel = index;
            this.lastWasNulled = true;
            this.resetLevel(index);
            if (index > 0) {
                this.selects.some((s, i) => {
                    const isAbove = i === index - 1;
                    if (isAbove) {
                        this.lastChangedSelect = s; // patch for .key to work as expected
                        this.lastChangedLevel = i;
                        this.keyChange.emit(s.key);
                    }
                    return isAbove;
                });
            }
            else {
                this.keyChange.emit(null);
            }
            this.change.emit(this);
        }
        else if (selected) {
            this.lastChangedSelect = select;
            this.lastChangedLevel = index;
            this.lastWasNulled = false;
            this.updateLevel(index, selected.suggestion);
            this.keyChange.emit(select.key);
            this.change.emit(this);
        }
    }
    public resetLevel(index) {
        if (!this.levels)
            return;
        if (index < this.levels.length - 1) {
            this.levels = this.ensureMinDepth(this.levels.slice(0, index + 1));
        }
    }
    public resetAllLevels() {
        if (typeof this.allNodesCtrl !== "undefined")
            return;
        const a = Array.of({
            webServiceApiUrl: this.getChildrenUrlOfNodeId(this.rootNode)
        });
        this.levels = this.ensureMinDepth(a);
    }
    public updateLevel(index, node) {
        this.findAnyChildOfNode(node).then(child => {
            let a;
            if (child) {
                a = this.ensureMinDepth(this.levels.slice(0, index + 2));
                a[index + 1] = {
                    webServiceApiUrl: this.getChildrenUrlOfNodeId(node.node)
                };
            }
            else {
                a = this.levels.slice(0, index + 1);
            }
            this.levels = this.ensureMinDepth(a);
        });
    }
    public updateAllLevels(path: Array<Node>) {
        const a = Array(path.length);
        a[0] = {
            webServiceApiUrl: this.getChildrenUrlOfNodeId(this.rootNode),
            firstKey: void 0
        };
        for (let i = 0, bound = path.length - 1; i <= bound; ++i) {
            let node = path[i];
            a[i].firstKey = node.id;
            if (i < bound) {
                a[i + 1] = {
                    webServiceApiUrl: this.getChildrenUrlOfNodeId(node.node),
                    firstKey: void 0
                };
            }
        }
        this.levels = this.ensureMinDepth(a);
    }
    public findSelectWithKey(key: any) {
        let found = null;
        if (this.selects && this.levels) {
            this.selects.some((select, index) => {
                const hasKey = select.suggestions.some(data => data.key === key);
                if (hasKey) {
                    found = { select, index };
                }
                return hasKey;
            });
        }
        return found;
    }
    public findNodeWithId(id) {
        return this.allNodes.then(allNodes => allNodes.find(node => String(node.id) === String(id)));
    }
    public findAnyChildOfNode(node) {
        return this.allNodes.then(allNodes => node.node == null
            ? void 0
            : allNodes.find(child => child.parentnode != null && String(child.parentnode) === String(node.node)));
    }
    public findPathForId(id) {
        return Promise.all([
            this.findNodeWithId(id),
            this.allNodes
        ]).then(([first, allNodes]) => {
            if (first) {
                let path = [first];
                for (let i = 0; i < path.length; ++i) {
                    const node = path[i];
                    if (node.parentnode == null || String(node.parentnode) === String(this.rootNode)) {
                        return path;
                    }
                    const parent = allNodes.find(other => String(other.node) === String(node.parentnode));
                    if (parent) {
                        path.push(parent);
                    }
                    else {
                        throw new RangeError("UWTreeSelect: Non-existent parent node");
                    }
                }
            }
            else
                return null;
        });
    }
    public getChildrenUrlOfNodeId(nodeid) {
        return (this.webServiceApiUrl +
            "?format=application/json&parentnode=" +
            encodeURIComponent(String(nodeid)));
    }
    public ensureMinDepth(levels: Array<Level>) {
        const minDepth = this.minDepth | 0;
        if (levels.length < minDepth) {
            levels.push.apply(levels, Array(minDepth - levels.length).fill(null));
        }
        return levels;
    }
}
