import DecoratorHelper from "./../helper";
import DataModelBase from "../../models/model";
export default function DataModel<T extends { new(...args: any[]): DataModelBase }>(constructor: T) {
    // Override placeholder model methods:
    constructor.prototype.listModelProperties = (view?: string): any => {
        const modelProps = DecoratorHelper.modelPropertyMetadata(constructor);
        switch (view) {
            case "table":
                type TableViewProperties = {
                    columnIndex: 0,
                    name: string,
                    label: string,
                    columnPercentualWidth: number,
                    dependsOnFilter: string,
                    align: "left" | "right" | "center",
                    cssClass: string,
                    sortBy: string,
                    sortType: string,
                };
                return Object.keys(modelProps)
                    .filter(key => modelProps[key].table)
                    .reduce((td, key) => {
                        td[key] = {
                            columnIndex: modelProps[key].table.columnIndex,
                            name: key,
                            label: modelProps[key].table.label || modelProps[key].label,
                            columnPercentualWidth: modelProps[key].table.columnPercentualWidth || 0,
                            dependsOnFilter: modelProps[key].table.dependsOnFilter || "",
                            align: modelProps[key].table.align,
                            cssClass: modelProps[key].table.cssClass || "",
                            sortBy: modelProps[key].table.sortBy,
                            sortType: modelProps[key].table.sortType,
                        };
                        return td;
                    }, {} as Record<string, TableViewProperties>)
                    ;
            case "csv":
                type CSVViewProperties = {
                    columnIndex: 0,
                    name: string,
                    label: string,
                    type: string,
                };
                return Object.keys(modelProps)
                    .filter(key => modelProps[key].csv)
                    .reduce((td, key) => {
                        td[key] = {
                            columnIndex: modelProps[key].table.columnIndex || modelProps[key].csv.columnIndex,
                            name: key,
                            label: modelProps[key].csv.label || modelProps[key].label,
                            type: modelProps[key].csv.type,
                        };
                        return td;
                    }, {} as Record<string, CSVViewProperties>)
                    ;
            case "shp":
                type SHPViewProperties = {
                    columnIndex: 0,
                    name: string,
                    shpfield: string,
                    label: string,
                    type: string,
                };
                return Object.keys(modelProps)
                    .filter(key => modelProps[key].shp)
                    .reduce((td, key) => {
                        td[key] = {
                            columnIndex: modelProps[key].table.columnIndex || modelProps[key].shp.columnIndex,
                            name: key,
                            shpfield: modelProps[key].shp.shpfield,
                            label: modelProps[key].shp.label || modelProps[key].label,
                            type: modelProps[key].shp.type,
                        };
                        return td;
                    }, {} as Record<string, SHPViewProperties>)
                    ;
            default:
                return modelProps;
        }
    }

    constructor.prototype.modelData = function () {
        return JSON.parse(JSON.stringify(this));
    }

    // overide model constructor:
    // return class extends constructor {}
}