import { Injectable } from "@angular/core";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import DataService from "../data/service";
import UserService from "../user/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";

@Injectable({
    providedIn: 'root'
})
export default class FormFischinfoProbenahmestelleService {
    public modelProps = [];

    constructor(
        protected dataService: DataService,
        protected userService: UserService,
    ) {
        this.modelProps["FischinfoProbenahmestelle"] = FischinfoProbenahmestelle.prototype.listModelProperties();
    }

    public _editMode: boolean;
    public get editMode(): boolean {
        return this._editMode;
    }
    public set editMode(value: boolean) {
        this._editMode = value;
    }

    public label(model: string, key: string) {
        return (this.modelProps[model][key] && this.modelProps[model][key].label) || "N.N.";
    }

    public calcUntersuchungenList(untersuchungList: FischinfoUntersuchung[], isExtendedView: boolean): any[] {
        return untersuchungList
            && untersuchungList
                .filter(x => isExtendedView
                    || (
                        !x.unsicher
                        && x.freigegeben
                    )
                )
                .sort((a, b) => {
                    return (a.untersuchungstermin < b.untersuchungstermin)
                        ? 1
                        : (
                            (b.untersuchungstermin < a.untersuchungstermin)
                                ? -1
                                : 0
                        )
                        ;
                })
            ;
    }

}