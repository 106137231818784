<div class="row">
    <div class="col-xs-15">
        <label *ngIf="!readonly">Geben Sie die FFH-Bewertung für die Fischart aus</label>
        <label *ngIf="readonly">FFH-Bewertung für die Fischart</label>
    </div>
</div>

<div class="row" style="padding-top:10px;">
    <div class="col-xs-6" style="text-align:right;">
        <label>Zustand der Population</label>
    </div>
    <div class="col-xs-9">
        <uw-radiobutton-group
            (change)="onZustandDerPopulationChange($event)"
            [value]="zustandderpopulation"
            [disabled]="readonly"
        >
            <uw-radiobutton 
                value="A"
                label="A"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="B"
                label="B"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="C"
                label="C"
                labelAt="e"
            ></uw-radiobutton>
        </uw-radiobutton-group>
    </div>
</div>

<div class="row">
    <div class="col-xs-6" style="text-align:right;">
        <label>Habitatqualität</label>
    </div>
    <div class="col-xs-9">
        <uw-radiobutton-group
            (change)="onHabitatQualitaetChange($event)"
            [value]="habitatqualitaet"
            [disabled]="readonly"
        >
            <uw-radiobutton 
                value="A"
                label="A"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="B"
                label="B"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="C"
                label="C"
                labelAt="e"
            ></uw-radiobutton>
        </uw-radiobutton-group>
    </div>
</div>

<div class="row">
    <div class="col-xs-6" style="text-align:right;">
        <label>Beeinträchtigungen</label>
    </div>
    <div class="col-xs-9">
        <uw-radiobutton-group
            (change)="onBeeintraechtigungenChange($event)"
            [value]="beeintraechtigungen"
            [disabled]="readonly"
        >
            <uw-radiobutton 
                value="A"
                label="A"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="B"
                label="B"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="C"
                label="C"
                labelAt="e"
            ></uw-radiobutton>
        </uw-radiobutton-group>
    </div>
</div>

<div class="row" style="padding-top:10px;">
    <div class="col-xs-6" style="text-align:right;">
        <label>Gesamtbewertung (berechnet)</label>
    </div>
    <div class="col-xs-2">
        <uw-textinput
            [value]="gesamtwert"
            disabled="true"
        ></uw-textinput>
    </div>
</div>

<div class="row" style="padding-top:10px;">
    <div class="col-xs-6" style="text-align:right;">
        <label>Gesamtbewertung (abweichend)</label>
    </div>
    <div class="col-xs-9">
        <uw-radiobutton-group
            (change)="onGesamtwertManuellChange($event)"
            [value]="gesamtwertmanuell"
            [disabled]="readonly"
        >
            <uw-radiobutton 
                value="A"
                label="A"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="B"
                label="B"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="C"
                label="C"
                labelAt="e"
            ></uw-radiobutton>
        </uw-radiobutton-group>
    </div>
</div>

<div
    *ngIf="showBegruendung"
    class="row"
>
    <div class="col-xs-15">
        <uw-textarea
            label="Geben sie eine Begründung für die abweichende FFH-Bewertung ein"
            [(value)]="begruendung"
            [disabled]="readonly"
        ></uw-textarea>
    </div>
</div>




