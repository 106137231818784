import { Component, Input, AfterViewInit, OnDestroy } from "@angular/core";
import UserService from "../user/service";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FormFischinfoProbenahmestelleService from "../form-fischinfo-probenahmestelle/service";
import DataService from "./../data/service";
import { LoginService } from "ng-uw-login-service";
import MapEditorService from "../map-editor/service";
import ModalService from "../modal/service";
import FibsHoep from "../../models/fibs/hoep/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FormFischinfoUntersuchungService from "../form-fischinfo-untersuchung/service";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
@Component({
    selector: "uw-modal-window-tab-probenahmestelle",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowTabProbenahmestelle implements AfterViewInit {
    @Input()
    public modal: any;
    public _mode: string;
    @Input()
    public set mode(value: string) { const oldValue = this._mode; this._mode = value; if (value === "Edit" && oldValue !== "Edit") { /* this.onFormChangedToEditMode() */ } }
    public get mode(): string { return this._mode; }
    public _topmargin: string;
    @Input()
    public set topmargin(value: string) { this._topmargin = String(Number(value)); /* - (this.userService.isEditor ? 0 : Number(this.buttonPanelHeight))); */ }
    ;
    public get topmargin(): string { return this._topmargin; }
    ;
    public buttonPanelHeight: string = "24";
    public warningIsShown: string = "false";
    public warningText: string | Array<string> = "";
    public warningTarget: any;
    public warningStack: Array<any> = [];
    public probenahmestelleInCache: FischinfoProbenahmestelle;
    public get probenahmestelle(): FischinfoProbenahmestelle {
        return this.modal && this.modal.data && this.modal.data.probenahmestelle;
    }
    public set probenahmestelle(item: FischinfoProbenahmestelle) {
        this.modal && this.modal.data && (this.modal.data.probenahmestelle = item);
    }
    public get hasRightToEditPS(): boolean {
        return this.hasRightToEditPSData
            || this.hasRightToEditPSGeometry
            || this.hasRightToDeletePS
            || this.hasRightToAddUntersuchung;
    }
    public get hasRightToEditPSData(): boolean {
        return this.userService.roles
            && (this.userService.roles.filter((x: string): boolean => x == "ROLE_ADMIN").length
                || (this.userService.roles.filter((x: string): boolean => x == "ERFASSER_INTERN").length
                    && this.probenahmestelle
                    && !this.probenahmestelle.freigegeben
                    && !this.probenahmestelle.abgeschlossen))
            ? true
            : false;
    }
    public get hasRightToEditPSGeometry(): boolean {
        return this.userService.roles
            && (this.userService.roles.filter((x: string): boolean => x == "ROLE_ADMIN").length
                || (this.userService.roles.filter((x: string): boolean => x == "ERFASSER_INTERN").length
                    && this.probenahmestelle
                    && !this.probenahmestelle.freigegeben
                    && !this.probenahmestelle.abgeschlossen))
            ? true
            : false;
    }
    public anzahlUntersuchungen: number = -1;
    public onAnzahlUntersuchungenChanged(e) {
        this.anzahlUntersuchungen = parseInt(e.toString(), 10);
    }
    public get hasRightToDeletePS(): boolean {
        return this.anzahlUntersuchungen > -1
            && this.anzahlUntersuchungen < 1
            && this.userService.roles
            && (this.userService.roles.filter((x: string): boolean => x == "ROLE_ADMIN").length
                || (this.userService.roles.filter((x: string): boolean => x == "ERFASSER_INTERN").length
                    && this.probenahmestelle
                    && !this.probenahmestelle.freigegeben
                    && !this.probenahmestelle.abgeschlossen))
            ? true
            : false;
    }
    public get hasRightToAddUntersuchung(): boolean {
        return this.userService.roles
            && (this.userService.roles.filter((x: string): boolean => x == "ROLE_ADMIN" || x == "ERFASSER_INTERN").length
                || (this.userService.roles.filter((x: string): boolean => x == "ERFASSER_EXTERN").length
                    && this.probenahmestelle
                    && this.probenahmestelle.freigegeben))
            ? true
            : false;
    }
    constructor(public userService: UserService, public formFischinfoProbenahmestelleService: FormFischinfoProbenahmestelleService, public dataService: DataService, public loginService: LoginService, public mapEditorService: MapEditorService, public modalService: ModalService, public formFischinfoUntersuchungService: FormFischinfoUntersuchungService) {
        this.topmargin = this.isExtendedView && this.hasRightToEditPS ? this.buttonPanelHeight : "0";
    }
    public ngAfterViewInit() {
        this.mapEditorService.cancelProbenahmestelle
            .next(null);
        this.formFischinfoProbenahmestelleService.editMode = false;
        this.isExtendedViewContentShown = false;
        setTimeout(() => {
            this.isExtendedViewContentShown = true;
        }, 0);
        if (this.modal.data.errorMessage) {
            this.warningIsShown = "true";
            this.warningText = this.modal.data.errorMessage;
            this.modal.data.errorMessage = null;
        }
        if (this.probenahmestelle["setEditMode"]) {
            this.probenahmestelle["setEditMode"] = null;
            this.setEditMode();
        }
    }
    public ngOnDestroy() {
    }
    public get readonly(): boolean {
        return !this.formFischinfoProbenahmestelleService.editMode;
    }
    public setEditMode() {
        this.probenahmestelleInCache = new FischinfoProbenahmestelle(JSON.parse(JSON.stringify(this.probenahmestelle)));
        this.mode = "Edit";
        setTimeout(() => {
            this.formFischinfoProbenahmestelleService.editMode = true;
        }, 0);
    }
    public isExtendedViewContentShown: boolean = true;
    public onClickEdit(e) {
        this.setEditMode();
    }
    public onStillgewaesserChanged(e) {
        this.probenahmestelle["stillgewaesserChanged"] = true;
    }
    public onClickCancel(e) {
        if (!this.modal.data.saving) {
            this.mode = "";
            this.warningTarget = null;
            this.warningText = "";
            this.warningIsShown = "false";
            setTimeout(() => {
                this.probenahmestelle = new FischinfoProbenahmestelle(JSON.parse(JSON.stringify(this.probenahmestelleInCache)));
                this.formFischinfoProbenahmestelleService.editMode = false;
            }, 0);
        }
    }
    public onClickSubmit(e) {
        this.warningStack.forEach((element, index) => {
            if (element.target === "submit") {
                this.warningStack.splice(index, 1);
            }
        });
        // this.validateOnSubmitTitel();
        // this.validateOnSubmitTyp();
        if (!this.warningStack.length) {
            if (!this.modal.data.saving) {
                this.modal.data.saving = true;
                const stillgewaesserChanged = this.probenahmestelle["stillgewaesserChanged"] ? true : false;
                this.probenahmestelle["stillgewaesserChanged"] = null;
                let validProbenahmestelleInCache = new FischinfoProbenahmestelle(JSON.parse(JSON.stringify(this.probenahmestelleInCache)));
                this.probenahmestelleInCache = new FischinfoProbenahmestelle(JSON.parse(JSON.stringify(this.probenahmestelle)));
                const postData = new FischinfoProbenahmestelle(JSON.parse(JSON.stringify(this.probenahmestelle))), finishFunc = () => {
                    setTimeout(() => {
                        this.modal.data.saving = false;
                    }, 0);
                };
                this.dataService.save<FischinfoProbenahmestelle>("probenahmestelle", postData)
                    .then((response): FischinfoProbenahmestelle | Promise<FischinfoProbenahmestelle> => {
                        return stillgewaesserChanged
                            ? this.dataService.get<FischinfoProbenahmestelle>("probenahmestelle", FischinfoProbenahmestelle, String(postData.id), null, null, false)
                            : postData;
                    })
                    .then((probenahmestelle: FischinfoProbenahmestelle): boolean => {
                        if (stillgewaesserChanged) {
                            this.probenahmestelle = probenahmestelle;
                            this.probenahmestelleInCache = new FischinfoProbenahmestelle(JSON.parse(JSON.stringify(this.probenahmestelle)));
                            validProbenahmestelleInCache = new FischinfoProbenahmestelle(JSON.parse(JSON.stringify(this.probenahmestelleInCache)));
                            this.isExtendedViewContentShown = false;
                            setTimeout(() => {
                                this.isExtendedViewContentShown = true;
                            }, 0);
                            this.dataService.loadDataStore();
                        }
                        return true;
                    })
                    .then(finishFunc)
                    .catch((e) => {
                        this.probenahmestelleInCache = new FischinfoProbenahmestelle(JSON.parse(JSON.stringify(validProbenahmestelleInCache)));
                        this.warningTarget = null;
                        this.warningText = "Die bearbeitete Probenahmestelle konnte nicht erfolgreich gespeichert werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen ein erneutes Speichern.";
                        this.warningIsShown = "true";
                        finishFunc();
                    });
            }
        }
        else {
            this.warningTarget = this.warningStack[0].target;
            this.warningText = this.warningStack[0].message;
            this.warningIsShown = "true";
        }
    }
    public validateOnSubmitTitel() {
        let warnText = "Bitte geben Sie ...";
        this.warningStack.forEach((element, index) => {
            if (warnText === element.message) {
                this.warningStack.splice(index, 1);
            }
        });
        if (!this.probenahmestelle.gewaesseralias
            || this.probenahmestelle.gewaesseralias.length < 2) {
            this.warningStack.push({ message: warnText, target: "submit" });
            this.warningText = warnText;
            this.warningIsShown = "true";
        }
    }
    public onClickDelete(e) {
        if (!this.modal.data.saving) {
            const finishFunc = () => {
                this.modal.data.saving = 0;
                this.formFischinfoProbenahmestelleService.editMode = false;
                this.isExtendedViewContentShown = true;
            };
            this.isExtendedViewContentShown = false;
            this.mode = "Edit";
            this.modal.data.saving = 1;
            this.dataService.delete("probenahmestelle", String(this.probenahmestelle.id))
                .then((response) => {
                    finishFunc();
                    this.dataService.loadDataStore();
                    this.modalService.closeModals();
                })
                .catch(finishFunc);
        }
    }
    public onClickGeometryEdit(e) {
        this.probenahmestelle["modalId"] = this.modal.id;
        this.mapEditorService.modifyProbenahmestelle
            .emit(this.probenahmestelle);
    }
    public addModel(event: Event, type: string): void {
        let data = this.modalService.getModalOption(this.modal.id, "data") || {};
        data.untersuchungToBeLoaded = null;
        data.untersuchung = null;
        data.activeTab = "untersuchung";
        this.modalService.setModalOption(this.modal.id, "data", data);
        setTimeout(() => {
            let untersuchung: FischinfoUntersuchung = new FischinfoUntersuchung({
                id: 0,
                unsicher: true,
                freigegeben: false,
                fibsgeeignet: false,
                probenahmestelle_id: this.probenahmestelle.id,
                untersuchungstermin: null,
                untersuchungbenutzer_id: parseInt(this.userService.id, 10)
            });
            this.formFischinfoUntersuchungService
                .modelUtil.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung")
                .erfasst = false;
            this.formFischinfoUntersuchungService
                .modelUtil.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung")
                .wrrlmonitoring = false;
            this.formFischinfoUntersuchungService
                .modelUtil.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung")
                .ffhmonitoring = false;
            this.formFischinfoUntersuchungService
                .modelUtil.setOrAdd<FischinfoBefischung>(untersuchung, "FischinfoBefischung")
                .beobachtung = type === "Beobachtung"
                    ? true
                    : false;
            data = this.modalService.getModalOption(this.modal.id, "data") || {};
            data.loaded = 1;
            data.untersuchungToBeLoaded = null;
            data.untersuchung = untersuchung;
            data.activeTab = "untersuchung";
            data.mode = "Create";
            this.modalService.setModalOption(this.modal.id, "data", data);
        }, 0);
    }
    public get isExtendedView(): boolean {
        return this.userService.isLoggedIn === true;
    }
}
