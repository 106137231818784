import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoMuscheluntersuchung from "./interface";
import FischinfoAnzahlmuschelergebnis from "./../../fischinfo/anzahlmuschelergebnis/model";
import FischinfoDetailmuschelergebnis from "./../../fischinfo/detailmuschelergebnis/model";
import FischinfoLaengenklassenmuschelergebnis from "./../../fischinfo/laengenklassenmuschelergebnis/model";
import FischinfoNachweismuschelergebnis from "./../../fischinfo/nachweismuschelergebnis/model";
import FischinfoHaeufigkeitsmuschelergebnis from "./../../fischinfo/haeufigkeitsmuschelergebnis/model";
import FischinfoUntersuchung from "./../../fischinfo/untersuchung/model";
@fn.DataModel
export default class FischinfoMuscheluntersuchung extends DataModel implements IFischinfoMuscheluntersuchung {
    constructor(obj?: object | IFischinfoMuscheluntersuchung) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoMuscheluntersuchung): FischinfoMuscheluntersuchung {
        return new FischinfoMuscheluntersuchung(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoMuscheluntersuchung): FischinfoMuscheluntersuchung {
        Object.assign(this, obj);
        obj && obj["fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id"] && obj["fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id"].length && (this.fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id = obj["fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id"].map(FischinfoAnzahlmuschelergebnis.deserialize));
        obj && obj["fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id"] && obj["fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id"].length && (this.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id = obj["fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id"].map(FischinfoDetailmuschelergebnis.deserialize));
        obj && obj["fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id"] && obj["fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id"].length && (this.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id = obj["fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id"].map(FischinfoLaengenklassenmuschelergebnis.deserialize));
        obj && obj["fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id"] && obj["fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id"].length && (this.fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id = obj["fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id"].map(FischinfoNachweismuschelergebnis.deserialize));
        obj && obj["fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id"] && obj["fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id"].length && (this.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id = obj["fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id"].map(FischinfoHaeufigkeitsmuschelergebnis.deserialize));
        obj && obj["fischinfoUntersuchungByUntersuchung_id"] && (this.fischinfoUntersuchungByUntersuchung_id = FischinfoUntersuchung.deserialize(obj["fischinfoUntersuchungByUntersuchung_id"]));
        return this;
    }

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "referenznr", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public referenznr?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "untersuchung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public untersuchung_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoAnzahlmuschelergebnis>}
     */
    @fn.DataMember({ name: "fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id", type: [FischinfoAnzahlmuschelergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id?: Array<FischinfoAnzahlmuschelergebnis>;

    /**
     * @type {Array<FischinfoDetailmuschelergebnis>}
     */
    @fn.DataMember({ name: "fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id", type: [FischinfoDetailmuschelergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id?: Array<FischinfoDetailmuschelergebnis>;

    /**
     * @type {Array<FischinfoLaengenklassenmuschelergebnis>}
     */
    @fn.DataMember({ name: "fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id", type: [FischinfoLaengenklassenmuschelergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id?: Array<FischinfoLaengenklassenmuschelergebnis>;

    /**
     * @type {Array<FischinfoNachweismuschelergebnis>}
     */
    @fn.DataMember({ name: "fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id", type: [FischinfoNachweismuschelergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id?: Array<FischinfoNachweismuschelergebnis>;

    /**
     * @type {Array<FischinfoHaeufigkeitsmuschelergebnis>}
     */
    @fn.DataMember({ name: "fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id", type: [FischinfoHaeufigkeitsmuschelergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id?: Array<FischinfoHaeufigkeitsmuschelergebnis>;

    /**
     * @type {FischinfoUntersuchung}
     */
    @fn.DataMember({ name: "fischinfoUntersuchungByUntersuchung_id", type: FischinfoUntersuchung, nullable: true, memberType: "JOIN", joinProperty: "untersuchung_id" })
    public fischinfoUntersuchungByUntersuchung_id?: FischinfoUntersuchung;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
