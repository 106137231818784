import UWAbstractFileService from "./abstract";

export default class UWTemplateFileService<TViewModel> extends UWAbstractFileService<TViewModel> {
    constructor(
        protected mimeType: string,
        private _transformModelToBlobSource: UWAbstractFileService<TViewModel>["transformModelToBlobSource"],
    ) {
        super();
    }
    protected transformModelToBlobSource(model: TViewModel) {
        return this._transformModelToBlobSource(model);
    }
}