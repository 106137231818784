import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoBesatzereignis from "./interface";
import FischinfoBesatzort from "./../../fischinfo/besatzort/model";
import FischinfoHerkunftfluss from "./../../fischinfo/herkunftfluss/model";
import FischinfoHerkunftstamm from "./../../fischinfo/herkunftstamm/model";
import FischinfoBesatzereignisgewaesserstruktur from "./../../fischinfo/besatzereignisgewaesserstruktur/model";
import FischinfoMarkierungfarbe from "./../../fischinfo/markierungfarbe/model";
import FischinfoMarkierungmethode from "./../../fischinfo/markierungmethode/model";
import FischinfoMarkierungpositioncwt from "./../../fischinfo/markierungpositioncwt/model";
import FischinfoMarkierungpositionfarbe from "./../../fischinfo/markierungpositionfarbe/model";
import FischinfoMarkierungpositionflossenschnitt from "./../../fischinfo/markierungpositionflossenschnitt/model";
import FischinfoMarkierungpositionvit from "./../../fischinfo/markierungpositionvit/model";
import FischinfoQualitaetelterntyp from "./../../fischinfo/qualitaetelterntyp/model";
import FischinfoFischart from "./../../fischinfo/fischart/model";
import FischinfoGesundheitsuntersuchungfgd from "./../../fischinfo/gesundheitsuntersuchungfgd/model";
import FischinfoWasserstand from "./../../fischinfo/wasserstand/model";
@fn.DataModel
export default class FischinfoBesatzereignis extends DataModel implements IFischinfoBesatzereignis {
    constructor(obj?: object | IFischinfoBesatzereignis) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoBesatzereignis): FischinfoBesatzereignis {
        return new FischinfoBesatzereignis(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoBesatzereignis): FischinfoBesatzereignis {
        Object.assign(this, obj);
        obj && obj["fischinfoBesatzortByBesatzort_id"] && (this.fischinfoBesatzortByBesatzort_id = FischinfoBesatzort.deserialize(obj["fischinfoBesatzortByBesatzort_id"]));
        obj && obj["fischinfoHerkunftflussByHerkunftfluss_id"] && (this.fischinfoHerkunftflussByHerkunftfluss_id = FischinfoHerkunftfluss.deserialize(obj["fischinfoHerkunftflussByHerkunftfluss_id"]));
        obj && obj["fischinfoHerkunftstammByHerkunftstamm_id"] && (this.fischinfoHerkunftstammByHerkunftstamm_id = FischinfoHerkunftstamm.deserialize(obj["fischinfoHerkunftstammByHerkunftstamm_id"]));
        obj && obj["fischinfoBesatzereignisgewaesserstrukturByForeignBesatzereignis_id"] && (this.fischinfoBesatzereignisgewaesserstrukturByForeignBesatzereignis_id = FischinfoBesatzereignisgewaesserstruktur.deserialize(obj["fischinfoBesatzereignisgewaesserstrukturByForeignBesatzereignis_id"]));
        obj && obj["fischinfoMarkierungfarbeByMarkierungfarbe_id"] && (this.fischinfoMarkierungfarbeByMarkierungfarbe_id = FischinfoMarkierungfarbe.deserialize(obj["fischinfoMarkierungfarbeByMarkierungfarbe_id"]));
        obj && obj["fischinfoMarkierungmethodeByMarkierungmethode_id"] && (this.fischinfoMarkierungmethodeByMarkierungmethode_id = FischinfoMarkierungmethode.deserialize(obj["fischinfoMarkierungmethodeByMarkierungmethode_id"]));
        obj && obj["fischinfoMarkierungpositioncwtByPositioncwt_id"] && (this.fischinfoMarkierungpositioncwtByPositioncwt_id = FischinfoMarkierungpositioncwt.deserialize(obj["fischinfoMarkierungpositioncwtByPositioncwt_id"]));
        obj && obj["fischinfoMarkierungpositionfarbeByPositionfarbe_id"] && (this.fischinfoMarkierungpositionfarbeByPositionfarbe_id = FischinfoMarkierungpositionfarbe.deserialize(obj["fischinfoMarkierungpositionfarbeByPositionfarbe_id"]));
        obj && obj["fischinfoMarkierungpositionflossenschnittByPositionflossenschnitt_id"] && (this.fischinfoMarkierungpositionflossenschnittByPositionflossenschnitt_id = FischinfoMarkierungpositionflossenschnitt.deserialize(obj["fischinfoMarkierungpositionflossenschnittByPositionflossenschnitt_id"]));
        obj && obj["fischinfoMarkierungpositionvitByPositionvit_id"] && (this.fischinfoMarkierungpositionvitByPositionvit_id = FischinfoMarkierungpositionvit.deserialize(obj["fischinfoMarkierungpositionvitByPositionvit_id"]));
        obj && obj["fischinfoHerkunftflussByQualitaetelternfluss_id"] && (this.fischinfoHerkunftflussByQualitaetelternfluss_id = FischinfoHerkunftfluss.deserialize(obj["fischinfoHerkunftflussByQualitaetelternfluss_id"]));
        obj && obj["fischinfoQualitaetelterntypByQualitaetelterntyp_id"] && (this.fischinfoQualitaetelterntypByQualitaetelterntyp_id = FischinfoQualitaetelterntyp.deserialize(obj["fischinfoQualitaetelterntypByQualitaetelterntyp_id"]));
        obj && obj["fischinfoFischartBySpezies_id"] && (this.fischinfoFischartBySpezies_id = FischinfoFischart.deserialize(obj["fischinfoFischartBySpezies_id"]));
        obj && obj["fischinfoGesundheitsuntersuchungfgdByUntersuchungfgd_id"] && (this.fischinfoGesundheitsuntersuchungfgdByUntersuchungfgd_id = FischinfoGesundheitsuntersuchungfgd.deserialize(obj["fischinfoGesundheitsuntersuchungfgdByUntersuchungfgd_id"]));
        obj && obj["fischinfoWasserstandByWasserstand_id"] && (this.fischinfoWasserstandByWasserstand_id = FischinfoWasserstand.deserialize(obj["fischinfoWasserstandByWasserstand_id"]));
        return this;
    }

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "abgeschlossen", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public abgeschlossen?: boolean;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "abwandermoeglichkeit", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public abwandermoeglichkeit?: boolean;

    /**
     * @type {numner} smallint
     */
    @fn.DataMember({ name: "abwandermoeglichkeit_abweichend", type: Number, databaseType: "smallint", nullable: true, memberType: "" })
    public abwandermoeglichkeit_abweichend?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "acrassusprozent", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public acrassusprozent?: number;

    /**
     * @type {numner} smallint
     */
    @fn.DataMember({ name: "angebunden", type: Number, databaseType: "smallint", nullable: true, memberType: "" })
    public angebunden?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "aquakultur", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public aquakultur?: boolean;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "ausfuehrender", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public ausfuehrender?: string;

    /**
     * @type {string} character varying 2000
     */
    @fn.DataMember({ name: "bemerkungbesatzereignis", type: String, databaseType: "character varying(2000)", nullable: true, memberType: "" })
    public bemerkungbesatzereignis?: string;

    /**
     * @type {string} character varying 2000
     */
    @fn.DataMember({ name: "bemerkungherkunft", type: String, databaseType: "character varying(2000)", nullable: true, memberType: "" })
    public bemerkungherkunft?: string;

    /**
     * @type {string} character varying 2000
     */
    @fn.DataMember({ name: "bemerkungmarkierung", type: String, databaseType: "character varying(2000)", nullable: true, memberType: "" })
    public bemerkungmarkierung?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "benutzer_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public benutzer_id?: number;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "besatzdatum", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public besatzdatum?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "besatzflaeche", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public besatzflaeche?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "besatzinstitution", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public besatzinstitution?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "besatzort_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public besatzort_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "besatzstadium_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public besatzstadium_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezugsquelleinfo", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezugsquelleinfo?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "brutaufzuchtanstaltinfo", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public brutaufzuchtanstaltinfo?: string;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "datumstichprobe", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public datumstichprobe?: number;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "datumuntersuchung", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public datumuntersuchung?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "entwicklungsstadium_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public entwicklungsstadium_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "ereignisnr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public ereignisnr?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "foerderkulisseaalbesatz", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public foerderkulisseaalbesatz?: string;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "freigegeben", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public freigegeben?: boolean;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "gesamtgewicht", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public gesamtgewicht?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "gesundheitsuntersuchung", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public gesundheitsuntersuchung?: boolean;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "gewaesserbreite", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public gewaesserbreite?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gewaesserstruktur_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public gewaesserstruktur_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "herkunftfluss_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public herkunftfluss_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "herkunftstamm_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public herkunftstamm_id?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "hvaprozent", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public hvaprozent?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 9
     * ID aus ISWAN (nur Altdaten)
     */
    @fn.DataMember({ name: "iswan_id", type: String, databaseType: "character varying(9)", nullable: true, memberType: "" })
    public iswan_id?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "iswanid", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public iswanid?: string;

    /**
     * @type {string} text
     */
    @fn.DataMember({ name: "lagebeschreibung", type: String, databaseType: "text", nullable: true, memberType: "" })
    public lagebeschreibung?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "larvenaltertage", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public larvenaltertage?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "markiert", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public markiert?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "markierungfarbe_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public markierungfarbe_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "markierunginstitution", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public markierunginstitution?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "markierungmethode_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public markierungmethode_id?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "maximalelaenge", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public maximalelaenge?: number;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "meldungdatum", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public meldungdatum?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "minimalelaenge", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public minimalelaenge?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "mittlerelaenge", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public mittlerelaenge?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "positioncwt_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public positioncwt_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "positionfarbe_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public positionfarbe_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "positionflossenschnitt_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public positionflossenschnitt_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "positionvit_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public positionvit_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "qualitaetelternfluss_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public qualitaetelternfluss_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "qualitaetelterntyp_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public qualitaetelterntyp_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "registriernummeraalfischereibetrieb", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public registriernummeraalfischereibetrieb?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "spezies_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public spezies_id?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "stichprobefgd", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public stichprobefgd?: boolean;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "stillgewaesser", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public stillgewaesser?: boolean;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "streckenbesatz", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public streckenbesatz?: boolean;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "streckenlaenge", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public streckenlaenge?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "stueckgewicht", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public stueckgewicht?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "stueckzahl", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public stueckzahl?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "stueckzahlmarkiert", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public stueckzahlmarkiert?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "textcodebis", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public textcodebis?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "textcodevon", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public textcodevon?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "untersuchungdurch", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public untersuchungdurch?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "untersuchungfgd_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public untersuchungfgd_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "untersuchungwas", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public untersuchungwas?: string;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "wasserstand_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public wasserstand_id?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "wassertemperatur", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public wassertemperatur?: number;

    /**
     * @type {FischinfoBesatzort}
     */
    @fn.DataMember({ name: "fischinfoBesatzortByBesatzort_id", type: FischinfoBesatzort, nullable: true, memberType: "JOIN", joinProperty: "besatzort_id" })
    public fischinfoBesatzortByBesatzort_id?: FischinfoBesatzort;

    /**
     * @type {FischinfoHerkunftfluss}
     */
    @fn.DataMember({ name: "fischinfoHerkunftflussByHerkunftfluss_id", type: FischinfoHerkunftfluss, nullable: true, memberType: "JOIN", joinProperty: "herkunftfluss_id" })
    public fischinfoHerkunftflussByHerkunftfluss_id?: FischinfoHerkunftfluss;

    /**
     * @type {FischinfoHerkunftstamm}
     */
    @fn.DataMember({ name: "fischinfoHerkunftstammByHerkunftstamm_id", type: FischinfoHerkunftstamm, nullable: true, memberType: "JOIN", joinProperty: "herkunftstamm_id" })
    public fischinfoHerkunftstammByHerkunftstamm_id?: FischinfoHerkunftstamm;

    /**
     * @type {FischinfoBesatzereignisgewaesserstruktur}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisgewaesserstrukturByForeignBesatzereignis_id", type: FischinfoBesatzereignisgewaesserstruktur, nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzereignisgewaesserstrukturByForeignBesatzereignis_id?: FischinfoBesatzereignisgewaesserstruktur;

    /**
     * @type {FischinfoMarkierungfarbe}
     */
    @fn.DataMember({ name: "fischinfoMarkierungfarbeByMarkierungfarbe_id", type: FischinfoMarkierungfarbe, nullable: true, memberType: "JOIN", joinProperty: "markierungfarbe_id" })
    public fischinfoMarkierungfarbeByMarkierungfarbe_id?: FischinfoMarkierungfarbe;

    /**
     * @type {FischinfoMarkierungmethode}
     */
    @fn.DataMember({ name: "fischinfoMarkierungmethodeByMarkierungmethode_id", type: FischinfoMarkierungmethode, nullable: true, memberType: "JOIN", joinProperty: "markierungmethode_id" })
    public fischinfoMarkierungmethodeByMarkierungmethode_id?: FischinfoMarkierungmethode;

    /**
     * @type {FischinfoMarkierungpositioncwt}
     */
    @fn.DataMember({ name: "fischinfoMarkierungpositioncwtByPositioncwt_id", type: FischinfoMarkierungpositioncwt, nullable: true, memberType: "JOIN", joinProperty: "positioncwt_id" })
    public fischinfoMarkierungpositioncwtByPositioncwt_id?: FischinfoMarkierungpositioncwt;

    /**
     * @type {FischinfoMarkierungpositionfarbe}
     */
    @fn.DataMember({ name: "fischinfoMarkierungpositionfarbeByPositionfarbe_id", type: FischinfoMarkierungpositionfarbe, nullable: true, memberType: "JOIN", joinProperty: "positionfarbe_id" })
    public fischinfoMarkierungpositionfarbeByPositionfarbe_id?: FischinfoMarkierungpositionfarbe;

    /**
     * @type {FischinfoMarkierungpositionflossenschnitt}
     */
    @fn.DataMember({ name: "fischinfoMarkierungpositionflossenschnittByPositionflossenschnitt_id", type: FischinfoMarkierungpositionflossenschnitt, nullable: true, memberType: "JOIN", joinProperty: "positionflossenschnitt_id" })
    public fischinfoMarkierungpositionflossenschnittByPositionflossenschnitt_id?: FischinfoMarkierungpositionflossenschnitt;

    /**
     * @type {FischinfoMarkierungpositionvit}
     */
    @fn.DataMember({ name: "fischinfoMarkierungpositionvitByPositionvit_id", type: FischinfoMarkierungpositionvit, nullable: true, memberType: "JOIN", joinProperty: "positionvit_id" })
    public fischinfoMarkierungpositionvitByPositionvit_id?: FischinfoMarkierungpositionvit;

    /**
     * @type {FischinfoHerkunftfluss}
     */
    @fn.DataMember({ name: "fischinfoHerkunftflussByQualitaetelternfluss_id", type: FischinfoHerkunftfluss, nullable: true, memberType: "JOIN", joinProperty: "qualitaetelternfluss_id" })
    public fischinfoHerkunftflussByQualitaetelternfluss_id?: FischinfoHerkunftfluss;

    /**
     * @type {FischinfoQualitaetelterntyp}
     */
    @fn.DataMember({ name: "fischinfoQualitaetelterntypByQualitaetelterntyp_id", type: FischinfoQualitaetelterntyp, nullable: true, memberType: "JOIN", joinProperty: "qualitaetelterntyp_id" })
    public fischinfoQualitaetelterntypByQualitaetelterntyp_id?: FischinfoQualitaetelterntyp;

    /**
     * @type {FischinfoFischart}
     */
    @fn.DataMember({ name: "fischinfoFischartBySpezies_id", type: FischinfoFischart, nullable: true, memberType: "JOIN", joinProperty: "spezies_id" })
    public fischinfoFischartBySpezies_id?: FischinfoFischart;

    /**
     * @type {FischinfoGesundheitsuntersuchungfgd}
     */
    @fn.DataMember({ name: "fischinfoGesundheitsuntersuchungfgdByUntersuchungfgd_id", type: FischinfoGesundheitsuntersuchungfgd, nullable: true, memberType: "JOIN", joinProperty: "untersuchungfgd_id" })
    public fischinfoGesundheitsuntersuchungfgdByUntersuchungfgd_id?: FischinfoGesundheitsuntersuchungfgd;

    /**
     * @type {FischinfoWasserstand}
     */
    @fn.DataMember({ name: "fischinfoWasserstandByWasserstand_id", type: FischinfoWasserstand, nullable: true, memberType: "JOIN", joinProperty: "wasserstand_id" })
    public fischinfoWasserstandByWasserstand_id?: FischinfoWasserstand;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
