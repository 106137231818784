import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoBenutzerbesatzorte from "./interface";
import FischinfoBesatzort from "./../../fischinfo/besatzort/model";
@fn.DataModel
export default class FischinfoBenutzerbesatzorte extends DataModel implements IFischinfoBenutzerbesatzorte {
    constructor(obj?: object | IFischinfoBenutzerbesatzorte) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoBenutzerbesatzorte): FischinfoBenutzerbesatzorte {
        return new FischinfoBenutzerbesatzorte(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoBenutzerbesatzorte): FischinfoBenutzerbesatzorte {
        Object.assign(this, obj);
        obj && obj["fischinfoBesatzortByBesatzort_id"] && (this.fischinfoBesatzortByBesatzort_id = FischinfoBesatzort.deserialize(obj["fischinfoBesatzortByBesatzort_id"]));
        return this;
    }

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "benutzer_id", type: Number, databaseType: "integer", nullable: false, memberType: "UNIQUE" })
    public benutzer_id: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "besatzort_id", type: Number, databaseType: "integer", nullable: false, memberType: "UNIQUE" })
    public besatzort_id: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {FischinfoBesatzort}
     */
    @fn.DataMember({ name: "fischinfoBesatzortByBesatzort_id", type: FischinfoBesatzort, nullable: true, memberType: "JOIN", joinProperty: "besatzort_id" })
    public fischinfoBesatzortByBesatzort_id?: FischinfoBesatzort;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
