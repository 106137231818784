import { Component, ElementRef, Inject, SimpleChange } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import ModalService from "./../modal/service";
import AppService from "./../app/service";
import { UWModal } from "ng-uw-modal";
@Component({
    selector: "uw-modal",
    templateUrl: "base.component.html",
    styleUrls: [
        "base.component.less",
        "./default.component.less"
    ]
})
export default class Modal extends UWModal {
    constructor(
        @Inject(DOCUMENT)
        public document: any, public elementRef: ElementRef, public modalService: ModalService, public appService: AppService) {
        super(document, elementRef, modalService);
    }
    public get parentElement() {
        return this.elementRef.nativeElement.parentElement.parentElement;
    }
    public offset(e): {
        left: number;
        top: number;
    } {
        var offset = { left: 0, top: 0 };
        while (e) {
            offset.left += e.offsetLeft;
            offset.top += e.offsetTop;
            e = e.offsetParent;
        }
        if (document.documentElement && (document.documentElement.scrollTop || document.documentElement.scrollLeft)) {
            offset.left -= document.documentElement.scrollLeft;
            offset.top -= document.documentElement.scrollTop;
        }
        else if (document.body && (document.body.scrollTop || document.body.scrollLeft)) {
            offset.left -= document.body.scrollLeft;
            offset.top -= document.body.scrollTop;
        }
        else if (window.pageXOffset || window.pageYOffset) {
            offset.left -= window.pageXOffset;
            offset.top -= window.pageYOffset;
        }
        return offset;
    }
    public ngOnInit(): void {
        // convert potential string inputs from the DOM
        this.height =
            this.modalService.previousActiveModalDimensions.height ||
            Number(this.height);
        this.width =
            this.modalService.previousActiveModalDimensions.width ||
            Number(this.width);
        this.left =
            this.modalService.previousActiveModalPosition.left
            || Number(this.left);
        this.top =
            this.modalService.previousActiveModalPosition.top
            || Number(this.top);
        this.zIndex = parseInt(this.zIndex.toString(), 10);
        // ensure that the modal is visible within the parent first moving then resizing it if necessary
        let { height, width, top, left } = this;
        let { innerWidth: windowWidth, innerHeight: windowHeight } = window;
        let { top: parentTop, left: parentLeft } = this.offset(this.parentElement);
        // oben
        if ((this.appService.isFullscreenMode
            || this.appService.isIFrame)
            &&
            (!this.modalService.previousActiveModalDimensions.height
                || parentTop + top < 10)) {
            this.top = top = 10 - parentTop;
        }
        // rechts
        this.width = width = Math.min(width, windowWidth);
        if ((this.appService.isFullscreenMode
            || this.appService.isIFrame)
            && windowWidth < (parentLeft + left + width)) {
            this.left = left = windowWidth - width - parentLeft;
        }
        // unten
        this.height = height = Math.min(height, windowHeight);
        if ((this.appService.isFullscreenMode
            || this.appService.isIFrame)
            && windowHeight < (parentTop + top + height)) {
            this.top = top = windowHeight - height - parentTop;
        }
        this.resize.emit(new SimpleChange({ width: 0, height: 0 }, { width: this.width, height: this.height }, true));
        this.move.emit(new SimpleChange({ left: 0, top: 0 }, { left: this.left, top: this.top }, true));
    }
    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
