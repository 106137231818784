import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import DataService from "../data/service";
import FischinfoMethodegeraetetyp from "../../models/fischinfo/methodegeraetetyp/model";
import FischinfoGeraetetyp from "../../models/fischinfo/geraetetyp/model";
import FischinfoStromart from "../../models/fischinfo/stromart/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoMethode from "../../models/fischinfo/methode/model";
/**
 * Mit dieser Komponente kann der Nutzer die Informationen zu einem eingesetzten E-Befischungsgerät ansehen und ggf. bearbeiten.
 */
@Component({
    selector: "[form-fischinfo-untersuchung-befischung-methode-egeraet-geraetetyp]",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungMethodeEgeraetGeraetetyp implements OnInit {
    @Input()
    public modalId: string;
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public methodegeraetetyp: FischinfoMethodegeraetetyp;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get geraetetyp_id(): number {
        return this.methodegeraetetyp
            && this.methodegeraetetyp.geraetetyp_id;
    }
    public set geraetetyp_id(value: number) {
        this.methodegeraetetyp
            && (this.methodegeraetetyp.geraetetyp_id = parseInt(String(value), 10) || null);
    }
    public get stromart_id(): number {
        return this.methodegeraetetyp
            && this.methodegeraetetyp.stromart_id;
    }
    public set stromart_id(value: number) {
        this.methodegeraetetyp
            && (this.methodegeraetetyp.stromart_id = parseInt(String(value), 10) || null);
    }
    public get anzahl(): number {
        return this.methodegeraetetyp
            && this.methodegeraetetyp.anzahl;
    }
    public set anzahl(value: number) {
        this.methodegeraetetyp
            && (this.methodegeraetetyp.anzahl = parseInt(String(value), 10) || null);
    }
    public get abgelesen(): boolean {
        return this.methodegeraetetyp
            && this.methodegeraetetyp.abgelesen;
    }
    public set abgelesen(value: boolean) {
        if (value) {
            (this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
                .fischinfoMethodegeraetetypListByForeignMethode_id || []).forEach(u => {
                u.abgelesen = false;
            });
        }
        this.methodegeraetetyp
            && (this.methodegeraetetyp.abgelesen = (value as any === "") ? null : value);
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    public onClickRemoveMethodegeraetetyp(e: Event) {
        this.formFischinfoUntersuchungService.modelUtil
            .remove<FischinfoMethodegeraetetyp>(this.untersuchung, "FischinfoMethodegeraetetyp", this.methodegeraetetyp);
        this.changed.emit(true);
    }
    public geraetetyp_idRefJsonItems: string;
    public stromart_idRefJsonItems: string;
    ngOnInit() {
        const a = this.dataService
            .getReferenceTable("geraetetyp", FischinfoGeraetetyp, "id");
        const b = this.dataService
            .getReferenceTable("stromart", FischinfoStromart, "id");
        return Promise.all<{
            [key: string]: FischinfoGeraetetyp | FischinfoStromart;
        }>([a, b])
            .then(refs => {
            [0, 1].map(x => {
                let refList = [], entityKey;
                Object.keys(refs[x]).forEach(key => {
                    entityKey = key;
                    refList.push('[' + refs[x][key].id + ',"' + refs[x][key].bezeichnung + '"]');
                });
                refs[x] && refs[x][entityKey] instanceof FischinfoGeraetetyp
                    && (this.geraetetyp_idRefJsonItems = "[" + refList.join(",") + "]");
                refs[x] && refs[x][entityKey] instanceof FischinfoStromart
                    && (this.stromart_idRefJsonItems = "[" + refList.join(",") + "]");
            });
        });
    }
}
