<div
    class="row"
    *ngIf="strukturelementehaeufigkeitList?.length > 0"
>
    <div class="col-xs-15">
        <label>Fischrelevante Strukturelemente</label>
    </div>
    <div class="col-xs-15">
        <table class="table table-striped strukturelementehaeufigkeitList inherit-inputs">
            <thead>
                <tr>
                    <th [style.width.%]="60"  title="Bezeichnung">Bezeichnung</th>
                    <th></th>
                    <th [style.width.px]="140" title="Häufigkeit">Häufigkeit</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let u of strukturelementehaeufigkeitList"
                >
                    <td [title]="u.bezeichnung">{{u.bezeichnung}}</td>
                    <td></td>
                    <td style="padding:0;"><uw-select
                        *ngIf="haeufigkeit_idRefJsonItems"
                        [jsonItems]="haeufigkeit_idRefJsonItems"
                        keyValueMapping="0:1"
                        [disabled]="readonly"
                        size="10"
                        [(key)]="u.haeufigkeit_id"
                        (keyChange)="onChangeStrukturelementehaeufigkeit($event, u)"
                    ></uw-select></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div
    class="row"
    *ngIf="!strukturelementehaeufigkeitList?.length"
>
    <div class="col-xs-15"><uw-textinput
        label="Fischrelevante Strukturelemente"
        value=""
        disabled="true"
    ></uw-textinput></div>
</div>
