import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IIntragisBenutzerprobenahmestelle from "./interface";

const toDateValue = (val?: number | string | undefined) => {
    if (val == null) {
        const dt = Date.now();
        return dt - (dt % 86400000);
    }
    else {
        return typeof val === "number" ? val
            : Date.parse(val.toString().split("T")[0])
            ;
    }
};

@fn.DataModel
export default class IntragisBenutzerprobenahmestelle extends DataModel implements IIntragisBenutzerprobenahmestelle {
    constructor(obj?: object | IIntragisBenutzerprobenahmestelle) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IIntragisBenutzerprobenahmestelle): IntragisBenutzerprobenahmestelle {
        return new IntragisBenutzerprobenahmestelle(obj);
    }
    public deserialize<T extends object>(obj: T | IIntragisBenutzerprobenahmestelle): IntragisBenutzerprobenahmestelle {
        Object.assign(this, Object.assign(obj, {
            gueltigbis: toDateValue(obj["gueltigbis"]),
            gueltigvon: toDateValue(obj["gueltigvon"]),
        }));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anlass", type: String, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public anlass?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "benutzer_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public benutzer_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "benutzer_name", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public benutzer_name?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "untersuchungstermin", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public gueltigbis?: number;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "untersuchungstermin", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public gueltigvon?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "probestellennr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public probestellennr?: string;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
