import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoBezirk from "./interface";
import FischinfoGemeinde from "./../../fischinfo/gemeinde/model";
@fn.DataModel
export default class FischinfoBezirk extends DataModel implements IFischinfoBezirk {
    constructor(obj?: object | IFischinfoBezirk) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoBezirk): FischinfoBezirk {
        return new FischinfoBezirk(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoBezirk): FischinfoBezirk {
        Object.assign(this, obj);
        obj && obj["fischinfoGemeindeListByForeignBezirk_id"] && obj["fischinfoGemeindeListByForeignBezirk_id"].length && (this.fischinfoGemeindeListByForeignBezirk_id = obj["fischinfoGemeindeListByForeignBezirk_id"].map(FischinfoGemeinde.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezirkname", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezirkname?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gkz", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gkz?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoGemeinde>}
     */
    @fn.DataMember({ name: "fischinfoGemeindeListByForeignBezirk_id", type: [FischinfoGemeinde], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoGemeindeListByForeignBezirk_id?: Array<FischinfoGemeinde>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
