export default class DecoratorHelper {
    public static definePropertyMetadata(target: any, propertyKey: string | symbol, data: any) {
        if (typeof(target.modelProperties) === "undefined") {
            target.modelProperties = {};
        }
        if (typeof(target.modelProperties[propertyKey]) === "undefined") {
            target.modelProperties[propertyKey] = {};
        }
        Object.assign(target.modelProperties[propertyKey].class = target.constructor.name);
        Object.assign(target.modelProperties[propertyKey], data);
    }
    public static modelPropertyMetadata<T extends {new(...args:any[]):{}}>(constructor: T): any {
        return constructor.prototype.modelProperties;
    }
}