<div class="row">
    <div class="col-xs-15">
        <label>Angaben zur Gewässerstruktur</label>
    </div>
</div>

<form-fischinfo-untersuchung-gewaesser-struktur-strukturelementehaeufigkeit
    [probenahmestelle]="probenahmestelle"
    [untersuchung]="untersuchung"
    [modalId]="modalId"
    [readonly]="readonly"
></form-fischinfo-untersuchung-gewaesser-struktur-strukturelementehaeufigkeit>

<form-fischinfo-untersuchung-gewaesser-struktur-substratprozent
    [probenahmestelle]="probenahmestelle"
    [untersuchung]="untersuchung"
    [modalId]="modalId"
    [readonly]="readonly"
></form-fischinfo-untersuchung-gewaesser-struktur-substratprozent>

<form-fischinfo-untersuchung-gewaesser-struktur-ausbauzustand
    *ngIf="probenahmestelle?.gewaessertyp === 'FG'"
    [probenahmestelle]="probenahmestelle"
    [untersuchung]="untersuchung"
    [modalId]="modalId"
    [readonly]="readonly"
></form-fischinfo-untersuchung-gewaesser-struktur-ausbauzustand>

<form-fischinfo-untersuchung-gewaesser-struktur-zustanduferpflanze
    *ngIf="probenahmestelle?.gewaessertyp === 'FG'"
    [probenahmestelle]="probenahmestelle"
    [untersuchung]="untersuchung"
    [modalId]="modalId"
    [readonly]="readonly"
></form-fischinfo-untersuchung-gewaesser-struktur-zustanduferpflanze>

<form-fischinfo-untersuchung-gewaesser-struktur-zustandpflanzenart
    *ngIf="probenahmestelle?.gewaessertyp === 'SG'"
    [probenahmestelle]="probenahmestelle"
    [untersuchung]="untersuchung"
    [modalId]="modalId"
    [readonly]="readonly"
></form-fischinfo-untersuchung-gewaesser-struktur-zustandpflanzenart>

<div
    class="row"
    style="padding-top:10px"
>
    <div
        *ngIf="probenahmestelle?.gewaessertyp === 'FG'"
        class="col-xs-15 col-sm-5"
    ><uw-textinput
        [label]="formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','beschattung')"
        [(value)]="beschattung"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>