import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { firstValueFrom } from "rxjs";
import { pluck } from "rxjs/operators";

import SuggestionService from "./service.interface";
import SuggestionServiceOptions from "./options.interface";
import KeyValue from "./key-value.interface";

@Injectable()
export default class StaticSuggestionService implements SuggestionService {
    protected __limit: number = 10;
    protected __items: Promise<Array<KeyValue>> = Promise.resolve([]);
    protected __itemsResolve: (items: Promise<Array<KeyValue>>) => void;
    protected __matcherFor: (pattern: string) => (suggestion: KeyValue) => boolean = (pattern: string) => (suggestion: KeyValue) => true;
    protected __staticJsonFileUrl: string = "/";
    protected __itemsAccess: Array<string> = [".resultset"];

    constructor(
        protected http: HttpClient
    ) {
        this.__items = new Promise<Array<KeyValue>>(done => {
            this.__itemsResolve = done;
        });
    }

    public config(options: SuggestionServiceOptions): SuggestionService {
        this.__limit = options.limit === null ? this.__limit : options.limit;
        this.__staticJsonFileUrl = options.staticJsonFileUrl === null ? this.__staticJsonFileUrl : options.staticJsonFileUrl;
        this.__itemsAccess = options.itemsAccess === null ? this.__itemsAccess : options.itemsAccess;
        this.__matcherFor = options.matcherFactory === null ? this.__matcherFor : options.matcherFactory;
        const items = firstValueFrom(
            this.http.get(this.__staticJsonFileUrl)
                .pipe(pluck(...(this.__itemsAccess || [])))
        ) as Promise<KeyValue[]>;
        this.__itemsResolve(items);
        return <SuggestionService>this;
    }

    public search(pattern: { [name: string]: string }): Promise<any[]> {
        return this.__items.then(items => this._filterWithMatcher(items, pattern).slice(0, this.__limit));
    }

    protected _filterWithMatcher(items, pattern): Array<any> {
        if (this.__matcherFor) {
            return items.filter(this.__matcherFor(pattern["term"]));
        }
        else return items;
    }

}
