import { IUWWebWorkerService } from './web-worker.interface';

/**
 * Dieser Dienst steuert die Ausführung von rechenintensiven Prozessen im Hintergrund.
 * 
 * Dieser Dienst hilft der Anwendung, daten- oder rechenintensive Teile der Anwendung in eigene Hintergrundprozesse auszulagern. Dadurch kann die Anwendung sicherstellen, dass diese Prozesse sie nicht in Ihrer Reaktionsfähigkeit auf Nutzereingaben behindern.
 */
export class UWWebWorkerService implements IUWWebWorkerService {
    private workerFunctionToUrlMap = new WeakMap<Function, string>();
    private promiseToWorkerMap = new WeakMap<Promise<any>, Worker>();

    run<T>(workerFunction: (any) => T, data?: any): Promise<T> {
        if (Function('/*@cc_on return 10===document.documentMode@*/')()) {
            return typeof data === "undefined"
                ? this.runLocal(workerFunction)
                : this.runLocal(workerFunction, data);
        }
        else {
            const url = this.getOrCreateWorkerUrl(workerFunction);
            return this.runUrl(url, data);
        }
    }

    runLocal<T>(fn: (data?: any) => T, data?: any): Promise<T> {
        const evaler = new Function(
            "postMessage",
            "$data",
            "$ctx",
            "return ("
            + fn.toString()
            + ").call($ctx, $data)",
        );
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                try {
                    const ctx = { postMessage: resolve };
                    const result = evaler(resolve, data, ctx);
                    if (typeof result !== "undefined") resolve(result);
                }
                catch (e) {
                    reject(e);
                }
            }, 0);
        });
    }

    runUrl(url: string, data?: any): Promise<any> {
        const worker = new Worker(url);
        const promise = this.createPromiseForWorker(worker, data);
        const promiseCleaner = this.createPromiseCleaner(promise);

        this.promiseToWorkerMap.set(promise, worker);

        promise
            .then(promiseCleaner)
            .catch(promiseCleaner);

        return promise;
    }

    terminate<T>(promise: Promise<T>): Promise<T> {
        return this.removePromise(promise);
    }

    getWorker(promise: Promise<any>): Worker {
        return this.promiseToWorkerMap.get(promise);
    }

    private createPromiseForWorker<T>(worker: Worker, data: any) {
        return new Promise<T>((resolve, reject) => {
            worker.addEventListener('message', (event) => resolve(event.data));
            worker.addEventListener('error', reject);
            worker.postMessage(data);
        });
    }

    private getOrCreateWorkerUrl(fn: Function): string {
        if (!this.workerFunctionToUrlMap.has(fn)) {
            const url = this.createWorkerUrl(fn);
            this.workerFunctionToUrlMap.set(fn, url);
            return url;
        }
        return this.workerFunctionToUrlMap.get(fn);
    }

    private createWorkerUrl(resolve: Function): string {
        const resolveString = resolve.toString();
        const properResolveString = "function " + resolveString.slice(resolveString.indexOf("("));
        const webWorkerTemplate = `
            self.addEventListener('message', function(e) {
                postMessage((${properResolveString})(e.data));
            });
        `;
        const blob = new Blob([webWorkerTemplate], { type: 'text/javascript' });
        return URL.createObjectURL(blob);
    }

    private createPromiseCleaner<T>(promise): (T) => T {
        return (event) => {
            this.removePromise(promise);
            return event;
        };
    }

    private removePromise<T>(promise): Promise<T> {
        const worker = this.promiseToWorkerMap.get(promise);
        if (worker) {
            worker.terminate();
        }
        this.promiseToWorkerMap.delete(promise);
        return promise;
    }
}
