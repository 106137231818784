import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
let nextButtonId = 0;
/**
 * Diese Eingabekomponente stellt einen HTML-<button> dar.
 *
 * Wird auf die Komponente geklickt, führt sie alle Aktionen aus, die mit der Ausgabeeigenschaft `click` verbunden sind. Jeder Instanz der Komponente sind unter Anderem eine Bezeichnung, eine Beschriftung und ein Wert, für den die Komponente steht, zuweisbar.
 */
@Component({
    selector: "uw-button",
    templateUrl: "./default.component.html",
    styleUrls: [
        "./default.component.less"
    ]
})
export default class UWButton implements OnInit {
    @Input()
    public label: string = "";
    @Input()
    public title: string = "";
    @Input()
    public infoTag: string = "";
    @Input()
    public infoTagClass: string = "fa-info-circle";
    @Input()
    public name: string = "";
    @Input()
    public disabled: boolean = false;
    @Input()
    public value: any;
    @Input()
    public internalId: string;
    @Input()
    public set textAlign(value: string) {
        const allowed = { left: true, center: true, right: true };
        if (allowed[value]) {
            this.cssAlignment = "uw-align-" + value;
        }
        else
            throw RangeError("UWButton.align: allowed "
                + Object.keys(allowed).join(", ")
                + "; got: "
                + String(value));
    }
    public get textAlign() { return this.cssAlignment.substr(9); }
    @Output()
    public click: EventEmitter<UWButton> = new EventEmitter();
    public cssAlignment: string = "uw-align-left";
    public ngOnInit() {
        if (typeof this.disabled === "string") {
            this.disabled = Boolean(JSON.parse(this.disabled));
        }
        if (!this.internalId) {
            this.internalId = "uw-textinput-" + String(nextButtonId++);
        }
    }
    public clickOnButton(ev: Event) {
        ev.stopPropagation();
        this.click.emit(this);
    }
}
