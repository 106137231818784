<div class="row" style="padding-top:10px;">
    <div class="col-xs-15">
        <label *ngIf="!readonly">Geben Sie die Anzahl der Individuen und ggf. deren Gesamtgewicht ein</label>
        <label *ngIf="readonly">Anzahl der Individuen und ggf. deren Gesamtgewicht</label>
    </div>
</div>

<div class="row" style="padding-top:10px;">
    <div class="col-xs-15">
        <label>Anzahl</label>
    </div>
</div>
<div class="row">
    <div class="col-sm-3">
        <uw-textinput
            [(value)]="anzahl"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div class="col-sm-6">
        <label>Individuen</label>
    </div>
</div>

<div
    class="row"
    style="padding-top:10px;"
    *ngIf="artGruppe==='Fische'"
>
    <div class="col-xs-15">
        <label>Gesamtgewicht (optional)</label>
    </div>
</div>
<div
    class="row"
    *ngIf="artGruppe==='Fische'"
>
    <div class="col-sm-3">
        <uw-textinput
            [(value)]="gewicht"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div class="col-sm-6">
        <label>kg</label>
    </div>
</div>
