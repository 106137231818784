import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoGeraetetyp from "./interface";
import FischinfoMethodegeraetetyp from "./../../fischinfo/methodegeraetetyp/model";
@fn.DataModel
export default class FischinfoGeraetetyp extends DataModel implements IFischinfoGeraetetyp {
    constructor(obj?: object | IFischinfoGeraetetyp) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoGeraetetyp): FischinfoGeraetetyp {
        return new FischinfoGeraetetyp(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoGeraetetyp): FischinfoGeraetetyp {
        Object.assign(this, obj);
        obj && obj["fischinfoMethodegeraetetypListByForeignGeraetetyp_id"] && obj["fischinfoMethodegeraetetypListByForeignGeraetetyp_id"].length && (this.fischinfoMethodegeraetetypListByForeignGeraetetyp_id = obj["fischinfoMethodegeraetetypListByForeignGeraetetyp_id"].map(FischinfoMethodegeraetetyp.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoMethodegeraetetyp>}
     */
    @fn.DataMember({ name: "fischinfoMethodegeraetetypListByForeignGeraetetyp_id", type: [FischinfoMethodegeraetetyp], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoMethodegeraetetypListByForeignGeraetetyp_id?: Array<FischinfoMethodegeraetetyp>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
