import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoUntersuchung from "./interface";
import FischinfoFoto from "./../../fischinfo/foto/model";
import FischinfoUntersuchunganlass from "./../../fischinfo/untersuchunganlass/model";
import FischinfoMuscheluntersuchung from "./../../fischinfo/muscheluntersuchung/model";
import FischinfoKrebsuntersuchung from "./../../fischinfo/krebsuntersuchung/model";
import FischinfoBefischung from "./../../fischinfo/befischung/model";
import FischinfoProbenahmestelle from "./../../fischinfo/probenahmestelle/model";
@fn.DataModel
export default class FischinfoUntersuchung extends DataModel implements IFischinfoUntersuchung {
    constructor(obj?: object | IFischinfoUntersuchung) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoUntersuchung): FischinfoUntersuchung {
        return new FischinfoUntersuchung(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoUntersuchung): FischinfoUntersuchung {
        if (obj.hasOwnProperty("d")) {
            Object.assign(this, {
                bearbeiter: obj["a"],
                fibsgeeignet: obj["b"],
                freigegeben: obj["c"],
                id: obj["d"],
                lfdnr: obj["e"],
                referenznr: obj["f"],
                schluesselwort: obj["g"],
                unsicher: obj["h"],
                untersuchungstermin: obj["i"],
                fischinfoBefischungListByForeignUntersuchung_id: obj["j"] && obj["j"].length
                    ? obj["j"].map(FischinfoBefischung.deserialize)
                    : null,
            });
        } else {
            Object.assign(this, obj);
            obj && obj["fischinfoFotoByFoto_id"] && (this.fischinfoFotoByFoto_id = FischinfoFoto.deserialize(obj["fischinfoFotoByFoto_id"]));
            obj && obj["fischinfoUntersuchunganlassListByForeignUntersuchung_id"] && obj["fischinfoUntersuchunganlassListByForeignUntersuchung_id"].length && (this.fischinfoUntersuchunganlassListByForeignUntersuchung_id = obj["fischinfoUntersuchunganlassListByForeignUntersuchung_id"].map(FischinfoUntersuchunganlass.deserialize));
            obj && obj["fischinfoMuscheluntersuchungListByForeignUntersuchung_id"] && obj["fischinfoMuscheluntersuchungListByForeignUntersuchung_id"].length && (this.fischinfoMuscheluntersuchungListByForeignUntersuchung_id = obj["fischinfoMuscheluntersuchungListByForeignUntersuchung_id"].map(FischinfoMuscheluntersuchung.deserialize));
            obj && obj["fischinfoKrebsuntersuchungListByForeignUntersuchung_id"] && obj["fischinfoKrebsuntersuchungListByForeignUntersuchung_id"].length && (this.fischinfoKrebsuntersuchungListByForeignUntersuchung_id = obj["fischinfoKrebsuntersuchungListByForeignUntersuchung_id"].map(FischinfoKrebsuntersuchung.deserialize));
            obj && obj["fischinfoBefischungListByForeignUntersuchung_id"] && obj["fischinfoBefischungListByForeignUntersuchung_id"].length && (this.fischinfoBefischungListByForeignUntersuchung_id = obj["fischinfoBefischungListByForeignUntersuchung_id"].map(FischinfoBefischung.deserialize));
            obj && obj["fischinfoProbenahmestelleByProbenahmestelle_id"] && (this.fischinfoProbenahmestelleByProbenahmestelle_id = FischinfoProbenahmestelle.deserialize(obj["fischinfoProbenahmestelleByProbenahmestelle_id"]));
        }
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bearbeiter", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    @fn.Label("Bearbeiter")
    public bearbeiter?: string;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "fibsgeeignet", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    @fn.Label("FIBS geeignet")
    public fibsgeeignet?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "foto_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public foto_id?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "freigegeben", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    @fn.Label("freigegeben")
    public freigegeben?: boolean;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "lfdnr", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public lfdnr?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "pinboard_id", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public pinboard_id?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "probenahmestelle_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public probenahmestelle_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "referenznr", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public referenznr?: number;

    /**
     * @type {string} character varying 500
     */
    @fn.DataMember({ name: "schluesselwort", type: String, databaseType: "character varying(500)", nullable: true, memberType: "" })
    @fn.Label("Schlüsselwort")
    public schluesselwort?: string;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "unsicher", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    @fn.Label("Unsichere Daten")
    public unsicher?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "untersuchungbenutzer_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public untersuchungbenutzer_id?: number;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "untersuchungstermin", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    @fn.Label("Datum")
    public untersuchungstermin?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoFoto}
     */
    @fn.DataMember({ name: "fischinfoFotoByFoto_id", type: FischinfoFoto, nullable: true, memberType: "JOIN", joinProperty: "foto_id" })
    public fischinfoFotoByFoto_id?: FischinfoFoto;

    /**
     * @type {Array<FischinfoUntersuchunganlass>}
     */
    @fn.DataMember({ name: "fischinfoUntersuchunganlassListByForeignUntersuchung_id", type: [FischinfoUntersuchunganlass], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoUntersuchunganlassListByForeignUntersuchung_id?: Array<FischinfoUntersuchunganlass>;

    /**
     * @type {Array<FischinfoMuscheluntersuchung>}
     */
    @fn.DataMember({ name: "fischinfoMuscheluntersuchungListByForeignUntersuchung_id", type: [FischinfoMuscheluntersuchung], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoMuscheluntersuchungListByForeignUntersuchung_id?: Array<FischinfoMuscheluntersuchung>;

    /**
     * @type {Array<FischinfoKrebsuntersuchung>}
     */
    @fn.DataMember({ name: "fischinfoKrebsuntersuchungListByForeignUntersuchung_id", type: [FischinfoKrebsuntersuchung], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoKrebsuntersuchungListByForeignUntersuchung_id?: Array<FischinfoKrebsuntersuchung>;

    /**
     * @type {Array<FischinfoBefischung>}
     */
    @fn.DataMember({ name: "fischinfoBefischungListByForeignUntersuchung_id", type: [FischinfoBefischung], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBefischungListByForeignUntersuchung_id?: Array<FischinfoBefischung>;

    /**
     * @type {FischinfoProbenahmestelle}
     */
    @fn.DataMember({ name: "fischinfoProbenahmestelleByProbenahmestelle_id", type: FischinfoProbenahmestelle, nullable: true, memberType: "JOIN", joinProperty: "probenahmestelle_id" })
    public fischinfoProbenahmestelleByProbenahmestelle_id?: FischinfoProbenahmestelle;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW


    public untersuchungsterminAsLocaleDateStringState?: [number, string] = [null, null];

    @fn.Table({ columnIndex: 5, align: "right", sortType: "number", sortBy: "datumSortNumber" })
    @fn.CSV({ type: "varchar(254)" })
    @fn.SHP({ type: "varchar(254)" })
    @fn.Label("Datum")
    public get untersuchungsterminAsLocaleDateString(): string {
        if (
            !this.untersuchungsterminAsLocaleDateStringState[0]
            || this.untersuchungsterminAsLocaleDateStringState[0] !== this.untersuchungstermin
        ) {
            this.untersuchungsterminAsLocaleDateStringState[0] = this.untersuchungstermin;
            const d = this.untersuchungstermin && new Date(this.untersuchungstermin);
            this.untersuchungsterminAsLocaleDateStringState[1] = (d && d.getUTCFullYear() !== 1970 && d.toLocaleDateString("de-DE", { year: 'numeric', month: '2-digit', day: '2-digit' })) || "";
        }
        return this.untersuchungsterminAsLocaleDateStringState[1];
    }
    public get datumSortNumber(): number {
        return this.untersuchungstermin;
    }

    public untersuchungsterminAsLocaleTimeStringState?: [number, string] = [null, null];
    @fn.Label("Uhrzeit")
    public get untersuchungsterminAsLocaleTimeString(): string {
        if (
            !this.untersuchungsterminAsLocaleTimeStringState[0]
            || this.untersuchungsterminAsLocaleTimeStringState[0] !== this.untersuchungstermin
        ) {
            this.untersuchungsterminAsLocaleTimeStringState[0] = this.untersuchungstermin;
            const d = this.untersuchungstermin && new Date(this.untersuchungstermin);
            this.untersuchungsterminAsLocaleTimeStringState[1] = (d && d.toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit' })) || "";
        }
        return this.untersuchungsterminAsLocaleTimeStringState[1];
    }

    @fn.Table({ columnIndex: 7, sortType: "string", columnPercentualWidth: 12 })
    @fn.CSV({ type: "varchar(254)" })
    @fn.SHP({ type: "varchar(254)" })
    @fn.Label("Untersuchung")
    public get untersuchung(): string {
        return this.fischinfoBefischungListByForeignUntersuchung_id
            && this.fischinfoBefischungListByForeignUntersuchung_id[0]
            && this.fischinfoBefischungListByForeignUntersuchung_id[0].beobachtung
            ? "Beobachtung"
            : "Befischung"
            ;
    }

    @fn.Label("Methode")
    public get methode(): string {
        return this.fischinfoBefischungListByForeignUntersuchung_id
            && this.fischinfoBefischungListByForeignUntersuchung_id[0]
            && this.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoMethodeByMethode_id
            && this.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoMethodeByMethode_id.fischinfoBefischungsgeraetByBefischungsgeraet_id
            ? this.fischinfoBefischungListByForeignUntersuchung_id[0]
                .fischinfoMethodeByMethode_id
                .fischinfoBefischungsgeraetByBefischungsgeraet_id
                .bezeichnung
            : ""
            ;
    }

    @fn.Label("Befischte Länge (m)")
    public get befischteLaenge(): string {
        return this.fischinfoBefischungListByForeignUntersuchung_id
            && this.fischinfoBefischungListByForeignUntersuchung_id[0]
            && this.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoMethodeByMethode_id
            && this.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoMethodeByMethode_id.befischtelaenge
            ? this.fischinfoBefischungListByForeignUntersuchung_id[0]
                .fischinfoMethodeByMethode_id
                .befischtelaenge
                .toString()
            : ""
            ;
    }

    @fn.Label("Erfassungs-Nr.")
    public get erfassungsNr(): string {
        return this.lfdnr
            ? String(this.lfdnr)
            : ""
            ;
    }


    @fn.Table({ columnIndex: 1, sortType: "number", sortBy: "erfassungsNrTabelleSortString" })
    @fn.CSV({ type: "varchar(254)" })
    @fn.SHP({ type: "varchar(254)" })
    @fn.Label("Erfassungs-Nr.")
    public get erfassungsNrTabelle(): string {
        return String(this.id) + "-" + String(this.lfdnr);
    }

    public get erfassungsNrTabelleSortString(): number {
        return this.id + (this.lfdnr * .000001);
    }


    @fn.Table({ columnIndex: 2, sortBy: "probestellennrSortString" })
    @fn.Label("PS-Nr.")
    @fn.CSV({ type: "varchar(254)" })
    @fn.SHP({ type: "varchar(254)" })
    public get probestellennrAsString(): string {
        return this.fischinfoProbenahmestelleByProbenahmestelle_id.probestellennrAsString;
    }

    public get probestellennrSortString(): string {
        return this.fischinfoProbenahmestelleByProbenahmestelle_id.probestellennrSortString;
    }


    @fn.Table({ columnIndex: 3, sortType: "string", columnPercentualWidth: 25 })
    @fn.Label("Gewässername (GKZ)")
    @fn.CSV({ type: "varchar(254)" })
    @fn.SHP({ type: "varchar(254)" })
    public get gewaessernameAsString(): string {
        return this.fischinfoProbenahmestelleByProbenahmestelle_id.gewaessernameAsString
            + " ("
            + this.fischinfoProbenahmestelleByProbenahmestelle_id.gewaesserkennzahlAsNumber
            + ")"
            ;
    }


    @fn.Table({ columnIndex: 4, sortType: "string", align: "right", columnPercentualWidth: 12 })
    @fn.CSV({ type: "varchar(4)" })
    @fn.SHP({ type: "varchar(4)" })
    @fn.Label("Gewässer-Typ")
    public get gewaessertyp(): string {
        return this.fischinfoProbenahmestelleByProbenahmestelle_id.gewaessertyp;
    }


    @fn.Table({ columnIndex: 6, sortType: "string", columnPercentualWidth: 20 })
    @fn.CSV({ type: "varchar(254)" })
    @fn.SHP({ type: "varchar(254)" })
    @fn.Label("Datenqualität")
    public get qualitaetdermeldung(): string {
        return this.fischinfoBefischungListByForeignUntersuchung_id
            && this.fischinfoBefischungListByForeignUntersuchung_id[0]
            && this.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoQualitaetdermeldungByQualitaetdermeldung_id
            ? this.fischinfoBefischungListByForeignUntersuchung_id[0]
                .fischinfoQualitaetdermeldungByQualitaetdermeldung_id
                .bezeichnung
            : ""
            ;
    }


    @fn.Table({ columnIndex: 8, sortType: "string", columnPercentualWidth: 12 })
    @fn.CSV({ type: "varchar(254)" })
    @fn.SHP({ type: "varchar(254)" })
    @fn.Label("unsichere Daten")
    public get unsicherAsString(): string {
        return !this.unsicher
            ? "Nein"
            : "Ja"
            ;
    }

    public get validiertAsString(): string {
        return !this.unsicher && this.freigegeben
            ? "Ja"
            : "Nein"
            ;
    }

}
