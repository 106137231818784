import { Component, Input, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoSubstrat from "../../models/fischinfo/substrat/model";
import FischinfoSubstratprozent from "../../models/fischinfo/substratprozent/model";
/**
 * Mit dieser Komponente kann der Nutzer die Zusammensetzung des Untergrunds des befischten Gewässers ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt die Anteile von verschiedenen Substraten am Untergrund dar. Der Nutzer kann diese als Prozente ansehen und bearbeiten.
 */
@Component({
    selector: "form-fischinfo-untersuchung-gewaesser-struktur-substratprozent",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungGewaesserStrukturSubstratprozent implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public _substratprozentList: any[];
    public get substratprozentList() {
        return this._substratprozentList;
    }
    public get gesamtProzent(): number {
        let prozent = 0;
        const gewaesserzustand = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand");
        gewaesserzustand
            && gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id
            && gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id.length > 0
            && gewaesserzustand.fischinfoSubstratprozentListByForeignGewaesserzustand_id.map(x => {
                prozent += parseFloat(String(x.anteil).replace(",", ".")) || 0;
            });
        return parseInt(String(prozent), 10) || 0;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    ngOnInit() {
        this.refreshSubstratprozentList();
    }
    public onChangeSubstratprozent(key: any, substratprozent: FischinfoSubstratprozent) {
        !this.readonly && this.formFischinfoUntersuchungService.modelUtil
            .remove<FischinfoSubstratprozent>(this.untersuchung, "FischinfoSubstratprozent", substratprozent);
        !this.readonly && (parseFloat(String(key).replace(",", ".")) || 0) > 0
            && this.formFischinfoUntersuchungService.modelUtil
                .setOrAdd<FischinfoSubstratprozent>(this.untersuchung, "FischinfoSubstratprozent", new FischinfoSubstratprozent({
                id: 1,
                substrat_id: substratprozent.substrat_id,
                anteil: parseFloat(String(key).replace(",", ".")) || null,
                gewaesserzustand_id: this.formFischinfoUntersuchungService.modelUtil
                    .getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                    .id
            }));
    }
    public refreshSubstratprozentList() {
        this.getNormalizedSubstratprozentList()
            .then((list) => {
            this._substratprozentList = list;
        });
    }
    public getNormalizedSubstratprozentList(): Promise<any[]> {
        let a = this.dataService
            .getReferenceTable("substrat", FischinfoSubstrat, "id");
        return Promise.all([a])
            .then(([substrat]) => {
            const values = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                .fischinfoSubstratprozentListByForeignGewaesserzustand_id || [];
            let list = [];
            Object.keys(substrat).forEach(key => {
                list.push({
                    id: (values.find(x => x.substrat_id === substrat[key].id) || { id: 0 }).id,
                    bezeichnung: substrat[key].bezeichnung,
                    substrat_id: substrat[key].id,
                    anteil: String((values.filter(x => x.substrat_id === substrat[key].id)[0] || { anteil: 0 }).anteil).replace(".", ",") || 0
                });
            });
            return list
                .filter(x => !this.readonly || (parseFloat(String(x.anteil).replace(",", ".")) || 0) > 0)
                .sort((a, b) => {
                a = a.substrat_id;
                b = b.substrat_id;
                return (a > b)
                    ? 1
                    : ((b > a)
                        ? -1
                        : 0);
            });
        });
    }
}
