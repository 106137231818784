import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoNetz from "./interface";
import FischinfoMethode from "./../../fischinfo/methode/model";
@fn.DataModel
export default class FischinfoNetz extends DataModel implements IFischinfoNetz {
    constructor(obj?: object | IFischinfoNetz) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoNetz): FischinfoNetz {
        return new FischinfoNetz(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoNetz): FischinfoNetz {
        Object.assign(this, obj);
        obj && obj["fischinfoMethodeByMethode_id"] && (this.fischinfoMethodeByMethode_id = FischinfoMethode.deserialize(obj["fischinfoMethodeByMethode_id"]));
        return this;
    }

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "hoehe", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public hoehe: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "laenge", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public laenge: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "methode_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public methode_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "position", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public position?: string;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "weitebis", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public weitebis?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "weitevon", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public weitevon?: number;

    /**
     * @type {FischinfoMethode}
     */
    @fn.DataMember({ name: "fischinfoMethodeByMethode_id", type: FischinfoMethode, nullable: true, memberType: "JOIN", joinProperty: "methode_id" })
    public fischinfoMethodeByMethode_id?: FischinfoMethode;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
