import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, enableProdMode } from "@angular/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UWButtonModule } from "ng-uw-button";
import { UWCheckboxModule } from "ng-uw-checkbox";
import { UWDateInputModule } from "ng-uw-dateinput";
import { UWDraggableModule } from "ng-uw-draggable";
import { UWFileInputModule } from "ng-uw-fileinput";
import { UWFileUploadModule } from "ng-uw-fileupload";
import { UWFileUploadMediathekModule } from "ng-uw-fileupload-mediathek";
import { UWLoginModule } from "ng-uw-login";
import { UWOverlayModule } from "ng-uw-overlay";
import { UWRadiobuttonModule } from "ng-uw-radiobutton";
import { UwResizeableModule } from "ng-uw-resizeable";
import { UWSelectModule } from "ng-uw-select";
import { UWShapefileInputModule } from "ng-uw-shapefile-input";
import { UWSidePanelModule } from "ng-uw-side-panel";
import { UWTabsModule } from "ng-uw-tabs";
import { UWTextareaModule } from "ng-uw-textarea";
import { UWTextInputModule } from "ng-uw-textinput";
import { UWTypeAheadModule } from "ng-uw-type-ahead";
/**
 * Required to support Web Animations `@angular/animation`.
 * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
 **/
import "web-animations-js/web-animations.min";
// require('web-animations-js/web-animations.min');

import App from "./components/app/component";

import Login from "./components/login/component";
import Map from "./components/map/component";
import InlineButton from "./components/inline-button/component";
import InlineUncheckButton from "./components/inline-uncheck-button/component";
import MapView from "./components/map-view/component";
import MapEditor from "./components/map-editor/component";
import MapLegende from "./components/map-legende/component";
import MapPanel from "./components/map-panel/component";
import MapTable from "./components/map-table/component";
import MapSearch from "./components/map-search/component";
import MapToolbar from "./components/map-toolbar/component";
import MapPanelFilter from "./components/map-panel-filter/component";
import MapPanelFibs from "./components/map-panel-fibs/component";
import MapPanelLayer from "./components/map-panel-layer/component";
import MapFooter from "./components/map-footer/component";
import Modal from "./components/modal/component";
import ModalWindow from "./components/modal-window/component";
import ModalWindowMessage from "./components/modal-window-message/component";
import ModalWindowTabWMS from "./components/modal-window-tab-wms/component";
import ModalWindowTabExport from "./components/modal-window-tab-export/component";
import ModalWindowTabAuskunft from "./components/modal-window-tab-auskunft/component";
import ModalWindowTabKartendruck from "./components/modal-window-tab-kartendruck/component";
import ModalWindowTabAuftragverwaltung from "./components/modal-window-tab-auftragverwaltung/component";
import ModalWindowTabAuftrag from "./components/modal-window-tab-auftrag/component";
import ModalWindowTabAuftragimport from "./components/modal-window-tab-auftragimport/component";
import ModalWindowTabBefischungimport from "./components/modal-window-tab-befischungimport/component";
import ModalService from "./components/modal/service";
import MapService from "./components/map/service";
import MapEditorService from "./components/map-editor/service";
import DataService from "./components/data/service";

import ModalWindowTabProbenahmestelle from "./components/modal-window-tab-probenahmestelle/component";
import ModalWindowTabUntersuchung from "./components/modal-window-tab-untersuchung/component";

import FormFischinfoProbenahmestelle from "./components/form-fischinfo-probenahmestelle/component";
import FormFischinfoProbenahmestelleService from "./components/form-fischinfo-probenahmestelle/service";
import FormFischinfoUntersuchung from "./components/form-fischinfo-untersuchung/component";
import FormFischinfoUntersuchungService from "./components/form-fischinfo-untersuchung/service";
import FormFischinfoUntersuchungBefischungAllgemein from "./components/form-fischinfo-untersuchung-befischung-allgemein/component";
import FormFischinfoUntersuchungBefischungErgebnisseFische from "./components/form-fischinfo-untersuchung-befischung-ergebnisse-fische/component";
import FormFischinfoUntersuchungBefischungErgebnisseMuscheln from "./components/form-fischinfo-untersuchung-befischung-ergebnisse-muscheln/component";
import FormFischinfoUntersuchungBefischungErgebnisseKrebse from "./components/form-fischinfo-untersuchung-befischung-ergebnisse-krebse/component";
import FormFischinfoUntersuchungBefischungErgebnisseArtErfassung from "./components/form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung/component";
import FormFischinfoUntersuchungBefischungErgebnisseArtErfassungLaengenklassen from "./components/form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-laengenklassen/component";
import FormFischinfoUntersuchungBefischungErgebnisseArtErfassungDetail from "./components/form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-detail/component";
import FormFischinfoUntersuchungBefischungErgebnisseArtErfassungDetailIndividuum from "./components/form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-detail-individuum/component";
import FormFischinfoUntersuchungBefischungErgebnisseArtErfassungAnzahl from "./components/form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-anzahl/component";
import FormFischinfoUntersuchungBefischungErgebnisseArtErfassungHaeufigkeit from "./components/form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-haeufigkeit/component";
import FormFischinfoUntersuchungBefischungErgebnisseArtErfassungNachweis from "./components/form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-nachweis/component";
import FormFischinfoUntersuchungBefischungFfhBewertung from "./components/form-fischinfo-untersuchung-befischung-ffhbewertung/component";
import FormFischinfoUntersuchungBefischungFfhBewertungArt from "./components/form-fischinfo-untersuchung-befischung-ffhbewertung-art/component";
import FormFischinfoUntersuchungBefischungMethode from "./components/form-fischinfo-untersuchung-befischung-methode/component";
import FormFischinfoUntersuchungBefischungMethodeEgeraet from "./components/form-fischinfo-untersuchung-befischung-methode-egeraet/component";
import FormFischinfoUntersuchungBefischungMethodeEgeraetGeraetetyp from "./components/form-fischinfo-untersuchung-befischung-methode-egeraet-geraetetyp/component";
import FormFischinfoUntersuchungBefischungMethodeEgeraetStreckenbefischung from "./components/form-fischinfo-untersuchung-befischung-methode-egeraet-streckenbefischung/component";
import FormFischinfoUntersuchungBefischungMethodeEgeraetPointabundance from "./components/form-fischinfo-untersuchung-befischung-methode-egeraet-pointabundance/component";
import FormFischinfoUntersuchungBefischungMethodeEgeraetAnderemethode from "./components/form-fischinfo-untersuchung-befischung-methode-egeraet-anderemethode/component";
import FormFischinfoUntersuchungBefischungMethodeNetz from "./components/form-fischinfo-untersuchung-befischung-methode-netz/component";
import FormFischinfoUntersuchungBefischungMethodeNetzAngabe from "./components/form-fischinfo-untersuchung-befischung-methode-netz-angabe/component";
import FormFischinfoUntersuchungBefischungMethodeReuse from "./components/form-fischinfo-untersuchung-befischung-methode-reuse/component";
import FormFischinfoUntersuchungBefischungMethodeSonstige from "./components/form-fischinfo-untersuchung-befischung-methode-sonstige/component";
import FormFischinfoUntersuchungFibsHmwb from "./components/form-fischinfo-untersuchung-fibs-hmwb/component";
import FormFischinfoUntersuchungFibsNwb from "./components/form-fischinfo-untersuchung-fibs-nwb/component";
import FormFischinfoUntersuchungFibsVergleich from "./components/form-fischinfo-untersuchung-fibs-vergleich/component";
import FormFischinfoUntersuchungGewaesserAllgemein from "./components/form-fischinfo-untersuchung-gewaesser-allgemein/component";
import FormFischinfoUntersuchungGewaesserSonstiges from "./components/form-fischinfo-untersuchung-gewaesser-sonstiges/component";
import FormFischinfoUntersuchungGewaesserSonstigesZustandumland from "./components/form-fischinfo-untersuchung-gewaesser-sonstiges-zustandumland/component";
import FormFischinfoUntersuchungGewaesserSonstigesZustandeinzelbeeintraechtigung from "./components/form-fischinfo-untersuchung-gewaesser-sonstiges-zustandeinzelbeeintraechtigung/component";
import FormFischinfoUntersuchungGewaesserStruktur from "./components/form-fischinfo-untersuchung-gewaesser-struktur/component";
import FormFischinfoUntersuchungGewaesserStrukturStrukturelementehaeufigkeit from "./components/form-fischinfo-untersuchung-gewaesser-struktur-strukturelementehaeufigkeit/component";
import FormFischinfoUntersuchungGewaesserStrukturSubstratprozent from "./components/form-fischinfo-untersuchung-gewaesser-struktur-substratprozent/component";
import FormFischinfoUntersuchungGewaesserStrukturAusbauzustand from "./components/form-fischinfo-untersuchung-gewaesser-struktur-ausbauzustand/component";
import FormFischinfoUntersuchungGewaesserStrukturZustanduferpflanze from "./components/form-fischinfo-untersuchung-gewaesser-struktur-zustanduferpflanze/component";
import FormFischinfoUntersuchungGewaesserStrukturZustandpflanzenart from "./components/form-fischinfo-untersuchung-gewaesser-struktur-zustandpflanzenart/component";

import UWAccordion from "./components/accordion/directive";
import UWAccordionPane from "./components/accordion-pane/component";
import UWAccordionPaneDirective from "./components/accordion-pane/directive";

/**
 * Bootstrap Modul
 *  
 */
@NgModule({
    imports: [
        HttpClientModule,
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        UWButtonModule,
        UWCheckboxModule,
        UWDateInputModule,
        UWDraggableModule,
        UWFileInputModule,
        UWFileUploadModule,
        UWFileUploadMediathekModule,
        UWLoginModule,
        UWOverlayModule,
        UWRadiobuttonModule,
        UwResizeableModule,
        UWSelectModule,
        UWShapefileInputModule,
        UWSidePanelModule,
        UWTabsModule,
        UWTextareaModule,
        UWTextInputModule,
        UWTypeAheadModule,
    ],
    bootstrap: [App],
    declarations: [
        App,
        Login,
        Modal,
        ModalWindow,
        ModalWindowMessage,
        ModalWindowTabWMS,
        ModalWindowTabExport,
        ModalWindowTabAuskunft,
        ModalWindowTabKartendruck,
        ModalWindowTabAuftragverwaltung,
        ModalWindowTabAuftrag,
        ModalWindowTabAuftragimport,
        ModalWindowTabBefischungimport,
        Map,
        MapView,
        MapEditor,
        MapLegende,
        MapPanelFilter,
        MapPanelFibs,
        MapPanelLayer,
        MapPanel,
        MapToolbar,
        MapFooter,
        MapSearch,
        MapTable,
        ModalWindowTabProbenahmestelle,
        ModalWindowTabUntersuchung,
        FormFischinfoProbenahmestelle,
        FormFischinfoUntersuchung,
        FormFischinfoUntersuchungBefischungAllgemein,
        FormFischinfoUntersuchungBefischungErgebnisseFische,
        FormFischinfoUntersuchungBefischungErgebnisseMuscheln,
        FormFischinfoUntersuchungBefischungErgebnisseKrebse,
        FormFischinfoUntersuchungBefischungErgebnisseArtErfassung,
        FormFischinfoUntersuchungBefischungErgebnisseArtErfassungLaengenklassen,
        FormFischinfoUntersuchungBefischungErgebnisseArtErfassungDetail,
        FormFischinfoUntersuchungBefischungErgebnisseArtErfassungDetailIndividuum,
        FormFischinfoUntersuchungBefischungErgebnisseArtErfassungAnzahl,
        FormFischinfoUntersuchungBefischungErgebnisseArtErfassungHaeufigkeit,
        FormFischinfoUntersuchungBefischungErgebnisseArtErfassungNachweis,
        FormFischinfoUntersuchungBefischungFfhBewertung,
        FormFischinfoUntersuchungBefischungFfhBewertungArt,
        FormFischinfoUntersuchungBefischungMethode,
        FormFischinfoUntersuchungBefischungMethodeEgeraet,
        FormFischinfoUntersuchungBefischungMethodeEgeraetGeraetetyp,
        FormFischinfoUntersuchungBefischungMethodeEgeraetStreckenbefischung,
        FormFischinfoUntersuchungBefischungMethodeEgeraetPointabundance,
        FormFischinfoUntersuchungBefischungMethodeEgeraetAnderemethode,
        FormFischinfoUntersuchungBefischungMethodeNetz,
        FormFischinfoUntersuchungBefischungMethodeNetzAngabe,
        FormFischinfoUntersuchungBefischungMethodeReuse,
        FormFischinfoUntersuchungBefischungMethodeSonstige,
        FormFischinfoUntersuchungFibsHmwb,
        FormFischinfoUntersuchungFibsNwb,
        FormFischinfoUntersuchungFibsVergleich,
        FormFischinfoUntersuchungGewaesserAllgemein,
        FormFischinfoUntersuchungGewaesserSonstiges,
        FormFischinfoUntersuchungGewaesserSonstigesZustandumland,
        FormFischinfoUntersuchungGewaesserSonstigesZustandeinzelbeeintraechtigung,
        FormFischinfoUntersuchungGewaesserStruktur,
        FormFischinfoUntersuchungGewaesserStrukturStrukturelementehaeufigkeit,
        FormFischinfoUntersuchungGewaesserStrukturSubstratprozent,
        FormFischinfoUntersuchungGewaesserStrukturAusbauzustand,
        FormFischinfoUntersuchungGewaesserStrukturZustanduferpflanze,
        FormFischinfoUntersuchungGewaesserStrukturZustandpflanzenart,
        InlineButton,
        InlineUncheckButton,
        UWAccordion,
        UWAccordionPane,
        UWAccordionPaneDirective,
    ],
    providers: [
        DataService,
        MapService,
        MapEditorService,
        ModalService,
        FormFischinfoProbenahmestelleService,
        FormFischinfoUntersuchungService
    ]
})
export class AppModule { }
enableProdMode();
platformBrowserDynamic().bootstrapModule(AppModule);
