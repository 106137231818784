import { Component, Input, Output, EventEmitter } from "@angular/core";
/**
 * Diese Komponente stellt eine durch den Nutzer an-/abwählbare Option dar.
 *
 * Wenn der Nutzer mit der auf diese Komponente klickt, ändert sich in der
 * Regel der Wert der mit der Komponente verknüpft ist. Diese Komponente gibt
 * die konkreten Regeln, nach denen sich der Wert änder, nicht vor.
 * Andere Komponenten beschreiben die konkreten Regeln für die Haltung des Wertes.
 *
 * Wenn die Option abgewählt ist, dann besteht die Erscheinung dieser Komponente
 * aus einem dünnen Ring. Wenn die Option angewählt ist, dann besteht die Erscheinung
 * dieser Komponente aus einem Kreuz und einem einstellbaren und optionalen
 * zusätzlichen Symbol (Standardeinstellung: Haken).
 */
@Component({
    selector: "uw-inline-uncheck-button",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class InlineUncheckButton {
    @Input()
    public iconClass: string = "fa fa-check";
    @Input()
    public title: string = "Angewählt";
    @Input()
    public checked: boolean;
    @Input()
    public disabled: boolean;
    @Input()
    public hideIcon: boolean;
    @Input()
    public width: number = 48;
    constructor() { }
}
