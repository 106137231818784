import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoHerkunftfluss from "./interface";
import FischinfoBesatzereignis from "./../../fischinfo/besatzereignis/model";
@fn.DataModel
export default class FischinfoHerkunftfluss extends DataModel implements IFischinfoHerkunftfluss {
    constructor(obj?: object | IFischinfoHerkunftfluss) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoHerkunftfluss): FischinfoHerkunftfluss {
        return new FischinfoHerkunftfluss(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoHerkunftfluss): FischinfoHerkunftfluss {
        Object.assign(this, obj);
        obj && obj["fischinfoBesatzereignisListByForeignHerkunftfluss_id"] && obj["fischinfoBesatzereignisListByForeignHerkunftfluss_id"].length && (this.fischinfoBesatzereignisListByForeignHerkunftfluss_id = obj["fischinfoBesatzereignisListByForeignHerkunftfluss_id"].map(FischinfoBesatzereignis.deserialize));
        obj && obj["fischinfoBesatzereignisListByForeignQualitaetelternfluss_id"] && obj["fischinfoBesatzereignisListByForeignQualitaetelternfluss_id"].length && (this.fischinfoBesatzereignisListByForeignQualitaetelternfluss_id = obj["fischinfoBesatzereignisListByForeignQualitaetelternfluss_id"].map(FischinfoBesatzereignis.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoBesatzereignis>}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisListByForeignHerkunftfluss_id", type: [FischinfoBesatzereignis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzereignisListByForeignHerkunftfluss_id?: Array<FischinfoBesatzereignis>;

    /**
     * @type {Array<FischinfoBesatzereignis>}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisListByForeignQualitaetelternfluss_id", type: [FischinfoBesatzereignis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzereignisListByForeignQualitaetelternfluss_id?: Array<FischinfoBesatzereignis>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
