import { Input, Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { saveAs } from "file-saver";

import { UWDataService } from "ng-uw-virtual-table";

import { UWCsvService } from "ng-uw-csv-service";
import { LoginService } from "ng-uw-login-service";

import DataModel from "../../models/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import UserService from "../user/service";

const DataWorkerUrl = require("./worker");

export interface Deserializable<T> {
    deserialize(source: any): T
}

/**
 * Dieser Dienst holt WebService-Modelle ab und schickt geänderte Daten an den WebService.
 * 
 * Darüber hinaus erlaubt dieser Dienst auch den Export von anderen Datenformaten wie CSV als Datei.
 * Dieser Dienst hält außerdem Referenzinformationen (Daten, die nur gelesen, nicht geschrieben werden)
 * vor, um einen schnelleren Zugriff darauf zu gewährleisten.
 */
@Injectable({
    providedIn: 'root'
})
export default class DataService extends UWDataService {
    protected __id: number;
    protected _folderChanged: BehaviorSubject<boolean> = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    protected _requestItemSuccess: BehaviorSubject<any> = <BehaviorSubject<any>>new BehaviorSubject({});
    protected _saveAsFileSuccess: BehaviorSubject<any> = new BehaviorSubject<any>({});
    protected _dataStoreHashMap: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
    public webServiceApiItemUrl: string;
    public webServiceApiItemGeometryUrl: string;
    public webServiceApiListUrl: string;
    public webServiceApiListItemUrl: string;
    public webServiceApiListItemMinifiedUrl: string;
    public webServiceApiTokenBasedLoginUrl: string;
    public webServiceApiListDataNodePlaceholderenUrl: string;
    public filter: any = {};
    public changedFilters: any = {};
    public columns: any = [];
    public folder: string = "probenahmestelle";
    public dataStore: any;
    public folderModel: typeof DataModel;
    public limit: number = 1000;
    protected loadingHash: number;
    protected loadingItemHash: any = [];
    public webServiceApiUrl: string;
    public startLoad: EventEmitter<boolean> = new EventEmitter();

    public set webServiceApi(url: string) {

        this.webServiceApiUrl = url;
        this.webServiceApiItemUrl =
            url + "{folder}/{id}?limit=1&format=application/json";
        this.webServiceApiItemGeometryUrl =
            url + "{folder}/{id}/geometry?limit=1&format=application/json";
        this.webServiceApiListUrl =
            url + "{folder}?limit={limit}&format=application/json";
        this.webServiceApiListItemUrl =
            url + "probenahmestelle?limit=400&format=application/json";
        this.webServiceApiListItemMinifiedUrl =
            url + "probenahmestelle/minified?limit=400&format=application/json";
        this.webServiceApiTokenBasedLoginUrl =
            url +
            "login?loginname={login}&password={password}&format=application/json";


    }
    public get webServiceApi(): string {
        return this.webServiceApiUrl;
    }

    public get folderChanged(): Observable<boolean> {
        return this._folderChanged.asObservable();
    }

    public get requestItemSuccess(): Observable<any> {
        return this._requestItemSuccess.asObservable();
    }

    public get saveAsFileSuccess(): Observable<any> {
        return this._saveAsFileSuccess.asObservable();
    }

    public get selectedFolderItems(): any {
        return this.dataStore.items || [];
    }

    public chosenDataNodePlaceholderChangeEvent: EventEmitter<any> = new EventEmitter();
    public chosenDataNodePlaceholderValue: any = null;
    public get chosenDataNodePlaceholder(): any {
        return this.chosenDataNodePlaceholderValue;
    }
    public set chosenDataNodePlaceholder(value: any) {
        if (
            !value ||
            value === null ||
            this.chosenDataNodePlaceholderValue === null ||
            this.chosenDataNodePlaceholderValue.id !== value.id
        ) {
            this.chosenDataNodePlaceholderValue = value;
            this.chosenDataNodePlaceholderChangeEvent.emit(value);
        }
    }

    public chosenSchutzgebietChangeEvent: EventEmitter<any> = new EventEmitter();
    public chosenSchutzgebietValue: any = null;
    public get chosenSchutzgebiet(): any {
        return this.chosenSchutzgebietValue;
    }
    public set chosenSchutzgebiet(value: any) {
        if (
            !value ||
            value === null ||
            this.chosenSchutzgebietValue === null ||
            this.chosenSchutzgebietValue.id !== value.id
        ) {
            this.chosenSchutzgebietValue = value;
            this.chosenSchutzgebietChangeEvent.emit(value);
        }
    }

    protected worker: { run<T>(data?: any): Promise<T> } =
        createWorkerRunner(() => new Worker(DataWorkerUrl));

    constructor(
        protected loginService: LoginService,
        protected userService: UserService,
        protected csv: UWCsvService,
        protected http: HttpClient,
        // protected worker: Worker
    ) {
        super();
        // this.dataStore.items = [];
    }

    public readonly classByFolder = Object.freeze({
        probenahmestelle: FischinfoProbenahmestelle,
        untersuchung: FischinfoUntersuchung,
        // auftrag: FischinfoAuftrag,
    });

    public get filtersAreSet(): boolean {
        let filtersAreSet: boolean;
        for (const key in this.changedFilters) {
            if (
                this.filter[key] !== null
                && typeof (this.filter[key]) !== "undefined"
            ) {
                filtersAreSet = true;
            }
        }
        return filtersAreSet;
    }


    public referenceTables: { [folder: string]: { [key: string]: any; }[]; }[] = [];
    public getReferenceTable<T = any>(folder: string | string[], type: Deserializable<T>, key: string, headers?: any): Promise<{ [key: string]: T; }> {
        headers = headers || {};
        type Result = { resultset: any[] };
        const folderKey = Array.isArray(folder) ? folder.join("/") : folder;
        if (this.referenceTables[folderKey]) {
            return new Promise((resolve, reject) => {
                resolve(this.referenceTables[folderKey]);
            });
        } else {
            return this.loginService
                .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
                .then(token => this.http
                    .get<Result>(
                        this.transcribeURL(
                            this.webServiceApiListUrl,
                            { folder },
                            10000,
                        ),
                        { headers: Object.assign(headers, { "X-Auth-Token": token }), responseType: "json" },
                    ).toPromise()
                )
                .then(x => x.resultset)
                .then(list => {
                    let refs = {};
                    list
                        .map(x => type.deserialize(x))
                        .forEach(element => {
                            refs[element[key].toString()] = element;
                        })
                        ;
                    return this.referenceTables[folderKey] = refs;
                })
                ;
        }
    }

    public get<T = any>(folder: string, type: Deserializable<T>, id: string, headers?: any, url?: string, withFilter: boolean = true, filter?: any): Promise<T> {
        headers = headers || {};
        type Result = { resultset: any[] };
        return this.loginService
            .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
            .then(token => this.http
                .get<Result>(
                    this.transcribeURL(
                        url || this.webServiceApiItemUrl,
                        Object.assign({ folder, id }, withFilter ? filter || this.filter : {}), // Filter in Einzelaufruf uebernehmen
                        1,
                    ),
                    { headers: Object.assign(headers, { "X-Auth-Token": token }), responseType: "json" },
                ).toPromise()
            )
            .then(x => x.resultset[0])
            .then(x => type.deserialize(x))
            ;
    }

    public getAll<T = any>(url: string, type: Deserializable<T>, headers?: any): Promise<T[]> {
        headers = headers || {};
        type Result = { resultset: any[] };
        return this.loginService
            .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
            .then(token => this.http
                .get<Result>(
                    url,
                    { headers: Object.assign(headers, { "X-Auth-Token": token }), responseType: "json" },
                ).toPromise()
            )
            .then(x => x && x.resultset && x.resultset.map(type.deserialize))
            ;
    }

    public delete<T = any>(folder: string, id: string, headers?: any): Promise<any> {
        if (!headers) {
            headers = { "X-HTTP-Method-Override": "DELETE" };
        }
        else if (!headers["X-HTTP-Method-Override"]) {
            headers["X-HTTP-Method-Override"] = "DELETE";
        }
        return this.loginService
            .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
            .then(token => this.http
                .post<T>(
                    this.transcribeURL(
                        this.webServiceApiItemUrl,
                        {
                            folder: folder,
                            id: id
                        },
                        1
                    ),
                    {
                        id: id
                    },
                    {
                        headers: Object.assign(headers, { "X-Auth-Token": token })
                    }
                )
                .toPromise()
            );
    }

    public save<T = any>(folder: string, item: any, headers?: any, url?: string): Promise<any> {
        headers = headers || {};
        return this.loginService
            .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
            .then(token => this.http
                .post<T>(
                    this.transcribeURL(
                        url || this.webServiceApiItemUrl,
                        {
                            folder: folder,
                            id: item.id
                        },
                        1
                    ),
                    item,
                    {
                        headers: Object.assign(headers, { "X-Auth-Token": token })
                    }
                )
                .toPromise());
    }

    public create<T>(folder: string, item: any, headers?: any): Promise<HttpResponse<T>> {
        headers = headers || {};
        return this.loginService
            .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
            .then(token => this.http
                .post(
                    this.transcribeURL(
                        this.webServiceApiListUrl,
                        {
                            folder: folder
                        },
                        1
                    ),
                    item,
                    {
                        observe: "response",
                        headers: Object.assign(headers, { "X-Auth-Token": token })
                    }
                )
                .pipe(mergeMap((response) => {
                    return this.http.get<T>(
                        response.headers.get("Location").replace("http://", "//"),
                        {
                            observe: "response",
                            headers: <any>{
                                "X-AUTH-TOKEN": token || [],
                            }
                        }
                    );
                }))
                .toPromise());
    }

    public transcribeURL(
        url: string,
        pattern: { [name: string]: string | string[] },
        limit?: number
    ): string {
        for (let key in pattern) if (pattern.hasOwnProperty(key)) {
            const patIndex = url.indexOf("{" + key + "}");
            let value = pattern[key];
            value = Array.isArray(value)
                ? value.map(encodeURIComponent).join(
                    patIndex > -1 && patIndex < url.indexOf("?") ? "/" : ""
                )
                : encodeURIComponent(value)
                ;
            if (patIndex > -1) {
                url = url.replace("{" + key + "}", value);
            } else {
                url = url +
                    "&" +
                    encodeURIComponent(key) +
                    "=" +
                    value;
            }
        }
        if (limit != null) {
            url = url.replace(
                "{limit}",
                encodeURIComponent(limit != null ? String(limit) : "")
            );
        }
        return url;
    }

    public getLoginTokenPromise(): Promise<any> {
        return Promise.resolve(
            this.loginService.getAuthToken(this.webServiceApiTokenBasedLoginUrl)
        );
    }

    public setFilter(name: string, value?: any): void {
        if (value === null || value === undefined) {
            delete this.filter[name];
        } else {
            this.filter[name] = value.toString();
        }
    }

    public setFolder(folder: string, refresh?: boolean): void {
        if (folder !== this.folder || refresh) {
            this.folder = folder;
            // @TODO get the classByFolder for all registered models
            this.folderModel = this.classByFolder[folder];
            this.columns = this.formatColumns(this.folderModel.prototype, "table");
            this.dataStore.items = this.dataStore[this.folder];
            this._folderChanged.next(true);
        }
    }

    public async provideBlobToUser(blob: Blob, name: string) {
        saveAs(blob, name);
    }

    public async saveFibsResult(untersuchungId: number, isNwb: boolean) {
        const befischungId = await this.get<{ id: number }>(
            "untersuchung",
            { deserialize: x => x },
            String(untersuchungId),
            null,
            this.webServiceApiUrl + "{folder}/{id}/befischung?limit=1&format=application/json",
        )
            .then(befischung => { return (befischung && befischung.id) || 0 }) || 0
            ;
        return this.loginService
            .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
            .then(token =>
                this.http.get(
                    this.transcribeURL(
                        this.webServiceApiItemUrl.replace("{id}", "{id}/{subfolder1}/{subfolder2}"),
                        {
                            folder: "fibscalculator", id: befischungId.toFixed(0),
                            subfolder1: "result", subfolder2: isNwb ? "nwb" : "hmwb",
                        },
                        1,
                    ),
                    {
                        headers: { "X-Auth-Token": token },
                        observe: "response",
                        responseType: "blob",
                    },
                ).toPromise()
            )
            .then(x => (
                this.provideBlobToUser(
                    x.body,
                    x.headers
                        .get("Content-Disposition")
                        .match(/attachment; ?filename=([^;]+)/)[1]
                    || (befischungId.toFixed(0) + (isNwb ? "nwb" : "hmwb") + ".fibs")
                )
            ))
            ;
    }

    public saveAsFile(filetype: string = "csv", filename?: string): void {
        const type =
            (filetype ? filetype[0] : "").toUpperCase() +
            filetype.slice(1).toLowerCase();
        const fn = this["saveAs" + type + "File"];
        if (typeof fn === "function") {
            fn.call(this, filename || "tabelle." + filetype.toLowerCase());
        } else {
            throw new RangeError(
                "File format '" + filetype + "' can not be exported"
            );
        }
    }

    public buildCsvData() {
        const tabDef = [];
        const modelProperties = this.formatColumns(this.folderModel.prototype, "csv");

        tabDef.push([]);
        for (let col of modelProperties) {
            tabDef[0].push(col.label);
        }

        tabDef.push([]);
        for (let col of modelProperties) {
            tabDef[1].push(col.shpfield || col.label);
        }

        tabDef.push([]);
        for (let col of modelProperties) {
            tabDef[2].push(col.type || "varchar(254)");
        }

        for (let item of this.dataStore.items) {
            const dataRow = [];
            for (let col of modelProperties) {
                dataRow.push(item[col.name]);
            }
            tabDef.push(dataRow);
        }
        return this.csv.transformModelToBlobSource(tabDef);
    }

    public saveAsCsvFile(filename: string): void {
        const tabDef = [];
        const modelProperties = this.formatColumns(this.folderModel.prototype, "csv");

        tabDef.push([]);
        for (let col of modelProperties) {
            tabDef[0].push(col.label);
        }

        for (let item of this.dataStore[this.folder]) {
            const dataRow = [];
            for (let col of modelProperties) {
                dataRow.push(item[col.name]);
            }
            tabDef.push(dataRow);
        }

        this.csv.saveAs(tabDef, filename);
    }

    public getByWorker(url, stack, params, model, success, error) {
        Object.assign(params, this.filter);
        params._t = new Date().getTime().toString();
        this.loadingItemHash[stack] = new Date().getTime();
        this.loginService
            .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
            .then(token => {
                this.worker
                    .run({
                        func: "requestItem",
                        url: this.transcribeURL(url, params, params.limit || 1),
                        // model: model,
                        headers: {
                            "X-AUTH-TOKEN": token
                        },
                        timestamp: this.loadingItemHash[stack]
                    })
                    .then(response => {
                        if (this.loadingItemHash[stack] === response["timestamp"]) {
                            success({ params: params, resultset: response["resultset"] });
                        }
                    })
                    .catch(e => error(e));
            })
            .catch(e => error(e));
    }

    public requestCollection(url, stack, params, model, success, error) {
        Object.assign(params, this.filter);
        params._t = new Date().getTime().toString();
        this.loadingItemHash[stack] = new Date().getTime();
        this.loginService
            .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
            .then(token => {
                this.worker
                    .run({
                        func: "requestCollection",
                        url: this.transcribeURL(url, params, params.limit || 1),
                        model: model,
                        headers: {
                            "X-AUTH-TOKEN": token
                        },
                        timestamp: this.loadingItemHash[stack]
                    })
                    .then(response => {
                        if (this.loadingItemHash[stack] === response["timestamp"]) {
                            success({ params: params, resultset: response["resultset"] });
                        }
                    })
                    .catch(e => error(e));
            })
            .catch(e => error(e));
    }

    public requestJsonURI(url, stack, params, success, error) {
        Object.assign(params, this.filter);
        params._t = new Date().getTime().toString();
        this.loadingItemHash[stack] = new Date().getTime();
        this.loginService
            .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
            .then(token => {
                this.worker
                    .run({
                        func: "requestJsonURI",
                        url: this.transcribeURL(url, params, params.limit || 1),
                        headers: {
                            "X-AUTH-TOKEN": token
                        },
                        timestamp: this.loadingItemHash[stack]
                    })
                    .then(response => {
                        if (this.loadingItemHash[stack] === response["timestamp"]) {
                            if (response["responseText"] && response["responseText"].length) {
                                success({
                                    params: params,
                                    resultset: JSON.parse(response["responseText"])
                                });
                            } else {
                                error({ message: "No valid responseText." });
                            }
                        }
                    })
                    .catch(e => error(e));
            })
            .catch(e => error(e));
    }

    public loadDataStore(): void {
        this.startLoad.emit(true);
        this.filter._t = new Date().getTime().toString();
        this.isLoading = true;
        this.dataStore = { data: [], items: [] }; // original data // view output pointer to formatted store
        // @TODO Get the list of all registered DataModels
        const stores = Object.keys(this.classByFolder); //this.core.getModelItemAttr();
        for (let store of stores) {
            this.dataStore[store] = [];
            this._dataStoreHashMap[store] = new Map<string, boolean>();
        }
        this.__id = -1;
        this.setFolder(this.folder, true);
        this.loadingHash = new Date().getTime();

        this.loginService
            .getAuthToken(this.webServiceApiTokenBasedLoginUrl)
            .then(token => {
                this._loadDataStoreNextPage(
                    this.transcribeURL(
                        this.webServiceApiListItemMinifiedUrl,
                        this.filter,
                        this.limit
                    ),
                    this.loadingHash,
                    token
                );
            })
            .catch(e => {
                console.log(e);
            });
    }



    protected _loadDataStoreNextPage(url, timestamp, token) {
        this.worker
            .run<{ timestamp: number, items: any[], next: string }>({
                func: "loadNextURL",
                url: url,
                // model: this.classByFolder.probenahmestelle,
                headers: { "X-AUTH-TOKEN": token },
                timestamp: timestamp
            })
            //this.http
            //.get<Result>(url, { responseType: "json" }).toPromise()
            //.then(({ resultset: items, next }) => ({
            //    timestamp,
            //    items: items ? items.map(x => this.classByFolder.probenahmestelle.deserialize(x)) : [],
            //    next,
            //}))
            .then(({ timestamp, items, next }) => ({
                timestamp,
                items: items ? items.map(x => this.classByFolder.probenahmestelle.deserialize(x)) : [],
                next,
            }))
            .then(response => {
                if (this.loadingHash === response.timestamp) {

                    // #region data mapping of probenahmestelle and untersuchungen
                    let pageProbenahmestellen = (response.items || []).map(probenahmestelle => {
                        if (!this._dataStoreHashMap["probenahmestelle"][String(probenahmestelle.id)]) {
                            this._dataStoreHashMap["probenahmestelle"][String(probenahmestelle.id)] = true;
                            Array.prototype.push.apply(
                                this.dataStore["untersuchung"],
                                (probenahmestelle.fischinfoUntersuchungListByForeignProbenahmestelle_id || []).map(untersuchung => {
                                    if (!this._dataStoreHashMap["untersuchung"][String(untersuchung.id)]) {
                                        this._dataStoreHashMap["untersuchung"][String(untersuchung.id)] = true;
                                        untersuchung.probenahmestelle_id = probenahmestelle.id;
                                        untersuchung.fischinfoProbenahmestelleByProbenahmestelle_id = probenahmestelle;
                                        return untersuchung;
                                    }
                                })
                            );
                            return probenahmestelle;
                        }
                    });
                    Array.prototype.push.apply(
                        this.dataStore["probenahmestelle"],
                        pageProbenahmestellen
                    );
                    // #endregion

                    if (!response.next || !response.next.length) {
                        this._partialLoadSuccess.next(pageProbenahmestellen);
                        this._completeLoadSuccess.next(true);
                        this.isLoading = false;
                    } else {
                        this._partialLoadSuccess.next(pageProbenahmestellen);
                        response.next = window.location.protocol + response.next;
                        this._loadDataStoreNextPage(response.next, response.timestamp, token);
                    }
                }
            })
            .catch(error => console.error(error));
    }

    public formatColumns(dataModel: DataModel, dataView: string): any {
        let staticColumnWidths: any = {
            cnt: 0,
            width: 0
        },
            colsWithHoles = [],
            cols = [];
        const modelProperties = dataModel.listModelProperties(dataView);
        const columns = Object.keys(modelProperties);

        columns.forEach(column => {
            const td = modelProperties[column];
            if (td.columnIndex > 0) {
                colsWithHoles[td.columnIndex - 1] = td;
            }
        });

        columns.forEach(column => {
            const td = modelProperties[column];
            if (!td.columnIndex || td.columnIndex < 1) {
                let i = 0;
                while (colsWithHoles[i]) {
                    i += 1;
                }
                colsWithHoles[i] = td;
            }
        });


        let filter = this.filter;
        filter["isNotExtendedView"] = !this.userService.isLoggedIn;
        filter["isExtendedView"] = this.userService.isLoggedIn;
        for (let column in colsWithHoles) {
            if (
                colsWithHoles[column]
                && !(
                    colsWithHoles[column].dependsOnFilter
                    && !filter[colsWithHoles[column].dependsOnFilter]
                )
            ) {
                cols.push(colsWithHoles[column]);
            }
        }

        for (let column in cols) {
            if (cols[column].columnPercentualWidth > 0) {
                staticColumnWidths.cnt++;
                staticColumnWidths.width += cols[column].columnPercentualWidth;
            }
        }

        for (let column in cols) {
            if (cols[column].columnPercentualWidth < 1) {
                cols[column].columnPercentualWidth =
                    (100 - staticColumnWidths.width) /
                    (cols.length - staticColumnWidths.cnt);
            }
        }

        return cols;
    }

    protected createStrComparer() {
        try {
            return new Intl.Collator("de-DE");
        }
        catch (e) {
            return {
                compare: (a: string, b: string) => a.localeCompare(b),
            };
        }
    }
    protected strComparer: { compare(a: string, b: string): number } = this.createStrComparer();

    public sort(selectedSortColumn, selectedSortOrder, selectedSortType): void {
        selectedSortColumn =
            selectedSortColumn && selectedSortColumn.length
                ? selectedSortColumn
                : "id";
        let _sort_asc = (a, b) => {
            return a[selectedSortColumn] === b[selectedSortColumn]
                ? a.id > b.id ? -1 : 1
                : +(a[selectedSortColumn] > b[selectedSortColumn]) || -1;
        };
        let _sort_desc = (a, b) => {
            return a[selectedSortColumn] === b[selectedSortColumn]
                ? a.id < b.id ? -1 : 1
                : +(a[selectedSortColumn] < b[selectedSortColumn]) || -1;
        };
        let _sort_str_asc = (a, b) => {
            return a[selectedSortColumn].toString().toLowerCase() ===
                b[selectedSortColumn].toString().toLowerCase()
                ? a.id > b.id ? -1 : 1
                : this.strComparer.compare(
                    a[selectedSortColumn].toString().toLowerCase(),
                    b[selectedSortColumn].toString().toLowerCase(),
                );
        };
        let _sort_str_desc = (a, b) => {
            return a[selectedSortColumn].toString().toLowerCase() ===
                b[selectedSortColumn].toString().toLowerCase()
                ? a.id < b.id ? -1 : 1
                : this.strComparer.compare(
                    b[selectedSortColumn].toString().toLowerCase(),
                    a[selectedSortColumn].toString().toLowerCase(),
                );
        };

        if (selectedSortType === "string") {
            this.dataStore.items && this.dataStore.items.sort(
                selectedSortOrder === "desc" ? _sort_str_desc : _sort_str_asc
            );
        } else {
            this.dataStore.items && this.dataStore.items.sort(
                selectedSortOrder === "desc" ? _sort_desc : _sort_asc
            );
        }
        this.dataStore.items && this.dataStore.items.forEach((item, i) => {
            item.__id = i;
        });

        this._sortSuccess.next(true);
    }

    public updateUntersuchungChildIdsFrom(updated: FischinfoUntersuchung) {
        const outdated: FischinfoUntersuchung = (this.dataStore["untersuchung"] || [])
            .find(u => u.id === updated.id)
            ;
        // Assumes that each Untersuchung has at most one of any Befischung, Krebsuntersuchung or Muscheluntersuchung
        if (outdated) {
            for (const listKey of [
                "fischinfoBefischungListByForeignUntersuchung_id" as "fischinfoBefischungListByForeignUntersuchung_id",
                "fischinfoKrebsuntersuchungListByForeignUntersuchung_id" as "fischinfoKrebsuntersuchungListByForeignUntersuchung_id",
                "fischinfoMuscheluntersuchungListByForeignUntersuchung_id" as "fischinfoMuscheluntersuchungListByForeignUntersuchung_id",
            ]) {
                const updList = updated[listKey]
                const outdList = outdated[listKey]
                if (updList && updList.length >= 1 && updList[0]) {
                    if (outdList && outdList.length >= 1 && outdList[0]) {
                        outdList[0].id = updList[0].id;
                    }
                    else {
                        outdated[listKey] = [updList[0] as any]
                    }
                }
                else if (outdList) {
                    outdList.length = 0;
                }
            }
        }
    }

}

export function createWorkerRunner(createWorker: () => Worker): { run<T>(data?: any): Promise<T> } {
    return {
        run<T>(data?: any) {
            // return createPromiseForWorker<T>(new Worker(url), data);
            return createPromiseForWorker<T>(createWorker(), data);
        }
    };

    function createPromiseForWorker<T>(worker: Worker, data: any) {
        return new Promise<T>((resolve, reject) => {
            worker.addEventListener('message', (event) => resolve(event.data));
            worker.addEventListener('error', reject);
            worker.postMessage(data);
        });
    }
}
