import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoReferenz_fischgewicht from "./interface";
import FischinfoFischart from "./../../fischinfo/fischart/model";
@fn.DataModel
export default class FischinfoReferenz_fischgewicht extends DataModel implements IFischinfoReferenz_fischgewicht {
    constructor(obj?: object | IFischinfoReferenz_fischgewicht) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoReferenz_fischgewicht): FischinfoReferenz_fischgewicht {
        return new FischinfoReferenz_fischgewicht(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoReferenz_fischgewicht): FischinfoReferenz_fischgewicht {
        Object.assign(this, obj);
        obj && obj["fischinfoFischartByFischart_id"] && (this.fischinfoFischartByFischart_id = FischinfoFischart.deserialize(obj["fischinfoFischartByFischart_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "bis_10", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public bis_10?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "bis_20", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public bis_20?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "bis_30", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public bis_30?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "bis_40", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public bis_40?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "bis_5", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public bis_5?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "bis_50", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public bis_50?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "bis_60", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public bis_60?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "bis_70", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public bis_70?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "fischart_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public fischart_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "ueber_70", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public ueber_70?: number;

    /**
     * @type {FischinfoFischart}
     */
    @fn.DataMember({ name: "fischinfoFischartByFischart_id", type: FischinfoFischart, nullable: true, memberType: "JOIN", joinProperty: "fischart_id" })
    public fischinfoFischartByFischart_id?: FischinfoFischart;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
