import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoLaengenklassenkrebsergebnis from "./interface";
import FischinfoKrebsart from "./../../fischinfo/krebsart/model";
import FischinfoKrebsuntersuchung from "./../../fischinfo/krebsuntersuchung/model";
@fn.DataModel
export default class FischinfoLaengenklassenkrebsergebnis extends DataModel implements IFischinfoLaengenklassenkrebsergebnis {
    constructor(obj?: object | IFischinfoLaengenklassenkrebsergebnis) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoLaengenklassenkrebsergebnis): FischinfoLaengenklassenkrebsergebnis {
        return new FischinfoLaengenklassenkrebsergebnis(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoLaengenklassenkrebsergebnis): FischinfoLaengenklassenkrebsergebnis {
        Object.assign(this, obj);
        obj && obj["fischinfoKrebsartByKrebsart_id"] && (this.fischinfoKrebsartByKrebsart_id = FischinfoKrebsart.deserialize(obj["fischinfoKrebsartByKrebsart_id"]));
        obj && obj["fischinfoKrebsuntersuchungByKrebsuntersuchung_id"] && (this.fischinfoKrebsuntersuchungByKrebsuntersuchung_id = FischinfoKrebsuntersuchung.deserialize(obj["fischinfoKrebsuntersuchungByKrebsuntersuchung_id"]));
        return this;
    }

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_0_5", type: Number, databaseType: "integer", nullable: false, memberType: "" })
    public anzahl_0_5: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_10_15", type: Number, databaseType: "integer", nullable: false, memberType: "" })
    public anzahl_10_15: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_15_20", type: Number, databaseType: "integer", nullable: false, memberType: "" })
    public anzahl_15_20: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_20_25", type: Number, databaseType: "integer", nullable: false, memberType: "" })
    public anzahl_20_25: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_25_30", type: Number, databaseType: "integer", nullable: false, memberType: "" })
    public anzahl_25_30: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_5_10", type: Number, databaseType: "integer", nullable: false, memberType: "" })
    public anzahl_5_10: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "krebsart_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public krebsart_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "krebsuntersuchung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public krebsuntersuchung_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoKrebsart}
     */
    @fn.DataMember({ name: "fischinfoKrebsartByKrebsart_id", type: FischinfoKrebsart, nullable: true, memberType: "JOIN", joinProperty: "krebsart_id" })
    public fischinfoKrebsartByKrebsart_id?: FischinfoKrebsart;

    /**
     * @type {FischinfoKrebsuntersuchung}
     */
    @fn.DataMember({ name: "fischinfoKrebsuntersuchungByKrebsuntersuchung_id", type: FischinfoKrebsuntersuchung, nullable: true, memberType: "JOIN", joinProperty: "krebsuntersuchung_id" })
    public fischinfoKrebsuntersuchungByKrebsuntersuchung_id?: FischinfoKrebsuntersuchung;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
