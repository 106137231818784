import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoMuschelart from "./interface";
import FischinfoAnzahlmuschelergebnis from "./../../fischinfo/anzahlmuschelergebnis/model";
import FischinfoDetailmuschelergebnis from "./../../fischinfo/detailmuschelergebnis/model";
import FischinfoLaengenklassenmuschelergebnis from "./../../fischinfo/laengenklassenmuschelergebnis/model";
import FischinfoNachweismuschelergebnis from "./../../fischinfo/nachweismuschelergebnis/model";
import FischinfoHaeufigkeitsmuschelergebnis from "./../../fischinfo/haeufigkeitsmuschelergebnis/model";
@fn.DataModel
export default class FischinfoMuschelart extends DataModel implements IFischinfoMuschelart {
    constructor(obj?: object | IFischinfoMuschelart) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoMuschelart): FischinfoMuschelart {
        return new FischinfoMuschelart(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoMuschelart): FischinfoMuschelart {
        Object.assign(this, obj);
        obj && obj["fischinfoAnzahlmuschelergebnisListByForeignMuschelart_id"] && obj["fischinfoAnzahlmuschelergebnisListByForeignMuschelart_id"].length && (this.fischinfoAnzahlmuschelergebnisListByForeignMuschelart_id = obj["fischinfoAnzahlmuschelergebnisListByForeignMuschelart_id"].map(FischinfoAnzahlmuschelergebnis.deserialize));
        obj && obj["fischinfoDetailmuschelergebnisListByForeignMuschelart_id"] && obj["fischinfoDetailmuschelergebnisListByForeignMuschelart_id"].length && (this.fischinfoDetailmuschelergebnisListByForeignMuschelart_id = obj["fischinfoDetailmuschelergebnisListByForeignMuschelart_id"].map(FischinfoDetailmuschelergebnis.deserialize));
        obj && obj["fischinfoLaengenklassenmuschelergebnisListByForeignMuschelart_id"] && obj["fischinfoLaengenklassenmuschelergebnisListByForeignMuschelart_id"].length && (this.fischinfoLaengenklassenmuschelergebnisListByForeignMuschelart_id = obj["fischinfoLaengenklassenmuschelergebnisListByForeignMuschelart_id"].map(FischinfoLaengenklassenmuschelergebnis.deserialize));
        obj && obj["fischinfoNachweismuschelergebnisListByForeignMuschelart_id"] && obj["fischinfoNachweismuschelergebnisListByForeignMuschelart_id"].length && (this.fischinfoNachweismuschelergebnisListByForeignMuschelart_id = obj["fischinfoNachweismuschelergebnisListByForeignMuschelart_id"].map(FischinfoNachweismuschelergebnis.deserialize));
        obj && obj["fischinfoHaeufigkeitsmuschelergebnisListByForeignArt_id"] && obj["fischinfoHaeufigkeitsmuschelergebnisListByForeignArt_id"].length && (this.fischinfoHaeufigkeitsmuschelergebnisListByForeignArt_id = obj["fischinfoHaeufigkeitsmuschelergebnisListByForeignArt_id"].map(FischinfoHaeufigkeitsmuschelergebnis.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "artenkennziffer", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public artenkennziffer?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "dvnr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public dvnr?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "nr_auswertung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public nr_auswertung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "nr_osiris", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public nr_osiris?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "rl_nrw", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public rl_nrw?: string;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "wissenschaftlichebezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public wissenschaftlichebezeichnung?: string;

    /**
     * @type {Array<FischinfoAnzahlmuschelergebnis>}
     */
    @fn.DataMember({ name: "fischinfoAnzahlmuschelergebnisListByForeignMuschelart_id", type: [FischinfoAnzahlmuschelergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoAnzahlmuschelergebnisListByForeignMuschelart_id?: Array<FischinfoAnzahlmuschelergebnis>;

    /**
     * @type {Array<FischinfoDetailmuschelergebnis>}
     */
    @fn.DataMember({ name: "fischinfoDetailmuschelergebnisListByForeignMuschelart_id", type: [FischinfoDetailmuschelergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoDetailmuschelergebnisListByForeignMuschelart_id?: Array<FischinfoDetailmuschelergebnis>;

    /**
     * @type {Array<FischinfoLaengenklassenmuschelergebnis>}
     */
    @fn.DataMember({ name: "fischinfoLaengenklassenmuschelergebnisListByForeignMuschelart_id", type: [FischinfoLaengenklassenmuschelergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoLaengenklassenmuschelergebnisListByForeignMuschelart_id?: Array<FischinfoLaengenklassenmuschelergebnis>;

    /**
     * @type {Array<FischinfoNachweismuschelergebnis>}
     */
    @fn.DataMember({ name: "fischinfoNachweismuschelergebnisListByForeignMuschelart_id", type: [FischinfoNachweismuschelergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoNachweismuschelergebnisListByForeignMuschelart_id?: Array<FischinfoNachweismuschelergebnis>;

    /**
     * @type {Array<FischinfoHaeufigkeitsmuschelergebnis>}
     */
    @fn.DataMember({ name: "fischinfoHaeufigkeitsmuschelergebnisListByForeignArt_id", type: [FischinfoHaeufigkeitsmuschelergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoHaeufigkeitsmuschelergebnisListByForeignArt_id?: Array<FischinfoHaeufigkeitsmuschelergebnis>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
