import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export default class AppService {

    public isFullscreenMode: boolean;
    public isIFrame: boolean;
    public nativeElementValue: any;
    public mode: string;

    public get nativeElement() {
        return this.nativeElementValue;
    }
    public set nativeElement(el: any) {
        this.nativeElementValue = el;
        this.isIFrame = (<Element>this.nativeElementValue)
            .hasAttribute("isIFrame")
            ;
    }
    public get width(): number {
        return this.isIFrame || this.isFullscreenMode
            ? window.innerWidth
            : this.nativeElement.parentNode.offsetWidth;
    }
    public get height(): number {
        return this.isIFrame || this.isFullscreenMode
            ? window.innerHeight
            : this.nativeElement.parentNode.offsetHeight
            ;
    }

    public appIsInViewportPromiseFunc: any;
    public get appIsInViewportPromise(): Promise<boolean> {
        return new Promise((resolve, fail) => {
            this.appIsInViewportPromiseFunc = () => {
                if (this.isAppInViewport()) {
                    resolve(true);
                } else {
                    window.setTimeout(this.appIsInViewportPromiseFunc, 500);
                }
            };
            this.appIsInViewportPromiseFunc();
        });
    }
    public isAppInViewport(): boolean {
        let elem = (<Element>this.nativeElementValue);
        return !!(elem["offsetWidth"] || elem["offsetHeight"] || elem.getClientRects().length);
    }

    public jQueryLibReadyPromiseFunc: any;
    public get jQueryLibReadyPromise(): Promise<boolean> {
        return new Promise((resolve, fail) => {
            this.jQueryLibReadyPromiseFunc = (i: number) => {
                if (window["jQuery"] || window["jquery"]) {
                    resolve(true);
                } else {
                    i < 100 && window.setTimeout(() => { this.jQueryLibReadyPromiseFunc(i ? ++i : 1); }, 200);
                }
            };
            this.jQueryLibReadyPromiseFunc();
        });
    }

    constructor() {
    }
}
