<div class="row">
    <div class="col-xs-15 col-sm-4"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','expositionsdauer')}}"
        [(value)]="expositionsdauer"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>

<div class="row">
    <div class="col-xs-15">
        <label>Liste der Netzmaße</label>
        <table class="table table-striped fischinfoNetzList inherit-inputs">
            <thead>
                <tr>
                    <th [style.width.%]="12" title="Höhe (m)">Höhe (m) <sup *ngIf="!readonly"><i class="fa fa-exclamation-circle" title="Die Angabe Netzhöhe ist eine Pflichtangabe."></i></sup></th>
                    <th [style.width.%]="12" title="Länge (m)">Länge (m) <sup *ngIf="!readonly"><i class="fa fa-exclamation-circle" title="Die Angabe Netzlänge ist eine Pflichtangabe."></i></sup></th>
                    <th [style.width.%]="25" title="Maschenweite von (mm)">Maschenweite von (mm)</th>
                    <th [style.width.%]="12" title="bis (mm)">bis (mm)</th>
                    <th  title="Netzposition">Netzposition</th>
                    <th *ngIf="!readonly" [style.width.px]="24"></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    form-fischinfo-untersuchung-befischung-methode-netz-angabe
                    *ngFor="let u of fischinfoNetzList; let i = index"
                    [modalId]="modalId"
                    [readonly]="readonly"
                    (changed)="onChangedMethodenetzangabe($event, u, i)"
                    [untersuchung]="untersuchung"
                    [netz]="u"
                ></tr>
                <tr
                    form-fischinfo-untersuchung-befischung-methode-netz-angabe
                    *ngIf="readonly && !fischinfoNetzList?.length"
                    [modalId]="modalId"
                    [readonly]="readonly"
                ></tr>
            </tbody>
        </table>
        <uw-inline-button
            *ngIf="!readonly && firstIsSet"
            iconClass="fa fa-plus-circle"
            title="Weiteres Netzmaß hinzufügen"
            value="Weiteres Netzmaß hinzufügen"
            (click)="addModel($event)"
        ></uw-inline-button>
    </div>
</div>
