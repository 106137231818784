import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoAuftrag from "./interface";
import FischinfoAuftragprobenahmestelle from "./../../fischinfo/auftragprobenahmestelle/model";
@fn.DataModel
export default class FischinfoAuftrag extends DataModel implements IFischinfoAuftrag {
    constructor(obj?: object | IFischinfoAuftrag) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoAuftrag): FischinfoAuftrag {
        return new FischinfoAuftrag(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoAuftrag): FischinfoAuftrag {
        Object.assign(this, obj);
        obj && obj["fischinfoAuftragprobenahmestelleListByForeignAuftrag_id"] && obj["fischinfoAuftragprobenahmestelleListByForeignAuftrag_id"].length && (this.fischinfoAuftragprobenahmestelleListByForeignAuftrag_id = obj["fischinfoAuftragprobenahmestelleListByForeignAuftrag_id"].map(FischinfoAuftragprobenahmestelle.deserialize));
        return this;
    }

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "abgeschlossen", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public abgeschlossen?: boolean;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "befischungsanlass", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public befischungsanlass?: string;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "befischungszeitraumbeginn", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public befischungszeitraumbeginn?: number;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "befischungszeitraumende", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public befischungszeitraumende?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "benutzer_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public benutzer_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    @fn.Table({ columnIndex: 3 })
    @fn.Label("Bezeichnung")
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    @fn.Table({ columnIndex: 1, align: "right", sortType: "number" })
    @fn.Label("ID")
    public id: number;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "untersuchungstermin", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public untersuchungstermin?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoAuftragprobenahmestelle>}
     */
    @fn.DataMember({ name: "fischinfoAuftragprobenahmestelleListByForeignAuftrag_id", type: [FischinfoAuftragprobenahmestelle], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoAuftragprobenahmestelleListByForeignAuftrag_id?: Array<FischinfoAuftragprobenahmestelle>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW
    @fn.Table({ align: "center", sortBy: "id", sortType: "string" })
    @fn.Label("Metafeld")
    public get metafeld() {
        return this.bezeichnung + " " + (this.abgeschlossen ? "√" : "X");
    }

}
