import { Component, Input, Renderer2, Optional, Inject } from "@angular/core";
import SuggestionFactoryService from "../factory.service";
import UWAbstractSelect from "../abstract";
const IconClasses = ["fa fa-square-o", "fa fa-check-square"];
/**
 * Diese Eingabekomponente stellt eine Auswahliste ähnlich HTML-<select> dar.
 *
 * Die Komponente unterscheidet sich von HTML-<select> in dem, dass die Auswahlliste immer als schwebendes Element angezeigt wird. Der Nutzer kann mehrere Optionen aus der Liste auswählen.
 */
@Component({
    selector: "uw-multi-select",
    templateUrl: "./default.component.html",
    styleUrls: [
        "../default.component.less",
        "./default.component.less",
    ],
    /**
     *  local scope service, one instance for each component:
     * */
    providers: [
        SuggestionFactoryService
    ]
})
export default class UWMultiSelect extends UWAbstractSelect<Array<string>> {
    public inputKey = [];
    public IconClasses = IconClasses;
    public nullable = true;
    public get key(): Array<string> { return this.inputKey; }
    @Input()
    public set key(value: Array<string>) {
        if (value instanceof Array) {
            this.inputKey = value;
        }
        else if (value === null) {
            this.inputKey = [];
        }
        // value can actually be also string
        else {
            const idx = this.key.indexOf(value);
            if (idx === -1) {
                this.inputKey.push(value);
            }
            else {
                this.inputKey.splice(idx, 1);
            }
        }
        this.setValueByKey();
        this.changeOnInput();
        this.keyChange.emit(this.inputKey);
        if (this.changeEventInitFlag) {
            this.change.emit(this);
        }
    }
    constructor(
        suggestionFactoryService: SuggestionFactoryService,
        renderer: Renderer2,
        @Optional() @Inject("UWOverlay") overlayFactory: any,
    ) {
        super(suggestionFactoryService, renderer, overlayFactory);
    }
    public get allIsSelected(): boolean {
        return this.suggestions != null && this.key.length === this.suggestions.length;
    }
    public get anyIsSelected(): boolean {
        return this.suggestions != null && this.key.length > 0;
    }
    public onClickToggleAll(ev: MouseEvent) {
        ev.stopPropagation();
        this.toggleAll();
    }
    public toggleAll() {
        this.key = this.allIsSelected ? null
            : this.suggestions.map(s => s.key);
    }
    public suggestionIconClass(selected: boolean) {
        return this.IconClasses[Number(selected)];
    }
    public isSelected(suggestion) {
        return this.key.indexOf(suggestion.key) > -1;
    }
    public setValueByKey(): void {
        let newValue = null;
        const getValue = (s) => s != null ? s.value : s;
        if (this.suggestions && this.suggestions.length) {
            newValue = (<Array<string>>this.key || [])
                .map(k => getValue(this.suggestions.find(sugg => sugg.key === k)));
            if (newValue.length === 0) {
                newValue = null;
            }
        }
        this.value = newValue !== null ? newValue.join(", ") : this.placeholder;
    }
}
