import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoGewaesserzustandumland from "./interface";
import FischinfoGewaesserzustand from "./../../fischinfo/gewaesserzustand/model";
import FischinfoUmland from "./../../fischinfo/umland/model";
@fn.DataModel
export default class FischinfoGewaesserzustandumland extends DataModel implements IFischinfoGewaesserzustandumland {
    constructor(obj?: object | IFischinfoGewaesserzustandumland) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoGewaesserzustandumland): FischinfoGewaesserzustandumland {
        return new FischinfoGewaesserzustandumland(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoGewaesserzustandumland): FischinfoGewaesserzustandumland {
        Object.assign(this, obj);
        obj && obj["fischinfoGewaesserzustandByGewaesserzustand_id"] && (this.fischinfoGewaesserzustandByGewaesserzustand_id = FischinfoGewaesserzustand.deserialize(obj["fischinfoGewaesserzustandByGewaesserzustand_id"]));
        obj && obj["fischinfoUmlandByUmland_id"] && (this.fischinfoUmlandByUmland_id = FischinfoUmland.deserialize(obj["fischinfoUmlandByUmland_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gewaesserzustand_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public gewaesserzustand_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "umland_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public umland_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoGewaesserzustand}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandByGewaesserzustand_id", type: FischinfoGewaesserzustand, nullable: true, memberType: "JOIN", joinProperty: "gewaesserzustand_id" })
    public fischinfoGewaesserzustandByGewaesserzustand_id?: FischinfoGewaesserzustand;

    /**
     * @type {FischinfoUmland}
     */
    @fn.DataMember({ name: "fischinfoUmlandByUmland_id", type: FischinfoUmland, nullable: true, memberType: "JOIN", joinProperty: "umland_id" })
    public fischinfoUmlandByUmland_id?: FischinfoUmland;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
