import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoGesundheitsuntersuchungfgd from "./interface";
import FischinfoBesatzereignis from "./../../fischinfo/besatzereignis/model";
@fn.DataModel
export default class FischinfoGesundheitsuntersuchungfgd extends DataModel implements IFischinfoGesundheitsuntersuchungfgd {
    constructor(obj?: object | IFischinfoGesundheitsuntersuchungfgd) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoGesundheitsuntersuchungfgd): FischinfoGesundheitsuntersuchungfgd {
        return new FischinfoGesundheitsuntersuchungfgd(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoGesundheitsuntersuchungfgd): FischinfoGesundheitsuntersuchungfgd {
        Object.assign(this, obj);
        obj && obj["fischinfoBesatzereignisListByForeignUntersuchungfgd_id"] && obj["fischinfoBesatzereignisListByForeignUntersuchungfgd_id"].length && (this.fischinfoBesatzereignisListByForeignUntersuchungfgd_id = obj["fischinfoBesatzereignisListByForeignUntersuchungfgd_id"].map(FischinfoBesatzereignis.deserialize));
        return this;
    }

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "acrassusprozent", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public acrassusprozent?: number;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "datumuntersuchung", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public datumuntersuchung?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "ergebnisgenetischekontrolle", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public ergebnisgenetischekontrolle?: string;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "genetischekontrolle", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public genetischekontrolle?: boolean;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "hvaprozent", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public hvaprozent?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "untersuchungdurch", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public untersuchungdurch?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "untersuchungwas", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public untersuchungwas?: string;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoBesatzereignis>}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisListByForeignUntersuchungfgd_id", type: [FischinfoBesatzereignis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzereignisListByForeignUntersuchungfgd_id?: Array<FischinfoBesatzereignis>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
