import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoKrebsart from "./interface";
import FischinfoHaeufigkeitkrebsergebnis from "./../../fischinfo/haeufigkeitkrebsergebnis/model";
import FischinfoLaengenklassenkrebsergebnis from "./../../fischinfo/laengenklassenkrebsergebnis/model";
import FischinfoDetailkrebsergebnis from "./../../fischinfo/detailkrebsergebnis/model";
import FischinfoAnzahlkrebsergebnis from "./../../fischinfo/anzahlkrebsergebnis/model";
import FischinfoNachweiskrebsergebnis from "./../../fischinfo/nachweiskrebsergebnis/model";
@fn.DataModel
export default class FischinfoKrebsart extends DataModel implements IFischinfoKrebsart {
    constructor(obj?: object | IFischinfoKrebsart) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoKrebsart): FischinfoKrebsart {
        return new FischinfoKrebsart(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoKrebsart): FischinfoKrebsart {
        Object.assign(this, obj);
        obj && obj["fischinfoHaeufigkeitkrebsergebnisListByForeignArt_id"] && obj["fischinfoHaeufigkeitkrebsergebnisListByForeignArt_id"].length && (this.fischinfoHaeufigkeitkrebsergebnisListByForeignArt_id = obj["fischinfoHaeufigkeitkrebsergebnisListByForeignArt_id"].map(FischinfoHaeufigkeitkrebsergebnis.deserialize));
        obj && obj["fischinfoLaengenklassenkrebsergebnisListByForeignKrebsart_id"] && obj["fischinfoLaengenklassenkrebsergebnisListByForeignKrebsart_id"].length && (this.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsart_id = obj["fischinfoLaengenklassenkrebsergebnisListByForeignKrebsart_id"].map(FischinfoLaengenklassenkrebsergebnis.deserialize));
        obj && obj["fischinfoDetailkrebsergebnisListByForeignKrebsart_id"] && obj["fischinfoDetailkrebsergebnisListByForeignKrebsart_id"].length && (this.fischinfoDetailkrebsergebnisListByForeignKrebsart_id = obj["fischinfoDetailkrebsergebnisListByForeignKrebsart_id"].map(FischinfoDetailkrebsergebnis.deserialize));
        obj && obj["fischinfoAnzahlkrebsergebnisListByForeignKrebsart_id"] && obj["fischinfoAnzahlkrebsergebnisListByForeignKrebsart_id"].length && (this.fischinfoAnzahlkrebsergebnisListByForeignKrebsart_id = obj["fischinfoAnzahlkrebsergebnisListByForeignKrebsart_id"].map(FischinfoAnzahlkrebsergebnis.deserialize));
        obj && obj["fischinfoNachweiskrebsergebnisListByForeignKrebsart_id"] && obj["fischinfoNachweiskrebsergebnisListByForeignKrebsart_id"].length && (this.fischinfoNachweiskrebsergebnisListByForeignKrebsart_id = obj["fischinfoNachweiskrebsergebnisListByForeignKrebsart_id"].map(FischinfoNachweiskrebsergebnis.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "artenkennziffer", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public artenkennziffer?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "dvnr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public dvnr?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "nr_auswertung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public nr_auswertung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "nr_osiris", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public nr_osiris?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "rl_nrw", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public rl_nrw?: string;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "wissenschaftlichebezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public wissenschaftlichebezeichnung?: string;

    /**
     * @type {Array<FischinfoHaeufigkeitkrebsergebnis>}
     */
    @fn.DataMember({ name: "fischinfoHaeufigkeitkrebsergebnisListByForeignArt_id", type: [FischinfoHaeufigkeitkrebsergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoHaeufigkeitkrebsergebnisListByForeignArt_id?: Array<FischinfoHaeufigkeitkrebsergebnis>;

    /**
     * @type {Array<FischinfoLaengenklassenkrebsergebnis>}
     */
    @fn.DataMember({ name: "fischinfoLaengenklassenkrebsergebnisListByForeignKrebsart_id", type: [FischinfoLaengenklassenkrebsergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoLaengenklassenkrebsergebnisListByForeignKrebsart_id?: Array<FischinfoLaengenklassenkrebsergebnis>;

    /**
     * @type {Array<FischinfoDetailkrebsergebnis>}
     */
    @fn.DataMember({ name: "fischinfoDetailkrebsergebnisListByForeignKrebsart_id", type: [FischinfoDetailkrebsergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoDetailkrebsergebnisListByForeignKrebsart_id?: Array<FischinfoDetailkrebsergebnis>;

    /**
     * @type {Array<FischinfoAnzahlkrebsergebnis>}
     */
    @fn.DataMember({ name: "fischinfoAnzahlkrebsergebnisListByForeignKrebsart_id", type: [FischinfoAnzahlkrebsergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoAnzahlkrebsergebnisListByForeignKrebsart_id?: Array<FischinfoAnzahlkrebsergebnis>;

    /**
     * @type {Array<FischinfoNachweiskrebsergebnis>}
     */
    @fn.DataMember({ name: "fischinfoNachweiskrebsergebnisListByForeignKrebsart_id", type: [FischinfoNachweiskrebsergebnis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoNachweiskrebsergebnisListByForeignKrebsart_id?: Array<FischinfoNachweiskrebsergebnis>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
