<div
    class="row table-accordion-pane-article"
    [style.overflow]="!active ? 'hidden' : 'auto'"
    [@growInOut]="animationState"
    (@growInOut.start)="animationStarted($event)"
    (@growInOut.done)="animationDone($event)"
>
    <div
        [style.min-height]="bodyHeight + 'px'"
    >
        <div
            class="col-md-15"
            [style.padding-bottom.px]="footerHeight+paddingBottom"
            [style.padding-top.px]="paddingTop"
        >
            <ng-content select="article"></ng-content>
        </div>
        <div 
            *ngIf="active && footerHeight>0"
            class="container-fluid table-accordion-pane-footer"
            [@fadeIn]="true"
        >
            <ng-content select="footer"></ng-content>
        </div>
    </div>
</div>

