<div class="uw-login" [style.width]="width">
    <ng-container *ngIf="mode=='login'">
        <form (submit)="submitOnForm($event)">
            <h2 *ngIf="label.length">{{label}}</h2>
            <uw-textinput
                class="uw-login-name"
                type="text"
                [placeholder]="namePlaceholder"
                [(value)]="username"
            ></uw-textinput>
            <uw-textinput
                class="uw-login-pass"
                type="password"
                [placeholder]="passPlaceholder"
                [(value)]="password"
                [valid]="!lastLoginFailed"
                (validate)="$event.target.valid = !lastLoginFailed"
                validationError="Bitte überprüfen Sie die Eingaben Ihrer Zugangsdaten"
            ></uw-textinput>
            <label class="uw-login-stores">
                <input type="checkbox"
                    [checked]="storesLoginData"
                    (change)="setStoresLoginDataFromEvent($event)"
                />
                <span>{{storesLabel}}</span>
            </label>
            <div class="uw-login-buttons">
                <button *ngIf="submitLabel.length" type="submit" class="uw-login-button">{{submitLabel}}</button>
                <button *ngIf="registrationLabel.length && withRegistration.length" (click)="register()" type="button" class="uw-login-button">{{registrationLabel}}</button>
            </div>
        </form>
        <p *ngIf="lastLoginFailed" class="uw-login-error-text">
            <i class="fa fa-exclamation-triangle"></i>
            <ng-container *ngIf="useContentAs !== 'error'">
                Bitte geben Sie zur Anmeldung Ihren Benutzernamen und Zugangspasswort ein und überprüfen gegebenenfalls die Korrektheit der Eingaben.
            </ng-container>
            <ng-content *ngIf="useContentAs === 'error'"></ng-content>
        </p>
        <p *ngIf="lastRegistrationMessage.length" class="uw-login-message-text">
                {{lastRegistrationMessage}}
        </p>
     </ng-container>

    <ng-container *ngIf="mode=='registration' && !lastRegistrationMessage.length">
        <form (submit)="submitOnRegistrationForm($event)">
            <h2 *ngIf="labelRegistration.length">{{labelRegistration}}</h2>
            <p *ngIf="descriptionRegistration.length">{{descriptionRegistration}}</p>
            <uw-textinput
                    class="uw-login-text"
                    type="text"
                    [placeholder]="realnamePlaceholder"
                    [(value)]="realname"
                    width="60%"
            ></uw-textinput>
            <uw-textinput
                    class="uw-login-text"
                    type="text"
                    [placeholder]="surnamePlaceholder"
                    [(value)]="surname"
            ></uw-textinput>
            <uw-textinput
                    class="uw-login-text"
                    type="text"
                    [placeholder]="emailPlaceholder"
                    [(value)]="registrationEmail"
            ></uw-textinput>
            <uw-textinput
                    class="uw-login-pass"
                    type="password"
                    [placeholder]="passPlaceholder"
                    [validationError]="passValidationError"
                    [valid]="passwordsAreMatching"
                    [(value)]="registrationPassword"
                    (validate)="validatePasswords($event)"
            ></uw-textinput>
            <uw-textinput
                    class="uw-login-checkpass"
                    type="password"
                    [placeholder]="passCheckPlaceholder"
                    [validationError]="passValidationError"
                    [valid]="passwordsAreMatching"
                    [(value)]="registrationPasswordCheck"
                    (validate)="validatePasswords($event)"
            ></uw-textinput>
            <label class="uw-login-dataprotect">
                <input type="checkbox"
                    [checked]="dataProtectAccepted"
                    (change)="setDataProtectAcceptedFromEvent($event)"
                />
                <span>Ich habe die Hinweise zum
                    <a [href]="dataProtectHref" title="Datenschutz" target="_blank">Datenschutz</a>
                    gelesen und erkläre mich mit ihnen einverstanden.
                </span>
            </label>
            <div class="uw-login-buttons">
                <button *ngIf="!waitingForRegistrationMessage && registrationSendLabel.length && withRegistration.length" (click)="register()" type="button"  class="uw-login-button">{{registrationSendLabel}}</button>
                <button *ngIf="registrationCancelLabel.length" (click)="login()" type="button" class="uw-login-button">{{registrationCancelLabel}}</button>
            </div>
        </form>
        <p *ngIf="waitingForRegistrationMessage" class="uw-login-message-text">
            Bitte warten...
        </p>
        <p *ngIf="lastRegistrationError.length" class="uw-login-error-text">
            <i class="fa fa-exclamation-triangle"></i>
            {{lastRegistrationError}}
        </p>
    </ng-container> 
</div>