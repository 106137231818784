<div 
    *ngIf="!readonly"
    class="row"
    style="padding-top:10px;"
>
    <div class="col-xs-15">
        <label>Wählen Sie eine der fünf Häufigkeitsklassen zur Art aus</label>
    </div>
</div>

<div class="row haeufigkeit" style="padding-top:10px;">
    <div class="col-xs-15">
        <uw-radiobutton-group
            (change)="onHaeufigkeitChange($event)"
            [(value)]="haeufigkeit"
            [disabled]="readonly"
            label="Häufigkeit (geschätzt)"
        >
            <uw-radiobutton 
                value="1"
                label="1"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="2"
                label="2"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="3"
                label="3"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="4"
                label="4"
                labelAt="e"
            ></uw-radiobutton>
            <uw-radiobutton 
                value="5"
                label="5"
                labelAt="e"
            ></uw-radiobutton>
        </uw-radiobutton-group>
    </div>
</div>
