import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoProbenahmestelle from "./interface";
import FischinfoFischgewaessertyp from "./../../fischinfo/fischgewaessertyp/model";
import FischinfoGemeinde from "./../../fischinfo/gemeinde/model";
import FischinfoGewaesser from "./../../fischinfo/gewaesser/model";
import FischinfoUntersuchung from "./../../fischinfo/untersuchung/model";
import FischinfoAuftragprobenahmestelle from "./../../fischinfo/auftragprobenahmestelle/model";
import FischinfoNatura2000 from "./../../fischinfo/natura2000/model";
import FischinfoOberflaechenwasserkoerper from "./../../fischinfo/oberflaechenwasserkoerper/model";
import FischinfoStillgewaessertyp from "./../../fischinfo/stillgewaessertyp/model";
@fn.DataModel
export default class FischinfoProbenahmestelle extends DataModel implements IFischinfoProbenahmestelle {
    constructor(obj?: object | IFischinfoProbenahmestelle) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoProbenahmestelle): FischinfoProbenahmestelle {
        return new FischinfoProbenahmestelle(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoProbenahmestelle): FischinfoProbenahmestelle {
        if (obj.hasOwnProperty("e")) {
            Object.assign(this, {
                abgeschlossen: obj["a"],
                etrs89e: obj["b"],
                etrs89n: obj["c"],
                freigegeben: obj["d"],
                id: obj["e"],
                probestellennr: obj["f"],
                stationierung: obj["g"],
                stillgewaesser: obj["h"],
                benutzer_id: obj["i"],
                fischinfoUntersuchungListByForeignProbenahmestelle_id: obj["u"] && obj["u"].length
                    ? obj["u"].map(FischinfoUntersuchung.deserialize)
                    : null,
                fischinfoGewaesserByGewaesser_id: obj["v"]
                    ? FischinfoGewaesser.deserialize(obj["v"])
                    : null,
                fischinfoStillgewaessertypByStillgewaessertyp_id: obj["w"]
                    ? FischinfoStillgewaessertyp.deserialize(obj["w"])
                    : null
            });
        } else {
            Object.assign(this, obj);
            obj && obj["fischinfoFischgewaessertypByFischgewaessertyp_id"] && (this.fischinfoFischgewaessertypByFischgewaessertyp_id = FischinfoFischgewaessertyp.deserialize(obj["fischinfoFischgewaessertypByFischgewaessertyp_id"]));
            obj && obj["fischinfoGemeindeByGemeinde_id"] && (this.fischinfoGemeindeByGemeinde_id = FischinfoGemeinde.deserialize(obj["fischinfoGemeindeByGemeinde_id"]));
            obj && obj["fischinfoGewaesserByGewaesser_id"] && (this.fischinfoGewaesserByGewaesser_id = FischinfoGewaesser.deserialize(obj["fischinfoGewaesserByGewaesser_id"]));
            obj && obj["fischinfoUntersuchungListByForeignProbenahmestelle_id"] && obj["fischinfoUntersuchungListByForeignProbenahmestelle_id"].length && (this.fischinfoUntersuchungListByForeignProbenahmestelle_id = obj["fischinfoUntersuchungListByForeignProbenahmestelle_id"].map(FischinfoUntersuchung.deserialize));
            obj && obj["fischinfoAuftragprobenahmestelleListByForeignProbenahmestelle_id"] && obj["fischinfoAuftragprobenahmestelleListByForeignProbenahmestelle_id"].length && (this.fischinfoAuftragprobenahmestelleListByForeignProbenahmestelle_id = obj["fischinfoAuftragprobenahmestelleListByForeignProbenahmestelle_id"].map(FischinfoAuftragprobenahmestelle.deserialize));
            obj && obj["fischinfoNatura2000ByNatura2000_id"] && (this.fischinfoNatura2000ByNatura2000_id = FischinfoNatura2000.deserialize(obj["fischinfoNatura2000ByNatura2000_id"]));
            obj && obj["fischinfoOberflaechenwasserkoerperByOberflaechenwasserkoerper_id"] && (this.fischinfoOberflaechenwasserkoerperByOberflaechenwasserkoerper_id = FischinfoOberflaechenwasserkoerper.deserialize(obj["fischinfoOberflaechenwasserkoerperByOberflaechenwasserkoerper_id"]));
            obj && obj["fischinfoStillgewaessertypByStillgewaessertyp_id"] && (this.fischinfoStillgewaessertypByStillgewaessertyp_id = FischinfoStillgewaessertyp.deserialize(obj["fischinfoStillgewaessertypByStillgewaessertyp_id"]));
        }
        return this;
    }

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "abgeschlossen", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public abgeschlossen?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "benutzer_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public benutzer_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "efnr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public efnr?: string;

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "etrs89e", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public etrs89e: number;

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "etrs89n", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public etrs89n: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "fischgewaessertyp_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public fischgewaessertyp_id?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "freigegeben", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    @fn.Label("Freigegeben")
    public freigegeben?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gemeinde_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public gemeinde_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gewaesser_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public gewaesser_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gewaesseralias", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gewaesseralias?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gsk_auflage", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gsk_auflage?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gsk_version", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gsk_version?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "guesart", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    @fn.Label("Art der GÜS-Messstelle")
    public guesart?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "guesnr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    @fn.Label("GÜS-Nr.")
    public guesnr?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "lagebeschreibung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    @fn.Label("Lagebeschreibung")
    public lagebeschreibung?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "natura2000_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public natura2000_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oberflaechenwasserkoerper_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public oberflaechenwasserkoerper_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "probestellennr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "UNIQUE" })
    @fn.Label("PS-Nr.")
    public probestellennr?: string;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "stationierung", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Stationierung")
    public stationierung?: number;

    public _stillgewaesser?: boolean;
    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "stillgewaesser", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public get stillgewaesser(): boolean {
        return this._stillgewaesser;
    }
    public set stillgewaesser(value: boolean) {
        this._stillgewaesser = value
            ? true
            : false
            ;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "stillgewaessertyp_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public stillgewaessertyp_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "vorflutergkz", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public vorflutergkz?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "vorflutername", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public vorflutername?: string;

    /**
     * @type {FischinfoFischgewaessertyp}
     */
    @fn.DataMember({ name: "fischinfoFischgewaessertypByFischgewaessertyp_id", type: FischinfoFischgewaessertyp, nullable: true, memberType: "JOIN", joinProperty: "fischgewaessertyp_id" })
    public fischinfoFischgewaessertypByFischgewaessertyp_id?: FischinfoFischgewaessertyp;

    /**
     * @type {FischinfoGemeinde}
     */
    @fn.Label("Gemeinde")
    @fn.DataMember({ name: "fischinfoGemeindeByGemeinde_id", type: FischinfoGemeinde, nullable: true, memberType: "JOIN", joinProperty: "gemeinde_id" })
    public fischinfoGemeindeByGemeinde_id?: FischinfoGemeinde;

    /**
     * @type {FischinfoGewaesser}
     */
    @fn.DataMember({ name: "fischinfoGewaesserByGewaesser_id", type: FischinfoGewaesser, nullable: true, memberType: "JOIN", joinProperty: "gewaesser_id" })
    public fischinfoGewaesserByGewaesser_id?: FischinfoGewaesser;

    /**
     * @type {Array<FischinfoUntersuchung>}
     */
    @fn.DataMember({ name: "fischinfoUntersuchungListByForeignProbenahmestelle_id", type: [FischinfoUntersuchung], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoUntersuchungListByForeignProbenahmestelle_id?: Array<FischinfoUntersuchung>;

    /**
     * @type {Array<FischinfoAuftragprobenahmestelle>}
     */
    @fn.DataMember({ name: "fischinfoAuftragprobenahmestelleListByForeignProbenahmestelle_id", type: [FischinfoAuftragprobenahmestelle], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoAuftragprobenahmestelleListByForeignProbenahmestelle_id?: Array<FischinfoAuftragprobenahmestelle>;

    /**
     * @type {FischinfoNatura2000}
     */
    @fn.DataMember({ name: "fischinfoNatura2000ByNatura2000_id", type: FischinfoNatura2000, nullable: true, memberType: "JOIN", joinProperty: "natura2000_id" })
    public fischinfoNatura2000ByNatura2000_id?: FischinfoNatura2000;

    /**
     * @type {FischinfoOberflaechenwasserkoerper}
     */
    @fn.DataMember({ name: "fischinfoOberflaechenwasserkoerperByOberflaechenwasserkoerper_id", type: FischinfoOberflaechenwasserkoerper, nullable: true, memberType: "JOIN", joinProperty: "oberflaechenwasserkoerper_id" })
    public fischinfoOberflaechenwasserkoerperByOberflaechenwasserkoerper_id?: FischinfoOberflaechenwasserkoerper;

    /**
     * @type {FischinfoStillgewaessertyp}
     */
    @fn.DataMember({ name: "fischinfoStillgewaessertypByStillgewaessertyp_id", type: FischinfoStillgewaessertyp, nullable: true, memberType: "JOIN", joinProperty: "stillgewaessertyp_id" })
    public fischinfoStillgewaessertypByStillgewaessertyp_id?: FischinfoStillgewaessertyp;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

    @fn.Table({ columnIndex: 1, sortBy: "probestellennrSortString" })
    @fn.Label("PS-Nr.")
    @fn.CSV({ type: "varchar(254)" })
    @fn.SHP({ type: "varchar(254)" })
    public get probestellennrAsString(): string {
        return (this.probestellennr && this.probestellennr.length)
            ? this.probestellennr
            : "N.N."
            ;
    }

    public get probestellennrSortString(): string {
        const padchar = "0";
        const lpad = (s, w, c) => new Array(w - s.length).fill(c).join("") + s;
        const parts = this.probestellennr && this.probestellennr.split("-");
        if (parts) {
            parts[1] = lpad(parts[1], 3, padchar);
            parts[2] = lpad(parts[2], 5, padchar);
            return parts.join("");
        }
        else return "";
    }

    @fn.Table({ columnIndex: 2, columnPercentualWidth: 25 })
    @fn.Label("Gewässername")
    @fn.CSV({ type: "varchar(254)" })
    @fn.SHP({ type: "varchar(254)" })
    public get gewaessernameAsString(): string {
        const hasGewaesserAlias = this.gewaesseralias && this.gewaesseralias.length && this.gewaesseralias !== "N.N.";
        const hasGewaesser = this.fischinfoGewaesserByGewaesser_id;
        const gewaesser = (this.fischinfoGewaesserByGewaesser_id && this.fischinfoGewaesserByGewaesser_id.gewaessername) || "N.N.";
        return hasGewaesserAlias
            ? this.gewaesseralias + (
                (hasGewaesser && this.gewaesseralias !== gewaesser)
                    ? " (" + gewaesser + ")"
                    : ""
            )
            : gewaesser
            ;
    }

    @fn.Table({ columnIndex: 3, sortType: "number", align: "right" })
    @fn.Label("Gewässerkennzahl")
    @fn.CSV({ type: "int" })
    @fn.SHP({ type: "int" })
    public get gewaesserkennzahlAsNumber(): number {
        return this.fischinfoGewaesserByGewaesser_id && Number(
            this.fischinfoGewaesserByGewaesser_id.stillgewaesser
                ? this.fischinfoGewaesserByGewaesser_id.seenkennzahl
                : this.fischinfoGewaesserByGewaesser_id.gewaesserkennzahl
        ) || null;
    }

    @fn.Table({ columnIndex: 4, sortType: "string", align: "right", columnPercentualWidth: 12 })
    @fn.CSV({ type: "varchar(4)" })
    @fn.SHP({ type: "varchar(4)" })
    @fn.Label("Gewässer-Typ")
    public get gewaessertyp(): string {
        const itemAttr = this.stillgewaesser === true || this.stillgewaesser === false;
        return itemAttr
            ? this.stillgewaesser
                ? "SG"
                : "FG"
            : this.stillgewaessertyp_id
                ? this.fischinfoStillgewaessertypByStillgewaessertyp_id.stillgewaesser
                    ? "SG"
                    : "FG"
                : this.fischinfoGewaesserByGewaesser_id
                    ? this.fischinfoGewaesserByGewaesser_id.stillgewaesser
                        ? "SG"
                        : "FG"
                    : "N.N."
            ;
    }

    @fn.Table({ dependsOnFilter: "isNotExtendedView", columnIndex: 6, sortType: "number", align: "right", columnPercentualWidth: 5 })
    @fn.CSV({ type: "int" })
    @fn.SHP({ type: "int" })
    @fn.Label("Anzahl der Untersuchungen")
    public get safeUntersuchungCount(): number {
        return this.fischinfoUntersuchungListByForeignProbenahmestelle_id
            ? this.fischinfoUntersuchungListByForeignProbenahmestelle_id
                .filter(
                    x => !x.unsicher
                        && x.freigegeben
                )
                .length
            : 0
            ;
    }

    @fn.Table({ dependsOnFilter: "isExtendedView", columnIndex: 7, sortType: "number", align: "right", columnPercentualWidth: 5 })
    @fn.CSV({ type: "int" })
    @fn.SHP({ type: "int" })
    @fn.Label("Anzahl der Untersuchungen")
    public get untersuchungCount(): number {
        return this.fischinfoUntersuchungListByForeignProbenahmestelle_id
            ? this.fischinfoUntersuchungListByForeignProbenahmestelle_id
                .length
            : 0
            ;
    }

    @fn.Table({ label: "Gewässerstationierung", columnIndex: 5, sortType: "number", sortBy: "stationierung", align: "right" })
    @fn.Label("Stationierung")
    @fn.CSV({ type: "varchar(254)" })
    @fn.SHP({ type: "varchar(254)" })
    public get stationierungAsInt(): number {
        return parseInt(String(this.stationierung), 10) || null;
    }

    public?: number;

    @fn.Label("UTM 32 Koordinaten")
    public get utm32koordinatenAsString(): string {
        return 'E ' + (this.etrs89e && this.etrs89e.toFixed(0))
            + ' N ' + (this.etrs89n && this.etrs89n.toFixed(0))
            + ' ETRS89 / UTM zone 32N';
    }

    @fn.Label("Gemeinde (Bezirk, Kreis)")
    public get gemeindeNameGkz(): string {
        const gemeinde = this.fischinfoGemeindeByGemeinde_id;
        const gemeindename = gemeinde && gemeinde.gemeindename;
        const kreis = gemeinde && gemeinde.fischinfoKreisByKreis_id;
        const kreisname = kreis && kreis.kreisname;
        const bezirk = gemeinde && gemeinde.fischinfoBezirkByBezirk_id;
        const bezirkname = bezirk && bezirk.bezirkname;
        return (gemeindename || "") + (gemeindename && bezirkname && kreisname ? ` (${bezirkname}, ${kreisname})` : "");
    }

    @fn.Label("Gewässertyp")
    public get gewaessertypName(): string {
        return this.fischinfoStillgewaessertypByStillgewaessertyp_id
            && this.fischinfoStillgewaessertypByStillgewaessertyp_id.bezeichnung
            || "";
    }

    @fn.Label("Fischgewässertyp")
    public get fischgewaessertypName(): string {
        return this.fischinfoFischgewaessertypByFischgewaessertyp_id
            && this.fischinfoFischgewaessertypByFischgewaessertyp_id.bezeichnung
            || "";
    }

    @fn.Label("Gewässername (GKZ)")
    public get gewaesserNameGkz(): string {
        const { gewaessernameAsString: gn, gewaesserkennzahlAsNumber: gkz } = this;
        return (gn || "") + (gn && gkz ? ` (${gkz})` : "");
    }

    @fn.Label("Vorfluter (GKZ)")
    public get vorfluterNameGkz(): string {
        const { vorflutername: vn, vorflutergkz: vkz } = this;
        return (vn || "") + (vn && vkz ? ` (${vkz})` : "");
    }

    public _fischhoep: string;

    @fn.Label("Fisch-HÖP")
    public get fischhoep(): string {
        return this._fischhoep;
    }
    public set fischhoep(value: string) {
        this._fischhoep = value;
    }

    @fn.Label("Fallgruppe")
    public get hmwbnutzungKuerzel(): string {
        const ofwk = this.fischinfoOberflaechenwasserkoerperByOberflaechenwasserkoerper_id;
        const hmwb = ofwk && ofwk.fibsHmwbnutzungByHmwbnutzung_id;
        return hmwb && hmwb.kuerzel || "";
    }

    @fn.Label("Ausweisung")
    public get ofwkAusweisung(): string {
        const ofwk = this.fischinfoOberflaechenwasserkoerperByOberflaechenwasserkoerper_id;
        return ofwk && ofwk.ausweisung || "";
    }

    @fn.Label("Oberflächenwasserkörper")
    public get ofwkBezeichnung(): string {
        const ofwk = this.fischinfoOberflaechenwasserkoerperByOberflaechenwasserkoerper_id;
        return ofwk && ofwk.bezeichnung || "";
    }

    @fn.Label("Natura 2000-Gebiet")
    public get n2000KennBezeich(): string {
        const n2k = this.fischinfoNatura2000ByNatura2000_id;
        return n2k && `${n2k.kennung} ${n2k.bezeichnung}` || "";
    }

    public get guesartBezeichnung(): string {
        return this.guesart && (
            this.guesart === "0" ? "Fließgewässer"
                : this.guesart === "1" ? "Stillgewässer"
                    : null
        );
    }
}
