import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoFischgewaessertyp from "./interface";
import FischinfoVerortung_fischgewaessertypen from "./../../fischinfo/verortung_fischgewaessertypen/model";
import FischinfoProbenahmestelle from "./../../fischinfo/probenahmestelle/model";
@fn.DataModel
export default class FischinfoFischgewaessertyp extends DataModel implements IFischinfoFischgewaessertyp {
    constructor(obj?: object | IFischinfoFischgewaessertyp) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoFischgewaessertyp): FischinfoFischgewaessertyp {
        return new FischinfoFischgewaessertyp(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoFischgewaessertyp): FischinfoFischgewaessertyp {
        Object.assign(this, obj);
        obj && obj["fischinfoVerortung_fischgewaessertypenListByForeignFischgewaessertyp_id"] && obj["fischinfoVerortung_fischgewaessertypenListByForeignFischgewaessertyp_id"].length && (this.fischinfoVerortung_fischgewaessertypenListByForeignFischgewaessertyp_id = obj["fischinfoVerortung_fischgewaessertypenListByForeignFischgewaessertyp_id"].map(FischinfoVerortung_fischgewaessertypen.deserialize));
        obj && obj["fischinfoProbenahmestelleListByForeignFischgewaessertyp_id"] && obj["fischinfoProbenahmestelleListByForeignFischgewaessertyp_id"].length && (this.fischinfoProbenahmestelleListByForeignFischgewaessertyp_id = obj["fischinfoProbenahmestelleListByForeignFischgewaessertyp_id"].map(FischinfoProbenahmestelle.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "kurzbezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public kurzbezeichnung?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "nr", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public nr?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoVerortung_fischgewaessertypen>}
     */
    @fn.DataMember({ name: "fischinfoVerortung_fischgewaessertypenListByForeignFischgewaessertyp_id", type: [FischinfoVerortung_fischgewaessertypen], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoVerortung_fischgewaessertypenListByForeignFischgewaessertyp_id?: Array<FischinfoVerortung_fischgewaessertypen>;

    /**
     * @type {Array<FischinfoProbenahmestelle>}
     */
    @fn.DataMember({ name: "fischinfoProbenahmestelleListByForeignFischgewaessertyp_id", type: [FischinfoProbenahmestelle], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoProbenahmestelleListByForeignFischgewaessertyp_id?: Array<FischinfoProbenahmestelle>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
