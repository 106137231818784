import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoStroemung from "./interface";
import FischinfoGewaesserzustand from "./../../fischinfo/gewaesserzustand/model";
@fn.DataModel
export default class FischinfoStroemung extends DataModel implements IFischinfoStroemung {
    constructor(obj?: object | IFischinfoStroemung) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoStroemung): FischinfoStroemung {
        return new FischinfoStroemung(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoStroemung): FischinfoStroemung {
        Object.assign(this, obj);
        obj && obj["fischinfoGewaesserzustandListByForeignStroemung_id"] && obj["fischinfoGewaesserzustandListByForeignStroemung_id"].length && (this.fischinfoGewaesserzustandListByForeignStroemung_id = obj["fischinfoGewaesserzustandListByForeignStroemung_id"].map(FischinfoGewaesserzustand.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoGewaesserzustand>}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandListByForeignStroemung_id", type: [FischinfoGewaesserzustand], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoGewaesserzustandListByForeignStroemung_id?: Array<FischinfoGewaesserzustand>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
