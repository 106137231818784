<uw-tabs>

    <uw-modal-window-message
        *ngIf="userService.roles.includes('ROLE_ADMIN')"
        [height]="buttonPanelHeight"
        [paddingLeft]="0"
        [paddingRight]="0"
    >
        <article>
            <uw-inline-button
                iconClass="fa fa-plus-circle"
                (click)="onClickCreateAuftrag($event)" 
                title="Neuen Auftrag erfassen"
                value="Neuen Auftrag erfassen"
            ></uw-inline-button>
            <uw-inline-button
                iconClass="fa fa-download"
                (click)="onClickExportList($event)" 
                title="CSV Export{{filtered ? '; gefiltert durch die Probenahmestellen der Hauptansicht' : ''}}"
                value="CSV Export{{filtered ? ' (gefiltert)' : ''}}"
            ></uw-inline-button>
            <uw-inline-button
                iconClass="fa fa-upload"
                (click)="onClickImportList($event)" 
                title="CSV Import"
                value="CSV Import"
            ></uw-inline-button>
        </article>
    </uw-modal-window-message>

    <section class="uw-tab-body">
        <div
            class="container-fluid"
            [style.marginTop.px]="topmargin"
        >
            <div class="row" style="margin-top:10px;">
                <div class="col-sm-15">
                    <uw-checkbox
                        label="&nbsp;Aufträge anhand der Tabelle filtern"
                        [value]="filtered"
                        (valueChange)="onFilteredChange($event)"
                    ></uw-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-md-15">
                    <table class="uw-modal-table">
                        <thead>
                            <tr>
                                <th title="Benutzer">Benutzer</th>
                                <th title="PS-Nr.">PS-Nr.</th>
                                <th title="von">von</th>
                                <th title="bis">bis</th>
                                <th title="Bezeichnung">Bezeichnung</th>
                                <th title="Anlass">Anlass</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="let auftrag of filteredAuftragList"
                            >
                                <tr
                                    (click)="onClickShowAuftrag($event, auftrag)"
                                >
                                    <td
                                        [title]="auftrag.benutzer_name"
                                    >{{auftrag.benutzer_name}}</td>
                                    <td
                                        [title]="auftrag.probestellennr"
                                    >{{auftrag.probestellennr}}</td>
                                    <td
                                        [title]="auftrag.gueltigvon"
                                    >{{getDateInDatetime(auftrag.gueltigvon)}}</td>
                                    <td
                                        [title]="auftrag.gueltigbis"
                                    >{{getDateInDatetime(auftrag.gueltigbis)}}</td>
                                    <td
                                        [title]="auftrag.bezeichnung"
                                    >{{auftrag.bezeichnung}}</td>
                                    <td
                                        [title]="auftrag.anlass"
                                    >{{auftrag.anlass}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                        <tfoot>
                            <tr *ngIf="modal.data.loaded && (auftragList?.length || 0) === 0">
                                <td colspan="5">Es sind keine Aufträge im angewählten Kartenbereich vorhanden.</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </section>

</uw-tabs>
