import { Injectable } from "@angular/core";
import UWCsvService from "./implementation"

/**
 * Dieser Dienst bietet einen Download als CSV-Datei an.
 * 
 * Er wandelt einen zwei-dimensionalen Array in eine herunterladbare CSV-Datei um und biete dem Nutzer die Datei zum Download an.
 */
@Injectable({
    providedIn: 'root'
})
export default class UWCsvInjectableService extends UWCsvService {
}