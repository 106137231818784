import { Injectable, EventEmitter } from "@angular/core";
import AppService from "./../app/service";

@Injectable({
    providedIn: 'root'
})
export default class MapViewService {
    public footerHeight: number = 36;
    public staticHeight: number;

    public nativeElementValue: any;
    public get nativeElement() {
        return this.nativeElementValue;
    }
    public set nativeElement(el: any) {
        this.nativeElementValue = el;
    }

    constructor(
        protected appService: AppService
    ) {
    }

    public mapPadding: any = {
        panel: { left: 0, top: 0, right: 0, bottom: 0 },
        modal: { left: 0, top: 0, right: 0, bottom: 0 }
    };

    public get width(): number {
        return this.appService.width;
    }

    public get height(): number {
        return this.appService.isFullscreenMode
            ? this.appService.height - this.footerHeight
            : this.staticHeight - this.footerHeight // Math.max(this.staticHeight, this.nativeElement.parentNode.offsetHeight) - this.footerHeight
    }
}
