import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFibsHmwbnutzung from "./interface";
import FibsHoep from "./../../fibs/hoep/model";
@fn.DataModel
export default class FibsHmwbnutzung extends DataModel implements IFibsHmwbnutzung {
    constructor(obj?: object | IFibsHmwbnutzung) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFibsHmwbnutzung): FibsHmwbnutzung {
        return new FibsHmwbnutzung(obj);
    }
    public deserialize<T extends object>(obj: T | IFibsHmwbnutzung): FibsHmwbnutzung {
        Object.assign(this, obj);
        obj && obj["fibsHoepListByForeignNutzung_id"] && obj["fibsHoepListByForeignNutzung_id"].length && (this.fibsHoepListByForeignNutzung_id = obj["fibsHoepListByForeignNutzung_id"].map(FibsHoep.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "beschreibung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public beschreibung?: string;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "datum_nutzung", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public datum_nutzung?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "kuerzel", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public kuerzel?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "kurzbezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public kurzbezeichnung?: string;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FibsHoep>}
     */
    @fn.DataMember({ name: "fibsHoepListByForeignNutzung_id", type: [FibsHoep], nullable: true, memberType: "FOREIGN JOIN" })
    public fibsHoepListByForeignNutzung_id?: Array<FibsHoep>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
