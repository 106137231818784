import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoMarkierungpositionfarbe from "./interface";
import FischinfoBesatzereignis from "./../../fischinfo/besatzereignis/model";
@fn.DataModel
export default class FischinfoMarkierungpositionfarbe extends DataModel implements IFischinfoMarkierungpositionfarbe {
    constructor(obj?: object | IFischinfoMarkierungpositionfarbe) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoMarkierungpositionfarbe): FischinfoMarkierungpositionfarbe {
        return new FischinfoMarkierungpositionfarbe(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoMarkierungpositionfarbe): FischinfoMarkierungpositionfarbe {
        Object.assign(this, obj);
        obj && obj["fischinfoBesatzereignisListByForeignPositionfarbe_id"] && obj["fischinfoBesatzereignisListByForeignPositionfarbe_id"].length && (this.fischinfoBesatzereignisListByForeignPositionfarbe_id = obj["fischinfoBesatzereignisListByForeignPositionfarbe_id"].map(FischinfoBesatzereignis.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoBesatzereignis>}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisListByForeignPositionfarbe_id", type: [FischinfoBesatzereignis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzereignisListByForeignPositionfarbe_id?: Array<FischinfoBesatzereignis>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
