<div class="container-fluid uw-filter">
    <div class="row">
        <div
            *ngIf="isExtendedView"
            class="col-xs-15"
        >
            <div class="row uw-session">
                <span>{{userService.name}}</span>&nbsp;<sup 
                    title="Nutzerrechte: {{userService.beschreibungRoles}}"
                ><i class="fa fa-info-circle"></i></sup>
            </div> 
        </div>
        <div
            *ngIf="!isExtendedView"
            class="col-xs-15"
        >
            <div class="row uw-session">
                <span>Öffentliches Auskunftssystem</span>
            </div> 
        </div>
        <div class="container-fluid uw-filter-tabs">
            <div class="row">
                <div
                    class="col-xs-15"
                    uw-tabs
                    [active-tab]="activeTab"
                >
                    <header
                        class="uw-tab-bar"
                        style="position: relative;"
                    >
                        <label
                            uw-tab="tabelle"
                            title="tabelle"
                            *ngIf="isExtendedView"
                        >
                            <span>Tabelle</span>
                        </label>
                        <label
                            uw-tab="karte"
                            title="Karte"
                        >
                            <span>Karte</span>
                        </label>
                        <label
                            uw-tab="filter"
                            title="Filter"
                            *ngIf="isExtendedView"
                        >
                            <span>Filter</span>
                        </label>
                        <label
                            uw-tab="fibs"
                            title="FIBS"
                            *ngIf="isExtendedView && hasExtendedAdminRightFIBS"
                        >
                            <span>FIBS</span>
                        </label>
                    </header>

                    <uw-tab name="filter">
                        <div
                            class="container-fluid"
                            [style.height.px]="tabContentHeight"
                        >
                            <map-panel-filter
                                #panelFilter
                            ></map-panel-filter>
                        </div>
                    </uw-tab>

                    <uw-tab name="tabelle">
                        <div
                            class="container-fluid"
                            [style.height.px]="tabContentHeight"
                        >
                            <div class="row">
                                <div class="col-xs-15">
                                    <uw-select
                                        label="Tabellarische Auflistung"
                                        jsonItems='[["probenahmestelle","Probestellen"], ["untersuchung","Untersuchungen"]]'
                                        keyValueMapping="0:1"
                                        key="probenahmestelle"
                                        (change)="onFolderChanged($event)"
                                    ></uw-select>
                                </div>
                            </div>
                            <!--
                            <div class="row">
                                <div class="col-xs-15">
                                    <label>Karte</label>
                                    <br/>Die Karte stellt Gewässer und Probenahmestellen dar. Bei Anwahl einer Probenahmestelle erreichen Sie die zugeordneten Untersuchungen.
                                    <br/><label>Tabelle</label>
                                    <br/>Die Tabelle listet Probenahmestellen oder Untersuchungen.
                                    <br/><label>Filter</label>
                                    <br/>Über den Reiter "Filter" können Sie nach verschiedenen Kriterien filtern.
                                    <br/><label>Auskunft</label>
                                    <br/>Für jedes Filterergebnis bzw. jede tabellarische Liste können Sie eine Auskunft erstellen und exportieren.
                                </div>
                            </div>
                            -->
                        </div>
                    </uw-tab>

                    <uw-tab name="karte">
                        <div
                            class="container-fluid"
                            [style.height.px]="tabContentHeight"
                        >
                            <map-panel-layer
                                #panelLayer
                            ></map-panel-layer>
                        </div>
                    </uw-tab>

                    <uw-tab name="fibs">
                        <div
                            class="container-fluid"
                            [style.height.px]="tabContentHeight"
                        >
                            <map-panel-fibs
                                #panelFibs
                            ></map-panel-fibs>
                        </div>
                    </uw-tab>

                </div>
            </div>
        </div>
    </div>
</div>
