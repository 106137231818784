<div
    class="row"
    [class.fullscreen]="appService.isFullscreenMode"
    [style.height.px]="mapViewService.height"
>
    <div
        class="uw-map-view-wrapper"
        [style.height.px]="mapViewService.height"
    >
        <custom-map
            [editable]="editable ? 'true': 'false'"
            [height]="mapViewService.height"
        ></custom-map>

        <uw-map-search></uw-map-search>

        <uw-map-editor
            *ngIf="editable"
            measureTool="true"
            shapesUpload="true"
        ></uw-map-editor>

        <custom-toolbar 
            #toolbar
            zIndex="1003"
        ></custom-toolbar>

        <uw-side-panel
            *ngIf="isExtendedView"
            #eastPanel
            zIndex="1000"
            width="0"
            side="e"
        >
            <uw-panel
                #panel
            ></uw-panel>
        </uw-side-panel>

        <uw-side-panel
            #southPanel
            zIndex="1002"
            height="274"
            side="s"
        >
            <custom-table
                #table
                virtualRowHeight="24"
            ></custom-table>
        </uw-side-panel>
    </div>
    <uw-footer></uw-footer>
</div>
