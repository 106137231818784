import { Component } from "@angular/core";
/**
 * Ausgabe der Legende und von Hinweisen zur Verwendung der Kartenanwendung.
 */
@Component({
    selector: "uw-legende",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class MapLegende {
}
