export default class Pulse {
    private promise: Promise<void> = null;
    private resolve: () => void = null;
    private reject: (a: any) => void = null;
    private handle: any = null;

    public constructor(private millisec: number = 1000) { }

    public next() {
        if (this.handle == null) throw new TypeError("cannot get pulse when not running");
        if (!this.promise) {
            this.promise = new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
            const cleanup = () => {
                this.promise = this.resolve = this.reject = null;
            };
            this.promise.then(cleanup, cleanup);
        }
        return this.promise;
    }

    public start() {
        if (this.handle == null) {
            this.handle = setInterval(() => this.resolve && this.resolve(), this.millisec);
        }
        return this;
    }

    public stop() {
        if (this.handle != null) {
            clearInterval(this.handle);
            if (this.reject) this.reject(this); //takes care of unsetting promise related
            this.handle = null;
        }
        return this;
    }
}