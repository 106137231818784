import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoFfhbewertung from "./interface";
import FischinfoFischart from "./../../fischinfo/fischart/model";
import FischinfoBefischung from "./../../fischinfo/befischung/model";
@fn.DataModel
export default class FischinfoFfhbewertung extends DataModel implements IFischinfoFfhbewertung {
    constructor(obj?: object | IFischinfoFfhbewertung) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoFfhbewertung): FischinfoFfhbewertung {
        return new FischinfoFfhbewertung(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoFfhbewertung): FischinfoFfhbewertung {
        Object.assign(this, obj);
        obj && obj["fischinfoFischartByArt_id"] && (this.fischinfoFischartByArt_id = FischinfoFischart.deserialize(obj["fischinfoFischartByArt_id"]));
        obj && obj["fischinfoBefischungByBefischung_id"] && (this.fischinfoBefischungByBefischung_id = FischinfoBefischung.deserialize(obj["fischinfoBefischungByBefischung_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "art_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public art_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "beeintraechtigungen", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public beeintraechtigungen?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "befischung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public befischung_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "begruendung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public begruendung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gesamtwert", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gesamtwert?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gesamtwertmanuell", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gesamtwertmanuell?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "habitatqualitaet", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public habitatqualitaet?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "zustandderpopulation", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public zustandderpopulation?: string;

    /**
     * @type {FischinfoFischart}
     */
    @fn.DataMember({ name: "fischinfoFischartByArt_id", type: FischinfoFischart, nullable: true, memberType: "JOIN", joinProperty: "art_id" })
    public fischinfoFischartByArt_id?: FischinfoFischart;

    /**
     * @type {FischinfoBefischung}
     */
    @fn.DataMember({ name: "fischinfoBefischungByBefischung_id", type: FischinfoBefischung, nullable: true, memberType: "JOIN", joinProperty: "befischung_id" })
    public fischinfoBefischungByBefischung_id?: FischinfoBefischung;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
