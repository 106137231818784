<div 
    class="uw-tool-control"
    [style.zIndex]="zIndex"
    >
    <div *ngIf="!appService.isIFrame" (click)="onClickButton($event, 'toggleFullscreen')" title="Vollbild anzeigen"><i class="fa fa-arrows-alt"></i></div>
    <div *ngIf="isExtendedView" (click)="onClickButton($event, 'logout')" title="Benutzer abmelden"><i class="fa fa-sign-out"></i></div>
    <div *ngIf="userService.roles.includes('ROLE_ADMIN')" (click)="onClickButton($event, 'manageAssignments')" title="Auftragsverwaltung"><i class="fa fa-vcard"></i></div>
    <!--<div *ngIf="userService.isAdmin" (click)="onClickButton($event, 'manageUsers')" title="Benutzer verwalten"><i class="fa fa-users"></i></div>//-->
    <div *ngIf="isExtendedView" (click)="onClickButton($event, 'clearFilter')" title="Alle Filter zurücksetzen"><i class="fa fa-filter"></i></div>
    <div *ngIf="userService.roles.includes('ROLE_ADMIN')" (click)="onClickButton($event, 'importCSV')" title="Untersuchungen aus CSV-Datei importieren"><i class="fa fa-upload"></i></div>
    <div (click)="onClickButton($event, 'exportCSV')" title="Filterergebnis in Datei speichern"><i class="fa fa-download"></i></div>
    <div [class.active]="toolControlBarViewToggle" (click)="onClickToggleExtentViewButtonsButton($event)" title="Aufteilung der Anzeige anpassen"><i class="fa fa-columns"></i></div>
    <div class="sub" *ngIf="toolControlBarViewToggle" (click)="onClickButton($event, 'extentView', 'wizard')" title="Den Anzeigebereich automatisch aufteilen"><i class="fa fa-magic"></i></div>
    <div *ngIf="isExtendedView && toolControlBarViewToggle" class="sub" (click)="onClickButton($event, 'extentView', 'filter')" title="Den Anzeigebereich mit der Filtersicht ausfüllen"><i class="fa fa-check-circle"></i></div>
    <div *ngIf="isExtendedView && toolControlBarViewToggle" class="sub" (click)="onClickButton($event, 'extentView', 'tablefilter')" title="Den Anzeigebereich mit der Tabellensicht und der Filtersicht ausfüllen"><i class="fa fa-i-big fa-list"></i><i class="fa fa-i-small fa-check-circle"></i></div>
    <div class="sub" *ngIf="toolControlBarViewToggle" (click)="onClickButton($event, 'extentView', 'table')" title="Den Anzeigebereich mit der Tabellensicht ausfüllen"><i class="fa fa-list"></i></div>
    <div *ngIf="isExtendedView && toolControlBarViewToggle" class="sub" (click)="onClickButton($event, 'extentView', 'mapfilter')" title="Den Anzeigebereich mit der Kartensicht und der Filtersicht ausfüllen"><i class="fa fa-i-big fa-map-o"></i><i class="fa fa-i-small fa-check-circle"></i></div>
    <div class="sub" *ngIf="toolControlBarViewToggle" (click)="onClickButton($event, 'extentView', 'map')" title="Den Anzeigebereich mit der Kartensicht ausfüllen"><i class="fa fa-map-o"></i></div>
    <div (click)="onClickToggleExtentViewButtonsButton($event)" class="last active" title="Anzeigeleiste ein-/ausblenden"><i [class]="toolControlBarViewToggle?'fa fa-chevron-right':'fa fa-chevron-left'"></i></div>
</div>