import { UWAbstractFileService } from "ng-uw-file-service";

const CsvMimeType = "text/csv;charset=utf-8";

export default class UWCsvService extends UWAbstractFileService<Array<Array<any>>> {
    protected get mimeType() { return CsvMimeType; };
    public transformModelToBlobSource(model: Array<Array<any>>) {
        let csv = "";

        for (let row of model) {
            let rowcsv = ""
            for (let cell of row) {
                rowcsv +=
                    (rowcsv.length ? ';"' : '"') +
                    String(cell == null ? "" : cell)
                        .replace(/(\r\n|\n|\r)/gm, " ")
                        .replace(/(")/gm, "'''") +
                    '"';
            }
            csv += rowcsv + "\r\n";
        }

        return csv;
    }
}
