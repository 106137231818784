<div class="uw-input uw-typeahead uw-select">
    <label *ngIf="label.length" [attr.for]="inputId">{{label}}<sup *ngIf="infoTag" [title]="infoTag"> <i class="fa" [ngClass]="infoTagClass"></i></sup></label>
    <div *ngIf="!disabled"
        class="uw-toggleall-button"
        title="Alles aus-/abwählen"
        (click)="onClickToggleAll($event)"
    >
        <ng-container [ngSwitch]="anyIsSelected + allIsSelected">
            <span
                *ngSwitchCase="1"
                class="fa-stack uw-any-icon"
            >
                <i class="fa fa-square-o fa-stack-2x"></i>
                <i class="fa fa-check fa-stack-1x"></i>
            </span>
            <i
                *ngSwitchDefault
                [ngClass]="suggestionIconClass(allIsSelected)"
            ></i>
        </ng-container>
    </div>
    <input 
      #input
      type="button"
      class="form-control"
      [attr.id]="inputId"
      aria-expanded="false" 
      aria-activedescendant=""
      [disabled]="disabled"
      [placeholder]="placeholder"
      [attr.value]="suggestions ? value : ''"
      [attr.aria-owns]="suggestionsId"
      [attr.aria-describedby]="manualId"
      [attr.title]="placeholder"
      [style.padding-right.px]="inputRightPadding"
      (mousedown)="mousedownOnInput($event)"
      (mouseenter)="mouseenterOnInput($event)"
      (keyup)="keyupOnInput($event)" 
      (keydown)="keydownOnInput($event)" 
      (blur)="blurOnInput($event)" 
      (focus)="focusOnInput($event)"
      (change)="changeEventOnInput($event)"
    >
    <div *ngIf="!disabled && nullable && !isEmpty" (click)="clickOnEmptizer($event)" class="uw-emptizer"><i class="fa fa-close" title="Eintrag leeren"></i></div>
    <div *ngIf="!disabled" [style.right.px]="nullable && value && value.length && value !== placeholder ? 24 : 0"  (mousedown)="mousedownOnCaret($event)" (mouseenter)="mouseenterOnCaret($event)" class="uw-selectable" [attr.title]="placeholder"><b class="caret" [title]="placeholder"></b></div>
    <div #listbox>
        <div
            *ngIf="!hideSuggestions" 
            class="uw-select-options-wrap"
            [attr.id]="suggestionsId"
        >
            <ul role="listbox" tabindex="0"
                [style.maxHeight]="getListMaxHeight(24)"
                (mousedown)="$event.preventDefault(); $event.stopPropagation()"
                (focusin)="focusOnSuggestionList($event)"
            >
                <li 
                *ngFor="let suggestion of suggestions; let i = index" 
                [class.hover]="i === hoverIndex"
                [class.selected]="isSelected(suggestion)"
                (mouseup)="mouseupOnSuggestions($event, i)"
                (mousedown)="mousedownOnSuggestions($event, i)"
                (mouseenter)="mouseenterOnSuggestions($event, i)"
                >{{suggestion.value}}<i class="fa"
                    [ngClass]="suggestionIconClass(isSelected(suggestion))"
                ></i></li>
                    
            </ul>
            <div *ngIf="suggestionListIsShortened"
                class="focus-down-button"
                [style.top.px]="size * 24"
                (mousedown)="$event.stopPropagation(); $event.preventDefault()"
                (mouseup)="$event.stopPropagation(); $event.preventDefault()"
                (click)="clickOnFocusDownButton($event)"
            ><i class="fa fa-angle-down"></i></div>
        </div>
    </div>
    <span [attr.id]="manualId" class="hidden">When select results are available use up and down arrows to review and enter to select.  Touch device users, explore by touch or with swipe gestures.</span>
    <div aria-live="assertive" class="screen-reader-text"></div>
</div>
