import { Injectable } from "@angular/core";

import { UWModalService } from "ng-uw-modal";
import AppService from "./../app/service";

@Injectable({
    providedIn: 'root'
})
export default class ModalService extends UWModalService {
    public modals: Array<any> = [];
    public modalViewInitLeft: number = 0;
    public modalViewInitTop: number = 40;
    public modalViewInitWidth: number = 665;
    public modalViewInitHeight: number = 640;

    constructor(
        protected appService: AppService
    ) {
        super();
    }

    public setModalOption(id: string, option: string, value: any): void {
        super.setModalOption(id, option, value);
    }

    public createModal(options: any): string {
        if (this.modalViewInitLeft < 1) {
            this.modalViewInitLeft = this.appService.width - this.modalViewInitWidth - (
                this.appService.isFullscreenMode || this.appService.isIFrame
                    ? 6
                    : 0
            );
        }
        options.id = "modal-stack-" + (new Date().getTime()) + "-" + this.modals.length;
        this.modals.push(options);
        return options.id;
    }

    public checkIfModalsAreInView(isFullscreen: boolean): void {
        if (isFullscreen) {
            this.stack.forEach(modal => {
                // modal.top = Math.max(10, modal.top);
                // modal.left = Math.max(10, modal.left);
                this.setPreviousActiveModalPosition(modal.top, modal.left);
            });
        }
    }
    public closeModals(): void {
        this.modals.forEach(element => {
            this.unregisterModal({ id: element.id });
        });
    }
    public unregisterModal(modal: any): void {
        let index = -1,
            i = index;
        this.stack.forEach(element => {
            ++i;
            index = element.id === modal.id ? i : index;
        });
        if (index > -1) {
            this.stack.splice(index, 1);
        }
        (index = -1), (i = index);
        this.modals.forEach(element => {
            ++i;
            index = element.id === modal.id ? i : index;
        });
        if (index > -1) {
            this.modalCloseEventEmitter.next({ id: modal.id });
            this.modals.splice(index, 1);
        }
    }
}
