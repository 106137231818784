import { Directive, Input, AfterContentInit, HostListener, HostBinding} from "@angular/core";

import UWTabsDirective from "../directive";

/**
 * Diese Komponente markiert ein Element als Aktivator für einen Tab.
 * 
 * Wenn der Nutzer das markierte Element klickt, wechselt die Anwendung zum uw-tab-Inhalt, für den dieser Aktivator zuständig ist. Wenn der Tab schon aktiv ist, dann hat die Aktivierung keinen Effekt.
 */
@Directive(
    { selector: "[uw-tab]"
    // , template: "<ng-content></ng-content>"
    }
)
export default class UWTabDirective implements AfterContentInit {

    @HostBinding("class.tab-active") public active: boolean;
    @Input("uw-tab") public name: string;

    protected onChangeSubscription: any;

    constructor(
       protected tabs: UWTabsDirective
    ) {
        // setup propagation of active tab
        this.onChangeSubscription = tabs.change.subscribe(change => {
            this.setActiveFromName(change.currentValue);
        });
    }

    public ngOnDestroy(): void {
        this.onChangeSubscription.unsubscribe();
    }

    @HostListener("click") protected activateTab () {
       this.tabs.activateTab(this.name);
    }

    protected setActiveFromName (name: string) {
        this.active = (name === this.name);
    }

    public ngAfterContentInit () {
        this.setActiveFromName(this.tabs.active);
    }
}
