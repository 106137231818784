import { Component, Input, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoEinzelbeeintraechtigung from "../../models/fischinfo/einzelbeeintraechtigung/model";
import FischinfoGewaesserzustandeinzelbeeintraechtigung from "../../models/fischinfo/gewaesserzustandeinzelbeeintraechtigung/model";
/**
 * Mit dieser Komponente kann der Nutzer die Beeinträchtigungen des befischten Gewässers ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt eine Menge von Beeinträchtigungen dar. Der Nutzer kann diese an- oder abwählen.
 */
@Component({
    selector: "form-fischinfo-untersuchung-gewaesser-sonstiges-zustandeinzelbeeintraechtigung",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungGewaesserSonstigesZustandeinzelbeeintraechtigung implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public _zustandeinzelbeeintraechtigungList: any[];
    public get zustandeinzelbeeintraechtigungList() {
        return this._zustandeinzelbeeintraechtigungList;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    ngOnInit() {
        this.refreshZustandeinzelbeeintraechtigungList();
    }
    public onClickZustandeinzelbeeintraechtigung(e: any, zustandeinzelbeeintraechtigung: FischinfoGewaesserzustandeinzelbeeintraechtigung) {
        this.formFischinfoUntersuchungService.modelUtil
            .getOrDefault<FischinfoGewaesserzustandeinzelbeeintraechtigung>(this.untersuchung, "FischinfoGewaesserzustandeinzelbeeintraechtigung", zustandeinzelbeeintraechtigung)
            .id > 0
            ? this.formFischinfoUntersuchungService.modelUtil
                .remove<FischinfoGewaesserzustandeinzelbeeintraechtigung>(this.untersuchung, "FischinfoGewaesserzustandeinzelbeeintraechtigung", zustandeinzelbeeintraechtigung)
            : this.formFischinfoUntersuchungService.modelUtil
                .setOrAdd<FischinfoGewaesserzustandeinzelbeeintraechtigung>(this.untersuchung, "FischinfoGewaesserzustandeinzelbeeintraechtigung", new FischinfoGewaesserzustandeinzelbeeintraechtigung({
                id: 1,
                einzelbeeintraechtigung_id: zustandeinzelbeeintraechtigung.einzelbeeintraechtigung_id,
                gewaesserzustand_id: this.formFischinfoUntersuchungService.modelUtil
                    .getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                    .id
            }));
        this.refreshZustandeinzelbeeintraechtigungList();
    }
    public refreshZustandeinzelbeeintraechtigungList() {
        this.getNormalizedZustandeinzelbeeintraechtigungList()
            .then((list) => {
            this._zustandeinzelbeeintraechtigungList = list;
        });
    }
    public getNormalizedZustandeinzelbeeintraechtigungList(): Promise<any[]> {
        let a = this.dataService
            .getReferenceTable("einzelbeeintraechtigung", FischinfoEinzelbeeintraechtigung, "id");
        return Promise.all([a])
            .then(([einzelbeeintraechtigung]) => {
            const values = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                .fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id || [];
            let list = [];
            Object.keys(einzelbeeintraechtigung).forEach(key => {
                list.push({
                    id: (values.find(x => x.einzelbeeintraechtigung_id === einzelbeeintraechtigung[key].id) || { id: 0 }).id,
                    bezeichnung: einzelbeeintraechtigung[key].bezeichnung,
                    einzelbeeintraechtigung_id: einzelbeeintraechtigung[key].id
                });
            });
            return list
                .filter(x => !this.readonly || x.id > 0)
                .sort((a, b) => {
                a = a.einzelbeeintraechtigung_id;
                b = b.einzelbeeintraechtigung_id;
                return (a > b)
                    ? 1
                    : ((b > a)
                        ? -1
                        : 0);
            });
        });
    }
}
