<uw-tabs>
    <section class="uw-tab-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-15">
                    <label>
                        Auskünfte abrufen und in Datei speichern
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-15">
                    <p *ngIf="dataService.isLoading">
                        Bitte warten Sie bis die Ergebnistabellen vollständig geladen sind…
                    </p>
                    <p *ngIf="!dataService.isLoading">
                        Bitte wählen Sie die gewünschte Auskunft in welche die gefilterten Untersuchungen oder Probenahmestellen aus den Tabellen 
                        gespeichert werden sollen.
                    </p>
                </div>
            </div>
            <div
                *ngIf="!dataService.isLoading"
                class="row"
            >
                <div class="col-sm-15">
                    <uw-button
                        label="Auskunft 1 als CSV-Tabelle für Excel"
                        (click)="exportAuskunftAsCsvFile('1_aus_2_mit_jungfisch_eingeschraenkt')"
                        [disabled]="isLoadingAuskunft('1_aus_2_mit_jungfisch_eingeschraenkt')"
                    >Auskunft 1 abrufen</uw-button>
                </div>
                <div
                    *ngIf="!limitedToUserRole"
                    class="col-sm-15"
                >
                    <uw-button
                        label="Auskunft 2 als CSV-Tabelle für Excel"
                        (click)="exportAuskunftAsCsvFile('2_mit_jungfisch')"
                        [disabled]="isLoadingAuskunft('2_mit_jungfisch')"
                    >Auskunft 2 abrufen</uw-button>
                </div>
                <div
                    *ngIf="!limitedToUserRole"
                    class="col-sm-15"
                >
                    <uw-button
                        label="Auskunft 2 FFH-Bewertung als CSV-Tabelle für Excel"
                        (click)="exportAuskunftAsCsvFile('2_ffhbewertung')"
                        [disabled]="isLoadingAuskunft('2_ffhbewertung')"
                    >Auskunft 2 FFH-Bewertung abrufen</uw-button>
                </div>
                <div
                    *ngIf="!limitedToUserRole"
                    class="col-sm-15"
                >
                    <uw-button
                        label="Auskunft 5 als CSV-Tabelle für Excel"
                        (click)="exportAuskunftAsCsvFile('5')"
                        [disabled]="isLoadingAuskunft('5')"
                    >Auskunft 5 abrufen</uw-button>
                </div>
                <div
                    *ngIf="!limitedToUserRole"
                    class="col-sm-15"
                >
                    <uw-button
                        label="Auskunft 9 als CSV-Tabelle für Excel"
                        (click)="exportAuskunftAsCsvFile('9')"
                        [disabled]="isLoadingAuskunft('9')"
                    >Auskunft 9 abrufen</uw-button>
                </div>
                <div
                    *ngIf="!limitedToUserRole"
                    class="col-sm-15"
                >
                    <uw-button
                        label="Auskunft 9 als ESRI Shapefile (SHP)"
                        (click)="exportAuskunftAsShpFile('9')"
                        [disabled]="isLoadingAuskunft('9_shape')"
                    >Auskunft 9 abrufen</uw-button>
                </div>
                <div
                    *ngIf="userHasAdminRole"
                    class="col-sm-15"
                >
                    <uw-button
                        label="Auskunft Probestellen FIBS als CSV-Tabelle für Excel"
                        (click)="exportAuskunftAsCsvFile('probestelle_fibs_komplett')"
                        [disabled]="isLoadingAuskunft('probestelle_fibs_komplett')"
                    >Auskunft Probestellen FIBS abrufen</uw-button>
                </div>
                <div
                    *ngIf="userHasAdminRole"
                    class="col-sm-15"
                >
                    <uw-button
                        label="Auskunft GÜS-Export"
                        (click)="exportAuskunftAsXmlFile('export_gues')"
                        [disabled]="isLoadingAuskunft('export_gues')"
                    >Auskunft GÜS-Export abrufen</uw-button>
                </div>
            </div>
        </div>
    </section>
</uw-tabs>
