<uw-modal
    *ngFor="let modal of modalService.modals" 
    uw-tabs
    [active-tab]="modal.data.activeTab"
    [height]="height"
    [width]="width"
    [left]="left"
    [top]="top"
    [zIndex]="zIndex"
    [id]="modal.id"
    (change)="onModalTabChanged($event, modal)"
    (move)="onMove($event, modal)"
    (resize)="onResize($event, modal)"
>
    <header>
        <!--
            <label *ngIf="modal.data.geometrieSpeichern" uw-tab="geometrieSpeichern" title="Neue ... wird verarbeitet">
                <span>Geometrie wird verarbeitet</span>
            </label>
            <label *ngIf="modal.data.dataNodePlaceholderErstellen" uw-tab="dataNodePlaceholderErstellen" title="Neue ... erfassen">
                <span>Neue ...</span>
            </label>
            <label *ngIf="modal.data.dataNodePlaceholderErstellenSchutzgebiet" uw-tab="dataNodePlaceholderErstellenSchutzgebiet" title="Neue ... erfassen">
                <span>Neue ...</span>
            </label>
            <label *ngIf="modal.data.dataNodePlaceholder" uw-tab="dataNodePlaceholder" title="...">
                <span>{{modal?.data?.dataNodePlaceholder?.untersuchungsgebiet}}</span>
            </label>
         -->
        <label *ngIf="modal.data.export" class="no-arrow" uw-tab="export" title="Filterergebnis bzw. Tabelle in Datei speichern">
            <span>Tabelle</span>
        </label>
        <label *ngIf="modal.data.export" class="no-arrow" uw-tab="kartendruck" title="Karte">
            <span>Karte</span>
        </label>
        <label *ngIf="modal.data.location" class="no-arrow" uw-tab="location-search" title="Karte">
            <span>Standort</span>
        </label>
        <label *ngIf="isExtendedView && modal.data.export && hasExtendedRightAuskuenfte" class="no-arrow" uw-tab="auskunft" title="Auskünfte abrufen">
            <span>Auskünfte</span>
        </label>
        <label *ngIf="modal?.data?.type==='help'" uw-tab="help" title="Hilfe">
            <span>Hilfe</span>
        </label>
        <label *ngIf="modal?.data?.type==='unsetfilter'" uw-tab="unsetfilter" title="Neue Probenahmestelle anlegen">
            <span>Neue Probenahmestelle anlegen</span>
        </label>
        <label *ngIf="modal?.data?.type==='imprint'" uw-tab="imprint" title="Impressum">
            <span>Impressum</span>
        </label>
        <label *ngIf="modal?.data?.activeTab === 'probenahmestelle' || modal?.data?.activeTab === 'wmsFeatureRequest' ||  modal?.data?.probenahmestelle || modal?.data?.wmsFeatureRequest"
            uw-tab="wmsFeatureRequest"
            title="Probestellen"
        >
            <span>Probestellen</span>
        </label>
        <label *ngIf="modal?.data?.probenahmestelleToBeLoaded && !modal?.data?.probenahmestelle"
            uw-tab="probenahmestelle"
            [title]="modal?.data?.probenahmestelleToBeLoaded?.probestellennr + (modal?.data?.probenahmestelleToBeLoaded?.gewaessernameAsString ? (' (' + modal.data.probenahmestelleToBeLoaded.gewaessernameAsString + ')') : '')"
        >
            <span>{{modal?.data?.probenahmestelleToBeLoaded?.probestellennr}}{{modal?.data?.probenahmestelleToBeLoaded?.gewaessernameAsString ? (' (' + modal.data.probenahmestelleToBeLoaded.gewaessernameAsString + ')') : ''}}</span>
        </label>
        <label *ngIf="modal?.data?.probenahmestelle"
            uw-tab="probenahmestelle"
            [title]="modal.data.probenahmestelle.probestellennr + ' (' + modal.data.probenahmestelle.gewaessernameAsString + ')'"
        >
            <span>{{modal.data.probenahmestelle.probestellennr}} ({{modal.data.probenahmestelle.gewaessernameAsString}})</span>
        </label>
        <label *ngIf="modal?.data?.untersuchungToBeLoaded && !modal?.data?.untersuchung"
            uw-tab="untersuchung"
            [title]="modal?.data?.untersuchungToBeLoaded?.untersuchungsterminAsLocaleDateString + ' (' + modal?.data?.untersuchungToBeLoaded?.erfassungsNr + ')'"
        >
            <span>{{modal?.data?.untersuchungToBeLoaded?.untersuchungsterminAsLocaleDateString}} ({{modal?.data?.untersuchungToBeLoaded?.erfassungsNr}})</span>
        </label>
        <label *ngIf="modal?.data?.untersuchung"
            uw-tab="untersuchung"
            [title]="modal.data.untersuchung.untersuchungsterminAsLocaleDateString + ' (' + erfassungsNrUntersuchung(modal) + ')'"
        >
            <span>{{modal.data.untersuchung.untersuchungsterminAsLocaleDateString}} ({{erfassungsNrUntersuchung(modal)}})</span>
        </label>
        <label *ngIf="modal.data.auftragverwaltung" uw-tab="auftragverwaltung">
            <span>Auftragsverwaltung</span>
        </label>
        <label *ngIf="modal.data.auftragToBeLoaded" uw-tab="auftrag">
            <span>Auftrag</span>
        </label>
        <label *ngIf="modal.data.activeTab === 'auftragimport'" uw-tab="auftragimport">
            <span>CSV Import</span>
        </label>
        <label *ngIf="modal.data.import" uw-tab="import">
            <span>Untersuchungen importieren</span>
        </label>
    </header>

    <article>
        <uw-tab *ngIf="modal.data.wmsFeatureRequest" name="wmsFeatureRequest">
            <uw-modal-window-tab-wms [modal]="modal"></uw-modal-window-tab-wms>
        </uw-tab>
        <uw-tab *ngIf="modal.data.gebiet" name="gebiet">
        </uw-tab>
        <uw-tab *ngIf="modal.data.dataNodePlaceholder" name="dataNodePlaceholder">
        </uw-tab>
        <uw-tab *ngIf="modal.data.export" name="export">
            <uw-modal-window-tab-export [modal]="modal"></uw-modal-window-tab-export>
        </uw-tab>
        <uw-tab *ngIf="modal.data.export" name="kartendruck">
            <uw-modal-window-tab-kartendruck [modal]="modal"></uw-modal-window-tab-kartendruck>
        </uw-tab>
        <uw-tab *ngIf="isExtendedView && modal.data.export" name="auskunft">
            <uw-modal-window-tab-auskunft [modal]="modal"></uw-modal-window-tab-auskunft>
        </uw-tab>
        <uw-tab *ngIf="modal.data.dataNodePlaceholderErstellen" name="dataNodePlaceholderErstellen">
        </uw-tab>
        <uw-tab *ngIf="modal.data.dataNodePlaceholderErstellenSchutzgebiet" name="dataNodePlaceholderErstellenSchutzgebiet">
        </uw-tab>
        <uw-tab *ngIf="modal.data.probenahmestelle" name="probenahmestelle">
            <uw-modal-window-tab-probenahmestelle [modal]="modal"></uw-modal-window-tab-probenahmestelle>
        </uw-tab>
        <uw-tab *ngIf="modal.data.probenahmestelleToBeLoaded && !modal.data.probenahmestelle" name="probenahmestelle">
            <section class="uw-tab-body" >
                <div class="container-fluid" [style.marginTop.px]="0">
                    <div class="row" *ngIf="modal.data.error">
                        <div class="col-md-15">
                            <p>{{modal.data.error}}</p>
                        </div>
                    </div>
                </div>
            </section>
        </uw-tab>
        <uw-tab *ngIf="modal.data.untersuchung" name="untersuchung">
            <uw-modal-window-tab-untersuchung [modal]="modal"></uw-modal-window-tab-untersuchung>
        </uw-tab>

        <uw-tab *ngIf="modal.data.auftragverwaltung" name="auftragverwaltung">
            <uw-modal-window-tab-auftragverwaltung [modal]="modal"></uw-modal-window-tab-auftragverwaltung>
        </uw-tab>
        <uw-tab *ngIf="modal.data.auftragToBeLoaded" name="auftrag">
            <uw-modal-window-tab-auftrag [modal]="modal"></uw-modal-window-tab-auftrag>
        </uw-tab>
        <uw-tab *ngIf="modal.data.activeTab === 'auftragimport'" name="auftragimport">
            <uw-modal-window-tab-auftragimport [modal]="modal"></uw-modal-window-tab-auftragimport>
        </uw-tab>

        <uw-tab *ngIf="modal.data.import" name="import">
            <uw-modal-window-tab-befischungimport [modal]="modal"></uw-modal-window-tab-befischungimport>
        </uw-tab>

        <uw-tab *ngIf="modal.data.location" name="location-search">
            <section class="uw-tab-body" >
                <div class="container-fluid" [style.marginTop.px]="0">
                    <div class="row">
                        <div class="col-md-15">
                            <h5>Standortsuche nach Koordinaten</h5>
                            <p>Bitte geben Sie die Koordinaten nach ETRS89 / UTM 32N als Rechts- und Hochwert ein.</p>
                        </div>
                    </div>
                    <div class="row" (keydown)="$event.key === 'Enter' ? searchLocBtn.onClick($event) : 0">
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-xs-15"><label>Rechtswert</label></div>
                            </div>
                            <div class="row">
                                <div class="col-xs-15">
                                    <label class="overlay-label">E</label>
                                    <uw-textinput #searchLocLon
                                        class="has-overlay-label"
                                        commitOn="input"
                                    ></uw-textinput>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-xs-15"><label>Hochwert</label></div>
                            </div>
                            <div class="row">
                                <div class="col-xs-15">
                                    <label class="overlay-label">N</label>
                                    <uw-textinput #searchLocLat
                                        class="has-overlay-label"
                                        commitOn="input"
                                    ></uw-textinput>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row space-top">
                        <div class="col-md-10">
                            <uw-inline-button #searchLocBtn
                                class="float-left"
                                iconClass="fa fa-search"
                                title="Standort suchen"
                                value="Standort suchen"
                                (click)="onSearchLocation(searchLocLon.value, searchLocLat.value)"
                            ></uw-inline-button>
                        </div>
                    </div>
                    <div class="row space-top" *ngIf="searchLocationError as error">
                        <div class="col-md-15" *ngIf="error.NOT_A_NUMBER">
                            <i class="fa fa-warning"></i>
                            Bitte geben Sie zwei Fließkommazahlen in ETRS89 / UTM zone 32N ein.
                            Mindestens einer der Werte ist keine Fließkommazahl.
                        </div>
                    </div>
                </div>
            </section>
        </uw-tab>
    
        <uw-tab *ngIf="modal.data.type==='unsetfilter'" name="unsetfilter">
            <section class="uw-tab-body" >
                <div class="container-fluid" [style.marginTop.px]="0">
                    <div class="row">
                        <div class="col-md-15">
                            <p>Zur Anlage einer neuen Probenahmestelle entfernen Sie zunächst bitte alle eingestellten Filter. Wählen Sie hierzu das Werkzeug "Alle Filter zurücksetzen" in der Wekzeugleiste oben rechts.</p>
                            <p>Das Zurücksetzen aller Filter ist notwendig da Ihre neu eingezeichnete Probenahmestelle ansonsten durch eine etwaige Filtereinstellung betroffen sein könnte.</p>
                        </div>
                    </div>
                </div>
            </section>
        </uw-tab>

        <uw-tab *ngIf="modal.data.type==='help'" name="help">
            <section class="uw-tab-body" >
                <div class="container-fluid" [style.marginTop.px]="0">
                    <div class="row">
                        <div class="col-md-15">
                            <p>Im folgenden finden Sie eine Liste mit häufigen Fragen und Antworten zur Anwendung. Für weitere Fragen wenden Sie sich bitte an die Kontaktadresse des Landesamtes.</p>

                            <h5><strong>Hinweise zur Verwendung der Karte:</strong></h5>

                            <p><strong>Zoomen:</strong> Zum hinein- oder heraus-Zoomen bitte den -/+ Button verwenden. Mit einem Doppelklick der linken Maustaste zoomt die Karte ebenfalls auf die nächste Zoomstufe. Bei gedrückter Shift-Taste können Sie mit der Maus in der Karte ein Rechteck aufziehen, in welches die Karte entsprechend hineinzoomt. Bei Verwendung eines Touchscreen kann auch per zwei-Finger-Zoom (pinch-to-zoom) hinein- oder herausgezoomt werden.
                            </p>

                            <p><strong>Verschieben:</strong> Zum Verschieben der Karte bitte mit der Maus in die Karte klicken und bei gehaltener Maustaste in die gewünschte Richtung verschieben.
                            </p>

                            <p><strong>Hintergrundkarten:</strong> Sie können durch Auswahl des entsprechenden Buttons oben links in der Karte zwischen dem Luftbild und der Topographischen Karte wechseln.
                            </p>

                            <p><strong>Nach Gebiet suchen:</strong> In dem Textfeld oben in der Karte können Sie den Namen einer Gemeinde oder eines Kreises in NRW eingeben und durch Auswahl aus der Vorschlagsliste auf dessen Grenzen zoomen.
                            </p>

                            <p><strong>Gebietsinformationen:</strong> Um weitere Informationen eines Schutzgebietes oder einer ... zu bekommen, können Sie mit der Maus in der Karte auf die Fläche des gewünschten Objektes klicken. Danach öffnen sich weiterführende Informationen.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </uw-tab>

        <uw-tab *ngIf="modal.data.type==='imprint'" name="imprint">
            <section class="uw-tab-body" >
                <div class="container-fluid" [style.marginTop.px]="0">
                    <div class="row">
                        <div class="col-md-15">
                                <h5><strong>Herausgeber</strong></h5>
                                <p>
                                    Landesamt für Natur, Umwelt und Verbraucherschutz
                                    <br>Nordrhein-Westfalen (LANUV NRW)
                                    <br>Leibnizstraße 10, D-45659 Recklinghausen
                                    <br>Telefon: (02361) 305-0
                                    <br>E-Mail: poststelle(at)lanuv.nrw.de
                                </p>
                        
                                <h5><strong>Technische und fachliche Umsetzung</strong></h5>
                                <p>
                                    [u] werk - Umwelt &amp; Informationsdienste, Münster
                                    <br>www.u-werk.net
                                </p>

                                <h5><strong>Urheberrechtshinweis</strong></h5>
                                <p>Texte, Bilder, Grafiken sowie Layout dieser Seite unterliegen weltweitem Urheberrecht. Unerlaubte Verwendung, Reproduktion oder Weitergabe einzelner Inhalt oder kompletter Seiten werden sowohl straf- als auch zivilrechtlich verfolgt. Die Rechtsverfolgung nach Rechtsordnungen anderer Länder ist möglich und kann entsprechende rechtliche Konsequenzen nach sich ziehen.</p>

                                <p>Copyright © Land NRW / Landesamt für Natur, Umwelt und Verbraucherschutz NRW. Alle Rechte vorbehalten.</p>

                                <p>Wiedergabe und Weitergabe von Inhalten dieser Web-Site und Verweise darauf sind nur unentgeltlich und unter der Voraussetzung gestattet, dass sowohl die Quelle als auch die Internet-Adresse genannt werden.</p>


                                <h5><strong>Datenschutz</strong></h5>

                                <p>Die ausführlichen Hinweise zum Datenschutz entnehmen Sie bitte unserer <a href="/natura2000-massnahmen/de/datenschutz" title="Datenschutzerklärung" target="_blank">Datenschutzerklärung</a>.</p>
                                
                                <h5><strong>Haftung</strong></h5>
                                <p>Das LANUV NRW stellt ihr Internetangebot unter folgenden Nutzungsbedingungen zur Verfügung:</p>

                                <p>Das LANUV NRW ist für die eigenen Inhalte, die sie zur Nutzung bereithält, nach den allgemeinen Vorschriften verantwortlich. Eine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität kann nicht übernommen werden. Die Haftung für Schäden materieller oder ideeller Art, die durch die Nutzung der Inhalte verursacht wurden, ist ausgeschlossen, sofern nicht Vorsatz oder grobe Fahrlässigkeit vorliegt.</p>
                        </div>
                    </div>
                </div>
            </section>
        </uw-tab>

        <uw-tab *ngIf="modal.data.geometrieSpeichern" name="geometrieSpeichern">
            <section class="uw-tab-body" >
                <div class="container-fluid" [style.marginTop.px]="0">
                    <div class="row">
                        <div class="col-md-15">
                            <p>Die zu speichernde Geometrie wird übertragen und anhand Ihrer hinterlegten Nutzerrechte geprüft. Zuzuordnende Schutzgebiete werden ermittelt und Flächenanteile vorausberechnet. Die Bearbeitungszeit für diesen Prüfungsprozess kann varieren. Bitte warten Sie…</p>
                        </div>
                    </div>
                </div>
            </section>
        </uw-tab>
    </article>

    <footer>
        <div *ngIf="!(modal.data.type === 'info' || modal.data.loaded || modal.data.saving || modal.data.printing)" class="first">
            <span>Wird geladen…</span>
        </div>
        <div *ngIf="modal.data.saving" class="first">
            <span>Wird gespeichert…</span>
        </div>
        <div *ngIf="modal.data.printing" class="first">
            <span>Wird gedruckt…</span>
        </div>
    </footer>
</uw-modal>