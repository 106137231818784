import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFibsHoepreferenzanteil from "./interface";
import FibsHoep from "./../../fibs/hoep/model";
@fn.DataModel
export default class FibsHoepreferenzanteil extends DataModel implements IFibsHoepreferenzanteil {
    constructor(obj?: object | IFibsHoepreferenzanteil) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFibsHoepreferenzanteil): FibsHoepreferenzanteil {
        return new FibsHoepreferenzanteil(obj);
    }
    public deserialize<T extends object>(obj: T | IFibsHoepreferenzanteil): FibsHoepreferenzanteil {
        Object.assign(this, obj);
        obj && obj["fibsHoepByHoep_id"] && (this.fibsHoepByHoep_id = FibsHoep.deserialize(obj["fibsHoepByHoep_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "fischart_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public fischart_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "hoep_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public hoep_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "referenzanteil", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public referenzanteil?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FibsHoep}
     */
    @fn.DataMember({ name: "fibsHoepByHoep_id", type: FibsHoep, nullable: true, memberType: "JOIN", joinProperty: "hoep_id" })
    public fibsHoepByHoep_id?: FibsHoep;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
