<td><uw-textinput
    [(value)]="anzahl"
    [disabled]="readonly"
></uw-textinput></td>
<td><uw-textinput
    [(value)]="laengecm"
    [disabled]="readonly"
></uw-textinput></td>
<td><uw-textinput
    [(value)]="gewichtg"
    [disabled]="readonly"
></uw-textinput></td>
<td 
    *ngIf="!readonly"
    style="width:24px;padding:0;"
><uw-inline-uncheck-button
    checked="true"
    hideIcon="true"
    width="24"
    (click)="delete($event)"
></uw-inline-uncheck-button></td>