/// <reference path="./../../../node_modules/leaflet/dist/index.d.ts"/>

import { Injectable, EventEmitter } from "@angular/core";
import { Proj } from "proj4";
import {
    Map,
    CRS,
    LayerGroup,
    Layer,
    Canvas,
    SVG,
    Marker,
    Point,
    LatLng
} from "leaflet";
import { Observable, BehaviorSubject, fromEvent } from "rxjs";
import { UWMapService } from "ng-uw-map-leaflet";
import leafletImg from "leaflet-image";

import DataService from "./../data/service";
import UserService from "./../user/service";
import { UWWebWorkerService } from "ng-uw-web-worker-service";
import ModalService from "./../modal/service";
import GridLayerService from "./../map-gridlayer/service";

import * as geojson from "geojson";

/**
 * Dieser Dienst manipuliert die Karte und ihren Inhalt.
 * 
 * Das erlaubt anderen Teile der Anwendung den Kartenausschnitt zu manipulieren, Grafikebenen der Karte zu verwalten und Geometrien in die Karte einzuzeichnen.
 */
@Injectable({
    providedIn: 'root'
})
export default class MapService extends UWMapService {
    public authToken: string = "";
    public wmsLayers: any = {};
    public wmsLayerStock: any = [{
        group: "Einzugsgebiete",
        name: "Übergeordnete Teileinzugsgebiete",
        layer: "0",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 3,
        maxZoom: 8,
        zIndex: 4,
    }, {
        //     group: "Einzugsgebiete",
        //     name: "Größere Einzugsgebiete",
        //     layer: "2",
        //     url: "//www.wms.nrw.de/umwelt/gewstat3c",
        //     minZoom: 5,
        //     maxZoom: 6,
        //     zIndex: 4,
        //     chosen: false
        // }, {
        group: "Einzugsgebiete",
        name: "Basiseinzugsgebiete",
        layer: "11",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 9,
        maxZoom: 30,
        zIndex: 4,
        chosen: false,
    },

    {
        //     group: "Gewässer",
        //     name: "Größere Fließgewässer -generalisiert-",
        //     layer: "5",
        //     url: "//www.wms.nrw.de/umwelt/gewstat3c",
        //     minZoom: 3,
        //     maxZoom: 4,
        //     zIndex: 4,
        //     chosen: false,
        // }, {
        group: "Gewässer",
        name: "Größere Fließgewässer",
        layer: "10",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 3,
        maxZoom: 30,
        zIndex: 4,
    }, {
        group: "Gewässer",
        name: "Mittlere Fließgewässer",
        layer: "9",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 6,
        maxZoom: 30,
        zIndex: 4,
    }, {
        group: "Gewässer",
        name: "Kleinere Fließgewässer",
        layer: "8",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 8,
        maxZoom: 30,
        zIndex: 4,
    }, {
        group: "Gewässer",
        name: "Sonstige Fließgewässer",
        layer: "4",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 9,
        maxZoom: 30,
        zIndex: 4,
        chosen: false,
    }, {
        group: "Gewässer",
        name: "Gewässerflächen",
        layer: "5",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 7,
        maxZoom: 30,
        zIndex: 4,
    }, {
        group: "Gewässer",
        name: "Sonstige Gewässerflächen – Größere",
        layer: "3",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 7,
        maxZoom: 30,
        zIndex: 4,
        chosen: false
    }, {
        group: "Gewässer",
        name: "Sonstige Gewässerflächen – Mittlere bis kleine",
        layer: "2",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 9,
        maxZoom: 30,
        zIndex: 4,
        chosen: false
        // }, {
        //     group: "Gewässer",
        //     name: "Schifffahrtskanäle",
        //     layer: "4",
        //     url: "//www.wms.nrw.de/umwelt/gewstat3c",
        //     minZoom: 0,
        //     maxZoom: 30,
        //     zIndex: 4,
        //     chosen: false
        // }, {
        //     group: "Gewässer",
        //     name: "Beschriftung Fließgewässer und Kanäle",
        //     layer: "19,20",
        //     url: "//www.wms.nrw.de/umwelt/gewstat3c",
        //     minZoom: 6,
        //     maxZoom: 30,
        //     zIndex: 4,
        //     chosen: false
    },

    {
        //     group: "Gewässerstationierung",
        //     name: "Stationierungslinie in Gewässerflächen",
        //     layer: "11",
        //     url: "//www.wms.nrw.de/umwelt/gewstat3c",
        //     minZoom: 9,
        //     maxZoom: 30,
        //     zIndex: 4,
        //     chosen: false
        // }, {
        group: "Gewässerstationierung",
        name: "Stationierung KP",
        layer: "15",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 9,
        maxZoom: 30,
        zIndex: 4,
        chosen: false
    }, {
        group: "Gewässerstationierung",
        name: "Stationierung FP",
        layer: "14",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 10,
        maxZoom: 30,
        zIndex: 4,
        chosen: false
    }, {
        group: "Gewässerstationierung",
        name: "Stationierung HP",
        layer: "13",
        url: "//www.wms.nrw.de/umwelt/gsk3e",
        minZoom: 11,
        maxZoom: 30,
        zIndex: 4,
        chosen: false
    },

    // {
    //     group: "Gewässerstationierung",
    //     name: "Stationierung Kanäle KP",
    //     layer: "15",
    //     url: "//www.wms.nrw.de/umwelt/gewstat3c",
    //     minZoom: 9,
    //     maxZoom: 30,
    //     zIndex: 4,
    //     chosen: false
    // }, {
    //     group: "Gewässerstationierung",
    //     name: "Stationierung Kanäle FP",
    //     layer: "14",
    //     url: "//www.wms.nrw.de/umwelt/gewstat3c",
    //     minZoom: 10,
    //     maxZoom: 30,
    //     zIndex: 4,
    //     chosen: false
    // }, {
    //     group: "Gewässerstationierung",
    //     name: "Stationierung Kanäle HP",
    //     layer: "13",
    //     url: "//www.wms.nrw.de/umwelt/gewstat3c",
    //     minZoom: 11,
    //     maxZoom: 30,
    //     zIndex: 4,
    //     chosen: false
    // },

    {
        group: "FFH- und Vogelschutzgebiete",
        name: "FFH-Gebiete",
        layer: "FFH-Gebiete",
        url: "//www.wms.nrw.de/umwelt/linfos",
        minZoom: 3,
        maxZoom: 30,
        zIndex: 3,
        chosen: false
    }, {
        group: "FFH- und Vogelschutzgebiete",
        name: "Vogelschutzgebiete",
        layer: "Vogelschutzgebiete",
        url: "//www.wms.nrw.de/umwelt/linfos",
        minZoom: 3,
        maxZoom: 30,
        zIndex: 3,
        chosen: false
    },

    {
        group: "Verwaltungseinheiten",
        name: "Gemeindegrenzen",
        layer: "nw_dvg_gem",
        url: "//www.wms.nrw.de/geobasis/wms_nw_dvg",
        minZoom: 4,
        maxZoom: 30,
        zIndex: 3,
        chosen: false
    }, {
        group: "Verwaltungseinheiten",
        name: "Kreisgrenzen",
        layer: "nw_dvg_krs",
        url: "//www.wms.nrw.de/geobasis/wms_nw_dvg",
        minZoom: 3,
        maxZoom: 30,
        zIndex: 10,
        chosen: false
    }, {
        group: "Verwaltungseinheiten",
        name: "Bezirksgrenzen",
        layer: "nw_dvg_rbz",
        url: "//www.wms.nrw.de/geobasis/wms_nw_dvg",
        minZoom: 1,
        maxZoom: 30,
        zIndex: 19,
        chosen: false
    }];

    public objectCreate: EventEmitter<void> = new EventEmitter();

    public __mouseHoverObservable: any;
    public __clickObservable: any;

    public chosenBaseLayer = null;
    constructor(
        protected dataService: DataService,
        protected userService: UserService,
        protected worker: UWWebWorkerService,
        protected modalService: ModalService,
        protected gridLayerService: GridLayerService
    ) {
        super();
        this.editable = true;
        this.editOptions = {};
        this.linkColor = "#ffce42";
    }

    public addCircleMarker(
        lat: number,
        lng: number,
        projection: string,
        options?: L.CircleMarkerOptions,
    ): string {
        const id = "circlemarker-" + Date.now();
        [lng, lat] = this.project(projection, this.projection, [lng, lat]);
        this.markers[id] = L.circleMarker(
            { lat, lng },
            options
        ).addTo(this.map) as any;
        return id;
    }

    public zoomToLatLng(
        lat: number,
        lng: number,
        projection: string = "EPSG:25832",
    ) {
        this.zoomGeometry(
            { type: "Point", coordinates: [lng, lat] } as geojson.Point as any,
            projection,
        );
    }

    public switchBaseLayer(layer: string): void {
        this.drawBaseLayer(layer);
    }

    public drawBaseLayer(layer?: string): void {
        this.chosenBaseLayer = layer;
        if (this.baseLayer) {
            this.map.removeLayer(this.baseLayer);
        }
        this.baseLayer = layer && layer === "luftbild"
            ? L.tileLayer(
                "//www.wmts.nrw.de/dop/tiles/dop/EPSG_25832_15/{z}/{x}/{y}.jpeg",
                {
                    detectRetina: true,
                    errorTileUrl:
                        "//www.wmts.nrw.de/webatlas_grau/tiles/webatlas_grau/EPSG_25832_15/04/1/7.png"
                }
            )
            : L.tileLayer(
                "//www.wmts.nrw.de/geobasis/wmts_nw_dtk/tiles/nw_dtk_sw/EPSG_25832_16/{z}/{x}/{y}.png",
                {
                    detectRetina: true,
                    errorTileUrl:
                        "//www.wmts.nrw.de/webatlas_grau/tiles/webatlas_grau/EPSG_25832_15/04/1/7.png"
                }
            )
        this.baseLayer.addTo(this.map);
        this.checkAdditionalLayers(true);
    }

    public wmsLayerInMassstabList: any[] = [];
    private renderWMSLayers(): any[] {
        const zoom = this.map.getZoom();
        this.wmsLayerInMassstabList = this.wmsLayerStock
            .map(layer => { layer.chosen = layer.chosen === false ? false : true; return layer; })
            .filter(layer => zoom >= layer.minZoom && zoom <= layer.maxZoom)
            ;
        return this.wmsLayerInMassstabList
            .filter(layer => layer.chosen)
            ;
    }

    public checkAdditionalLayers(redraw?: boolean): void {
        let layers = this.renderWMSLayers();

        let layerCompounds = [];
        for (let i = 0; i < layers.length; i++) {
            const compound = layers[i].zIndex + layers[i].url;
            layerCompounds[compound] =
                layerCompounds[compound]
                    ? Object.assign(layerCompounds[compound], {
                        layer: layerCompounds[compound].layer + "," + layers[i].layer
                    })
                    : JSON.parse(JSON.stringify(layers[i]))
                ;
        }

        for (let layer in this.wmsLayers) {
            if (this.wmsLayers[layer] !== null) {
                let found = false;
                for (let compound in layerCompounds) {
                    const compoundName = compound + layerCompounds[compound].layer;
                    if (compoundName === layer) {
                        found = true;
                    }
                }
                if (
                    redraw
                    || !found
                ) {
                    this.map.removeLayer(this.wmsLayers[layer]);
                    this.wmsLayers[layer] = null;
                }
            }
        }

        for (let compound in layerCompounds) {
            const compoundName = compound + layerCompounds[compound].layer;
            if (
                !this.wmsLayers[compoundName]
            ) {
                this.wmsLayers[compoundName] = L["nonTiledLayer"]["wms"](
                    layerCompounds[compound].url,
                    Object.assign({
                        layers: layerCompounds[compound].layer,
                        format: layerCompounds[compound].format || 'image/png',
                        detectRetina: layerCompounds[compound].detectRetina === false ? false : true,
                        transparent: layerCompounds[compound].transparent === false ? false : true,
                        pane: layerCompounds[compound].pane || 'tilePane',
                        zIndex: layerCompounds[compound].zIndex || 4,
                        srs: layerCompounds[compound].srs || "EPSG:25832",
                    }, layerCompounds[compound].options)
                );
                this.map.addLayer(this.wmsLayers[compoundName]);
            }
        }
    }

    public onZoomEnd(event: any) {
        this.checkAdditionalLayers();
    }

    public addMapFeatureGroupLayer(name, style): any {
        this.layers[name] = L.featureGroup();
        this.layers[name]["style"] = style || {};
        this.layers[name].addTo(this.map);
    }

    public getMapFeatureStyle(type: string): any {
        switch (type) {
            case "Gebietsbezugfilter":
                return {
                    weight: 2,
                    color: "#E30513",
                    opacity: 1,
                    dashArray: "5,5",
                    fill: false,
                    renderer: this.canvasRenderer,
                };
            case "Kartensuche":
                return {
                    weight: 2,
                    color: "#FFDD00",
                    opacity: 1,
                    dashArray: "5,5",
                    fill: false,
                    renderer: this.canvasRenderer,
                };
            default:
                return {};
        }
    }

    public drawFeatureLayer(): void {
    }

    public clickOnMap(e: any): boolean {
        const pixelRadius = 4,
            featureHashMap: any = new Map<number, boolean>(),
            latLng = this.project(this.projection, "EPSG:25832", [
                e.latlng.lng,
                e.latlng.lat
            ]),
            formatLatLngStr = (c: string, i: number, a: string) => {
                return i && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
            },
            bbox = this.createBBoxFromLatLng(
                e.latlng.lng,
                e.latlng.lat,
                "EPSG:25832",
                2 * this.gridLayerService.iconScale[this.map.getZoom()]
            )
            ;

        let items: Number[] = [];
        let i = 0;
        this.gridLayerService.spatialIndexTree["probenahmestelle"].forEach(index => {
            items.push(...index.range(Number(bbox[0]), Number(bbox[3]), Number(bbox[2]), Number(bbox[1])).map(id => this.gridLayerService.data["probenahmestelle"][i][id].id));
            i++;
        });

        const features = this.dataService.dataStore["probenahmestelle"].filter(x => items.includes(x.id));
        this.modalService.closeModals();
        const modalId: string = this.modalService.createModal({
            title: "Gebiete",
            status: "",
            data: {
                type: features.length === 1 ? "probenahmestelle" : "wmsFeatureRequest",
                activeTab: features.length === 1 ? "probenahmestelle" : "wmsFeatureRequest",
                probenahmestelleToBeLoaded: features.length === 1 ? features[0] : null,
                wmsFeatureRequest: features,
                latLng: "E " + latLng[0].toFixed(0).replace(/./g, formatLatLngStr) + " N " + latLng[1].toFixed(0).replace(/./g, formatLatLngStr) + " ETRS89 / UTM zone 32N",
                loaded: 1
            }
        });

        return true;
    }


    protected getBaseCRS(): CRS {
        return (this.baseCRS = this.getCustomCRS(this.projection));
    }

    public initMap(options: any): void {
        this.transformation = new L.Transformation(1, 0, -1, 0);
        // options noch in "map-service-options.interface.ts" auslagern und definieren
        this.htmlElement = options.htmlElement;
        this.hostName = options.hostName;
        this.canvasRenderer = L.Browser.canvas ? L.canvas() : L.svg();
        this.resolution =
            typeof options.resolution !== "undefined"
                ? options.resolution
                : this.resolution;
        this.bounds =
            typeof options.bounds !== "undefined" ? options.bounds : this.bounds;
        this.transformation =
            typeof options.transformation !== "undefined"
                ? options.transformation
                : this.transformation;
        this.tileSize =
            typeof options.tileSize !== "undefined"
                ? options.tileSize
                : this.tileSize;
        this.maxZoom =
            typeof options.maxZoom !== "undefined" ? options.maxZoom : this.maxZoom;
        this.zoomSnap =
            typeof options.zoomSnap !== "undefined"
                ? options.zoomSnap
                : this.zoomSnap;
        this.keyboard =
            typeof options.keyboard !== "undefined"
                ? options.keyboard
                : this.keyboard;
        this.zoomControl =
            typeof options.zoomControl !== "undefined"
                ? options.zoomControl
                : this.zoomControl;
        this.scrollWheelZoom =
            typeof options.scrollWheelZoom !== "undefined"
                ? options.scrollWheelZoom
                : this.scrollWheelZoom;
        this.boxZoom =
            typeof options.boxZoom !== "undefined" ? options.boxZoom : this.boxZoom;
        this.doubleClickZoom =
            typeof options.doubleClickZoom !== "undefined"
                ? options.doubleClickZoom
                : this.doubleClickZoom;
        this.dragging =
            typeof options.dragging !== "undefined"
                ? options.dragging
                : this.dragging;
        this.editable =
            typeof options.editable !== "undefined"
                ? options.editable
                : this.editable;
        this.editOptions =
            typeof options.editOptions !== "undefined"
                ? options.editOptions
                : this.editOptions;
        this.projection = "EPSG:4326";

        this.map = L.map(this.htmlElement, {
            crs: this.getCustomCRS("EPSG:25832"),

            editable: this.editable,
            editOptions: this.editOptions,
            maxZoom: this.maxZoom ? this.maxZoom : this.resolution.length - 3,
            zoomSnap: this.zoomSnap,
            keyboard: this.keyboard,
            zoomControl: this.zoomControl,
            scrollWheelZoom: this.scrollWheelZoom,
            boxZoom: this.boxZoom,
            doubleClickZoom: this.doubleClickZoom,
            dragging: this.dragging
        });

        this.map.on('zoomend', (e) => { this.onZoomEnd(e); });

        this.setAttributionPrefix("");
        this.__mouseHoverObservable = fromEvent(this.map, "mousemove");
        this.__mouseHoverObservable.subscribe(event => {
            this.__mouseHover.next(event);
        });
        this.__clickObservable = fromEvent(this.map, "click");
        this.__clickObservable.subscribe(event => {
            this.__click.next(event);
        });
    }

    public getMapAsCanvas() {
        return new Promise<HTMLCanvasElement>((resolve, reject) =>
            leafletImg(this.map, {
                proxy: this.dataService.webServiceApi + "wmsproxy?url=",
            }, (err, canvas) =>
                    err ? reject(err) : resolve(canvas)
            )
        );
    }
}
