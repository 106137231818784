import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { pluck } from "rxjs/operators";

import SuggestionService from "./service.interface";
import SuggestionServiceOptions from "./options.interface";
import KeyValue from "./key-value.interface";
import { LoginService } from "ng-uw-login-service";

@Injectable()
export default class DynamicSuggestionService implements SuggestionService {
    protected __limit: number = 10;
    protected __matcherFor: (pattern: string) => (suggestion: KeyValue) => boolean = (pattern: string) => (suggestion: KeyValue) => true;
    protected __itemsAccess: Array<string> = [".resultset"];
    protected __webServiceApiUrl: string = "/";
    protected __webServiceApiTokenBasedLoginUrl: string = "/";

    constructor(
        protected http: HttpClient
        , protected login: LoginService
    ) {
    }

    public config(options: SuggestionServiceOptions): SuggestionService {
        this.__limit = options.limit === null ? this.__limit : options.limit;
        this.__webServiceApiUrl = options.webServiceApiUrl === null ? this.__webServiceApiUrl : options.webServiceApiUrl;
        this.__webServiceApiTokenBasedLoginUrl = options.webServiceApiTokenBasedLoginUrl === null ? this.__webServiceApiTokenBasedLoginUrl : options.webServiceApiTokenBasedLoginUrl;
        this.__itemsAccess = options.itemsAccess === null ? this.__itemsAccess : options.itemsAccess;
        this.__matcherFor = options.matcherFactory === null ? this.__matcherFor : options.matcherFactory;
        return <SuggestionService>this;
    }

    public search(pattern: { [name: string]: string }): Promise<any[]> {
        if (!pattern) {
            return <Promise<any>>Promise.reject(Object.create(Error.prototype, { name: { value: "EmptyPatternError" } }));
        }
        return this.__webServiceApiTokenBasedLoginUrl
            ? this.login.getAuthToken(this.__webServiceApiTokenBasedLoginUrl)
                .then((token: string) => {
                    return this._querywebServiceApiUrl(pattern, token);
                })
            : this._querywebServiceApiUrl(pattern)
            ;
    }

    protected _querywebServiceApiUrl(pattern: { [name: string]: string }, token?: string): Promise<Array<any>> {
        return this.http
            .get(this._getBaseURl(this._getUrl(pattern, this.__limit)), {
                params: this._getParams(this._getUrl(pattern, this.__limit))
                , headers: this._getHeaders(token)
            })
            .pipe(pluck(...(this.__itemsAccess || [])))
            .toPromise(Promise)
            .then((items: any) => {
                if (items == null || !Array.isArray(items)) throw new TypeError("invalid search result");
                return this._filterWithMatcher(items, pattern).slice(0, this.__limit);
            })
            ;
    }

    protected _getHeaders(token?: string): HttpHeaders | null {
        if (token) {
            const headers = new HttpHeaders()
                .append("X-Auth-Token", token)
                ;
            return headers;
        }
        return null;
    }

    protected _getParams(url: string): HttpParams {
        let params = new HttpParams();
        url = url.split("?")[1];
        if (url) {
            params = url.split("&").reduce(
                (params, element) =>
                    params.set(element.split("=")[0], decodeURIComponent(element.split("=")[1]))
                , params
            );
        }
        return params;
    }

    protected _getBaseURl(url: string): string {
        return url.split("?")[0];
    }

    protected _getUrl(pattern: { [name: string]: string }, limit: number): string {
        let url = this.__webServiceApiUrl;
        for (let key in pattern) {
            url = pattern.hasOwnProperty(key) ? url.replace("{" + key + "}", encodeURIComponent(pattern[key])) : url;
        }
        return url.replace("{limit}", encodeURIComponent(limit != null ? String(limit) : ""));
    }

    protected _filterWithMatcher(items, pattern): Array<any> {
        if (this.__matcherFor) {
            return items.filter(this.__matcherFor(pattern));
        }
        else return items;
    }
}
