import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoSubstrat from "./interface";
import FischinfoSubstratprozent from "./../../fischinfo/substratprozent/model";
@fn.DataModel
export default class FischinfoSubstrat extends DataModel implements IFischinfoSubstrat {
    constructor(obj?: object | IFischinfoSubstrat) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoSubstrat): FischinfoSubstrat {
        return new FischinfoSubstrat(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoSubstrat): FischinfoSubstrat {
        Object.assign(this, obj);
        obj && obj["fischinfoSubstratprozentListByForeignSubstrat_id"] && obj["fischinfoSubstratprozentListByForeignSubstrat_id"].length && (this.fischinfoSubstratprozentListByForeignSubstrat_id = obj["fischinfoSubstratprozentListByForeignSubstrat_id"].map(FischinfoSubstratprozent.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoSubstratprozent>}
     */
    @fn.DataMember({ name: "fischinfoSubstratprozentListByForeignSubstrat_id", type: [FischinfoSubstratprozent], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoSubstratprozentListByForeignSubstrat_id?: Array<FischinfoSubstratprozent>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
