import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoAnlass from "./interface";
import FischinfoUntersuchunganlass from "./../../fischinfo/untersuchunganlass/model";
@fn.DataModel
export default class FischinfoAnlass extends DataModel implements IFischinfoAnlass {
    constructor(obj?: object | IFischinfoAnlass) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoAnlass): FischinfoAnlass {
        return new FischinfoAnlass(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoAnlass): FischinfoAnlass {
        Object.assign(this, obj);
        obj && obj["fischinfoUntersuchunganlassListByForeignAnlass_id"] && obj["fischinfoUntersuchunganlassListByForeignAnlass_id"].length && (this.fischinfoUntersuchunganlassListByForeignAnlass_id = obj["fischinfoUntersuchunganlassListByForeignAnlass_id"].map(FischinfoUntersuchunganlass.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    @fn.Label("Anlass, Art der Befischung")
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "kurzbezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public kurzbezeichnung?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoUntersuchunganlass>}
     */
    @fn.DataMember({ name: "fischinfoUntersuchunganlassListByForeignAnlass_id", type: [FischinfoUntersuchunganlass], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoUntersuchunganlassListByForeignAnlass_id?: Array<FischinfoUntersuchunganlass>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
