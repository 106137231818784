import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoGewaesserzustand from "./interface";
import FischinfoBeeintraechtigunggesamt from "./../../fischinfo/beeintraechtigunggesamt/model";
import FischinfoGewaesserzustandeinzelbeeintraechtigung from "./../../fischinfo/gewaesserzustandeinzelbeeintraechtigung/model";
import FischinfoGewaesserzustandpflanzenart from "./../../fischinfo/gewaesserzustandpflanzenart/model";
import FischinfoGewaesserzustanduferpflanze from "./../../fischinfo/gewaesserzustanduferpflanze/model";
import FischinfoSubstratprozent from "./../../fischinfo/substratprozent/model";
import FischinfoGewaesserzustandausbauzustand from "./../../fischinfo/gewaesserzustandausbauzustand/model";
import FischinfoStrukturelementehaeufigkeit from "./../../fischinfo/strukturelementehaeufigkeit/model";
import FischinfoBefischung from "./../../fischinfo/befischung/model";
import FischinfoGewaesserzustandumland from "./../../fischinfo/gewaesserzustandumland/model";
import FischinfoLinienfuehrung from "./../../fischinfo/linienfuehrung/model";
import FischinfoStroemung from "./../../fischinfo/stroemung/model";
import FischinfoWasserstand from "./../../fischinfo/wasserstand/model";
@fn.DataModel
export default class FischinfoGewaesserzustand extends DataModel implements IFischinfoGewaesserzustand {
    constructor(obj?: object | IFischinfoGewaesserzustand) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoGewaesserzustand): FischinfoGewaesserzustand {
        return new FischinfoGewaesserzustand(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoGewaesserzustand): FischinfoGewaesserzustand {
        Object.assign(this, obj);
        obj && obj["fischinfoBeeintraechtigunggesamtByBeeintraechtigunggesamt_id"] && (this.fischinfoBeeintraechtigunggesamtByBeeintraechtigunggesamt_id = FischinfoBeeintraechtigunggesamt.deserialize(obj["fischinfoBeeintraechtigunggesamtByBeeintraechtigunggesamt_id"]));
        obj && obj["fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id"] && obj["fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id"].length && (this.fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id = obj["fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id"].map(FischinfoGewaesserzustandeinzelbeeintraechtigung.deserialize));
        obj && obj["fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id"] && obj["fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id"].length && (this.fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id = obj["fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id"].map(FischinfoGewaesserzustandpflanzenart.deserialize));
        obj && obj["fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id"] && obj["fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id"].length && (this.fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id = obj["fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id"].map(FischinfoGewaesserzustanduferpflanze.deserialize));
        obj && obj["fischinfoSubstratprozentListByForeignGewaesserzustand_id"] && obj["fischinfoSubstratprozentListByForeignGewaesserzustand_id"].length && (this.fischinfoSubstratprozentListByForeignGewaesserzustand_id = obj["fischinfoSubstratprozentListByForeignGewaesserzustand_id"].map(FischinfoSubstratprozent.deserialize));
        obj && obj["fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id"] && obj["fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id"].length && (this.fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id = obj["fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id"].map(FischinfoGewaesserzustandausbauzustand.deserialize));
        obj && obj["fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id"] && obj["fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id"].length && (this.fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id = obj["fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id"].map(FischinfoStrukturelementehaeufigkeit.deserialize));
        obj && obj["fischinfoBefischungListByForeignGewaesserzustand_id"] && obj["fischinfoBefischungListByForeignGewaesserzustand_id"].length && (this.fischinfoBefischungListByForeignGewaesserzustand_id = obj["fischinfoBefischungListByForeignGewaesserzustand_id"].map(FischinfoBefischung.deserialize));
        obj && obj["fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id"] && obj["fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id"].length && (this.fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id = obj["fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id"].map(FischinfoGewaesserzustandumland.deserialize));
        obj && obj["fischinfoLinienfuehrungByLinienfuehrung_id"] && (this.fischinfoLinienfuehrungByLinienfuehrung_id = FischinfoLinienfuehrung.deserialize(obj["fischinfoLinienfuehrungByLinienfuehrung_id"]));
        obj && obj["fischinfoStroemungByStroemung_id"] && (this.fischinfoStroemungByStroemung_id = FischinfoStroemung.deserialize(obj["fischinfoStroemungByStroemung_id"]));
        obj && obj["fischinfoWasserstandByWasserstand_id"] && (this.fischinfoWasserstandByWasserstand_id = FischinfoWasserstand.deserialize(obj["fischinfoWasserstandByWasserstand_id"]));
        return this;
    }

    /**
     * @type {numner} smallint
     */
    @fn.DataMember({ name: "angebunden", type: Number, databaseType: "smallint", nullable: true, memberType: "" })
    @fn.Label("Anbindung des Stillgewässers")
    public angebunden?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "beeintraechtigunggesamt_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    @fn.Label("Beeinträchtigung Gesamteindruck")
    public beeintraechtigunggesamt_id?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "beschattung", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Beschattung (%)")
    public beschattung?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "gewaesserbreite", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Gewässerbreite (m)")
    public gewaesserbreite?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "gewaesserflaeche", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Gewässerfläche (ha)")
    public gewaesserflaeche?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "gewaessertiefe", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("mittlere Gewässertiefe im befischten Bereich (m)")
    public gewaessertiefe?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "leitfaehigkeit", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Leitfähigkeit (µS)")
    public leitfaehigkeit?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "linienfuehrung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    @fn.Label("Linienführung")
    public linienfuehrung_id?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "ph", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("pH-Wert")
    public ph?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "referenznr", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public referenznr?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "sonstigebeeintraechtigung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public sonstigebeeintraechtigung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "sonstigessubstrat", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public sonstigessubstrat?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "stroemung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    @fn.Label("Strömung")
    public stroemung_id?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "truebung", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    @fn.Label("Trübung")
    public truebung?: boolean;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "wasserstand_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    @fn.Label("Wasserstand")
    public wasserstand_id?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "wassertemperatur", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Wassertemperatur (°C)")
    public wassertemperatur?: number;

    /**
     * @type {FischinfoBeeintraechtigunggesamt}
     */
    @fn.DataMember({ name: "fischinfoBeeintraechtigunggesamtByBeeintraechtigunggesamt_id", type: FischinfoBeeintraechtigunggesamt, nullable: true, memberType: "JOIN", joinProperty: "beeintraechtigunggesamt_id" })
    @fn.Label("Beeinträchtigung Gesamteindruck")
    public fischinfoBeeintraechtigunggesamtByBeeintraechtigunggesamt_id?: FischinfoBeeintraechtigunggesamt;

    /**
     * @type {Array<FischinfoGewaesserzustandeinzelbeeintraechtigung>}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id", type: [FischinfoGewaesserzustandeinzelbeeintraechtigung], nullable: true, memberType: "FOREIGN JOIN" })
    @fn.Label("Einzelbeeinträchtigung")
    public fischinfoGewaesserzustandeinzelbeeintraechtigungListByForeignGewaesserzustand_id?: Array<FischinfoGewaesserzustandeinzelbeeintraechtigung>;

    /**
     * @type {Array<FischinfoGewaesserzustandpflanzenart>}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id", type: [FischinfoGewaesserzustandpflanzenart], nullable: true, memberType: "FOREIGN JOIN" })
    @fn.Label("Pflanzenarten")
    public fischinfoGewaesserzustandpflanzenartListByForeignGewaesserzustand_id?: Array<FischinfoGewaesserzustandpflanzenart>;

    /**
     * @type {Array<FischinfoGewaesserzustanduferpflanze>}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id", type: [FischinfoGewaesserzustanduferpflanze], nullable: true, memberType: "FOREIGN JOIN" })
    @fn.Label("Pflanzenbestand Uferböschung")
    public fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id?: Array<FischinfoGewaesserzustanduferpflanze>;

    /**
     * @type {Array<FischinfoSubstratprozent>}
     */
    @fn.DataMember({ name: "fischinfoSubstratprozentListByForeignGewaesserzustand_id", type: [FischinfoSubstratprozent], nullable: true, memberType: "FOREIGN JOIN" })
    @fn.Label("Untergrund/Substrat (Anteil %)")
    public fischinfoSubstratprozentListByForeignGewaesserzustand_id?: Array<FischinfoSubstratprozent>;

    /**
     * @type {Array<FischinfoGewaesserzustandausbauzustand>}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id", type: [FischinfoGewaesserzustandausbauzustand], nullable: true, memberType: "FOREIGN JOIN" })
    @fn.Label("Ausbauzustand")
    public fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id?: Array<FischinfoGewaesserzustandausbauzustand>;

    /**
     * @type {Array<FischinfoStrukturelementehaeufigkeit>}
     */
    @fn.DataMember({ name: "fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id", type: [FischinfoStrukturelementehaeufigkeit], nullable: true, memberType: "FOREIGN JOIN" })
    @fn.Label("Fischrelevante Strukturelemente")
    public fischinfoStrukturelementehaeufigkeitListByForeignGewaesserzustand_id?: Array<FischinfoStrukturelementehaeufigkeit>;

    /**
     * @type {Array<FischinfoBefischung>}
     */
    @fn.DataMember({ name: "fischinfoBefischungListByForeignGewaesserzustand_id", type: [FischinfoBefischung], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBefischungListByForeignGewaesserzustand_id?: Array<FischinfoBefischung>;

    /**
     * @type {Array<FischinfoGewaesserzustandumland>}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id", type: [FischinfoGewaesserzustandumland], nullable: true, memberType: "FOREIGN JOIN" })
    @fn.Label("Umland")
    public fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id?: Array<FischinfoGewaesserzustandumland>;

    /**
     * @type {FischinfoLinienfuehrung}
     */
    @fn.DataMember({ name: "fischinfoLinienfuehrungByLinienfuehrung_id", type: FischinfoLinienfuehrung, nullable: true, memberType: "JOIN", joinProperty: "linienfuehrung_id" })
    public fischinfoLinienfuehrungByLinienfuehrung_id?: FischinfoLinienfuehrung;

    /**
     * @type {FischinfoStroemung}
     */
    @fn.DataMember({ name: "fischinfoStroemungByStroemung_id", type: FischinfoStroemung, nullable: true, memberType: "JOIN", joinProperty: "stroemung_id" })
    public fischinfoStroemungByStroemung_id?: FischinfoStroemung;

    /**
     * @type {FischinfoWasserstand}
     */
    @fn.DataMember({ name: "fischinfoWasserstandByWasserstand_id", type: FischinfoWasserstand, nullable: true, memberType: "JOIN", joinProperty: "wasserstand_id" })
    public fischinfoWasserstandByWasserstand_id?: FischinfoWasserstand;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
