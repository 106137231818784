import { Component } from "@angular/core";
import { UWLogin } from "ng-uw-login";
import { MD5 } from "crypto-js";
/**
 * Diese Komponente ist ein Anmeldeformular.
 *
 * Nachdem der Nutzer seine Anmeldedaten eingegeben hat, meldet das Anmeldeformular den Nutzer bei einem WebService an.
 */
@Component({
    selector: "custom-login",
    templateUrl: "./base.component.html",
    styleUrls: [
        "./base.component.less"
    ]
})
export default class Login extends UWLogin {
    public get passwordSHA1(): string {
        return MD5(this.password);
    }
    ;
}
