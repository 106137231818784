import { Component, Input, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import DataService from "../data/service";
import FischinfoMethodegeraetetyp from "../../models/fischinfo/methodegeraetetyp/model";
import FischinfoNetz from "../../models/fischinfo/netz/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
/**
 * Mit dieser Komponente kann der Nutzer die Maße eines Netzes einer Befischung ansehen und ggf. bearbeiten.
 */
@Component({
    selector: "[form-fischinfo-untersuchung-befischung-methode-netz-angabe]",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungMethodeNetzAngabe {
    @Input()
    public modalId: string;
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public netz: FischinfoNetz;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get hoehe(): string {
        return this.netz
            && this.netz.hoehe
            && String(this.netz.hoehe).replace(".", ",");
    }
    public set hoehe(value: string) {
        this.netz
            && (this.netz.hoehe = parseFloat(String(value).replace(",", ".")) || null);
    }
    public get laenge(): string {
        return this.netz
            && this.netz.laenge
            && String(this.netz.laenge).replace(".", ",");
    }
    public set laenge(value: string) {
        this.netz
            && (this.netz.laenge = parseFloat(String(value).replace(",", ".")) || null);
    }
    public get weitebis(): string {
        return this.netz
            && this.netz.weitebis
            && String(this.netz.weitebis).replace(".", ",");
    }
    public set weitebis(value: string) {
        this.netz
            && (this.netz.weitebis = parseFloat(String(value).replace(",", ".")) || null);
    }
    public get weitevon(): string {
        return this.netz
            && this.netz.weitevon
            && String(this.netz.weitevon).replace(".", ",");
    }
    public set weitevon(value: string) {
        this.netz
            && (this.netz.weitevon = parseFloat(String(value).replace(",", ".")) || null);
    }
    public get position(): string {
        return this.netz
            && this.netz.position;
    }
    public set position(value: string) {
        this.netz
            && (this.netz.position = value);
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    public delete(e: Event) {
        this.formFischinfoUntersuchungService.modelUtil
            .remove<FischinfoNetz>(this.untersuchung, "FischinfoNetz", this.netz);
        this.changed.emit(true);
    }
}
