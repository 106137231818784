import { Component, Input, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import DataService from "../data/service";
import FischinfoHaeufigkeitbefischungergebnis from "../../models/fischinfo/haeufigkeitbefischungergebnis/model";
import FischinfoHaeufigkeitsmuschelergebnis from "../../models/fischinfo/haeufigkeitsmuschelergebnis/model";
import FischinfoHaeufigkeitkrebsergebnis from "../../models/fischinfo/haeufigkeitkrebsergebnis/model";
/**
 * Mit dieser Komponente kann der Nutzer die Häufigkeitsklasse einer befischten Art ansehen und ggf. bearbeiten.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-haeufigkeit",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungErgebnisseArtErfassungHaeufigkeit {
    @Input()
    public modalId: string;
    @Input()
    public artId: string;
    @Input()
    public artGruppe: string;
    @Input()
    public haeufigkeitErgebnis: FischinfoHaeufigkeitbefischungergebnis | FischinfoHaeufigkeitsmuschelergebnis | FischinfoHaeufigkeitkrebsergebnis;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get haeufigkeit(): string {
        return this.haeufigkeitErgebnis instanceof FischinfoHaeufigkeitbefischungergebnis
            ? this.haeufigkeitErgebnis
                && String(this.haeufigkeitErgebnis.haeufigkeit_id)
            : this.haeufigkeitErgebnis instanceof FischinfoHaeufigkeitsmuschelergebnis
                ? this.haeufigkeitErgebnis
                    && String(this.haeufigkeitErgebnis.muschelhaeufigkeit_id)
                : this.haeufigkeitErgebnis instanceof FischinfoHaeufigkeitkrebsergebnis
                    ? this.haeufigkeitErgebnis
                        && String(this.haeufigkeitErgebnis.krebshaeufigkeit_id)
                    : null;
    }
    public set haeufigkeit(value: string) {
        if (this.haeufigkeitErgebnis instanceof FischinfoHaeufigkeitbefischungergebnis) {
            this.haeufigkeitErgebnis
                && (this.haeufigkeitErgebnis.haeufigkeit_id = parseInt(String(value), 10) || null);
        }
        else if (this.haeufigkeitErgebnis instanceof FischinfoHaeufigkeitsmuschelergebnis) {
            this.haeufigkeitErgebnis
                && (this.haeufigkeitErgebnis.muschelhaeufigkeit_id = parseInt(String(value), 10) || null);
        }
        else if (this.haeufigkeitErgebnis instanceof FischinfoHaeufigkeitkrebsergebnis) {
            this.haeufigkeitErgebnis
                && (this.haeufigkeitErgebnis.krebshaeufigkeit_id = parseInt(String(value), 10) || null);
        }
        this.changed.emit(true);
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    public onHaeufigkeitChange(e: any) {
        this.haeufigkeit = e.value;
    }
}
