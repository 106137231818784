import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoAnodenart from "./interface";
import FischinfoMethode from "./../../fischinfo/methode/model";
@fn.DataModel
export default class FischinfoAnodenart extends DataModel implements IFischinfoAnodenart {
    constructor(obj?: object | IFischinfoAnodenart) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoAnodenart): FischinfoAnodenart {
        return new FischinfoAnodenart(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoAnodenart): FischinfoAnodenart {
        Object.assign(this, obj);
        obj && obj["fischinfoMethodeListByForeignAnodenart_id"] && obj["fischinfoMethodeListByForeignAnodenart_id"].length && (this.fischinfoMethodeListByForeignAnodenart_id = obj["fischinfoMethodeListByForeignAnodenart_id"].map(FischinfoMethode.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoMethode>}
     */
    @fn.DataMember({ name: "fischinfoMethodeListByForeignAnodenart_id", type: [FischinfoMethode], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoMethodeListByForeignAnodenart_id?: Array<FischinfoMethode>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
