import DecoratorHelper from "./../helper";

export default function SHP(data: {
    type?: string,
    label?: string,
    shpfield?: string,
    columnIndex?: number,
}): any {
    return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
        DecoratorHelper.definePropertyMetadata(target, propertyKey, {
            shp: data
        });
    }
}
