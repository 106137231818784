import {
    Directive,
    HostListener,
    Input,
    HostBinding,
} from "@angular/core";

import UWAccordion from "../accordion/directive";

/**
 * Diese Komponente markiert ein Element als Titel einer Kategorie (siehe Komponente `UWAccordion`).
 * 
 * Wenn der Nutzer den Titel einer Kategorie klickt, dann schaltet die Eltenkomponente
 * die Sichtbarkeit des dazugehörigen Körpers der Kategorie um. Der Titel der Kategorie
 * ist immer sichtbar.
 */
@Directive({
    selector: "[uw-accordion-pane]"
})
export default class UWAccordionPaneDirective {

    @Input() public id: number = 0;
    @Input() public striped: boolean = true;

    constructor(
        protected accordion: UWAccordion
    ) {
        this.accordion.classifyTab(this);
    }

    @HostListener("click") protected activateTab() {
        this.accordion.activatePane(this.id);
    }

    @Input() class: string = "";

    @HostBinding("class") get hostClasses(): string {
        return [
            this.class,
            "uw-accordion-pane",
            this.id % 2 ? "even" : "odd",
            this.id === this.accordion.activePane ? "active" : ""
        ].join(" ");
    }

    @HostBinding('style.background-color') get hostBackgroundColor(): string {
        return !this.striped || this.id % 2 ? "inherit" : "#fff";
    }

}
