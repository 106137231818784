<div
    *ngIf="!disabled"
    class="uw-inline-uncheck-button-div"
    [style.width]="width"
>
    <div
        *ngIf="checked && !hideIcon"
        [title]="title"
    ><i
        [class]="iconClass"
    ></i></div>
    <div
        *ngIf="checked && !disabled"
    ><i title ="Eintrag entfernen" class="fa fa-close"></i></div>
    <div
        *ngIf="!checked && !disabled"
    ><i title ="Eintrag anwählen" class="fa fa-circle-thin"></i></div>
</div>
