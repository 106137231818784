import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoBesatzort from "./interface";
import FischinfoGemeinde from "./../../fischinfo/gemeinde/model";
import FischinfoGewaesser from "./../../fischinfo/gewaesser/model";
import FischinfoBesatzereignis from "./../../fischinfo/besatzereignis/model";
import FischinfoBenutzerbesatzorte from "./../../fischinfo/benutzerbesatzorte/model";
import FischinfoStillgewaessertyp from "./../../fischinfo/stillgewaessertyp/model";
@fn.DataModel
export default class FischinfoBesatzort extends DataModel implements IFischinfoBesatzort {
    constructor(obj?: object | IFischinfoBesatzort) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoBesatzort): FischinfoBesatzort {
        return new FischinfoBesatzort(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoBesatzort): FischinfoBesatzort {
        Object.assign(this, obj);
        obj && obj["fischinfoGemeindeByGemeinde_id"] && (this.fischinfoGemeindeByGemeinde_id = FischinfoGemeinde.deserialize(obj["fischinfoGemeindeByGemeinde_id"]));
        obj && obj["fischinfoGewaesserByGewaesser_id"] && (this.fischinfoGewaesserByGewaesser_id = FischinfoGewaesser.deserialize(obj["fischinfoGewaesserByGewaesser_id"]));
        obj && obj["fischinfoBesatzereignisListByForeignBesatzort_id"] && obj["fischinfoBesatzereignisListByForeignBesatzort_id"].length && (this.fischinfoBesatzereignisListByForeignBesatzort_id = obj["fischinfoBesatzereignisListByForeignBesatzort_id"].map(FischinfoBesatzereignis.deserialize));
        obj && obj["fischinfoBenutzerbesatzorteByForeignBesatzort_id"] && (this.fischinfoBenutzerbesatzorteByForeignBesatzort_id = FischinfoBenutzerbesatzorte.deserialize(obj["fischinfoBenutzerbesatzorteByForeignBesatzort_id"]));
        obj && obj["fischinfoStillgewaessertypByStillgewaessertyp_id"] && (this.fischinfoStillgewaessertypByStillgewaessertyp_id = FischinfoStillgewaessertyp.deserialize(obj["fischinfoStillgewaessertypByStillgewaessertyp_id"]));
        return this;
    }

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "abgeschlossen", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public abgeschlossen?: boolean;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "besatzortnr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public besatzortnr?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "efnr", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public efnr?: string;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "etrs89e", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public etrs89e?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "etrs89n", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public etrs89n?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "freigegeben", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public freigegeben?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gemeinde_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public gemeinde_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gewaesser_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public gewaesser_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gewaesseralias", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gewaesseralias?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gsk_auflage", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gsk_auflage?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gsk_version", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gsk_version?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "lagebeschreibung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public lagebeschreibung?: string;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "stationierung", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public stationierung?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "stillgewaesser", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public stillgewaesser?: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "stillgewaessertyp_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public stillgewaessertyp_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "vorflutergkz", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public vorflutergkz?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "vorflutername", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public vorflutername?: string;

    /**
     * @type {FischinfoGemeinde}
     */
    @fn.DataMember({ name: "fischinfoGemeindeByGemeinde_id", type: FischinfoGemeinde, nullable: true, memberType: "JOIN", joinProperty: "gemeinde_id" })
    public fischinfoGemeindeByGemeinde_id?: FischinfoGemeinde;

    /**
     * @type {FischinfoGewaesser}
     */
    @fn.DataMember({ name: "fischinfoGewaesserByGewaesser_id", type: FischinfoGewaesser, nullable: true, memberType: "JOIN", joinProperty: "gewaesser_id" })
    public fischinfoGewaesserByGewaesser_id?: FischinfoGewaesser;

    /**
     * @type {Array<FischinfoBesatzereignis>}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisListByForeignBesatzort_id", type: [FischinfoBesatzereignis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzereignisListByForeignBesatzort_id?: Array<FischinfoBesatzereignis>;

    /**
     * @type {FischinfoBenutzerbesatzorte}
     */
    @fn.DataMember({ name: "fischinfoBenutzerbesatzorteByForeignBesatzort_id", type: FischinfoBenutzerbesatzorte, nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBenutzerbesatzorteByForeignBesatzort_id?: FischinfoBenutzerbesatzorte;

    /**
     * @type {FischinfoStillgewaessertyp}
     */
    @fn.DataMember({ name: "fischinfoStillgewaessertypByStillgewaessertyp_id", type: FischinfoStillgewaessertyp, nullable: true, memberType: "JOIN", joinProperty: "stillgewaessertyp_id" })
    public fischinfoStillgewaessertypByStillgewaessertyp_id?: FischinfoStillgewaessertyp;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
