<div class="row" style="padding-top:10px;">
    <div
        class="col-xs-15"
        *ngIf="!readonly"
    >
        <label 
            *ngIf="artGruppe==='Muscheln'"
        >Geben Sie die Anzahl der Individuen für die jeweilige Grössenklasse ein</label>
        <label 
            *ngIf="artGruppe!=='Muscheln'"
        >Geben Sie die Anzahl der Individuen für die jeweilige Längenklasse ein</label>
    </div>
    <div
        class="col-xs-15"
        *ngIf="readonly"
    >
        <label 
            *ngIf="artGruppe==='Muscheln'"
        >Anzahl der Individuen je Grössenklasse</label>
        <label 
            *ngIf="artGruppe!=='Muscheln'"
        >Anzahl der Individuen je Längenklasse</label>
    </div>
</div>

<div class="row">
    <div class="col-sm-3">
        <uw-textinput
            label=">0-5cm"
            [(value)]="anzahl_0_5"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div class="col-sm-3">
        <uw-textinput
            label=">5-10cm"
            [(value)]="anzahl_5_10"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div class="col-sm-3">
        <uw-textinput
            label=">10-15cm"
            [(value)]="anzahl_10_15"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div class="col-sm-3">
        <uw-textinput
            label=">15-20cm"
            [(value)]="anzahl_15_20"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div class="col-sm-3">
        <uw-textinput
            label=">20-25cm"
            [(value)]="anzahl_20_25"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div class="col-sm-3">
        <uw-textinput
            label=">25-30cm"
            [(value)]="anzahl_25_30"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div
        class="col-sm-3"
        *ngIf="artGruppe==='Fische'"
    >
        <uw-textinput
            label=">30-40cm"
            [(value)]="anzahl_30_40"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div
        class="col-sm-3"
        *ngIf="artGruppe==='Fische'"
    >
        <uw-textinput
            label=">40-50cm"
            [(value)]="anzahl_40_50"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div
        class="col-sm-3"
        *ngIf="artGruppe==='Fische'"
    >
        <uw-textinput
            label=">50-60cm"
            [(value)]="anzahl_50_60"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div
        class="col-sm-3"
        *ngIf="artGruppe==='Fische'"
    >
        <uw-textinput
            label=">60-70cm"
            [(value)]="anzahl_60_70"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
    <div
        class="col-sm-3"
        *ngIf="artGruppe==='Fische'"
    >
        <uw-textinput
            label=">70cm"
            [(value)]="anzahl_70"
            [disabled]="readonly"
        ></uw-textinput>
    </div>

    <div
        class="col-sm-9 col-sm-offset-3"
        *ngIf="artGruppe==='Fische'"
    >
        <div class="row">
            <div class="col-xs-15 text-right">
                <label style="display:block">Anzahl der Jungfische (0+) an den gezählten Tieren</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 col-sm-offset-10">
                <uw-textinput
                    [(value)]="anzahljungfische"
                    [disabled]="readonly"
                ></uw-textinput>
            </div>
        </div>
    </div>
</div>

<div
    class="row"
    *ngIf="artGruppe==='Fische'"
    style="padding-top:10px;"
>
    <div class="col-xs-15">
        <label>Alte Längeklassen <sup><i class="fa fa-info-circle" title="Aktuell nicht mehr nutzbar. Die Felder können nachbearbeitet werden, wenn bereits etwas eingetragen wurde."></i></sup></label>
    </div>
</div>
<div
    class="row"
    *ngIf="artGruppe==='Fische'"
>
    <div
        class="col-sm-3"
        [class.disabled]="!readonly && (!anzahl_0_10)"
    >
        <uw-textinput
            label=">0-10cm"
            [(value)]="anzahl_0_10"
            [disabled]="readonly || (!anzahl_0_10)"
        ></uw-textinput>
    </div>
    <div
        class="col-sm-3"
        [class.disabled]="!readonly && (!anzahl_10_20)"
    >
        <uw-textinput
            label=">10-20cm"
            [(value)]="anzahl_10_20"
            [disabled]="readonly || (!anzahl_10_20)"
        ></uw-textinput>
    </div>
    <div
        class="col-sm-3"
        [class.disabled]="!readonly && (!anzahl_20_30)"
    >
        <uw-textinput
            label=">20-30cm"
            [(value)]="anzahl_20_30"
            [disabled]="readonly || (!anzahl_20_30)"
        ></uw-textinput>
    </div>
</div>
