import { Component, Input, AfterContentInit } from "@angular/core";
import UWTabsDirective from "../directive";
/**
 * Diese Komponente umschließt den Inhalt eines bestimmten Tabs.
 *
 * Wenn der Nutzer den für diesen Tab zuständigen uw-tabs-Aktivator betätigt, dann wird der Inhalt des Tabs sichtbar. Wenn der Tab nicht aktiv ist, dann wird sein Inhalt auch nicht angezeigt.
 */
@Component({ selector: "uw-tab",
    template: "<ng-content *ngIf='active'></ng-content>"
})
export default class UWTab implements AfterContentInit {
    public active: boolean;
    @Input()
    public name: string;
    public onChangeSubscription: any;
    constructor(public tabs: UWTabsDirective) {
        this.onChangeSubscription = tabs.change.subscribe(change => {
            this.active = change.currentValue === this.name;
        });
    }
    public ngOnDestroy(): void {
        this.onChangeSubscription.unsubscribe();
    }
    public ngAfterContentInit() {
        this.active = this.tabs.active === this.name;
    }
}
