import { Component, ViewChild, Renderer2, ElementRef, HostListener } from "@angular/core";
import UWOverlay from "../directive";
import UWOverlayControls from "../interface";

/**
 * Diese Komponente stellt den Bereich dar, in dem die Anwendung schwebende Elemente anzeigt.
 *
 * Der Anzeigebereich übernimmt die Berechnung der Position, das Anzeigen und Ausblenden der schwebenden Elemente.
 */
@Component({
    selector: "uw-overlay-layer",
    template: "<div #layerDiv class='uw-overlay-layer'></div>",
    styles: [".uw-overlay-layer { position: absolute !important; top: 0px; left: 0px; }"]
})
export default class UWOverlayLayer {
    public static readonly ANCHOR_KEY = "__uw_overlay_anchor__;";
    @ViewChild("layerDiv", { static: false })
    public set layerDivRef(ref: ElementRef) {
        this.layerDiv = ref.nativeElement;
    }
    public layerDiv: HTMLElement;
    constructor(popctrl: UWOverlay, public renderer: Renderer2) {
        popctrl.registerLayer(this);
    }
    public createOverlay(content: Element, anchor: Element): UWOverlayControls {
        return new UWLayerPopControls(this, content, anchor);
    }
    public appendOverlayContent(content: Element, anchor: Element) {
        const { layerDiv } = this;
        if (!this.hasOverlay(content)) {
            if (layerDiv.firstElementChild) {
                this.removeOverlayContent(layerDiv.firstElementChild);
            }
            this.assignAnchor(content, anchor);
            this.layerDiv.appendChild(content);
        }
    }
    public removeOverlayContent(content: Element) {
        const { layerDiv } = this;
        if (this.hasOverlay(content)) {
            if (this.showsOverlay(content)) {
                this.hideOverlay(content);
            }
            layerDiv.removeChild(content);
            this.assignAnchor(content, void 0);
        }
    }
    public showOverlay(content: Element) {
        const { layerDiv } = this;
        if (this.hasOverlay(content)) {
            this.renderer.addClass(layerDiv, "uw-visible");
            this.renderer.removeClass(layerDiv, "uw-hidden");
            this.setBoundingRectFrom(content, this.getAnchor(content));
        }
    }
    public hideOverlay(content: Element) {
        const { layerDiv } = this;
        if (this.hasOverlay(content)) {
            this.renderer.removeClass(layerDiv, "uw-visible");
            this.renderer.addClass(layerDiv, "uw-hidden");
        }
    }
    public hasOverlay(content: Element) {
        return this.layerDiv.firstElementChild === content;
    }
    public showsOverlay(content: Element) {
        return this.hasOverlay(content) && /\buw-visible\b/.test(content.className);
    }
    public setBoundingRectFrom(content: Element, anchor: Element) {
        const { layerDiv } = this;
        if (layerDiv === content.parentElement) {
            const bbox = anchor.getBoundingClientRect();
            const poffset = layerDiv.offsetParent.getBoundingClientRect();
            this.renderer.setStyle(layerDiv, "top", (bbox.top + bbox.height - poffset.top) + "px");
            this.renderer.setStyle(layerDiv, "left", (bbox.left - poffset.left) + "px");
            this.renderer.setStyle(layerDiv, "width", bbox.width + "px");
        }
    }
    @HostListener("window:resize")
    public updateBoundingRect() {
        const { layerDiv } = this;
        if (layerDiv.firstElementChild) {
            const content = layerDiv.firstElementChild;
            const anchor = this.getAnchor(content);
            if (!anchor) return;
            this.setBoundingRectFrom(content, anchor);
        }
    }
    public assignAnchor(element: Element, anchor: Element) {
        this.renderer.setProperty(element, UWOverlayLayer.ANCHOR_KEY, anchor);
    }
    public getAnchor(element: Element) {
        return (element as any)[UWOverlayLayer.ANCHOR_KEY];
    }
    public getAnchorForFirstChild() {
        return this.getAnchor(this.layerDiv.firstElementChild);
    }
}

class UWLayerPopControls implements UWOverlayControls {
    private _layer: UWOverlayLayer;
    private _element: HTMLElement;
    private _anchor: HTMLElement;
    constructor(layer, element, anchor) {
        this._layer = layer;
        this._anchor = anchor;
        this._element = element;
    }
    public show() {
        const { _layer, _element } = this;
        if (!_layer.hasOverlay(_element)) {
            _layer.appendOverlayContent(_element, this._anchor);
        }
        _layer.showOverlay(_element);
    }
    public hide() {
        const { _layer, _element } = this;
        _layer.hideOverlay(_element);
    }
    public destroy() {
        const { _layer, _element } = this;
        _layer.removeOverlayContent(_element);
    }
    public updateBounds() {
        const { _layer, _element, _anchor } = this;
        _layer.setBoundingRectFrom(_element, _anchor);
    }
}
