import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoDokument from "./interface";
import FischinfoFoto from "./../../fischinfo/foto/model";
@fn.DataModel
export default class FischinfoDokument extends DataModel implements IFischinfoDokument {
    constructor(obj?: object | IFischinfoDokument) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoDokument): FischinfoDokument {
        return new FischinfoDokument(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoDokument): FischinfoDokument {
        Object.assign(this, obj);
        obj && obj["fischinfoFotoListByForeignDokument_id"] && obj["fischinfoFotoListByForeignDokument_id"].length && (this.fischinfoFotoListByForeignDokument_id = obj["fischinfoFotoListByForeignDokument_id"].map(FischinfoFoto.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "beschreibung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public beschreibung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "dateiname", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public dateiname?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoFoto>}
     */
    @fn.DataMember({ name: "fischinfoFotoListByForeignDokument_id", type: [FischinfoFoto], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoFotoListByForeignDokument_id?: Array<FischinfoFoto>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
