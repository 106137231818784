import { Component, Input, ElementRef, ViewChild, } from "@angular/core";
import UserService from "./../user/service";
import AppService from "./../app/service";
import MapPanelFilter from "./../map-panel-filter/component";
import MapViewService from "../map-view/service";
import DataService from "../data/service";
/**
 * Mit dieser Komponente kann der Nutzer die Darstellung und die dargestellten Informationen der Anwendungen steuern.
 *
 * Der Nutzer kann den betrachteten Datentyp zwischen Probestellen und Untersuchungen umschalten.
 * Der Nutzer kann die in der Karte dargestellten Kartenelemente (Gewässer, Schutzgebiete etc.) auswählen.
 * Der Nutzer kann durch Auswahlkriterien (Filter) die angezeigten Probestellen/Untersuchungen beschränken.
 * Der Nutzer kann die FIBS-Bewertungen für die ausgewählten Probestellen/Untersuchungen berechnen.
 */
@Component({
    selector: "uw-panel",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class Panel {
    @ViewChild("panelFilter", { static: false })
    public panelFilter: MapPanelFilter;
    public activeTab: string;
    public get tabContentHeight(): number {
        const h = this.mapViewService.height
            - this.mapViewService.mapPadding.panel.bottom
            - 48;
        return Math.max(0, h);
    }
    public get hasExtendedAdminRightFIBS(): boolean {
        return this.userService.roles
            && this.userService.roles.filter((x: string): boolean => x == "ROLE_ADMIN").length
            ? true
            : false;
    }
    constructor(public appService: AppService, public userService: UserService, public mapViewService: MapViewService, public dataService: DataService) {
        this.activeTab = this.isExtendedView
            ? "tabelle"
            : "karte";
    }
    public get isExtendedView(): boolean {
        return this.userService.isLoggedIn === true;
    }
    public clearFilter(e): void {
        this.panelFilter.clearFilter(e);
    }
    public onFolderChanged(event: any): void {
        this.dataService.setFolder(event.key);
    }
}
