import { Component, Input, ViewChild, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoKrebsart from "../../models/fischinfo/krebsart/model";
import UWAccordion from "../accordion/directive";
/**
 * Mit dieser Komponente kann der Nutzer die Inhalte einer Untersuchung ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt nur die Informationen über die befischten Krebse einer Untersuchung dar.
 * Dazu gehören die erhobenen Daten der verschiedenen Krebsarten.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-ergebnisse-krebse",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungErgebnisseKrebse implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @ViewChild(UWAccordion, { static: false })
    accordion;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public _untersuchungsergebnisseList: any[];
    public get untersuchungsergebnisseList() {
        return this._untersuchungsergebnisseList;
    }
    public _labelList: any[];
    public get labelList() {
        return this._labelList;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    ngOnInit() {
        this.refreshUntersuchungsergebnisseList();
    }
    public onChanged(e) {
        !this.readonly && this.getNormalizedBefischungsergebnisseList()
            .then((list) => {
            this._labelList = this._labelList.map(x => list.find(y => y.id === x.id));
        });
    }
    public refreshUntersuchungsergebnisseList() {
        this.getNormalizedBefischungsergebnisseList()
            .then((list) => {
            list = this.sort(list, this.readonly ? "art" : "summe", this.readonly ? "text" : "number");
            this._untersuchungsergebnisseList = list;
            this._labelList = list;
        });
    }
    public isSorting: boolean;
    public sortField: any = {};
    public sort(list: any, column: string, type: string): any[] {
        let _sort_asc = function (a, b) {
            return a[column] === b[column]
                ? ((a.untersuchungstyp || "") + "zz" + a.art.toLowerCase().trim()).toLowerCase().trim().localeCompare(((b.untersuchungstyp || "") + "zz" + b.art.toLowerCase().trim()).toLowerCase().trim())
                : +(a[column] > b[column]) || -1;
        };
        let _sort_desc = function (a, b) {
            return a[column] === b[column]
                ? ((b.untersuchungstyp || "") + "aa" + b.art.toLowerCase().trim()).toLowerCase().trim().localeCompare(((a.untersuchungstyp || "") + "aa" + a.art.toLowerCase().trim()).toLowerCase().trim())
                : +(a[column] < b[column]) || -1;
        };
        let _sort_str_asc = function (a, b) {
            return a[column] === b[column]
                ? +(a.summe < b.summe) || -1
                : (a[column]).toString().toLowerCase().trim().localeCompare((b[column]).toLowerCase().trim());
        };
        let _sort_str_desc = function (a, b) {
            return a[column] === b[column]
                ? +(a.summe > b.summe) || -1
                : (b[column]).toString().toLowerCase().trim().localeCompare((a[column]).toLowerCase().trim());
        };
        this.sortField = { [column]: this.sortField[column] };
        this.sortField[column] =
            type === "text"
                ? this.sortField[column] === "asc"
                    ? "desc"
                    : "asc"
                : this.sortField[column] === "desc"
                    ? "asc"
                    : "desc";
        return (type === "text")
            ? list.sort(this.sortField[column] === "desc" ? _sort_str_desc : _sort_str_asc)
            : list.sort(this.sortField[column] === "desc" ? _sort_desc : _sort_asc);
    }
    public async onClickSortColumn(column: string, type: string) {
        this.isSorting = true;
        const list = this.sort(await this.getNormalizedBefischungsergebnisseList(), column, type);
        this._untersuchungsergebnisseList = list;
        this._labelList = list;
        setTimeout(() => this.isSorting = false, 0);
    }
    public getNormalizedBefischungsergebnisseList(): Promise<any[]> {
        let a = this.dataService
            .getReferenceTable("krebsart", FischinfoKrebsart, "id");
        let b = this
            .formFischinfoUntersuchungService
            .viewUtil
            .getNormalizedBefischungsergebnisseList(this.untersuchung);
        return Promise.all([a, b])
            .then(([fibsartenRef, untersuchungsergebnisseList]) => {
            let list = [];
            Object.keys(fibsartenRef).forEach(key => {
                let untersuchteArt = untersuchungsergebnisseList
                    .filter(x => x.gruppe === "Krebse" && x.krebsart_id === fibsartenRef[key].id)
                    .shift()
                    || {
                        gruppe: "Krebse"
                    };
                list.push({
                    id: fibsartenRef[key].id,
                    art: fibsartenRef[key].bezeichnung,
                    gruppe: untersuchteArt["gruppe"],
                    untersuchungstyp: untersuchteArt["untersuchungstyp"] || "",
                    summe: untersuchteArt["summe"] || "",
                });
            });
            return list
                .filter(x => !this.readonly || x.untersuchungstyp);
        });
    }
}
