import { Component, Input, Optional, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoMethode from "../../models/fischinfo/methode/model";
import DataService from "../data/service";
import { UWTab } from "ng-uw-tabs";
import FischinfoBefischungsgeraet from "../../models/fischinfo/befischungsgeraet/model";
import FischinfoBefischungsart from "../../models/fischinfo/befischungsart/model";
import FischinfoMethodegeraetetyp from "../../models/fischinfo/methodegeraetetyp/model";
import FischinfoNetz from "../../models/fischinfo/netz/model";
/**
 * Mit dieser Komponente kann der Nutzer die Informationen zur Befischungsmethode einer Untersuchung ansehen und ggf. bearbeiten.
 *
 * Diese Komponente umfasst nur Informationen zur Befischungsmethode einer Untersuchung.
 * Die angezeigten Felder hängen vom ausgewählten Befischungsgerät ab, da die verschiedenen
 * Befischungsgeräte unterschiedlich detailierte Informationen zur Befischung zulassen.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-methode",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungMethode implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get inActiveTab() {
        return !!this.uwtab && this.uwtab.active;
    }
    public get methode_id(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .methode_id;
    }
    public set methode_id(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .methode_id = parseInt(String(value), 10) || null;
    }
    public get befischungsart_id(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .befischungsart_id;
    }
    public set befischungsart_id(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .befischungsart_id = parseInt(String(value), 10) || null;
    }
    public get befischungsgeraet_id(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .befischungsgeraet_id;
    }
    public lastChosenbefischungsgeraet_id;
    public deletedByBefischungsgeraet_id: any = {
        "1": {},
        "2": {},
        "3": {},
        "4": {},
    };
    public set befischungsgeraet_id(value: number) {
        let methode = this.formFischinfoUntersuchungService.modelUtil
            .setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode");
        if (methode.befischungsgeraet_id === parseInt(String(value), 10))
            return;
        const undefinedValueReplacer = (k: string, v: any) => v === undefined ? null : v;
        const modelsToBeDeleted = {
            "1": (clear: boolean) => {
                [
                    "fischinfoMethodegeraetetypListByForeignMethode_id",
                    "elektrobefischungsmethode_id",
                    "anzahlanodenkaescher",
                    "streifenanode",
                    "anzahlnichtelektrischerkaescher",
                    "spannung",
                    "stromstaerkeampere",
                    "stromstaerkeprozent",
                    "anodenart_id",
                    "anodendurchmesser",
                    "vorgehensweise_id",
                    "anzahlprobepunkte",
                    "befischteteilflaeche",
                    "untersuchungsbereich",
                    "befischtelaenge",
                    "befischtebreite"
                ].forEach(key => {
                    !clear && this.lastChosenbefischungsgeraet_id === 1
                        && (this.deletedByBefischungsgeraet_id[String(this.lastChosenbefischungsgeraet_id)][key] =
                            key === "fischinfoMethodegeraetetypListByForeignMethode_id"
                                ? JSON.parse(JSON.stringify(methode[key], undefinedValueReplacer)).map(x => { return x = new FischinfoMethodegeraetetyp(x); })
                                : JSON.parse(JSON.stringify(methode[key], undefinedValueReplacer)));
                    clear && (methode[key] = null);
                });
            },
            "2": (clear: boolean) => {
                [
                    "fischinfoNetzListByForeignMethode_id",
                    "expositionsdauer",
                ].forEach(key => {
                    !clear && this.lastChosenbefischungsgeraet_id === 2
                        && (this.deletedByBefischungsgeraet_id[String(this.lastChosenbefischungsgeraet_id)][key] =
                            key === "fischinfoNetzListByForeignMethode_id"
                                ? JSON.parse(JSON.stringify(methode[key], undefinedValueReplacer)).map(x => { return x = new FischinfoNetz(x); })
                                : JSON.parse(JSON.stringify(methode[key], undefinedValueReplacer)));
                    clear && (methode[key] = null);
                });
            },
            "3": (clear: boolean) => {
                [
                    "expositionsdauer",
                    "anzahlreusen",
                    "reusenarten",
                    "maschenweiten",
                ].forEach(key => {
                    !clear && this.lastChosenbefischungsgeraet_id === 3
                        && (this.deletedByBefischungsgeraet_id[String(this.lastChosenbefischungsgeraet_id)][key] = JSON.parse(JSON.stringify(methode[key], undefinedValueReplacer)));
                    clear && (methode[key] = null);
                });
            },
            "4": (clear: boolean) => [].forEach(key => {
                !clear && this.lastChosenbefischungsgeraet_id === 4
                    && (this.deletedByBefischungsgeraet_id[String(this.lastChosenbefischungsgeraet_id)][key] = JSON.parse(JSON.stringify(methode[key], undefinedValueReplacer)));
                clear && (methode[key] = null);
            })
        };
        Object.keys(modelsToBeDeleted)
            .filter(x => String(x) !== String(value))
            .map(x => { modelsToBeDeleted[String(x)](false); return x; })
            .forEach(x => modelsToBeDeleted[String(x)](true));
        value
            && this.deletedByBefischungsgeraet_id[String(value)]
            && Object.keys(this.deletedByBefischungsgeraet_id[String(value)])
                .forEach(x => {
                    methode[x] =
                        this.deletedByBefischungsgeraet_id[String(value)][x]
                        || methode[x]
                        || null;
                });
        this.lastChosenbefischungsgeraet_id = parseInt(String(value), 10) || null;
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .befischungsgeraet_id = parseInt(String(value), 10) || null;
    }
    public set beschreibung(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .beschreibung = value;
    }
    public get beschreibung(): string {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMethode>(this.untersuchung, "FischinfoMethode")
            .beschreibung;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService,
        @Optional()
        public uwtab: UWTab) {
    }
    public befischungsgeraet_idRefJsonItems: string;
    public befischungsart_idRefJsonItems: string;
    ngOnInit() {
        this.lastChosenbefischungsgeraet_id = this.befischungsgeraet_id;
        const a = this.dataService
            .getReferenceTable("befischungsgeraet", FischinfoBefischungsgeraet, "id");
        const b = this.dataService
            .getReferenceTable("befischungsart", FischinfoBefischungsart, "id");
        return Promise.all<{
            [key: string]: FischinfoBefischungsgeraet | FischinfoBefischungsart;
        }>([a, b])
            .then(refs => {
                [0, 1].map(x => {
                    let refList = [], entityKey;
                    Object.keys(refs[x]).forEach(key => {
                        entityKey = key;
                        refList.push('[' + refs[x][key].id + ',"' + refs[x][key].bezeichnung + '"]');
                    });
                    refs[x] && refs[x][entityKey] instanceof FischinfoBefischungsgeraet
                        && (this.befischungsgeraet_idRefJsonItems = "[" + refList.join(",") + "]");
                    refs[x] && refs[x][entityKey] instanceof FischinfoBefischungsart
                        && (this.befischungsart_idRefJsonItems = "[" + refList.join(",") + "]");
                });
            });
    }
    public onChangedMethodegeraet(e) {
    }
    public onChangedMethodenetz(e) {
    }
    public onChangedMethodereuse(e) {
    }
    public onChangedMethodesonstige(e) {
    }
}
