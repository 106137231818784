<uw-tabs
    (change)="tabChange($event)"
    [active-tab]="selectedTab"
>

<!-- #region Buttonleiste zur Gesamtuntersuchung //-->
    <uw-modal-window-message
        *ngIf="hasRightToEdit || !isExtendedView" 
        top="0"
        [height]="buttonPanelHeight"
        zIndex="1005"
        paddingLeft="0"
        paddingRight="0"
        [class]="isExtendedView ? 'sub-tabs' : 'sub-tabs sub-tabs-last'"
    >
        <article>
            <ng-container
                [ngSwitch]="mode"
            >
                <ng-container 
                    *ngSwitchCase="'Delete'"
                >
                    <uw-inline-button
                        iconClass="fa fa-trash-o"
                        (click)="onClickDelete($event)"
                        title="Ja, löschen"
                        value="Ja, löschen"
                    ></uw-inline-button>
                    <uw-inline-button
                        iconClass="fa fa-remove"
                        (click)="mode = 'Edit'" 
                        title="Nein, abbrechen"
                        value="Nein, abbrechen"
                    ></uw-inline-button>
                    <div class="question-button">
                        <span>Untersuchung unwideruflich löschen?</span>
                    </div>
                </ng-container>

                <ng-container
                    *ngSwitchCase="'Edit'"
                >
                    <uw-inline-button
                        iconClass="fa fa-remove"
                        (click)="onClickCancel($event)" 
                        title="Bearbeitung abbrechen"
                        value="Abbrechen"
                    ></uw-inline-button>
                    <uw-inline-button 
                        iconClass="fa fa-save"
                        (click)="onClickSubmit($event)" 
                        title="Änderungen speichern"
                        value="Speichern"
                    ></uw-inline-button>
                    <!-- <uw-inline-button 
                        iconClass="fa fa-check-circle"
                        (click)="onClickValidate($event)" 
                        title="Eingaben prüfen"
                        value="Prüfen"
                    ></uw-inline-button> -->
                    <uw-inline-button
                        *ngIf="isExtendedView"
                        iconClass="fa fa-trash-o"
                        (click)="mode = 'Delete'" 
                        title="Untersuchung löschen"
                        value="Löschen"
                    ></uw-inline-button>
                    <uw-inline-button
                        *ngIf="isExtendedView && hasRightToMovePS"
                        (click)="onClickMove($event)" 
                        title="Untersuchung einer anderen Probenahmestelle zuordnen"
                        iconClass="fa fa-reply"
                        value="Verschieben"
                    ></uw-inline-button>
                </ng-container>

                <ng-container
                    *ngSwitchCase="'Create'"
                >
                    <uw-inline-button
                        iconClass="fa fa-remove"
                        (click)="onClickCancel($event)" 
                        title="Abbrechen"
                        value="Abbrechen"
                    ></uw-inline-button>
                    <uw-inline-button 
                        iconClass="fa fa-save"
                        (click)="onClickSubmit($event)" 
                        title="Neue Untersuchung anlegen"
                        value="Speichern"
                    ></uw-inline-button>
                    <!-- <uw-inline-button 
                        iconClass="fa fa-check-circle"
                        (click)="onClickValidate($event)" 
                        title="Eingaben prüfen"
                        value="Prüfen"
                    ></uw-inline-button> -->
                </ng-container>

                <ng-container
                    *ngSwitchDefault
                >
                    <uw-inline-button
                        *ngIf="isExtendedView"
                        (click)="onClickEdit($event)" 
                        title="Sachdaten der Untersuchung bearbeiten"
                        iconClass="fa fa-pencil"
                        value="Bearbeiten"
                    ></uw-inline-button>
                    <uw-inline-button
                        *ngIf="!isExtendedView"
                        iconClass="fa fa-download"
                        title="CSV-Datei der Untersuchungsergebnisse herunterladen"
                        value="CSV-Tabelle (Excel)"
                        (click)="saveUntersuchungsergebnisseAsCsvFile($event)"
                    ></uw-inline-button>
                </ng-container>

            </ng-container>
        </article>
    </uw-modal-window-message>
<!-- #endregion //-->

<!-- #region Tableiste //-->
    <uw-modal-window-message
        *ngIf="isExtendedView"
        [top]="hasRightToEdit || !isExtendedView ? buttonPanelHeight : 0"
        zIndex="1004"
        [height]="buttonPanelHeight"
        paddingLeft="0"
        paddingRight="0"
        class="sub-tabs"
    >
        <article>
            <label uw-tab="befischung" title="Befischung">
                <span>Befischung</span>
            </label>
            <label uw-tab="gewaesser" title="Gewässer">
                <span>Gewässer</span>
            </label>
            <label
                *ngIf="!isBeobachtung &amp;&amp; hasErfassungsNr &amp;&amp; showsFIBS"
                uw-tab="fibs"
                title="FIBS"
            >
                <span>FIBS</span>
            </label>
        </article>
    </uw-modal-window-message>
<!-- #endregion //-->

<!-- #region SubTableiste //-->
    <uw-modal-window-message
        *ngIf="isExtendedView"
        [top]="buttonPanelHeight * (hasRightToEdit ? 2 : 1 )"
        zIndex="1003"
        [height]="buttonPanelHeight"
        paddingLeft="0"
        paddingRight="0"
        class="sub-tabs sub-tabs-last"
    >
        <article>
            <uw-tab name="befischung" >
                <uw-tabs
                    (change)="tabChange($event)"
                    [active-tab]="selectedSubTab"
                >
                    <label uw-tab="befischung.default" title="Allgemein">
                        <span>Allgemein</span>
                    </label>
                    <label uw-tab="befischung.ergebnisse.fische" title="Fische (Ergebnisse)">
                        <span>Fische</span>
                    </label>
                    <label uw-tab="befischung.ergebnisse.muscheln" title="Muscheln (Ergebnisse)">
                        <span>Muscheln</span>
                    </label>
                    <label uw-tab="befischung.ergebnisse.krebse" title="Krebse (Ergebnisse)">
                        <span>Krebse</span>
                    </label>
                    <label
                        *ngIf="!isBeobachtung"
                        uw-tab="befischung.methode"
                        title="Methode"
                    >
                        <span>Methode</span>
                    </label>
                    <label
                        uw-tab="befischung.ffhbewertung"
                        title="FFH-Bewertung"
                        *ngIf="!isBeobachtung &amp;&amp; showsFFH"
                    >
                       <span>FFH-Bewertung</span>
                    </label>
                </uw-tabs>
            </uw-tab>
            <uw-tab name="gewaesser">
                <uw-tabs
                    (change)="tabChange($event)"
                    [active-tab]="selectedSubTab"
                    class="sub-tabs"
                >
                    <label
                        *ngIf="!isBeobachtung"
                        uw-tab="gewaesser.default"
                        title="Allgemein"
                    >
                        <span>Allgemein</span>
                    </label>
                    <label
                        *ngIf="!isBeobachtung"
                        uw-tab="gewaesser.struktur"
                        title="Struktur"
                    >
                        <span>Struktur</span>
                    </label>
                    <label uw-tab="gewaesser.sonstiges" title="Sonstiges">
                        <span>Sonstiges</span>
                    </label>
                </uw-tabs>
            </uw-tab>
            <uw-tab
                *ngIf="!isBeobachtung &amp;&amp; showsFIBS"
                name="fibs"
            >
                <uw-tabs
                    (change)="tabChange($event)"
                    [active-tab]="selectedSubTab"
                    class="sub-tabs"
                >
                    <label uw-tab="fibs.default" title="NWB">
                        <span>NWB</span>
                    </label>
                    <label uw-tab="fibs.hmwb" *ngIf="showsHMWB" title="HMWB">
                        <span>HMWB</span>
                    </label>
                    <label uw-tab="fibs.vergleich" *ngIf="showsWBVergleich" title="Vergleich NWB/HMWB">
                        <span>Vergleich NWB/HMWB</span>
                    </label>
                </uw-tabs>
            </uw-tab>
        </article>
    </uw-modal-window-message>
<!-- #endregion //-->


<!-- #region Warnmeldung(en) //-->
    <uw-modal-window-message
        [(visibility)]="warningIsShown"
        closeable="true"
        [top]="isExtendedView ? buttonPanelHeightPx * (hasRightToEdit ? 3 : 2) : buttonPanelHeightPx"
        warning="true"
    >
        <article [ngSwitch]="warningText.constructor.name">
            <p *ngIf="warningSummary"
                [textContent]="warningSummary"
            ></p>
            <ng-container *ngSwitchCase="'Array'">
                <p *ngFor="let para of warningText"
                    [textContent]="para"
                ></p>
            </ng-container>
            <p *ngSwitchCase="'String'"
                [textContent]="warningText"
            ></p>
            <div *ngIf="confirmSaveIsShown" class="overflow: auto;">
                <uw-inline-button color="inverted"
                    iconClass="fa fa-remove"
                    (click)="warningIsShown = false" 
                    title="Nicht speichern"
                    value="Abbrechen"
                ></uw-inline-button>
                <uw-inline-button color="inverted"
                    iconClass="fa fa-save"
                    (click)="onClickSubmit($event, null, true); warningIsShown = false" 
                    title="Änderungen mit Fehlern speichern"
                    value="Trotzdem Speichern"
                ></uw-inline-button>
            </div>
        </article>
    </uw-modal-window-message>
<!-- #endregion //-->

<!-- #region Formular (Öffentliche Sicht) //-->
    <section
        *ngIf="!isExtendedView"
        class="uw-tab-body"
    >
        <div
            class="container-fluid"
            [style.marginTop.px]="topmargin"
        >
            <form-fischinfo-untersuchung
                [untersuchung]="untersuchung"
                [modalId]="modal.id"
                [readonly]="readonly"
            ></form-fischinfo-untersuchung>
        </div>
    </section>
<!-- #endregion //-->

<!-- #region Formular (Erweiterte Sicht) //-->
    <section 
        *ngIf="isExtendedView && isExtendedViewContentShown"
        class="uw-tab-body"
        [class.edit]="mode === 'Edit' || mode === 'Create'"
    >
        <div 
            class="container-fluid" 
            [class.edit-mode]="mode === 'Edit' || mode === 'Create'"
            [style.marginTop.px]="topmargin"
        >
            <uw-tabs
                [active-tab]="selectedSubTab"
            >
                <uw-tab name="befischung.default">
                    <form-fischinfo-untersuchung-befischung-allgemein
                        *ngIf="selectedSubTab==='befischung.default'"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-befischung-allgemein>
                </uw-tab>
                <uw-tab name="befischung.verschieben">
                    <ng-container
                        *ngIf="selectedSubTab==='befischung.verschieben' && mode === 'Edit'"
                    >
                        <div class="row">
                            <div class="col-xs-15">
                                <label>Untersuchung einer anderen Probenahmestelle zuordnen</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-15">
                                <uw-type-ahead
                                    label="Probestellen-Nr."
                                    webServiceApiUrl="{{dataService.webServiceApiUrl}}gebietsbezug/probestellennr?term={term}&limit={limit}&format=application/json"
                                    webServiceApiTokenBasedLoginUrl="{{dataService.webServiceApiUrl}}login?loginname={login}&password={password}&format=application/json"
                                    keyValueMapping="id:name"
                                    itemsAccess=".resultset"
                                    placeholder="PS-Nr. eintragen"
                                    min="0"
                                    nullable="true"
                                    limit="32"
                                    size="8"
                                    (change)="onPSChanged($event)"
                                ></uw-type-ahead>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-15">
                                <uw-inline-button
                                    iconClass="fa fa-reply float-right"
                                    value="In eingetragene Probestelle verschieben"
                                    title="In eingetragene Probestelle verschieben"
                                    (click)="onClickPSChange($event)"
                                    class="inline-btn-nobrk"
                                ></uw-inline-button>
                            </div>
                        </div>
                    </ng-container>
                </uw-tab>
                <uw-tab name="befischung.ergebnisse.fische">
                    <form-fischinfo-untersuchung-befischung-ergebnisse-fische
                        *ngIf="selectedSubTab==='befischung.ergebnisse.fische'"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-befischung-ergebnisse-fische>
                </uw-tab>
                <uw-tab name="befischung.ergebnisse.muscheln">
                    <form-fischinfo-untersuchung-befischung-ergebnisse-muscheln
                        *ngIf="selectedSubTab==='befischung.ergebnisse.muscheln'"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-befischung-ergebnisse-muscheln>
                </uw-tab>
                <uw-tab name="befischung.ergebnisse.krebse">
                    <form-fischinfo-untersuchung-befischung-ergebnisse-krebse
                        *ngIf="selectedSubTab==='befischung.ergebnisse.krebse'"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-befischung-ergebnisse-krebse>
                </uw-tab>
                <uw-tab name="befischung.methode">
                    <form-fischinfo-untersuchung-befischung-methode
                        *ngIf="selectedSubTab==='befischung.methode'"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-befischung-methode>
                </uw-tab>
                <uw-tab name="befischung.ffhbewertung">
                    <form-fischinfo-untersuchung-befischung-ffhbewertung
                        *ngIf="selectedSubTab==='befischung.ffhbewertung'"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-befischung-ffhbewertung>
                </uw-tab>
                <uw-tab name="gewaesser.default">
                    <form-fischinfo-untersuchung-gewaesser-allgemein
                        *ngIf="selectedSubTab==='gewaesser.default'"
                        [probenahmestelle]="probenahmestelle"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-gewaesser-allgemein>
                </uw-tab>
                <uw-tab name="gewaesser.struktur">
                    <form-fischinfo-untersuchung-gewaesser-struktur
                        *ngIf="selectedSubTab==='gewaesser.struktur'"
                        [probenahmestelle]="probenahmestelle"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-gewaesser-struktur>
                </uw-tab>
                <uw-tab name="gewaesser.sonstiges">
                    <form-fischinfo-untersuchung-gewaesser-sonstiges
                        *ngIf="selectedSubTab==='gewaesser.sonstiges'"
                        [probenahmestelle]="probenahmestelle"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-gewaesser-sonstiges>
                </uw-tab>
                <uw-tab name="fibs.default" *ngIf="showsFIBS">
                    <form-fischinfo-untersuchung-fibs-nwb
                        *ngIf="selectedSubTab==='fibs.default'"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-fibs-nwb>
                </uw-tab>
                <uw-tab name="fibs.hmwb" *ngIf="showsFIBS &amp;&amp; showsHMWB">
                    <form-fischinfo-untersuchung-fibs-hmwb
                        *ngIf="selectedSubTab==='fibs.hmwb'"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-fibs-hmwb>
                </uw-tab>
                <uw-tab name="fibs.vergleich" *ngIf="showsFIBS &amp;&amp; showsWBVergleich">
                    <form-fischinfo-untersuchung-fibs-vergleich
                        *ngIf="selectedSubTab==='fibs.vergleich'"
                        [probenahmestelle]="probenahmestelle"
                        [untersuchung]="untersuchung"
                        [modalId]="modal.id"
                        [readonly]="readonly"
                    ></form-fischinfo-untersuchung-fibs-vergleich>
                </uw-tab>
            </uw-tabs>
        </div>
    </section>
<!-- #endregion //-->

</uw-tabs>
