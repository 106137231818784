import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import DataService from "../data/service";
import FischinfoDetailbefischungergebnis from "../../models/fischinfo/detailbefischungergebnis/model";
import FischinfoDetailmuschelergebnis from "../../models/fischinfo/detailmuschelergebnis/model";
import FischinfoDetailkrebsergebnis from "../../models/fischinfo/detailkrebsergebnis/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoMuscheluntersuchung from "../../models/fischinfo/muscheluntersuchung/model";
import FischinfoKrebsuntersuchung from "../../models/fischinfo/krebsuntersuchung/model";
/**
 * Mit dieser Komponente kann der Nutzer zur einer befischten Art eine Individuenanzahl zusammen mit einer Länge und einem Gewicht ansehen und ggf. bearbeiten.
 */
@Component({
    selector: "[form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-detail-individuum]",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungErgebnisseArtErfassungDetailIndividuum {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @Input()
    public artId: string;
    @Input()
    public artGruppe: string;
    @Input()
    public detailindividuumErgebnis: FischinfoDetailbefischungergebnis | FischinfoDetailmuschelergebnis | FischinfoDetailkrebsergebnis;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get anzahl(): number {
        return this.detailindividuumErgebnis
            && this.detailindividuumErgebnis.anzahl;
    }
    public set anzahl(value: number) {
        this.detailindividuumErgebnis
            && (this.detailindividuumErgebnis.anzahl = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get gewichtg(): string {
        return this.detailindividuumErgebnis
            && this.detailindividuumErgebnis.gewichtg
            && String(this.detailindividuumErgebnis.gewichtg).replace(".", ",");
    }
    public set gewichtg(value: string) {
        this.detailindividuumErgebnis
            && (this.detailindividuumErgebnis.gewichtg = parseFloat(String(value).replace(",", ".")) || null);
    }
    public get laengecm(): string {
        return this.detailindividuumErgebnis instanceof FischinfoDetailbefischungergebnis
            ? this.detailindividuumErgebnis
                && this.detailindividuumErgebnis.laengecm
                && String(this.detailindividuumErgebnis.laengecm).replace(".", ",")
            : this.detailindividuumErgebnis instanceof FischinfoDetailmuschelergebnis
                ? this.detailindividuumErgebnis
                    && this.detailindividuumErgebnis.laengecm
                    && String(this.detailindividuumErgebnis.laengecm).replace(".", ",")
                : this.detailindividuumErgebnis instanceof FischinfoDetailkrebsergebnis
                    ? this.detailindividuumErgebnis
                        && this.detailindividuumErgebnis.groessecm
                        && String(this.detailindividuumErgebnis.groessecm).replace(".", ",")
                    : null;
    }
    public set laengecm(value: string) {
        (this.detailindividuumErgebnis instanceof FischinfoDetailbefischungergebnis
            && this.detailindividuumErgebnis
            && (this.detailindividuumErgebnis.laengecm = parseFloat(String(value).replace(",", ".")) || null))
            ||
                (this.detailindividuumErgebnis instanceof FischinfoDetailmuschelergebnis
                    && this.detailindividuumErgebnis
                    && (this.detailindividuumErgebnis.laengecm = parseFloat(String(value).replace(",", ".")) || null))
            ||
                (this.detailindividuumErgebnis instanceof FischinfoDetailkrebsergebnis
                    && this.detailindividuumErgebnis
                    && (this.detailindividuumErgebnis.groessecm = parseFloat(String(value).replace(",", ".")) || null));
        this.changed.emit(true);
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    public delete(e: any) {
        this.artGruppe === "Fische"
            ? this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoDetailbefischungergebnis>(this.untersuchung, "FischinfoDetailbefischungergebnis", new FischinfoDetailbefischungergebnis(this.detailindividuumErgebnis))
            : this.artGruppe === "Muscheln"
                ? this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoDetailmuschelergebnis>(this.untersuchung, "FischinfoDetailmuschelergebnis", new FischinfoDetailmuschelergebnis(this.detailindividuumErgebnis))
                : this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoDetailkrebsergebnis>(this.untersuchung, "FischinfoDetailkrebsergebnis", new FischinfoDetailkrebsergebnis(this.detailindividuumErgebnis));
        this.artGruppe === "Fische"
            ? !this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
                .fischinfoDetailbefischungergebnisListByForeignBefischung_id
                .filter((x) => !x.jungfisch)
                .length
                && this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDetailbefischungergebnis>(this.untersuchung, "FischinfoDetailbefischungergebnis", new FischinfoDetailbefischungergebnis({
                    id: 1,
                    art_id: this.artId,
                    befischung_id: this.formFischinfoUntersuchungService.modelUtil
                        .getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
                        .id
                }))
            : this.artGruppe === "Muscheln"
                ? !this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoMuscheluntersuchung>(this.untersuchung, "FischinfoMuscheluntersuchung")
                    .fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id.length
                    && this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDetailmuschelergebnis>(this.untersuchung, "FischinfoDetailmuschelergebnis", new FischinfoDetailmuschelergebnis({
                        id: 1,
                        muschelart_id: this.artId,
                        muscheluntersuchung_id: this.formFischinfoUntersuchungService.modelUtil
                            .getOrDefault<FischinfoMuscheluntersuchung>(this.untersuchung, "FischinfoMuscheluntersuchung")
                            .id
                    }))
                : !this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoKrebsuntersuchung>(this.untersuchung, "FischinfoKrebsuntersuchung")
                    .fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id.length
                    && this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDetailkrebsergebnis>(this.untersuchung, "FischinfoDetailkrebsergebnis", new FischinfoDetailkrebsergebnis({
                        id: 1,
                        krebsart_id: this.artId,
                        krebsuntersuchung_id: this.formFischinfoUntersuchungService.modelUtil
                            .getOrDefault<FischinfoKrebsuntersuchung>(this.untersuchung, "FischinfoKrebsuntersuchung")
                            .id
                    }));
        this.changed.emit(true);
    }
}
