<div class="row">
    <div class="col-xs-15">
        <label>Sonstige Angaben zum Gewässer</label>
    </div>
</div>

<form-fischinfo-untersuchung-gewaesser-sonstiges-zustandumland
    *ngIf="!isBeobachtung"
    [probenahmestelle]="probenahmestelle"
    [untersuchung]="untersuchung"
    [modalId]="modalId"
    [readonly]="readonly"
></form-fischinfo-untersuchung-gewaesser-sonstiges-zustandumland>

<div
    *ngIf="!isBeobachtung"
    class="row"
    style="padding-bottom:10px;padding-top:10px"
>
    <div class="col-xs-15"><uw-select
        [label]="formFischinfoUntersuchungService.label('FischinfoGewaesserzustand','beeintraechtigunggesamt_id')"
        *ngIf="beeintraechtigunggesamt_idRefJsonItems"
        [jsonItems]="beeintraechtigunggesamt_idRefJsonItems"
        keyValueMapping="0:1"
        [disabled]="readonly"
        size="10"
        nullable="true"
        [(key)]="beeintraechtigunggesamt_id"
    ></uw-select></div>
</div>

<form-fischinfo-untersuchung-gewaesser-sonstiges-zustandeinzelbeeintraechtigung
    *ngIf="!isBeobachtung"
    [probenahmestelle]="probenahmestelle"
    [untersuchung]="untersuchung"
    [modalId]="modalId"
    [readonly]="readonly"
></form-fischinfo-untersuchung-gewaesser-sonstiges-zustandeinzelbeeintraechtigung>

<div
    *ngIf="!isBeobachtung"
    class="row"
    style="padding-top:10px"
>
    <div class="col-xs-15"><uw-textarea
        label="{{formFischinfoUntersuchungService.label('FischinfoBefischung','fischereigenossenschaft')}}"
        [(value)]="fischereigenossenschaft"
        [disabled]="readonly"
        [updateHeight]="inActiveTab"
    ></uw-textarea></div>
</div>

<div class="row">
    <div class="col-xs-14"><uw-fileupload-mediathek
        label="Foto der Untersuchung"
        url="https://mediathek.api.naturschutzinformationen.nrw.de/mediathek/api/v1/assets/"
        formdata="collection: 8"
        chunkUrl="https://mediathek.api.naturschutzinformationen.nrw.de/mediathek/api/v1/uploadhandler/"
        login="appnatura2000massnahmen"
        token="dbe02652e8b89d195d91cecf1d6b9c13"
        [(value)]="dateiname"
        [(filename)]="dateibeschreibung"
        [disabled]="readonly"
        [unlinked]="false"
    ></uw-fileupload-mediathek></div>
    <div class="col-xs-1">
        <uw-button
            textAlign="center"
            label="&nbsp;"
            [disabled]="readonly || !dateiname"
            (click)="dateiname = null; dateibeschreibung = null"
        ><i class="fa fa-close"></i></uw-button>
    </div>
</div>