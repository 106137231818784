<div class="uw-map-draw-control">
    <div title="Zeichenwerkzeuge ein-/ausblenden" [class.active]="mapEditorService.toolbarIsOpen" (click)="toggleToolbar()">
        <i class="fa fa-edit"></i>
    </div>
    <ng-container *ngIf="mapEditorService.toolbarIsOpen">
        <div class="sub" (click)="zoom()" [class.active]="mapEditorService.isZoomable" title="Auswahl heranzoomen">
            <i class="fa fa-search"></i>
        </div>
        <div
            *ngIf="measureTool==='true'"
            (click)="toggleEdit('measurePolyline')"
            class="sub"
            [class.active]="true"
            [class.hover]="editMode==='measurePolyline'"
            title="Eine Entfernung messen"
        >
            <i class="fa fa-arrows-h"></i>
        </div>
        <div class="sub"
            (click)="clickOnProbenahmestelleAnFliessgewaesser($event)"
            [class.active]="mapEditorService.isEditable"
            title="An nächstes Fließgewässer verschieben">
            <i class="fa fa-arrow-circle-o-right"></i>
        </div>
        <div (click)="clickOnAddPoint($event)" class="sub"
            [class.active]="mapEditorService.isEditable && point==='true'"
            [class.hover]="editMode==='drawPoint' && point==='true'"
            title="Einen Punkt hinzufügen">
            <i class="fa fa-main-attributed icon-punkt"></i>
            <i class="fa fa-attr fa-plus"></i>
        </div>
        <div class="sub"
            title="Eine Linie hinzufügen">
            <i class="fa fa-main-attributed icon-linie"></i>
            <i class="fa fa-attr fa-plus"></i>
        </div>
        <div class="sub"
            title="Eine Fläche hinzufügen">
            <i class="fa fa-main-attributed icon-polygon"></i>
            <i class="fa fa-attr fa fa-plus"></i>
        </div>
        <div class="sub" *ngIf="shapesUpload==='true'">
            <uw-shapefile-input
                iconified="true"
                title="Ein ESRI-Shapefile hinzufügen"
                disabled="true"
                (change)="onChangeShapeInput($event)"
            ></uw-shapefile-input>
        </div>
        <div (click)="toggleSelect()" class="sub" [class.active]="mapEditorService.isEditable" [class.hover]="editMode==='select'"
            title="Teilgeometrie auswählen und nachbearbeiten">
            <i class="fa fa-main-attributed fa-mouse-pointer"></i>
            <i class="fa fa-attr icon-punkt"></i>
        </div>
        <div
            *ngIf="!vanitizing"
            class="sub"
            title="Eine einzuzeichnende Fläche abziehen"
        >
            <i class="fa fa-main-attributed fa-scissors"></i>
            <i class="fa fa-attr fa fa-minus"></i>
        </div>
        <div class="sub"
            title="Teilgeometrie durch Auswahl entfernen">
            <i class="fa fa-main-attributed fa-eraser"></i>
            <i class="fa fa-attr fa-minus"></i>
        </div>
        <div (click)="walkFeatureLayerGroupHistory(-1)" class="sub" [class.active]="historyBackwardsFlag" title="Bearbeitungsschritt zurück">
            <i class="fa fa-main fa-mail-reply"></i>
        </div>
        <div (click)="walkFeatureLayerGroupHistory(1)" class="sub" [class.active]="historyForwardFlag" title="Bearbeitungsschritt vor">
            <i class="fa fa-main fa-mail-forward"></i>
        </div>
        <div
            (click)="onClickCancel()"
            class="sub"
            [class.active]="editMode==='measurePolyline' || mapEditorService.isEditable"
            title="Bearbeitung abbrechen"
        >
            <i class="fa fa-main fa-remove"></i>
        </div>
        <div (click)="onClickSave()" class="sub" [class.active]="mapEditorService.isEditable" title="Geometrie speichern">
            <i class="fa fa-main fa-save"></i>
        </div>
    </ng-container>
    <div (click)="toggleToolbar()" class="last active" title="Zeichenwerkzeuge ein-/ausblenden">
        <i [class.fa-chevron-left]="mapEditorService.toolbarIsOpen" [class.fa-chevron-right]="!mapEditorService.toolbarIsOpen" class="fa"></i>
    </div>
</div>

