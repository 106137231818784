<div [class.fullscreen]="appService.isFullscreenMode">
    <div class="col-md-15">
        <div [style.height.px]="mapViewService.footerHeight" class="uw-footer">
            <div class="left">
                <span>{{dataService.length}} </span>
                <span>{{dataService.folder === 'probenahmestelle' ? 'Probenahmestellen' : 'Untersuchungen'}}</span>
                <span *ngIf="dataService.isLoading"> sind geladen. Bitte warten…</span>
            </div>
            <div>
                <span>{{KoorE}} {{KoorN}} </span>
                <span>ETRS89 / UTM zone 32N</span>
            </div>
            <div id="bottomleft"></div>
        </div>
    </div>
</div>