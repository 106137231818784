import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoMethode from "./interface";
import FischinfoAnodenart from "./../../fischinfo/anodenart/model";
import FischinfoBefischungsart from "./../../fischinfo/befischungsart/model";
import FischinfoBefischungsgeraet from "./../../fischinfo/befischungsgeraet/model";
import FischinfoElektrobefischungsmethode from "./../../fischinfo/elektrobefischungsmethode/model";
import FischinfoNetz from "./../../fischinfo/netz/model";
import FischinfoMethodegeraetetyp from "./../../fischinfo/methodegeraetetyp/model";
import FischinfoBefischung from "./../../fischinfo/befischung/model";
import FischinfoVorgehensweise from "./../../fischinfo/vorgehensweise/model";
@fn.DataModel
export default class FischinfoMethode extends DataModel implements IFischinfoMethode {
    constructor(obj?: object | IFischinfoMethode) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoMethode): FischinfoMethode {
        return new FischinfoMethode(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoMethode): FischinfoMethode {
        Object.assign(this, obj);
        obj && obj["fischinfoAnodenartByAnodenart_id"] && (this.fischinfoAnodenartByAnodenart_id = FischinfoAnodenart.deserialize(obj["fischinfoAnodenartByAnodenart_id"]));
        obj && obj["fischinfoBefischungsartByBefischungsart_id"] && (this.fischinfoBefischungsartByBefischungsart_id = FischinfoBefischungsart.deserialize(obj["fischinfoBefischungsartByBefischungsart_id"]));
        obj && obj["fischinfoBefischungsgeraetByBefischungsgeraet_id"] && (this.fischinfoBefischungsgeraetByBefischungsgeraet_id = FischinfoBefischungsgeraet.deserialize(obj["fischinfoBefischungsgeraetByBefischungsgeraet_id"]));
        obj && obj["fischinfoElektrobefischungsmethodeByElektrobefischungsmethode_id"] && (this.fischinfoElektrobefischungsmethodeByElektrobefischungsmethode_id = FischinfoElektrobefischungsmethode.deserialize(obj["fischinfoElektrobefischungsmethodeByElektrobefischungsmethode_id"]));
        obj && obj["fischinfoNetzListByForeignMethode_id"] && obj["fischinfoNetzListByForeignMethode_id"].length && (this.fischinfoNetzListByForeignMethode_id = obj["fischinfoNetzListByForeignMethode_id"].map(FischinfoNetz.deserialize));
        obj && obj["fischinfoMethodegeraetetypListByForeignMethode_id"] && obj["fischinfoMethodegeraetetypListByForeignMethode_id"].length && (this.fischinfoMethodegeraetetypListByForeignMethode_id = obj["fischinfoMethodegeraetetypListByForeignMethode_id"].map(FischinfoMethodegeraetetyp.deserialize));
        obj && obj["fischinfoBefischungListByForeignMethode_id"] && obj["fischinfoBefischungListByForeignMethode_id"].length && (this.fischinfoBefischungListByForeignMethode_id = obj["fischinfoBefischungListByForeignMethode_id"].map(FischinfoBefischung.deserialize));
        obj && obj["fischinfoVorgehensweiseByVorgehensweise_id"] && (this.fischinfoVorgehensweiseByVorgehensweise_id = FischinfoVorgehensweise.deserialize(obj["fischinfoVorgehensweiseByVorgehensweise_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anodenart_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    @fn.Label("Anodenart")
    public anodenart_id?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "anodendurchmesser", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Anodendurchmesser (cm)")
    public anodendurchmesser?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahlanodenkaescher", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    @fn.Label("Anzahl Anodenkäscher")
    public anzahlanodenkaescher?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahlnichtelektrischerkaescher", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    @fn.Label("Anzahl nichtelektr. Käscher")
    public anzahlnichtelektrischerkaescher?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahlprobepunkte", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    @fn.Label("Anzahl Probepunkte")
    public anzahlprobepunkte?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahlreusen", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    @fn.Label("Anzahl Reusen")
    public anzahlreusen?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "befischtebreite", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("befischte Breite (m)")
    public befischtebreite?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "befischtelaenge", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("befischte Länge (m)")
    public befischtelaenge?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "befischteteilflaeche", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("befischte Teilfläche (m²)")
    public befischteteilflaeche?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "befischungsart_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    @fn.Label("Befischungsart")
    public befischungsart_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "befischungsgeraet_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    @fn.Label("Befischungsgerät")
    public befischungsgeraet_id?: number;

    /**
     * @type {string} character varying 2000
     */
    @fn.DataMember({ name: "beschreibung", type: String, databaseType: "character varying(2000)", nullable: true, memberType: "" })
    @fn.Label("Technische, methodische Anmerkungen")
    public beschreibung?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "elektrobefischungsmethode_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    @fn.Label("E-Befischungsmethode")
    public elektrobefischungsmethode_id?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "expositionsdauer", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Expositionsdauer (h)")
    public expositionsdauer?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "geraeteanzahl", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public geraeteanzahl?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "maschenweiten", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Kleinste Maschenweite (mm)")
    public maschenweiten?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "referenznr", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public referenznr?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "reusenarten", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    @fn.Label("Reusenarten")
    public reusenarten?: string;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "spannung", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Spannung (V)")
    public spannung?: number;

    /**
     * @type {numner} smallint
     */
    @fn.DataMember({ name: "streifenanode", type: Number, databaseType: "smallint", nullable: true, memberType: "" })
    @fn.Label("Streifenanode")
    public streifenanode?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "stromstaerkeampere", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Stromstärke (A)")
    public stromstaerkeampere?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "stromstaerkeprozent", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Stromstärke (%)")
    public stromstaerkeprozent?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "untersuchungsbereich", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Untersuchungsbereich (m²)")
    public untersuchungsbereich?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "vorgehensweise_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    @fn.Label("Vorgehensweise")
    public vorgehensweise_id?: number;

    /**
     * @type {FischinfoAnodenart}
     */
    @fn.DataMember({ name: "fischinfoAnodenartByAnodenart_id", type: FischinfoAnodenart, nullable: true, memberType: "JOIN", joinProperty: "anodenart_id" })
    public fischinfoAnodenartByAnodenart_id?: FischinfoAnodenart;

    /**
     * @type {FischinfoBefischungsart}
     */
    @fn.DataMember({ name: "fischinfoBefischungsartByBefischungsart_id", type: FischinfoBefischungsart, nullable: true, memberType: "JOIN", joinProperty: "befischungsart_id" })
    public fischinfoBefischungsartByBefischungsart_id?: FischinfoBefischungsart;

    /**
     * @type {FischinfoBefischungsgeraet}
     */
    @fn.DataMember({ name: "fischinfoBefischungsgeraetByBefischungsgeraet_id", type: FischinfoBefischungsgeraet, nullable: true, memberType: "JOIN", joinProperty: "befischungsgeraet_id" })
    public fischinfoBefischungsgeraetByBefischungsgeraet_id?: FischinfoBefischungsgeraet;

    /**
     * @type {FischinfoElektrobefischungsmethode}
     */
    @fn.DataMember({ name: "fischinfoElektrobefischungsmethodeByElektrobefischungsmethode_id", type: FischinfoElektrobefischungsmethode, nullable: true, memberType: "JOIN", joinProperty: "elektrobefischungsmethode_id" })
    public fischinfoElektrobefischungsmethodeByElektrobefischungsmethode_id?: FischinfoElektrobefischungsmethode;

    /**
     * @type {Array<FischinfoNetz>}
     */
    @fn.DataMember({ name: "fischinfoNetzListByForeignMethode_id", type: [FischinfoNetz], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoNetzListByForeignMethode_id?: Array<FischinfoNetz>;

    /**
     * @type {Array<FischinfoMethodegeraetetyp>}
     */
    @fn.DataMember({ name: "fischinfoMethodegeraetetypListByForeignMethode_id", type: [FischinfoMethodegeraetetyp], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoMethodegeraetetypListByForeignMethode_id?: Array<FischinfoMethodegeraetetyp>;

    /**
     * @type {Array<FischinfoBefischung>}
     */
    @fn.DataMember({ name: "fischinfoBefischungListByForeignMethode_id", type: [FischinfoBefischung], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBefischungListByForeignMethode_id?: Array<FischinfoBefischung>;

    /**
     * @type {FischinfoVorgehensweise}
     */
    @fn.DataMember({ name: "fischinfoVorgehensweiseByVorgehensweise_id", type: FischinfoVorgehensweise, nullable: true, memberType: "JOIN", joinProperty: "vorgehensweise_id" })
    public fischinfoVorgehensweiseByVorgehensweise_id?: FischinfoVorgehensweise;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
