import { Component, OnInit } from "@angular/core";
import { GeometryCollection } from "geojson";
import { Observable, Subscription, firstValueFrom } from "rxjs";
import { skip } from "rxjs/operators";
import DataService from "./../data/service";
import UserService from "./../user/service";
import MapService from "./../map/service";
import MapViewService from "./../map-view/service";
import { KeyValue as UWTypeAheadKeyValue, SuggestionService as UWTypeAheadSuggestionService } from "ng-uw-type-ahead";
import FischinfoFischgewaessertyp from "../../models/fischinfo/fischgewaessertyp/model";
import FibsHoep from "../../models/fibs/hoep/model";
import FischinfoFischart from "../../models/fischinfo/fischart/model";
import FischinfoMuschelart from "../../models/fischinfo/muschelart/model";
import FischinfoKrebsart from "../../models/fischinfo/krebsart/model";
import FischinfoAnlass from "../../models/fischinfo/anlass/model";
import FischinfoQualitaetdermeldung from "../../models/fischinfo/qualitaetdermeldung/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import TermItem from "../../models/termitem/model";
/**
 * Mit dieser Komponente kann der Nutzer Auswahlkriterien für die angezeigten Probenahmestellen/Untersuchungen zusammenstellen.
 *
 * Die Auswahlkriterien sind in Kategorien unterteilt. Der Nutzer kann in jeder der Kategorien Auswahlkriterien an- und abwählen.
 * Die angezeigten Probenahmestellen/Untersuchungen erfüllen alle eingestellten Auswahlkriterien.
 */
@Component({
    selector: "map-panel-filter",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class MapPanelFilter implements OnInit {
    public params: any = {};
    public areFiltersUnsetting: boolean = false;
    public filterGebietsbezugGetGeomService: UWTypeAheadSuggestionService;
    public filterGewkzGetGeomService: UWTypeAheadSuggestionService;
    public get hasExtendedRights(): boolean {
        return this.userService.roles
            && this.userService.roles.filter((x: string): boolean => x == "ROLE_ADMIN" || x == "ERFASSER_INTERN").length
            ? true
            : false;
    }
    public get hasAdminRights(): boolean {
        return this.userService.roles?.includes("ROLE_ADMIN") ?? false;
    }
    constructor(public dataService: DataService, public mapService: MapService, public userService: UserService, public mapViewService: MapViewService) {
    }
    public ausweisungJsonItems: string;
    public fischgewtypRefJsonItems: string;
    public fibshoepRefJsonItems: string;
    public anlassRefJsonItems: string;
    public qualitaetRefJsonItems: string;
    public artRefJsonItems: string;
    public artRefJsonItemsTmpCache = [];
    ngOnInit() {
        class Ausweisung extends TermItem {
            static deserialize(obj: Pick<Ausweisung, "id" | "term">) {
                return new Ausweisung(obj);
            }
            get bezeichnung(): string {
                return this.term;
            }
        };
        const a = this.dataService
            .getReferenceTable(["fischgewaessertyp", "filter"], FischinfoFischgewaessertyp, "id");
        const b = this.dataService
            .getReferenceTable(["fibshoep", "filter"], FibsHoep, "id");
        const c = this.dataService
            .getReferenceTable("fischart", FischinfoFischart, "id");
        const d = this.dataService
            .getReferenceTable("krebsart", FischinfoKrebsart, "id");
        const e = this.dataService
            .getReferenceTable("muschelart", FischinfoMuschelart, "id");
        const f = this.dataService
            .getReferenceTable("anlass", FischinfoAnlass, "id");
        const g = this.dataService
            .getReferenceTable("qualitaetdermeldung", FischinfoQualitaetdermeldung, "id");
        const h = this.dataService
            .getReferenceTable("ausweisung", Ausweisung, "id");
        return Promise.all<{
            [key: string]: FischinfoFischgewaessertyp | FibsHoep | FischinfoFischart | FischinfoMuschelart | FischinfoKrebsart | FischinfoAnlass | FischinfoQualitaetdermeldung | Ausweisung;
        }>([a, b, c, d, e, f, g, h])
            .then(refs => {
                [0, 1, 2, 3, 4, 5, 6, 7].forEach(x => {
                    let refList = [], entityKey;
                    const keys = Object.keys(refs[x]);
                    if (refs[x][keys[0]] instanceof FischinfoFischart || refs[x][keys[0]] instanceof FischinfoKrebsart || refs[x][keys[0]] instanceof FischinfoMuschelart) {
                        type Art = FischinfoFischart | FischinfoKrebsart | FischinfoMuschelart;
                        keys.sort((k1, k2) => ((refs[x][k1] as Art).bezeichnung ?? '').localeCompare((refs[x][k2] as Art).bezeichnung ?? ''));
                    }
                    keys.forEach(key => {
                        entityKey = key;
                        refList.push(refs[x][key] instanceof FischinfoFischgewaessertyp
                            ? '[' + refs[x][key].id + ',"' + (refs[x][key].id === 0 ? '' : (refs[x][key] as FischinfoFischgewaessertyp).kurzbezeichnung + ', ') + refs[x][key].bezeichnung + '"]'
                            : refs[x][key] instanceof FischinfoFischart
                                ? '[' + (refs[x][key].id + 1000) + '," Fisch, ' + refs[x][key].bezeichnung + '"]'
                                : refs[x][key] instanceof FischinfoMuschelart
                                    ? '[' + (refs[x][key].id + 2000) + ',"Muschel, ' + refs[x][key].bezeichnung + '"]'
                                    : refs[x][key] instanceof FischinfoKrebsart
                                        ? '[' + (refs[x][key].id + 3000) + ',"Krebs, ' + refs[x][key].bezeichnung + '"]'
                                        : '[' + refs[x][key].id + ',"' + refs[x][key].bezeichnung + '"]');
                    });
                    refs[x] && refs[x][entityKey] instanceof FischinfoFischgewaessertyp
                        && (this.fischgewtypRefJsonItems = "[" + refList.join(",") + "]");
                    refs[x] && refs[x][entityKey] instanceof FibsHoep
                        && (this.fibshoepRefJsonItems = "[" + refList.join(",") + "]");
                    refs[x] && refs[x][entityKey] instanceof FischinfoAnlass
                        && (this.anlassRefJsonItems = "[" + refList.join(",") + "]");
                    refs[x] && refs[x][entityKey] instanceof FischinfoQualitaetdermeldung
                        && (this.qualitaetRefJsonItems = "[" + refList.join(",") + "]");
                    refs[x] && refs[x][entityKey] instanceof Ausweisung
                        && (this.ausweisungJsonItems = "[" + refList.join(",") + "]");
                    refs[x] && (refs[x][entityKey] instanceof FischinfoFischart
                        || refs[x][entityKey] instanceof FischinfoMuschelart
                        || refs[x][entityKey] instanceof FischinfoKrebsart)
                        && (this.artRefJsonItemsTmpCache.push(refList));
                    if (this.artRefJsonItemsTmpCache.length == 3) {
                        let artRefList = [];
                        this.artRefJsonItemsTmpCache.forEach(element => {
                            artRefList.push(...element);
                        });
                        this.artRefJsonItems = "[" + artRefList.join(",") + "]";
                    }
                });
            });
    }
    public get isExtendedView(): boolean {
        return this.userService.isLoggedIn === true;
    }
    public clearFilter(e): void {
        for (let filter in this.dataService.changedFilters) {
            this.areFiltersUnsetting = true;
            this.dataService.changedFilters[filter].unset();
            delete this.dataService.changedFilters[filter];
        }
        if (this.areFiltersUnsetting === true) {
            setTimeout(() => {
                this.areFiltersUnsetting = false;
            }, 200);
            if (e !== null) {
                this.dataService.loadDataStore();
            }
        }
    }
    public get accordionHeight(): number {
        const h = this.mapViewService.height
            - this.mapViewService.mapPadding.panel.bottom
            - (8 * 24)
            - 110;
        return Math.max(230, h);
    }
    public objectKeysHelperFunc = Object.keys;
    public arrayFilters = {};
    public deleteArrayFilter(filter, key) {
        delete this.arrayFilters[filter][key];
        this.dataService.setFilter(filter, Object.keys(this.arrayFilters[filter]).join(","));
        this.dataService.loadDataStore();
    }
    public unsetArrayFilter(filter, dataFilter, key, options?: { separator?: string }) {
        const separator = options?.separator ?? ",";
        delete this.arrayFilters[filter][key];
        const keys = Object.keys(this.arrayFilters[filter]);
        if (keys.length === 0) {
            this.dataService.setFilter(dataFilter, null);
            this.dataService.changedFilters[filter].unset();
            delete this.dataService.changedFilters[filter];
        }
        else {
            this.dataService.setFilter(dataFilter, keys.join(separator));
        }
        this.dataService.loadDataStore();
    }
    public updateFilterGeometryLayer(layerName: string, geoms?: string | { bbox: number[], shape: any }[]): void {
        if (typeof geoms === "string") {
            geoms = Object.values(this.arrayFilters[geoms]);
        }
        geoms ??= Object.values(this.arrayFilters[layerName]);
        let allBbox = null;
        const allShape = { type: "GeometryCollection", geometries: [] } as GeometryCollection;
        for (const { bbox, shape } of geoms) {
            if (!allBbox) {
                allBbox = bbox.slice(0);
            }
            else {
                allBbox[0] = Math.min(allBbox[0], bbox[0]);
                allBbox[1] = Math.min(allBbox[1], bbox[1]);
                allBbox[2] = Math.max(allBbox[2], bbox[2]);
                allBbox[3] = Math.max(allBbox[3], bbox[3]);
            }
            allShape.geometries.push(shape);
        }
        if (allBbox) {
            const a = this.mapService.project("EPSG:25832", this.mapService.projection, { x: allBbox[0], y: allBbox[1] });
            const b = this.mapService.project("EPSG:25832", this.mapService.projection, { x: allBbox[2], y: allBbox[3] });
            this.mapService.zoomToBounds([a.y, a.x], [b.y, b.x], this.mapService.projection);
            this.mapService.drawToggleGeoJSON(layerName, allShape as any, "EPSG:25832", this.mapService.getMapFeatureStyle("Gebietsbezugfilter"));
        }
        else {
            this.mapService.zoomToBounds(null, null, null);
            this.mapService.drawToggleGeoJSON("gewkz", null, null);
        }
    }
    public get filterBadge(): any {
        return {
            raum: (this.dataService.filter.geomrefname
                && this.dataService.filter.geomrefname.length) || (this.dataService.filter.gewkz
                    && this.dataService.filter.gewkz.length)
                ? ((this.dataService.filter.geomrefname
                    && this.dataService.filter.geomrefname.length
                    ? 1
                    : 0)
                    + (this.dataService.filter.gewkz
                        && this.dataService.filter.gewkz.length
                        ? 1
                        : 0)
                    + " Filter")
                : null,
            anlass: this.dataService.filter.anlass
                && this.dataService.filter.anlass.split(",").length
                && this.dataService.filter.anlass.split(",").length + " Filter",
            verschiedenes: ((this.dataService.filter.gewaessertyp
                && this.dataService.filter.gewaessertyp.length)
                || (this.dataService.filter.bb
                    && this.dataService.filter.bb.length)
                || (this.dataService.filter.an
                    && this.dataService.filter.an.length)
                || (this.dataService.filter.kf
                    && this.dataService.filter.kf.length)
                || (this.dataService.filter.tg
                    && this.dataService.filter.tg.length)
                || (this.dataService.filter.untersuchterminvon
                    && this.dataService.filter.untersuchterminvon.length)
                || (this.dataService.filter.untersuchterminbis
                    && this.dataService.filter.untersuchterminbis.length)
                || (this.dataService.filter.ausw
                    && this.dataService.filter.ausw.length)
                || (this.dataService.filter.ofwk
                    && this.dataService.filter.ofwk.length)
                || (this.dataService.filter.schluesselwort
                    && this.dataService.filter.schluesselwort.length))
                ? ((this.dataService.filter.gewaessertyp
                    && this.dataService.filter.gewaessertyp.length
                    ? 1
                    : 0)
                    + (this.dataService.filter.bb
                        && this.dataService.filter.bb.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.an
                        && this.dataService.filter.an.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.kf
                        && this.dataService.filter.kf.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.tg
                        && this.dataService.filter.tg.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.untersuchterminvon
                        && this.dataService.filter.untersuchterminvon.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.untersuchterminbis
                        && this.dataService.filter.untersuchterminbis.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.ausw
                        && this.dataService.filter.ausw.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.ofwk
                        && this.dataService.filter.ofwk.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.schluesselwort
                        && this.dataService.filter.schluesselwort.length
                        ? 1
                        : 0)
                    + " Filter")
                : null,
            verwaltung: ((this.dataService.filter.un
                && this.dataService.filter.un.length)
                || (this.dataService.filter.uf
                    && this.dataService.filter.uf.length)
                || (this.dataService.filter.pf
                    && this.dataService.filter.pf.length)
                || (this.dataService.filter.fg
                    && this.dataService.filter.fg.length)
                || (this.dataService.filter.wg
                    && this.dataService.filter.wg.length)
                || (this.dataService.filter.fm
                    && this.dataService.filter.fm.length))
                ? ((this.dataService.filter.un
                    && this.dataService.filter.un.length
                    ? 1
                    : 0)
                    + (this.dataService.filter.uf
                        && this.dataService.filter.uf.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.pf
                        && this.dataService.filter.pf.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.fg
                        && this.dataService.filter.fg.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.wg
                        && this.dataService.filter.wg.length
                        ? 1
                        : 0)
                    + (this.dataService.filter.fm
                        && this.dataService.filter.fm.length
                        ? 1
                        : 0)
                    + " Filter")
                : null,
            fischgewaesser: this.dataService.filter.fischgewtyp
                && this.dataService.filter.fischgewtyp.split(",").length
                && this.dataService.filter.fischgewtyp.split(",").length + " Filter",
            hoep: this.dataService.filter.fibshoep
                && this.dataService.filter.fibshoep.split(",").length
                && this.dataService.filter.fibshoep.split(",").length + " Filter",
            art: (this.dataService.filter.fischart
                || this.dataService.filter.krebsart
                || this.dataService.filter.muschelart) &&
                ((this.dataService.filter.fischart && this.dataService.filter.fischart.split(",").length)
                    || (this.dataService.filter.krebsart && this.dataService.filter.krebsart.split(",").length)
                    || (this.dataService.filter.muschelart && this.dataService.filter.muschelart.split(",").length))
                &&
                ((this.dataService.filter.fischart && this.dataService.filter.fischart.split(",").length
                    ? this.dataService.filter.fischart && this.dataService.filter.fischart.split(",").length
                    : 0)
                    + (this.dataService.filter.krebsart && this.dataService.filter.krebsart.split(",").length
                        ? this.dataService.filter.krebsart && this.dataService.filter.krebsart.split(",").length
                        : 0)
                    + (this.dataService.filter.muschelart && this.dataService.filter.muschelart.split(",").length
                        ? this.dataService.filter.muschelart && this.dataService.filter.muschelart.split(",").length
                        : 0)
                    + " Filter"),
            qualitaet: this.dataService.filter.qualitaet
                && this.dataService.filter.qualitaet.split(",").length
                && this.dataService.filter.qualitaet.split(",").length + " Filter"
        };
    }
    public onFilterChanged(event: any, filter: string): boolean {
        if (this.areFiltersUnsetting) {
            filter = "";
        }
        switch (filter) {
            case "art":
                filter = event.key > 3000
                    ? "krebsart"
                    : event.key > 2000
                        ? "muschelart"
                        : "fischart";
                this.arrayFilters[filter] || (this.arrayFilters[filter] = {});
                this.arrayFilters[filter][String(event.key % 1000)] = { key: event.key % 1000, name: event.value };
                this.dataService.setFilter(filter, Object.keys(this.arrayFilters[filter]).join(","));
                this.dataService.loadDataStore();
                this.areFiltersUnsetting = true;
                event.key = null;
                event.value = event.placeholder;
                this.areFiltersUnsetting = false;
                this.dataService.changedFilters[filter] = {
                    unset: () => {
                        this.arrayFilters[filter] = [];
                        this.dataService.setFilter(filter, null);
                    }
                };
                break;
            case "anlass":
            case "qualitaet":
            case "fibshoep":
            case "fischgewtyp":
                this.arrayFilters[filter] || (this.arrayFilters[filter] = {});
                this.arrayFilters[filter][String(event.key)] = { key: event.key, name: event.value };
                this.dataService.setFilter(filter, Object.keys(this.arrayFilters[filter]).join(","));
                this.dataService.loadDataStore();
                this.areFiltersUnsetting = true;
                event.key = null;
                event.value = event.placeholder;
                this.areFiltersUnsetting = false;
                this.dataService.changedFilters[filter] = {
                    unset: () => {
                        this.arrayFilters[filter] = [];
                        this.dataService.setFilter(filter, null);
                    }
                };
                break;
            case "untersuchterminvon":
            case "untersuchterminbis":
                let error = false, value = null;
                event.target.value && (value = String(event.target.value)); // event.detail.currentValue;
                value && !error && value.split(".").length !== 3 && (error = true);
                value && !error && value.split(".")[0].length > 2 && (error = true);
                value && !error && value.split(".")[1].length > 2 && (error = true);
                value && !error && value.split(".")[2].length > 4 && (error = true);
                value && !error && (value = (value.split(".").map(element => { return element[0] === "0" ? element.substring(element.lastIndexOf("0") + 1) : element; })).join("."));
                value && !error && value.split(".").forEach(element => { (!element || !element.length || isNaN(parseInt(element, 10))) && (error = true); });
                value && !error && parseInt(value.split(".")[0], 10) < 0 && (error = true);
                value && !error && parseInt(value.split(".")[1], 10) < 0 && (error = true);
                value && !error && parseInt(value.split(".")[2], 10) < 0 && (error = true);
                value && !error && parseInt(value.split(".")[0], 10) > 31 && (error = true);
                value && !error && parseInt(value.split(".")[1], 10) > 12 && (error = true);
                value && !error && parseInt(value.split(".")[2], 10) < 100 && (value = value.split(".")[0] + "." + value.split(".")[1] + ".20" + value.split(".")[2]);
                value && !error && parseInt(value.split(".")[2], 10) < 1900 && (error = true);
                value && !error && (value = (value.split(".").map(element => parseInt(element, 10))).join("."));
                this.dataService.setFilter(filter, error || !value
                    ? null
                    : parseInt(String(new Date(Date.UTC(parseInt(value.split(".")[2], 10), parseInt(value.split(".")[1], 10) - 1, parseInt(value.split(".")[0], 10), filter === "untersuchterminvon" ? 0 : 23, filter === "untersuchterminvon" ? 0 : 59, filter === "untersuchterminvon" ? 0 : 59, filter === "untersuchterminvon" ? 0 : 999)).getTime()
                        / 1000).split(".")[0], 10));
                if (error) {
                    event.target.valid = false;
                    event.target.validationError = "Fehlerhaftes Datumsformat.";
                }
                else {
                    this.dataService.loadDataStore();
                }
                this.dataService.changedFilters[filter] = {
                    unset: () => {
                        event.target.value = null;
                        this.dataService.setFilter(filter, null);
                    }
                };
                break;
            case "schluesselwort":
                (event.key || event.key === 0) && event.suggestions.forEach(element => {
                    if (element.key === event.key) {
                        this.arrayFilters[filter] || (this.arrayFilters[filter] = {});
                        this.arrayFilters[filter][String(event.value)] = { key: event.value, name: event.value };
                        this.dataService.setFilter(filter, Object.keys(this.arrayFilters[filter]).join("$$"));
                        this.dataService.loadDataStore();
                        this.areFiltersUnsetting = true;
                        event.key = null;
                        event.value = "";
                        this.areFiltersUnsetting = false;
                        this.dataService.changedFilters[filter] = {
                            unset: () => {
                                this.arrayFilters[filter] = [];
                                this.dataService.setFilter(filter, null);
                            }
                        };
                    }
                });
                break;
            case "gu":
                this.dataService.setFilter(filter, event.key);
                this.dataService.loadDataStore();
                this.dataService.changedFilters[filter] = {
                    unset: () => {
                        event.key = null;
                        event.value = event.placeholder;
                        this.dataService.setFilter(filter, null);
                    }
                };
                new Promise<FischinfoProbenahmestelle>((resolve, reject) => {
                    const subs = new Subscription();
                    const error = e => (subs.unsubscribe(), reject(e));
                    const skipPrev = skip(1) as <T>(source: Observable<T>) => Observable<T>;
                    subs.add((this.dataService.partialLoadSuccess as Observable<FischinfoProbenahmestelle[]>)
                        // skip first emission because it is a BehaviorSubject replaying the previous value
                        .pipe(skipPrev)
                        .subscribe({
                            next: (chunk: FischinfoProbenahmestelle[]) => {
                                if (chunk && chunk.length > 0) {
                                    resolve(chunk[0]);
                                    subs.unsubscribe();
                                }
                            },
                            error,
                        }));
                    subs.add(this.dataService.completeLoadSuccess
                        // skip first emission because it is a BehaviorSubject replaying the previous value
                        .pipe(skipPrev)
                        .subscribe({
                            next: () => {
                                resolve(null);
                                subs.unsubscribe();
                            },
                            error,
                        }));
                })
                    .then(item => {
                        if (item && item.etrs89n && String(item.etrs89n).length > 0) {
                            const point: any = {
                                type: "FeatureCollection",
                                features: [
                                    {
                                        type: "Feature",
                                        geometry: { "type": "Point", "coordinates": [item.etrs89e, item.etrs89n] },
                                        properties: {}
                                    },
                                ]
                            };
                            this.mapService.zoomGeometry(<JSON>point.features[0].geometry, "EPSG:25832");
                        }
                    });
                break;
            case "ausw":
            case "ofwk":
            case "gewaessertyp":
            case "bb":
            case "an":
            case "un":
            case "tg":
            case "kf":
            case "uf":
            case "pf":
            case "fg":
            case "fp":
            case "wg":
            case "fm":
                this.dataService.setFilter(filter, event.key);
                this.dataService.loadDataStore();
                this.dataService.changedFilters[filter] = {
                    unset: () => {
                        event.key = null;
                        event.value = event.placeholder;
                        this.dataService.setFilter(filter, null);
                    }
                };
                break;
            case "gewkz":
                (event.key || event.key === 0) && event.suggestions.forEach(element => {
                    if (element.key === event.key) {
                        const key = element.suggestion.name.split(" (")[1].split(")")[0];
                        this.arrayFilters[filter] || (this.arrayFilters[filter] = {});
                        this.arrayFilters[filter][String(key)] = { key: key, name: event.value };
                        this.dataService.setFilter(filter, Object.keys(this.arrayFilters[filter]).join(","));
                        this.dataService.loadDataStore();
                        this.areFiltersUnsetting = true;
                        event.key = null;
                        event.value = "";
                        this.areFiltersUnsetting = false;
                        this.dataService.changedFilters[filter] = {
                            unset: () => {
                                this.arrayFilters[filter] = [];
                                this.dataService.setFilter(filter, null);
                                this.mapService.drawToggleGeoJSON(filter, null, null);
                            }
                        };
                        if (!this.filterGewkzGetGeomService) {
                            this.filterGewkzGetGeomService = event.suggestionFactoryService.createSuggestionService({
                                limit: event.limit,
                                jsonItems: event.jsonItems && JSON.parse(event.jsonItems),
                                staticJsonFileUrl: event.staticJsonFileUrl,
                                webServiceApiUrl: this.dataService.webServiceApiUrl + "gebietsbezug/{id}?shape=true&bbox=true&geomrefname={geomrefname}&geomrefnameid={geomrefnameid}&geomrefnameschema={geomrefnameschema}&geomrefnametable={geomrefnametable}&geomrefvalueid={geomrefvalueid}&format=application/json",
                                webServiceApiTokenBasedLoginUrl: event.webServiceApiTokenBasedLoginUrl,
                                itemsAccess: event.itemsAccess && event.itemsAccess_parse(event.itemsAccess),
                                matcherFactory: event.patternMatchingOnSuggestions
                                    ? (pattern: string) => (suggestion: UWTypeAheadKeyValue) => suggestion[event.keyValueMap.value]
                                        .toLowerCase()
                                        .indexOf(pattern.toLowerCase()) >= 0
                                    : null
                            });
                        }
                        this.filterGewkzGetGeomService
                            .search(element.suggestion)
                            .catch((e: Error) => {
                                this.mapService.zoomToBounds(null, null, null);
                                this.mapService.drawToggleGeoJSON("gewkz", null, null);
                                return [];
                            })
                            .then((result: any) => {
                                if (result?.[0]?.bbox) {
                                    const polyBbox = JSON.parse(result[0].bbox).coordinates[0];
                                    Object.assign(this.arrayFilters[filter][String(key)], {
                                        bbox: [polyBbox[0][0], polyBbox[0][1], polyBbox[2][0], polyBbox[2][1]],
                                        shape: JSON.parse(result[0].shape),
                                    });
                                    this.updateFilterGeometryLayer(filter, Object.values(this.arrayFilters[filter]));
                                }
                            });
                    }
                });
                break;
            case "psnr":
                this.areFiltersUnsetting = true;
                [1, 2, 3, 4, "psnr"].forEach(i => {
                    const ix = typeof i === "string" ? i : ("gebietsbezug" + i);
                    if (filter !== ix
                        && this.dataService.changedFilters[ix]) {
                        this.dataService.changedFilters[ix].unset();
                        this.dataService.changedFilters[ix] = null;
                        delete this.dataService.changedFilters[ix];
                    }
                });
                this.areFiltersUnsetting = false;

                (event.key || event.key === 0) && event.suggestions.forEach(element => {
                    if (element.key === event.key) {
                        const key = element.suggestion.term;
                        this.arrayFilters[filter] || (this.arrayFilters[filter] = {});
                        this.arrayFilters[filter][String(key)] = { key: key, name: event.value };
                        this.dataService.setFilter(filter, Object.keys(this.arrayFilters[filter]).join("$$"));
                        this.dataService.loadDataStore();
                        this.areFiltersUnsetting = true;
                        event.key = null;
                        event.value = "";
                        this.areFiltersUnsetting = false;
                        this.dataService.changedFilters[filter] = {
                            unset: () => {
                                this.arrayFilters[filter] = [];
                                this.dataService.setFilter(filter, null);
                                this.mapService.drawToggleGeoJSON("gebietsbezug", null, null);
                            }
                        };

                        if (!this.filterGebietsbezugGetGeomService) {
                            this.filterGebietsbezugGetGeomService = event.suggestionFactoryService.createSuggestionService({
                                limit: event.limit,
                                jsonItems: event.jsonItems && JSON.parse(event.jsonItems),
                                staticJsonFileUrl: event.staticJsonFileUrl,
                                webServiceApiUrl: this.dataService.webServiceApiUrl + "gebietsbezug/{id}?shape=true&bbox=true&geomrefname={geomrefname}&geomrefnameid={geomrefnameid}&geomrefnameschema={geomrefnameschema}&geomrefnametable={geomrefnametable}&geomrefvalueid={geomrefvalueid}&format=application/json",
                                webServiceApiTokenBasedLoginUrl: event.webServiceApiTokenBasedLoginUrl,
                                itemsAccess: event.itemsAccess && event.itemsAccess_parse(event.itemsAccess),
                                matcherFactory: event.patternMatchingOnSuggestions
                                    ? (pattern: string) => (suggestion: UWTypeAheadKeyValue) => suggestion[event.keyValueMap.value]
                                        .toLowerCase()
                                        .indexOf(pattern.toLowerCase()) >= 0
                                    : null
                            });
                        }
                        if (!key.startsWith("~*")) {
                            this.filterGebietsbezugGetGeomService
                                .search(element.suggestion)
                                .catch((e: Error) => {
                                    this.mapService.zoomToBounds(null, null, null);
                                    this.mapService.drawToggleGeoJSON("gebietsbezug", null, null);
                                    return [];
                                })
                                .then((result: any) => {
                                    if (result?.[0]?.bbox) {
                                        const polyBbox = JSON.parse(result[0].bbox).coordinates[0];
                                        Object.assign(this.arrayFilters[filter][String(key)], {
                                            bbox: [polyBbox[0][0], polyBbox[0][1], polyBbox[2][0], polyBbox[2][1]],
                                            shape: JSON.parse(result[0].shape),
                                        });
                                        this.updateFilterGeometryLayer("gebietsbezug", Object.values(this.arrayFilters[filter]));
                                    }
                                });
                        }
                        else {
                            this.dataService.getAll(
                                this.dataService.transcribeURL(
                                    this.dataService.webServiceApiItemUrl.replace("{folder}", "{folder0}/{folder1}"),
                                    {
                                        folder0: "gebietsbezug",
                                        folder1: "psnr_caseinsensitive",
                                        id: key.replace("~*", ""),
                                    }
                                ),
                                { deserialize: x => x },
                            )
                                .catch((e: Error) => {
                                    this.mapService.zoomToBounds(null, null, null);
                                    this.mapService.drawToggleGeoJSON("gebietsbezug", null, null);
                                    return [];
                                })
                                .then((result: any) => {
                                    if (result?.[0]?.bbox) {
                                        const polyBbox = JSON.parse(result[0].bbox).coordinates[0];
                                        Object.assign(this.arrayFilters[filter][String(key)], {
                                            bbox: [polyBbox[0][0], polyBbox[0][1], polyBbox[2][0], polyBbox[2][1]],
                                            shape: JSON.parse(result[0].shape),
                                        });
                                        this.updateFilterGeometryLayer("gebietsbezug", Object.values(this.arrayFilters[filter]));
                                    }
                                });
                        }
                    }
                });
                break;
            case "gebietsbezug1":
            case "gebietsbezug2":
            case "gebietsbezug3":
            case "gebietsbezug4":
                this.areFiltersUnsetting = true;
                [1, 2, 3, 4, "psnr"].forEach(i => {
                    const ix = typeof i === "string" ? i : ("gebietsbezug" + i);
                    if (filter !== ix
                        && this.dataService.changedFilters[ix]) {
                        this.dataService.changedFilters[ix].unset();
                        this.dataService.changedFilters[ix] = null;
                        delete this.dataService.changedFilters[ix];
                    }
                });
                this.areFiltersUnsetting = false;
                this.dataService.setFilter("geomrefname", null);
                this.dataService.setFilter("geomrefnameid", null);
                this.dataService.setFilter("geomrefnameschema", null);
                this.dataService.setFilter("geomrefnametable", null);
                this.dataService.setFilter("geomrefvalueid", null);
                this.mapService.drawToggleGeoJSON("gebietsbezug", null, null);
                event.suggestions.forEach(element => {
                    if (element.key === event.key) {
                        this.dataService.setFilter("geomrefname", element.suggestion.geomrefname);
                        this.dataService.setFilter("geomrefnameid", element.suggestion.geomrefnameid);
                        this.dataService.setFilter("geomrefnameschema", element.suggestion.geomrefnameschema);
                        this.dataService.setFilter("geomrefnametable", element.suggestion.geomrefnametable);
                        this.dataService.setFilter("geomrefvalueid", element.suggestion.geomrefvalueid);
                        if (!this.filterGebietsbezugGetGeomService) {
                            this.filterGebietsbezugGetGeomService = event.suggestionFactoryService.createSuggestionService({
                                limit: event.limit,
                                jsonItems: event.jsonItems && JSON.parse(event.jsonItems),
                                staticJsonFileUrl: event.staticJsonFileUrl,
                                webServiceApiUrl: this.dataService.webServiceApiUrl + "gebietsbezug/{id}?shape=true&bbox=true&geomrefname={geomrefname}&geomrefnameid={geomrefnameid}&geomrefnameschema={geomrefnameschema}&geomrefnametable={geomrefnametable}&geomrefvalueid={geomrefvalueid}&format=application/json",
                                webServiceApiTokenBasedLoginUrl: event.webServiceApiTokenBasedLoginUrl,
                                itemsAccess: event.itemsAccess && event.itemsAccess_parse(event.itemsAccess),
                                matcherFactory: event.patternMatchingOnSuggestions
                                    ? (pattern: string) => (suggestion: UWTypeAheadKeyValue) => suggestion[event.keyValueMap.value]
                                        .toLowerCase()
                                        .indexOf(pattern.toLowerCase()) >= 0
                                    : null
                            });
                        }
                        this.filterGebietsbezugGetGeomService
                            .search(element.suggestion)
                            .catch((e: Error) => {
                                this.mapService.zoomToBounds(null, null, null);
                                this.mapService.drawToggleGeoJSON("gebietsbezug", null, null);
                                return [];
                            })
                            .then((result: any) => {
                                if (result && result[0] && result[0].bbox) {
                                    let bbox = JSON.parse(result[0].bbox);
                                    let a = this.mapService.project("EPSG:25832", this.mapService.projection, [bbox.coordinates[0][2][0], bbox.coordinates[0][0][1]]);
                                    let b = this.mapService.project("EPSG:25832", this.mapService.projection, [bbox.coordinates[0][0][0], bbox.coordinates[0][1][1]]);
                                    this.mapService.zoomToBounds([a[1], a[0]], [b[1], b[0]], this.mapService.projection);
                                    this.mapService.drawToggleGeoJSON("gebietsbezug", JSON.parse(result[0].shape), "EPSG:25832", this.mapService.getMapFeatureStyle("Gebietsbezugfilter"));
                                }
                            });
                    }
                });
                this.dataService.loadDataStore();
                this.dataService.changedFilters[filter] = {
                    unset: () => {
                        event.key = null;
                        event.value = "";
                        this.dataService.setFilter("geomrefname", null);
                        this.dataService.setFilter("geomrefnameid", null);
                        this.dataService.setFilter("geomrefnameschema", null);
                        this.dataService.setFilter("geomrefnametable", null);
                        this.dataService.setFilter("geomrefvalueid", null);
                        this.mapService.drawToggleGeoJSON("gebietsbezug", null, null);
                    }
                };
                break;
        }
        return true;
    }
}
