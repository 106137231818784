import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import DataService from "../data/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoFfhbewertung from "../../models/fischinfo/ffhbewertung/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
/**
 * Mit dieser Komponente kann der Nutzer die FFH-Bewertung einer Art einer Untersuchung ansehen und bearbeiten.
 *
 * Der Nutzer kann Einzelbewertung für Zustand der Population, Habitatqualität & Beeinträchtigungen angeben.
 * Daraus berechnet die Komponente eine Besamtbewertung. Der Nutzer kann dann noch eine abweichende Gesamtbewertung angeben.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-ffhbewertung-art",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungFfhBewertungArt implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @Input()
    public artId: string;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public ffhBewertung: FischinfoFfhbewertung;
    public get beeintraechtigungen(): string {
        return this.ffhBewertung
            && this.ffhBewertung.beeintraechtigungen;
    }
    public set beeintraechtigungen(value: string) {
        this.ffhBewertung
            && (this.ffhBewertung.beeintraechtigungen = value);
        this.calcFfhBewertung();
        this.setFfhBewertung();
    }
    public get begruendung(): string {
        return this.ffhBewertung
            && this.ffhBewertung.begruendung;
    }
    public set begruendung(value: string) {
        this.ffhBewertung
            && (this.ffhBewertung.begruendung = value);
        this.setFfhBewertung();
    }
    public get showBegruendung(): boolean {
        return this.gesamtwert
            && this.gesamtwert !== this.gesamtwertmanuell;
    }
    public get zustandderpopulation(): string {
        return this.ffhBewertung
            && this.ffhBewertung.zustandderpopulation;
    }
    public set zustandderpopulation(value: string) {
        this.ffhBewertung
            && (this.ffhBewertung.zustandderpopulation = value);
        this.calcFfhBewertung();
        this.setFfhBewertung();
    }
    public get habitatqualitaet(): string {
        return this.ffhBewertung
            && this.ffhBewertung.habitatqualitaet;
    }
    public set habitatqualitaet(value: string) {
        this.ffhBewertung
            && (this.ffhBewertung.habitatqualitaet = value);
        this.calcFfhBewertung();
        this.setFfhBewertung();
    }
    public get gesamtwert(): string {
        const ffhbewertung = ((this.zustandderpopulation || "")
            + (this.habitatqualitaet || "")
            + (this.beeintraechtigungen || ""));
        return {
            "AAA": "A",
            "AAB": "A",
            "AAC": "A",
            "ABA": "A",
            "ABB": "B",
            "ABC": "B",
            "ACA": "B",
            "ACB": "B",
            "ACC": "C",
            "BAA": "A",
            "BAB": "B",
            "BAC": "B",
            "BBA": "B",
            "BBB": "B",
            "BBC": "B",
            "BCA": "B",
            "BCB": "B",
            "BCC": "C",
            "CAA": "B",
            "CAB": "B",
            "CAC": "C",
            "CBA": "B",
            "CBB": "B",
            "CBC": "C",
            "CCA": "C",
            "CCB": "C",
            "CCC": "C",
        }[ffhbewertung] || null;
    }
    public get gesamtwertmanuell(): string {
        return this.ffhBewertung
            && this.ffhBewertung.gesamtwertmanuell;
    }
    public set gesamtwertmanuell(value: string) {
        this.ffhBewertung
            && (this.ffhBewertung.gesamtwertmanuell = value);
        this.setFfhBewertung();
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    public ngOnInit() {
        this.refreshErgebnisse();
    }
    public refreshErgebnisse() {
        let befischung: FischinfoBefischung = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung");
        this.ffhBewertung =
            Array.of<FischinfoFfhbewertung>(...(befischung.fischinfoFfhbewertungListByForeignBefischung_id || []))
                .find(x => x.art_id === parseInt(this.artId, 10)) || new FischinfoFfhbewertung({
                id: 0,
                art_id: parseInt(this.artId, 10),
                befischung_id: befischung.id,
            });
    }
    public calcFfhBewertung() {
        this.ffhBewertung && (this.ffhBewertung.gesamtwert = this.gesamtwert);
        this.ffhBewertung && (this.gesamtwertmanuell = this.gesamtwert);
        this.begruendung = null;
    }
    public setFfhBewertung() {
        this.ffhBewertung && this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoFfhbewertung>(this.untersuchung, "FischinfoFfhbewertung", this.ffhBewertung);
        this.changed.emit(true);
    }
    public onZustandDerPopulationChange(e: any) {
        this.zustandderpopulation = e.value;
    }
    public onHabitatQualitaetChange(e: any) {
        this.habitatqualitaet = e.value;
    }
    public onBeeintraechtigungenChange(e: any) {
        this.beeintraechtigungen = e.value;
    }
    public onGesamtwertManuellChange(e: any) {
        this.gesamtwertmanuell = e.value;
    }
}
