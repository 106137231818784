import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoVerortung_fischgewaessertypen from "./interface";
import FischinfoFischgewaessertyp from "./../../fischinfo/fischgewaessertyp/model";
@fn.DataModel
export default class FischinfoVerortung_fischgewaessertypen extends DataModel implements IFischinfoVerortung_fischgewaessertypen {
    constructor(obj?: object | IFischinfoVerortung_fischgewaessertypen) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoVerortung_fischgewaessertypen): FischinfoVerortung_fischgewaessertypen {
        return new FischinfoVerortung_fischgewaessertypen(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoVerortung_fischgewaessertypen): FischinfoVerortung_fischgewaessertypen {
        Object.assign(this, obj);
        obj && obj["fischinfoFischgewaessertypByFischgewaessertyp_id"] && (this.fischinfoFischgewaessertypByFischgewaessertyp_id = FischinfoFischgewaessertyp.deserialize(obj["fischinfoFischgewaessertypByFischgewaessertyp_id"]));
        return this;
    }

    /**
     * @type {string} character varying 254
     * Datum der zugrundeliegenden Stationierungsversion (z.B. 30.11.2014)
     */
    @fn.DataMember({ name: "Datum_GSK", type: String, databaseType: "character varying(254)", nullable: true, memberType: "" })
    public Datum_GSK?: string;

    /**
     * @type {string} character varying 254
     * zugrunde liegende Stationierungsversion (z.B. GSK3C)
     */
    @fn.DataMember({ name: "GSK", type: String, databaseType: "character varying(254)", nullable: true, memberType: "" })
    public GSK?: string;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "bis", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public bis?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "fischgewaessertyp_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public fischgewaessertyp_id?: number;

    /**
     * @type {string} character varying 254
     */
    @fn.DataMember({ name: "gewaesserkennzahl", type: String, databaseType: "character varying(254)", nullable: true, memberType: "" })
    public gewaesserkennzahl?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "von", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public von?: number;

    /**
     * @type {FischinfoFischgewaessertyp}
     */
    @fn.DataMember({ name: "fischinfoFischgewaessertypByFischgewaessertyp_id", type: FischinfoFischgewaessertyp, nullable: true, memberType: "JOIN", joinProperty: "fischgewaessertyp_id" })
    public fischinfoFischgewaessertypByFischgewaessertyp_id?: FischinfoFischgewaessertyp;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
