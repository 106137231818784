<div class="row" style="margin-top:10px;">
    <div class="col-xs-15">
        <table class="table table-striped methodegeraetetypList inherit-inputs">
            <thead>
                <tr>
                    <th title="Eingesetzte Gerätetypen">Eingesetzte Gerätetypen</th>
                    <th [style.width.%]="35" title="Stromart">Stromart</th>
                    <th [style.width.%]="12" title="Anzahl">Anzahl</th>
                    <th title="abgelesen">abgelesen</th>
                    <th *ngIf="!readonly"  [style.width.px]="24"></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    form-fischinfo-untersuchung-befischung-methode-egeraet-geraetetyp
                    *ngFor="let u of methodegeraetetypList; let i = index"
                    [modalId]="modalId"
                    [readonly]="readonly"
                    [methodegeraetetyp]="u"
                    [untersuchung]="untersuchung"
                    (changed)="onChangedMethodegeraetetyp($event, u, i)"
                ></tr>
                <tr
                    form-fischinfo-untersuchung-befischung-methode-egeraet-geraetetyp
                    *ngIf="readonly && !methodegeraetetypList?.length"
                    [modalId]="modalId"
                    [readonly]="readonly"
                ></tr>
            </tbody>
        </table>
        <uw-inline-button
        *ngIf="!readonly && firstIsSet"
            iconClass="fa fa-plus-circle"
            title="Weiteren Gerätetyp hinzufügen"
            value="Weiteren Gerätetyp hinzufügen"
            (click)="addModel($event)"
        ></uw-inline-button>
    </div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-4"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','anzahlanodenkaescher')}}"
        [(value)]="anzahlanodenkaescher"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-4"><uw-select
        [disabled]="readonly"
        jsonItems='[[0,"Keine Angabe"],[1,"Ja"],[2,"Nein"]]'
        keyValueMapping="0:1"
        [label]="formFischinfoUntersuchungService.label('FischinfoMethode','streifenanode')"
        [(key)]="streifenanode"
        nullable="true"
    ></uw-select></div>
</div>

<div class="row">
    <div class="col-xs-15">
        <div class="row">
            <div class="col-xs-15" style="margin-bottom: -2px;">
                <label>{{formFischinfoUntersuchungService.label('FischinfoMethode','anzahlnichtelektrischerkaescher')}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-15 col-sm-4">
                <uw-textinput
                    [(value)]="anzahlnichtelektrischerkaescher"
                    [disabled]="readonly"
                ></uw-textinput> 
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xs-15">
        <label>Angaben zur angelegten Spannung und Stromstärke an dem als abgelesen markiertem Gerätetypen</label>
    </div>
    <div class="col-xs-15 col-sm-4"><label style="margin-top:0;">{{formFischinfoUntersuchungService.label('FischinfoMethode','spannung')}}</label></div>
    <div class="col-xs-15 col-sm-4"><label style="margin-top:0;">{{formFischinfoUntersuchungService.label('FischinfoMethode','stromstaerkeampere')}}</label></div>
    <div class="col-xs-15 col-sm-4"><label style="margin-top:0;">{{formFischinfoUntersuchungService.label('FischinfoMethode','stromstaerkeprozent')}}</label></div>
</div>
<div class="row">
    <div class="col-xs-15 col-sm-4"><uw-textinput
        [(value)]="spannung"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-4"><uw-textinput
        [(value)]="stromstaerkeampere"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-4"><uw-textinput
        [(value)]="stromstaerkeprozent"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-7dot5"><uw-select
        [disabled]="readonly"
        *ngIf="elektrobefischungsmethode_idRefJsonItems"
        [jsonItems]="elektrobefischungsmethode_idRefJsonItems"
        keyValueMapping="0:1"
        size="10"
        [label]="formFischinfoUntersuchungService.label('FischinfoMethode','elektrobefischungsmethode_id')"
        [(key)]="elektrobefischungsmethode_id"
        nullable="true"
    ></uw-select></div>
</div>

<ng-container [ngSwitch]="elektrobefischungsmethode_id">
    <ng-container *ngSwitchCase="1">
        <form-fischinfo-untersuchung-befischung-methode-egeraet-streckenbefischung
            [untersuchung]="untersuchung"
            [modalId]="modalId"
            [readonly]="readonly"
            (changed)="onChangedMethodegeraetstreckenbefischung($event)"
        ></form-fischinfo-untersuchung-befischung-methode-egeraet-streckenbefischung>
    </ng-container>
    <ng-container *ngSwitchCase="2">
        <form-fischinfo-untersuchung-befischung-methode-egeraet-pointabundance
            [untersuchung]="untersuchung"
            [modalId]="modalId"
            [readonly]="readonly"
            (changed)="onChangedMethodegeraetpointabundance($event)"
        ></form-fischinfo-untersuchung-befischung-methode-egeraet-pointabundance>
    </ng-container>
    <ng-container *ngSwitchCase="3">
        <form-fischinfo-untersuchung-befischung-methode-egeraet-anderemethode
            [untersuchung]="untersuchung"
            [modalId]="modalId"
            [readonly]="readonly"
            (changed)="onChangedMethodegeraetanderemethode($event)"
        ></form-fischinfo-untersuchung-befischung-methode-egeraet-anderemethode>
    </ng-container>
</ng-container>
