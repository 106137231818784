import { Component, Input, ViewChild, ElementRef, AfterViewInit, Inject, HostListener } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import MapViewService from "./service";
import { UWSidePanel } from "ng-uw-side-panel";
import MapPanel from "./../map-panel/component";
import MapToolbar from "./../map-toolbar/component";
import Modal from "./../modal/component";
import VirtualTable from "./../map-table/component";
import MapService from "./../map/service";
import MapEditorService from "./../map-editor/service";
import DataService from "./../data/service";
import ModalService from "./../modal/service";
import UserService from "./../user/service";
import AppService from "./../app/service";
/**
 * Diese Komponenten stellt die Hauptansicht der Anwendung dar und setzt sich aus einer Karte,
 * eine Eingabe- und einem Ausgabepaneel zusammen.
 *
 * Die Karte stellt ein Eingabefeld zur Suche von geografischen Elementen und eine zusätzliche Werkzeugleiste zur Verfügung.
 * Über die Steuerelement im Eingabepaneel kann der Nutzer die angezeigten Daten im Ausgabepaneel beeinflussen.
 */
@Component({
    selector: "uw-map-view",
    templateUrl: "./default.component.html",
    styleUrls: [
        "./default.component.less",
        "./../../assets/styles/main.less"
    ]
})
export default class MapView implements AfterViewInit {
    @ViewChild("southPanel", { static: false })
    public southPanel: UWSidePanel;
    @ViewChild("eastPanel", { static: false })
    public eastPanel: UWSidePanel;
    @ViewChild("panel", { static: false })
    public panel: MapPanel;
    @ViewChild("toolbar", { static: false })
    public toolbar: MapToolbar;
    @ViewChild("table", { static: false })
    public table: VirtualTable;
    @Input()
    height: number;
    @Input()
    mode: string;
    @Input()
    editable: boolean;
    constructor(public mapViewService: MapViewService, public modalService: ModalService, public mapService: MapService, public mapEditorService: MapEditorService, public dataService: DataService, public appService: AppService, public userService: UserService, public elementRef: ElementRef) {
        this.mapViewService.nativeElement = elementRef.nativeElement;
        this.modalService.modalCloseObservable.subscribe(event => {
            this.mapViewService.mapPadding.modal.left = 0;
            this.mapViewService.mapPadding.modal.right = 0;
            setTimeout(() => {
                this.resetMapLayout();
                this.resetMapPaddingByModal();
            }, 100);
        });
    }
    public ngAfterViewInit(): void {
        const maxEastPanelWidth = 561;
        const minEastPanelWidth = 379;
        this.eastPanel && this.isExtendedView && this.eastPanel.resize(maxEastPanelWidth);
        this.mapViewService.staticHeight = this.height;
        this.southPanel && this.southPanel.resizeEvent.subscribe(resize => {
            if (resize.dimension === "height") {
                this.mapViewService.mapPadding.panel.bottom = Math.max(0, resize.value);
                this.resetMapLayout();
            }
        });
        this.eastPanel && this.eastPanel.resizeEvent.subscribe(resize => {
            if (resize.dimension === "width") {
                this.mapViewService.mapPadding.panel.right = Math.max(0, resize.value);
                this.resetMapLayout();
            }
        });
        this.toolbar.button.asObservable().subscribe(event => {
            switch (event.name) {
                case "extentView":
                    if (event.mode === "map") {
                        this.southPanel && this.southPanel.resize(0);
                        this.eastPanel && this.eastPanel.resize(0);
                    }
                    else if (event.mode === "table") {
                        this.southPanel && this.southPanel.resize(this.mapViewService.height);
                        this.eastPanel && this.eastPanel.resize(0);
                    }
                    else if (event.mode === "filter") {
                        this.southPanel && this.southPanel.resize(0);
                        this.eastPanel && this.eastPanel.resize(this.mapViewService.width);
                    }
                    else if (event.mode === "mapfilter") {
                        this.southPanel && this.southPanel.resize(0);
                        this.eastPanel && this.eastPanel.resize(Math.min(maxEastPanelWidth, Math.max(minEastPanelWidth, this.mapViewService.width / 2.032)));
                    }
                    else if (event.mode === "tablefilter") {
                        this.southPanel && this.southPanel.resize(this.mapViewService.height - Math.min(maxEastPanelWidth, Math.max(minEastPanelWidth, this.mapViewService.width / 2.032)));
                        this.eastPanel && this.eastPanel.resize(this.mapViewService.width);
                    }
                    else if (event.mode === "wizard") {
                        this.southPanel && this.southPanel.resize(Math.max(160, this.mapViewService.height / 3));
                        this.isExtendedView
                            ? this.eastPanel && this.eastPanel.resize(Math.min(maxEastPanelWidth, Math.max(minEastPanelWidth, this.mapViewService.width / 2.032)))
                            : this.eastPanel && this.eastPanel.resize(0);
                    }
                    break;
                case "toggleFullscreen":
                    this.toggleFullscreen();
                    break;
                case "clearFilter":
                    this.panel.clearFilter(event);
                    break;
                case "exportCSV":
                    this.modalService.closeModals();
                    this.modalService.createModal({
                        data: {
                            activeTab: "export",
                            export: true,
                            loaded: 1
                        },
                    });
                    break;
                case "importCSV":
                    this.modalService.closeModals();
                    this.modalService.createModal({
                        data: {
                            activeTab: "import",
                            import: true,
                            loaded: 1
                        },
                    });
                    break;
                case "logout":
                    if (this.appService.isFullscreenMode) {
                        this.toggleFullscreen();
                    }
                    window.location.reload();
                    break;
                case "manageAssignments":
                    this.modalService.closeModals();
                    this.modalService.createModal({
                        data: {
                            activeTab: "auftragverwaltung",
                            auftragverwaltung: true,
                            loaded: 0
                        },
                    });
                    break;
                default:
                    break;
            }
        });
        this.mapViewService.mapPadding.panel.right = this.eastPanel ? this.eastPanel.width : 0;
        this.mapViewService.mapPadding.panel.bottom = this.southPanel ? this.southPanel.height : 0;
        this.resetMapLayout();
        if (this.appService.isIFrame) {
            this.toggleFullscreen();
        }
        this.table.init();
        /*
        this.dataService
            .getLoginTokenPromise()
            .then((token: string) => {
                this.dataService.loadDataStore();
                this.mapService.authToken = token;
                setTimeout(() => {
                    this.mapService.zoomToBounds(null, null, null);
                }, 100);
            })
            ;
        */
    }
    @HostListener("window:resize")
    public onWindowResize(e: Event) {
        this.resetMapLayout();
    }
    public onModalMove(e: any, modal: any): void {
        this.resetMapPaddingByModal();
    }
    public onModalResize(e: any, modal: any): void {
        this.resetMapPaddingByModal();
    }
    public get isExtendedView(): boolean {
        return this.userService.isLoggedIn === true;
    }
    public toggleFullscreen(): void {
        if (!this.appService.isFullscreenMode) {
            document.body.classList.add("fullscreen");
        }
        else {
            document.body.classList.remove("fullscreen");
        }
        this.appService.isFullscreenMode = !this.appService.isFullscreenMode;
        /*
        if (this.modal) {
            this.modal.left = this.appService.isFullscreenMode
                ? this.appService.width - this.modalService.previousActiveModalDimensions.width
                - 6
                : this.mapViewService.width - this.modalService.previousActiveModalDimensions.width
                ;
            this.modal.left = this.modal.left < 0
                ? 0
                : this.modal.left
                ;
            this.modalService.setPreviousActiveModalPosition(this.modal.top, this.modal.left);
        }
        */
        setTimeout(() => {
            this.modalService.checkIfModalsAreInView(this.appService.isFullscreenMode);
            this.resetMapLayout();
            this.resetMapPaddingByModal();
        }, 100);
    }
    public resetMapPaddingByModal(): void {
        if (this.modalService.modals.length) {
            const modalLeft = this.modalService.previousActiveModalPosition.left;
            const modalWidth = modalLeft < 0
                ? Math.max(0, this.modalService.previousActiveModalDimensions.width + modalLeft)
                : Math.max(0, this.modalService.previousActiveModalDimensions.width);
            const panelRight = Math.max(0, this.mapViewService.mapPadding.panel.right);
            const spaceRight = this.mapViewService.width -
                panelRight -
                Math.max(0, modalLeft) -
                modalWidth;
            const spaceLeft = Math.max(0, modalLeft);
            if (spaceLeft > 200 || spaceRight > 200) {
                this.mapViewService.mapPadding.modal.left =
                    spaceRight > spaceLeft ? Math.max(0, modalLeft) + modalWidth : 0;
                this.mapViewService.mapPadding.modal.right =
                    spaceRight > spaceLeft
                        ? 0
                        : this.mapViewService.width -
                        Math.min(this.mapViewService.width, modalLeft);
            }
            else {
                this.mapViewService.mapPadding.modal.left = 0;
                this.mapViewService.mapPadding.modal.right = 0;
            }
        }
        else {
            this.mapViewService.mapPadding.modal.left = 0;
            this.mapViewService.mapPadding.modal.right = 0;
        }
        this.mapService.padding.left = Math.max(0, this.mapViewService.mapPadding.panel.left, this.mapViewService.mapPadding.modal.left);
        this.mapService.padding.right = Math.max(0, this.mapViewService.mapPadding.panel.right, this.mapViewService.mapPadding.modal.right);
    }
    public resetMapLayout() {
        this.mapService.updateSize();
        if (this.eastPanel &&
            this.mapViewService.width > 100 &&
            this.eastPanel.width > this.mapViewService.width) {
            this.eastPanel.resize(Math.max(0, this.mapViewService.width));
        }
        if (this.southPanel && this.southPanel.height > this.mapViewService.height) {
            this.southPanel.resize(Math.max(0, this.mapViewService.height));
        }
        if (this.southPanel) {
            this.southPanel.innerWidth = this.mapViewService.width;
        }
        this.southPanel.innerWidth = this.mapViewService.width;
        this.eastPanel && (this.eastPanel.innerHeight = this.mapViewService.height -
            Math.max(0, this.mapViewService.mapPadding.panel.bottom));
        this.table.height = Math.max(0, this.mapViewService.mapPadding.panel.bottom - 10);
        this.resetMapPaddingByModal();
        this.mapService.padding.right = Math.max(0, this.mapViewService.mapPadding.panel.right, this.mapViewService.mapPadding.modal.right);
        this.mapService.padding.bottom = Math.max(0, this.mapViewService.mapPadding.panel.bottom, this.mapViewService.mapPadding.modal.bottom);
    }
}
