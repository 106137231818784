import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoDetailmuschelergebnis from "./interface";
import FischinfoMuschelart from "./../../fischinfo/muschelart/model";
import FischinfoMuscheluntersuchung from "./../../fischinfo/muscheluntersuchung/model";
@fn.DataModel
export default class FischinfoDetailmuschelergebnis extends DataModel implements IFischinfoDetailmuschelergebnis {
    constructor(obj?: object | IFischinfoDetailmuschelergebnis) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoDetailmuschelergebnis): FischinfoDetailmuschelergebnis {
        return new FischinfoDetailmuschelergebnis(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoDetailmuschelergebnis): FischinfoDetailmuschelergebnis {
        Object.assign(this, obj);
        obj && obj["fischinfoMuschelartByMuschelart_id"] && (this.fischinfoMuschelartByMuschelart_id = FischinfoMuschelart.deserialize(obj["fischinfoMuschelartByMuschelart_id"]));
        obj && obj["fischinfoMuscheluntersuchungByMuscheluntersuchung_id"] && (this.fischinfoMuscheluntersuchungByMuscheluntersuchung_id = FischinfoMuscheluntersuchung.deserialize(obj["fischinfoMuscheluntersuchungByMuscheluntersuchung_id"]));
        return this;
    }

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl", type: Number, databaseType: "integer", nullable: false, memberType: "" })
    public anzahl: number;

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "gewichtg", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public gewichtg: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "laengecm", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public laengecm: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "muschelart_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public muschelart_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "muscheluntersuchung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public muscheluntersuchung_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoMuschelart}
     */
    @fn.DataMember({ name: "fischinfoMuschelartByMuschelart_id", type: FischinfoMuschelart, nullable: true, memberType: "JOIN", joinProperty: "muschelart_id" })
    public fischinfoMuschelartByMuschelart_id?: FischinfoMuschelart;

    /**
     * @type {FischinfoMuscheluntersuchung}
     */
    @fn.DataMember({ name: "fischinfoMuscheluntersuchungByMuscheluntersuchung_id", type: FischinfoMuscheluntersuchung, nullable: true, memberType: "JOIN", joinProperty: "muscheluntersuchung_id" })
    public fischinfoMuscheluntersuchungByMuscheluntersuchung_id?: FischinfoMuscheluntersuchung;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
