<uw-tabs>
    <section class="uw-tab-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-15">
                    <label>
                        Kartenansicht drucken
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-15">
                    <uw-button
                        label="Karte als PDF"
                        (click)="exportImgFile()"
                    >Als PDF speichern</uw-button>
                </div>
            </div>
        </div>
    </section>
</uw-tabs>
