import { Component, EventEmitter, Input } from "@angular/core";
import AppService from "./../app/service";
import DataService from "./../data/service";
import MapService from "./../map/service";
import UserService from "../user/service";
/**
 * Dieser Komponente stellt ein Menü bereit, mit dem der Nutzer vor allem das Layout der
 * Nutzeroberfläche anpassen kann.
 *
 * Mit dieser Komponente kann der Nutzer desweiteren sich ausloggen, die Auswahlkriterien
 * zurücksetzen und die ausgewählten Daten als Datei speichern.
 */
@Component({
    selector: "custom-toolbar",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ToolBar {
    public toolControlBarViewToggle: boolean;
    public button: EventEmitter<any> = new EventEmitter();
    @Input()
    public zIndex: string;
    constructor(public appService: AppService, public dataService: DataService, public mapService: MapService, public userService: UserService) { }
    public get isExtendedView(): boolean {
        return this.userService.isLoggedIn === true;
    }
    public onClickToggleExtentViewButtonsButton(e: MouseEvent): boolean {
        this.toolControlBarViewToggle = !this.toolControlBarViewToggle;
        return true;
    }
    public onClickButton(e: MouseEvent, name: string, mode?: string): void {
        this.button.emit({
            name: name,
            mode: mode
        });
    }
}
