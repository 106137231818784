import { Component, Input, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import DataService from "../data/service";
import FischinfoNachweisbefischungergebnis from "../../models/fischinfo/nachweisbefischungergebnis/model";
import FischinfoNachweismuschelergebnis from "../../models/fischinfo/nachweismuschelergebnis/model";
import FischinfoNachweiskrebsergebnis from "../../models/fischinfo/nachweiskrebsergebnis/model";
/**
 * Diese Komponente stellt keine Eingabefelder für den Nutzer zur Verfügung und enthält nur einen Hinweis,
 * dass der Nutzer den Vorkommensnachweis für eine befischte Art ausgewählt hat.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-nachweis",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungErgebnisseArtErfassungNachweis {
    @Input()
    public modalId: string;
    @Input()
    public artId: string;
    @Input()
    public artGruppe: string;
    @Input()
    public nachweisErgebnis: FischinfoNachweisbefischungergebnis | FischinfoNachweismuschelergebnis | FischinfoNachweiskrebsergebnis;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
}
