import { Component, Input, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoUmland from "../../models/fischinfo/umland/model";
import FischinfoGewaesserzustandumland from "../../models/fischinfo/gewaesserzustandumland/model";
/**
 * Mit dieser Komponente kann der Nutzer die Elemente im Umland des befischten Gewässers ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt eine Menge von Elementen im Umland des befischten Gewässers dar.
 * Der Nutzer kann diese an- oder abwählen.
 */
@Component({
    selector: "form-fischinfo-untersuchung-gewaesser-sonstiges-zustandumland",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungGewaesserSonstigesZustandumland implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public _zustandumlandList: any[];
    public get zustandumlandList() {
        return this._zustandumlandList;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    ngOnInit() {
        this.refreshZustandumlandList();
    }
    public onClickZustandumland(e: any, zustandumland: FischinfoGewaesserzustandumland) {
        this.formFischinfoUntersuchungService.modelUtil
            .getOrDefault<FischinfoGewaesserzustandumland>(this.untersuchung, "FischinfoGewaesserzustandumland", zustandumland)
            .id > 0
            ? this.formFischinfoUntersuchungService.modelUtil
                .remove<FischinfoGewaesserzustandumland>(this.untersuchung, "FischinfoGewaesserzustandumland", zustandumland)
            : this.formFischinfoUntersuchungService.modelUtil
                .setOrAdd<FischinfoGewaesserzustandumland>(this.untersuchung, "FischinfoGewaesserzustandumland", new FischinfoGewaesserzustandumland({
                id: 1,
                umland_id: zustandumland.umland_id,
                gewaesserzustand_id: this.formFischinfoUntersuchungService.modelUtil
                    .getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                    .id
            }));
        this.refreshZustandumlandList();
    }
    public refreshZustandumlandList() {
        this.getNormalizedZustandumlandList()
            .then((list) => {
            this._zustandumlandList = list;
        });
    }
    public getNormalizedZustandumlandList(): Promise<any[]> {
        let a = this.dataService
            .getReferenceTable("umland", FischinfoUmland, "id");
        return Promise.all([a])
            .then(([umland]) => {
            const values = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                .fischinfoGewaesserzustandumlandListByForeignGewaesserzustand_id || [];
            let list = [];
            Object.keys(umland).forEach(key => {
                list.push({
                    id: (values.find(x => x.umland_id === umland[key].id) || { id: 0 }).id,
                    bezeichnung: umland[key].bezeichnung,
                    umland_id: umland[key].id
                });
            });
            return list
                .filter(x => !this.readonly || x.id > 0)
                .sort((a, b) => {
                a = a.umland_id;
                b = b.umland_id;
                return (a > b)
                    ? 1
                    : ((b > a)
                        ? -1
                        : 0);
            });
        });
    }
}
