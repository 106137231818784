import { UWFileService } from "./interface";
import { saveAs } from "file-saver";

export type BlobPart = ArrayBuffer | ArrayBufferView | Blob | string;
export type BlobSource = BlobPart | Array<BlobPart>;
export type BlobParts = Array<BlobPart>;

const asArray = (o: BlobSource): BlobParts => Array.isArray(o) ? o : [o];

const newBlob = (parts: BlobParts, mimeType: string) => new Blob(parts, { type: mimeType });
const sanitizeBuffer = (o: BlobPart) => o instanceof ArrayBuffer ? (new Uint8Array(o)).buffer : o;
const createBlob = (mimeType: string) => (parts: BlobParts) => {
    let blob: Blob;
    try {
        blob = newBlob(parts, mimeType);
    } catch (e) {
        /* Old browser which can't handle it without making it an byte array (ie10) */
        if (typeof e === "object" && e instanceof Error && e.name == "InvalidStateError") {
            blob = newBlob(parts.map(sanitizeBuffer), mimeType);
        }
        else {
            throw e;
        }
    }
    return blob;
};

export default abstract class UWAbstractFileService<TViewModel> implements UWFileService<TViewModel> {
    protected abstract transformModelToBlobSource(model: TViewModel): PromiseLike<BlobSource> | BlobSource;
    protected abstract readonly mimeType: string;
    protected createBlobParts(model: TViewModel): Promise<BlobParts> {
        try {
            return Promise.resolve(
                this.transformModelToBlobSource(model)
            ).then(asArray);
        }
        catch (e) {
            return Promise.reject(e);
        }
    }

    public createBlob(model: TViewModel): Promise<Blob> {
        return this.createBlobParts(model).then(createBlob(this.mimeType));
    }

    public saveAs(model: TViewModel, fileName: string) {
        return this.createBlob(model).then(blob =>
            new Promise<void>((resolve, reject) =>
                Object.assign(
                    saveAs(blob, fileName),
                    { onwriteend: resolve, onerror: reject }
                )
            )
        );
    }
}
