<div class="row">
    <div class="col-xs-15 col-sm-7dot5">
        <div class="row">
            <div class="col-xs-15">
                <label>NWB – Qualitätsmerkmale und Parameter</label>
            </div>
        </div>
        <!--Arten- & Gildeninventar-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoreartenundgildeninventargesamt')"
            ></div>
            <div
                class="col-xs-5 text-left wb-values-eq"
                [textContent]="novalueOrTruncatedNumber(nwbfibsresult.scoreartenundgildeninventargesamt)"
            ></div>
        </div>
        <!--Artenabundanz & Gildenverteilung-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoreartenabundanzundgildenverteilung')"
            ></div>
            <div
                class="col-xs-5 text-left wb-values-eq"
                [textContent]="novalueOrTruncatedNumber(nwbfibsresult.scoreartenabundanzundgildenverteilung)"
            ></div>
        </div>
        <!--Altersstruktur (Reproduktion)-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorealtersstruktur')"
            ></div>
            <div
                class="col-xs-5 text-left wb-values-eq"
                [textContent]="novalueOrTruncatedNumber(nwbfibsresult.scorealtersstruktur)"
            ></div>
        </div>
        <!--Migration-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoremigrationsindex')"
            ></div>
            <div class="col-xs-5 text-left wb-values-eq">
                <span class="result-score"
                    [textContent]="novalueOrTruncatedNumber(nwbfibsresult.scoremigrationsindex)"
                ></span>
                (<span class="result-ref"
                    [textContent]="novalueOrTruncatedNumber(nwbfibsresult.refmigrationsindexohneaal)"
                ></span>)
            </div>
        </div>
        <!--Fischregion-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorefischregionsindex')"
            ></div>
            <div
                class="col-xs-5 text-left wb-values-eq"
            >
                <span class="result-score"
                    [textContent]="novalueOrTruncatedNumber(nwbfibsresult.scorefischregionsindex)"
                ></span>
                (<span class="result-ref"
                    [textContent]="novalueOrTruncatedNumber(nwbfibsresult.reffischregionsgesamtindex)"
                ></span>)
            </div>
        </div>
        <!--Dominante Arten-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoredominantearten')"
            ></div>
            <div
                class="col-xs-5 text-left wb-values-eq"
                [textContent]="novalueOrTruncatedNumber(nwbfibsresult.scoredominantearten)"
            ></div>
        </div>
        <!--Gesamtbewertung-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('gesamtbewertung')"
            ></div>
            <div class="col-xs-5 text-left wb-values-eq">
                <b [textContent]="novalueOrTruncatedNumber(nwbfibsresult.gesamtbewertung)"></b>
            </div>
        </div>
        <!--Ecological Quality Ratio-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('ecologicalqualityratio')"
            ></div>
            <div
                class="col-xs-5 text-left wb-values-eq"
                [textContent]="novalueOrTruncatedNumber(nwbfibsresult.ecologicalqualityratio)"
            ></div>
        </div>
        <!--Ökologischer Zustand-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('zustandsklasse')"
            ></div>
            <div class="col-xs-5 text-left wb-values-eq">
                <b [textContent]="novalueOr(zustand)"></b>
            </div>
        </div>
    </div>

    <div class="col-xs-15 col-sm-7dot5">
        <div class="row">
            <div class="col-xs-15">
                <label>HMWB – Qualitätsmerkmale und Parameter</label>
            </div>
        </div>
        <!--Arten- & Gildeninventar-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoreartenundgildeninventargesamt')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOrTruncatedNumber(hmwbfibsresult.scoreartenundgildeninventargesamt)"
                [ngClass]="cssEqualClasses('scoreartenundgildeninventargesamt')"
            ></div>
        </div>
        <!--Artenabundanz & Gildenverteilung-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoreartenabundanzundgildenverteilung')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOrTruncatedNumber(hmwbfibsresult.scoreartenabundanzundgildenverteilung)"
                [ngClass]="cssEqualClasses('scoreartenabundanzundgildenverteilung')"
            ></div>
        </div>
        <!--Altersstruktur (Reproduktion)-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorealtersstruktur')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOrTruncatedNumber(hmwbfibsresult.scorealtersstruktur)"
                [ngClass]="cssEqualClasses('scorealtersstruktur')"
            ></div>
        </div>
        <!--Migration-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoremigrationsindex')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [ngClass]="cssEqualClasses('scoremigrationsindex')"
            >
                <span class="result-score"
                    [textContent]="novalueOrTruncatedNumber(hmwbfibsresult.scoremigrationsindex)"
                ></span>
                (<span class="result-ref"
                    [textContent]="novalueOrTruncatedNumber(hmwbfibsresult.refmigrationsindexohneaal)"
                ></span>)
            </div>
        </div>
        <!--Fischregion-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorefischregionsindex')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [ngClass]="cssEqualClasses('scorefischregionsindex')"
            >
                <span class="result-score"
                    [textContent]="novalueOrTruncatedNumber(hmwbfibsresult.scorefischregionsindex)"
                ></span>
                (<span class="result-ref"
                    [textContent]="novalueOrTruncatedNumber(hmwbfibsresult.reffischregionsgesamtindex)"
                ></span>)
            </div>
        </div>
        <!--Dominante Arten-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoredominantearten')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOrTruncatedNumber(hmwbfibsresult.scoredominantearten)"
                [ngClass]="cssEqualClasses('scoredominantearten')"
            ></div>
        </div>
        <!--Gesamtbewertung-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('gesamtbewertung')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [ngClass]="cssEqualClasses('gesamtbewertung')"
            >
                <b [textContent]="novalueOrTruncatedNumber(hmwbfibsresult.gesamtbewertung)"></b>
            </div>
        </div>
        <!--Ecological Quality Ratio-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('ecologicalqualityratio')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOrTruncatedNumber(hmwbfibsresult.ecologicalqualityratio)"
                [ngClass]="cssEqualClasses('ecologicalqualityratio')"
            ></div>
        </div>
        <!--Ökologisches Potenzial-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('potenzial')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [ngClass]="cssEqualClasses('zustandsklasse')"
            >
                <b [textContent]="novalueOr(potenzial)"></b>
            </div>
        </div>
    </div>
</div>
<div class="row" style="margin-top:14px;">
    <div class="col-xs-15">
        <div class="row">
            <div class="col-xs-5 text-right">Fischgewässertyp</div>
            <div class="col-xs-10" [textContent]="novalueOr(probenahmestelle.fischgewaessertypName || null)"></div>
        </div>
        <div class="row">
            <div class="col-xs-5 text-right">Fisch-HÖP</div>
            <div class="col-xs-10" [textContent]="probenahmestelle.fischhoep || 'k.A.'"></div>
        </div>
        <div class="row">
            <div class="col-xs-5 text-right">Individuensumme</div>
            <div class="col-xs-10" [textContent]="individuensumme"></div>
        </div>
    </div>
</div>
