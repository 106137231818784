import { Component, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import UserService from "../user/service";
/**
 * Über dieses Formular kann der Nutzer einen Teil der Informationen einer Untersuchung einsehen.
 * Dieses Formular bietet nur einen Ausschnitt der Informationen einer Untersuchung an:
 * Datum, Befischung/Beobachtung, Methode, Befischte Länge und Untersuchungsergebnisse.
 */
@Component({
    selector: "form-fischinfo-untersuchung",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchung {
    @Input()
    public modalId: string;
    public _untersuchung: FischinfoUntersuchung;
    public get untersuchung(): FischinfoUntersuchung {
        return this._untersuchung;
    }
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    @Input()
    public set untersuchung(item: FischinfoUntersuchung) {
        this._untersuchung = item;
        this.formFischinfoUntersuchungService
            .viewUtil
            .getNormalizedBefischungsergebnisseList(this.untersuchung)
            .then(list => {
            this.untersuchungsergebnisList = list
                .filter(x => x.gruppe === "Fische");
        });
    }
    public readonly skalaKeys: string[] = [];
    public untersuchungsergebnisList: any = [];
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService, public userService: UserService) {
    }
}
