import { Injectable } from "@angular/core";

import SuggestionService from "./service.interface";
import SuggestionServiceOptions from "./options.interface";
import KeyValue from "./key-value.interface";

@Injectable()
export default class FixedSuggestionService implements SuggestionService {
    protected __limit: number = 10;
    protected __jsonItems: Array<KeyValue> = [];
    protected __matcherFor: (pattern: string) => (suggestion: KeyValue) => boolean = (pattern: string) => (suggestion: KeyValue) => true;

    constructor() {
    }

    public config(options: SuggestionServiceOptions): SuggestionService {
        this.__limit = options.limit === null ? this.__limit : options.limit;
        this.__jsonItems = options.jsonItems === null ? this.__jsonItems : options.jsonItems;
        this.__matcherFor = options.matcherFactory === null ? this.__matcherFor : options.matcherFactory;

        return <SuggestionService>this;
    }

    public search(pattern: { [name: string]: string }): Promise<any[]> {
        return Promise.resolve(this.__jsonItems.filter(this.__matcherFor(pattern["term"])).slice(0, this.__limit));
    }
}
