import { Injectable, EventEmitter } from "@angular/core";
import UserService from "./../user/service";
import DataService from "./../data/service";

/**
 * Dieser Dienst konvertiert eine Kartenansicht in ein herunterladbares PDF-Dokument.
 */
@Injectable({
    providedIn: 'root'
})
export default class PrintService {
    constructor(
        protected dataService: DataService,
    ) {
        if (typeof String.prototype.trim !== 'function') {
            String.prototype.trim = function () { return this.replace(/^\s+|\s+$/g, ''); };
        }
        if (typeof window["str_replace"] !== 'function') {
            window["str_replace"] = function (search, replace, subject) {
                return subject.toString().split(search).join(replace);
            };
        }
        Array.prototype.reduce || (
            Array.prototype.reduce = function (d) {
                if (null == this) throw new TypeError("Array.prototype.reduce called on null or undefined"); if ("function" !== typeof d) throw new TypeError(d + " is not a function"); let b = Object(this), e = b.length >>> 0, a = 0, c; if (2 == arguments.length) c = arguments[1]; else { for (; a < e && !(a in b);)a++; if (a >= e) throw new TypeError("Reduce of empty array with no initial value"); c = b[a++] } for (; a < e; a++)a in b && (c = d(c, b[a], a, b)); return c;
            }
        );
        try {
            window["isFileSaverSupported"] = !!new Blob;
        } catch (e) { }
    }


    public printMapPDF(canvas: HTMLCanvasElement) {
        return this.pdfLibReadyPromise
            .then(async resp => {
                await this.makeMapPDF(canvas);
            })
            ;
    }

    protected pdfLibReadyPromiseFunc: any;
    protected get pdfLibReadyPromise(): Promise<boolean> {
        return new Promise((resolve, fail) => {
            this.pdfLibReadyPromiseFunc = (i: number) => {
                if (window["jQuery"] || window["jquery"]) {
                    resolve(true);
                } else {
                    i < 100 && window.setTimeout(() => { this.pdfLibReadyPromiseFunc(i ? ++i : 1); }, 200);
                }
            };
            this.pdfLibReadyPromiseFunc();
        });
    }

    public makeMapPDF(canvas: HTMLCanvasElement) {
        /* Essential value definitions */
        const mapImgUrl = canvas.toDataURL();

        /* Helper functions */
        const isSomething = (o) => o != null;
        const btos = (bool: boolean) => bool ? "Ja" : "Nein";
        const nbtos = (boolOrNull: boolean | null) => boolOrNull == null ? "" : btos(boolOrNull);
        const dtos = (date: string) => date.split("-").reverse().join(".");
        const ndtos = (dateOrNull: string | null) => dateOrNull == null ? "" : dtos(dateOrNull);
        const nullUnless = x => Boolean(x) || null;
        const mergeParts = (...args) => [].concat(...args).filter(isSomething);

        /* Style definitions */
        const styles = {
            table: { fontSize: 7 }
            , hint: { fontSize: 7 }
            , base: { fontSize: 11, margin: [0, 30, 0, 0] }
            , header: {
                margin: [30, 20, 30, 0]
                , fontSize: 9
                , color: "#444"
                , bold: true
            }
            , footer: { margin: [30, 0, 30, 20], fontSize: 9 }
            , tableHead: { bold: true }
            , title: {
                fontSize: 18
                , bold: true
                , margin: [0, 15, 0, 7]
            }
            , sectionHead: {
                fontSize: 14
                , bold: true
                , margin: [0, 15, 0, 7]
            }
            , subsectionHead: {
                fontSize: 11
                , bold: true
                , margin: [0, 15, 0, 7]
            }
            , subsubsectionHead: {
                fontSize: 11
                , bold: true
                , italics: true
                , margin: [0, 11, 0, 5]
            }
        };

        /* Sub-structure generators */
        type PDFText = { margin?: number[], text: string, [K: string]: any };
        const spaceWhenEmpty = (text: string | PDFText, space: number) => {
            if (text == null || typeof text !== "object") {
                text = {
                    margin: null,
                    text: <string>text,
                };
            }
            if (text.margin == null) {
                text.margin = [0, 0, 0, 0];
            }
            text.margin[3] = text.text ? 0 : space;

            return text;
        };

        /* content definition */
        const content = {
            image: mapImgUrl,
            fit: [760, 470],
            style: "base",
        };

        /* document definition */
        let dd = {
            content, styles

            , pageSize: "A4"
            , pageOrientation: "landscape"

            , background: {
                image: "logo"
                , fit: [200, 400]
                , alignment: "right"
                , margin: [0, 20, 10, 0]
            }

            , header(page, count) {
                let h = {
                    columns: [
                        {
                            text: "Kartenausdruck"
                            , alignment: "left"
                            , width: "*"
                        }
                        ,
                        {
                            text: [page, count].join(" von ")
                            , alignment: "center"
                            , width: 90
                        }
                        ,
                        {
                            text: " "
                            , alignment: "right"
                            , width: "*"
                        }
                    ]
                    , style: "header"
                }
                return h
            }

            , footer(page, count) {
                let f = {
                    columns: [
                        {
                            text: "\nAusdruck vom " + (function (d) {
                                return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
                                    .join(".")
                            })(new Date()) +
                                "; " +
                                "Fischinformationssystem in Nordrhein-Westfalen – LANUV NRW " +
                                new Date()
                                    .getFullYear()
                            , alignment: "left"
                            , width: "*"
                        }
                        ,
                        {
                            text: "\nSeite " + [page, count].join(" von ")
                            , alignment: "right"
                            , width: 90
                        }
                    ]
                    , style: "footer"
                }
                return f
            }
            , images: {
                logo: window["lanuv_logo_b64"]
            }
        };

        /* ---- */
        return new Promise<void>((resolve, reject) => {
            window["pdfMake"]
                .createPdf(dd)
                .getBuffer((outDoc) => {
                    let blob;
                    try {
                        blob = new Blob([outDoc], { type: 'application/pdf' });
                    } catch (e) {
                        /* Old browser which can't handle it without making it an byte array (ie10) */
                        if (e instanceof Error && e.name == "InvalidStateError") {
                            let byteArray = new Uint8Array(outDoc);
                            blob = new Blob([byteArray.buffer], { type: 'application/pdf' });
                        }
                    }
                    if (blob) {
                        window["saveAs"](blob, 'ausdruck.pdf');
                    } else {
                        window["saveTextAs"](outDoc, "ausdruck.pdf");
                    }
                    resolve();
                })
                ;
        });
    }
}
