<div class="row">
    <div class="col-xs-15 col-sm-7dot5">
        <div class="row">
            <div class="col-xs-15">
                <label>Qualitätsmerkmale und Parameter</label>
            </div>
        </div>
        <!--Arten- & Gildeninventar-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoreartenundgildeninventargesamt')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOrTruncatedNumber(fibsresult.scoreartenundgildeninventargesamt)"
            ></div>
        </div>
        <!--Artenabundanz & Gildenverteilung-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoreartenabundanzundgildenverteilung')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOrTruncatedNumber(fibsresult.scoreartenabundanzundgildenverteilung)"
            ></div>
        </div>
        <!--Altersstruktur (Reproduktion)-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorealtersstruktur')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOrTruncatedNumber(fibsresult.scorealtersstruktur)"
            ></div>
        </div>
        <!--Migration-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoremigrationsindex')"
            ></div>
            <div class="col-xs-5 text-left">
                <span class="result-score"
                    [textContent]="novalueOrTruncatedNumber(fibsresult.scoremigrationsindex)"
                ></span>
                (<span class="result-ref"
                    [textContent]="novalueOrTruncatedNumber(fibsresult.refmigrationsindexohneaal)"
                ></span>)
            </div>
        </div>
        <!--Fischregion-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorefischregionsindex')"
            ></div>
            <div
                class="col-xs-5 text-left"
            >
                <span class="result-score"
                    [textContent]="novalueOrTruncatedNumber(fibsresult.scorefischregionsindex)"
                ></span>
                (<span class="result-ref"
                    [textContent]="novalueOrTruncatedNumber(fibsresult.reffischregionsgesamtindex)"
                ></span>)
            </div>
        </div>
        <!--Dominante Arten-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoredominantearten')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOrTruncatedNumber(fibsresult.scoredominantearten)"
            ></div>
        </div>
        <!--Gesamtbewertung-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('gesamtbewertung')"
            ></div>
            <div class="col-xs-5 text-left">
                <b [textContent]="novalueOrTruncatedNumber(fibsresult.gesamtbewertung)"></b>
            </div>
        </div>
        <!--Ecological Quality Ratio-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('ecologicalqualityratio')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOrTruncatedNumber(fibsresult.ecologicalqualityratio)"
            ></div>
        </div>
        <!--Ökologisches Potenzial-->
        <div class="row" style="margin-bottom:7px;">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('potenzial')"
            ></div>
            <div class="col-xs-5 text-left">
                <b [textContent]="novalueOr(potenzial)"></b>
            </div>
        </div>
        <!--plausibilisierte Zustandklasse-->
        <div class="row space-right" *ngIf="fibsCalculatable">
            <div class="col-xs-15 overflow">
                <label>Berechnen Sie eine aktuelle FIBS-Bewertung</label>
            </div>
        </div>
        <div class="row space-right" *ngIf="fibsCalculatable">
            <div class="col-xs-15">
                <uw-inline-button
                    iconClass="fa fa-calculator"
                    value="FIBS-Bewertung {{fibsVersion | async}} berechnen"
                    title="FIBS-Bewertung {{fibsVersion | async}} berechnen oder aktualisieren"
                    (click)="calculateFibsResult()"
                    class="inline-btn-nobrk"
                ></uw-inline-button>
            </div>
        </div>
        <div class="row space-right" *ngIf="fibsCalculatable">
            <div class="col-xs-15 overflow">
                <label>Bei vorheriger Editierung der Befischung speichern Sie diese zunächst. Die FIBS-Bewertung erfolgt anhand der aktuell gespeicherten Version.</label>
            </div>
        </div>
        <div class="row space-right">
            <div class="col-xs-15 overflow">
                <label>FIBS-Bewertung (HMWB) als *.fibs-Datei speichern</label>
            </div>
        </div>
        <div class="row space-right">
            <div class="col-xs-15">
                <uw-inline-button
                    iconClass="fa fa-table"
                    value="*.fibs speichern"
                    title="*.fibs speichern"
                    (click)="downloadFibsResult()"
                    class="inline-btn-nobrk"
                ></uw-inline-button>
            </div>
        </div>
        <div class="row space-right">
            <div class="col-xs-15">
                <label
                    [textContent]="label('zustandsklasse_expert')"
                ></label>
            </div>
            <div class="col-xs-15">
                <uw-select
                    [(key)]="zustandsklasse_expert"
                    [jsonItems]="zustandsklasseListJSON"
                    keyValueMapping="id:term"
                    nullable="true"
                    [disabled]="readonly"
                ></uw-select>
            </div>
        </div>
        <div class="row space-right" *ngIf="zustandsklasse_expert != null">
            <div class="col-xs-15">
                <label
                    [textContent]="label('datum_zustandsklasse_expert')"
                ></label>
            </div>
            <div class="col-xs-15">
                <uw-textinput
                    [value]="datum_zustandsklasse_expert?.toLocaleString()"
                    [disabled]="true"
                ></uw-textinput>
            </div>
        </div>
        <div class="row space-right" *ngIf="zustandsklasse_expert != null">
            <div class="col-xs-15">
                <label
                    [textContent]="label('begruendung_zustandsklasse_expert')"
                ></label>
            </div>
            <div class="col-xs-15">
                <uw-textarea
                    [(value)]="begruendung_zustandsklasse_expert"
                    [disabled]="readonly"
                    [updateHeight]="inActiveTab"
                ></uw-textarea>
            </div>
        </div>
    </div>

    <div class="col-xs-15 col-sm-7dot5">
        <div class="row">
            <div class="col-xs-15">
                <label>Arten- und Gildeninventar</label>
            </div>
        </div>
        <!--Typspezifische Arten-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoretypspezifischearten')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scoretypspezifischearten)"
            ></div>
        </div>
        <!--Anzahl Begleitarten-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorebegleitarten')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorebegleitarten)"
            ></div>
        </div>
        <!--Anzahl anadromer und potamodromer Arten-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoreanapotamodromearten')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scoreanapotamodromearten)"
            ></div>
        </div>
        <!--Anzahl referenzferner Arten-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorereferenzfernearten')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorereferenzfernearten)"
            ></div>
        </div>
        <!--Anzahl Habitatgilden >= 1%-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorehabitatgildengreossergleich1prozentrefanteil')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorehabitatgildengreossergleich1prozentrefanteil)"
            ></div>
        </div>
        <!--Anzahl referenzferner Habitatgilden-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorehabitatgildenreferenzfern')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorehabitatgildenreferenzfern)"
            ></div>
        </div>
        <!--Anzahl Reproduktionsgilden >= 1%-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorereproduktionsgildengreossergleich1prozentrefanteil')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorereproduktionsgildengreossergleich1prozentrefanteil)"
            ></div>
        </div>
        <!--Anzahl referenzferner Reproduktionsgilden-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorereproduktionsgildenreferenzfern')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorereproduktionsgildenreferenzfern)"
            ></div>
        </div>
        <!--Anzahl Trophiegilden >= 1%-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoretrophiegildengreossergleich1prozentrefanteil')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scoretrophiegildengreossergleich1prozentrefanteil)"
            ></div>
        </div>
        <!--Anzahl referenzferner Trophiegilden-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoretrophiegildenreferenzfern')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scoretrophiegildenreferenzfern)"
            ></div>
        </div>
    
        <!--Barsch-/Rotaugen-Abundanz-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorebarschundrotaugenabundanz')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorebarschundrotaugenabundanz)"
            ></div>
        </div>
        <!--Rheophile-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorehabitatgildenrheophile')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorehabitatgildenrheophile)"
            ></div>
        </div>
        <!--Stagnophile-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorehabitatgildenstagnophile')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorehabitatgildenstagnophile)"
            ></div>
        </div>
        <!--Lithophile-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorereproduktionsgildenlithophile')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorereproduktionsgildenlithophile)"
            ></div>
        </div>
        <!--Psammophile-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorereproduktionsgildenpsammophile')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorereproduktionsgildenpsammophile)"
            ></div>
        </div>
        <!--Phytophile-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorereproduktionsgildenphytophile')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorereproduktionsgildenphytophile)"
            ></div>
        </div>
        <!--Invertivore-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoretrophiegildeninvertivore')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scoretrophiegildeninvertivore)"
            ></div>
        </div>
        <!--Omnivore-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoretrophiegildenomnivore')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scoretrophiegildenomnivore)"
            ></div>
        </div>
        <!--Piscivore-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoretrophiegildenpiscivore')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scoretrophiegildenpiscivore)"
            ></div>
        </div>
        <!--Leitartenindex-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scoreleitartenindex')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scoreleitartenindex)"
            ></div>
        </div>
        <!--CDI-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('scorecdi')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.scorecdi)"
            ></div>
        </div>
        <!--Warnung diadrom-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('warnungandrompotamodrom')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.warnungandrompotamodrom)"
            ></div>
        </div>
        <!--Warnung Individuen-->
        <div class="row">
            <div
                class="col-xs-10 text-right"
                [textContent]="label('warnungindividuen')"
            ></div>
            <div
                class="col-xs-5 text-left"
                [textContent]="novalueOr(fibsresult.warnungindividuen)"
            ></div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-7dot5">
        <div class="row">
            <div class="col-xs-15">
                <label>0+ Anteile der Leitarten</label>
            </div>
            <div class="col-xs-15">
                <table class="table table-striped leitarten">
                    <colgroup>
                        <col style="width:20%"/>
                        <col/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Score</th>
                            <th>Art</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="nullplusanteileList?.length">
                        <tr *ngFor="let a of nullplusanteileList">
                            <td [textContent]="a.score"></td>
                            <td [textContent]="a.artname"></td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!nullplusanteileList?.length">
                        <tr>
                            <td colspan="2">Keine 0+ Anteile vorhanden</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="col-xs-15 col-sm-7dot5">
        <div class="row">
            <div class="col-xs-15">
                <label>Abundanz der Leitarten</label>
            </div>
            <div class="col-xs-15">
                <table class="table table-striped leitarten">
                    <colgroup>
                        <col style="width:20%"/>
                        <col/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Score</th>
                            <th>Art</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="artenabundanzList?.length">
                        <tr *ngFor="let a of artenabundanzList">
                            <td [textContent]="a.score"></td>
                            <td [textContent]="a.artname"></td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!artenabundanzList?.length">
                        <tr>
                            <td colspan="2">Keine Abundanzen vorhanden</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
