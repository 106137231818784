import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoWasserstand from "./interface";
import FischinfoBesatzereignis from "./../../fischinfo/besatzereignis/model";
import FischinfoGewaesserzustand from "./../../fischinfo/gewaesserzustand/model";
@fn.DataModel
export default class FischinfoWasserstand extends DataModel implements IFischinfoWasserstand {
    constructor(obj?: object | IFischinfoWasserstand) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoWasserstand): FischinfoWasserstand {
        return new FischinfoWasserstand(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoWasserstand): FischinfoWasserstand {
        Object.assign(this, obj);
        obj && obj["fischinfoBesatzereignisListByForeignWasserstand_id"] && obj["fischinfoBesatzereignisListByForeignWasserstand_id"].length && (this.fischinfoBesatzereignisListByForeignWasserstand_id = obj["fischinfoBesatzereignisListByForeignWasserstand_id"].map(FischinfoBesatzereignis.deserialize));
        obj && obj["fischinfoGewaesserzustandListByForeignWasserstand_id"] && obj["fischinfoGewaesserzustandListByForeignWasserstand_id"].length && (this.fischinfoGewaesserzustandListByForeignWasserstand_id = obj["fischinfoGewaesserzustandListByForeignWasserstand_id"].map(FischinfoGewaesserzustand.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoBesatzereignis>}
     */
    @fn.DataMember({ name: "fischinfoBesatzereignisListByForeignWasserstand_id", type: [FischinfoBesatzereignis], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzereignisListByForeignWasserstand_id?: Array<FischinfoBesatzereignis>;

    /**
     * @type {Array<FischinfoGewaesserzustand>}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandListByForeignWasserstand_id", type: [FischinfoGewaesserzustand], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoGewaesserzustandListByForeignWasserstand_id?: Array<FischinfoGewaesserzustand>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
