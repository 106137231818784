import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoDetailbefischungergebnis from "../../models/fischinfo/detailbefischungergebnis/model";
import FischinfoLaengenklassenbefischungergebnis from "../../models/fischinfo/laengenklassenbefischungergebnis/model";

//#region validation utility
const isNullish = (x: any): x is null => x == null;

const handleNullish = <T>(pred: (x: T) => boolean) =>
    (x: null | T, allowNullish: boolean = false) =>
        isNullish(x) ? !allowNullish : pred(x)
    ;
const isEmpty = handleNullish((x: { length: number }) => x.length === 0);
const isZero = handleNullish((x: number) => x === 0.0);

type JungfischIndex = {
    [art_id: number]: { hasJungfisch: boolean, hasNonJungfisch: boolean }
};
const indexByJungfisch = (idx: JungfischIndex, ergeb: FischinfoDetailbefischungergebnis) => {
    const entry = idx[ergeb.art_id] || (idx[ergeb.art_id] = { hasJungfisch: false, hasNonJungfisch: false })
    if (ergeb.jungfisch === true) {
        entry.hasJungfisch = entry.hasJungfisch || !(isNullish(ergeb.anzahl) || Number.isNaN(ergeb.anzahl))
    }
    else {
        entry.hasNonJungfisch = true
    }
    return idx;
};
const getMissingJungfisch = (idx: JungfischIndex) =>
    Object.keys(idx).map<JungfischIndex[number]>(k => idx[k]).some(v => v.hasNonJungfisch && !v.hasJungfisch)
    ;
const hasMissingJungfisch = (a: FischinfoDetailbefischungergebnis[]) =>
    getMissingJungfisch(a.reduce(indexByJungfisch, {} as JungfischIndex))
    ;
//#endregion

export default class Validation {
    constructor(
    ) {
    }

    // Datenvalidierung
    public validate(untersuchung: FischinfoUntersuchung): ValidationFail {
        const validationResult = Array<{ validationText: string, warningText: string }>();
        const revisedUntersuchung: FischinfoUntersuchung = this.revise(untersuchung);
        let pflichtTested = 0, pflichtFailed = 0;

        // #region Netz (Höhe und Länge)
        if (
            revisedUntersuchung
            && revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id
            && revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
            && revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoMethodeByMethode_id
            && revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoMethodeByMethode_id.fischinfoNetzListByForeignMethode_id
            && revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoMethodeByMethode_id.fischinfoNetzListByForeignMethode_id.length
            && (
                pflichtTested += 1,
                revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoMethodeByMethode_id.fischinfoNetzListByForeignMethode_id.filter(x =>
                    x.hoehe === null || x.hoehe === undefined
                    || x.laenge === null || x.laenge === undefined
                ).length
            )
        ) {
            pflichtFailed += 1;
            validationResult.push({
                validationText: "Fehlende Angabe der Höhe oder Länge für eingetragene Netze unter \"Befischung > Methode\".",
                warningText: "Geben Sie eine Höhe und Länge für jedes eingetragene Netz unter \"Befischung > Methode\" ein oder entfernen Sie das eingetragene Befischungsgerät."
            })
        }
        // #endregion

        //#region Issue #53: Angabe eines Bearbeiter muss erfolgen
        pflichtTested += 1;
        if (revisedUntersuchung && isEmpty(revisedUntersuchung.bearbeiter)) {
            pflichtFailed += 1;
            validationResult.push({
                validationText: "Fehlende Angabe eines Bearbeiters für Befischung unter \"Befischung > Allgemein\".",
                warningText: "Geben Sie einen Bearbeiter unter \"Befischung > Allgemein\".",
            });
        }
        //#endregion

        if (
            revisedUntersuchung
            && revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id
            && revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id.length
        ) {
            //#region Issue 53: Datum muss eingetragen sein - es soll kein Datum vorausgefüllt sein (aktuell wird das aktuelle Datum eingetragen)
            pflichtTested += 1;
            if (
                isNullish(revisedUntersuchung.untersuchungstermin)
                || !Number.isInteger(revisedUntersuchung.untersuchungstermin)
                || !(new Date(revisedUntersuchung.untersuchungstermin))
                || isZero(revisedUntersuchung.untersuchungstermin - (revisedUntersuchung.untersuchungstermin % 86400000))
            ) {
                pflichtFailed += 1;
                validationResult.push({
                    validationText: "Fehlende oder ungültige Angabe des Untersuchungsdatums unter \"Befischung > Allgemein\".",
                    warningText: "Tragen Sie ein Datum der Form \"TT.MM.JJJJ\" unter \"Befischung > Allgemein\" ein.",
                });
            }
            //#endregion

            //#region Issue #53: entweder 'Trocken gefallen' oder 'keine Fische' oder mindestens eine Art muss eingetragen sein (sieh auch Plausiprüfung unten)
            // Ergänzung Issue #100: Bei Befischungen [nicht Beobachtungen] bleibt die bisherige Regel bestehen 
            const befischList = revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id.filter(befisch => !befisch.beobachtung)
            if (befischList.length > 0) {
                pflichtTested += 1;
                if (
                    befischList.some(befisch => (
                        !befisch.trockengefallen && !befisch.keinefische
                        && isEmpty(befisch.fischinfoDetailbefischungergebnisListByForeignBefischung_id)
                        && isEmpty(befisch.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id)
                        && isEmpty(befisch.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id)
                        && isEmpty(befisch.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id)
                        && isEmpty(befisch.fischinfoNachweisbefischungergebnisListByForeignBefischung_id)
                    ))
                ) {
                    pflichtFailed += 1;
                    validationResult.push({
                        validationText: "Fehlende Angabe einer gefundenen Fischart wenn weder \"Keine Fische\" noch \"Trocken gefallen\" unter \"Befischung > Fische\" ausgewählt sind.",
                        warningText: "Tragen Sie eine Fischart ein oder wählen Sie das Feld \"Keine Fische\" oder \"Trocken gefallen\" unter \"Befischung > Fische\" aus.",
                    });
                }
            }
            //#endregion

            //#region Issue #53: Wenn nicht 'Trocken gefallen' und wenn unter Befischung -> Methode -> Auswahl E-Gerät und Streckenbefischung erfolgt ist -> befischte Länge und befischte Breite muss eingetragen sein
            const notTrockenEGeraetStreckeBefisch = revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id.filter(befisch => (
                !befisch.trockengefallen
                && befisch.fischinfoMethodeByMethode_id
                && befisch.fischinfoMethodeByMethode_id.befischungsgeraet_id === 1
                && befisch.fischinfoMethodeByMethode_id.elektrobefischungsmethode_id === 1
            ));
            pflichtTested += Math.sign(Math.abs(notTrockenEGeraetStreckeBefisch.length));
            if (
                notTrockenEGeraetStreckeBefisch.some(befisch => (
                    isZero(befisch.fischinfoMethodeByMethode_id.befischtebreite)
                    || Number.isNaN(befisch.fischinfoMethodeByMethode_id.befischtebreite)
                    || isZero(befisch.fischinfoMethodeByMethode_id.befischtelaenge)
                    || Number.isNaN(befisch.fischinfoMethodeByMethode_id.befischtelaenge)
                ))
            ) {
                pflichtFailed += 1;
                validationResult.push({
                    validationText: "Fehlende Angabe einer befischten Länge oder Breite unter \"Befischung > Methode\".",
                    warningText: "Geben Sie  eine befischte Länge und befischte Breite unter \"Befischung > Methode\" an"
                        + " oder wählen Sie eine andere E-Befischungsmethode oder ein anderes Befischungsgerät.",
                });
            }
            //#endregion

            //#region Issue #53: wenn WRRL-Monitoring ausgewählt ist ...
            if (revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id.some(befisch => befisch.wrrlmonitoring === true)) {
                //#region Issue #53: ... Angabe eines Schlüsselwortes muss erfolgen
                pflichtTested += 1;
                if (
                    isEmpty(revisedUntersuchung.schluesselwort)
                ) {
                    pflichtFailed += 1;
                    validationResult.push({
                        validationText: "Fehlende Angabe eines Schlüsselwortes für Befischung mit WRRL-Monitoring unter \"Befischung > Allgemein\".",
                        warningText: "Geben Sie ein Schlüsselwort unter \"Befischung > Allgemein\" oder deselektieren Sie WRRL-Monitoring.",
                    });
                }
                //#endregion

                //#region Issue #53: ... Bei einer Angabe von Fischen (Bedingung: Auswahl Individuen oder Längenklassen) muss ein Eintrag im Feld Jungfische erfolgen
                const ergebIdx = revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id.reduce((idx, befisch) => {
                    if (
                        !isEmpty(befisch.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id)
                        && Array.isArray(befisch.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id)
                    ) {
                        idx.laenge.push(...befisch.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id);
                    }
                    if (
                        !isEmpty(befisch.fischinfoDetailbefischungergebnisListByForeignBefischung_id)
                        && Array.isArray(befisch.fischinfoDetailbefischungergebnisListByForeignBefischung_id)
                    ) {
                        idx.detail.push(...befisch.fischinfoDetailbefischungergebnisListByForeignBefischung_id);
                    }
                    return idx;
                }, { laenge: Array<FischinfoLaengenklassenbefischungergebnis>(), detail: Array<FischinfoDetailbefischungergebnis>() });

                pflichtTested += Math.sign(Math.abs(ergebIdx.detail.length + ergebIdx.laenge.length));

                if (
                    ergebIdx.laenge.some(ergeb => isNullish(ergeb.anzahljungfische) || Number.isNaN(ergeb.anzahljungfische))
                    || hasMissingJungfisch(ergebIdx.detail)
                ) {
                    pflichtFailed += 1;
                    validationResult.push({
                        validationText: "Fehlende Angabe von Jungfischen bei den Erfassungsarten \"Individuen\" und \"Längenklassen\" mit WRRL-Monitoring",
                        warningText: "Geben Sie eine Anzahl der Jungfische unter \"Befischung > Fische\" bei den Fischen mit Erfassungsart \"Individuen\" oder \"Längenklassen\""
                            + " oder delektieren Sie WRRL-Monitoring unter \"Befischung > Allgemein\".",
                    });
                }
                //#endregion
            }
            //#endregion

            //#region Issue #53: ('Trocken gefallen' oder) 'keine Fische' schließen Eintrag im Feld Fischarten aus
            if (
                revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id.some(befisch => (
                    befisch.keinefische === true
                    && (
                        !isEmpty(befisch.fischinfoDetailbefischungergebnisListByForeignBefischung_id)
                        || !isEmpty(befisch.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id)
                        || !isEmpty(befisch.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id)
                        || !isEmpty(befisch.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id)
                        || !isEmpty(befisch.fischinfoNachweisbefischungergebnisListByForeignBefischung_id)
                    )
                ))
            ) {
                validationResult.push({
                    validationText: "Überzählige Angabe von Fischen, wenn \"Keine Fische\" angegeben ist.",
                    warningText: "Entfernen Sie alle eingetragenen Fische oder deselektieren Sie \"Keine Fische\" unter \"Befischung > Fische\".",
                });
            }
            //#endregion

            //#region Issue #53: Substratverteilung soll 100% ergeben, wenn ausgefüllt (größer 0%)
            if (
                revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id.some(befisch => (
                    befisch.fischinfoGewaesserzustandByGewaesserzustand_id
                    && !isEmpty(befisch.fischinfoGewaesserzustandByGewaesserzustand_id.fischinfoSubstratprozentListByForeignGewaesserzustand_id)
                    && ![0, 100].includes(befisch.fischinfoGewaesserzustandByGewaesserzustand_id.fischinfoSubstratprozentListByForeignGewaesserzustand_id.reduce(
                        (sum, x) => sum + x.anteil,
                        0
                    ))
                ))
            ) {
                validationResult.push({
                    validationText: "Die Summe der Anteile der Substratverteilung ist nicht 100%.",
                    warningText: "Passen Sie die Anteile der Subtrate unter \”Gewässer > Struktur\” so an, dass deren Summe 100% ergibt.",
                });
            }
            //#endregion

            //#region Issue #53: Befischte Breite darf nicht größer sein als Gewässerbreite
            if (
                revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id.some(befisch => (
                    befisch.fischinfoGewaesserzustandByGewaesserzustand_id
                    && befisch.fischinfoMethodeByMethode_id
                    && befisch.fischinfoMethodeByMethode_id.befischungsgeraet_id === 1
                    && befisch.fischinfoMethodeByMethode_id.elektrobefischungsmethode_id === 1
                    && !isNullish(befisch.fischinfoGewaesserzustandByGewaesserzustand_id.gewaesserbreite)
                    && !isNullish(befisch.fischinfoMethodeByMethode_id.befischtebreite)
                    && befisch.fischinfoGewaesserzustandByGewaesserzustand_id.gewaesserbreite
                    < befisch.fischinfoMethodeByMethode_id.befischtebreite
                ))
            ) {
                validationResult.push({
                    validationText: "Die befischte Breite darf nicht größer sein als die Gewässerbreite.",
                    warningText: "Korrigieren Sie entweder die \"befischte Breite\" unter \"Befischung > Methode\" oder die \"Gewässerbreite\" unter \"Gewässer > Allgemein\".",
                });
            }
            //#endregion

            //#region Issue #100: […] ist die Bedingung bei Beoachtungen, dass mindestens eine Fischart oder Krebsart oder eine Muschelart erfasst werden muss.
            const beobachList = revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id.filter(befisch => !!befisch.beobachtung)
            if (beobachList.length > 0) {
                pflichtTested += 1;
                if (
                    beobachList.every(beobach => (
                        isEmpty(beobach.fischinfoDetailbefischungergebnisListByForeignBefischung_id)
                        && isEmpty(beobach.fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id)
                        && isEmpty(beobach.fischinfoAnzahlbefischungergebnisListByForeignBefischung_id)
                        && isEmpty(beobach.fischinfoHaeufigkeitbefischungergebnisListByForeignBefischung_id)
                        && isEmpty(beobach.fischinfoNachweisbefischungergebnisListByForeignBefischung_id)
                    ))
                    && (revisedUntersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id || []).every(beobach => (
                        isEmpty(beobach.fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id)
                        && isEmpty(beobach.fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id)
                        && isEmpty(beobach.fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id)
                        && isEmpty(beobach.fischinfoHaeufigkeitkrebsergebnisListByForeignKrebsuntersuchung_id)
                        && isEmpty(beobach.fischinfoNachweiskrebsergebnisListByForeignKrebsuntersuchung_id)
                    ))
                    && (revisedUntersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id || []).every(beobach => (
                        isEmpty(beobach.fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id)
                        && isEmpty(beobach.fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id)
                        && isEmpty(beobach.fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id)
                        && isEmpty(beobach.fischinfoHaeufigkeitsmuschelergebnisListByForeignMuscheluntersuchung_id)
                        && isEmpty(beobach.fischinfoNachweismuschelergebnisListByForeignMuscheluntersuchung_id)
                    ))
                ) {
                    pflichtFailed += 1;
                    validationResult.push({
                        validationText: "Fehlende Angabe einer gefundenen Fisch-, Krebs- oder Muschelart für eine Beobachtung.",
                        warningText: "Tragen Sie für die Beobachtung mindestens eine Fisch-, Krebs- oder Muschelart ein.",
                    });
                }
            }
            //#endregion
        }

        let ValidationFail: { new(message: string, fails: ValidationFailInfo[]): ValidationFail } = FatalValidationFail;
        let failMsg = "";
        //#region Issue #53: Wenn das Feld „Erfassung abgeschlossen” angewählt ist, dann müssen alle Pflichtfelder ausgefüllt und alle Plausibilitätsprüfungen erfüllt sein.
        if (
            revisedUntersuchung
            && !isEmpty(revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id)
            && Array.isArray(revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id)
            && revisedUntersuchung.fischinfoBefischungListByForeignUntersuchung_id.some(befisch => befisch.erfasst === true)
        ) {
            if (
                validationResult.length > 0
            ) {
                validationResult.push({
                    validationText: "Angabe \"Erfassung abgeschlossen\" kann nur gewählt werden, wenn keine Fehler vorliegen.",
                    warningText: "Füllen Sie alle Pflichtfelder aus und beheben Sie alle Plausibilitätsfehler.",
                });
                failMsg = "Es sind noch nicht alle Pflichtfelder ausgefüllt und/oder es sind nicht alle Plausibilitätsprüfungen erfüllt."
            }
            //#endregion
        }

        //#region Issue #53: Wenn das Feld „Erfassung abgeschlossen” nicht angewählt ist, dann muss mindestens ein Pflichtfeld ausgefüllt sein. Der Nutzer bestätigt das Speichern mit Plausi-/Pflichtfehlern vor dem Speichern.
        else {
            if (
                pflichtFailed > 0
                && pflichtFailed === pflichtTested
            ) {
                validationResult.push({
                    validationText: "Zum Speichern muss mindestens ein Pflichtfeld ausgefüllt sein.",
                    warningText: "Füllen Sie eines der Pflichtfelder \"Datum\" oder \"Bearbeiter\" aus.",
                });
                failMsg = "Zum Speichern muss mindestens ein Pflichtfeld (z.B. \"Datum\") ausgefüllt sein.";
            }
            else {
                ValidationFail = AllowedValidationFail;
                failMsg = "Es sind noch nicht alle Pflichtfelder ausgefüllt und/oder es sind nicht alle Plausibilitätsprüfungen erfüllt.";
            }
        }
        //#endregion

        return validationResult.length ? new ValidationFail(failMsg, validationResult) : null;
    }

    // Datenbereinigung und auflösen von "NOT NULL" Datenwerten, welche Datenmodellintern und nicht durch den User validiert werden müsssen
    public revise(unrevisedUntersuchung: FischinfoUntersuchung): FischinfoUntersuchung {
        let untersuchung: FischinfoUntersuchung = new FischinfoUntersuchung(JSON.parse(JSON.stringify(unrevisedUntersuchung)));

        // #region Laengenklassebefischung (NOT NULL)
        untersuchung
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id.length
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id.forEach((x, i) => {
                x.anzahl_0_5 = x.anzahl_0_5 || 0;
                x.anzahl_10_15 = x.anzahl_10_15 || 0;
                x.anzahl_15_20 = x.anzahl_15_20 || 0;
                x.anzahl_20_25 = x.anzahl_20_25 || 0;
                x.anzahl_25_30 = x.anzahl_25_30 || 0;
                x.anzahl_5_10 = x.anzahl_5_10 || 0;
                x.anzahl_0_10 = x.anzahl_0_10 || 0;
                x.anzahl_10_20 = x.anzahl_10_20 || 0;
                x.anzahl_20_30 = x.anzahl_20_30 || 0;
                x.anzahl_30_40 = x.anzahl_30_40 || 0;
                x.anzahl_40_50 = x.anzahl_40_50 || 0;
                x.anzahl_50_60 = x.anzahl_50_60 || 0;
                x.anzahl_60_70 = x.anzahl_60_70 || 0;
                x.anzahl_70 = x.anzahl_70 || 0;
                x.anzahljungfische = x.anzahljungfische;
                untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoLaengenklassenbefischungergebnisListByForeignBefischung_id[i] = x;
            });
        untersuchung
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id.length
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id.forEach((x, i) => {
                x.anzahl_0_5 = x.anzahl_0_5 || 0;
                x.anzahl_10_15 = x.anzahl_10_15 || 0;
                x.anzahl_15_20 = x.anzahl_15_20 || 0;
                x.anzahl_20_25 = x.anzahl_20_25 || 0;
                x.anzahl_25_30 = x.anzahl_25_30 || 0;
                x.anzahl_5_10 = x.anzahl_5_10 || 0;
                untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoLaengenklassenmuschelergebnisListByForeignMuscheluntersuchung_id[i] = x;
            });
        untersuchung
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id.length
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id.forEach((x, i) => {
                x.anzahl_0_5 = x.anzahl_0_5 || 0;
                x.anzahl_10_15 = x.anzahl_10_15 || 0;
                x.anzahl_15_20 = x.anzahl_15_20 || 0;
                x.anzahl_20_25 = x.anzahl_20_25 || 0;
                x.anzahl_25_30 = x.anzahl_25_30 || 0;
                x.anzahl_5_10 = x.anzahl_5_10 || 0;
                untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoLaengenklassenkrebsergebnisListByForeignKrebsuntersuchung_id[i] = x;
            });
        // #endregion

        // #region Anzahlbefischung (NOT NULL)
        untersuchung
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoAnzahlbefischungergebnisListByForeignBefischung_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoAnzahlbefischungergebnisListByForeignBefischung_id.length
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoAnzahlbefischungergebnisListByForeignBefischung_id.forEach((x, i) => {
                x.anzahl = x.anzahl || 0;
                untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoAnzahlbefischungergebnisListByForeignBefischung_id[i] = x;
            });
        untersuchung
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id.length
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id.forEach((x, i) => {
                x.anzahl = x.anzahl || 0;
                untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoAnzahlmuschelergebnisListByForeignMuscheluntersuchung_id[i] = x;
            });
        untersuchung
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id.length
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id.forEach((x, i) => {
                x.anzahl = x.anzahl || 0;
                untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoAnzahlkrebsergebnisListByForeignKrebsuntersuchung_id[i] = x;
            });
        // #endregion

        // #region Detailbefischung (NOT NULL)
        untersuchung
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoDetailbefischungergebnisListByForeignBefischung_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoDetailbefischungergebnisListByForeignBefischung_id.length
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoDetailbefischungergebnisListByForeignBefischung_id.forEach((x, i) => {
                x.anzahl = x.anzahl || 0;
                x.gewichtg = x.gewichtg || 0;
                x.laengecm = x.laengecm || 0;
                untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoDetailbefischungergebnisListByForeignBefischung_id[i] = x;
            });
        untersuchung
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id.length
            && untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id.forEach((x, i) => {
                x.anzahl = x.anzahl || 0;
                x.gewichtg = x.gewichtg || 0;
                x.groessecm = x.groessecm || 0;
                untersuchung.fischinfoKrebsuntersuchungListByForeignUntersuchung_id[0].fischinfoDetailkrebsergebnisListByForeignKrebsuntersuchung_id[i] = x;
            });
        untersuchung
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id.length
            && untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id.forEach((x, i) => {
                x.anzahl = x.anzahl || 0;
                x.gewichtg = x.gewichtg || 0;
                x.laengecm = x.laengecm || 0;
                untersuchung.fischinfoMuscheluntersuchungListByForeignUntersuchung_id[0].fischinfoDetailmuschelergebnisListByForeignMuscheluntersuchung_id[i] = x;
            });
        // #endregion

        // #region SubstratProzent (NOT NULL)
        untersuchung
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoGewaesserzustandByGewaesserzustand_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoGewaesserzustandByGewaesserzustand_id.fischinfoSubstratprozentListByForeignGewaesserzustand_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoGewaesserzustandByGewaesserzustand_id.fischinfoSubstratprozentListByForeignGewaesserzustand_id.length
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoGewaesserzustandByGewaesserzustand_id.fischinfoSubstratprozentListByForeignGewaesserzustand_id.forEach((x, i) => {
                x.anteil = x.anteil || 0;
                untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoGewaesserzustandByGewaesserzustand_id.fischinfoSubstratprozentListByForeignGewaesserzustand_id[i] = x;
            });
        // #endregion

        // #region FibsResult (NOT NULL)
        untersuchung
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0]
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoFibsresultListByForeignBefischung_id
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoFibsresultListByForeignBefischung_id.length
            && untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoFibsresultListByForeignBefischung_id.forEach((x, i) => {
                x.abwerten = x.abwerten ? true : false;
                x.nwb = x.nwb ? true : false;
                x.warnungandrompotamodrom = x.warnungandrompotamodrom ? true : false;
                x.warnungindividuen = x.warnungindividuen || 0;
                untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0].fischinfoFibsresultListByForeignBefischung_id[i] = x;
            });
        // #endregion

        return untersuchung;
    }
}

export interface ValidationFailInfo { validationText: string, warningText: string }

export class ValidationFail extends Error {
    constructor(
        message: string,
        public readonly isFatal: boolean,
        public readonly fails: ValidationFailInfo[],
    ) {
        super(message);
    }
}

export class FatalValidationFail extends ValidationFail {
    constructor(
        message: string,
        fails: ValidationFailInfo[],
    ) {
        super(message, true, fails);
    }
}

export class AllowedValidationFail extends ValidationFail {
    constructor(
        message: string,
        fails: ValidationFailInfo[],
    ) {
        super(message, false, fails);
    }
}