import { Component, Input, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoGewaesserzustanduferpflanze from "../../models/fischinfo/gewaesserzustanduferpflanze/model";
import FischinfoUferpflanze from "../../models/fischinfo/uferpflanze/model";
/**
 * Mit dieser Komponente kann der Nutzer den Pflanzenbestand der Uferböschung des befischten Gewässers ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt eine Menge von Pflanzenbeständen dar. Der Nutzer kann diese an- und abwählen.
 */
@Component({
    selector: "form-fischinfo-untersuchung-gewaesser-struktur-zustanduferpflanze",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungGewaesserStrukturZustanduferpflanze implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public _zustanduferpflanzeList: any[];
    public get zustanduferpflanzeList() {
        return this._zustanduferpflanzeList;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    ngOnInit() {
        this.refreshZustanduferpflanzeList();
    }
    public onClickZustanduferpflanze(e: any, zustanduferpflanze: FischinfoGewaesserzustanduferpflanze) {
        this.formFischinfoUntersuchungService.modelUtil
            .getOrDefault<FischinfoGewaesserzustanduferpflanze>(this.untersuchung, "FischinfoGewaesserzustanduferpflanze", zustanduferpflanze)
            .id > 0
            ? this.formFischinfoUntersuchungService.modelUtil
                .remove<FischinfoGewaesserzustanduferpflanze>(this.untersuchung, "FischinfoGewaesserzustanduferpflanze", zustanduferpflanze)
            : this.formFischinfoUntersuchungService.modelUtil
                .setOrAdd<FischinfoGewaesserzustanduferpflanze>(this.untersuchung, "FischinfoGewaesserzustanduferpflanze", new FischinfoGewaesserzustanduferpflanze({
                id: 1,
                uferpflanze_id: zustanduferpflanze.uferpflanze_id,
                gewaesserzustand_id: this.formFischinfoUntersuchungService.modelUtil
                    .getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                    .id
            }));
        this.refreshZustanduferpflanzeList();
    }
    public refreshZustanduferpflanzeList() {
        this.getNormalizedZustanduferpflanzeList()
            .then((list) => {
            this._zustanduferpflanzeList = list;
        });
    }
    public getNormalizedZustanduferpflanzeList(): Promise<any[]> {
        let a = this.dataService
            .getReferenceTable("uferpflanze", FischinfoUferpflanze, "id");
        return Promise.all([a])
            .then(([uferpflanze]) => {
            const values = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                .fischinfoGewaesserzustanduferpflanzeListByForeignGewaesserzustand_id || [];
            let list = [];
            Object.keys(uferpflanze).forEach(key => {
                list.push({
                    id: (values.find(x => x.uferpflanze_id === uferpflanze[key].id) || { id: 0 }).id,
                    bezeichnung: uferpflanze[key].bezeichnung,
                    uferpflanze_id: uferpflanze[key].id
                });
            });
            return list
                .filter(x => !this.readonly || x.id > 0)
                .sort((a, b) => {
                a = a.uferpflanze_id;
                b = b.uferpflanze_id;
                return (a > b)
                    ? 1
                    : ((b > a)
                        ? -1
                        : 0);
            });
        });
    }
}
