import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoGemeinde from "./interface";
import FischinfoBesatzort from "./../../fischinfo/besatzort/model";
import FischinfoProbenahmestelle from "./../../fischinfo/probenahmestelle/model";
import FischinfoBezirk from "./../../fischinfo/bezirk/model";
import FischinfoKreis from "./../../fischinfo/kreis/model";
@fn.DataModel
export default class FischinfoGemeinde extends DataModel implements IFischinfoGemeinde {
    constructor(obj?: object | IFischinfoGemeinde) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoGemeinde): FischinfoGemeinde {
        return new FischinfoGemeinde(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoGemeinde): FischinfoGemeinde {
        Object.assign(this, obj);
        obj && obj["fischinfoBesatzortListByForeignGemeinde_id"] && obj["fischinfoBesatzortListByForeignGemeinde_id"].length && (this.fischinfoBesatzortListByForeignGemeinde_id = obj["fischinfoBesatzortListByForeignGemeinde_id"].map(FischinfoBesatzort.deserialize));
        obj && obj["fischinfoProbenahmestelleListByForeignGemeinde_id"] && obj["fischinfoProbenahmestelleListByForeignGemeinde_id"].length && (this.fischinfoProbenahmestelleListByForeignGemeinde_id = obj["fischinfoProbenahmestelleListByForeignGemeinde_id"].map(FischinfoProbenahmestelle.deserialize));
        obj && obj["fischinfoKreisByKreis_id"] && (this.fischinfoKreisByKreis_id = FischinfoKreis.deserialize(obj["fischinfoKreisByKreis_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "bezirk_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public bezirk_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gemeindename", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gemeindename?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gkz", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gkz?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "kreis_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public kreis_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoBesatzort>}
     */
    @fn.DataMember({ name: "fischinfoBesatzortListByForeignGemeinde_id", type: [FischinfoBesatzort], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzortListByForeignGemeinde_id?: Array<FischinfoBesatzort>;

    /**
     * @type {Array<FischinfoProbenahmestelle>}
     */
    @fn.DataMember({ name: "fischinfoProbenahmestelleListByForeignGemeinde_id", type: [FischinfoProbenahmestelle], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoProbenahmestelleListByForeignGemeinde_id?: Array<FischinfoProbenahmestelle>;

    /**
     * @type {FischinfoBezirk}
     */
    @fn.DataMember({ name: "fischinfoBezirkByBezirk_id", type: FischinfoBezirk, nullable: true, memberType: "JOIN", joinProperty: "bezirk_id" })
    @fn.Label("Bezirk")
    public fischinfoBezirkByBezirk_id?: FischinfoBezirk;

    /**
     * @type {FischinfoKreis}
     */
    @fn.DataMember({ name: "fischinfoKreisByKreis_id", type: FischinfoKreis, nullable: true, memberType: "JOIN", joinProperty: "kreis_id" })
    @fn.Label("Kreis")
    public fischinfoKreisByKreis_id?: FischinfoKreis;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
