import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoKreis from "./interface";
import FischinfoGemeinde from "./../../fischinfo/gemeinde/model";
@fn.DataModel
export default class FischinfoKreis extends DataModel implements IFischinfoKreis {
    constructor(obj?: object | IFischinfoKreis) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoKreis): FischinfoKreis {
        return new FischinfoKreis(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoKreis): FischinfoKreis {
        Object.assign(this, obj);
        obj && obj["fischinfoGemeindeListByForeignKreis_id"] && obj["fischinfoGemeindeListByForeignKreis_id"].length && (this.fischinfoGemeindeListByForeignKreis_id = obj["fischinfoGemeindeListByForeignKreis_id"].map(FischinfoGemeinde.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "gkz", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public gkz?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "kreisname", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public kreisname?: string;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoGemeinde>}
     */
    @fn.DataMember({ name: "fischinfoGemeindeListByForeignKreis_id", type: [FischinfoGemeinde], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoGemeindeListByForeignKreis_id?: Array<FischinfoGemeinde>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
