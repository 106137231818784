import { Component, Input, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoAusbauzustand from "../../models/fischinfo/ausbauzustand/model";
import FischinfoGewaesserzustandausbauzustand from "../../models/fischinfo/gewaesserzustandausbauzustand/model";
/**
 * Mit dieser Komponente kann der Nutzer den Ausbauzustand des befischten Gewässers ansehen und ggf. bearbeiten.
 *
 * Dieser Komponente stellt eine Menge von Ausbauelementen dar. Der Nutzer kann diese an- oder abwählen.
 */
@Component({
    selector: "form-fischinfo-untersuchung-gewaesser-struktur-ausbauzustand",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungGewaesserStrukturAusbauzustand implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public _ausbauzustandList: any[];
    public get ausbauzustandList() {
        return this._ausbauzustandList;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    ngOnInit() {
        this.refreshAusbauzustandList();
    }
    public onClickAusbauzustand(e: any, ausbauzustand: FischinfoGewaesserzustandausbauzustand) {
        this.formFischinfoUntersuchungService.modelUtil
            .getOrDefault<FischinfoGewaesserzustandausbauzustand>(this.untersuchung, "FischinfoGewaesserzustandausbauzustand", ausbauzustand)
            .id > 0
            ? this.formFischinfoUntersuchungService.modelUtil
                .remove<FischinfoGewaesserzustandausbauzustand>(this.untersuchung, "FischinfoGewaesserzustandausbauzustand", ausbauzustand)
            : this.formFischinfoUntersuchungService.modelUtil
                .setOrAdd<FischinfoGewaesserzustandausbauzustand>(this.untersuchung, "FischinfoGewaesserzustandausbauzustand", new FischinfoGewaesserzustandausbauzustand({
                id: 1,
                ausbauzustand_id: ausbauzustand.ausbauzustand_id,
                gewaesserzustand_id: this.formFischinfoUntersuchungService.modelUtil
                    .getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                    .id
            }));
        this.refreshAusbauzustandList();
    }
    public refreshAusbauzustandList() {
        this.getNormalizedAusbauzustandList()
            .then((list) => {
            this._ausbauzustandList = list;
        });
    }
    public getNormalizedAusbauzustandList(): Promise<any[]> {
        let a = this.dataService
            .getReferenceTable("ausbauzustand", FischinfoAusbauzustand, "id");
        return Promise.all([a])
            .then(([ausbauzustand]) => {
            const values = this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
                .fischinfoGewaesserzustandausbauzustandListByForeignGewaesserzustand_id || [];
            let list = [];
            Object.keys(ausbauzustand).forEach(key => {
                list.push({
                    id: (values.filter(x => x.ausbauzustand_id === ausbauzustand[key].id)[0] || { id: 0 }).id,
                    bezeichnung: ausbauzustand[key].bezeichnung,
                    ausbauzustand_id: ausbauzustand[key].id
                });
            });
            return list
                .filter(x => !this.readonly || x.id > 0)
                .sort((a, b) => {
                a = a.ausbauzustand_id;
                b = b.ausbauzustand_id;
                return (a > b)
                    ? 1
                    : ((b > a)
                        ? -1
                        : 0);
            });
        });
    }
}
