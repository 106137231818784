import { HttpClient } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { take } from "rxjs/operators";
import DataService from "../data/service";
import ModalService from "../modal/service";
import UserService from "../user/service";
import IntragisBenutzerprobenahmestelle from "../../models/intragis/benutzerprobenahmestelle/model";
import ModalWindowTabAuftragverwaltung from "../modal-window-tab-auftragverwaltung/component";
@Component({
    selector: "uw-modal-window-tab-auftragimport",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowTabAuftragimport {
    @Input()
    public modal: any;
    @Input()
    public set topmargin(value: string) {
        this.topMarginPx = String(Number(value) - (this.userService.roles.includes("ROLE_ADMIN")
            ? 0
            : Number(this.buttonPanelHeight)));
    }
    ;
    public get topmargin(): string {
        return this.topMarginPx;
    }
    ;
    public buttonPanelHeight: string = "24";
    public topMarginPx: string;
    constructor(public readonly modalService: ModalService, public readonly dataService: DataService, public readonly userService: UserService, public readonly http: HttpClient) {
        this.topmargin = "0";
    }
    public running: boolean = false;
    public error: any = null;
    public async importAuftragCsv(file: File) {
        const auftragList: ModalWindowTabAuftragverwaltung = this.modal.data.auftragList;
        try {
            this.error = null;
            this.running = true;
            this.modal.data.loaded = 0;
            auftragList.auftragImportDisabled = true;
            auftragList.clearAuftragList();
            const data = Array.from(this.readCsvAsObject(await this.loadContent(file)));
            if (!data || data.length === 0) {
                throw this.createInvalidFormatError();
            }
            await this.requestImport(data);
            await auftragList.updateAuftragList();
            auftragList.auftragImportDisabled = false;
            this.modal.data.loaded = 1;
        }
        catch (e) {
            this.error = e;
        }
        finally {
            this.running = false;
            auftragList.auftragImportDisabled = false;
            this.modal.data.loaded = 1;
        }
    }
    public loadContent(file: File) {
        return new Promise<string>((resolve, reject) => {
            const fr = new FileReader();
            fr.addEventListener("load", (ev: ProgressEvent) => {
                const fr = ev.target as FileReader;
                if (fr.readyState === FileReader.DONE) {
                    resolve(fr.result as string);
                }
            });
            fr.addEventListener("error", reject);
            fr.readAsText(file);
        });
    }
    public *lines(text: string) {
        for (let i = -1; (i = (text = text.slice(i + 1)).indexOf("\n", 1)) != -1;) {
            yield text.slice(0, i);
        }
        if (text.length)
            yield text;
    }
    public *readCsvAsObject(text: string) {
        const lines = this.lines(text);
        let i = 0;
        for (let line: string | void = undefined; !({ value: line! } = lines.next()).done; ++i) {
            line = line as string;
            line = line.trim();
            if (i === 0) {
                if (line !== "benutzer_id;probestellennr;gueltigvon;gueltigbis;bezeichnung;anlass") {
                    throw this.createInvalidFormatError();
                }
            }
            else {
                try {
                    yield this.parseAuftragCsvLine(line);
                }
                catch (e) {
                    console.error(e);
                    throw this.createInvalidFormatError();
                }
            }
        }
    }
    public parseAuftragCsvLine(line: string) {
        const cells = line.split(";");
        if (cells.length !== 6) {
            throw new RangeError("Invalid cell count: " + cells.length);
        }
        const nullIfEmpty = <T extends {
            length: number;
        }>(val: T) => val != null && val.length === 0 ? null : val;
        const parseDateTuple = (src: string) => src.split(".", 3).map(n => Number.parseInt(n)) as [
            number,
            number,
            number
        ];
        const parseString = (src: string) => nullIfEmpty(JSON.parse(`"${src.replace(/"/g, (_, i) => i === 0 || i === src.length - 1 ? "" : "\\\"")}"`));
        const benutzer_id = Number.parseInt(cells[0]);
        const probestellennr = cells[1].trim();
        const gueltigvonTuple = parseDateTuple(cells[2]);
        const gueltigvon = Date.UTC(gueltigvonTuple[2], gueltigvonTuple[1] - 1, gueltigvonTuple[0]);
        const gueltigbisTuple = parseDateTuple(cells[3]);
        const gueltigbis = Date.UTC(gueltigbisTuple[2], gueltigbisTuple[1] - 1, gueltigbisTuple[0]);
        const bezeichnung = parseString(cells[4]);
        const anlass = parseString(cells[5]);
        if (probestellennr.length === 0) {
            throw new RangeError("Empty Probenahmestelle");
        }
        if (gueltigvonTuple.length !== 3 || gueltigvonTuple.some(n => Number.isNaN(n) || !Number.isInteger(n))
            || gueltigbisTuple.length !== 3 || gueltigbisTuple.some(n => Number.isNaN(n) || !Number.isInteger(n))) {
            throw new RangeError("Invalid date format");
        }
        return new IntragisBenutzerprobenahmestelle({ probestellennr, benutzer_id, gueltigvon, gueltigbis, bezeichnung, anlass });
    }
    public createInvalidFormatError() {
        return Object.assign(new Error("Invalid Auftrag-Import format"), { name: "INVALID_FORMAT" as "INVALID_FORMAT" });
    }
    public async requestImport(data: any[]) {
        // await this.userService.whenUserSetup;
        const token = this.userService.token;
        await this.http
            .post<never>(this.dataService.transcribeURL(this.dataService.webServiceApiListUrl.replace("{folder}", "{folder}/{subfolder}"), { folder: "auftragverwaltung", subfolder: "auftraege" }, 1), data, { headers: { "X-Auth-Token": token } })
            .pipe(take(1)).toPromise();
    }
}
