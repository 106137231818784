import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoStillgewaessertyp from "./interface";
import FischinfoBesatzort from "./../../fischinfo/besatzort/model";
import FischinfoProbenahmestelle from "./../../fischinfo/probenahmestelle/model";
@fn.DataModel
export default class FischinfoStillgewaessertyp extends DataModel implements IFischinfoStillgewaessertyp {
    constructor(obj?: object | IFischinfoStillgewaessertyp) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoStillgewaessertyp): FischinfoStillgewaessertyp {
        return new FischinfoStillgewaessertyp(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoStillgewaessertyp): FischinfoStillgewaessertyp {
        if (obj.hasOwnProperty("b")) {
            Object.assign(this, {
                bezeichnung: obj["a"],
                id: obj["b"],
                stillgewaesser: obj["c"]
            });
        } else {
            Object.assign(this, obj);
            obj && obj["fischinfoBesatzortListByForeignStillgewaessertyp_id"] && obj["fischinfoBesatzortListByForeignStillgewaessertyp_id"].length && (this.fischinfoBesatzortListByForeignStillgewaessertyp_id = obj["fischinfoBesatzortListByForeignStillgewaessertyp_id"].map(FischinfoBesatzort.deserialize));
            obj && obj["fischinfoProbenahmestelleListByForeignStillgewaessertyp_id"] && obj["fischinfoProbenahmestelleListByForeignStillgewaessertyp_id"].length && (this.fischinfoProbenahmestelleListByForeignStillgewaessertyp_id = obj["fischinfoProbenahmestelleListByForeignStillgewaessertyp_id"].map(FischinfoProbenahmestelle.deserialize));
        }
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "stillgewaesser", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public stillgewaesser?: boolean;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoBesatzort>}
     */
    @fn.DataMember({ name: "fischinfoBesatzortListByForeignStillgewaessertyp_id", type: [FischinfoBesatzort], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoBesatzortListByForeignStillgewaessertyp_id?: Array<FischinfoBesatzort>;

    /**
     * @type {Array<FischinfoProbenahmestelle>}
     */
    @fn.DataMember({ name: "fischinfoProbenahmestelleListByForeignStillgewaessertyp_id", type: [FischinfoProbenahmestelle], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoProbenahmestelleListByForeignStillgewaessertyp_id?: Array<FischinfoProbenahmestelle>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
