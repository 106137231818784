import { Component, ElementRef, Inject, OnInit, AfterViewInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { UWMap } from "ng-uw-map-leaflet";
import ModalService from "./../modal/service";
import MapService from "./service";
import GridLayerService from "./../map-gridlayer/service";
import MapEditorService from "./../map-editor/service";
import DataService from "./../data/service";
import UserService from "./../user/service";
import AppService from "./../app/service";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import KDBush from 'kdbush';
/**
 * Diese Komponente zeigt eine Karte mit Hilfe eines Karten-WebServices an und lässt den Nutzer die Karte manipulieren.
 */
@Component({
    selector: "custom-map",
    templateUrl: "./default.component.html",
    styleUrls: [
        "./base.component.less",
        "./leaflet.less",
        "./default.component.less",
        "../../assets/styles/main.less"
    ]
})
export default class Map extends UWMap {
    public mouseWasOverUTFGridFeatureOnLastClick: boolean;
    public get hasRightToCreatePS(): boolean {
        const roleFilter = (x: string): boolean => x == "ERFASSER_INTERN" || x == "ROLE_ADMIN";
        return this.userService.roles
            && this.userService.roles.filter(roleFilter).length
            ? true
            : false;
    }
    constructor(
        @Inject(DOCUMENT)
        public document: any, public elementRef: ElementRef, public mapService: MapService, public gridLayerService: GridLayerService, public mapEditorService: MapEditorService, public dataService: DataService, public userService: UserService, public appService: AppService, public modalService: ModalService) {
        super(document, elementRef, mapService);
    }
    public ngOnInit(): void {
        super.ngOnInit();
        this.gridLayerService.spatialIndexTree["probenahmestelle"] = [];
        this.gridLayerService.data["probenahmestelle"] = [];
        this.gridLayerService.redraw(this.mapService.map, "probenahmestelle", {});
        this.dataService.startLoad.subscribe(() => {
            this.gridLayerService.spatialIndexTree["probenahmestelle"] = [];
            this.gridLayerService.data["probenahmestelle"] = [];
            this.gridLayerService.redraw(this.mapService.map, "probenahmestelle", {});
        });
        this.dataService.partialLoadSuccess.subscribe((probenahmestellen: Array<FischinfoProbenahmestelle>) => {
            if (probenahmestellen && probenahmestellen.length) {
                const list = probenahmestellen
                    .filter(x => x.etrs89e)
                    .map(x => {
                        const coor = this.mapService.project("EPSG:25832", "EPSG:4326", [x.etrs89e, x.etrs89n]);
                        return Object.assign({}, {
                            id: x.id,
                            type: x.gewaessertyp,
                            etrs89e: x.etrs89e,
                            etrs89n: x.etrs89n,
                            etrs4326e: coor[0],
                            etrs4326n: coor[1],
                            label: x.probestellennrAsString,
                            freigegeben: x.freigegeben
                        });
                    });
                this.gridLayerService.data["probenahmestelle"].push(list);
                this.gridLayerService.spatialIndexTree["probenahmestelle"].push(new KDBush(list, p => p.etrs89e, p => p.etrs89n, 64, Float64Array));
                this.gridLayerService.refresh(this.mapService.map, "probenahmestelle", {});
            }
        });
    }
    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        setTimeout(() => {
            this.mapService.authToken = this.userService.token;
            this.mapService.zoomToBounds(null, null, null);
        }, 200);
    }
    public onClickAddProbenahmestelle(e) {
        if (this.dataService.filtersAreSet) {
            this.modalService.closeModals();
            const modalId: string = this.modalService.createModal({
                title: "Anfrage wird ausgeführt…",
                status: "Wird geladen…",
                data: {
                    type: "unsetfilter",
                    activeTab: "unsetfilter",
                    loaded: 1
                }
            });
        }
        else {
            this.mapEditorService.modifyProbenahmestelle
                .emit(new FischinfoProbenahmestelle({
                    id: 0
                }));
        }
    }
    public setComponentHeight(): void {
        this.height =
            this.heightDependency === "parentNode" &&
                this.nativeElement &&
                this.nativeElement.parentNode &&
                this.nativeElement.parentNode.offsetHeight > 0
                ? this.nativeElement.parentNode.offsetHeight
                : this.heightDependency === "static" && this.height ? this.height : 320;
    }
    public switchBaseLayer(layer: string): void {
        this.baseLayer = layer;
        this.mapService.switchBaseLayer(layer);
    }
    public onClickInfoButton(e: MouseEvent): void {
        this.modalService.closeModals();
        const modalId: string = this.modalService.createModal({
            title: "Anfrage wird ausgeführt…",
            status: "Wird geladen…",
            data: {
                type: "imprint",
                activeTab: "imprint",
                loaded: 1
            }
        });
    }
    public onClickHelpButton(e: MouseEvent): void {
        this.modalService.closeModals();
        const modalId: string = this.modalService.createModal({
            title: "Anfrage wird ausgeführt…",
            status: "Wird geladen…",
            data: {
                type: "help",
                activeTab: "help",
                loaded: 1
            }
        });
    }
    /*
        protected onClickCreateButton(e: MouseEvent): boolean {
            this.mapService.objectCreate.emit();
            return true;
        }
    */
    public clickOnMap(e: any): boolean {
        // if (this.mouseWasOverUTFGridFeatureOnLastClick) {
        if (!this.mapEditorService.isEditing) {
            this.mapService.clickOnMap(e);
        }
        // }
        return true;
    }
    public onClickSearchLocationButton(e: MouseEvent): boolean {
        this.modalService.closeModals();
        this.modalService.createModal({
            data: {
                activeTab: "location-search",
                location: true,
                loaded: 1
            },
        });
        return false;
    }
    public onClickReloadButton(e: MouseEvent): boolean {
        this.dataService.loadDataStore();
        return false;
    }
    public onClickExportCSVButton(e: MouseEvent): boolean {
        // this.dataService.saveAsFile("csv");
        this.modalService.closeModals();
        this.modalService.createModal({
            data: {
                activeTab: "export",
                export: true,
            },
        });
        return false;
    }
    public clickOnZoomIn(e: MouseEvent): boolean {
        this.mapService.zoomIn();
        return true;
    }
    public clickOnZoomOut(e: MouseEvent): boolean {
        this.mapService.zoomOut();
        return true;
    }
}
