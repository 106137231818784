import { Component, Input, OnInit, OnDestroy, ViewChild, } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { saveAs } from "file-saver";
import { UWFileUpload } from "ng-uw-fileupload";
import ModalService from "./../modal/service";
import DataService from "./../data/service";
import EsriExport from "./esri";
import AuskunftShpExport from "./auskunftshp";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import UserService from "../user/service";
export type Warning = {
    message: string | Array<string>;
    target: string;
    code?: string;
};
export type WarningInfo = {
    message: string | Array<string>;
    target?: string;
    code?: string;
};
@Component({
    selector: "uw-modal-window-tab-auskunft",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowTabAuskunft implements OnDestroy, OnInit {
    @Input()
    public modal: any;
    @Input()
    public topmargin: string = "24";
    public readonly baseHeaders = {
        "Accept": "application/json",
    };
    public esriExport: EsriExport;
    public auskunftShpExport: AuskunftShpExport;
    public isProcessingEsri: boolean = false;
    public get esriProgressPerc() {
        let esriProgress = 0;
        if (this.esriExport) {
            esriProgress = this.esriExport.progress;
        }
        let uploadProgress = this.uploadProgress;
        const progress = (uploadProgress * .1 + esriProgress * .9) * 100;
        return Math.min(progress, 100);
    }
    public dataNodePlaceholder: string = "";
    public uploadUrl: string = "";
    public uploadProgress: number = 0.0;
    public warningIsShown: boolean = false;
    public warningStack: Array<Warning> = [];
    public warningTarget: string;
    public warningText: string | Array<string> = null;
    @ViewChild("fileupload", { static: false })
    public fileupload: UWFileUpload;
    public get warningIsShownAsString(): string {
        return String(this.warningIsShown);
    }
    public set warningIsShownAsString(value: string) {
        this.warningIsShown = value === 'true';
    }
    public get limitedToUserRole(): boolean {
        return this.userService.roles
            && !this.userService.roles.filter((x: string): boolean => x == "ROLE_ADMIN" || x == "ERFASSER_EXTERN" || x == "ERFASSER_INTERN").length
            ? true
            : false;
    }
    constructor(public modalService: ModalService, public dataService: DataService, public http: HttpClient, public userService: UserService) {
        this.uploadUrl = dataService.webServiceApiUrl + (dataService.webServiceApiUrl.slice(-1) === "/"
            ? "upload" : "/upload");
    }
    public ngOnInit() {
        this.modal.data.loaded = true;
    }
    public ngOnDestroy() {
        this.esriExport && this.esriExport.cancel();
        this.auskunftShpExport && this.auskunftShpExport.cancel();
    }
    public get userHasAdminRole(): boolean {
        return this.userService.roles != null
            && this.userService.roles.some(x => x == "ROLE_ADMIN");
    }
    public isLoadingAuskunft(auskunftVersion: string) {
        let t: any = this;
        return (t = t.modal) && (t = t.data) && (t = t.loadingAuskunft) && t[auskunftVersion] || false;
    }
    public doExportEsriShapefile(uploadpath: string) {
        const esri = this.esriExport = new EsriExport(this.http, this.dataService, () => this.prepareRequest());
        this.isProcessingEsri = true;
        esri.export(uploadpath)
            .then(file => {
                this.resetEsriExport();
                saveAs(file, "esrishape.zip");
            }, _ => {
                this.resetEsriExport();
            });
    }
    public exportEsriShapefile() {
        const csv = this.dataService.buildCsvData();
        this.fileupload.uploadString(csv, "source.csv", "text/csv");
    }
    public exportCsvFile() {
        this.dataService.saveAsFile("csv");
    }
    public async exportAuskunftAsCsvFile(auskunftVersion: string) {
        const data = this.modal.data || {};
        const loadingAuskunft = data.loadingAuskunft || (data.loadingAuskunft = {});
        auskunftVersion = auskunftVersion + "_csv";
        if ("1234567890".includes(auskunftVersion[0])) {
            auskunftVersion = "auskunft" + auskunftVersion;
        }
        if (!loadingAuskunft[auskunftVersion]) {
            loadingAuskunft[auskunftVersion] = true;
            data.loaded = false;
            try {
                const befischungList = (<FischinfoUntersuchung[]>this.dataService.dataStore["untersuchung"])
                    .map((untersuchung) => (untersuchung.fischinfoBefischungListByForeignUntersuchung_id
                        && <FischinfoBefischung>(untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0] as any)))
                    .filter((b) => b != null);
                if (befischungList.length > 0) {
                    const befischungIds = befischungList.map(b => (Number(b.id) | 0));
                    const requtils = await this.prepareRequest();
                    const csv = await firstValueFrom(this.http.post(this.dataService.transcribeURL(this.dataService.webServiceApiListUrl.replace("?", "/{version}?"), {
                        folder: "auskunft",
                        version: auskunftVersion,
                    }), befischungIds, {
                            headers: requtils.headers(),
                            responseType: "blob",
                        }));
                    saveAs(csv, `${auskunftVersion.replace(/_csv$/, "")}.csv`);
                }
            }
            finally {
                delete loadingAuskunft[auskunftVersion];
                data.loaded = Object.keys(loadingAuskunft).length === 0;
            }
        }
    }
    public async exportAuskunftAsXmlFile(auskunftVersion: string) {
        const data = this.modal.data || {};
        const loadingAuskunft = data.loadingAuskunft || (data.loadingAuskunft = {});
        if ("1234567890".includes(auskunftVersion[0])) {
            auskunftVersion = "auskunft" + auskunftVersion;
        }
        if (!loadingAuskunft[auskunftVersion]) {
            loadingAuskunft[auskunftVersion] = true;
            data.loaded = false;
            try {
                const befischungList = (<FischinfoUntersuchung[]>this.dataService.dataStore["untersuchung"])
                    .map((untersuchung) => (untersuchung.fischinfoBefischungListByForeignUntersuchung_id
                        && <FischinfoBefischung>(untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0] as any)))
                    .filter((b) => b != null);
                if (befischungList.length > 0) {
                    const befischungIds = befischungList.map(b => (Number(b.id) | 0));
                    const requtils = await this.prepareRequest();
                    const xml = await firstValueFrom(this.http.post(this.dataService.transcribeURL(this.dataService.webServiceApiListUrl.replace("?", "/{version}?"), {
                        folder: "auskunft",
                        version: auskunftVersion,
                    }), befischungIds, {
                            headers: requtils.headers(),
                            responseType: "blob",
                        }));
                    saveAs(xml, `${auskunftVersion}.xml`);
                }
            }
            finally {
                delete loadingAuskunft[auskunftVersion];
                data.loaded = Object.keys(loadingAuskunft).length === 0;
            }
        }
    }
    public async exportAuskunftAsShpFile(auskunftVersion: string) {
        const data = this.modal.data || {};
        const loadingAuskunft = data.loadingAuskunft || (data.loadingAuskunft = {});
        const auskunftVersionKey = auskunftVersion + "_shape";
        if (!loadingAuskunft[auskunftVersionKey]) {
            loadingAuskunft[auskunftVersionKey] = true;
            data.loaded = false;
            try {
                const befischungList = (<FischinfoUntersuchung[]>this.dataService.dataStore["untersuchung"])
                    .map((untersuchung) => (untersuchung.fischinfoBefischungListByForeignUntersuchung_id
                        && <FischinfoBefischung>(untersuchung.fischinfoBefischungListByForeignUntersuchung_id[0] as any)))
                    .filter((b) => b != null);
                if (befischungList.length > 0) {
                    const befischungIds = befischungList.map(b => (Number(b.id) | 0));
                    const auskunftShp = this.auskunftShpExport = new AuskunftShpExport(this.http, this.dataService, () => this.prepareRequest());
                    this.isProcessingEsri = true;
                    const file = await auskunftShp.export(auskunftVersion, befischungIds);
                    saveAs(file, ["auskunft", auskunftVersion, "_shape.zip"].join(""));
                }
            }
            finally {
                this.resetAuskunftShpExport();
                delete loadingAuskunft[auskunftVersionKey];
                data.loaded = Object.keys(loadingAuskunft).length === 0;
            }
        }
    }
    public fetchUploadFilePath(fileid: string) {
        return this.prepareRequest()
            .then(requtil => this.http.get<{
                resultset: [
                    {
                        path: string;
                    }
                ];
            }>(this.dataService.webServiceApiUrl
                + "upload/" + fileid + "/path"
                + "?_t=" + String(Date.now()) + String(Math.random()).slice(2), {
                    headers: requtil.headers(),
                }).toPromise())
            .then(resp => resp.resultset[0].path);
    }
    public prepareRequest() {
        const baseHeaders = this.baseHeaders;
        return this.dataService.getLoginTokenPromise()
            .then(token => {
                const tokenHeaders = {
                    "X-AUTH-TOKEN": token,
                };
                return {
                    headers: (otherHeaders?: {
                        [name: string]: any;
                    }) => new HttpHeaders(Object.assign({}, baseHeaders, tokenHeaders, otherHeaders)),
                };
            });
    }
    public resetAuskunftShpExport() {
        // this.uploadProgress = 0.0;
        this.auskunftShpExport = null;
        // this.isProcessingEsri = false;
    }
    public resetEsriExport() {
        this.uploadProgress = 0.0;
        this.esriExport = null;
        this.isProcessingEsri = false;
    }
    public warn(w: WarningInfo) {
        if (!w.target)
            w.target = "submit";
        this.warningStack.push(<Warning>w);
        this.warningText = w.message;
        this.warningIsShown = true;
    }
    public warnFromResponse(response: Response) {
        const warning = {
            "400": {
                code: "InvalidData",
                message: "Bitte überprüfen Sie alle eingegebene Daten auf Korrektheit!",
            },
            "403": {
                code: "ActionForbidden",
                message: "Sie haben nicht die nötigen Rechte!",
            },
            "500": {
                code: "ServiceUnavailable",
                message: "Die Anfrage kann aktuell nicht bearbeitet werden. Bitte probieren Sie es später noch einmal.",
            }
        }[response.status]
            || {
                code: "UnknownError",
                message: "Unbekannter Fehler im Datenservice. Bitte wenden Sie sich an Ihren IT-Dienstleister.",
            };
        if (warning)
            this.warningRemove((w) => w.code === warning.code);
        this.warn(warning);
    }
    public closeWarning() {
        this.warningIsShown = false;
        this.warningText = null;
        this.warningTarget = null;
    }
    public warningRemove(filter: (item: Warning, index: number) => boolean) {
        const stack = this.warningStack;
        for (let i = 0; i < stack.length;) {
            if (filter(stack[i], i)) {
                stack.splice(i, 1);
            }
            else
                ++i;
        }
    }
    public warningTopShow() {
        this.warningTarget = this.warningStack[0].target;
        this.warningText = this.warningStack[0].message;
        this.warningIsShown = true;
    }
    public onUploadProgress(progress: number) {
        this.uploadProgress = progress;
    }
    public onUploadSuccess(result: any) {
        const fileid = result.data.resultset[0].id;
        this.fetchUploadFilePath(fileid)
            .then(uploadpath => this.doExportEsriShapefile(uploadpath));
    }
    public onUploadError(result: any) {
        this.resetEsriExport();
    }
}
