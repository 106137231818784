import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoStrukturelement from "./interface";
import FischinfoStrukturelementehaeufigkeit from "./../../fischinfo/strukturelementehaeufigkeit/model";
@fn.DataModel
export default class FischinfoStrukturelement extends DataModel implements IFischinfoStrukturelement {
    constructor(obj?: object | IFischinfoStrukturelement) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoStrukturelement): FischinfoStrukturelement {
        return new FischinfoStrukturelement(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoStrukturelement): FischinfoStrukturelement {
        Object.assign(this, obj);
        obj && obj["fischinfoStrukturelementehaeufigkeitListByForeignStrukturelement_id"] && obj["fischinfoStrukturelementehaeufigkeitListByForeignStrukturelement_id"].length && (this.fischinfoStrukturelementehaeufigkeitListByForeignStrukturelement_id = obj["fischinfoStrukturelementehaeufigkeitListByForeignStrukturelement_id"].map(FischinfoStrukturelementehaeufigkeit.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "fliessgewaesser", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public fliessgewaesser?: boolean;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "oldkey", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public oldkey?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "stillgewaesser", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public stillgewaesser?: boolean;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {Array<FischinfoStrukturelementehaeufigkeit>}
     */
    @fn.DataMember({ name: "fischinfoStrukturelementehaeufigkeitListByForeignStrukturelement_id", type: [FischinfoStrukturelementehaeufigkeit], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoStrukturelementehaeufigkeitListByForeignStrukturelement_id?: Array<FischinfoStrukturelementehaeufigkeit>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
