import { Component, Input, OnInit } from "@angular/core";
import UserService from "./../user/service";
import ModalService from "./../modal/service";
import DataService from "./../data/service";
import MapService from "../map/service";
import IntragisBenutzerprobenahmestelle from "../../models/intragis/benutzerprobenahmestelle/model";
import IIntragisBenutzerprobenahmestelle from "../../models/intragis/benutzerprobenahmestelle/interface";
import ModalWindowTabAuftragverwaltung from "../modal-window-tab-auftragverwaltung/component";
const isNullish = (x: any): x is null => x == null;
const handleNullish = <T>(pred: (x: T) => boolean) => (x: null | T, allowNullish: boolean = false) => isNullish(x) ? !allowNullish : pred(x);
const isEmpty = handleNullish((x: {
    length: number;
}) => x.length === 0);
const isZero = handleNullish((x: number) => x === 0.0);
@Component({
    selector: "uw-modal-window-tab-auftrag",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowTabAuftrag implements OnInit {
    @Input()
    public modal: any;
    @Input()
    public set topmargin(value: string) {
        this.topMarginPx = String(Number(value) - (this.userService.roles.includes("ROLE_ADMIN")
            ? 0
            : Number(this.buttonPanelHeight)));
    }
    ;
    public get topmargin(): string {
        return this.topMarginPx;
    }
    ;
    @Input()
    public mode: "Delete" | "Edit" | "Create" | null = null;
    public get readonly() {
        return this.mode == null;
    }
    public readonly isExtendedView: boolean = false;
    public readonly hasRightToEdit: boolean = false;
    public buttonPanelHeight: string = "24";
    public get buttonPanelHeightPx(): number {
        return Number(this.buttonPanelHeight);
    }
    public topMarginPx: string;
    public confirmSaveIsShown: boolean = false;
    public warningIsShown: "true" | "false" = "false";
    public warningSummary: string;
    public warningText: string | Array<string> = "";
    public warningTarget: any;
    public warningStack: Array<any> = [];
    public auftrag$: IntragisBenutzerprobenahmestelle = null;
    public get auftrag(): IntragisBenutzerprobenahmestelle {
        this.updateAuftragIfIdChanged();
        return this.auftrag$;
    }
    public set auftrag(val: IntragisBenutzerprobenahmestelle) {
        this.auftrag$ = val;
    }
    public unchangedAuftrag: IntragisBenutzerprobenahmestelle = null;
    public get benutzer_id(): number {
        return this.auftrag && this.auftrag.benutzer_id;
    }
    public set benutzer_id(v: number) {
        if (this.auftrag) {
            this.auftrag.benutzer_id = v;
        }
    }
    public get benutzer_name(): string {
        return this.auftrag && this.auftrag.benutzer_name;
    }
    public set benutzer_name(v: string) {
        if (this.auftrag) {
            this.auftrag.benutzer_name = v;
        }
    }
    public new_benutzer_name: string;
    public get probestellennr(): string {
        return this.auftrag && this.auftrag.probestellennr;
    }
    public set probestellennr(v: string) {
        if (this.auftrag) {
            this.auftrag.probestellennr = v;
        }
    }
    public get gueltigvon(): string {
        return this.auftrag && this.getDateInDatetime(this.auftrag.gueltigvon);
    }
    public set gueltigvon(v: string) {
        if (this.auftrag) {
            this.auftrag.gueltigvon = this.setDateInDatetime(this.auftrag.gueltigvon, v);
        }
    }
    public get gueltigbis(): string {
        return this.auftrag && this.getDateInDatetime(this.auftrag.gueltigbis);
    }
    public set gueltigbis(v: string) {
        if (this.auftrag) {
            this.auftrag.gueltigbis = this.setDateInDatetime(this.auftrag.gueltigbis, v);
        }
    }
    public get bezeichnung(): string {
        return this.auftrag && this.auftrag.bezeichnung;
    }
    public set bezeichnung(v: string) {
        if (this.auftrag) {
            this.auftrag.bezeichnung = v;
        }
    }
    public get anlass(): string {
        return this.auftrag && this.auftrag.anlass;
    }
    public set anlass(v: string) {
        if (this.auftrag) {
            this.auftrag.anlass = v;
        }
    }
    public readonly benutzerUrlTemplate = `${this.dataService.webServiceApiUrl}benutzer?term={term}&limit={limit}&format=application/json&X-Auth-Token=${this.userService.token}`;
    public readonly probestellenUrlTemplate = `${this.dataService.webServiceApiUrl}gebietsbezug/probestellennr?term={term}&limit={limit}&format=application/json&X-Auth-Token=${this.userService.token}`;
    constructor(public modalService: ModalService, public dataService: DataService, public userService: UserService, public mapService: MapService) {
        this.topmargin = "24";
    }
    public ngOnInit() {
        this.updateAuftrag();
    }
    public async updateAuftragIfIdChanged() {
        if (this.updatingAuftrag) {
            await this.updatingAuftrag.then(() => this.updateAuftragIfIdChanged());
        }
        else if ((this.auftrag$ && this.auftrag$.id) !== this.modal.data.auftragToBeLoaded.id) {
            await this.updateAuftrag();
        }
        else {
            this.modal.data.loaded = 1;
        }
    }
    public updatingAuftrag: Promise<IIntragisBenutzerprobenahmestelle> = null;
    public async fetchAuftrag(id: number) {
        return this.dataService.get("auftragverwaltung", { deserialize: x => x as IIntragisBenutzerprobenahmestelle }, id.toFixed(0));
    }
    public async updateAuftrag() {
        this.auftrag = null;
        this.unchangedAuftrag = null;
        this.modal.data.loaded = 0;
        const idToLoad = this.modal.data.auftragToBeLoaded.id;
        let item: IIntragisBenutzerprobenahmestelle;
        if (idToLoad === 0) {
            item = { id: 0 } as IIntragisBenutzerprobenahmestelle;
            this.mode = "Create";
        }
        else {
            this.updatingAuftrag = this.fetchAuftrag(idToLoad);
            item = await this.updatingAuftrag;
            this.mode = null;
        }
        this.auftrag = IntragisBenutzerprobenahmestelle.deserialize(item);
        this.unchangedAuftrag = IntragisBenutzerprobenahmestelle.deserialize(item);
        this.updatingAuftrag = null;
        this.modal.data.loaded = 1;
    }
    public getDateInDatetime(ts: number): string {
        const d = ts && new Date(ts);
        return !d || d.getUTCFullYear() === 1970 ? "" : ((d.getUTCDate() < 10
            ? "0" + d.getUTCDate()
            : d.getUTCDate()) + "." + ((d.getUTCMonth() + 1) < 10
            ? "0" + (d.getUTCMonth() + 1)
            : (d.getUTCMonth() + 1)) + "." + d.getUTCFullYear());
    }
    public setDateInDatetime(ts: number, value: string) {
        if (value
            && value.split(".").length === 3
            && parseInt(value.split(".")[2], 10) < 100) {
            value = value.split(".")[0]
                + "." + value.split(".")[1]
                + "." + ((parseInt(value.split(".")[2], 10) < 70
                ? 2000
                : 1900)
                + parseInt(value.split(".")[2], 10)).toString();
        }
        const datetime = (value
            && value.split(".").length === 3
            && parseInt(value.split(".")[0], 10) <= 31
            && parseInt(value.split(".")[0], 10) >= 1
            && parseInt(value.split(".")[1], 10) <= 12
            && parseInt(value.split(".")[1], 10) >= 1
            && parseInt(value.split(".")[2], 10) >= 1900)
            ? Date.UTC(parseInt(value.split(".")[2], 10), parseInt(value.split(".")[1], 10) - 1, parseInt(value.split(".")[0], 10))
            : null;
        return datetime;
    }
    public onClickEdit(e: Event) {
        this.mode = "Edit";
    }
    public onClickCancel(e: Event) {
        if (!this.modal.data.saving) {
            this.warningText = "";
            this.warningIsShown = "false";
            if (this.mode === "Create") {
                this.modal.data.activeTab = "auftragverwaltung";
                this.modal.data.auftragToBeLoaded = null;
            }
            else {
                this.mode = null;
                this.auftrag = new IntragisBenutzerprobenahmestelle(this.unchangedAuftrag);
            }
        }
    }
    public onClickSubmit(e, mode, ignoreWarning?: boolean) {
        this.warningStack.forEach((element, index) => {
            if (element.target === "submit") {
                this.warningStack.splice(index, 1);
            }
        });
        this.validate();
        if (ignoreWarning === true || !this.warningStack.length) {
            if (!this.modal.data.saving) {
                this.modal.data.saving = true;
                const { unchangedAuftrag } = this;
                const postData = new IntragisBenutzerprobenahmestelle(this.auftrag);
                (this.mode === "Create"
                    ? this.dataService.create<{
                        resultset: IIntragisBenutzerprobenahmestelle;
                    }>("auftragverwaltung", postData)
                        .then(response => {
                        this.unchangedAuftrag = new IntragisBenutzerprobenahmestelle(response.body.resultset[0]);
                        this.auftrag = new IntragisBenutzerprobenahmestelle(this.unchangedAuftrag);
                        this.modal.data.auftragToBeLoaded = new IntragisBenutzerprobenahmestelle(this.unchangedAuftrag);
                        this.mode = "Edit";
                        return this.unchangedAuftrag;
                    })
                    : this.dataService.save("auftragverwaltung", postData)
                        .then(() => {
                        this.unchangedAuftrag = new IntragisBenutzerprobenahmestelle(this.auftrag);
                        if (this.new_benutzer_name != null && this.new_benutzer_name.length > 0) {
                            this.unchangedAuftrag.benutzer_name = this.new_benutzer_name;
                        }
                        return this.unchangedAuftrag;
                    }))
                    .then((auftrag) => {
                    const auftragList: ModalWindowTabAuftragverwaltung = this.modal.data.auftragList;
                    auftragList.insertOrReplaceAuftrag(auftrag);
                })
                    .catch((e) => {
                    this.unchangedAuftrag = unchangedAuftrag;
                    this.warningTarget = null;
                    this.warningText = this.mode === "Create"
                        ? "Der neue Auftrag konnte nicht erfolgreich angelegt werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen ein erneutes Speichern."
                        : "Der bearbeitete Auftrag konnte nicht erfolgreich gespeichert werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen ein erneutes Speichern.";
                    this.warningIsShown = "true";
                })
                    .then(() => {
                    this.modal.data.saving = false;
                });
            }
        }
        else {
            this.warningTarget = this.warningStack[0].target;
            this.warningText = this.warningStack[0].message;
            this.warningIsShown = "true";
        }
    }
    public validate() {
        this.warningStack.forEach((element, index) => {
            if (element.target === "validator") {
                this.warningStack.splice(index, 1);
            }
        });
        const revisedAuftrag = this.revise(this.auftrag);
        let validationResult = Array<{
            validationText: string;
            warningText: string;
        }>();
        if (isNullish(revisedAuftrag.gueltigvon)
            || !Number.isInteger(revisedAuftrag.gueltigvon)
            || !(new Date(revisedAuftrag.gueltigvon))
            || isZero(revisedAuftrag.gueltigvon - (revisedAuftrag.gueltigvon % 86400000))) {
            validationResult.push({
                validationText: "Fehlende oder ungültige Angabe des Gültig-von-Datum.",
                warningText: "Tragen Sie ein Datum der Form \"TT.MM.JJJJ\" im Feld \"Gültig von\" ein.",
            });
        }
        if (isNullish(revisedAuftrag.gueltigbis)
            || !Number.isInteger(revisedAuftrag.gueltigbis)
            || !(new Date(revisedAuftrag.gueltigbis))
            || isZero(revisedAuftrag.gueltigbis - (revisedAuftrag.gueltigbis % 86400000))) {
            validationResult.push({
                validationText: "Fehlende oder ungültige Angabe des Gültig-bis-Datum.",
                warningText: "Tragen Sie ein Datum der Form \"TT.MM.JJJJ\" im Feld \"Gültig bis\" ein.",
            });
        }
        if (isNullish(revisedAuftrag.benutzer_id)
            || !Number.isInteger(revisedAuftrag.benutzer_id)
            || revisedAuftrag.benutzer_id < 1) {
            validationResult.push({
                validationText: "Fehlende Angabe des Benutzer.",
                warningText: "Wählen Sie einen Benutzer aus.",
            });
        }
        if (isNullish(revisedAuftrag.probestellennr)
            || isEmpty(revisedAuftrag.probestellennr)) {
            validationResult.push({
                validationText: "Fehlende Angabe der Probestellen-Nummer.",
                warningText: "Wählen Sie eine Probenahmestelle.",
            });
        }
        if (validationResult && validationResult.length) {
            const warningText = validationResult[0].warningText;
            this.warningSummary = validationResult[0].validationText;
            this.warningText = warningText;
            this.warningStack.push({ message: warningText, target: "validator" });
            this.warningIsShown = "true";
        }
    }
    public revise(auftrag: IntragisBenutzerprobenahmestelle): IntragisBenutzerprobenahmestelle {
        return auftrag;
    }
    public onClickDelete(e: Event) {
        this.warningStack.forEach((element, index) => {
            if (element.target === "delete") {
                this.warningStack.splice(index, 1);
            }
        });
        if (!this.modal.data.saving) {
            this.modal.data.saving = true;
            const { unchangedAuftrag } = this;
            this.dataService.delete("auftragverwaltung", String(unchangedAuftrag.id))
                .then(() => {
                this.unchangedAuftrag = null;
                this.auftrag = null;
                const auftragList: ModalWindowTabAuftragverwaltung = this.modal.data.auftragList;
                auftragList.removeAuftrag(unchangedAuftrag);
                const modalId = this.modal.id;
                const data = this.modalService.getModalOption(modalId, "data") || {};
                data.activeTab = "auftragverwaltung";
                data.auftragToBeLoaded = null;
                this.modalService.setModalOption(modalId, "data", data);
            })
                .catch((e) => {
                this.warningTarget = null;
                this.warningText = "Der bearbeitete Auftrag konnte nicht erfolgreich gelöscht werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen ein erneutes Löschen.";
                this.warningIsShown = "true";
            })
                .then(setTimeout.bind(undefined, () => {
                this.modal.data.saving = false;
            }, 0));
        }
    }
}
