import { EventEmitter, Injectable } from "@angular/core";
import { UWMapEditorService } from "ng-uw-map-leaflet";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/interface";

/**
 * Durch diesen Dienst können andere Teile der Anwendung bearbeitbare Teile der Karte manipulieren.
 */
@Injectable({
    providedIn: 'root'
})
export default class MapEditorService extends UWMapEditorService {
    public modifyProbenahmestelle: EventEmitter<FischinfoProbenahmestelle> = new EventEmitter();
    public cancelProbenahmestelle: EventEmitter<FischinfoProbenahmestelle> = new EventEmitter();
}
