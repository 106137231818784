import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoAuftragprobenahmestelle from "./interface";
import FischinfoAuftrag from "./../../fischinfo/auftrag/model";
import FischinfoProbenahmestelle from "./../../fischinfo/probenahmestelle/model";
@fn.DataModel
export default class FischinfoAuftragprobenahmestelle extends DataModel implements IFischinfoAuftragprobenahmestelle {
    constructor(obj?: object | IFischinfoAuftragprobenahmestelle) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoAuftragprobenahmestelle): FischinfoAuftragprobenahmestelle {
        return new FischinfoAuftragprobenahmestelle(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoAuftragprobenahmestelle): FischinfoAuftragprobenahmestelle {
        Object.assign(this, obj);
        obj && obj["fischinfoAuftragByAuftrag_id"] && (this.fischinfoAuftragByAuftrag_id = FischinfoAuftrag.deserialize(obj["fischinfoAuftragByAuftrag_id"]));
        obj && obj["fischinfoProbenahmestelleByProbenahmestelle_id"] && (this.fischinfoProbenahmestelleByProbenahmestelle_id = FischinfoProbenahmestelle.deserialize(obj["fischinfoProbenahmestelleByProbenahmestelle_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "auftrag_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public auftrag_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "probenahmestelle_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public probenahmestelle_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoAuftrag}
     */
    @fn.DataMember({ name: "fischinfoAuftragByAuftrag_id", type: FischinfoAuftrag, nullable: true, memberType: "JOIN", joinProperty: "auftrag_id" })
    public fischinfoAuftragByAuftrag_id?: FischinfoAuftrag;

    /**
     * @type {FischinfoProbenahmestelle}
     */
    @fn.DataMember({ name: "fischinfoProbenahmestelleByProbenahmestelle_id", type: FischinfoProbenahmestelle, nullable: true, memberType: "JOIN", joinProperty: "probenahmestelle_id" })
    public fischinfoProbenahmestelleByProbenahmestelle_id?: FischinfoProbenahmestelle;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
