import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import ModalService from "./../modal/service";
import DataService from "./../data/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FormFischinfoUntersuchungService from "../form-fischinfo-untersuchung/service";
import Pulse from "./pulse";
import { firstValueFrom } from "rxjs";
interface CalculationStatus {
    running: boolean;
    currentStep: number;
    totalSteps: number;
    failed: boolean;
}
/**
 * Mit dieser Komponente kann der Nutzer für die ausgewählten Probestellen/Untersuchungen die FIBS-Bewertungen berechnen.
 */
@Component({
    selector: "map-panel-fibs",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class MapPanelFibs implements OnDestroy, OnInit {
    @Input()
    public modal: any;
    @Input()
    public topmargin: string = "24";
    constructor(public modalService: ModalService, public dataService: DataService, public http: HttpClient, public formFischinfoUntersuchungService: FormFischinfoUntersuchungService) {
    }
    public fibsVersion: Promise<string>;
    public fibsresultsGenerating = false;
    public fibsresultsProgress = 0.0;
    public fibsresultsPulse: Pulse = null;
    public ngOnInit() {
        this.fibsVersion = this.formFischinfoUntersuchungService
            .fibsVersion().then(ver => ["(", ver, ")"].join(""));
        this.checkCalculationStatus(true);
    }
    public ngOnDestroy() {
    }
    public get fibsresultsProgressInt() {
        return parseInt(this.fibsresultsProgress.toString(), 10).toString().replace(".", ",");
    }
    public get untersuchungCount() {
        let t = null;
        return (t = this.dataService.dataStore)
            && (t = t["untersuchung"])
            && (t = t.length)
            || 0;
    }
    public readonly getFibsresultsGeneratingFromCalculationStatus = (status: CalculationStatus) => status.running;
    public readonly getFibsresultsProgressFromCalculationStatus = ({ running, currentStep, totalSteps }: CalculationStatus) => (running ? totalSteps && (currentStep * 100 / totalSteps) : 0);
    public async fetchCalculationStatus() {
        return this.dataService.get<CalculationStatus>("fibscalculator", { deserialize: x => x }, "calculation");
    }
    public needsContinuousCalculationStatusChecking(status: CalculationStatus) {
        return status.running && !this.fibsresultsPulse;
    }
    public async continueCalculationStatusChecking() {
        const pulse = this.fibsresultsPulse = new Pulse(4000);
        pulse.start();
        while (pulse.isActive) {
            await pulse.next();
            const status = await this.fetchCalculationStatus();
            this.processCalculationStatus(status);
            if (!status.running) {
                pulse.stop();
                if (this.fibsresultsPulse === pulse) {
                    this.fibsresultsPulse = null;
                }
            }
        }
    }
    public async checkCalculationStatus(keepChecking: boolean) {
        const status = await this.fetchCalculationStatus();
        this.processCalculationStatus(status);
        if (this.needsContinuousCalculationStatusChecking(status) && keepChecking) {
            await this.continueCalculationStatusChecking();
        }
    }
    public indicateFirstCalculationActivity(status: CalculationStatus) {
        if (status.running && status.currentStep === 0 && status.totalSteps > 0) {
            const expectedStepsPerPulse = 6;
            const scaleFactor = 0.1 * 100;
            this.fibsresultsProgress = expectedStepsPerPulse * scaleFactor / status.totalSteps;
        }
    }
    public processCalculationStatus(status: CalculationStatus) {
        const isGenerating = this.getFibsresultsGeneratingFromCalculationStatus(status);
        if (isGenerating) {
            this.fibsresultsGenerating = isGenerating;
            this.fibsresultsProgress = this.getFibsresultsProgressFromCalculationStatus(status);
        }
        else {
            if (!status.failed) {
                this.fibsresultsProgress = 100.0;
            }
            setTimeout(() => {
                this.fibsresultsGenerating = false;
                this.fibsresultsProgress = 0.0;
            }, 1000);
        }
    }
    public calculateFibsresults() {
        if (!this.fibsresultsGenerating) {
            const untersuchungList = (<FischinfoUntersuchung[]>this.dataService.dataStore["untersuchung"])
                .filter((o) => o != null)
                .map(u => u.id);
            if (untersuchungList && untersuchungList.length > 0) {
                this.fibsresultsGenerating = true;
                this.dataService.getLoginTokenPromise()
                    .then(token => firstValueFrom(this.http.post<{
                    resultset: [
                        CalculationStatus
                    ];
                }>(this.dataService.transcribeURL(this.dataService.webServiceApiListUrl, Object.assign({
                    folder: "fibscalculator",
                    limit: Number.MAX_SAFE_INTEGER,
                }, this.dataService.filter)), untersuchungList, { responseType: "json", headers: { "X-Auth-Token": token } }))
                    .then(x => x.resultset[0]))
                    .then(status => {
                    this.processCalculationStatus(status);
                    if (this.needsContinuousCalculationStatusChecking(status)) {
                        this.indicateFirstCalculationActivity(status);
                        return this.continueCalculationStatusChecking();
                    }
                });
            }
        }
    }
    public calcConfirmation: {
        proceed(): any;
        abort();
        any;
        promise: Promise<boolean>;
    } = null;
    public confirmCalculation() {
        if (this.fibsresultsGenerating || this.calcConfirmation) {
            return this.calcConfirmation && this.calcConfirmation.promise;
        }
        else {
            const lastly = <T>(p: Promise<T>, f: () => any) => p.then(f, f).then(() => p);
            const confirm = this.calcConfirmation = { proceed: null, abort: null, promise: null } as MapPanelFibs["calcConfirmation"];
            return lastly(confirm.promise = new Promise<boolean>((resolve, reject) => {
                confirm.proceed = () => resolve(true);
                confirm.abort = () => resolve(false);
            }), () => this.calcConfirmation = null).then(confirmed => (confirmed ? this.calculateFibsresults() : 0, confirmed));
        }
    }
}
