import { Component, Input, Output, EventEmitter } from "@angular/core";
/**
 * Diese Komponente stellt eine durch den Nutzer ausführbare Aktion dar.
 *
 * Wenn der Nutzer mit der Maus auf diese Komponente klickt, führt die Komponente die
 * verknüpfte Aktion aus. Diese Komponente gibt die konkrete Aktion nicht vor.
 * Andere Komponenten beschreiben die konkrete Aktion, die mit dieser Komponente verknüpft ist.
 *
 * Die Erscheinung dieser Komponente besteht aus einem farbigen Symbol (Icon) und einem
 * die Aktion beschreibenden Text (Wert).
 */
@Component({
    selector: "uw-inline-button",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class InlineButton {
    @Input()
    public iconClass: string;
    @Input()
    public title: string;
    @Input()
    public value: string;
    @Input()
    public stopPropagation: string = "true";
    @Output()
    public click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    constructor() { }
    public onClick(e: MouseEvent) {
        this.stopPropagation === "true" && e.stopPropagation();
        this.click.emit(e);
    }
}
