import { Component, Input } from "@angular/core";
import AppService from "./../app/service";
import DataService from "./../data/service";
import MapService from "./../map/service";
import MapViewService from "./../map-view/service";
/**
 * Diese Fußzeile zeigt die aktuelle Mausposition in der Karte an.
 *
 * Die Koordinaten des geographischen Punktes, über dem sich der Mauszeiger in der Karte befindet, werden vom MapService geholt und als Text angezeigt.
 * Diese Komponente zeigt desweiteren die Anzahl der geladenen Probenstellen/Untersuchungen an.
 */
@Component({
    selector: "uw-footer",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class Footer {
    public KoorE: string = "";
    public KoorN: string = "";
    constructor(public appService: AppService, public dataService: DataService, public mapService: MapService, public mapViewService: MapViewService) {
        this.mapService.mousehoverObservable.subscribe(event => {
            this.onMousehoverMap(event);
        });
    }
    public onMousehoverMap(e: any): boolean {
        const latLng = this.mapService.project(this.mapService.projection, "EPSG:25832", [e.latlng.lng, e.latlng.lat]);
        const formatLatLngStr = (c: string, i: number, a: string) => {
            return i && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
        };
        this.KoorE = "E " + latLng[0].toFixed(0).replace(/./g, formatLatLngStr);
        this.KoorN = "N " + latLng[1].toFixed(0).replace(/./g, formatLatLngStr);
        return true;
    }
}
