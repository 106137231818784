import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoStrukturelementehaeufigkeit from "./interface";
import FischinfoGewaesserzustand from "./../../fischinfo/gewaesserzustand/model";
import FischinfoHaeufigkeit from "./../../fischinfo/haeufigkeit/model";
import FischinfoStrukturelement from "./../../fischinfo/strukturelement/model";
@fn.DataModel
export default class FischinfoStrukturelementehaeufigkeit extends DataModel implements IFischinfoStrukturelementehaeufigkeit {
    constructor(obj?: object | IFischinfoStrukturelementehaeufigkeit) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoStrukturelementehaeufigkeit): FischinfoStrukturelementehaeufigkeit {
        return new FischinfoStrukturelementehaeufigkeit(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoStrukturelementehaeufigkeit): FischinfoStrukturelementehaeufigkeit {
        Object.assign(this, obj);
        obj && obj["fischinfoGewaesserzustandByGewaesserzustand_id"] && (this.fischinfoGewaesserzustandByGewaesserzustand_id = FischinfoGewaesserzustand.deserialize(obj["fischinfoGewaesserzustandByGewaesserzustand_id"]));
        obj && obj["fischinfoHaeufigkeitByHaeufigkeit_id"] && (this.fischinfoHaeufigkeitByHaeufigkeit_id = FischinfoHaeufigkeit.deserialize(obj["fischinfoHaeufigkeitByHaeufigkeit_id"]));
        obj && obj["fischinfoStrukturelementByStrukturelement_id"] && (this.fischinfoStrukturelementByStrukturelement_id = FischinfoStrukturelement.deserialize(obj["fischinfoStrukturelementByStrukturelement_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "gewaesserzustand_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public gewaesserzustand_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "haeufigkeit_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public haeufigkeit_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "strukturelement_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public strukturelement_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoGewaesserzustand}
     */
    @fn.DataMember({ name: "fischinfoGewaesserzustandByGewaesserzustand_id", type: FischinfoGewaesserzustand, nullable: true, memberType: "JOIN", joinProperty: "gewaesserzustand_id" })
    public fischinfoGewaesserzustandByGewaesserzustand_id?: FischinfoGewaesserzustand;

    /**
     * @type {FischinfoHaeufigkeit}
     */
    @fn.DataMember({ name: "fischinfoHaeufigkeitByHaeufigkeit_id", type: FischinfoHaeufigkeit, nullable: true, memberType: "JOIN", joinProperty: "haeufigkeit_id" })
    public fischinfoHaeufigkeitByHaeufigkeit_id?: FischinfoHaeufigkeit;

    /**
     * @type {FischinfoStrukturelement}
     */
    @fn.DataMember({ name: "fischinfoStrukturelementByStrukturelement_id", type: FischinfoStrukturelement, nullable: true, memberType: "JOIN", joinProperty: "strukturelement_id" })
    public fischinfoStrukturelementByStrukturelement_id?: FischinfoStrukturelement;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
