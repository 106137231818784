import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import UWButton from "./component";

@NgModule({
    declarations: [
        UWButton,
    ],
    exports: [
        UWButton,
    ],
    imports: [
        CommonModule,
    ],
})
export default class UWButtonModule { }
