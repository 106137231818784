import { Component } from "@angular/core";
import { UWMapEditor } from "ng-uw-map-leaflet";
import MapEditorService from "./service";
import MapService from "./../map/service";
import ModalService from "./../modal/service";
import DataService from "./../data/service";
import { LoginService as UWLoginService } from "ng-uw-login-service";
import UserService from "./../user/service";
import AppService from "./service";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import GridLayerService from "../map-gridlayer/service";
import { Subscription } from "rxjs";
/**
 * Mit Hilfe dieser Komponente ist es dem Nutzer möglich eigene Elemente in die Karte einzuzeichnen.
 *
 * Die zur Verfügung stehenden Werkzeuge können über Eingabeattribute beeinflusst werden.
 */
@Component({
    selector: "uw-map-editor",
    templateUrl: "./default.component.html",
    styleUrls: [
        "./base.component.less",
        "./default.component.less"
    ]
})
export default class MapEditor extends UWMapEditor {
    public subscriptions: Subscription[] = [];
    public probenahmestelle: FischinfoProbenahmestelle;
    constructor(public mapService: MapService, public modalService: ModalService, public mapEditorService: MapEditorService, public dataService: DataService, public loginService: UWLoginService, public appService: AppService, public userService: UserService, public gridLayerService: GridLayerService) {
        super(mapService, mapEditorService);
        const createEvent: (name: string) => Event = typeof Event === "function"
            ? name => new Event(name)
            : name => {
                const ev = document.createEvent("Event");
                ev.initEvent(name, false, false);
                return ev;
            };
        this.subscriptions.push(this.mapEditorService.modifyProbenahmestelle
            .subscribe((probenahmestelle: FischinfoProbenahmestelle) => {
                this.probenahmestelle = probenahmestelle;
                this.mapEditorService.toolbarIsOpen = true;
                if (probenahmestelle.id) { // Probenahmestelle nachbearbeiten
                    const the_geom: any = {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                geometry: { "type": "Point", "coordinates": [probenahmestelle.etrs89e, probenahmestelle.etrs89n] },
                                properties: {}
                            },
                        ]
                    };
                    this.draw({
                        name: "dataItem",
                        item: {
                            id: probenahmestelle.id,
                            bbox: null
                        },
                        the_geom: the_geom,
                        projection: "EPSG:25832",
                        style: {
                            radius: this.gridLayerService.iconScale[10],
                            color: "#31842e",
                            fillColor: "#31842e",
                            opacity: 1,
                            fill: true,
                            fillOpacity: 1,
                            weight: 1,
                        },
                        editable: true
                    });
                    this.mapService.zoomGeometry(<JSON>the_geom.features[0].geometry, "EPSG:25832");
                    this.setSelectMode();
                }
                else { // Probenahmestelle anlegen
                    this.clickOnAddPoint(createEvent("createProbenahmestelle"));
                }
            }));
        this.subscriptions.push(this.mapEditorService.cancelProbenahmestelle
            .subscribe(item => {
                this.cancel();
            }));
        this.subscriptions.push(this.mapEditorService.save
            .subscribe(item => {
                let modalId: string = this.modalService.getModalOption(this.probenahmestelle["modalId"], "data") // Schon bestehendes Fenster vorhanden und mitgefuehrt?
                    ? this.probenahmestelle["modalId"]
                    : null, postData = new FischinfoProbenahmestelle(JSON.parse(JSON.stringify(this.probenahmestelle)));
                this.cancel();
                if (!modalId) {
                    this.modalService.closeModals();
                    modalId = this.modalService.createModal({
                        data: {
                            probenahmestelleToBeLoaded: postData,
                            probenahmestelle: null
                        }
                    });
                }
                let data = this.modalService.getModalOption(modalId, "data") || {};
                data.saving = 1;
                data.activeTab = "probenahmestelle";
                this.modalService.setModalOption(modalId, "data", data);
                postData.etrs89e =
                    item
                    && item.geoJSON
                    && item.geoJSON.geometries
                    && item.geoJSON.geometries[0]
                    && item.geoJSON.geometries[0].coordinates
                    && item.geoJSON.geometries[0].type
                    &&
                    (item.geoJSON.geometries[0].type.toLowerCase() === "multipoint"
                        ? item.geoJSON.geometries[0].coordinates[0]
                        && item.geoJSON.geometries[0].coordinates[0][0]
                        : item.geoJSON.geometries[0].coordinates[0]);
                postData.etrs89n =
                    item
                    && item.geoJSON
                    && item.geoJSON.geometries
                    && item.geoJSON.geometries[0]
                    && item.geoJSON.geometries[0].coordinates
                    && item.geoJSON.geometries[0].type
                    &&
                    (item.geoJSON.geometries[0].type.toLowerCase() === "multipoint"
                        ? item.geoJSON.geometries[0].coordinates[0]
                        && item.geoJSON.geometries[0].coordinates[0][1]
                        : item.geoJSON.geometries[0].coordinates[1]);
                const finishFunc = () => {
                    setTimeout(() => {
                        let data = this.modalService.getModalOption(modalId, "data") || {};
                        data.loaded = 1;
                        data.saving = 0;
                        this.modalService.setModalOption(modalId, "data", data);
                    }, 0);
                };
                (postData.id
                    ? this.dataService.save("probenahmestelle", postData)
                        .then((response): Promise<FischinfoProbenahmestelle> => { return this.dataService.get<FischinfoProbenahmestelle>("probenahmestelle", FischinfoProbenahmestelle, String(postData.id), null, null, false); })
                    : this.dataService.create("probenahmestelle", postData)
                        .then((response): FischinfoProbenahmestelle => { return this.probenahmestelle = new FischinfoProbenahmestelle(response.body["resultset"][0]); }))
                    .then((probenahmestelle: FischinfoProbenahmestelle): void => {
                        let data = this.modalService.getModalOption(modalId, "data") || {};
                        probenahmestelle["setEditMode"] = true;
                        data.probenahmestelle = probenahmestelle;
                        data.probenahmestelleToBeLoaded = probenahmestelle;
                        this.modalService.setModalOption(modalId, "data", data);
                        this.dataService.loadDataStore();
                    })
                    .then(finishFunc)
                    .catch((e) => {
                        console.log(e);
                        finishFunc();
                    });
            }));
    }
    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            !subscription.closed && subscription.unsubscribe();
        });
    }
    public toggleToolbar() {
        this.mapEditorService.toolbarIsOpen = !this.mapEditorService.toolbarIsOpen;
        !this.mapEditorService.toolbarIsOpen && this.unset();
        this.checkDrawingState();
    }
    public clickOnProbenahmestelleAnFliessgewaesser(e: Event) {
        if (this.mapEditorService.isEditable) {
            this.redrawFeatureLayer();
            const geom = <JSON>this.layerGroupAsGeoJSON(this.mapEditorService.layerName, "EPSG:25832") as any, etrs89e = geom
                && geom.geometries
                && geom.geometries[0]
                && geom.geometries[0].coordinates
                && geom.geometries[0].type
                &&
                (geom.geometries[0].type.toLowerCase() === "multipoint"
                    ? geom.geometries[0].coordinates[0]
                    && geom.geometries[0].coordinates[0][0]
                    : geom.geometries[0].coordinates[0]), etrs89n = geom
                        && geom.geometries
                        && geom.geometries[0]
                        && geom.geometries[0].coordinates
                        && geom.geometries[0].type
                        &&
                        (geom.geometries[0].type.toLowerCase() === "multipoint"
                            ? geom.geometries[0].coordinates[0]
                            && geom.geometries[0].coordinates[0][1]
                            : geom.geometries[0].coordinates[1]);
            if (etrs89e && etrs89n) {
                this.dataService.save("probenahmestelle", new FischinfoProbenahmestelle({
                    id: 0,
                    etrs89e: etrs89e,
                    etrs89n: etrs89n
                }), null, this.dataService.webServiceApiUrl + "probenahmestelle/snap?limit=1&format=application/json").then((response) => {
                    if (response
                        && response.resultset
                        && response.resultset[0]
                        && response.resultset[0].etrs89e
                        && response.resultset[0].etrs89n) {
                        this.probenahmestelle.etrs89e = response.resultset[0].etrs89e;
                        this.probenahmestelle.etrs89n = response.resultset[0].etrs89n;
                        const the_geom: any = {
                            type: "FeatureCollection",
                            features: [
                                {
                                    type: "Feature",
                                    geometry: { "type": "Point", "coordinates": [response.resultset[0].etrs89e, response.resultset[0].etrs89n] },
                                    properties: {}
                                },
                            ]
                        };
                        this.draw({
                            name: "dataItem",
                            item: {
                                id: this.probenahmestelle.id,
                                bbox: null
                            },
                            the_geom: the_geom,
                            projection: "EPSG:25832",
                            style: {
                                radius: this.gridLayerService.iconScale[10],
                                color: "#31842e",
                                fillColor: "#31842e",
                                opacity: 1,
                                fill: true,
                                fillOpacity: 1,
                                weight: 1,
                            },
                            editable: true
                        });
                        this.setSelectMode();
                    }
                });
            }
        }
    }
    public clickOnAddPoint(e: Event) {
        if (this.mapEditorService.isEditable
            || e.type === "createProbenahmestelle") {
            this.draw({
                name: "dataItem",
                item: {
                    id: 0,
                    bbox: null
                },
                the_geom: <JSON>{},
                projection: "EPSG:25832",
                style: {
                    radius: this.gridLayerService.iconScale[this.mapService.map.getZoom()],
                    color: "#31842e",
                    fillColor: "#31842e",
                    opacity: 1,
                    fill: true,
                    fillOpacity: 1,
                    weight: 1,
                },
                editable: true
            });
            this.toggleEdit('drawPoint');
        }
    }
    public onClickSave() {
        this.mapEditorService.isEditable && this.save();
    }
    public onClickCancel() {
        if (this.editMode === 'measurePolyline') {
            this.cancelMeasurement();
            this.editMode = null;
            this.mapEditorService.isEditable && this.setSelectMode();
        }
        else {
            this.mapEditorService.isEditable && this.cancel();
        }
    }
    public cancel() {
        this.probenahmestelle = null;
        this.draw({
            name: "dataItem",
            item: {},
            the_geom: <JSON>{},
            projection: "EPSG:25832",
            style: null,
            editable: false
        });
    }
    public drawGeometry(name: string, geometry?: any, srs?: any, drawingModeFlag?: boolean, touchModeFlag?: boolean, selectModeFlag?: boolean, layerAttributes?: any): void {
        if (geometry && geometry["type"]) {
            let enableEdit = this.mapEditorService.isEditable && this.mapEditorService.isEditing
                ? true
                : false;
            let coordinates = [], vector = null, style = {};
            switch (geometry["type"]) {
                case "Point":
                    coordinates = L.Proj.proj4(srs, this.mapService.projections["EPSG:4326"], geometry["coordinates"]);
                    if (enableEdit) {
                        if (drawingModeFlag || selectModeFlag) {
                            style = Object.assign({
                                icon: L.divIcon({ className: "leaflet-div-icon" })
                            }, this.mapService.layers[name]["style"]);
                            if (drawingModeFlag) {
                                style["historyDrawn"] = true;
                            }
                            vector = L.marker([coordinates[1], coordinates[0]], style);
                            this.mapService.layers[name]["addLayer"](vector);
                            vector["enableEdit"]();
                        }
                        else {
                            style = Object.assign({
                                interactive: true,
                                radius: 5,
                                color: this.mapService.linkColor,
                                weight: 2,
                                opacity: 0.7,
                                fill: true,
                                fillOpacity: 0.01
                            }, this.mapService.layers[name]["style"]);
                            vector = L.circleMarker([coordinates[1], coordinates[0]], style);
                            this.mapService.layers[name]["addLayer"](vector);
                        }
                    }
                    else {
                        style = Object.assign({
                            interactive: true,
                            radius: 6,
                            color: this.mapService.linkColor,
                            weight: 2,
                            opacity: 0.7,
                            fill: true,
                            fillOpacity: 0.01
                        }, this.mapService.layers[name]["style"]);
                        vector = L.circleMarker([coordinates[1], coordinates[0]], style);
                        this.mapService.layers[name]["addLayer"](vector);
                    }
                    if (vector) {
                        if (layerAttributes) {
                            Object.keys(layerAttributes).forEach(key => {
                                vector[key] = layerAttributes[key];
                            });
                        }
                        vector.on("click", event => {
                            event.target["_clickSelected"] = true;
                            this.mapService.map.fireEvent("editable:feature:selected", event.target);
                        });
                    }
                    break;
                case "MultiPoint":
                    let io = 0, iox = geometry["indexOfGeometryInDrawingMode"], ioc = geometry["indexOfGeometryInSelectionMode"];
                    for (let coordinates of geometry["coordinates"]) {
                        this.drawGeometry(name, { type: "Point", coordinates: coordinates }, srs, iox === io, touchModeFlag, ioc === io, layerAttributes);
                        ++io;
                    }
                    break;
                default:
            }
        }
    }
}
