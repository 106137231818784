import { HostListener, Directive, ElementRef, OnDestroy, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { fromEvent } from "rxjs";

const TranslateEventName = "uwtranslateneeded";

/**
 * Diese Direktive markiert ein Element als ziehbar,
 * 
 * Wenn der Nutzer auf das markierte Element klickt, beginnt das Ziehen und die Komponente löst Nachrichten im HTML-Baum aus, um anzuzeigen, dass ein Empfänger das Element verschieben sollte. Wenn der Nutzer den Klick beendet, dann stellt die Komponente das Senden der Nachrichten bis zum nächsten Klick ein.
 */
@Directive({
  selector: "[uw-draggable]"
})

export default class UWDraggableDirective implements OnDestroy, OnInit {

  protected isDragging: boolean = false;
  protected dx: number = 0;
  protected dy: number = 0;

  protected onMousemoveSubscription: any;
  protected onMouseupSubscription: any;

  constructor(
        @Inject(DOCUMENT) protected document: any
      , protected elementRef: ElementRef
    ) {
  }

  public ngOnInit(): void {
    this.onMousemoveSubscription = fromEvent<MouseEvent>(this.document, "mousemove")
      .subscribe((event: MouseEvent) => {
        if (this.isDragging) {
          this.translateTarget(event.clientX, event.clientY);
          event.preventDefault();
        }
      }
    );

    this.onMouseupSubscription = fromEvent<MouseEvent>(this.document, "mouseup")
      .subscribe((event) => { this.isDragging = false; }
    );
  }

  public ngOnDestroy(): void {
    this.onMousemoveSubscription.unsubscribe();
    this.onMouseupSubscription.unsubscribe();
  }

  @HostListener("mousedown", ["$event"]) public mousedownOnElement(event: MouseEvent): boolean {
    if (event.button !== 0) return; // only left mouse down triggers
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    this.dx = event.clientX - rect.left;
    this.dy = event.clientY - rect.top;
    this.isDragging = true;
    return true;
  };

  public translateTarget(x: number, y: number): void {
    if (!x || !y) return;
    const target = this.elementRef.nativeElement.parentElement;
    const offset = target.offsetParent.getBoundingClientRect();
    x = x - offset.left - this.dx | 0;
    y = y - offset.top - this.dy | 0;
    const ev = this.createTranslateNeededEvent({ left: x, top: y });
    target.dispatchEvent(ev);
  }

  protected createTranslateNeededEvent (position): any {
    if (typeof CustomEvent === "function") {
      return new CustomEvent(TranslateEventName
      , { bubbles: false
        , cancelable: true
        , detail: position
        }
      );
    } else {
      const ev = this.document.createEvent("CustomEvent");
      ev.initCustomEvent(TranslateEventName, false, true, position);
      return ev;
    }
  }

}
