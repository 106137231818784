import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoLaengenklassenbefischungergebnis from "./interface";
import FischinfoBefischung from "./../../fischinfo/befischung/model";
@fn.DataModel
export default class FischinfoLaengenklassenbefischungergebnis extends DataModel implements IFischinfoLaengenklassenbefischungergebnis {
    constructor(obj?: object | IFischinfoLaengenklassenbefischungergebnis) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoLaengenklassenbefischungergebnis): FischinfoLaengenklassenbefischungergebnis {
        return new FischinfoLaengenklassenbefischungergebnis(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoLaengenklassenbefischungergebnis): FischinfoLaengenklassenbefischungergebnis {
        Object.assign(this, obj);
        obj && obj["fischinfoBefischungByBefischung_id"] && (this.fischinfoBefischungByBefischung_id = FischinfoBefischung.deserialize(obj["fischinfoBefischungByBefischung_id"]));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_0_10", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_0_10?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_0_5", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_0_5?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_10_15", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_10_15?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_10_20", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_10_20?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_15_20", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_15_20?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_20_25", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_20_25?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_20_30", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_20_30?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_25_30", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_25_30?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_30_40", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_30_40?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_40_50", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_40_50?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_50_60", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_50_60?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_5_10", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_5_10?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_60_70", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_60_70?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl_70", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahl_70?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahljungfische", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public anzahljungfische?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "art_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public art_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "befischung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public befischung_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoBefischung}
     */
    @fn.DataMember({ name: "fischinfoBefischungByBefischung_id", type: FischinfoBefischung, nullable: true, memberType: "JOIN", joinProperty: "befischung_id" })
    public fischinfoBefischungByBefischung_id?: FischinfoBefischung;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
