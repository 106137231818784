<div class="row" style="padding-top:10px;">
    <div class="col-xs-15">
        <label *ngIf="!readonly">Geben Sie Länge und Gewicht der einzelnen Individuen ein</label>
        <label *ngIf="readonly">Länge und Gewicht einzelner Individuen</label>
    </div>
</div>

<div class="row" style="padding-top:10px;">
    <div class="col-xs-15">
        <table class="table table-striped detailErgebnisList inherit-inputs">
            <thead>
                <tr>
                    <th title="Anzahl">Anzahl</th>
                    <th
                        *ngIf="artGruppe==='Krebse'"
                        [style.width.%]="40"
                        title="Grössse (cm)"
                    >Grössse (cm)</th>
                    <th
                        *ngIf="artGruppe!=='Krebse'"
                        [style.width.%]="40"
                        title="Länge (cm)"
                    >Länge (cm)</th>
                    <th [attr.colspan]="readonly ? 1 : 2" [style.width.%]="40" title="Gewicht (g)">Gewicht (g)</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let u of detailErgebnisList"
                    form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-detail-individuum
                    [modalId]="modalId"
                    [untersuchung]="untersuchung"
                    [readonly]="readonly"
                    [artId]="artId"
                    [artGruppe]="artGruppe"
                    [detailindividuumErgebnis]="u"
                    (changed)="changed.emit(true)"
                ></tr>
                <tr
                    *ngIf="readonly && !detailErgebnisList.length"
                    form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-detail-individuum
                    [modalId]="modalId"
                    [untersuchung]="untersuchung"
                    [readonly]="readonly"
                    [artId]="artId"
                    [artGruppe]="artGruppe"
                ></tr>
            </tbody>
        </table>
        <uw-inline-button
            *ngIf="!readonly && firstIsSet"
            iconClass="fa fa-plus-circle"
            title="Weitere Individuen hinzufügen"
            value="Weitere Individuen hinzufügen"
            (click)="addModel($event)"
        ></uw-inline-button>
    </div>
</div>

<div
    class="row"
    *ngIf="artGruppe==='Fische'"
    style="padding-top:10px;"
>
    <div class="col-xs-15">
        <label>Anzahl der diesjährigen Jungfische (0+) an den gezählten Tieren</label>
    </div>
</div>
<div
    class="row"
    *ngIf="artGruppe==='Fische'"
>
    <div class="col-sm-3">
        <uw-textinput
            [(value)]="anzahljungfische"
            [disabled]="readonly"
        ></uw-textinput>
    </div>
</div>
