import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoFoto from "./interface";
import FischinfoDokument from "./../../fischinfo/dokument/model";
import FischinfoUntersuchung from "./../../fischinfo/untersuchung/model";
@fn.DataModel
export default class FischinfoFoto extends DataModel implements IFischinfoFoto {
    constructor(obj?: object | IFischinfoFoto) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoFoto): FischinfoFoto {
        return new FischinfoFoto(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoFoto): FischinfoFoto {
        Object.assign(this, obj);
        obj && obj["fischinfoDokumentByDokument_id"] && (this.fischinfoDokumentByDokument_id = FischinfoDokument.deserialize(obj["fischinfoDokumentByDokument_id"]));
        obj && obj["fischinfoUntersuchungListByForeignFoto_id"] && obj["fischinfoUntersuchungListByForeignFoto_id"].length && (this.fischinfoUntersuchungListByForeignFoto_id = obj["fischinfoUntersuchungListByForeignFoto_id"].map(FischinfoUntersuchung.deserialize));
        return this;
    }

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "dokument_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public dokument_id?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "format", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public format?: string;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoDokument}
     */
    @fn.DataMember({ name: "fischinfoDokumentByDokument_id", type: FischinfoDokument, nullable: true, memberType: "JOIN", joinProperty: "dokument_id" })
    public fischinfoDokumentByDokument_id?: FischinfoDokument;

    /**
     * @type {Array<FischinfoUntersuchung>}
     */
    @fn.DataMember({ name: "fischinfoUntersuchungListByForeignFoto_id", type: [FischinfoUntersuchung], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoUntersuchungListByForeignFoto_id?: Array<FischinfoUntersuchung>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
