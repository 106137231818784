import { Component, Input, ViewChild, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import DataService from "../data/service";
import FischinfoFischart from "../../models/fischinfo/fischart/model";
import UWAccordion from "../accordion/directive";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoFfhbewertung from "../../models/fischinfo/ffhbewertung/model";
/**
 * Mit dieser Komponente kann der Nutzer die FFH-Bewertung einer Untersuchung ansehen und ggf. bearbeiten.
 *
 * Diese Komponente umfasst nur Informationen der FFH-Bewertung einer Untersuchung.
 * Der Nutzer kann für jede in der FFH-Bewertung enthaltene Art die Bewertung einzeln ansehen und bearbeiten.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-ffhbewertung",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungFfhbewertung implements OnInit {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @ViewChild(UWAccordion, { static: false })
    accordion;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public _ffhArtenList: any[];
    public get ffhArtenList() {
        return this._ffhArtenList;
    }
    public _labelList: any[];
    public get labelList() {
        return this._labelList;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
    ngOnInit() {
        this.refreshffhArtenList();
    }
    public onChanged(e) {
        !this.readonly && this.getNormalizedffhArtenList()
            .then((list) => {
            this._labelList = this._labelList.map(x => list.find(y => y.id === x.id));
        });
    }
    public refreshffhArtenList() {
        this.getNormalizedffhArtenList()
            .then((list) => {
            if (!this.readonly) {
                this.sortField = { bewertung: "asc" };
            }
            list = this.sort(list, this.readonly ? "art" : "bewertung", this.readonly ? "text" : "text");
            this._labelList = list;
            this._ffhArtenList = list;
        });
    }
    public isSorting: boolean;
    public sortField: any = {};
    public sort(list: any, column: string, type: string): any[] {
        let _sort_asc = function (a, b) {
            return a[column] === b[column]
                ? ((a.bewertung || "") + "zz" + a.art.toLowerCase().trim()).toLowerCase().trim().localeCompare(((b.bewertung || "") + "zz" + b.art.toLowerCase().trim()).toLowerCase().trim())
                : +(a[column] > b[column]) || -1;
        };
        let _sort_desc = function (a, b) {
            return a[column] === b[column]
                ? ((b.bewertung || "") + "aa" + b.art.toLowerCase().trim()).toLowerCase().trim().localeCompare(((a.bewertung || "") + "aa" + a.art.toLowerCase().trim()).toLowerCase().trim())
                : +(a[column] < b[column]) || -1;
        };
        let _sort_str_asc = function (a, b) {
            return a[column] === b[column]
                ? ((a.bewertung || "") + "zz" + a.art.toLowerCase().trim()).toLowerCase().trim().localeCompare(((b.bewertung || "") + "zz" + b.art.toLowerCase().trim()).toLowerCase().trim())
                : (a[column]).toString().toLowerCase().trim().localeCompare((b[column]).toLowerCase().trim());
        };
        let _sort_str_desc = function (a, b) {
            return a[column] === b[column]
                ? ((b.bewertung || "") + "aa" + b.art.toLowerCase().trim()).toLowerCase().trim().localeCompare(((a.bewertung || "") + "aa" + a.art.toLowerCase().trim()).toLowerCase().trim())
                : (b[column]).toString().toLowerCase().trim().localeCompare((a[column]).toLowerCase().trim());
        };
        this.sortField = { [column]: this.sortField[column] };
        this.sortField[column] =
            type === "text"
                ? this.sortField[column] === "asc"
                    ? "desc"
                    : "asc"
                : this.sortField[column] === "desc"
                    ? "asc"
                    : "desc";
        return (type === "text")
            ? list.sort(this.sortField[column] === "desc" ? _sort_str_desc : _sort_str_asc)
            : list.sort(this.sortField[column] === "desc" ? _sort_desc : _sort_asc);
    }
    public async onClickSortColumn(column: string, type: string) {
        this.isSorting = true;
        const list = this.sort(await this.getNormalizedffhArtenList(), column, type);
        this._ffhArtenList = list;
        this._labelList = list;
        setTimeout(() => this.isSorting = false, 0);
    }
    public onClickRemoveFfhbewertung(e: Event, u: FischinfoFfhbewertung, paneIndex: number): boolean {
        paneIndex !== this.accordion.activePane && e.stopPropagation();
        this.formFischinfoUntersuchungService.modelUtil.remove<FischinfoFfhbewertung>(this.untersuchung, "FischinfoFfhbewertung", new FischinfoFfhbewertung({
            id: 0,
            art_id: u.id
        }));
        this.getNormalizedffhArtenList()
            .then((list) => {
            this._labelList = this._labelList.map(x => list.find(y => y.id === x.id));
        });
        return paneIndex === this.accordion.activePane;
    }
    public getNormalizedffhArtenList(): Promise<any[]> {
        let a = this.dataService
            .getReferenceTable("fischart", FischinfoFischart, "id");
        return Promise.all([a])
            .then(([refs]) => {
            let list: any[] = [];
            Object.keys(refs).forEach(key => {
                if (refs[key].ffh) {
                    let ffhWert = (this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
                        .fischinfoFfhbewertungListByForeignBefischung_id
                        || [])
                        .find(x => x.art_id === refs[key].id);
                    list.push({
                        id: refs[key].id,
                        art: refs[key].bezeichnung,
                        bewertung: ffhWert
                            ? (ffhWert.gesamtwertmanuell || "") + ((ffhWert.gesamtwert && ffhWert.gesamtwert !== ffhWert.gesamtwertmanuell)
                                ? " (" + ffhWert.gesamtwert + ")"
                                : "")
                            : ""
                    });
                }
            });
            return list
                .filter(x => !this.readonly || x.bewertung);
        });
    }
}
