import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFibsHoep from "./interface";
import FibsHoepreferenzanteil from "./../../fibs/hoepreferenzanteil/model";
import FibsHmwbnutzung from "./../../fibs/hmwbnutzung/model";
@fn.DataModel
export default class FibsHoep extends DataModel implements IFibsHoep {
    constructor(obj?: object | IFibsHoep) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFibsHoep): FibsHoep {
        return new FibsHoep(obj);
    }
    public deserialize<T extends object>(obj: T | IFibsHoep): FibsHoep {
        Object.assign(this, obj);
        obj && obj["fibsHoepreferenzanteilListByForeignHoep_id"] && obj["fibsHoepreferenzanteilListByForeignHoep_id"].length && (this.fibsHoepreferenzanteilListByForeignHoep_id = obj["fibsHoepreferenzanteilListByForeignHoep_id"].map(FibsHoepreferenzanteil.deserialize));
        obj && obj["fibsHmwbnutzungByNutzung_id"] && (this.fibsHmwbnutzungByNutzung_id = FibsHmwbnutzung.deserialize(obj["fibsHmwbnutzungByNutzung_id"]));
        return this;
    }

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "fischgewaessertyp_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public fischgewaessertyp_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "nutzung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public nutzung_id?: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "weser", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public weser?: boolean;

    /**
     * @type {Array<FibsHoepreferenzanteil>}
     */
    @fn.DataMember({ name: "fibsHoepreferenzanteilListByForeignHoep_id", type: [FibsHoepreferenzanteil], nullable: true, memberType: "FOREIGN JOIN" })
    public fibsHoepreferenzanteilListByForeignHoep_id?: Array<FibsHoepreferenzanteil>;

    /**
     * @type {FibsHmwbnutzung}
     */
    @fn.DataMember({ name: "fibsHmwbnutzungByNutzung_id", type: FibsHmwbnutzung, nullable: true, memberType: "JOIN", joinProperty: "nutzung_id" })
    public fibsHmwbnutzungByNutzung_id?: FibsHmwbnutzung;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
