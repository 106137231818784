<div class="row">
    <div class="col-xs-15">
        <label>Angaben zur Befischungsmethode</label>
    </div>
</div>

<div class="row">
    <div class="col-xs-15 col-sm-6"><uw-select
        [disabled]="readonly"
        size="10"
        *ngIf="befischungsgeraet_idRefJsonItems"
        [jsonItems]="befischungsgeraet_idRefJsonItems"
        keyValueMapping="0:1"
        [label]="formFischinfoUntersuchungService.label('FischinfoMethode','befischungsgeraet_id')"
        [(key)]="befischungsgeraet_id"
        nullable="true"
    ></uw-select></div>
    <div
        *ngIf="befischungsgeraet_id===1"
        class="col-xs-15 col-sm-9"
    ><uw-select
        [disabled]="readonly"
        *ngIf="befischungsart_idRefJsonItems"
        [jsonItems]="befischungsart_idRefJsonItems"
        keyValueMapping="0:1"
        size="10"
        [label]="formFischinfoUntersuchungService.label('FischinfoMethode','befischungsart_id')"
        [(key)]="befischungsart_id"
        nullable="true"
    ></uw-select></div>
</div>

<ng-container [ngSwitch]="befischungsgeraet_id">
    <ng-container *ngSwitchCase="1">
        <form-fischinfo-untersuchung-befischung-methode-egeraet
            [untersuchung]="untersuchung"
            [modalId]="modalId"
            [readonly]="readonly"
            (changed)="onChangedMethodegeraet($event)"
        ></form-fischinfo-untersuchung-befischung-methode-egeraet>
    </ng-container>
    <ng-container *ngSwitchCase="2">
        <form-fischinfo-untersuchung-befischung-methode-netz
            [untersuchung]="untersuchung"
            [modalId]="modalId"
            [readonly]="readonly"
            (changed)="onChangedMethodenetz($event)"
        ></form-fischinfo-untersuchung-befischung-methode-netz>
    </ng-container>
    <ng-container *ngSwitchCase="3">
        <form-fischinfo-untersuchung-befischung-methode-reuse
            [untersuchung]="untersuchung"
            [modalId]="modalId"
            [readonly]="readonly"
            (changed)="onChangedMethodereuse($event)"
        ></form-fischinfo-untersuchung-befischung-methode-reuse>
    </ng-container>
    <ng-container *ngSwitchCase="4">
        <form-fischinfo-untersuchung-befischung-methode-sonstige
            [untersuchung]="untersuchung"
            [modalId]="modalId"
            [readonly]="readonly"
            (changed)="onChangedMethodesonstige($event)"
        ></form-fischinfo-untersuchung-befischung-methode-sonstige>
    </ng-container>
</ng-container>

<div class="row">
    <div class="col-xs-15"><uw-textarea
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','beschreibung')}}"
        [(value)]="beschreibung"
        [disabled]="readonly"
        [updateHeight]="inActiveTab"
    ></uw-textarea></div>
</div>
