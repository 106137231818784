<uw-tabs>

<!-- #region Buttonleiste zur Probenahmestelle //-->
    <uw-modal-window-message
        *ngIf="isExtendedView && hasRightToEditPS"
        top="0"
        [height]="buttonPanelHeight"
        zIndex="1000"
        paddingLeft="0"
        paddingRight="0"
    >
        <article>
            <ng-container
                [ngSwitch]="mode"
            >
                <ng-container 
                    *ngSwitchCase="'Delete'"
                >
                    <uw-inline-button
                        iconClass="fa fa-trash-o"
                        (click)="onClickDelete($event)"
                        title="Ja, löschen"
                        value="Ja, löschen"
                    ></uw-inline-button>
                    <uw-inline-button
                        iconClass="fa fa-remove"
                        (click)="mode = 'Edit'" 
                        title="Nein, abbrechen"
                        value="Nein, abbrechen"
                    ></uw-inline-button>
                    <div class="question-button">
                        <span>Probenahmestelle inkl. Untersuchungen löschen?</span>
                    </div>
                </ng-container>

                <ng-container
                    *ngSwitchCase="'Edit'"
                >
                    <uw-inline-button
                        iconClass="fa fa-remove"
                        (click)="onClickCancel($event)" 
                        title="Bearbeitung abbrechen"
                        value="Abbrechen"
                    ></uw-inline-button>
                    <uw-inline-button 
                        iconClass="fa fa-save"
                        (click)="onClickSubmit($event)" 
                        title="Änderungen speichern"
                        value="Speichern"
                    ></uw-inline-button>
                    <uw-inline-button
                        *ngIf="hasRightToDeletePS"
                        iconClass="fa fa-trash-o"
                        (click)="mode = 'Delete'" 
                        title="Probenahmestelle löschen"
                        value="Löschen"
                    ></uw-inline-button>
                </ng-container>

                <ng-container
                    *ngSwitchDefault
                >            
                    <uw-inline-button
                        *ngIf="hasRightToEditPSData"
                        (click)="onClickEdit($event)" 
                        title="Sachdaten der Probenahmestelle bearbeiten"
                        iconClass="fa fa-pencil"
                        value="Bearbeiten"
                    ></uw-inline-button>
                    <uw-inline-button
                        *ngIf="hasRightToEditPSGeometry"
                        iconClass="fa fa-edit"
                        (click)="onClickGeometryEdit($event)" 
                        title="Geometrie der Probenahmestelle bearbeiten"
                        value="Geometrie"
                    ></uw-inline-button>
                    <uw-inline-button
                        *ngIf="hasRightToAddUntersuchung"
                        title="Eine neue Beobachtung an der Probenahmestelle anlegen"
                        iconClass="fa fa-plus-circle"
                        value="Beobachtung"
                        (click)="addModel($event,'Beobachtung')"
                    ></uw-inline-button>
                    <uw-inline-button
                        *ngIf="hasRightToAddUntersuchung"
                        title="Eine neue Befischung an der Probenahmestelle anlegen"
                        iconClass="fa fa-plus-circle"
                        value="Befischung"
                        (click)="addModel($event,'Befischung')"
                    ></uw-inline-button>
                </ng-container>
            </ng-container>
        </article>
    </uw-modal-window-message>
<!-- #endregion //-->

<!-- #region Warnmeldung(en) //-->
    <uw-modal-window-message
        [(visibility)]="warningIsShown"
        closeable="true"
        [top]="isExtendedView && hasRightToEditPS ? buttonPanelHeight : 0"
        warning="true"
    >
        <article [ngSwitch]="warningText.constructor.name">
            <ng-container *ngSwitchCase="'Array'">
                <p *ngFor="let para of warningText"
                    [textContent]="para"
                ></p>
            </ng-container>
            <p *ngSwitchCase="'String'"
                [textContent]="warningText"
            ></p>
        </article>
    </uw-modal-window-message>
<!-- #endregion //-->

<!-- #region Formular //-->
    <section class="uw-tab-body" [class.edit]="mode === 'Edit'">
        <div 
            class="container-fluid" 
            [class.edit-mode]="mode === 'Edit'"
            [style.marginTop.px]="topmargin"
        >
            <form-fischinfo-probenahmestelle
                *ngIf="isExtendedViewContentShown"
                [probenahmestelle]="probenahmestelle"
                [modalId]="modal.id"
                [readonly]="readonly"
                (stillgewaesserChanged)="onStillgewaesserChanged($event)"
                (anzahlUntersuchungenChanged)="onAnzahlUntersuchungenChanged($event)"
            ></form-fischinfo-probenahmestelle>
        </div>
    </section>
<!-- #endregion //-->

</uw-tabs>
