/// <reference path="./../../../node_modules/leaflet/dist/index.d.ts"/>

import { Injectable } from "@angular/core";
import DataService from "./../data/service";
import LabelEngine from "./labelengine";

/**
 * Kachelbasierter Kartendienst der anhand von Bounding Boxes darzustellende Karteninhalte aus dem Endpunkt "gridlayer" der Webschnittstelle (API) abholt und als Layer innerhalb der Karte anzeigt.
 */
@Injectable({
    providedIn: 'root'
})
export default class GridLayerService {
    protected map: L.Map;
    public spatialIndexTree: any[] = [];
    public spatialIndexPageSize: number = 0;
    public data: any[] = [];
    protected zoom: { [key: string]: any } = [];
    protected tiles: { [key: string]: any } = [];
    protected zoomCaches: { [key: string]: any } = [];
    protected layers: { [key: string]: any } = [];
    protected featureLayerGroups: { [key: string]: any } = [];
    protected tileToLoad: { [key: string]: any } = [];
    protected renderer = null;
    protected labelEngines: { [key: string]: any } = [];
    protected labelMinZoom = 8;
    protected labelPane;
    public iconScale = {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
        4: 1,
        5: 1,
        6: 2,
        7: 3,
        8: 4,
        9: 5,
        10: 6,
        11: 7,
        12: 8,
        13: 9,
        14: 10,
        15: 11,
        16: 12,
        17: 13,
        18: 14
    };
    protected projections: { [projection: string]: string } = {
        "EPSG:25832": "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs",
        "EPSG:4326": "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs"
    };

    constructor(
        protected dataService: DataService
    ) {
    }

    public redraw(map: L.Map, layerName: string, layerParams: any) {
        if (map) {
            this.map = map;
            this.featureLayerGroups[layerName] && this.remove(map, layerName);
            this.featureLayerGroups[layerName] = L.featureGroup();
            this.spatialIndexTree[layerName] = this.spatialIndexTree[layerName] || [];
            this.data[layerName] = this.data[layerName] || [];
            map.addLayer(this.featureLayerGroups[layerName]);
            this.renderer = (L.Browser.canvas)
                ? new L["Canvas"]()
                : new L["SV" + "G"]()
                ;
            this.layers[layerName] = new L["GridLayer"]({ tileSize: 512 });
            this.layers[layerName].onAdd = () => this.onAdd(layerName, layerParams);
            this.layers[layerName].on("loading", () => this.onLoading(layerName, layerParams));
            this.layers[layerName].on("tileunload", (tile) => this.onTileUnload(layerName, layerParams, tile));
            this.layers[layerName].on("tileload", (tile) => this.onTileLoad(layerName, layerParams, tile));
            map.addLayer(this.layers[layerName]);
            if (!this.labelPane) {
                this.labelPane = map.createPane('gridLabelPane');
                map.getPane('gridLabelPane').style.zIndex = "640";
                map.getPane('gridLabelPane').style.pointerEvents = "none";
            }
        }
    }

    public refresh(map: L.Map, layerName, layerParams) {
        if (map) {
            for (let key in this.tiles[layerName]) {
                if (this.tiles[layerName][key] !== this.tileToLoad[layerName]) {
                    this.drawTile(layerName, this.tiles[layerName][key]);
                }
            }
        }
    }

    public remove(map: L.Map, layerName: string) {
        if (this.featureLayerGroups[layerName]) {
            this.featureLayerGroups[layerName].clearLayers();
            map.removeLayer(this.featureLayerGroups[layerName]);
            map.removeLayer(this.layers[layerName]);
        }
    }

    protected onAdd(layerName, layerParams) {
        this.data[layerName] = this.data[layerName] || [];
        this.spatialIndexTree[layerName] = this.spatialIndexTree[layerName] || [];
        this.tiles[layerName] = [];
        this.zoom[layerName] = null;
        this.tileToLoad[layerName] = null;
        this.layers[layerName]._initContainer();
        this.layers[layerName]._levels = {};
        this.layers[layerName]._tiles = {};
        this.zoomCaches[layerName] = [];
        this.layers[layerName]._resetView();
        this.layers[layerName]._update();
        this.labelEngines[layerName] = new LabelEngine();
    }

    protected onLoading(layerName, layerParams) {
        if (this.zoom[layerName] != this.layers[layerName]._tileZoom) {
            this.zoom[layerName] = this.layers[layerName]._tileZoom;
            this.featureLayerGroups[layerName].clearLayers();
            this.zoomCaches[layerName] = [];
            for (let key in this.tiles[layerName]) {
                if (this.tiles[layerName].hasOwnProperty(key)) {
                    if (key.split(":")[2] != this.zoom[layerName]) {
                        this.tiles[layerName][key] = null;
                        delete this.tiles[layerName][key];
                    }
                }
            }
        }
        setTimeout(() => this.loadNextTile(layerName, layerParams), 400);
    }

    protected onTileUnload(layerName, layerParams, tile) {
        var key = tile.coords.x + ":" + tile.coords.y + ":" + tile.coords.z;
        this.tiles[layerName][key] = null;
        delete this.tiles[layerName][key];
    }

    protected onTileLoad(layerName, layerParams, tile) {
        var key = tile.coords.x + ":" + tile.coords.y + ":" + tile.coords.z;
        if (!this.zoomCaches[layerName][key]) {
            this.zoomCaches[layerName][key] = true;
            this.tiles[layerName][key] = tile;
        }
    }

    protected project(
        projectionFrom: string,
        projectionTo: string,
        coords: any
    ): any {
        return L.Proj.proj4(
            this.projections[projectionFrom],
            this.projections[projectionTo],
            coords
        );
    }

    protected loadNextTile(layerName, layerParams) {
        if (!this.tileToLoad[layerName]) {
            let key = null;
            for (let k in this.tiles[layerName]) {
                if (
                    !this.tileToLoad[layerName]
                    && this.tiles[layerName].hasOwnProperty(k)
                    && !this.tiles[layerName][k].loaded
                ) {
                    key = k;
                    this.tiles[layerName][key].loaded = true;
                    this.tileToLoad[layerName] = this.tiles[layerName][key];
                }
            }
            if (this.tileToLoad[layerName]) {
                this.drawTile(layerName, this.tiles[layerName][key]).then(x => {
                    this.tileToLoad[layerName] = null;
                    this.loadNextTile(layerName, layerParams);
                }).catch(e => {
                    this.tileToLoad[layerName] = null;
                    this.loadNextTile(layerName, layerParams);
                })
            }
        }
    }

    public drawTile(layerName, tile): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const zoom = this.layers[layerName]._tileZoom,
                    tileBounds = this.layers[layerName]["_tileCoordsToBounds"](tile.coords),
                    nw = tileBounds.getNorthWest(),
                    se = tileBounds.getSouthEast(),
                    _nw = this.project("EPSG:4326", "EPSG:25832", [nw.lng, nw.lat]),
                    _sw = this.project("EPSG:4326", "EPSG:25832", [nw.lng, se.lat]),
                    _se = this.project("EPSG:4326", "EPSG:25832", [se.lng, se.lat]),
                    _ne = this.project("EPSG:4326", "EPSG:25832", [se.lng, nw.lat]),
                    layerMinZoom = 5,
                    layerLabelMinZoom = 9,
                    radius = this.iconScale[zoom]
                    ;
                if (zoom > layerMinZoom) {
                    let geojson = [];
                    let lastIndexDrawn = this.spatialIndexTree[layerName].length;
                    for (let i = tile.lastIndexDrawn || 0; i < lastIndexDrawn; i++) {
                        geojson.push(...this.spatialIndexTree[layerName][i].range(_nw[0], _se[1], _se[0], _nw[1])
                            .filter(x => this.data[layerName][i]
                                && this.data[layerName][i][x]
                                && this.data[layerName][i][x].etrs89e
                            )
                            .map(x => {
                                return {
                                    "type": "Feature",
                                    "properties": {
                                        "id": this.data[layerName][i][x].id,
                                        "label": this.data[layerName][i][x].label,
                                        "type": this.data[layerName][i][x].type,
                                        "freigegeben": this.data[layerName][i][x].freigegeben
                                    },
                                    "geometry": {
                                        "type": "Point",
                                        "coordinates": [
                                            this.data[layerName][i][x].etrs4326e,
                                            this.data[layerName][i][x].etrs4326n
                                        ]
                                    }
                                }
                            }))
                            ;
                    };
                    tile.lastIndexDrawn = lastIndexDrawn;

                    if (geojson.length) {
                        const layer = {
                            // coordsToLatLng: (coords) => this.project("EPSG:25832", "EPSG:4326", coords).reverse(),
                            pointToLayer: (feature, latlng) => {
                                const color = feature.properties.type === "FG" ? "#B41311" : "blue";
                                return feature.properties.freigegeben
                                    ? L.circleMarker(latlng, {
                                        renderer: this.renderer,
                                        interactive: true,
                                        radius: radius,
                                        weight: 1,
                                        fillColor: color,
                                        color: color,
                                        fillOpacity: 1,
                                        opacity: 1,
                                    })
                                    : L["triangleMarker"](latlng, {
                                        renderer: this.renderer,
                                        interactive: true,
                                        radius: radius * 1.2,
                                        weight: 1,
                                        fillColor: color,
                                        color: color,
                                        fillOpacity: 1,
                                        opacity: 1,
                                    })
                                    ;
                            }
                        };
                        if (zoom > layerLabelMinZoom) {
                            layer["onEachFeature"] = (feature, layer) => {
                                layer.labelId = [layerName, lastIndexDrawn, nw.lng, nw.lat, se.lng, se.lat].join(",");
                                layer.bindTooltip(feature.properties.label, {
                                    permanent: true,
                                    direction: "center",
                                    offset: L.point(0, 20),
                                    opacity: 1,
                                    className: "gridlabel",
                                    pane: "gridLabelPane"
                                });
                            }
                        }
                        this.featureLayerGroups[layerName].addLayer(L["geoJson"](geojson, layer));
                        if (zoom > layerLabelMinZoom) {
                            this.featureLayerGroups[layerName].eachLayer((layergroup) => {
                                layergroup.eachLayer((layer) => {
                                    this.labelEngines[layerName].addLabel(this.map, layer, layer.labelId);
                                });
                            });
                            this.labelEngines[layerName].update();
                        }
                    }
                }
                resolve({});
            } catch (e) {
                reject(e);
            }
        });
    }

}