import { Component, Input, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import DataService from "../data/service";
import FischinfoDetailbefischungergebnis from "../../models/fischinfo/detailbefischungergebnis/model";
import FischinfoDetailmuschelergebnis from "../../models/fischinfo/detailmuschelergebnis/model";
import FischinfoDetailkrebsergebnis from "../../models/fischinfo/detailkrebsergebnis/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoMuscheluntersuchung from "../../models/fischinfo/muscheluntersuchung/model";
import FischinfoKrebsuntersuchung from "../../models/fischinfo/krebsuntersuchung/interface";
/**
 * Mit dieser Komponente kann der Nutzer die Individuenanzahl einer befischten Art ansehen und ggf. bearbeiten.
 *
 * Der Nutzer kann mehrere Individuenanzahlen zusammen mit einer zugehörigen Länge und einem zugrhörigem Gewicht angeben.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-detail",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungErgebnisseArtErfassungDetail {
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    @Input()
    public artId: string;
    @Input()
    public artGruppe: string;
    @Input()
    public detailErgebnis: (FischinfoDetailbefischungergebnis | FischinfoDetailmuschelergebnis | FischinfoDetailkrebsergebnis)[];
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get detailErgebnisList(): (FischinfoDetailbefischungergebnis | FischinfoDetailmuschelergebnis | FischinfoDetailkrebsergebnis)[] {
        return (this.detailErgebnis || [])
            .filter(x => !(x instanceof FischinfoDetailbefischungergebnis
            && x.jungfisch));
    }
    public get firstIsSet(): boolean {
        return this.detailErgebnisList.length > 0
            && (this.detailErgebnisList.length > 1
                || parseInt(String(this.detailErgebnisList[0].anzahl), 10) > 0);
    }
    public get anzahljungfische(): number {
        return (<FischinfoDetailbefischungergebnis>this.detailErgebnis
            .find(x => x instanceof FischinfoDetailbefischungergebnis
            && x.jungfisch)
            || {} as FischinfoDetailbefischungergebnis)
            .anzahl;
    }
    public get addId(): number {
        return parseInt(String((this.detailErgebnis || [])
            .reduce((max, p) => p.id > max ? p.id : max, ((this.detailErgebnis && this.detailErgebnis[0]) || { id: 0 }).id)
            || 0), 10) + 1;
    }
    public set anzahljungfische(value: number) {
        this.anzahljungfische != null && this.formFischinfoUntersuchungService.modelUtil
            .remove<FischinfoDetailbefischungergebnis>(this.untersuchung, "FischinfoDetailbefischungergebnis", this.detailErgebnis
            .find(x => x instanceof FischinfoDetailbefischungergebnis
            && x.jungfisch));
        if (!Number.isNaN(Number.parseInt(String(value), 10))) {
            this.formFischinfoUntersuchungService.modelUtil
                .setOrAdd<FischinfoDetailbefischungergebnis>(this.untersuchung, "FischinfoDetailbefischungergebnis", new FischinfoDetailbefischungergebnis({
                id: this.addId,
                art_id: this.artId,
                anzahl: Number.parseInt(String(value), 10),
                jungfisch: true,
                befischung_id: this.formFischinfoUntersuchungService.modelUtil
                    .getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
                    .id
            }));
            this.changed.emit(true);
        }
    }
    public addModel(e: any) {
        this.artGruppe === "Fische"
            ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDetailbefischungergebnis>(this.untersuchung, "FischinfoDetailbefischungergebnis", new FischinfoDetailbefischungergebnis({
                id: this.addId,
                art_id: this.artId,
                befischung_id: this.formFischinfoUntersuchungService.modelUtil
                    .getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
                    .id
            }))
            : this.artGruppe === "Muscheln"
                ? this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDetailmuschelergebnis>(this.untersuchung, "FischinfoDetailmuschelergebnis", new FischinfoDetailmuschelergebnis({
                    id: this.addId,
                    muschelart_id: this.artId,
                    muscheluntersuchung_id: this.formFischinfoUntersuchungService.modelUtil
                        .getOrDefault<FischinfoMuscheluntersuchung>(this.untersuchung, "FischinfoMuscheluntersuchung")
                        .id
                }))
                : this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDetailkrebsergebnis>(this.untersuchung, "FischinfoDetailkrebsergebnis", new FischinfoDetailkrebsergebnis({
                    id: this.addId,
                    krebsart_id: this.artId,
                    krebsuntersuchung_id: this.formFischinfoUntersuchungService.modelUtil
                        .getOrDefault<FischinfoKrebsuntersuchung>(this.untersuchung, "FischinfoKrebsuntersuchung")
                        .id
                }));
        this.changed.emit(true);
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
}
