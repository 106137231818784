<div class="row">
    <div class="col-xs-15 col-sm-4"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','befischtelaenge')}}"
        [(value)]="befischtelaenge"
        [disabled]="readonly"
    ></uw-textinput></div>
    <div class="col-xs-15 col-sm-4"><uw-textinput
        label="{{formFischinfoUntersuchungService.label('FischinfoMethode','befischtebreite')}}"
        [(value)]="befischtebreite"
        [disabled]="readonly"
    ></uw-textinput></div>
</div>
