import { Component, Input, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import DataService from "../data/service";
import FischinfoLaengenklassenbefischungergebnis from "../../models/fischinfo/laengenklassenbefischungergebnis/model";
import FischinfoLaengenklassenmuschelergebnis from "../../models/fischinfo/laengenklassenmuschelergebnis/model";
import FischinfoLaengenklassenkrebsergebnis from "../../models/fischinfo/laengenklassenkrebsergebnis/model";
/**
 * Mit dieser Komponente kann der Nutzer die Individuenanzahlen einer befischten Art nach Längenklassen ansehen und ggf. bearbeiten.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-laengenklassen",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungErgebnisseArtErfassungLaengenklassen {
    @Input()
    public modalId: string;
    @Input()
    public artId: string;
    @Input()
    public artGruppe: string;
    @Input()
    public laengenklassenErgebnis: FischinfoLaengenklassenbefischungergebnis | FischinfoLaengenklassenmuschelergebnis | FischinfoLaengenklassenkrebsergebnis;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get anzahl_0_5(): number {
        return this.laengenklassenErgebnis
            && this.laengenklassenErgebnis.anzahl_0_5;
    }
    public set anzahl_0_5(value: number) {
        this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_0_5 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_5_10(): number {
        return this.laengenklassenErgebnis
            && this.laengenklassenErgebnis.anzahl_5_10;
    }
    public set anzahl_5_10(value: number) {
        this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_5_10 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_0_10(): number {
        return this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            ? this.laengenklassenErgebnis
                && this.laengenklassenErgebnis.anzahl_0_10
            : null;
    }
    public set anzahl_0_10(value: number) {
        this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            && this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_0_10 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_10_15(): number {
        return this.laengenklassenErgebnis
            && this.laengenklassenErgebnis.anzahl_10_15;
    }
    public set anzahl_10_15(value: number) {
        this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_10_15 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_15_20(): number {
        return this.laengenklassenErgebnis
            && this.laengenklassenErgebnis.anzahl_15_20;
    }
    public set anzahl_15_20(value: number) {
        this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_15_20 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_10_20(): number {
        return this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            ? this.laengenklassenErgebnis
                && this.laengenklassenErgebnis.anzahl_10_20
            : null;
    }
    public set anzahl_10_20(value: number) {
        this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            && this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_10_20 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_20_25(): number {
        return this.laengenklassenErgebnis
            && this.laengenklassenErgebnis.anzahl_20_25;
    }
    public set anzahl_20_25(value: number) {
        this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_20_25 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_25_30(): number {
        return this.laengenklassenErgebnis
            && this.laengenklassenErgebnis.anzahl_25_30;
    }
    public set anzahl_25_30(value: number) {
        this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_25_30 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_20_30(): number {
        return this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            ? this.laengenklassenErgebnis
                && this.laengenklassenErgebnis.anzahl_20_30
            : null;
    }
    public set anzahl_20_30(value: number) {
        this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            && this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_20_30 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_30_40(): number {
        return this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            ? this.laengenklassenErgebnis
                && this.laengenklassenErgebnis.anzahl_30_40
            : null;
    }
    public set anzahl_30_40(value: number) {
        this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            && this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_30_40 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_40_50(): number {
        return this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            ? this.laengenklassenErgebnis
                && this.laengenklassenErgebnis.anzahl_40_50
            : null;
    }
    public set anzahl_40_50(value: number) {
        this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            && this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_40_50 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_50_60(): number {
        return this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            ? this.laengenklassenErgebnis
                && this.laengenklassenErgebnis.anzahl_50_60
            : null;
    }
    public set anzahl_50_60(value: number) {
        this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            && this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_50_60 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_60_70(): number {
        return this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            ? this.laengenklassenErgebnis
                && this.laengenklassenErgebnis.anzahl_60_70
            : null;
    }
    public set anzahl_60_70(value: number) {
        this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            && this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_60_70 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahl_70(): number {
        return this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            ? this.laengenklassenErgebnis
                && this.laengenklassenErgebnis.anzahl_70
            : null;
    }
    public set anzahl_70(value: number) {
        this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            && this.laengenklassenErgebnis
            && (this.laengenklassenErgebnis.anzahl_70 = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get anzahljungfische(): number {
        return this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            ? this.laengenklassenErgebnis
                && this.laengenklassenErgebnis.anzahljungfische
            : null;
    }
    public set anzahljungfische(value: number) {
        this.laengenklassenErgebnis instanceof FischinfoLaengenklassenbefischungergebnis
            && (this.laengenklassenErgebnis.anzahljungfische = typeof value === "number" ? value : value ? parseInt(value, 10) : null);
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
}
