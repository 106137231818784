/// <reference path="../../node_modules/@types/proj4/index.d.ts"/>
/// <reference path="../../node_modules/leaflet/dist/index.d.ts"/>

import { Component, Input, HostBinding, ElementRef, Inject, Output, EventEmitter, SimpleChange } from "@angular/core";
import { fromEvent } from "rxjs";
import turfUnion from "@turf/union";
import turfKinks from "@turf/kinks";
import turfBuffer from "@turf/buffer";
import turfIntersect from "@turf/intersect";
import turfArea from "@turf/area";
import turfDifference from "@turf/difference";
import turfTruncate from "@turf/truncate";
import proj4 from "proj4";
import UWMapEditorService from "./../mapeditor/service";
import UWCustomizedMapService from "./../service";
import { LayerGroup, Layer, Polygon as LPolygon } from "leaflet";
import { Feature, Polygon } from "geojson";
@Component({
    selector: "uw-map-editor",
    templateUrl: "./default.component.html",
    styleUrls: [
        "./default.component.less",
        "./../assets/styles/glyphter.css",
    ]
})
export default class UWMapEditor {
    @Input()
    point: string = "true";
    @Input()
    polyline: string = "true";
    @Input()
    polygon: string = "true";
    @Input()
    shapesUpload: string = "";
    @Input()
    measureTool: string = "";
    @Input()
    vanitizeIntersectionsRecursionLimit: number = 100;
    public history: any;
    public historyForwardFlag: boolean;
    public historyBackwardsFlag: boolean;
    public editMode: string;
    public measurePolylineLayer: L.Layer;
    public measureTooltip: any;
    public isModified: boolean;
    public geometryHasToBeVanitized: boolean;
    public geometryInSavedState: any;
    public lastMapEditorClickEvent: number;
    public lastMapClickEvent: number;
    public item: any;
    public vanitizeCnt: number = 0;
    public vanitizePos: number = 0;
    public vanitizedPercentValue: number = 0;
    public vanitizing: boolean;
    public set vanitizedPercent(percent: number) {
        this.vanitizedPercentValue = percent;
        this.vanitizing = this.vanitizedPercentValue < 100 ? true : false;
    }
    ;
    public get vanitizedPercent(): number {
        return this.vanitizedPercentValue;
    }
    ;
    public debugModeValue: boolean;
    public set debugMode(mode: boolean) {
        if (mode && !this.debugModeValue) {
            this.mapService.layers["debug"] = L.featureGroup();
            this.mapService.layers["debug"]["style"] = {
                color: "yellow",
                opacity: 1,
                weight: 2,
                dashArray: [5, 5],
                fillColor: "yellow",
                fillOpacity: .5
            };
            this.mapService.layers["debug"].addTo(this.mapService.map);
            this.mapService.layers["debugfeature"] = L.featureGroup();
            this.mapService.layers["debugfeature"]["style"] = {
                color: "blue",
                opacity: 1,
                weight: 1,
                dashArray: [5, 5],
                fillColor: "yellow",
                fillOpacity: .5
            };
            this.mapService.layers["debugfeature"].addTo(this.mapService.map);
        }
        this.debugModeValue = mode;
    }
    public get debugMode(): boolean {
        return this.debugModeValue;
    }
    constructor(public mapService: UWCustomizedMapService, public mapEditorService: UWMapEditorService) {
        this.mapEditorService.load.subscribe(item => {
            this.draw(item);
        });
        this.mapEditorService.edit.subscribe(value => {
            this.mapService.featureLayer.stoputfgrid = value;
        });
        this.mapEditorService.add.subscribe(item => {
            this.addGeoJSON(item.name, item.geometry, item.srs, item.style, item.touchModeFlag);
            this.vanitizeFeatureGroup(item.name)
                .then((modified) => {
                    this.mapEditorService.vanitized
                        .emit(modified);
                })
                .catch(err => this.mapEditorService.vanitizeFailed
                    .emit(err));
        });
    }
    public ngAfterViewInit(): void {
        // this.debugMode = true;
    }
    public draw(item: any) {
        this.clearHistory();
        this.item = item.item;
        this.mapEditorService.isEditable = item.editable;
        this.mapEditorService.isEditing = false;
        this.mapEditorService.layerName = item.name;
        this.mapEditorService.bbox = item.bbox;
        this.mapEditorService.isZoomable = item.bbox ? true : false;
        item.projection = item.projection ? item.projection : "EPSG:25832";
        this.drawGeoJSON(item.name, null, null);
        this.isModified = false;
        this.unset();
        this.checkDrawingState();
        if (item.zoom && item.bbox) {
            this.mapService.zoomGeometry(item.bbox, item.projection);
        }
        if (item.editable) {
            this.initializeDrawEvents();
        }
        if (item.the_geom) {
            this.drawGeoJSON(item.name, item.the_geom, item.projection, item.style);
            if (item.editable) {
                this.createSnapshotOfFeatureLayerGroup();
            }
        }
    }
    public toggleToolbar() {
        this.mapEditorService.toolbarIsOpen = !this.mapEditorService.toolbarIsOpen;
        if (!this.mapEditorService.toolbarIsOpen) {
            this.unset();
            this.checkDrawingState();
        }
        else {
            this.checkDrawingState();
        }
    }
    public checkDrawingState() {
        let isDrawn = this.mapService.featureLayer &&
            this.mapService.featureLayer.wmslayerparams &&
            this.mapService.featureLayer.wmslayerparams.domainobjectid;
        if (!this.item) {
            if (isDrawn) {
                this.mapService.featureLayer.wmslayerparams = {};
                this.mapService.drawFeatureLayer();
            }
        }
        else if (!this.mapEditorService.isEditable) {
            if (isDrawn) {
                this.mapService.featureLayer.wmslayerparams = {};
                this.mapService.drawFeatureLayer();
            }
        }
        else {
            if (this.mapEditorService.toolbarIsOpen) {
                this.mapService.featureLayer.wmslayerparams = {
                    domainobjectid: this.item.domainobjectid
                };
                this.mapService.drawFeatureLayer();
                if (this.history &&
                    this.history.history &&
                    this.history.history.length) {
                    this.drawGeoJSON(this.mapEditorService.layerName, this.history.history[this.history.pointer], "EPSG:25832");
                }
                else {
                    this.drawGeoJSON(this.mapEditorService.layerName, this.layerGroupAsGeoJSON(this.mapEditorService.layerName, "EPSG:25832"), "EPSG:25832");
                }
            }
            else if (isDrawn) {
                this.mapService.featureLayer.wmslayerparams = {};
                this.mapService.drawFeatureLayer();
                if (this.item) {
                    this.drawGeoJSON(this.mapEditorService.layerName, this.geometryInSavedState, "EPSG:25832");
                }
            }
        }
    }
    public toggleSelect() {
        if (this.mapEditorService.isEditable) {
            if (this.editMode === "select") {
                this.unset();
            }
            else {
                this.setSelectMode();
            }
        }
    }
    public toggleEdit(name: string) {
        if (name === "measurePolyline") {
            if (this.editMode !== name) {
                this.editMode = name;
                // this.mapEditorService.isEditing = true;
                this.initializeDrawEvents();
                this.redrawFeatureLayer();
                this[name]();
            }
        }
        else if (this.mapEditorService.isEditable) {
            if (this.editMode === name) {
                this.setSelectMode();
            }
            else {
                let allow = true;
                if (name === "drawPoint" && this.point !== "true") {
                    allow = false;
                }
                if (name === "drawPolyline" && this.polyline !== "true") {
                    allow = false;
                }
                if (name === "drawPolygon" && this.polygon !== "true") {
                    allow = false;
                }
                if (name === "cutPolygon" && this.polygon !== "true") {
                    allow = false;
                }
                if (allow) {
                    if (this.editMode === "drawPolygon" || this.editMode === "cutPolygon") {
                        this.geometryHasToBeVanitized = true;
                    }
                    this.editMode = name;
                    this.mapEditorService.isEditing = true;
                    this.redrawFeatureLayer();
                    this[name]();
                }
            }
        }
    }
    public onChangeShapeInput(geom) {
        this.vanitizing = true;
        this.unset();
        this.addGeoJSON(this.mapEditorService.layerName, geom, "EPSG:25832");
        this.createSnapshotOfFeatureLayerGroup();
        this.vanitizeFeatureGroup(this.mapEditorService.layerName)
            .then((modified) => {
                if (modified) {
                    this.createSnapshotOfFeatureLayerGroup();
                }
                let a = this.mapService.project("EPSG:25832", this.mapService.projection, [
                    geom.bbox[0],
                    geom.bbox[1]
                ]);
                let b = this.mapService.project("EPSG:25832", this.mapService.projection, [
                    geom.bbox[2],
                    geom.bbox[3]
                ]);
                this.mapService.zoomToBounds([a[1], a[0]], [b[1], b[0]], this.mapService.projection);
                this.setSelectMode();
            })
            .catch(err => (console.error(err),
                this.mapEditorService.vanitizeFailed.emit(err)));
    }
    public toggleRemove() {
        if (this.mapEditorService.isEditable) {
            if (this.editMode === "remove") {
                this.unset();
            }
            else {
                if (this.editMode === "drawPolygon" || this.editMode === "cutPolygon") {
                    this.geometryHasToBeVanitized = true;
                }
                this.editMode = "remove";
                this.mapEditorService.isEditing = true;
                this.redrawFeatureLayer();
            }
        }
    }
    public walkFeatureLayerGroupHistory(step: number): void {
        if (this.mapEditorService.isEditable) {
            this.mapService.map.editTools.stopDrawing();
            let previousPointer = this.history.pointer;
            this.history.pointer += step;
            this.historyForwardFlag = true;
            this.historyBackwardsFlag = true;
            if (this.history.pointer < 1) {
                this.history.pointer = 0;
                this.historyBackwardsFlag = false;
            }
            if (this.history.pointer >= this.history.history.length - 1) {
                this.history.pointer = this.history.history.length - 1;
                this.historyForwardFlag = false;
            }
            if (previousPointer !== this.history.pointer) {
                this.geometryHasToBeVanitized = true;
                this.isModified = true;
                this.drawGeoJSON(this.mapEditorService.layerName, this.history.history[this.history.pointer], "EPSG:25832");
                this.editMode = "select";
                this.mapService.layers[this.mapEditorService.layerName]["eachLayer"](domLayer => {
                    if (domLayer.editor) {
                        let layer = domLayer.toGeoJSON();
                        if (layer.geometry["type"] === "Polygon") {
                            this.editMode = "drawPolygon";
                        }
                        else if (layer.geometry["type"] === "LineString") {
                            this.editMode = "drawPolyline";
                        }
                    }
                });
                if (this.editMode === "select") {
                    this.redrawFeatureLayer(true);
                }
            }
        }
    }
    public save() {
        if (this.mapEditorService.isEditable) {
            if (this.editMode === "drawPolygon" || this.editMode === "cutPolygon") {
                this.geometryHasToBeVanitized = true;
            }
            this.redrawFeatureLayer();
            this.geometryInSavedState = <JSON>this.layerGroupAsGeoJSON(this.mapEditorService.layerName, "EPSG:25832");
            this.mapEditorService.save.emit({
                item: this.item,
                geoJSON: this.geometryInSavedState
            });
        }
    }
    public unset() {
        if (this.editMode === "drawPolygon" || this.editMode === "cutPolygon") {
            this.geometryHasToBeVanitized = true;
        }
        this.editMode = null;
        this.mapEditorService.isEditing = false;
        this.redrawFeatureLayer();
    }
    public setSelectMode() {
        if (this.editMode === "drawPolygon" || this.editMode === "cutPolygon") {
            this.geometryHasToBeVanitized = true;
        }
        if (this.editMode !== "select") {
            this.editMode = "select";
            this.mapEditorService.isEditing = true;
            this.redrawFeatureLayer();
        }
    }
    public onClickCancel() {
        this.cancelMeasurement();
        this.editMode === 'measurePolyline' && (this.editMode = null);
    }
    public cancelMeasurement() {
        if (this.measurePolylineLayer) {
            this.mapService.map.removeLayer(this.measureTooltip);
            this.mapService.map.removeLayer(this.measurePolylineLayer);
        }
    }
    public redrawFeatureLayer(withoutSnapshot?: boolean) {
        this.stopDrawingTools();
        this.cancelMeasurement();
        if (this.geometryHasToBeVanitized) {
            this.vanitizeFeatureGroup(this.mapEditorService.layerName)
                .then((vanitized) => {
                    if (vanitized && !withoutSnapshot) {
                        this.createSnapshotOfFeatureLayerGroup();
                    }
                    this.geometryHasToBeVanitized = false;
                    this.drawGeoJSON(this.mapEditorService.layerName, this.layerGroupAsGeoJSON(this.mapEditorService.layerName, "EPSG:25832", true), "EPSG:25832");
                });
        }
        else {
            this.drawGeoJSON(this.mapEditorService.layerName, this.layerGroupAsGeoJSON(this.mapEditorService.layerName, "EPSG:25832", true), "EPSG:25832");
        }
    }
    public stopDrawingTools() {
        if (this.mapService.map.editTools) {
            this.mapService.map.editTools.stopDrawing();
        }
        if (this.mapService.layers[this.mapEditorService.layerName]) {
            this.mapService.layers[this.mapEditorService.layerName]["eachLayer"](domLayer => {
                if (domLayer.editor) {
                    domLayer.disableEdit();
                }
            });
        }
    }
    public drawPolygon(): void {
        let style = Object.assign({
            interactive: true,
            color: this.mapService.linkColor,
            weight: 2,
            opacity: 0.7,
            fill: true,
            fillColor: this.mapService.linkColor,
            fillOpacity: 0.2
        }, this.mapService.layers[this.mapEditorService.layerName]["style"]);
        this.mapService.layers[this.mapEditorService.layerName]["addLayer"](this.mapService.map.editTools.startPolygon(null, style));
        this.editMode = "drawPolygon";
    }
    public cutPolygon(): void {
        let style = Object.assign({
            interactive: true,
            color: this.mapService.linkColor,
            weight: 2,
            opacity: 0.7,
            fill: true,
            fillColor: this.mapService.linkColor,
            fillOpacity: 0.2
        }, this.mapService.layers[this.mapEditorService.layerName]["style"]);
        const poly = this.mapService.map.editTools.startPolygon(null, style);
        poly["subtract"] = true;
        this.mapService.layers[this.mapEditorService.layerName]["addLayer"](poly);
        this.editMode = "cutPolygon";
    }
    public drawPolyline(): void {
        let style = Object.assign({
            interactive: true,
            color: this.mapService.linkColor,
            weight: 2,
            opacity: 0.7
        }, this.mapService.layers[this.mapEditorService.layerName]["style"]);
        style["fill"] = false;
        this.mapService.layers[this.mapEditorService.layerName]["addLayer"](this.mapService.map.editTools.startPolyline(null, style));
        this.editMode = "drawPolyline";
    }
    public getMeasureTooltipText = (point?: L.LatLng) => {
        let geoJson = this.measurePolylineLayer ? (this.measurePolylineLayer as any).toGeoJSON() : null;
        if (!geoJson || !geoJson.geometry || !geoJson.geometry.coordinates || geoJson.geometry.coordinates.length < 1) {
            return 'Setzen Sie den Anfangspunkt der Messung';
        }
        else {
            return this.getMeasureDrawnDistance(geoJson, point);
        }
    };
    public getMeasureDrawnDistance = (geoJson, point?: L.LatLng) => {
        if (!geoJson
            || !geoJson.geometry
            || !geoJson.geometry.coordinates
            || (geoJson.geometry.coordinates.length < 2
                && !point)) {
            return "0 m";
        }
        else {
            let distance = 0;
            for (let i = 1; i < geoJson.geometry.coordinates.length; i++) {
                distance += L.latLng(geoJson.geometry.coordinates[i - 1][1], geoJson.geometry.coordinates[i - 1][0]).distanceTo(L.latLng(geoJson.geometry.coordinates[i][1], geoJson.geometry.coordinates[i][0]));
            }
            if (point) {
                distance += L.latLng(geoJson.geometry.coordinates[geoJson.geometry.coordinates.length - 1][1], geoJson.geometry.coordinates[geoJson.geometry.coordinates.length - 1][0]).distanceTo(point);
            }
            if (distance > 1000) {
                return (parseInt((distance / 100).toString(), 10) / 10).toString().replace(".", ",") + " km";
            }
            else {
                return parseInt(distance.toString(), 10) + " m";
            }
        }
    };
    public measurePolyline(): void {
        let style = {
            interactive: true,
            color: "red",
            weight: 2,
            opacity: 0.7,
            dashArray: "5",
            fill: false
        };
        this.measurePolylineLayer = this.mapService.map.editTools.startPolyline(null, style);
        this.mapService.map.addLayer(this.measurePolylineLayer);
        var bounds = this.mapService.map.getBounds().pad(0.25); // slightly out of screen
        var options = {
            position: 'left',
            noWrap: true
        };
        this.measureTooltip = (L["tooltipBounded"](options as any)
            .addTo(this.mapService.map) as any)
            .setContent(this.getMeasureTooltipText())
            .setLatLng(new (L as any).LatLng(bounds.getNorth(), bounds.getCenter().lng));
        this.editMode = "measurePolyline";
    }
    public drawPoint(): void {
        let style = Object.assign({
            icon: L.divIcon({ className: "leaflet-div-icon" })
        }, this.mapService.layers[this.mapEditorService.layerName]["style"]);
        this.mapService.layers[this.mapEditorService.layerName]["addLayer"](this.mapService.map.editTools.startMarker(null, style));
        this.editMode = "drawPoint";
    }
    public zoom() {
        if (this.mapEditorService.isZoomable) {
            this.mapService.zoomGeometry(this.mapEditorService.bbox, "EPSG:25832");
        }
    }
    public initializeDrawEvents() {
        if (this.mapService.map && !this.mapService.map["drawEventsInitialized"]) {
            this.mapService.map["drawEventsInitialized"] = true;
            fromEvent(this.mapService.map, "mousemove").subscribe(event => {
                this.measureTooltip && this.measureTooltip
                    .setContent(this.getMeasureTooltipText((event as any).latlng))
                    .updatePosition((event as any).layerPoint);
                ;
            });
            fromEvent(this.mapService.map, "editable:vertex:ctrlclick editable:vertex:metakeyclick").subscribe(event => {
                event["vertex"].continue();
                this.lastMapEditorClickEvent = Date.now();
            });
            fromEvent(this.mapService.map, "editable:drawing:clicked editable:dragend editable:vertex:dragend").subscribe(event => {
                this.isModified = true;
                if (this.editMode === "select" || this.editMode === "drawPolygon" || this.editMode === "cutPolygon") {
                    this.geometryHasToBeVanitized = true;
                }
                this.editMode !== 'measurePolyline' && this.createSnapshotOfFeatureLayerGroup();
                this.lastMapEditorClickEvent = Date.now();
                this.measureTooltip && this.measureTooltip
                    .setContent(this.getMeasureTooltipText())
                    .updatePosition((event as any).layerPoint);
            });
            fromEvent(this.mapService.map, "editable:feature:selected").subscribe(event => {
                if (this.editMode === "remove") {
                    if (event["sourceLayer"]) {
                        this.mapService.layers[this.mapEditorService.layerName]["removeLayer"](event["sourceLayer"]);
                    }
                    else {
                        this.mapService.layers[this.mapEditorService.layerName]["removeLayer"](event);
                    }
                    this.createSnapshotOfFeatureLayerGroup();
                }
                else if (this.editMode === "select") {
                    this.redrawFeatureLayer();
                }
                /*} else if (this.mapEditorService.toolbarIsOpen) {
                                this.editMode = "select";
                                this.mapService.featureLayer.stoputfgrid = true;
                                this.mapEditorService.isEditing = true;
                                this.redrawFeatureLayer();
                            }
                            */
                this.lastMapEditorClickEvent = Date.now();
            });
            fromEvent(this.mapService.map, "editable:drawing:start").subscribe(event => {
                this.mapService.map["mapeditlayer"] = event["layer"];
                this.mapEditorService.isEditing = true;
                this.lastMapEditorClickEvent = Date.now();
                this.measureTooltip && this.measureTooltip
                    .setContent(this.getMeasureTooltipText());
            });
            fromEvent(this.mapService.map, "editable:drawing:end").subscribe(event => {
                this.measureTooltip && this.cancelMeasurement();
                this.editMode === 'measurePolyline' && (this.editMode = null);
            });
            fromEvent(this.mapService.map, "editable:drawing:end").subscribe(event => {
                if (!event["layer"]["options"]["historyDrawn"]) {
                    if (this.editMode === "measurePolyline") {
                    }
                    if (this.editMode === "drawPoint" ||
                        this.editMode === "drawPolyline" ||
                        this.editMode === "drawPolygon" ||
                        this.editMode === "cutPolygon") {
                        if (this.mapService.map["mapeditlayer"] === event["layer"]) {
                            this.setSelectMode();
                        }
                    }
                }
                this.lastMapEditorClickEvent = Date.now();
            });
        }
    }
    public clearHistory(): void {
        this.historyForwardFlag = false;
        this.historyBackwardsFlag = false;
        this.history = {
            history: [],
            pointer: 0
        };
    }
    public addGeoJSON(name: string, geometry?: JSON, srs?: string, style?: any, touchModeFlag?: boolean): void {
        if (this.mapService.layers[name]) {
            this.mapService.layers[name]["eachLayer"](domLayer => {
                if (domLayer.editor) {
                    if (domLayer.editor._drawing) {
                        domLayer.disableEdit();
                        this.mapService.map.editTools.stopDrawing();
                    }
                    else {
                        domLayer.disableEdit();
                    }
                }
            });
            style = style || this.mapService.layers[name]["style"];
            this.mapService.layers[name]["style"] = style || {};
        }
        else {
            this.mapService.layers[name] = L.featureGroup();
            this.mapService.layers[name]["style"] = style || {};
            this.mapService.layers[name].addTo(this.mapService.map);
        }
        if (geometry &&
            (geometry["geometries"] ||
                geometry["features"] ||
                geometry["coordinates"])) {
            if (geometry["type"] === "FeatureCollection") {
                for (let feature of geometry["features"]
                    ? geometry["features"]
                    : [geometry]) {
                    this.drawGeometry(name, feature["geometry"], this.mapService.projections[srs], false, touchModeFlag);
                }
            }
            else if (geometry["type"] === "GeometryCollection") {
                for (let feature of geometry["geometries"]
                    ? geometry["geometries"]
                    : [geometry]) {
                    this.drawGeometry(name, feature, this.mapService.projections[srs], false, touchModeFlag);
                }
            }
            else {
                this.drawGeometry(name, geometry, this.mapService.projections[srs], false, touchModeFlag);
            }
        }
    }
    public drawGeoJSON(name: string, geometry?: JSON, srs?: string, style?: any, touchModeFlag?: boolean): void {
        if (this.mapService.layers[name]) {
            this.mapService.layers[name]["eachLayer"](domLayer => {
                if (domLayer.editor) {
                    if (domLayer.editor._drawing) {
                        domLayer.disableEdit();
                        this.mapService.map.editTools.stopDrawing();
                    }
                    else {
                        domLayer.disableEdit();
                    }
                }
            });
            style = style || this.mapService.layers[name]["style"];
            this.mapService.map.removeLayer(this.mapService.layers[name]);
            delete this.mapService.layers[name];
        }
        this.mapService.layers[name] = L.featureGroup();
        this.mapService.layers[name]["style"] = style || {};
        this.mapService.layers[name].addTo(this.mapService.map);
        if (geometry &&
            (geometry["geometries"] ||
                geometry["features"] ||
                geometry["coordinates"])) {
            if (geometry["type"] === "FeatureCollection") {
                for (let feature of geometry["features"]
                    ? geometry["features"]
                    : [geometry]) {
                    this.drawGeometry(name, feature["geometry"], this.mapService.projections[srs], false, touchModeFlag);
                }
            }
            else if (geometry["type"] === "GeometryCollection") {
                for (let feature of geometry["geometries"]
                    ? geometry["geometries"]
                    : [geometry]) {
                    this.drawGeometry(name, feature, this.mapService.projections[srs], false, touchModeFlag);
                }
            }
            else {
                this.drawGeometry(name, geometry, this.mapService.projections[srs], false, touchModeFlag);
            }
        }
    }
    public drawGeometry(name: string, geometry?: any, srs?: any, drawingModeFlag?: boolean, touchModeFlag?: boolean, selectModeFlag?: boolean, layerAttributes?: any): void {
        if (geometry && geometry["type"]) {
            let enableEdit = this.mapEditorService.isEditable && this.mapEditorService.isEditing
                ? true
                : false;
            let coordinates = [], vector = null, style = {};
            switch (geometry["type"]) {
                case "Polygon":
                    for (let ring of geometry["coordinates"]) {
                        coordinates.push(ring.map(latlng => {
                            latlng = proj4(srs, this.mapService.projections["EPSG:4326"], latlng);
                            return [latlng[1], latlng[0]];
                        }));
                    }
                    coordinates = turfTruncate({
                        type: "Polygon",
                        coordinates: coordinates
                    }, 6, 2, true).coordinates;
                    style = Object.assign({
                        interactive: true,
                        color: this.mapService.linkColor,
                        weight: 2,
                        opacity: 0.7,
                        fill: this.mapEditorService.toolbarIsOpen,
                        fillColor: this.mapService.linkColor,
                        fillOpacity: 0.2
                    }, this.mapService.layers[name]["style"]);
                    if (enableEdit) {
                        if (drawingModeFlag && coordinates.length < 2) {
                            style["historyDrawn"] = true;
                            vector = this.mapService.map.editTools.startPolygon(L.latLng({
                                lat: coordinates[0][0][0],
                                lng: coordinates[0][0][1]
                            }), style);
                            for (let j = 1; j < coordinates[0].length - 1; j++) {
                                vector.editor.push(L.latLng({
                                    lat: coordinates[0][j][0],
                                    lng: coordinates[0][j][1]
                                }));
                            }
                            this.mapService.layers[name]["addLayer"](vector);
                        }
                        else {
                            if (coordinates.length > 0 && coordinates[0].length > 2) {
                                vector = L.polygon(coordinates, style);
                                this.mapService.layers[name]["addLayer"](vector);
                                if (selectModeFlag) {
                                    vector["enableEdit"]();
                                }
                            }
                        }
                    }
                    else {
                        if (coordinates.length > 0 && coordinates[0].length > 2) {
                            vector = L.polygon(coordinates, style);
                            this.mapService.layers[name]["addLayer"](vector);
                        }
                    }
                    if (vector) {
                        if (layerAttributes) {
                            Object.keys(layerAttributes).forEach(key => {
                                vector[key] = layerAttributes[key];
                            });
                        }
                        vector.on("click", event => {
                            event.target["_clickSelected"] = true;
                            this.mapService.map.fireEvent("editable:feature:selected", event.target);
                        });
                    }
                    break;
                case "LineString":
                    coordinates = geometry["coordinates"].map(latlng => {
                        latlng = proj4(srs, this.mapService.projections["EPSG:4326"], latlng);
                        return [latlng[1], latlng[0]];
                    });
                    style = Object.assign({
                        interactive: false,
                        color: this.mapService.linkColor,
                        weight: 2,
                        opacity: 0.7
                    }, this.mapService.layers[name]["style"]);
                    style["fill"] = false;
                    if (enableEdit) {
                        if (drawingModeFlag) {
                            style["historyDrawn"] = true;
                            vector = this.mapService.map.editTools.startPolyline(L.latLng({ lat: coordinates[0][0], lng: coordinates[0][1] }), style);
                            for (let j = 1; j < coordinates.length; j++) {
                                vector.editor.push(L.latLng({ lat: coordinates[j][0], lng: coordinates[j][1] }));
                            }
                            this.mapService.layers[name]["addLayer"](vector);
                        }
                        else {
                            if (coordinates.length > 1) {
                                if (selectModeFlag) {
                                    vector = L.polyline(coordinates, style);
                                    this.mapService.layers[name]["addLayer"](vector);
                                    vector["enableEdit"]();
                                }
                                else {
                                    vector = L.polyline(coordinates, style);
                                    this.mapService.layers[name]["addLayer"](vector);
                                }
                            }
                        }
                    }
                    else {
                        if (coordinates.length > 1) {
                            vector = L.polyline(coordinates, style);
                            this.mapService.layers[name]["addLayer"](vector);
                        }
                    }
                    if (vector) {
                        if (layerAttributes) {
                            Object.keys(layerAttributes).forEach(key => {
                                vector[key] = layerAttributes[key];
                            });
                        }
                        style["interactive"] = true;
                        style["opacity"] = 0;
                        style["fillOpacity"] = 0;
                        let touch = L.geoJSON(turfBuffer(vector.toGeoJSON(), .1, "meters"), style);
                        touch["sourceLayer"] = vector;
                        touch.addTo(this.mapService.map);
                        touch.on("click", event => {
                            event.target["sourceLayer"]["_clickSelected"] = true;
                            this.mapService.map.fireEvent("editable:feature:selected", event.target);
                        });
                        vector.touchLayer = touch;
                        vector.on("remove", () => {
                            this.mapService.map.removeLayer(touch);
                            touch = null;
                        });
                    }
                    break;
                case "Point":
                    coordinates = proj4(srs, this.mapService.projections["EPSG:4326"], geometry["coordinates"]) as any as number[];
                    if (enableEdit) {
                        if (drawingModeFlag || selectModeFlag) {
                            style = Object.assign({
                                icon: L.divIcon({ className: "leaflet-div-icon" })
                            }, this.mapService.layers[name]["style"]);
                            if (drawingModeFlag) {
                                style["historyDrawn"] = true;
                            }
                            vector = L.marker([coordinates[1], coordinates[0]], style);
                            this.mapService.layers[name]["addLayer"](vector);
                            vector["enableEdit"]();
                        }
                        else {
                            style = Object.assign({
                                interactive: true,
                                radius: 5,
                                color: this.mapService.linkColor,
                                weight: 2,
                                opacity: 0.7,
                                fill: true,
                                fillOpacity: 0.01
                            }, this.mapService.layers[name]["style"]);
                            vector = L.circle([coordinates[1], coordinates[0]], style);
                            this.mapService.layers[name]["addLayer"](vector);
                        }
                    }
                    else {
                        style = Object.assign({
                            interactive: true,
                            radius: 6,
                            color: this.mapService.linkColor,
                            weight: 2,
                            opacity: 0.7,
                            fill: true,
                            fillOpacity: 0.01
                        }, this.mapService.layers[name]["style"]);
                        vector = L.circle([coordinates[1], coordinates[0]], style);
                        this.mapService.layers[name]["addLayer"](vector);
                    }
                    if (vector) {
                        if (layerAttributes) {
                            Object.keys(layerAttributes).forEach(key => {
                                vector[key] = layerAttributes[key];
                            });
                        }
                        vector.on("click", event => {
                            event.target["_clickSelected"] = true;
                            this.mapService.map.fireEvent("editable:feature:selected", event.target);
                        });
                    }
                    break;
                case "MultiPolygon":
                    let ip = 0, ipx = geometry["indexOfGeometryInDrawingMode"], ipc = geometry["indexOfGeometryInSelectionMode"];
                    for (let coordinates of geometry["coordinates"]) {
                        layerAttributes = layerAttributes || {};
                        if (geometry["vanitizedIntersections"]) {
                            layerAttributes["vanitizedIntersections"] = geometry["vanitizedIntersections"][ip];
                        }
                        if (geometry["vanitizedSelfIntersections"]) {
                            layerAttributes["vanitizedSelfIntersections"] = geometry["vanitizedSelfIntersections"][ip];
                        }
                        this.drawGeometry(name, { type: "Polygon", coordinates: coordinates }, srs, ipx === ip, touchModeFlag, ipc === ip, layerAttributes);
                        ++ip;
                    }
                    break;
                case "MultiLineString":
                    let im = 0, imx = geometry["indexOfGeometryInDrawingMode"], imc = geometry["indexOfGeometryInSelectionMode"];
                    for (let coordinates of geometry["coordinates"]) {
                        this.drawGeometry(name, { type: "LineString", coordinates: coordinates }, srs, imx === im, touchModeFlag, imc === im, layerAttributes);
                        ++im;
                    }
                    break;
                case "MultiPoint":
                    let io = 0, iox = geometry["indexOfGeometryInDrawingMode"], ioc = geometry["indexOfGeometryInSelectionMode"];
                    for (let coordinates of geometry["coordinates"]) {
                        this.drawGeometry(name, { type: "Point", coordinates: coordinates }, srs, iox === io, touchModeFlag, ioc === io, layerAttributes);
                        ++io;
                    }
                    break;
                default:
            }
        }
    }
    public createSnapshotOfFeatureLayerGroup(): void {
        this.historyForwardFlag = false;
        if (this.history.pointer < this.history.history.length - 1) {
            this.history.history.splice(this.history.pointer + 1, this.history.history.length - this.history.pointer);
        }
        this.history.history.push(this.layerGroupAsGeoJSON(this.mapEditorService.layerName, "EPSG:25832"));
        if (this.history.history.length < 2) {
            this.geometryInSavedState = this.history.history[0];
        }
        if (this.history.history.length > 20) {
            this.history.history.splice(0, 1);
        }
        this.history.pointer = this.history.history.length - 1;
        if (this.history.history.length > 1) {
            this.historyBackwardsFlag = true;
        }
    }
    public layerGroupAsGeoJSON(layergroup: string, srs: string, flags?: boolean): any {
        let geojson: any = null;
        if (this.mapService.layers[layergroup]) {
            geojson = {
                type: "GeometryCollection",
                geometries: [
                    {
                        type: "MultiPolygon",
                        coordinates: [],
                        indexOfGeometryInDrawingMode: -1,
                        indexOfGeometryInSelectionMode: -1
                    },
                    {
                        type: "MultiLineString",
                        coordinates: [],
                        indexOfGeometryInDrawingMode: -1,
                        indexOfGeometryInSelectionMode: -1
                    },
                    {
                        type: "MultiPoint",
                        coordinates: [],
                        indexOfGeometryInDrawingMode: -1,
                        indexOfGeometryInSelectionMode: -1
                    }
                ]
            };
            if (this.mapService.layers[layergroup]) {
                this.mapService.layers[layergroup]["eachLayer"](domLayer => {
                    let layer = domLayer.toGeoJSON();
                    switch (layer.geometry["type"]) {
                        case "Polygon":
                            if (domLayer.editor && domLayer.editor._drawing) {
                                geojson.geometries[0].indexOfGeometryInDrawingMode =
                                    geojson.geometries[0].coordinates.length;
                            }
                            if (domLayer._clickSelected) {
                                geojson.geometries[0].indexOfGeometryInSelectionMode =
                                    geojson.geometries[0].coordinates.length;
                            }
                            let coordinates = [];
                            for (let ring of layer.geometry["coordinates"]) {
                                coordinates.push(ring.map(latlng => {
                                    return proj4(this.mapService.projections["EPSG:4326"], this.mapService.projections[srs], latlng);
                                }));
                            }
                            geojson.geometries[0].coordinates.push(coordinates);
                            if (flags) {
                                geojson.geometries[0].vanitizedSelfIntersections = geojson.geometries[0].vanitizedSelfIntersections || [];
                                geojson.geometries[0].vanitizedSelfIntersections.push(domLayer.vanitizedSelfIntersections);
                                geojson.geometries[0].vanitizedIntersections = geojson.geometries[0].vanitizedIntersections || [];
                                geojson.geometries[0].vanitizedIntersections.push(domLayer.vanitizedIntersections);
                            }
                            break;
                        case "LineString":
                            if (domLayer.editor && domLayer.editor._drawing) {
                                geojson.geometries[1].indexOfGeometryInDrawingMode =
                                    geojson.geometries[1].coordinates.length;
                            }
                            if (domLayer._clickSelected) {
                                geojson.geometries[1].indexOfGeometryInSelectionMode =
                                    geojson.geometries[1].coordinates.length;
                            }
                            geojson.geometries[1].coordinates.push(layer.geometry["coordinates"].map(latlng => {
                                return proj4(this.mapService.projections["EPSG:4326"], this.mapService.projections[srs], latlng);
                            }));
                            break;
                        case "Point":
                            if (domLayer.editor && domLayer.editor._drawing) {
                                geojson.geometries[2].indexOfGeometryInDrawingMode =
                                    geojson.geometries[2].coordinates.length;
                            }
                            if (domLayer._clickSelected) {
                                geojson.geometries[2].indexOfGeometryInSelectionMode =
                                    geojson.geometries[2].coordinates.length;
                            }
                            geojson.geometries[2].coordinates.push(proj4(this.mapService.projections["EPSG:4326"], this.mapService.projections[srs], layer.geometry["coordinates"]));
                            break;
                        default:
                            if (this.debugMode) {
                                console.log("layerGroupAsGeoJSON");
                            }
                    }
                });
            }
            for (let j = 0; j < geojson.geometries.length; ++j) {
                if (geojson.geometries[j].indexOfGeometryInDrawingMode === -1) {
                    delete geojson.geometries[j].indexOfGeometryInDrawingMode;
                }
                if (geojson.geometries[j].indexOfGeometryInSelectionMode === -1) {
                    delete geojson.geometries[j].indexOfGeometryInSelectionMode;
                }
                if (geojson.geometries[j].coordinates.length < 1) {
                    geojson.geometries.splice(j, 1);
                    --j;
                }
            }
        }
        return geojson;
    }
    public sliceGeoJSONFeature(feature: any, intersection: any) {
        let slicedFeature = null;
        try {
            if (feature
                && intersection) {
                if (this.debugMode) {
                    this.drawGeometry("debug", intersection.geometry, this.mapService.projections["EPSG:4326"]);
                    this.drawGeometry("debugfeature", feature.geometry, this.mapService.projections["EPSG:4326"]);
                }
                slicedFeature = turfTruncate(turfDifference(turfTruncate(feature, 6, 2, true), turfTruncate(intersection, 6, 2, true)), 6, 2, true);
            }
        }
        catch (e) {
            if (this.debugMode) {
                console.log("error in creation of turfDifference:", e, turfTruncate(feature, 6, 2, true), turfTruncate(intersection, 6, 2, true));
            }
        }
        return slicedFeature;
    }
    public sliceGeoJSON(geoJSON: any, intersectionGeoJSON: any) {
        let featureGeoJSON: any = null;
        switch (intersectionGeoJSON.geometry.type) {
            case "GeometryCollection":
                intersectionGeoJSON.geometry.geometries.forEach(geometry => {
                    geoJSON = this.sliceGeoJSON(geoJSON, {
                        type: "Feature",
                        geometry: geometry,
                        properties: {}
                    });
                });
                break;
            case "MultiPolygon":
                intersectionGeoJSON.geometry.coordinates.forEach(coordinates => {
                    geoJSON = this.sliceGeoJSON(geoJSON, {
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: coordinates
                        },
                        properties: {}
                    });
                });
                break;
            case "MultiLineString":
                intersectionGeoJSON.geometry.coordinates.forEach(coordinates => {
                    geoJSON = this.sliceGeoJSON(geoJSON, {
                        type: "Feature",
                        geometry: {
                            type: "LineString",
                            coordinates: coordinates
                        },
                        properties: {}
                    });
                });
                break;
            case "MultiPoint":
                intersectionGeoJSON.geometry.coordinates.forEach(coordinates => {
                    geoJSON = this.sliceGeoJSON(geoJSON, {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: coordinates
                        },
                        properties: {}
                    });
                });
                break;
            case "Polygon":
                geoJSON = this.sliceGeoJSONFeature(geoJSON, {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: intersectionGeoJSON.geometry.coordinates
                    },
                    properties: {}
                });
                break;
            case "LineString":
                geoJSON = this.sliceGeoJSONFeature(geoJSON, {
                    type: "Feature",
                    geometry: {
                        type: "LineString",
                        coordinates: intersectionGeoJSON.geometry.coordinates
                    },
                    properties: {}
                });
                break;
            case "Point":
                geoJSON = this.sliceGeoJSONFeature(geoJSON, {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: intersectionGeoJSON.geometry.coordinates
                    },
                    properties: {}
                });
                break;
            default:
                return geoJSON;
        }
        return geoJSON;
    }
    public vanitizeFeature(layer: any, layergroup: string): boolean {
        let modified: boolean = false, geoJSON: any = null;
        if (!layer.vanitizedSelfIntersections) {
            geoJSON = layer.toGeoJSON();
            if (geoJSON.geometry.type === "Polygon") {
                if (geoJSON.geometry.coordinates[0].length > 3) {
                    try {
                        // Koordinatengenauigkeit
                        let toBeTruncated = false;
                        for (let i = 0; !toBeTruncated && i < geoJSON.geometry.coordinates[0].length; i++) {
                            if (geoJSON.geometry.coordinates[0][i][0].toString().split(".")[1].length > 6
                                || geoJSON.geometry.coordinates[0][i][1].toString().split(".")[1].length > 6) {
                                toBeTruncated = true;
                            }
                        }
                        if (toBeTruncated) {
                            geoJSON = turfTruncate(geoJSON, 6, 2, true);
                            modified = true;
                        }
                        // Self-Intersections
                        if (geoJSON
                            && turfKinks(geoJSON).features.length > 0) {
                            geoJSON = turfBuffer(geoJSON, 0, "meters");
                            modified = true;
                        }
                        // Identische Koordinaten
                        let previous = [];
                        geoJSON.geometry.coordinates[0] = geoJSON.geometry.coordinates[0].filter(element => {
                            if (previous[0] === element[0]
                                && previous[1] === element[1]) {
                                modified = true;
                                return false;
                            }
                            else {
                                previous = element;
                                return true;
                            }
                        });
                        // Gültiges Polygon
                        if (geoJSON.geometry.coordinates[0].length < 4) {
                            geoJSON = null;
                            modified = true;
                        }
                    }
                    catch (e) {
                        if (this.debugMode) {
                            console.log("Fehler in vanitizeSelfIntersections", e);
                        }
                    }
                }
                else {
                    geoJSON = null;
                    modified = true;
                }
            }
        }
        layer.vanitizedSelfIntersections = true;
        if (modified) {
            let attributes = {
                edited: layer.edited,
                vanitizedSelfIntersections: true,
                vanitizedIntersections: layer.vanitizedIntersections,
                subtract: layer.subtract,
            };
            this.mapService.layers[layergroup]["removeLayer"](layer);
            layer.remove();
            if (geoJSON) {
                this.drawGeometry(layergroup, geoJSON.geometry, this.mapService.projections["EPSG:4326"], null, null, null, attributes);
            }
        }
        return modified;
    }
    ;
    public vanitizeFeatures(layergroup: string): boolean {
        let modified: boolean = false;
        this.mapService.layers[layergroup]["eachLayer"](layer => {
            modified = this.vanitizeFeature(layer, layergroup) || modified;
        });
        return modified;
    }
    public vanitizeFeatureIntersections(layergroup: string): boolean {
        let modified: boolean = false;
        this.vanitizeCnt = 0;
        this.mapService.layers[layergroup]["eachLayer"](layergroupLayer => {
            this.vanitizeCnt++;
        });
        this.vanitizePos = this.vanitizeCnt;
        const calcIntersections = (layers: Record<string, Layer>, layergroup: string) => {
            let modified = false;
            layers[layergroup]["eachLayer"](layer => {
                modified = this.calcFeatureIntersectionVanitization(layer, layergroup) || modified;
                this.vanitizedPercent = parseInt(((1 - (--this.vanitizePos / this.vanitizeCnt)) * 100).toFixed(), 10);
            });
            return modified;
        };
        modified = calcIntersections(this.mapService.layers, layergroup)
            && this.execFeatureGroupIntersectionVanitization(layergroup);
        // ensure that new union layers do not overlap by reiteration until nothing is calculated
        for (let recursionsDone = 0; modified
            && this.ensureVanitizeIntersectionsRecursionLimit(recursionsDone)
            && calcIntersections(this.mapService.layers, layergroup); recursionsDone++) {
            this.execFeatureGroupIntersectionVanitization(layergroup);
        }
        return modified;
    }
    public ensureVanitizeIntersectionsRecursionLimit(doneSoFar: number) {
        if (doneSoFar < this.vanitizeIntersectionsRecursionLimit)
            return true;
        else
            throw Object.assign(new RangeError("vanitize intersections recursion limit reached"), { name: "VanitizeIntersectionsRecursionLimitReached" });
    }
    public _vanitizeFeatureGroup(layergroup: string): boolean {
        let modified: boolean = false;
        if (this.mapService.layers[layergroup]) {
            this.mapService.layers[layergroup]["eachLayer"](layer => {
                layer.edited = null;
                if (layer.options.editOptions) {
                    layer.disableEdit();
                    layer.edited = true;
                    this.vanitizeCnt = 1;
                    this.vanitizePos = this.vanitizeCnt;
                    modified = this.vanitizeFeatures(layergroup) || modified;
                }
            });
            this.mapService.layers[layergroup]["eachLayer"](layer => {
                if (layer.edited) {
                    modified = this.calcFeatureIntersectionVanitization(layer, layergroup) || modified;
                    this.vanitizedPercent = parseInt(((1 - (--this.vanitizePos / this.vanitizeCnt)) * 100).toFixed(), 10);
                    this.execFeatureGroupIntersectionVanitization(layergroup);
                    modified = this.calcFeatureDifference(layer, layergroup) || modified;
                    this.execFeatureGroupDifference(layergroup);
                }
            });
            modified = this.vanitizeFeatures(layergroup) || modified;
            modified = this.vanitizeFeatureIntersections(layergroup) || modified;
            this.mapService.layers[layergroup]["eachLayer"](layer => {
                layer.vanitizedSelfIntersections = true;
                layer.vanitizedIntersections = true;
            });
        }
        return modified;
    }
    public vanitizeFeatureGroup(layergroup: string): Promise<boolean> {
        this.vanitizing = true;
        const result = Promise.resolve().then(() => this._vanitizeFeatureGroup(layergroup));
        return result.then(...Array(2).fill(() => (this.vanitizing = false,
            result)));
    }
    public execFeatureIntersectionVanitizationGeom(layer: any, layergroup: string): any {
        let newLayer = layer.toGeoJSON();
        if (layer.intersections) {
            layer.intersections.forEach(intersection => {
                newLayer = this.sliceGeoJSON(newLayer, intersection);
            });
        }
        return newLayer;
    }
    public execFeatureIntersectionVanitization(layer: any, layergroup: string): boolean {
        let modified = false;
        if (layer.intersections) {
            let newLayer = this.execFeatureIntersectionVanitizationGeom(layer, layergroup);
            this.mapService.layers[layergroup]["removeLayer"](layer);
            layer.remove();
            if (newLayer) {
                modified = true;
                this.drawGeometry(layergroup, newLayer.geometry, this.mapService.projections["EPSG:4326"]);
            }
        }
        return modified;
    }
    public execFeatureGroupIntersectionVanitization(layergroup: string): boolean {
        let modified = false;
        this.mapService.layers[layergroup]["eachLayer"](layer => {
            if (layer.unions) {
                layer.delete = true;
                let newLayer = layer.toGeoJSON();
                layer.unions.forEach(union => {
                    let unionLayer = null;
                    this.mapService.layers[layergroup]["eachLayer"](l => {
                        if (l._leaflet_id === union) {
                            l.delete = true;
                            unionLayer = l;
                        }
                    });
                    if (newLayer
                        && unionLayer) {
                        newLayer = turfTruncate(turfUnion(turfTruncate(newLayer, 6, 2, true), turfTruncate(this.execFeatureIntersectionVanitizationGeom(unionLayer, layergroup), 6, 2, true)), 6, 2, true);
                    }
                });
                if (newLayer) {
                    modified = true;
                    this.drawGeometry(layergroup, newLayer.geometry, this.mapService.projections["EPSG:4326"]);
                }
            }
        });
        this.mapService.layers[layergroup]["eachLayer"](layer => {
            if (layer.delete) {
                this.mapService.layers[layergroup]["removeLayer"](layer);
                layer.remove();
            }
        });
        this.mapService.layers[layergroup]["eachLayer"](layer => {
            if (layer.intersections) {
                modified = this.execFeatureIntersectionVanitization(layer, layergroup) || modified;
            }
        });
        return modified;
    }
    public calcFeatureIntersectionVanitization(layer: any, layergroup: string): boolean {
        let modified = false;
        if (!layer.subtract && !layer.vanitizedIntersections) {
            layer.vanitizedIntersections = true;
            let layerGeoJSON = layer.toGeoJSON();
            if (layerGeoJSON
                && layerGeoJSON.geometry
                && layerGeoJSON.geometry.type === "Polygon") {
                this.mapService.layers[layergroup]["eachLayer"](layergroupLayer => {
                    if (!layergroupLayer.intersections
                        && !layergroupLayer.unions
                        && !layergroupLayer.subtract
                        && layergroupLayer._leaflet_id !== layer._leaflet_id) {
                        try {
                            let layergroupLayerGeoJSON = layergroupLayer.toGeoJSON();
                            if (layergroupLayerGeoJSON.geometry.type === "Polygon") {
                                let intersection = turfIntersect(layergroupLayerGeoJSON, layerGeoJSON);
                                if (intersection) {
                                    modified = true;
                                    let intersectionGeoJSONArea = turfArea(intersection) || 0;
                                    let intersectionLayerGeoJSONArea = turfArea(layergroupLayerGeoJSON) || 0.01;
                                    if (intersectionGeoJSONArea / intersectionLayerGeoJSONArea < .07) {
                                        layer.intersections = layer.intersections || [];
                                        let intersection = turfTruncate(turfIntersect(turfTruncate(turfBuffer(layergroupLayerGeoJSON, .7, "meters"), 6, 2, true), turfTruncate(turfBuffer(layerGeoJSON, .7, "meters"), 6, 2, true)), 6, 2, true);
                                        layer.intersections.push(intersection);
                                    }
                                    else {
                                        layer.unions = layer.unions || [];
                                        layer.unions.push(layergroupLayer._leaflet_id);
                                    }
                                }
                            }
                        }
                        catch (e) {
                            if (this.debugMode) {
                                console.log("error in intersection", e, layerGeoJSON);
                            }
                        }
                    }
                });
            }
        }
        return modified;
    }
    ;
    public execFeatureGroupDifference(layergroupKey: string): boolean {
        let modified = false;
        const layergroup = <LayerGroup>this.mapService.layers[layergroupKey];
        const findLayer = (id: number) => {
            let match = null;
            layergroup.eachLayer(layer => {
                if (!match && layer["_leaflet_id"] === id) {
                    match = layer;
                }
            });
            return match;
        };
        layergroup.eachLayer(layer => {
            const difference = <Array<number>>layer["difference"];
            if (layer["subtract"] && difference && difference.length > 0) {
                const subtrahend = turfTruncate((<LPolygon>layer).toGeoJSON(), 6, 2, true);
                difference.forEach(layerId => {
                    const minuendlayer = findLayer(layerId);
                    if (minuendlayer) {
                        minuendlayer["delete"] = true;
                        const diff = turfDifference(turfTruncate(this.execFeatureIntersectionVanitizationGeom(minuendlayer, layergroupKey), 6, 2, true), subtrahend);
                        if (diff) {
                            modified = true;
                            this.drawGeometry(layergroupKey, turfTruncate(diff, 6, 3, true).geometry, this.mapService.projections["EPSG:4326"]);
                        }
                    }
                });
            }
        });
        layergroup.eachLayer(layer => {
            if (layer["delete"] || layer["subtract"]) {
                layergroup.removeLayer(layer);
                layer.remove();
            }
        });
        return modified;
    }
    public calcFeatureDifference(layer: Layer, layergroupKey: string): boolean {
        let modified = false;
        const layergroup = <LayerGroup>this.mapService.layers[layergroupKey];
        if (layer["subtract"] && !layer["differenceCalculated"]) {
            layer["differenceCalculated"] = true;
            const layerGeoJSON: Feature<Polygon> = <any>(<LPolygon>layer).toGeoJSON();
            if (layerGeoJSON.geometry.type === "Polygon") {
                layergroup.eachLayer(otherlayer => {
                    if (!otherlayer["subtract"]
                        && otherlayer["_leaflet_id"] !== layer["_leaflet_id"]
                        && !otherlayer["difference"]) {
                        try {
                            const otherlayerGeoJSON: Feature<Polygon> = <any>(<LPolygon>layer).toGeoJSON();
                            if (otherlayerGeoJSON.geometry.type === "Polygon") {
                                const intersection = turfIntersect(otherlayerGeoJSON, layerGeoJSON);
                                if (intersection) {
                                    modified = true;
                                    if (!layer["difference"]) {
                                        layer["difference"] = [];
                                    }
                                    layer["difference"].push(otherlayer["_leaflet_id"]);
                                }
                            }
                        }
                        catch (e) {
                            if (this.debugMode) {
                                console.error("error in difference", e, layerGeoJSON);
                            }
                        }
                    }
                });
            }
        }
        return modified;
    }
}
