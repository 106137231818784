<div 
    *ngIf="visibility==='true'"
    class="uw-message"
    [style.zIndex]="zIndex"
    [class.fixed-height]="height!=='auto'"
    [class.uw-message-warning]="warning==='true'"
    [style.top.px]="top"
    [style.height]="height === 'auto' ? 'auto' : height + 'px'"
    [style.maxHeight]="height === 'auto' ? 'none' : height + 'px'"
>
    <div
        class="container-fluid"
        [style.paddingLeft.px]="paddingLeft"
        [style.paddingRight.px]="paddingRight"
    >
        <div class="row">
            <div class="col-md-15">
                <div
                    *ngIf="closeable==='true'"
                    (click)="close($event)"
                    class="uw-close"
                ><i
                    class="fa fa-close"
                    title="Meldung schliessen"
                ></i></div>
                <div 
                    class="message"
                    [style.marginRight.px]="closeable==='true' ? 24 : 0"
                >
                    <ng-content select="article"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>
