import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoFibsresult from "./interface";
import FischinfoBefischung from "./../../fischinfo/befischung/model";
import FischinfoArtenabundanzleitartscore from "./../../fischinfo/artenabundanzleitartscore/model";
import FischinfoAltersstrukturleitartscore from "./../../fischinfo/altersstrukturleitartscore/model";
@fn.DataModel
export default class FischinfoFibsresult extends DataModel implements IFischinfoFibsresult {
    constructor(obj?: object | IFischinfoFibsresult) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoFibsresult): FischinfoFibsresult {
        return new FischinfoFibsresult(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoFibsresult): FischinfoFibsresult {
        Object.assign(this, obj);
        obj && obj["fischinfoBefischungByBefischung_id"] && (this.fischinfoBefischungByBefischung_id = FischinfoBefischung.deserialize(obj["fischinfoBefischungByBefischung_id"]));
        obj && obj["fischinfoArtenabundanzleitartscoreListByForeignFibsresult_id"] && obj["fischinfoArtenabundanzleitartscoreListByForeignFibsresult_id"].length && (this.fischinfoArtenabundanzleitartscoreListByForeignFibsresult_id = obj["fischinfoArtenabundanzleitartscoreListByForeignFibsresult_id"].map(FischinfoArtenabundanzleitartscore.deserialize));
        obj && obj["fischinfoAltersstrukturleitartscoreListByForeignFibsresult_id"] && obj["fischinfoAltersstrukturleitartscoreListByForeignFibsresult_id"].length && (this.fischinfoAltersstrukturleitartscoreListByForeignFibsresult_id = obj["fischinfoAltersstrukturleitartscoreListByForeignFibsresult_id"].map(FischinfoAltersstrukturleitartscore.deserialize));
        return this;
    }

    /**
     * @required
     * @type {boolean}
     */
    @fn.DataMember({ name: "abwerten", type: Boolean, databaseType: "boolean", nullable: false, memberType: "" })
    public abwerten: boolean;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "befischung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public befischung_id?: number;

    /**
     * @type {string} text
     */
    @fn.DataMember({ name: "begruendung_zustandsklasse_expert", type: String, databaseType: "text", nullable: true, memberType: "" })
    @fn.Label("Bemerkung zur Plausibilisierung")
    public begruendung_zustandsklasse_expert?: string;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "datum_zustandsklasse_expert", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    @fn.Label("Datum der Plausibilisierung")
    public datum_zustandsklasse_expert?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "ecologicalqualityratio", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Ecological Quality Ratio (EQR)")
    public ecologicalqualityratio?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "explanation", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public explanation?: string;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "gesamtartenzahlreferenzfischzoenose", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public gesamtartenzahlreferenzfischzoenose?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "gesamtbewertung", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Gesamtbewertung")
    public gesamtbewertung?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "gesamtindividuenzahlallebefischungenanprobestelle", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public gesamtindividuenzahlallebefischungenanprobestelle?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "individuendichte", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public individuendichte?: number;

    /**
     * @type {number} timestamp without time zone
     */
    @fn.DataMember({ name: "last_update", type: Number, databaseType: "timestamp without time zone", nullable: true, memberType: "" })
    public last_update?: number;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "message", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public message?: string;

    /**
     * @required
     * @type {boolean}
     */
    @fn.DataMember({ name: "nwb", type: Boolean, databaseType: "boolean", nullable: false, memberType: "" })
    public nwb: boolean;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "oekologischerzustand", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public oekologischerzustand?: string;

    @fn.Label("Ökologisches Potenzial")
    public get potenzial(): number {
        return this.zustandsklasse;
    }

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp20", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp20?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp21", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp21?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp22", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp22?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp24", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp24?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp25", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp25?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp26", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp26?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp27", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp27?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp28", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp28?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp65", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp65?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp66", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp66?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp67", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp67?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp70", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp70?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp73", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp73?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenahme_bp76", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenahme_bp76?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenbarschrotaugenabundanz", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenbarschrotaugenabundanz?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probencdi", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probencdi?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenhabitatgildenindiff", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenhabitatgildenindiff?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenhabitatgildenrheophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenhabitatgildenrheophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenhabitatgildenstagnophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenhabitatgildenstagnophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenhoechsterrefanteilnichtnachgewiesenerarten", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenhoechsterrefanteilnichtnachgewiesenerarten?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenreproduktionsgildenlithopelagophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenreproduktionsgildenlithopelagophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenreproduktionsgildenlithophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenreproduktionsgildenlithophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenreproduktionsgildenmarine", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenreproduktionsgildenmarine?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenreproduktionsgildenostracophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenreproduktionsgildenostracophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenreproduktionsgildenpelagophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenreproduktionsgildenpelagophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenreproduktionsgildenphytolithophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenreproduktionsgildenphytolithophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenreproduktionsgildenphytophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenreproduktionsgildenphytophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenreproduktionsgildenpsammophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenreproduktionsgildenpsammophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probenreproduktionsgildenspeleophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probenreproduktionsgildenspeleophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probentrophiegildenfiltrierer", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probentrophiegildenfiltrierer?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probentrophiegildenherbivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probentrophiegildenherbivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probentrophiegildeninvertipiscivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probentrophiegildeninvertipiscivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probentrophiegildeninvertivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probentrophiegildeninvertivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probentrophiegildenomnivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probentrophiegildenomnivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probentrophiegildenpiscivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probentrophiegildenpiscivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "probentrophiegildenplanktivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public probentrophiegildenplanktivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refanzahlanaoderpotamodrom", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refanzahlanaoderpotamodrom?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refanzahlbegleitarten", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refanzahlbegleitarten?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refanzahltypspezifischearten", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refanzahltypspezifischearten?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refanzahltypspezifischeartendavonleitarten", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refanzahltypspezifischeartendavonleitarten?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refbarschrotaugenabundanz", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refbarschrotaugenabundanz?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "referenz_aq20", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public referenz_aq20?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "referenz_aq21", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public referenz_aq21?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "referenz_aq22", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public referenz_aq22?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "referenzanteilesumme", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public referenzanteilesumme?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "reffischregionsgesamtindex", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public reffischregionsgesamtindex?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refhabitatgildenindifferent", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refhabitatgildenindifferent?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refhabitatgildenrheophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refhabitatgildenrheophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refhabitatgildenstagnophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refhabitatgildenstagnophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refmigrationsindexohneaal", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refmigrationsindexohneaal?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refreproduktionsgildenlithopelagophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refreproduktionsgildenlithopelagophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refreproduktionsgildenlithophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refreproduktionsgildenlithophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refreproduktionsgildenmarine", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refreproduktionsgildenmarine?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refreproduktionsgildenostracophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refreproduktionsgildenostracophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refreproduktionsgildenpelagophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refreproduktionsgildenpelagophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refreproduktionsgildenphytolithophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refreproduktionsgildenphytolithophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refreproduktionsgildenphytophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refreproduktionsgildenphytophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refreproduktionsgildenpsammophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refreproduktionsgildenpsammophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "refreproduktionsgildenspeleophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public refreproduktionsgildenspeleophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "reftrophiegildenfiltrierer", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public reftrophiegildenfiltrierer?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "reftrophiegildenherbivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public reftrophiegildenherbivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "reftrophiegildeninvertipiscivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public reftrophiegildeninvertipiscivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "reftrophiegildeninvertivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public reftrophiegildeninvertivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "reftrophiegildenomnivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public reftrophiegildenomnivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "reftrophiegildenpiscivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public reftrophiegildenpiscivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "reftrophiegildenplankivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    public reftrophiegildenplankivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorealtersstruktur", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Altersstruktur (Reproduktion)")
    public scorealtersstruktur?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoreanapotamodromearten", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Anzahl anadromer und potamodromer Arten")
    public scoreanapotamodromearten?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoreartenabundanzundgildenverteilung", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Artenabundanz und Gildenverteilung")
    public scoreartenabundanzundgildenverteilung?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoreartenundgildeninventargesamt", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Arten- und Gildeninventar")
    public scoreartenundgildeninventargesamt?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorebarschundrotaugenabundanz", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Barsch-/Rotaugen-Abundanz")
    public scorebarschundrotaugenabundanz?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorebegleitarten", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Anzahl Begleitarten")
    public scorebegleitarten?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorecdi", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("CDI")
    public scorecdi?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoredominantearten", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Dominante Arten")
    public scoredominantearten?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorefischregionsindex", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Fischregion")
    public scorefischregionsindex?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorehabitatgildengreossergleich1prozentrefanteil", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Anzahl Habitatgilden >= 1%")
    public scorehabitatgildengreossergleich1prozentrefanteil?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorehabitatgildenreferenzfern", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Anzahl referenzferner Habitatgilden")
    public scorehabitatgildenreferenzfern?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorehabitatgildenrheophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Rheophile")
    public scorehabitatgildenrheophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorehabitatgildenstagnophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Stagnophile")
    public scorehabitatgildenstagnophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoreleitartenindex", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Leitartenindex")
    public scoreleitartenindex?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoremigrationsindex", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Migration")
    public scoremigrationsindex?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorereferenzfernearten", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Anzahl referenzferner Arten")
    public scorereferenzfernearten?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorereproduktionsgildengreossergleich1prozentrefanteil", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Anzahl Reproduktionsgilden >= 1%")
    public scorereproduktionsgildengreossergleich1prozentrefanteil?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorereproduktionsgildenlithophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Lithophile")
    public scorereproduktionsgildenlithophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorereproduktionsgildenphytophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Phytophile")
    public scorereproduktionsgildenphytophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorereproduktionsgildenpsammophile", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Psammophile")
    public scorereproduktionsgildenpsammophile?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scorereproduktionsgildenreferenzfern", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Anzahl referenzferner Reproduktionsgilden")
    public scorereproduktionsgildenreferenzfern?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoretrophiegildengreossergleich1prozentrefanteil", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Anzahl Trophiegilden >= 1%")
    public scoretrophiegildengreossergleich1prozentrefanteil?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoretrophiegildeninvertivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Invertivore")
    public scoretrophiegildeninvertivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoretrophiegildenomnivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Omnivore")
    public scoretrophiegildenomnivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoretrophiegildenpiscivore", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Piscivore")
    public scoretrophiegildenpiscivore?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoretrophiegildenreferenzfern", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Anzahl referenzferner Trophiegilden")
    public scoretrophiegildenreferenzfern?: number;

    /**
     * @type {number} double precision
     */
    @fn.DataMember({ name: "scoretypspezifischearten", type: Number, databaseType: "double precision", nullable: true, memberType: "" })
    @fn.Label("Typspezifische Arten")
    public scoretypspezifischearten?: number;

    /**
     * @required
     * @type {boolean}
     */
    @fn.DataMember({ name: "warnungandrompotamodrom", type: Boolean, databaseType: "boolean", nullable: false, memberType: "" })
    @fn.Label("Warnung diadrom")
    public warnungandrompotamodrom: boolean;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "warnungindividuen", type: Number, databaseType: "integer", nullable: false, memberType: "" })
    @fn.Label("Warnung Individuen")
    public warnungindividuen: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "zustandsklasse", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    @fn.Label("Ökologischer Zustand")
    public zustandsklasse?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "zustandsklasse_expert", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    @fn.Label("plausibilisierte Zustandklasse")
    public zustandsklasse_expert?: number;

    /**
     * @type {FischinfoBefischung}
     */
    @fn.DataMember({ name: "fischinfoBefischungByBefischung_id", type: FischinfoBefischung, nullable: true, memberType: "JOIN", joinProperty: "befischung_id" })
    public fischinfoBefischungByBefischung_id?: FischinfoBefischung;

    /**
     * @type {Array<FischinfoArtenabundanzleitartscore>}
     */
    @fn.DataMember({ name: "fischinfoArtenabundanzleitartscoreListByForeignFibsresult_id", type: [FischinfoArtenabundanzleitartscore], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoArtenabundanzleitartscoreListByForeignFibsresult_id?: Array<FischinfoArtenabundanzleitartscore>;

    /**
     * @type {Array<FischinfoAltersstrukturleitartscore>}
     */
    @fn.DataMember({ name: "fischinfoAltersstrukturleitartscoreListByForeignFibsresult_id", type: [FischinfoAltersstrukturleitartscore], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoAltersstrukturleitartscoreListByForeignFibsresult_id?: Array<FischinfoAltersstrukturleitartscore>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
