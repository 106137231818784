export default class DataModel {
    // Placeholder will be overridden by model class
    public static deserialize<T extends object, U extends DataModel>(obj?: T): U { return null; }

    // Placeholder will be overridden by model class
    public deserialize<T extends object, U extends DataModel>(this: U, obj: T): U { return null; }

    // Placeholder will be overriden by ./../decorators/data-model/decorator.ts
    public listModelProperties(view?: string): { [prop: string]: any } { return {}; };
    // Placeholder will be overriden by ./../decorators/data-model/decorator.ts
    public label(key: string): string { return ""; };

    // Placeholder will be overriden by ./../decorators/data-model/decorator.ts
    public infoTag(key: string): string { return ""; };

    // Placeholder will be overriden by ./../decorators/data-model/decorator.ts
    public modelData<T>(): Partial<T> { return {}; };

    public toJSON<T extends DataModel>(this: T): Partial<T> {
        const propList = this.listModelProperties();
        return Object.keys(propList)
            // .map((key) => ([key, Object.getOwnPropertyDescriptor(propList, key)] as [string, PropertyDescriptor]))
            .map((key) => ([key, propList[key]] /* as [string, PropertyDescriptor] */))
            .reduce((jsonObj, [key, descriptor]) => {
                if (descriptor && /*propList[key]*/descriptor.dataMember) {
                    try {
                        const val = /*propList[key]*/descriptor.dataMember.convertValueToJSON((this as any)[key]);
                        jsonObj[key] = val;
                    } catch (error) {
                        console.error(`Error calling getter ${key}`, error);
                    }
                }
                return jsonObj
            }, {});
    }
}
