import DecoratorHelper from "./../helper";

export default function DataMember({ name, type, databaseType, nullable, memberType, joinProperty }: {
    name: string,
    type: any,
    databaseType?: string,
    nullable?: boolean,
    memberType?: string,
    joinProperty?: string
}): any {
    return function (target: any, propertyKey: string | symbol) {
        let convertValueToJSON = x => x;

        databaseType = typeof databaseType === "undefined" ? "" : databaseType;
        nullable = typeof nullable === "undefined" ? false : nullable;

        if (databaseType.toString().startsWith("character varying(")) {
            const valueKey = "_" + String(propertyKey);

            if (delete target[propertyKey]) {
                Object.defineProperty(target, propertyKey, {
                    get(this: Object) {
                        return this[valueKey];
                    },
                    set(value) {
                        // if (!target.hasOwnProperty(valueKey)) {
                        Object.defineProperty(target, valueKey, { writable: true });
                        // }
                        this[valueKey] = value;
                    },
                    enumerable: true,
                    configurable: true
                });
            }
        }
        else if (databaseType.toString().startsWith("timestamp")) {
            const valueKey = "_" + String(propertyKey);
            convertValueToJSON = (n: number) => new Date(n).toJSON();
            if (delete target[propertyKey]) {
                Object.defineProperty(target, propertyKey, {
                    get(this: Object) {
                        return this[valueKey];
                    },
                    set(value) {
                        // if (!target.hasOwnProperty(valueKey)) {
                        Object.defineProperty(target, valueKey, { writable: true });
                        // }
                        if (typeof value === "string") {
                            value = Date.UTC(
                                parseInt(value.split("-")[0], 10),
                                parseInt(value.split("-")[1], 10) - 1,
                                parseInt(
                                    value.split("-")[2].split("T")[0].charAt(0) === "0"
                                        ? value.split("-")[2].split("T")[0].charAt(1)
                                        : value.split("-")[2].split("T")[0]
                                    , 10),
                                parseInt(
                                    value.split("T")[1].split(":")[0].charAt(0) === "0"
                                        ? value.split("T")[1].split(":")[0].charAt(1)
                                        : value.split("T")[1].split(":")[0]
                                    , 10)
                                , parseInt(
                                    value.split("T")[1].split(":")[1].charAt(0) === "0"
                                        ? value.split("T")[1].split(":")[1].charAt(1)
                                        : value.split("T")[1].split(":")[1]
                                    , 10),
                                parseInt(
                                    value.split("T")[1].split(":")[2].charAt(0) === "0"
                                        ? value.split("T")[1].split(":")[2].charAt(1)
                                        : value.split("T")[1].split(":")[2]
                                    , 10)
                            );
                        }
                        this[valueKey] = value;
                    },
                    enumerable: true,
                    configurable: true
                });
            }
        }

        // Create new property with getter and setter
        /*  
            let _val = target[propertyKey];
            const getter = function () {
                console.log("setter");
                return _val + 1;
            };
            const setter = function (newVal) {
                console.log("getter");
                _val = newVal;

            };
            if (delete target[propertyKey]) {
                Object.defineProperty(target, propertyKey, {
                    get: getter,
                    set: setter,
                    enumerable: true,
                    configurable: true 
                });
            }
        */

        DecoratorHelper.definePropertyMetadata(target, propertyKey, {
            dataMember: {
                name: name,
                type: type,
                databaseType: databaseType,
                nullable: nullable,
                memberType: memberType,
                joinProperty: joinProperty,
                convertValueToJSON: convertValueToJSON,
            }
        });

    }
}
