import { Component, Input, Optional, OnInit } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoBefischung from "../../models/fischinfo/befischung/model";
import FischinfoGewaesserzustand from "../../models/fischinfo/gewaesserzustand/model";
import { UWTab } from "ng-uw-tabs";
import FischinfoDokument from "../../models/fischinfo/dokument/model";
import DataService from "../data/service";
import FischinfoBeeintraechtigunggesamt from "../../models/fischinfo/beeintraechtigunggesamt/model";
/**
 * Mit dieser Komponente kann der Nutzer die Inhalte einer Untersuchung ansehen und ggf. bearbeiten.
 *
 * Diese Komponente stellt nur die zusätzlichen Informationen des befischten Gewässers dar.
 * Dazu gehören unter Anderem Elemente des Umlandes und der Beeinträchtigungen des Gewässers.
 */
@Component({
    selector: "form-fischinfo-untersuchung-gewaesser-sonstiges",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungGewaesserSonstiges implements OnInit {
    @Input()
    public probenahmestelle: FischinfoProbenahmestelle;
    @Input()
    public untersuchung: FischinfoUntersuchung;
    @Input()
    public modalId: string;
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get isBeobachtung(): boolean {
        return this.formFischinfoUntersuchungService.isBeobachtung(this.untersuchung);
    }
    public get beeintraechtigunggesamt_id(): number {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .beeintraechtigunggesamt_id;
    }
    public set beeintraechtigunggesamt_id(value: number) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoGewaesserzustand>(this.untersuchung, "FischinfoGewaesserzustand")
            .beeintraechtigunggesamt_id = parseInt(String(value), 10) || null;
    }
    public get fischereigenossenschaft(): string {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .fischereigenossenschaft;
    }
    public set fischereigenossenschaft(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoBefischung>(this.untersuchung, "FischinfoBefischung")
            .fischereigenossenschaft = value;
    }
    public get dateiname(): string {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoDokument>(this.untersuchung, "FischinfoDokument")
            .dateiname;
    }
    public set dateiname(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDokument>(this.untersuchung, "FischinfoDokument")
            .dateiname = value;
    }
    public get dateibeschreibung(): string {
        return this.formFischinfoUntersuchungService.modelUtil.getOrDefault<FischinfoDokument>(this.untersuchung, "FischinfoDokument")
            .beschreibung;
    }
    public set dateibeschreibung(value: string) {
        this.formFischinfoUntersuchungService.modelUtil.setOrAdd<FischinfoDokument>(this.untersuchung, "FischinfoDokument")
            .beschreibung = value;
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService,
        @Optional()
        public uwtab: UWTab) {
    }
    ngOnInit() {
        this.dataService
            .getReferenceTable("beeintraechtigunggesamt", FischinfoBeeintraechtigunggesamt, "id")
            .then(beeintraechtigunggesamt => {
                let refList = [];
                Object.keys(beeintraechtigunggesamt).forEach(key => {
                    refList.push('[' + beeintraechtigunggesamt[key].id + ',"' + beeintraechtigunggesamt[key].bezeichnung + '"]');
                });
                this.beeintraechtigunggesamt_idRefJsonItems = "[" + refList.join(",") + "]";
            });
    }
    public beeintraechtigunggesamt_idRefJsonItems: string;
    public get inActiveTab() {
        return !!this.uwtab && this.uwtab.active;
    }
}
