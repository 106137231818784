
<!-- #region Warnmeldung(en) //-->
<uw-modal-window-message
    [visibility]="(error != null).toString()"
    (visibilityChange)="error = $event === 'false' ? null : error"
    closeable="true"
    warning="true"
>
    <article [ngSwitch]="true">
        <p *ngSwitchCase="error?.name === 'INVALID_FORMAT'">
            Das Format der Auftrag-Importdaten ist fehlerhaft. Bitte überprüfen Sie Ihre Eingaben.
        </p>
        <p *ngSwitchCase="error?.status === 403">
            Sie haben keine Rechte, den Auftrag-Import auszuführen.
        </p>
        <p *ngSwitchDefault>
            Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
        </p>
    </article>
</uw-modal-window-message>
<!-- #endregion //-->

<!-- #region Formular //-->
<section
    class="uw-tab-body"
>
    <div
        class="container-fluid"
        [style.marginTop.px]="topmargin"
    >
        <div class="row">
            <div class="col-xs-15"><label>Aufträge importieren</label></div>
        </div>

        <section class="form-step row" *ngIf="!error || error.name === 'INVALID_FORMAT'">
            <div class="col-xs-15 space-top">
                <ng-container *ngIf="!running">
                    <div class="row">
                        <div class="col-xs-15">
                            <p>
                                Bitte berücksichtigen Sie, dass die importierten Daten die vorhandenen Daten komplett ersetzen.
                                Falls die importierten Daten unvollständig sind, z.B. durch einen vorherigen gefilterten Export, gehen unter Umständen Daten verloren.
                            </p>
                            <p>
                                Um das zu verhindern, sollte vor dem Import zur Absicherung ein Export der Daten ohne eingestellten Filter
                                (Haken „Aufträge anhand der Tabelle filtern“ entfernen) ausgeführt werden. So können falls notwendig die
                                Daten wiederhergestellt werden.
                            </p>
                        </div>
                    </div>
                    <div class="row space-bottom">
                        <div class="col-xs-15">
                            <uw-fileinput
                                placeholder="Bitte wählen Sie eine CSV-Datei aus"
                                accept="text/csv,application/csv,*.csv"
                                (change)="importAuftragCsv($event)"
                            ></uw-fileinput>
                        </div>
                    </div>
                </ng-container>
                <div class="row space-bottom" *ngIf="running">
                    <div class="col-xs-15">
                        Der Import wird jetzt ausgeführt. Das kann einige Minuten dauern.
                        Die Auftragsliste wird zunächst geleert und nach erfolgter Übernahme aktualisiert.
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>
<!-- #endregion //-->
