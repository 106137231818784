<label 
    *ngIf="label.length > 0"
    class="uw-button-label"
    [title]="title"
>
    {{label}}
    <span *ngIf="infoTag.length > 0"> <sup><i [title]="infoTag" class="fa" [ngClass]="infoTagClass"></i></sup></span>
</label>
<button
    type="button"
    class="uw-button"
    [ngClass]="cssAlignment"
    [title]="title"
    [name]="name"
    [disabled]="disabled"
    (click)="clickOnButton($event)"
>
    <ng-content></ng-content>
</button>
 
