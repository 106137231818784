import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoOberflaechenwasserkoerper from "./interface";
import FischinfoProbenahmestelle from "./../../fischinfo/probenahmestelle/model";
import FibsHmwbnutzung from "../../fibs/hmwbnutzung/model";
@fn.DataModel
export default class FischinfoOberflaechenwasserkoerper extends DataModel implements IFischinfoOberflaechenwasserkoerper {
    constructor(obj?: object | IFischinfoOberflaechenwasserkoerper) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoOberflaechenwasserkoerper): FischinfoOberflaechenwasserkoerper {
        return new FischinfoOberflaechenwasserkoerper(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoOberflaechenwasserkoerper): FischinfoOberflaechenwasserkoerper {
        Object.assign(this, obj);
        obj && obj["fibsHmwbnutzungByHmwbnutzung_id"] && (this.fibsHmwbnutzungByHmwbnutzung_id = FibsHmwbnutzung.deserialize(obj["fibsHmwbnutzungByHmwbnutzung_id"]));
        obj && obj["fischinfoProbenahmestelleListByForeignOberflaechenwasserkoerper_id"] && obj["fischinfoProbenahmestelleListByForeignOberflaechenwasserkoerper_id"].length && (this.fischinfoProbenahmestelleListByForeignOberflaechenwasserkoerper_id = obj["fischinfoProbenahmestelleListByForeignOberflaechenwasserkoerper_id"].map(FischinfoProbenahmestelle.deserialize));
        return this;
    }

    /**
     * @type {string} character varying 254
     * Datum der zugrundeliegenden Stationierungsversion (z.B. 30.11.2014)
     */
    @fn.DataMember({ name: "Datum_GSK", type: String, databaseType: "character varying(254)", nullable: true, memberType: "" })
    public Datum_GSK?: string;

    /**
     * @type {string} character varying 254
     * zugrunde liegende Stationierungsversion (z.B. GSK3C)
     */
    @fn.DataMember({ name: "GSK", type: String, databaseType: "character varying(254)", nullable: true, memberType: "" })
    public GSK?: string;

    /**
     * @type {string} character varying 10
     */
    @fn.DataMember({ name: "auflage", type: String, databaseType: "character varying(10)", nullable: true, memberType: "" })
    public auflage?: string;

    /**
     * @type {string} character varying 254
     * Ausweisung des Oberflächenwasserkörpers (NWB, HMWB oder AWB)
     */
    @fn.DataMember({ name: "ausweisung", type: String, databaseType: "character varying(254)", nullable: true, memberType: "" })
    public ausweisung?: string;

    /**
     * @type {string} character varying 255
     */
    @fn.DataMember({ name: "bezeichnung", type: String, databaseType: "character varying(255)", nullable: true, memberType: "" })
    public bezeichnung?: string;

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "bis", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public bis: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "hmwbnutzung_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public hmwbnutzung_id?: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "von", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public von: number;

    /**
     * @type {FibsHmwbnutzung}
     */
    @fn.DataMember({ name: "fibsHmwbnutzungByHmwbnutzung_id", type: FibsHmwbnutzung, nullable: true, memberType: "JOIN", joinProperty: "hmwbnutzung_id" })
    public fibsHmwbnutzungByHmwbnutzung_id?: FibsHmwbnutzung;

    /**
     * @type {Array<FischinfoProbenahmestelle>}
     */
    @fn.DataMember({ name: "fischinfoProbenahmestelleListByForeignOberflaechenwasserkoerper_id", type: [FischinfoProbenahmestelle], nullable: true, memberType: "FOREIGN JOIN" })
    public fischinfoProbenahmestelleListByForeignOberflaechenwasserkoerper_id?: Array<FischinfoProbenahmestelle>;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
