<td><uw-textinput
    [(value)]="hoehe"
    [disabled]="readonly"
></uw-textinput></td>
<td><uw-textinput
    [(value)]="laenge"
    [disabled]="readonly"
></uw-textinput></td>
<td><uw-textinput
    [(value)]="weitevon"
    [disabled]="readonly"
></uw-textinput></td>
<td><uw-textinput
    [(value)]="weitebis"
    [disabled]="readonly"
></uw-textinput></td>
<td><uw-textinput
    [(value)]="position"
    [disabled]="readonly"
></uw-textinput></td>
<td 
    *ngIf="!readonly"
    style="width:24px;padding:0;"
><uw-inline-uncheck-button
    checked="true"
    hideIcon="true"
    width="24"
    (click)="delete($event)"
></uw-inline-uncheck-button></td>