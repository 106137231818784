import * as fn from "./../../../decorators/index";
import DataModel from "./../../model";
import IFischinfoDetailbefischungergebnis from "./interface";
import FischinfoBefischung from "./../../fischinfo/befischung/model";
@fn.DataModel
export default class FischinfoDetailbefischungergebnis extends DataModel implements IFischinfoDetailbefischungergebnis {
    constructor(obj?: object | IFischinfoDetailbefischungergebnis) {
        super();
        this.deserialize(obj);
    }
    public static deserialize<T extends object>(obj?: T | IFischinfoDetailbefischungergebnis): FischinfoDetailbefischungergebnis {
        return new FischinfoDetailbefischungergebnis(obj);
    }
    public deserialize<T extends object>(obj: T | IFischinfoDetailbefischungergebnis): FischinfoDetailbefischungergebnis {
        Object.assign(this, obj);
        obj && obj["fischinfoBefischungByBefischung_id"] && (this.fischinfoBefischungByBefischung_id = FischinfoBefischung.deserialize(obj["fischinfoBefischungByBefischung_id"]));
        return this;
    }

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "anzahl", type: Number, databaseType: "integer", nullable: false, memberType: "" })
    public anzahl: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "art_id", type: Number, databaseType: "integer", nullable: true, memberType: "" })
    public art_id?: number;

    /**
     * @type {number} integer
     */
    @fn.DataMember({ name: "befischung_id", type: Number, databaseType: "integer", nullable: true, memberType: "FOREIGN KEY" })
    public befischung_id?: number;

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "gewichtg", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public gewichtg: number;

    /**
     * @required
     * @type {number} integer
     */
    @fn.DataMember({ name: "id", type: Number, databaseType: "integer", nullable: false, memberType: "KEY" })
    public id: number;

    /**
     * @type {boolean}
     */
    @fn.DataMember({ name: "jungfisch", type: Boolean, databaseType: "boolean", nullable: true, memberType: "" })
    public jungfisch?: boolean;

    /**
     * @required
     * @type {number} double precision
     */
    @fn.DataMember({ name: "laengecm", type: Number, databaseType: "double precision", nullable: false, memberType: "" })
    public laengecm: number;

    /**
     * @type {number} bigint
     */
    @fn.DataMember({ name: "version", type: Number, databaseType: "bigint", nullable: true, memberType: "" })
    public version?: number;

    /**
     * @type {FischinfoBefischung}
     */
    @fn.DataMember({ name: "fischinfoBefischungByBefischung_id", type: FischinfoBefischung, nullable: true, memberType: "JOIN", joinProperty: "befischung_id" })
    public fischinfoBefischungByBefischung_id?: FischinfoBefischung;

    // KEEP THIS COMMENT AND ADD CUSTOM MEMBERS BELOW

}
