import { Component, Input, AfterViewInit } from "@angular/core";
import UserService from "./../user/service";
import ModalService from "./../modal/service";
import DataService from "./../data/service";
import MapService from "../map/service";
import FischinfoProbenahmestelle from "../../models/fischinfo/probenahmestelle/model";
import FischinfoUntersuchung from "../../models/fischinfo/untersuchung/model";
@Component({
    selector: "uw-modal-window-tab-wms",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class ModalWindowTabWMS {
    @Input()
    public modal: any;
    @Input()
    public set topmargin(value: string) {
        this.topMarginPx = String(Number(value)
            -
                (this.userService.isEditor
                    ? 0
                    : Number(this.buttonPanelHeight)));
    }
    ;
    public get topmargin(): string {
        return this.topMarginPx;
    }
    ;
    public buttonPanelHeight: string = "24";
    public topMarginPx: string;
    public get wms() {
        return this.modal.data.wmsFeatureRequest;
    }
    public set wms(item) {
        this.modal.data.wmsFeatureRequest = item;
    }
    public get probestellen(): FischinfoProbenahmestelle[] {
        return this.wms.filter(x => x instanceof FischinfoProbenahmestelle);
    }
    constructor(public modalService: ModalService, public dataService: DataService, public userService: UserService, public mapService: MapService) {
        this.topmargin = "24";
    }
    public ngAfterViewInit() {
        if (this.wms && this.wms.length === 1) {
            if (this.wms[0] instanceof FischinfoProbenahmestelle) {
                this.getProbestelle(this.modal.id, this.wms[0] as FischinfoProbenahmestelle);
            }
            else if (this.wms[0] instanceof FischinfoUntersuchung) {
                this.getUntersuchung(this.modal.id, this.wms[0] as FischinfoUntersuchung);
            }
        }
    }
    public onClickModalWMSFeature(e: Event, wmsFeature: any) {
        this.getProbestelle(this.modal.id, wmsFeature as FischinfoProbenahmestelle);
    }
    public getProbestelle(modalId, item: FischinfoProbenahmestelle) {
        let data = this.modalService.getModalOption(modalId, "data") || {};
        data.loaded = 0;
        data.activeTab = "probenahmestelle";
        data.probenahmestelleToBeLoaded = item;
        data.probenahmestelle = null;
        data.untersuchungToBeLoaded = null;
        data.untersuchung = null;
        data.status = "Wird geladen…";
        this.modalService.setModalOption(modalId, "data", data);
        this.dataService.get("probenahmestelle", FischinfoProbenahmestelle, String(item.id), null, null, false)
            .then(item => {
            let data = this.modalService.getModalOption(modalId, "data") || {};
            data.loaded = 1;
            data["probenahmestelle"] = item;
            this.modalService.setModalOption(modalId, "data", data);
            this.modalService.setModalOption(modalId, "status", "");
            if (data.zoomOnFeature && item["etrs89n"] && String(item["etrs89n"]).length > 0) {
                const point: any = {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            geometry: { "type": "Point", "coordinates": [item["etrs89e"], item["etrs89n"]] },
                            properties: {}
                        },
                    ]
                };
                this.mapService.zoomGeometry(<JSON>point.features[0].geometry, "EPSG:25832");
                /*
                this.mapService.drawAndZoomGeometry(
                    "selectedItem",
                    <JSON>point.features[0].geometry,
                    <JSON>point,
                    false,
                    {},
                    "EPSG:25832"
                );
                */
            }
        })
            .catch(error => console.error(error));
    }
    public getUntersuchung(modalId, item: FischinfoUntersuchung) {
        let data = this.modalService.getModalOption(modalId, "data") || {};
        data.loaded = false;
        data.activeTab = "untersuchung";
        data.untersuchungToBeLoaded = item;
        data.probenahmestelleToBeLoaded = item.fischinfoProbenahmestelleByProbenahmestelle_id;
        data.probenahmestelle = null;
        data.untersuchung = null;
        data.wmsFeatureRequest = [item.fischinfoProbenahmestelleByProbenahmestelle_id];
        data.status = "Wird geladen…";
        this.modalService.setModalOption(modalId, "data", data);
        this.dataService.get("probenahmestelle", FischinfoProbenahmestelle, String(item.probenahmestelle_id), null, null, false)
            .then(item => {
            let data = this.modalService.getModalOption(modalId, "data") || {};
            data.probenahmestelle = item;
            data.loaded = data.untersuchung ? true : false;
            this.modalService.setModalOption(modalId, "data", data);
            this.modalService.setModalOption(modalId, "status", "");
            if (data.zoomOnFeature && item.etrs89n && String(item.etrs89n).length > 0) {
                const point: any = {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            geometry: { "type": "Point", "coordinates": [item.etrs89e, item.etrs89n] },
                            properties: {}
                        },
                    ]
                };
                this.mapService.zoomGeometry(<JSON>point.features[0].geometry, "EPSG:25832");
            }
        })
            .catch(error => console.error(error));
        this.dataService.get("untersuchung", FischinfoUntersuchung, String(item.id))
            .then(item => {
            let data = this.modalService.getModalOption(modalId, "data") || {};
            data.untersuchung = item;
            data.loaded = data.probenahmestelle ? true : false;
            this.modalService.setModalOption(modalId, "data", data);
            this.modalService.setModalOption(modalId, "status", "");
        })
            .catch(error => console.error(error));
    }
    public onClickCreateDataItem(e: Event) {
    }
}
