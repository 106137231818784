
<div
    *ngIf="isExtendedView"
    class="row"
    style="padding-top:6px;"
>
    <div class="col-xs-15">
        <table 
            class="table table-accordion"
            uw-accordion
            active="-1"
            toggle="true"
            [accordionHeight]="accordionHeight"
        >
            <thead>
                <tr>
                    <th title="Filter">Layerauswahl</th>
                </tr>
            </thead>
            <tbody>
                <ng-container
                    *ngFor="let u of layerGroups; let i = index"
                >
                    <tr uw-accordion-pane>
                        <td style="padding: 0 2px 0 0;"><uw-inline-button
                            iconClass="fa"
                            title="{{romanize(i+1) + '. ' + u.name}}"
                            value="{{romanize(i+1) + '. ' + u.name}}"
                            stopPropagation="false"
                        ></uw-inline-button></td>
                    </tr>
                    <tr>
                        <td>
                            <uw-accordion-pane>
                                <article>
                                    <div
                                        *ngFor="let l of u.layers; let j= index"
                                        class="row"
                                    >
                                        <div
                                            [ngSwitch]="l.chosen && !wmsLayerIsInMassstab(l.name)"
                                            class="col-xs-15 col-sm-7dot5"
                                        >
                                            <uw-checkbox
                                                *ngSwitchCase="true"
                                                (change)="onLayerChange($event, l)"
                                                [(value)]="l.chosen"
                                                [label]="l.name"
                                                infoTagClass="fa-exclamation-circle"
                                                infoTag="Layer ist für die Anzeige angewählt, befindet sich jedoch ausserhalb des aktuellen Kartenmaßstabes."
                                                title="Layeransicht wechseln"
                                            ></uw-checkbox>
                                            <uw-checkbox
                                                *ngSwitchDefault
                                                (change)="onLayerChange($event, l)" 
                                                [(value)]="l.chosen"
                                                [label]="l.name"
                                                title="Layeransicht wechseln"
                                            ></uw-checkbox>
                                        </div>
                                    </div>
                                </article>
                            </uw-accordion-pane>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
