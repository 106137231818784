import { Component, Input, Output, EventEmitter } from "@angular/core";
import FormFischinfoUntersuchungService from "./../form-fischinfo-untersuchung/service";
import DataService from "../data/service";
import FischinfoAnzahlbefischungergebnis from "../../models/fischinfo/anzahlbefischungergebnis/model";
import FischinfoAnzahlmuschelergebnis from "../../models/fischinfo/anzahlmuschelergebnis/model";
import FischinfoAnzahlkrebsergebnis from "../../models/fischinfo/anzahlkrebsergebnis/model";
/**
 * Mit dieser Komponente kann der Nutzer die Individuenanzahl einer befischten Art ansehen und ggf. bearbeiten.
 *
 * Der Nutzer kann zur Individuenanzahl optional ein Gesamtgewicht aller Individuen angeben.
 */
@Component({
    selector: "form-fischinfo-untersuchung-befischung-ergebnisse-art-erfassung-anzahl",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.less"]
})
export default class FormFischinfoUntersuchungBefischungErgebnisseArtErfassungAnzahl {
    @Input()
    public modalId: string;
    @Input()
    public artId: string;
    @Input()
    public artGruppe: string;
    @Input()
    public anzahlErgebnis: FischinfoAnzahlbefischungergebnis | FischinfoAnzahlmuschelergebnis | FischinfoAnzahlkrebsergebnis;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    public _readonly: boolean;
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input()
    public set readonly(value: boolean) {
        this._readonly = value;
    }
    public get anzahl(): number {
        return this.anzahlErgebnis
            && this.anzahlErgebnis.anzahl;
    }
    public set anzahl(value: number) {
        this.anzahlErgebnis
            && (this.anzahlErgebnis.anzahl = parseInt(String(value), 10) || null);
        this.changed.emit(true);
    }
    public get gewicht(): number {
        return this.anzahlErgebnis instanceof FischinfoAnzahlbefischungergebnis
            ? this.anzahlErgebnis
                && this.anzahlErgebnis.gewicht
            : null;
    }
    public set gewicht(value: number) {
        this.anzahlErgebnis instanceof FischinfoAnzahlbefischungergebnis
            && this.anzahlErgebnis
            && (this.anzahlErgebnis.gewicht = parseInt(String(value), 10) || null);
    }
    constructor(public formFischinfoUntersuchungService: FormFischinfoUntersuchungService, public dataService: DataService) {
    }
}
