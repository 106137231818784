import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * Dieser Dienst liefert den Authentifikationschlüssel für den Nutzer.
 * 
 * Der Dienst unterscheidet dabei nach der URL des Authentifizierungsdienstes und speichert die Schlüssel zwischen. Wenn für einen Authentifizierungsdienst kein Schlüssel vorhanden ist, dann meldet der Dienst den Nutzer an.
 */
@Injectable({
    providedIn: 'root'
})
export default class LoginService {
    public login: string;
    public password: string;

    protected tokenByAuthUrl: { [authUrl: string]: string } = {};
    protected tokenByAuthUrlPromise: { [authUrl: string]: Promise<string> } = {};

    constructor(protected http: HttpClient) {
        this.login = "unset"; // wird noch ausgelagert in Eingabe
        this.password = "unset"; // SHA1("unset"); // wird noch ausgelagert in Eingabe
    }

    public getAuthToken(authUrl): Promise<string> {
        return !this.tokenByAuthUrlPromise[authUrl]
            ? this.tokenByAuthUrlPromise[authUrl] = this.tokenByAuthUrl[authUrl]
                ? <Promise<string>>Promise.resolve(this.tokenByAuthUrl[authUrl])
                : this.http
                    .get<{ resultset: [{ token: string }] }>(this._getUrl(authUrl))
                    .toPromise(Promise)
                    .then((result) => {
                        return this.tokenByAuthUrl[authUrl] = result.resultset[0].token;
                    })
                    .catch(() => {
                        return this.tokenByAuthUrl[authUrl] = "ist nicht erfolgreich";
                    })
            : this.tokenByAuthUrlPromise[authUrl]
        ;
    }

    protected _getUrl (url: string): string {
        url = url.replace("{login}", this.login);
        url = url.replace("{password}", this.password);
        return url;
    }

    public setConfig(config: any): void {
    }

    public getOption(option: string): any {
    }

    public setOption(option: string, value: any): any {
    }

    public getAttribute(attribute: string): any {
    }

    public setAttribute(attribute: string, value: any): any {
    }

}
