
<!-- #region Warnmeldung(en) //-->
<uw-modal-window-message
    [visibility]="(error != null).toString()"
    (visibilityChange)="error = $event === 'false' ? null : error"
    closeable="true"
    warning="true"
>
    <article [ngSwitch]="true">
        <p *ngSwitchCase="error?.name === 'INVALID_FORMAT'">
            Das Format der Untersuchungs-Importdaten ist fehlerhaft. Bitte überprüfen Sie Ihre Eingaben.
            <br/>
            <i>{{error.message}}</i>
        </p>
        <p *ngSwitchCase="error?.status === 403">
            Sie haben keine Rechte, den Untersuchungs-Import auszuführen.
        </p>
        <p *ngSwitchDefault>
            Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
        </p>
    </article>
</uw-modal-window-message>
<!-- #endregion //-->

<!-- #region Formular //-->
<section
    class="uw-tab-body"
>
    <div
        class="container-fluid"
        [style.marginTop.px]="topmargin"
    >
        <div class="row">
            <div class="col-xs-15"><label>Untersuchungen importieren</label></div>
        </div>

        <section class="form-step row" *ngIf="!error || error.name === 'INVALID_FORMAT'">
            <div class="col-xs-15 space-top">
                <ng-container *ngIf="!running">
                    <div class="row">
                        <div class="col-xs-15">
                            <p>
                                Bitte wählen Sie unten eine Datei mit den Daten für die neuen Untersuchungen aus.
                                Die Daten werden dann geprüft und importiert.
                            </p>
                        </div>
                    </div>
                    <div class="row space-bottom">
                        <div class="col-xs-15">
                            <uw-fileinput
                                placeholder="Bitte wählen Sie eine CSV-Datei aus"
                                accept="text/csv,application/csv,*.csv"
                                (change)="importCsv($event)"
                            ></uw-fileinput>
                        </div>
                    </div>
                </ng-container>
                <div class="row space-bottom" *ngIf="running">
                    <div class="col-xs-15">
                        <p>Der Import wird jetzt ausgeführt. Bitte warten ...</p>
                        <p>{{progress?.step}} von {{progress?.total}} Untersuchungen verarbeitet.</p>
                    </div>
                </div>
                <div class="row space-bottom" *ngIf="failedImports.length > 0">
                    <div class="col-xs-15">
                        Folgende Untersuchungen konnten nicht importiert werden:
                        <ul>
                            <li *ngFor="let fail of failedImports" [ngSwitch]="fail.shortdesc">
                                {{fail.item.fischinfoProbenahmestelleByProbenahmestelle_id.probestellennr}}
                                {{fail.item.untersuchungsterminAsLocaleDateString}}
                                {{fail.item.untersuchungsterminAsLocaleTimeString}}
                                {{fail.item.schluesselwort}}
                                <br/>
                                <ng-container *ngSwitchCase="'Missing probenahmestelle_id and probestellennr'">Fehlende PS-Nr.</ng-container>
                                <ng-container *ngSwitchCase="'Ambiguous probenahmestelle'">Uneindeutige PS-Nr. (mehrfach vorhanden)</ng-container>
                                <ng-container *ngSwitchCase="'Unknown probenahmestelle_id'">Unbekannte PS-Nr.</ng-container>
                                <ng-container *ngSwitchCase="'Untersuchung already exists'">Untersuchung ist zum gleichen Zeitpunkt bereits vorhanden.</ng-container>
                                <ng-container *ngSwitchDefault>Fehlerhafte Daten</ng-container>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>
<!-- #endregion //-->
